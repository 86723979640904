import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import DropDownPicker, {
	DropDownPickerProps,
} from 'react-native-dropdown-picker';
import { ChevronDown } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Label from '../label/Label';

type Props = {
	label?: string;
	actionText?: string;
	outerContainerStyles?: TStyle;
	error?: string;
} & DropDownPickerProps;

const DropdownLarge = ({
	containerStyle,
	textStyle,
	style,
	dropDownContainerStyle,
	listItemContainerStyle,
	open,
	setOpen,
	label,
	outerContainerStyles,
	error,
	actionText,
	...props
}: Props) => {
	const { isLessThanDesktopMedium, isLessThanDesktopBase } =
		useIsSpecificViewportWidth();
	const primary = usePrimaryColors();
	const { t } = useTranslation();
	return (
		<View style={[styles.outerContainer, outerContainerStyles]}>
			{label && (
				<Label
					error={error}
					label={label}
					actionText={actionText ?? ''}
				/>
			)}
			<DropDownPicker
				listMode="FLATLIST"
				showTickIcon={false}
				disableBorderRadius={false}
				open={open}
				setOpen={setOpen}
				searchPlaceholder={`${t('Search')}...`}
				searchTextInputStyle={[
					{
						padding: 14,
						paddingVertical: 0,
						paddingBottom: 20,
						height: 50,
						marginHorizontal: 14,
						marginBottom: getResponsiveStyle(14),
						borderColor: primary[0],
					},
					Platform.OS === 'web' &&
						({
							outlineColor: primary[0],
							outerWidth: 3,
						} as any),
				]}
				scrollViewProps={{
					showsVerticalScrollIndicator: false,
					showsHorizontalScrollIndicator: false,
				}}
				flatListProps={{
					showsHorizontalScrollIndicator: false,
					showsVerticalScrollIndicator: false,
				}}
				style={[
					styles.main,
					styleMain(
						isLessThanDesktopMedium,
						isLessThanDesktopBase,
						open
					),
					style,
					open && { ...styles.mainOpen, borderColor: primary[0] },
					!!error && styles.mainError,
					Platform.OS === 'web' &&
						({
							outline: 'none',
						} as any),
				]}
				textStyle={[
					styles.text,
					styleText(),
					stylePaddingTop(
						isLessThanDesktopMedium,
						isLessThanDesktopBase,
						open,
						'positive'
					),
					textStyle,
				]}
				containerStyle={[
					styles.container,
					containerStyle,
					!!error
						? styles.containerError
						: open && {
								borderRadius: 8,
								borderColor: primary[1],
						  },
					Platform.OS === 'web' &&
						({
							outline: 'none',
						} as any),
				]}
				dropDownContainerStyle={[
					styles.dropdownContainer,
					styleDropdownContainer(),
					dropDownContainerStyle,
					{ zIndex: 5000, elevation: 5000 },
				]}
				listItemContainerStyle={[
					styles.listItemContainer,
					styleListItemContainer(),
					listItemContainerStyle,
				]}
				listItemLabelStyle={[
					styles.listItemLabel,
					stylePaddingTop(
						isLessThanDesktopMedium,
						isLessThanDesktopBase,
						open,
						'negative'
					),
				]}
				selectedItemContainerStyle={{
					...styles.selectedItemContainer,
					borderLeftColor: primary[0],
				}}
				placeholderStyle={stylePaddingTop(
					isLessThanDesktopMedium,
					isLessThanDesktopBase,
					open,
					'positive'
				)}
				selectedItemLabelStyle={styles.selectedItemLabel}
				ArrowDownIconComponent={() => (
					<ChevronDown
						style={[
							styleChevron('down'),
							Platform.OS === 'web' &&
								({
									outline: 'none',
								} as any),
						]}
						containerStyles={[
							styleChevronContainer(),
							styles.chevronContaniner,
							Platform.OS === 'web' &&
								({
									outline: 'none',
								} as any),
						]}
					/>
				)}
				ArrowUpIconComponent={() => (
					<ChevronDown
						style={[
							styles.chevronUp,
							styleChevron('up'),
							Platform.OS === 'web' &&
								({
									outline: 'none',
								} as any),
						]}
						onPress={() => {
							props.onClose && props.onClose();
							setOpen(!open);
						}}
						containerStyles={[
							styleChevronContainer(),
							styles.chevronContaniner,
							Platform.OS === 'web' &&
								({
									outline: 'none',
								} as any),
						]}
					/>
				)}
				{...props}
			/>
		</View>
	);
};

export default DropdownLarge;

const styles = StyleSheet.create({
	chevronContaniner: {
		justifyContent: 'center',
	},
	outerContainer: {
		width: '100%',
		height: 'max-content',
		zIndex: 5000,
	},
	main: {
		flexDirection: 'row',
		borderRadius: 6,
		borderWidth: 1,
		borderColor: palettes.grey[4],
	},
	mainError: {
		borderColor: palettes.red[0],
	},
	mainOpen: { borderWidth: 2 },
	container: {
		borderWidth: 3,
		borderColor: 'transparent',
	},
	containerError: {
		borderRadius: 8,
		borderColor: `${palettes.red[0]}20`,
	},
	text: {
		color: palettes.grey[0],
		marginRight: 4,
	},
	dropdownContainer: {
		borderRadius: 6,
		backgroundColor: 'white',
		borderColor: palettes.grey[3],
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
	},
	listItemContainer: {
		borderLeftWidth: 2,
		borderLeftColor: 'transparent',
	},
	listItemLabel: {
		display: 'flex',
		alignItems: 'center',
	},
	selectedItemContainer: {
		borderLeftWidth: 2,
	},
	selectedItemLabel: {
		color: palettes.dark[0],
	},
	chevronUp: {
		transform: [{ rotate: '180deg' }],
	},
});

const styleMain = (
	isLessThanDesktopMedium: boolean,
	isLessThanDesktopBase: boolean,
	open: boolean
) => {
	const paddingTop = isLessThanDesktopBase
		? 18
		: isLessThanDesktopMedium
		? 14
		: 22;
	const paddingLeft = getResponsiveStyle(16);
	const _paddingTop = open ? paddingTop - 1 : paddingTop;
	const _paddingLeft = open ? paddingLeft - 1 : paddingLeft;
	return {
		// paddingTop: _paddingTop,
		paddingLeft: _paddingLeft,
		paddingRight: getResponsiveStyle(18),
		height: getResponsiveStyle(48, 'dimensions'),
	};
};

const stylePaddingTop = (
	isLessThanDesktopMedium: boolean,
	isLessThanDesktopBase: boolean,
	open: boolean,
	type: 'negative' | 'positive'
) => {
	const paddingTop = isLessThanDesktopBase
		? 18
		: isLessThanDesktopMedium
		? 14
		: 22;
	const _paddingTop = open ? paddingTop - 1 : paddingTop;
	return {
		paddingTop: type === 'negative' ? -_paddingTop : _paddingTop,
		height: getResponsiveStyle(48, 'dimensions'),
	};
};

const styleChevron = (type: 'up' | 'down') => {
	return {
		width: 8,
		height: getResponsiveStyle(6, 'dimensions'),
		marginTop: type === 'down' && 'calc(50% - 4px)',
		marginBottom: type === 'up' && 'calc(50% - 4px)',
	} as TStyle;
};

const styleListItemContainer = () => {
	return {
		marginBottom: getResponsiveStyle(6),
		height: getResponsiveStyle(25, 'dimensions'),
		paddingHorizontal: getResponsiveStyle(8),
	};
};

const styleDropdownContainer = () => {
	return {
		paddingTop: getResponsiveStyle(6),
		marginTop: getResponsiveStyle(8),
		// height: getResponsiveStyle(700),
	};
};

const styleText = () => {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
};
function styleChevronContainer() {
	return {
		height: getResponsiveStyle(48, 'dimensions'),
	};
}
