import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Button, DraggableScrollView } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
// import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { createArray } from '../../../utils/createArray';
import ChatProfileStatsCard from './sub-component/ChatProfileStatCard';
import Links from './sub-component/Links';
import Media from './sub-component/Media';
import Videos from './sub-component/Videos';
const ProfileDetail = () => {
	const ItemsToRender = createArray(1);
	const { isDesktop } = useIsSpecificViewportWidth();
	// const { t } = useTranslation();

	const [media, setMedia] = useState('Media');

	const handleMediaSelect = (media: string) => {
		setMedia(media);
	};
	const { t } = useTranslation();

	// const onCompleteGetAllUsers = (data: TGetAllUsersResponse) => {
	// 	if (data?.getAllUsers.success && data.getAllUsers.data) {
	// 		// const users = data.getAllUsers.data.data.map(users => ({
	// 		// 	label: users.email,
	// 		// 	value: users._id,
	// 		// }));
	// 		// setUsers(data.getAllUsers.data.data);
	// 	}
	// };

	// const {
	// 	lazy: { getAllUsersLazy },
	// 	data,
	// } = useGetAllUsers({
	// 	onCompleted: onCompleteGetAllUsers,
	// });

	// useEffect(() => {
	// 	const find = {
	// 		username: "fassih",
	// 	} as TGetAllUsersFind;
	// 	getAllUsersLazy(find);
	// }, []);

	const variant = (tab: string) => {
		return media !== tab ? 'grey' : 'outline';
	};

	return (
		<View style={[styles.container, { paddingLeft: isDesktop ? 0 : 10 }]}>
			<ChatProfileStatsCard />
			<View style={{ paddingLeft: isDesktop ? 10 : 0 }}>
				<DraggableScrollView
					data={ItemsToRender}
					showsHorizontalScrollIndicator={false}
					renderItem={() => (
						<>
							<Button
								width={20}
								radius={50}
								variant={`${variant('Media')}`}
								title={t('Media')}
								onPress={() => handleMediaSelect('Media')}
							/>
							<Button
								width={30}
								radius={50}
								variant={`${variant('Videos')}`}
								title={t('Videos')}
								onPress={() => handleMediaSelect('Videos')}
							/>
							<Button
								width={30}
								radius={50}
								variant={`${variant('Links')}`}
								title={t('Links')}
								onPress={() => handleMediaSelect('Links')}
							/>
						</>
					)}
				/>
			</View>
			{media === 'Media' && <Media />}
			{media === 'Links' && <Links />}
			{media === 'Videos' && <Videos />}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// width: '20%',
		flex: 2,
		borderRightWidth: 1,
		borderColor: palettes.grey[4],
	},
});

export default ProfileDetail;
