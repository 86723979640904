import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsSpecificViewportWidth } from "../useIsSpecificViewportWidth";
import { TUseNavigation } from "../../types/exportedTypes";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "../../redux/store";
import { useDispatch } from "react-redux";
import { setCreateEventSidebarValues, setCurrentScreen } from "../../redux/uiSlice";
import { getStateOfEvent } from "../../utils/handleNavigationToEvent/getStateOfEvent";
import { useLazyQuery, useQuery } from "@apollo/client";
import useGetMedia from "../../api/common/useGetMedia";
import { setCurrentEvent, setIsAffiliatedHostEditingEvent, setIsEditEvent, setIsEditEventFromLink } from "../../redux/getEventSlice";
import { setTypeOfEvent } from "../../redux/createEventSlice";
import { IS_SUBSCRIBED_TO_EVENTS } from "../../graphql/eventSubscription/queries";
import { isEventDone } from "../../utils/statusOfEvent/isEventDone";
import { isEevntOngoing } from "../../utils/statusOfEvent/isEventOngoing";
import dayjs from "dayjs";
import { Linking } from "react-native";
import config from "../../../config";
import { GET_SINGLE_EVENT } from "../../graphql/events/queries";

export const useEventDescription = ({
    eventId
}: any) => {
    const { t } = useTranslation();
    const SECTION_TABS = [
        { label: t('Description'), value: 'Description' },
        { label: t('Products'), value: 'Products' },
        { label: t('Guest'), value: 'Guest' },
        { label: t('Location'), value: 'Location' },
        // { label: t('Reviews'), value: 'Reviews' },
    ];

    const SECTION: TEventDescriptionSection =
        'Description' as TEventDescriptionSection;

    const getTabs = (_: TEventDescriptionSection) => {
        return SECTION_TABS;
    };
    const [selectedTab, setSelectedTab] = useState<string>(
        getTabs(SECTION)[0].value
    );
    const [image, setImage] = useState<TMedia>();
    const { isLessThanDesktopBase, isDesktop } = useIsSpecificViewportWidth();
    const navigation = useNavigation<TUseNavigation>();
    const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
        loggedInUserDetails: state.auth.loggedInUserDetails,
        isUserLoggedIn: state.auth.isUserLoggedIn,
    }));
    const dispatch = useDispatch();

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isLoadingProduct, setIsLoadingProduct] = useState(true);

    useEffect(() => {
        document
            .getElementById(selectedTab)
            ?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedTab]);

    const onPress = (tab: string) => {
        setSelectedTab(tab);
        // TODO: Set wondows scroll here
    };

    const { data: eventData, loading: eventLoading } = useQuery(
        GET_SINGLE_EVENT,
        {
            fetchPolicy: 'no-cache',
            variables: {
                _id: eventId,
            },
        }
    );

    const event = eventData?.getEvent?.data;

    const stateOfEvent = getStateOfEvent(
        event?.eventStreamTimeline,
        event?.startingEventDateTime
    );

    useEffect(() => {
        dispatch(setCurrentScreen('/event-description'));
        if (
            ['Upcoming', 'Ongoing']?.includes(stateOfEvent) &&
            event?.typeOfEvent === 'VIRTUAL'
        ) {
            navigation.navigate('LiveEvent', {
                eventId,
            });
        }
    }, [event]);

    const { executeMediaQuery, data: mediaData } = useGetMedia();

    useEffect(() => {
        if (event?.eventMedia?.length) {
            executeMediaQuery({
                _id: event?.eventMedia[0],
            });
        }
    }, [eventData]);

    useEffect(() => {
        const image = mediaData?.getMedia?.data;
        if (image) setImage(image);
    }, [mediaData]);
    if (event) {
        dispatch(setCurrentEvent(event));
    }

    const handleEditEvent = (page: string) => {
        dispatch(setTypeOfEvent(event.typeOfEvent));
        if (event.typeOfEvent) {
            dispatch(setCreateEventSidebarValues(event.typeOfEvent));
            dispatch(setIsEditEventFromLink(true));
            dispatch(setIsEditEvent(true));
            dispatch(setIsAffiliatedHostEditingEvent(true));
            navigation.navigate(page as any);
        }
    };

    const isAffiliatedHost = () => {
        return (
            (loggedInUserDetails?._id === event?.affiliatedHosts?.[0]?._id &&
                event?.isHostApplicationAccepted) ||
            (!event?.affiliatedHosts?.length &&
                loggedInUserDetails?._id === event?.affiliatedSeller?._id &&
                event.typeOfEvent === 'VIRTUAL')
        );
    };

    const isAffiliatedSeller = () => {
        return loggedInUserDetails?._id === event?.affiliatedSeller?._id;
    };

    const isLocationAvailable = () => {
        return event?.eventLocation?.address1 || event?.eventLocation?.address2;
    };

    const isAllowedToSeeLocation = () => {
        if (loggedInUserDetails?.typeOfAccount === 'CONSUMER') {
            return event?.invitedPeople
                ?.map((user: any) => user._id)
                ?.includes(loggedInUserDetails?._id);
        } else if (isAffiliatedHost() || isAffiliatedSeller()) {
            return true;
        }
    };

    const aDayAfter = new Date(
        +event?.startingEventDateTime + 1000 * 60 * 60 * 24
    ).getTime();

    const currentTime = new Date().getTime();

    const isEventEnded = () => {
        //if the event never started
        if (event?.typeOfEvent === 'VIRTUAL' && !event?.eventStreamTimeline) {
            return new Date().getTime() <
                new Date(+event?.startingEventDateTime).getTime()
                ? false
                : true;
        }
        //if the event actual started
        return (
            (event?.typeOfEvent === 'VIRTUAL' &&
                event?.eventStreamTimeline?.endedAt) ||
            (event?.typeOfEvent === 'PHYSICAL' && currentTime > aDayAfter)
        );
    };

    const [isSubscribedToEvent] = useLazyQuery(IS_SUBSCRIBED_TO_EVENTS, {
        onCompleted: data =>
            setIsSubscribed(
                data?.isSubscribedToEvent?.data?.data[0]?.isSubscribed
            ),
    });

    useEffect(() => {
        if (
            loggedInUserDetails?.typeOfAccount === 'HOST' ||
            loggedInUserDetails?.typeOfAccount === 'CONSUMER'
        ) {
            isSubscribedToEvent({
                variables: {
                    eventIds: [eventId],
                },
            });
        }
    }, [eventId]);

    const isShowViewProfileBtn =
        (isEventDone(event) || isEevntOngoing(event)) && !isUserLoggedIn;

    const getGoogleLink = () => {
        const formattedDate = dayjs(+event?.startingEventDateTime).format(
            'YYYYMMDDTHHmmss'
        );
        const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
            event?.name
        )}&dates=${encodeURIComponent(
            `${formattedDate}/${formattedDate}`
        )}&location=${encodeURIComponent(
            `${config.CLIENT_URL}/event/description?eventId=${event?._id}`
        )}`;
        Linking.openURL(googleCalendarUrl as string);
    };

    const getOutlookLink = () => {
        const formattedDate = dayjs(+event?.startingEventDateTime).format(
            'YYYYMMDDTHHmmss'
        );
        const outlookCalendarUrl = `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&subject=${encodeURIComponent(
            event?.name
        )}&startdt=${encodeURIComponent(
            `${formattedDate}`
        )}&enddt=${encodeURIComponent(
            `${formattedDate}`
        )}&body=${encodeURIComponent(event?.description)}`;
        Linking.openURL(outlookCalendarUrl as string);
    };

    return {
        SECTION_TABS,
        SECTION,
        isLessThanDesktopBase,
        isDesktop,
        selectedTab,
        image,
        isUserLoggedIn,
        loggedInUserDetails,
        isSubscribed,
        isLoadingProduct,
        eventData,
        eventLoading,
        stateOfEvent,
        isShowViewProfileBtn,
        getGoogleLink,
        getOutlookLink,
        isLocationAvailable,
        isEventEnded,
        isAffiliatedHost,
        isAllowedToSeeLocation,
        handleEditEvent,
        setIsSubscribed,
        setIsLoadingProduct,
        onPress,
        setImage,
        getTabs,
        setSelectedTab,
    }
};
