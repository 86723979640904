import React from 'react';
import { Pressable } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

const CommentIcon = ({
	onPress,
	outlineColor = 'rgba(16,22,37,1)',
	...props
}: SvgProps & { outlineColor?: string }) => {
	return (
		<Pressable onPress={onPress}>
			<Svg viewBox="0 0 24 24" width={24} height={24} {...props}>
				<Path fill="none" d="M0 0h24v24H0z" />
				<Path
					d="M5.763 17H20V5H4v13.385L5.763 17zm.692 2L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455z"
					fill={outlineColor}
				/>
			</Svg>
		</Pressable>
	);
};

export default CommentIcon;
