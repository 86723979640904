import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';
import { TStyle } from '../../../types/TStyle';

type Props = {
	containerStyles?: TStyle;
	onPress?: () => void;
	color?: string;
	data?: any;
	position?: string;
} & Omit<SvgProps, 'onPress' | 'color'>;

const BackEditIcon = ({
	containerStyles,
	onPress,
	color,
	data,
	position,
	...props
}: Props) => {
	return (
		<Pressable onPress={onPress} style={containerStyles}>
			<Svg
				fill="#000000"
				height="800px"
				width="800px"
				viewBox="0 0 512 512"
				// xmlSpace="preserve"
				{...props}
			>
				<G>
					<G>
						<Path
							d="M490.667,128c11.782,0,21.333-9.551,21.333-21.333V21.333C512,9.551,502.449,0,490.667,0H21.333C9.551,0,0,9.551,0,21.333
              v85.333C0,118.449,9.551,128,21.333,128H192v64H21.333C9.551,192,0,201.551,0,213.333v85.333C0,310.449,9.551,320,21.333,320H192
              v42.667h-64c-19.953,0-28.986,24.948-13.657,37.722l128,106.667c7.911,6.593,19.403,6.593,27.315,0l128-106.667
              c15.328-12.774,6.296-37.722-13.657-37.722h-64V320h170.667c11.782,0,21.333-9.551,21.333-21.333v-85.333
              c0-11.782-9.551-21.333-21.333-21.333H320v-64H490.667z M298.667,405.333h26.41L256,462.897l-69.076-57.564h26.41
              c11.782,0,21.333-9.551,21.333-21.333v-64h42.667v64C277.333,395.782,286.885,405.333,298.667,405.333z M469.333,277.333H298.667
              h-85.333H42.667v-42.667h170.667h85.333h170.667V277.333z M234.667,192v-85.333c0-11.782-9.551-21.333-21.333-21.333H42.667
              V42.667h426.667v42.667H298.667c-11.782,0-21.333,9.551-21.333,21.333V192H234.667z"
							fill={props?.fill}
						/>
					</G>
				</G>
			</Svg>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		// Additional styles for the container if needed
	},
});

export default BackEditIcon;
