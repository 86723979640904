import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const MicFilledIcon = ({ fillColor }: { fillColor: string }) => {
	return (
		<Svg viewBox="0 0 24 24" width="19.04" height="16.85">
			<Path fill="none" d="M0 0h24v24H0z" />
			<Path
				d="M12 3a3 3 0 0 0-3 3v6a3 3 0 0 0 6 0V6a3 3 0 0 0-3-3zm0-2a5 5 0 0 1 5 5v6a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM2.192 13.962l1.962-.393a8.003 8.003 0 0 0 15.692 0l1.962.393C20.896 18.545 16.85 22 12 22s-8.896-3.455-9.808-8.038z"
				fill={fillColor}
			/>
		</Svg>
	);
};

export default MicFilledIcon;
