import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TInitialSortState = {
	value?: string;
	local?: boolean;
	type?: number | any;
};

const initialState: TInitialSortState = {};

const addSortSlice = createSlice({
	name: 'addSortSlice',
	initialState,
	reducers: {
		resetSort: () => initialState,
		setSort(state, action: PayloadAction<TInitialSortState>) {
			state.value = action.payload.value;
			state.type = action.payload.type;
			state.local = action.payload.local;
		},
	},
});

export const { resetSort, setSort } = addSortSlice.actions;

export default addSortSlice.reducer;
