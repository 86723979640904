import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
	Image,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
	Pressable,
	FlatList,
} from 'react-native';
import { ScrollView } from 'react-native-web-hover';
import { palettes } from '../../../../../config';
import { CREATE_CART } from '../../../../../graphql/cart/mutation';
import { GET_CART } from '../../../../../graphql/cart/queries';
import { useSetAlert } from '../../../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useTranslation } from 'react-i18next';
import VariantSkeleton from '../../../../common/skeleton-loaders/product-card/variantCardLoader';
import { getResponsiveStyle } from '../../../../../utils/getResponsiveStyle';
import Button from '../../../../common/button/Button';
import { isEevntOngoing } from '../../../../../utils/statusOfEvent/isEventOngoing';
import useGetReviewsAndRating from '../../../../../api/rating/useGetReviewsAndRating';
import { CommentBlock, Loader } from '../../../..';
import { usePrimaryColors } from '../../../../../hooks/usePrimaryColors';

const renderItem = ({ item }: { item: RatingsAndReviews }) => {
	return (
		<CommentBlock
			status={item.review}
			user={item.ratedBy}
			_id={item._id}
			createdAt={item?.createdAt || '1655877090055'}
			rating={item.rating}
			isRatingType
			isProductCardRating
		/>
	);
};

interface Props {
	event?: TGetAllEventCommon | TSingleLiveEventData;
	productData?: TGetAllProductsData;
	allVariants?: Variant[];
	variant: PopulatedVariant;
	loading?: boolean;
	optionValue: string;
	setOptionValue: (val: string) => void;
	onChangeVariant: (variant: Variant, product?: Product) => void;
	setModalState?: (val: boolean) => void;
}
const ProductDescription = ({
	event,
	variant,
	allVariants,
	productData,
	loading = false,
	onChangeVariant,
	optionValue,
	setOptionValue,
	setModalState = () => { },
}: Props) =>
// product: Product
{
	const [createCart, { data: createData, loading: createLoading }] =
		useMutation(CREATE_CART);
	const { t } = useTranslation();
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const userId = useAppSelector(
		state => state.auth.loggedInUserDetails?._id
	);
	const [variants, setVariants] = useState<Variant[]>([]);

	const [ratingsReviews, setRatingsReviews] = useState<
		RatingsAndReviews[]
	>([]);

	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	const primary = usePrimaryColors();

	const { executeGetReviewsAndRating, loading: reviewLoading } =
		useGetReviewsAndRating({
			onCompleted(data) {
				setRatingsReviews(data.getEntityRating.data.data as any);
			},
		});

	useEffect(() => {
		executeGetReviewsAndRating({
			args: {
				find: {
					_id: variant?.associatedProduct?._id,
				},
				populate: ['ratedBy'],
			},
		});
	}, []);

	const { setAlert } = useSetAlert();
	useEffect(() => {
		if (allVariants) {
			setVariants(allVariants);
		} else {
		}
	}, [allVariants]);

	const dispatch = useAppDispatch();
	const usersCart = useAppSelector(state => state.user.cart);
	let inCart = false;
	usersCart.map(cartVariant => {
		if (
			cartVariant.type === 'Product' &&
			cartVariant?.productId?._id === variant?._id
		) {
			if (!cartVariant?.productId?.option?.values?.length) {
				inCart = true;
			} else if (cartVariant?.optionValue === optionValue) {
				inCart = true;
			}
		}
	});

	// const mutateCart = () => {
	// 	createCart({
	// 		variables: {
	// 			args: {
	// 				items: {
	// 					eventId: event?._id,
	// 					quantity: 1,
	// 					productId: variant?._id,
	// 					type: 'Product',
	// 					optionValue,
	// 					price: variant?.option?.values.find(
	// 						value => value?.name === optionValue
	// 					).price,
	// 				},
	// 			},
	// 		},
	// 		awaitRefetchQueries: true,
	// 		refetchQueries: [{ query: GET_CART }],
	// 		onCompleted: data => {
	// 			setModalState(false);
	// 			if (data.createCart.success) {
	// 				setAlert(t('Item Added to Cart'), 'normal');
	// 			} else {
	// 				setAlert(t('Unable to add item to cart'), 'danger');
	// 			}
	// 		},
	// 		onError: () => {
	// 			setAlert(t('Unable to add item to cart'), 'danger');
	// 		},
	// 	});
	// };

	return (
		<>
			<Text style={styles.shortDesc}>
				{variant?.associatedProduct?.name + ' ' + (variant?.value ? variant?.value : "")}
			</Text>
			{variant?.option?.values?.length > 1 && (
				<ScrollView horizontal style={styles.optionValue}>
					{variant.option.values?.map((value, index) => {
						return (
							<Button
								title={value.name}
								key={index}
								variant="outline"
								innerContainerProps={
									optionValue === value.name
										? {
											style: { borderWidth: 2 },
										}
										: {}
								}
								onPress={() => setOptionValue(value.name)}
							/>
						);
					})}
				</ScrollView>
			)}

			{variants && variants.length ? (
				<Text style={styles.title}>{t('OTHER')}:</Text>
			) : (
				<></>
			)}
			<View style={{ flexDirection: 'row' }}>
				{loading ? (
					<>
						<VariantSkeleton />
						<VariantSkeleton />
						<VariantSkeleton />
						<VariantSkeleton />
						<VariantSkeleton />
						<VariantSkeleton />
					</>
				) : (
					<ScrollView horizontal>
						{variants?.map(variantVal => {
							return (
								<Pressable
									onPress={() =>
										onChangeVariant(variantVal)
									}
								>
									<Image
										style={styles.image}
										source={variantVal?.image?.src}
									/>
								</Pressable>
							);
						})}
					</ScrollView>
				)}
			</View>

			<View>
				<Text style={styles.title}>{t('SHORT DESCRIPTION')}</Text>
				<View>
					<Text style={styles.desc}>
						{variant?.associatedProduct?.shortDescription}
					</Text>
				</View>
				<Text style={styles.title}>{t('DESCRIPTION')}</Text>
				<View>
					<Text style={styles.desc}>
						{variant?.associatedProduct?.description}
					</Text>
				</View>
				<Text style={styles.title}>{t('REVIEWS & RATINGS')}</Text>
				{reviewLoading && (
					<Loader
						size="small"
						containerStyles={{ marginBottom: 20 }}
					/>
				)}
				<FlatList
					data={ratingsReviews}
					keyExtractor={(item: any) => item?._id}
					renderItem={renderItem}
					style={{ marginBottom: getResponsiveStyle(10) }}
				/>
			</View>
			{/* <View style={{position:'absolute', bottom:0,}}>
					{inCart ? (
						<View
							style={[
								styles.downloadBtn,
								stylePrimaryBorderColor(primary[0]),
							]}
						>
							<Text
								style={[
									styles.downloadText,
									stylePrimaryColor(primary[0]),
								]}
							>
								{t('Added to Cart')}
							</Text>
						</View>
					) : !inCart && createLoading ? (
						<View
							style={[
								styles.downloadBtn,
								stylePrimaryBorderColor(primary[0]),
							]}
						>
							<Text
								style={[
									styles.downloadText,
									stylePrimaryColor(primary[0]),
								]}
							>
								{t('Adding to Cart...')}
							</Text>
						</View>
					) : (
						<>
							{!inCart &&
								typeOfAccount === 'CONSUMER' &&
								event?.invitedPeople.find(
									user => user._id === userId
								) &&
								event?._id &&
								isEevntOngoing(event) && (
									<View>
										<Button
											title={t('Add to cart')}
											variant={
												variant?.option?.values?.length
													? optionValue
														? 'outline'
														: 'disabled'
													: 'outline'
											}
											onPress={() =>
												variant && mutateCart()
											}
										/>
									</View>
								)}
							{!inCart &&
								typeOfAccount === 'HOST' &&
								!event?._id && (
									<View>
										<Button
											title={t('Add to cart')}
											variant={
												variant?.option?.values?.length
													? optionValue
														? 'outline'
														: 'disabled'
													: 'outline'
											}
											onPress={() =>
												variant && mutateCart()
											}
										/>
									</View>
								)}
						</>
					)}
				</View> */}
		</>
	);
};

const styles = StyleSheet.create({
	image: {
		width: 56,
		height: 56,
		marginRight: 10,
	},
	title: {
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
		marginBottom: 10,
		marginTop: 15,
	},
	optionValue: {
		flexDirection: 'row',
	},
	shortDesc: {
		fontWeight: '400',
		fontSize: 18,
		fontStyle: 'normal',
		lineHeight: 28,
		color: palettes.dark[0],
	},
	desc: {
		fontSize: 14,
		fontWeight: 'normal',
		color: palettes.dark[4],
		lineHeight: 24,
		flex: 1,
	},
	downloadBtn: {
		height: 40,
		justifyContent: 'center',
		alignItems: 'center',
		// border: '1px solid #6645F4',
		borderWidth: 1,
		borderRadius: 6,
		// position: 'absolute',
		// bottom: 10,
		// width: '90%',
	},
	downloadText: {
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: '600',
		// color: '#6645F4',
	},
});

export default ProductDescription;

function stylePrimaryBorderColor(color: string) {
	return {
		borderColor: color,
	};
}

function stylePrimaryColor(color: string) {
	return {
		color: color,
	};
}
