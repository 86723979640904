import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SEARCH_USERS } from '../../graphql/chat/queries';
import { useAppDispatch } from '../../redux/store';

const useSearchUser = () => {
	const dispatch = useAppDispatch();

	const [getConversation, { data, error, loading }] =
		useLazyQuery(GET_SEARCH_USERS);

	const executeSearchQuery = ({
		searchTerm,
		typeOfAccount,
	}: {
		searchTerm: string | undefined;
		typeOfAccount: string;
	}) => {
		getConversation({
			variables: {
				searchTerm,
				typeOfAccount,
			},
		});
	};
	return { executeSearchQuery, data, error, loading };
};

export default useSearchUser;
