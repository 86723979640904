import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native';
import { EventCard, PageHeading, SectionHeading } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { createArray } from '../utils/createArray';

const SaveAs = () => {
	const ToRender = createArray(10);
	return (
		<DesktopTemplate>
			<PageHeading
				heading="Save As"
				subHeading="Create your account to fully experience the app"
			/>
			<View style={styles.section}>
				<SectionHeading heading="Upcoming Event" />
				<ScrollView
					horizontal
					showsHorizontalScrollIndicator={false}
					style={styles.eventRow}
				>
					{ToRender.map((item, index) => (
						<EventCard
							size="small"
							containerStyles={{
								width: 320,
								marginRight:
									ToRender.length - 1 === index ? 0 : 16,
							}}
						/>
					))}
				</ScrollView>
			</View>
			<View style={styles.section}>
				<SectionHeading heading="Past Events" />
				<ScrollView
					horizontal
					showsHorizontalScrollIndicator={false}
					style={styles.eventRow}
				>
					{ToRender.map((item, index) => (
						<EventCard
							size="small"
							containerStyles={{
								width: 320,
								marginRight:
									ToRender.length - 1 === index ? 0 : 16,
							}}
						/>
					))}
				</ScrollView>
			</View>
		</DesktopTemplate>
	);
};

const styles = StyleSheet.create({
	eventRow: {
		flexDirection: 'row',
		maxWidth: 'max-content',
		marginTop: 17,
	},
	section: {
		marginBottom: 37,
	},
});

export default SaveAs;
