import { gql } from '@apollo/client';

export const GET_ALL_HOSTS = gql`
	query getAllHosts(
		$find: UserFilterQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllHosts(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						totalActive
						totalInactive
						newRequest
						data {
							... on User {
								_id
								gender
								username
								email
								gender
								profileStatus
								personalInformation {
									profileImageLink
									firstName
									lastName
									address {
										city
										countryCode
										countryLabel
										zipCode
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_ALL_SELLERS = gql`
	query getAllSellers(
		$find: UserFilterQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllSellers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			statusCode
			success
			message
			data {
				total
				data {
					... on User {
						_id

						username
						email
						gender
						profileStatus
						personalInformation {
							profileImageLink
							firstName
							lastName
							address {
								city
								countryCode
								countryLabel
								zipCode
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_ALL_CONSUMERS = gql`
	query getAllConsumers(
		$find: UserFilterQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllConsumers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			statusCode
			success
			message
			data {
				total
				data {
					... on User {
						_id
						gender
						username
						gender
						email
						profileStatus
						personalInformation {
							profileImageLink
							firstName
							lastName
							address {
								city
								countryCode
								countryLabel
								zipCode
							}
							favouriteCategories
							interestTags
						}
					}
				}
			}
		}
	}
`;

export const IS_FOLLOWING = gql`
	query IsFollowing($args: IsFollowingInput) {
		IsFollowing(args: $args) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on IsFollowingResponse {
								userId
								isFollowing
							}
						}
					}
				}
			}
		}
	}
`;

export const IS_FOLLOWING_ME = gql`
	query IsFollowingMe($args: IsFollowingMeInput) {
		IsFollowingMe(args: $args) {
			statusCode
			success
			message
			data {
				total
				data {
					... on IsFollowingMeResponse {
						userId
						isFollowingMe
					}
				}
			}
		}
	}
`;

export const GET_ALL_MEMBERS = gql`
	query getAllMembers(
		$current: Int
		$limit: Int
		$find: UserQueryParam
		$sort: JSON
	) {
		getAllMembers(
			current: $current
			limit: $limit
			find: $find
			sort: $sort
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on User {
								_id
								username
								gender
								personalInformation {
									profileImageLink
									address {
										city
										countryLabel
										zipCode
										countryCode
									}
								}
								typeOfAccount
								isVerified
							}
						}
					}
				}
			}
		}
	}
`;
