import { useMutation } from '@apollo/client';
import { UPDATE_EVENT } from '../../graphql/events/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { useAppSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { GET_ALL_USERS_BY_IDS } from '../../graphql/auth/queries';

type TParams = {
	onCompleted: (data: TODO) => void;
};

export const useUpdateNewEvent = ({ onCompleted }: TParams) => {
	const updateEventState = useAppSelector(state => state.createEvent);
	const isEditEvent = useAppSelector(state => state.getEvent.isEditEvent);
	const { hostsInvited, hostsInvitedIds } = useAppSelector(state => ({
		hostsInvited: state.addHostToEvent.hostsInvited,
		hostsInvitedIds: state.addHostToEvent.hostsInvitedIds,
	}));
	const event = useAppSelector(state => state.getEvent.event);
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const isHostEditingEvent = useAppSelector(
		state => state.getEvent.isHostEditingEvent
	);

	const onError: ApolloOnErrorFn = error => {
		if (error) {
			setAlert(t('An error occurred!'), 'danger');
		}
	};

	const updateNewEvent: (
		wereHostsSkipped?: boolean,
		isAddingNewHostsAfterEventCreation?: boolean
	) => void = (
		wereHostsSkipped = false,
		isAddingNewHostsAfterEventCreation = false
	) => {
		if (isAddingNewHostsAfterEventCreation) {
			_updateNewEvent({
				refetchQueries: [
					{
						query: GET_ALL_USERS_BY_IDS,
						variables: {
							_ids: hostsInvitedIds,
						},
					},
				],
				variables: {
					_id: event._id,
					args: {
						affiliatedHosts: hostsInvited.map(hosts => hosts._id),
					},
				},
			});
		} else {
			if (updateEventState.typeOfEvent === 'VIRTUAL' && isEditEvent) {
				const _updateEventState = Object.assign({}, updateEventState);
				delete _updateEventState.eventLocation;
				delete _updateEventState.catchmentAreas;
				delete _updateEventState.isHostRequired;
				_updateNewEvent({
					variables: {
						_id: event._id,
						args: _updateEventState,
					},
				});
			} else {
				let _updateEventState = Object.assign({}, updateEventState);
				delete _updateEventState.catchmentAreas;
				delete _updateEventState.isHostRequired;

				if (isHostEditingEvent) {
					_updateEventState.isHostEditingEventLocation = true;
				}
				if (wereHostsSkipped) {
					_updateEventState = {
						..._updateEventState,
						affiliatedHosts: [],
					};
				}
				_updateNewEvent({
					variables: {
						_id: event._id,
						args: _updateEventState,
					},
				});
			}
		}
	};

	const [_updateNewEvent, states] = useMutation<
		TCreateNewEventResponse,
		TUpdateEventInputsWithArgs
	>(UPDATE_EVENT, {
		onCompleted,
		onError,
	});

	return { updateNewEvent, ...states };
};
