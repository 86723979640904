import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { TStyle } from '../../../types/TStyle';

type Props = {
	fill: string;
	containerStyles?: TStyle;
};

const UserFollowIcon = ({ fill, containerStyles }: Props) => {
	return (
		<Svg
			width="15"
			height="16"
			viewBox="0 0 15 16"
			fill="none"
			style={containerStyles}
		>
			<Path
				d="M10.6667 12.3405H10.8167V12.1905V10.0548H12.0405V12.1905V12.3405H12.1905H14.3262V13.5643H12.1905H12.0405V13.7143V15.85H10.8167V13.7143V13.5643H10.6667H8.53095V12.3405H10.6667ZM4.89516 10.1748C5.74602 9.99947 6.62458 10.0135 7.46905 10.2141V11.482C6.81515 11.2831 6.125 11.2283 5.44636 11.3225C4.69811 11.4263 3.9857 11.7082 3.36895 12.1444C2.7522 12.5806 2.24912 13.1584 1.90195 13.8293C1.5778 14.4557 1.39841 15.1462 1.37617 15.8499L0.151852 15.8493C0.17322 14.9933 0.379286 14.1513 0.756636 13.3814C1.15599 12.5665 1.73659 11.8538 2.45394 11.298C3.17129 10.7422 4.00636 10.358 4.89516 10.1748ZM6.09524 8.99286C3.65237 8.99286 1.67381 7.0143 1.67381 4.57143C1.67381 2.12856 3.65237 0.15 6.09524 0.15C8.53811 0.15 10.5167 2.12856 10.5167 4.57143C10.5167 7.0143 8.53811 8.99286 6.09524 8.99286ZM6.09524 7.76905C7.86189 7.76905 9.29286 6.33808 9.29286 4.57143C9.29286 2.80478 7.86189 1.37381 6.09524 1.37381C4.32859 1.37381 2.89762 2.80478 2.89762 4.57143C2.89762 6.33808 4.32859 7.76905 6.09524 7.76905Z"
				fill={fill}
				stroke="white"
				stroke-width="0.3"
			/>
		</Svg>
	);
};

export default UserFollowIcon;
