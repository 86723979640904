import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { UPDATE_MESSAGE_RECEIPT } from '../../graphql/chat/mutation';
import { TUseNavigation } from '../../types/exportedTypes';

const useUpdateReadReceipt = () => {
	const navigation = useNavigation<TUseNavigation>();

	const [updateReadReceipt] = useMutation(UPDATE_MESSAGE_RECEIPT);

	const executeUpdateReadReceiptMutation = ({
		recipients,
		conversationId,
	}: {
		recipients: string;
		conversationId: string;
	}) => {
		updateReadReceipt({
			variables: {
				recipients,
				conversationId,
			},
		});
	};
	return { executeUpdateReadReceiptMutation };
};

export default useUpdateReadReceipt;
