import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LogoutIcon = (props: SvgProps) => (
	<Svg width={18} height={18} fill="none" viewBox="0 0 24 24" {...props}>
		<Path fill="none" d="M0 0h24v24H0z" />
		<Path
			d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z"
			fill="rgba(255,255,255,1)"
		/>
	</Svg>
);

export default LogoutIcon;
