import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
// import Skeleton from '../Skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ContactCardSkeleton = () => {
	return (
		<View style={[styles.userDetail, styles.view]}>
			<View style={{ flexDirection: 'row' }}>
				<Skeleton
					style={{ marginRight: 10, borderRadius: 27 }}
					height={50}
					width={50}
				/>

				<View style={styles.user}>
					<Skeleton
						width={getResponsiveStyle(80)}
						height={getResponsiveStyle(10)}
						style={{ borderRadius: 6 }}
					/>
					<Text style={{ fontSize: 14 }}>
						<Skeleton
							width={getResponsiveStyle(150)}
							height={getResponsiveStyle(8)}
							style={{ borderRadius: 6 }}
						/>
					</Text>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	user: { justifyContent: 'space-evenly' },
	userImg: { marginRight: 10, borderRadius: 27 },
	userDetail: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		padding: 10,
		justifyContent: 'space-between',
	},
	view: {
		...Platform.select({
			web: {
				cursor: 'pointer',
				outlineStyle: 'none',
				borderBottomWidth: 1,
				borderBottomColor: 'transparent',
				transitionDuration: '200ms',
			},
			default: {},
		}),
	},
});

export default ContactCardSkeleton;
