import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { AddUserIcon, RemoveUserIcon } from '../icons';
import { useTranslation } from 'react-i18next';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import Flag from '../flag/Flag';
import { useNavigation, useRoute } from '@react-navigation/native';
import { TUseNavigation, TUseRoute } from '../../../types/exportedTypes';
import { useMutation, useQuery } from '@apollo/client';
import {
	FOLLOW,
	FOLLOW_BRAND,
	UNFOLLOW,
	UNFOLLOW_BRAND,
} from '../../../graphql/follow/mutations';
import { IS_FOLLOWING } from '../../../graphql/network/queries';
import { useFonts } from '../../../hooks/useCutomFonts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import StatusOptionSelector from '../status-ellipsis-selector/StatusEllipsisSelector';
import DeleteModal from '../delete-modal/DeleteModal';
import useDeleteStatus from '../../../api/status/deleteUserStatus';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { setDeletedStatusId } from '../../../redux/statusPostSlice';
import { shortenName } from '../../../utils/manipulateString';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

const AddIconContainer = styled.View<{ primary: string[] }>`
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding-bottom: 4;
	&:hover {
		border-bottom: 1px solid ${({ primary }) => primary[0]};
	}
`;

type Props = {
	name?: string;
	userId?: string;
	postId?: string;
	profileImage: string | undefined;
	isBrandProfile?: boolean;
	id?: string;
	index?: number;
	containerStyles?: TStyle;
	hasAddIcon?: boolean;
	time?: string;
	brandDetails?: any;
	countryCode?: string;
	countryLabel?: string;
	hasAccountVerified?: boolean;
	country?: string;
	city?: string;
	isFollowing?: boolean;
	isGuest?: boolean;
	onClickFollowMeActionButton?: (
		recipientId: string,
		hasRequestBeenSent: boolean,
		index: number
	) => void;
	onClickUnFollowActionButton?: (unFollowId: string) => void;
	followMeUnfollowMeButtonPressedId?: string;
	loadingFollowMe?: boolean;
	setSelected?: (userId: string) => void;
	requestSendBooleanArray?: TIsFollowMeRequestData[];
	isFollowingMeArray?: TODO[];
	loadingUnFollowMe?: boolean;
	isRatingType?: boolean;
};

const Profile = ({
	name,
	userId,
	postId,
	profileImage,
	index,
	isBrandProfile,
	hasAddIcon = false,
	hasAccountVerified = false,
	time,
	id,
	brandDetails,
	countryCode,
	countryLabel,
	containerStyles,
	country,
	city,
	isFollowing,
	isGuest,
	onClickFollowMeActionButton,
	onClickUnFollowActionButton,
	followMeUnfollowMeButtonPressedId,
	loadingFollowMe,
	loadingUnFollowMe,
	setSelected,
	requestSendBooleanArray,
	isFollowingMeArray,
	isRatingType,
}: Props) => {
	const hasRequestBeenSent = requestSendBooleanArray?.find(
		i => i.userId === id
	)?.isFollowRequestSent;

	const isFollowingMe = isFollowingMeArray?.find(
		i => i.userId === id
	)?.isFollowingMe;

	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();
	const [isProfileOption, setIsProfileOption] = useState(false);
	const [seletedProfileOption, setSelectedProfileOption] = useState('');
	const [isBrandFollowed, setIsBrandFollowed] = useState<boolean>();
	const [checked, setChecked] = useState(false);
	const [reminderChecked, setReminderChecked] = useState('');
	useEffect(() => {
		setIsBrandFollowed(isFollowing);
	}, [isFollowing]);

	const { loggedInUserDetails } = useAppSelector(state => state.auth);
	const { isDesktop, isLessThanDesktopBase } = useIsSpecificViewportWidth();

	// profileObj && setIsBrandFollowed(profileObj.isFollowing)
	const [followAccount, { loading: loadingFollow }] = useMutation<
		TFollowResponse,
		TFollowInputs
	>(FOLLOW, {
		onCompleted: data => {
			if (data.Follow.success && data.Follow.statusCode === 200) {
				setIsBrandFollowed(true);
			}
		},
	});
	const [unFollowAccount, { loading: loadingUnfollow }] = useMutation<
		TUnfollowResponse,
		TUnfollowInputs
	>(UNFOLLOW, {
		onCompleted: data => {
			if (data.UnFollow.success && data.UnFollow.statusCode === 200) {
				setIsBrandFollowed(false);
			}
		},
	});
	const [followBrandAccount, { loading: loadingBrandFollow }] = useMutation<
		TFollowBrandResponse,
		TFollowInputs
	>(FOLLOW_BRAND, {
		onCompleted: data => {
			if (
				data.FollowBrand.success &&
				data.FollowBrand.statusCode === 200
			) {
				setIsBrandFollowed(true);
			}
		},
	});

	const [unFollowBrandAccount, { loading: loadingBrandUnfollow }] =
		useMutation<TUnfollowBrandResponse, TUnfollowInputs>(UNFOLLOW_BRAND, {
			onCompleted: data => {
				if (data && data.UnFollowBrand && data.UnFollowBrand.success) {
					setIsBrandFollowed(false);
				}
			},
		});

	/******************** END  -  API - unfollow ***********************/

	const ids = useAppSelector(state => state.user.followedIds);
	const followUnfollowMutationFunction = () => {
		if (!id) return;
		isBrandFollowed
			? isBrandProfile
				? unFollowBrandAccount({
						variables: {
							args: {
								followedId: id,
							},
						},
						awaitRefetchQueries: true,
						refetchQueries: [
							{
								query: IS_FOLLOWING,
								variables: {
									args: {
										type: 'BRAND',
										followedIds: ids,
									},
								},
							},
						],
				  })
				: unFollowAccount({
						variables: {
							args: {
								followedId: id,
							},
						},
						awaitRefetchQueries: true,
						refetchQueries: [
							{
								query: IS_FOLLOWING,
								variables: {
									args: {
										type: 'USER',
										followedIds: ids,
									},
								},
							},
						],
				  })
			: isBrandProfile
			? followBrandAccount({
					variables: {
						args: {
							followedId: id,
						},
					},
					awaitRefetchQueries: true,
					refetchQueries: [
						{
							query: IS_FOLLOWING,
							variables: {
								args: {
									type: 'BRAND',
									followedIds: ids,
								},
							},
						},
					],
			  })
			: followAccount({
					variables: {
						args: {
							followedId: id,
						},
					},
					awaitRefetchQueries: true,
					refetchQueries: [
						{
							query: IS_FOLLOWING,
							variables: {
								args: {
									type: 'USER',
									followedIds: ids,
								},
							},
						},
					],
			  });
		onClickUnFollowActionButton && onClickUnFollowActionButton(id);
	};
	const primaryColors = usePrimaryColors();

	useEffect(() => {
		useFonts().then(() => null);
	}, []);

	const { setAlert } = useSetAlert();

	const {
		executeDeleteStatusMutation,
		loading: loading_delete,
		data: deleteStatus,
	} = useDeleteStatus();

	useEffect(() => {
		if (deleteStatus?.deleteStatus?.success) {
			setSelectedProfileOption('');
			setAlert(t('Deleted Successfully'), 'normal');
		}
	}, [deleteStatus]);

	const dispatch = useAppDispatch();

	const handleDelete = () => {
		executeDeleteStatusMutation({ _id: postId as string });
		dispatch(setDeletedStatusId(postId as string));
		if (checked) handleIsCheckPostDelete();
	};

	const handleIsCheckPostDelete = async () => {
		await AsyncStorage.setItem('isCheckPostDelete', 'true');
	};

	useEffect(() => {
		const getCheckedState = async () => {
			const value = await AsyncStorage.getItem('isCheckPostDelete');
			setReminderChecked(value as string);
		};
		getCheckedState();
	}, []);

	return (
		<View style={[styles.container, containerStyles]}>
			<TouchableOpacity
				onPress={() =>
					id
						? isBrandProfile && brandDetails
							? navigation.navigate('BrandDetails', {
									_id: id,
									brandDetails: brandDetails,
							  })
							: navigation.navigate('UserProfile', {
									userId: id,
							  })
						: null
				}
				style={{ maxWidth: 250 }}
			>
				<View style={styles.imageNameLocationContainer}>
					{profileImage ? (
						<Image
							source={{ uri: profileImage }}
							style={[styles.profileImage, styleProfileImage()]}
						/>
					) : (
						<View
							style={[
								styles.imageContainer,
								styles.profileImage,
								styleProfileImage(),
							]}
						>
							<Image
								source={require('../../../assets/defaultProfileImage.svg')}
								style={{ width: 30, height: 30 }}
							/>
						</View>
					)}

					<View style={styles.nameLocationContainer}>
						<Text
							style={[styles.name, styleName()]}
							numberOfLines={1}
						>
							{name}
						</Text>
						{!isBrandProfile && (
							<>
								<Text
									style={[styles.location, styleLocation()]}
									numberOfLines={2}
								>
									<Flag countryCode={countryCode} />
									{shortenName(city, 8)},{' '}
									{shortenName(countryLabel ?? ' ', 10)}
								</Text>
							</>
						)}
						{hasAccountVerified && !hasAddIcon && !time && (
							<View style={styleAccountVerifiedContainer()}>
								<Text
									style={[
										styles.accountVerified,
										styleAccountVerified(primaryColors),
									]}
								>
									{t('Account Verified')}
								</Text>
							</View>
						)}
					</View>
				</View>
			</TouchableOpacity>

			{/* <Button
							title={isUserFollowed ? t('Unfollow') : t('Follow')}
							variant={followLoading ? 'disabled' : 'outline'}
							size="sm"
							onPress={() => followUser && followUser(_id ?? '')}
							loading={followLoading}
						/> */}

			{hasAddIcon && !hasAccountVerified && !time && (
				<View style={styles.addUserContainer}>
					{!isGuest ? (
						<AddIconContainer primary={primaryColors}>
							{isBrandFollowed ? (
								<RemoveUserIcon style={styleAddUserIcon()} />
							) : (
								<AddUserIcon style={styleAddUserIcon()} />
							)}
							<TouchableOpacity
								onPress={() => followUnfollowMutationFunction()}
							>
								<Text style={styleAddUserText(primaryColors)}>
									{isBrandFollowed &&
									!loadingFollow &&
									!loadingUnfollow &&
									!loadingBrandFollow &&
									!loadingBrandUnfollow
										? t('Unfollow')
										: !isBrandFollowed &&
										  !loadingFollow &&
										  !loadingBrandFollow &&
										  !loadingBrandUnfollow &&
										  !loadingUnfollow &&
										  t('Follow')}
									{loadingFollow || loadingBrandFollow
										? t('Following') + '...'
										: (loadingUnfollow ||
												loadingBrandUnfollow) &&
										  t('Unfollowing') + '...'}
								</Text>
							</TouchableOpacity>
						</AddIconContainer>
					) : (
						<>
							{
								hasRequestBeenSent ? (
									<Text style={styles.requestSent}>
										{t('Request sent')}
									</Text>
								) : isFollowingMe ? (
									<AddIconContainer primary={primaryColors}>
										<RemoveUserIcon
											style={styleAddUserIcon()}
										/>
										<Text
											style={styleAddUserText(
												primaryColors
											)}
											onPress={() => {
												onClickFollowMeActionButton &&
													onClickFollowMeActionButton(
														id ?? '',
														true,
														index ?? 0
													);
											}}
										>
											{followMeUnfollowMeButtonPressedId ===
												id && loadingUnFollowMe === true
												? t('Removing user...')
												: t('Unfollow Me')}
											{/* Unfollow Me */}
										</Text>
									</AddIconContainer>
								) : (
									!isFollowingMe && (
										<AddIconContainer
											primary={primaryColors}
										>
											<AddUserIcon
												style={styleAddUserIcon()}
											/>
											<Text
												style={styleAddUserText(
													primaryColors
												)}
												onPress={() => {
													onClickFollowMeActionButton &&
														onClickFollowMeActionButton(
															id ?? '',
															false,
															index ?? 0
														);
												}}
											>
												{followMeUnfollowMeButtonPressedId ===
													id &&
												loadingFollowMe === true
													? t('Sending request')
													: t('Follow Me')}
												{/* Follow Me */}
											</Text>
										</AddIconContainer>
									)
								)

								// (followMeUnfollowMeButtonPressedId === id && loadingFollowMe === true) ?
								// <Text>Sending request...</Text> :
								// (followMeUnfollowMeButtonPressedId === id && loadingUnFollowMe === true) &&
								// <Text>Removing user</Text>
							}
						</>
					)}
				</View>
			)}
			{time && !hasAddIcon && !hasAccountVerified && (
				<View style={styles.timeContainer}>
					<Text style={[styles.timeText, styleTimeText()]}>
						{time}
					</Text>
					{id === loggedInUserDetails?._id && !isRatingType && (
						<StatusOptionSelector
							handleClick={() =>
								setIsProfileOption(!isProfileOption)
							}
							openState={isProfileOption}
							handleOptionSelect={value => {
								if (reminderChecked === 'true') {
									handleDelete();
								} else {
									setSelectedProfileOption(value);
								}
							}}
						/>
					)}
				</View>
			)}
			{seletedProfileOption === 'delete' && (
				<View style={{ position: 'absolute' }}>
					<DeleteModal
						checked={checked}
						onConfirmCheck={() => setChecked(!checked)}
						loading={loading_delete}
						isOpen={seletedProfileOption === 'delete'}
						onCancelDelete={() => setSelectedProfileOption('')}
						header={'Delete Post'}
						info={'Are you sure you want to delete this post?'}
						onConfirmDelete={handleDelete}
					/>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	accountVerified: {
		fontWeight: '700',
	},
	requestSent: {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		color: '#7C889B',
	},
	imageContainer: {
		borderRadius: 9999,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	profileImage: {
		borderRadius: 9999,
		backgroundColor: palettes.grey[10],
	},
	imageNameLocationContainer: {
		flexDirection: 'row',
		maxWidth: 250,
	},
	nameLocationContainer: {
		marginTop: 5,
		width: '100%',
	},
	name: {
		fontWeight: '700',
		color: palettes.dark[0],
		width: 130,
	},
	location: {
		color: palettes.grey[0],
	},
	addUserContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	timeContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	timeText: {
		textAlign: 'right',
		color: palettes.grey[0],
	},
});

export default Profile;
function styleTimeText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
	};
}

function styleAddUserText(primary: string[]) {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		color: primary[0],
		// width: 100,
		// display:'flex',
		// flexDirection:'row',
		// justifyContent: 'flex-end'
	};
}

function styleAddUserIcon() {
	return {
		width: getResponsiveStyle(14, 'dimensions'),
		height: getResponsiveStyle(16, 'dimensions'),
		marginRight: getResponsiveStyle(5),
	};
}

function styleAccountVerified(primary: string[]) {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		color: primary[0],
	};
}

function styleAccountVerifiedContainer() {
	return { marginTop: getResponsiveStyle(4) };
}

function styleLocation() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
	};
}

function styleProfileImage() {
	return {
		width: getResponsiveStyle(40, 'dimensions'),
		height: getResponsiveStyle(40, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleName() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: getResponsiveStyle(2),
		maxWidth: 180,
		// fontFamily: 'maisonNeue',
	};
}
