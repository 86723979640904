import { useMutation, useQuery } from '@apollo/client';
// import { GET_NOTIFICATIONS } from '../../../graphql/common/queries';
import React, { useCallback, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import {
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { NotificationBellIcon } from '../icons';
import SectionHeading from '../SectionHeading';
import Loader from '../loader/Loader';
import { GET_ALL_NOTIFICATIONS } from '../../../graphql/notification/queries';
import {
	DELETE_ALL_NOTIFICATIONS,
	READ_NOTIFICATIONS,
} from '../../../graphql/notification/mutation';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setNoOfNotifications } from '../../../redux/uiSlice';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { NotAvailableMessage } from '../..';
import NotificationLayouts from './NotificationLayouts';
import { useTranslation } from 'react-i18next';
import { useSetAlert } from '../../../hooks/useSetAlerts';

const NotificationIconWithDropdown = () => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const [open, setOpen] = useState(false);
	const dispatch = useAppDispatch();
	const { loading: notificationLoading, data: notificationData } = useQuery(
		GET_ALL_NOTIFICATIONS
	);

	const { t } = useTranslation();
	const notifications_: [NotificationType] =
		notificationData?.getAllNotifications?.data?.data;

	const [notifications, setNotifications] = useState<TODO>([]);

	const noOfRead = useAppSelector(state => state.ui.noOfNotifications);
	const primary = usePrimaryColors();
	const [viewAll, setViewAll] = useState(false);

	const { setAlert } = useSetAlert();
	useEffect(() => {
		if (notifications_ && notifications.length !== notifications_.length) {
			setNotifications(notifications_);
		}
	}, [notifications_]);

	const [readNotifications] = useMutation(READ_NOTIFICATIONS, {
		onCompleted: () => {
			dispatch(setNoOfNotifications(0));
		},
	});

	const [deleteAllNotifications, { loading: deleteLoading }] = useMutation(
		DELETE_ALL_NOTIFICATIONS,
		{
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
			onCompleted: data => {
				if (data && data.deleteAllNotifications.success) {
					setAlert(
						t('All Notifications successfully deleted'),
						'normal'
					);
				} else {
					setAlert(t('Could not delete notifications'), 'danger');
				}
			},
			onError: () => {
				setAlert(t('Could not delete notifications'), 'danger');
			},
		}
	);

	const removeFromNotification = useCallback((id: string) => {
		setNotifications((prev: NotificationType[]) =>
			prev.filter(notification => notification._id !== id)
		);
	}, []);
	const aDay = 86400000;
	const twoDays = 172800000;
	const todayNotification =
		notifications &&
		notifications.length > 0 &&
		notifications.filter(
			(not: NotificationType) => Date.now() - Number(not.createdAt) < aDay
		);
	const yesterdayNotifications =
		notifications &&
		notifications.length > 0 &&
		notifications.filter(
			(notification: NotificationType) =>
				Date.now() - Number(notification.createdAt) >= aDay &&
				Date.now() - Number(notification.createdAt) < twoDays
		);

	const moreNotifications =
		notifications &&
		notifications.length > 0 &&
		notifications.filter(
			(notification: NotificationType) =>
				Date.now() - Number(notification.createdAt) >= twoDays
		);

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<View
				style={[
					styles.container,
					styles.notificationIconContainer,
					styleNotificationIconContainer(isLessThanDesktopBase),
				]}
			>
				<NotificationBellIcon
					onPressIcon={() => {
						setOpen(prev => !prev);
						readNotifications();
					}}
					style={styleNotificationBellIcon(isLessThanDesktopBase)}
				/>
				{open && (
					<View
						style={[
							styles.dropdownContainer,
							styleDropdownContainer(),
						]}
					>
						{notificationLoading || deleteLoading ? (
							<View style={{ marginTop: 20, marginBottom: 20 }}>
								<Loader size={'small'} />
							</View>
						) : (
							<>
								<TouchableOpacity
									style={styles.notificationHeading}
								>
									<SectionHeading
										heading={`${t('Notifications')}`}
										size="small"
										containerStyles={styleHeading()}
									/>
									{notifications &&
										notifications.length > 0 &&
										(moreNotifications.length > 0 ? (
											<Text
												onPress={() =>
													!viewAll &&
													(todayNotification.length >
														0 ||
														yesterdayNotifications.length >
															0)
														? setViewAll(!viewAll)
														: deleteAllNotifications()
												}
												style={{
													color: palettes.grey[1],
													paddingRight: 20,
													marginTop: 10,
												}}
											>
												{viewAll
													? t('Clear All')
													: todayNotification.length >
															0 ||
													  yesterdayNotifications.length >
															0
													? t('View All')
													: t('Clear All')}
											</Text>
										) : (
											<Text
												onPress={() =>
													deleteAllNotifications()
												}
												style={{
													color: palettes.grey[1],
													paddingRight: 20,
													marginTop: 10,
												}}
											>
												{t('Clear All')}
											</Text>
										))}
								</TouchableOpacity>
								{notifications.length > 0 ? (
									<ScrollView
										contentContainerStyle={{
											height: '50vh',
										}}
									>
										{todayNotification.length > 0 && (
											<View style={styles.dateHeader}>
												<Text style={styles.dateText}>
													{t('Today')}
												</Text>
											</View>
										)}

										<>
											{notifications &&
												notifications.length > 0 &&
												// show notification based on type
												todayNotification.map(
													(
														notification: NotificationType
													) => {
														return (
															<NotificationLayouts
																notification={
																	notification
																}
																removeFromNotification={
																	removeFromNotification
																}
															/>
														);
													}
												)}
										</>

										{yesterdayNotifications.length > 0 && (
											<View style={styles.dateHeader}>
												<Text style={styles.dateText}>
													{t('Yesterday')}
												</Text>
											</View>
										)}

										{/* yesterday's notifications */}

										<>
											{notifications &&
												notifications.length > 0 &&
												// show notification based on type
												yesterdayNotifications.map(
													(
														notification: NotificationType
													) => {
														return (
															<NotificationLayouts
																notification={
																	notification
																}
																removeFromNotification={
																	removeFromNotification
																}
															/>
														);
													}
												)}
										</>
										{viewAll && (
											<View style={styles.dateHeader}>
												<Text style={styles.dateText}>
													{t('More')}
												</Text>
											</View>
										)}
										<>
											{(notifications && viewAll) ||
											(todayNotification.length === 0 &&
												yesterdayNotifications.length ===
													0 &&
												notifications.length > 0)
												? // show notification based on type
												  moreNotifications.map(
														(
															notification: NotificationType
														) => {
															return (
																<NotificationLayouts
																	notification={
																		notification
																	}
																	removeFromNotification={
																		removeFromNotification
																	}
																/>
															);
														}
												  )
												: null}
										</>
									</ScrollView>
								) : (
									<View style={{ marginLeft: 25 }}>
										<NotAvailableMessage
											message={t('No notification')}
										/>
									</View>
								)}
							</>
						)}
					</View>
				)}
				{noOfRead !== 0 && (
					<Text
						style={[
							styles.notifications,
							styleNewNotification(primary),
						]}
					>
						{noOfRead < 10 ? `0${noOfRead}` : noOfRead}
					</Text>
				)}
			</View>
		</ClickAwayListener>
	);
};

function styleNewNotification(primary: string[]) {
	return {
		marginLeft: getResponsiveStyle(0),
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		color: primary[0],
	};
}

function styleHeading(): TStyleInputOrText {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingTop: getResponsiveStyle(24),
		marginBottom: getResponsiveStyle(14),
	};
}

function styleNotificationBellIcon(isLessThanDesktopBase: boolean) {
	return {
		width: getResponsiveStyle(20, 'dimensions'),
		height: getResponsiveStyle(20, 'dimensions'),
		marginTop: isLessThanDesktopBase ? 0 : getResponsiveStyle(5),
	};
}

function styleNotificationIconContainer(
	isLessThanDesktopBase: boolean
): TStyle {
	return {
		paddingRight: isLessThanDesktopBase ? 0 : getResponsiveStyle(8),
		width: isLessThanDesktopBase ? 0 : 48,
		borderRightWidth: isLessThanDesktopBase ? 0 : 1,
		marginLeft: isLessThanDesktopBase ? 0 : getResponsiveStyle(21),
	};
}

function styleDropdownContainer(): TStyle {
	return {
		minHeight: getResponsiveStyle(224, 'dimensions'),
		width: getResponsiveStyle(320, 'dimensions'),
		minWidth: getResponsiveStyle(320, 'dimensions'),
		top: getResponsiveStyle(50, 'dimensions'),
		paddingBottom: getResponsiveStyle(8),
		right: 0,
	};
}

export default NotificationIconWithDropdown;

const styles = StyleSheet.create({
	notifications: {
		fontWeight: '600',
	},
	eventContainer: {
		borderTopWidth: 1,
		borderTopColor: palettes.grey[5],
	},
	eventHeadingStyle: {
		fontWeight: '600',
	},
	container: {
		position: 'relative',
		display: 'flex',
		// paddingTop: 7,
		flexDirection: 'row',
		boxSizing: 'border-box',
	},
	dateHeader: {
		paddingHorizontal: getResponsiveStyle(25),
		paddingVertical: getResponsiveStyle(13),
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
	notificationHeading: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignContent: 'center',
		alignItems: 'center',
	},
	dateText: {
		fontSize: getResponsiveStyle(12, 'font'),
		fontWeight: '600',
		lineHeight: getResponsiveStyle(16, 'font'),
		color: palettes.dark[4],
	},
	dropdownContainer: {
		height: 'fit-content',
		position: 'absolute',
		zIndex: 9999,
		backgroundColor: 'white',
		borderWidth: 1,
		borderColor: palettes.grey[5],
		shadowOffset: {
			width: 0,
			height: 30,
		},
		shadowRadius: 40,
		shadowColor: 'rgba(124, 136, 155, 0.18)',
		shadowOpacity: 1,
		borderRadius: 6,
	},
	notificationIconContainer: {
		borderRightColor: palettes.grey[7],
	},
	gradientBox: {
		width: '100%',
		height: 70,
		position: 'absolute',
		bottom: 0,
	},
});
