import {
	DailyCall,
	DailyParticipant,
	DailyParticipantsObject,
} from '@daily-co/daily-js';
import { TFunction } from 'react-i18next';
import { isEventPublished } from '../../../../utils/statusOfEvent/isEventPublished';
import {
	TInitializeIVSPlayerEventsArgs,
	TInitializeLiveStreamEvents,
	TProfileCardDetailsGetter,
	TProfileCardDetailsGetterReturnType,
} from './LiveEventSection.types';

const LOCK_DIMENSIONS = {
	livestreamArea: {
		icon: {
			height: 36,
			width: 33,
		},
		background: {
			height: 140,
			width: 140,
		},
	},
	descriptionArea: {
		icon: {
			height: 36,
			width: 33,
		},
		background: {
			height: 84,
			width: 84,
		},
	},
};

const initialCallItemState = {
	local: {
		videoTrackState: null,
		audioTrackState: null,
	},
};

const checkCallObjectForNullUndefined = (dailyJsObject: DailyCall) => {
	return (
		!dailyJsObject.participants() || !dailyJsObject.participants()['local']
	);
};

const isAudioEnabledForHostSeller = (dailyJsObject: DailyCall | null) => {
	if (
		!dailyJsObject ||
		checkCallObjectForNullUndefined(dailyJsObject) ||
		dailyJsObject.participants()['local']?.audio === undefined
	)
		return false;

	return dailyJsObject.participants()['local'].audio;
};

const isVideoEnabledForHostSeller = (dailyJsObject: DailyCall | null) => {
	if (
		!dailyJsObject ||
		checkCallObjectForNullUndefined(dailyJsObject) ||
		dailyJsObject.participants()['local']?.video === undefined
	)
		return false;

	return dailyJsObject.participants()['local'].video;
};

const getRtmpUrl = (liveStream: TLiveStream) => {
	return `rtmps://${liveStream.stream.channel.ingestEndpoint}:443/app/${liveStream.stream.streamKey.value}`;
};

const isAccountHostOrSeller = (typeOfAccount?: TTypeOfAccount) => {
	if (!typeOfAccount) return false;
	return typeOfAccount === 'HOST' || typeOfAccount === 'SELLER';
};

const getRatingEntity = (
	eventDetails: TSingleLiveEventData,
	typeOfAccount: TTypeOfAccount | undefined
) => {
	if (typeOfAccount === 'SELLER') {
		return [
			{
				type: 'HOST',
				_id: eventDetails?.affiliatedHosts[0]?._id,
			},
		];
	} else if (typeOfAccount === 'HOST') {
		return [
			{
				type: 'SELLER',
				_id: eventDetails?.affiliatedSeller?._id,
			},
		];
	} else {
		return eventDetails?.affiliatedHosts[0]?._id
			? [
					{
						type: 'SELLER',
						_id: eventDetails?.affiliatedSeller?._id,
					},
					{
						type: 'HOST',
						_id: eventDetails?.affiliatedHosts[0]?._id,
					},
			  ]
			: [
					{
						type: 'SELLER',
						_id: eventDetails?.affiliatedSeller?._id,
					},
			  ];
	}
};

// Daily example code
const getDailyCallItems = (participants: DailyParticipantsObject) => {
	let callItems: any = { ...initialCallItemState };
	for (const [id, participant] of Object.entries(participants)) {
		callItems[id] = {
			videoTrackState: participant.tracks.video,
			audioTrackState: participant.tracks.audio,
		};
		if (shouldIncludeScreenCallItem(participant)) {
			callItems[id + '-screen'] = {
				videoTrackState: participant.tracks.screenVideo,
				audioTrackState: participant.tracks.screenAudio,
			};
		}
	}
	return callItems;
};

// Daily example code
const shouldIncludeScreenCallItem = (participant: DailyParticipant) => {
	const trackStatesForInclusion = ['loading', 'playable', 'interrupted'];
	return (
		trackStatesForInclusion.includes(
			participant.tracks.screenVideo.state
		) ||
		trackStatesForInclusion.includes(participant.tracks.screenAudio.state)
	);
};

const getLiveEventSectionTabs = (
	t: TFunction,
	tabType: 'section' | 'participant'
) => {
	if (tabType === 'section') {
		return [
			{ label: t('Live'), value: 'Live' },
			{ label: t('Products'), value: 'Products' },
			{ label: t('Participants'), value: 'Participants' },
			{ label: t('About'), value: 'About' },
		];
	} else {
		return [
			{ label: t('Chat'), value: 'Chat' },
			{ label: t('People'), value: 'People' },
		];
	}
};

const getHostRequiredConsumerProfileCardDetails = (
	affiliatedHosts: TLiveEventAffiliatedHosts
): TProfileCardDetailsGetterReturnType => {
	return {
		username: affiliatedHosts.username,
		personalInformation: affiliatedHosts.personalInformation,
	};
};

const getLoggedInUserProfileCardDetails = (
	loggedInUserDetails: TCommonResponseData | undefined
): TProfileCardDetailsGetterReturnType => {
	const personalInformation = {
		profileImageLink:
			loggedInUserDetails?.personalInformation.profileImageLink ?? '',
	};
	return {
		username: loggedInUserDetails?.username ?? '',
		personalInformation,
	};
};

const getHostNotRequiredConsumerProfileCardDetails = (
	affiliatedSeller: TLiveEventAffiliatedSeller
): TProfileCardDetailsGetterReturnType => {
	return {
		username: affiliatedSeller.username,
		personalInformation: affiliatedSeller.personalInformation,
	};
};

const getProfileCardDetails: TProfileCardDetailsGetter = (
	eventDetails,
	typeOfAccount,
	loggedInUserDetails
) => {
	if (eventDetails.isHostRequired) {
		if (typeOfAccount === 'CONSUMER') {
			return getHostRequiredConsumerProfileCardDetails(
				eventDetails.affiliatedHosts[0]
			);
		} else {
			return getLoggedInUserProfileCardDetails(loggedInUserDetails);
		}
	} else {
		if (typeOfAccount === 'CONSUMER' || !typeOfAccount) {
			return getHostNotRequiredConsumerProfileCardDetails(
				eventDetails.affiliatedSeller
			);
		} else {
			return getLoggedInUserProfileCardDetails(loggedInUserDetails);
		}
	}
};

const initializeIVSPlayerEvents = ({
	t,
	player,
	navigation,
	setAlert,
	eventDetails,
	dispatch,
	isStreamEnded,
}: TInitializeIVSPlayerEventsArgs) => {
	player.addEventListener('PlayerInitialized', () => {});

	player.addEventListener('Ready', () => {});

	player.addEventListener('Playing', () => {
		dispatch({
			type: 'SET_IVS_PLAYER_LOADING_FALSE',
		});

		dispatch({
			type: 'HOST_OR_SELLER_ACTIVE_CONSUMER',
			payload: true,
		});
	});

	player.addEventListener('Idle', () => {
		dispatch({
			type: 'SET_IS_LIVESTREAM_IDLE_FOR_CONSUMER',
			payload: true,
		});
	});

	player.addEventListener('Ended', () => {
		dispatch({
			type: 'SET_IS_LIVESTREAM_IDLE_FOR_CONSUMER',
			payload: true,
		});

		dispatch({
			type: 'HOST_OR_SELLER_ACTIVE_CONSUMER',
			payload: false,
		});
	});

	player.addEventListener('PlayerError', (error: any) => {
		dispatch({
			type: 'SET_IVS_PLAYER_LOADING_FALSE',
		});
		if (!isStreamEnded) {
			if (
				error.type === 'ErrorNotAvailable'
				// && isEventDone(eventDetails)
			) {
				// setAlert(t('Live stream not available!'), 'danger');
				dispatch({
					type: 'SET_IS_LIVESTREAM_IDLE_FOR_CONSUMER',
					payload: true,
				});
			} else if (
				error.type === 'ErrorNotAvailable' &&
				isEventPublished(eventDetails)
			) {
				setAlert(t('Unable to start live event!'), 'danger');
				navigation.navigate('Events');
			} else {
				setAlert(
					t('Error occurred while loading live stream!'),
					'danger'
				);
				navigation.navigate('Events');
			}
		}
	});

	player.addEventListener('PlayerNetworkUnavailable', () => {
		setAlert(t('An error occurred! Try again later.'), 'danger');
		navigation.navigate('Events');
	});

	player.addEventListener('PlayerVolumeChanged', () => {
		const volumeOfPlayer: number = player.getVolume();
		dispatch({
			type: 'IVS_PLAYER_VOLUME_CHANGE',
			payload: volumeOfPlayer,
		});
	});
};

//TODO: add clean up
export const initializeLiveStreamEvents = ({
	dailyJsObject,
	setAlert,
	cleanUpBeforeUnmount,
	startLeavingCall,
	t,
	dispatch,
}: TInitializeLiveStreamEvents) => {
	dailyJsObject.on('joined-meeting', () => {
		dispatch({
			type: 'DAILY_ON_JOIN_MEETING',
		});
	});

	dailyJsObject.on('left-meeting', () => {
		dailyJsObject.destroy().finally(() => {
			dispatch({
				type: 'DAILY_ON_LEFT_MEETING',
			});
		});
	});

	dailyJsObject.on('live-streaming-started', () => {
		dispatch({
			type: 'DAILY_ON_LIVE_STREAM_STARTED',
		});
	});

	dailyJsObject.on('live-streaming-stopped', () => {
		dispatch({
			type: 'DAILY_ON_LIVE_STREAM_STOPPED',
		});
	});

	dailyJsObject.on('recording-started', data => {
		if (data?.action === 'recording-started') {
			dispatch({
				type: 'RECORDING_STARTED',
				payload: data.recordingId,
			});
		}
		console.log({ recordingStartData: data });
	});

	dailyJsObject.on('recording-error', error => {
		console.log({ recordingError: error });
	});

	dailyJsObject.on('loaded', () => {
		dispatch({
			type: 'DAILY_ON_LOADED',
		});
	});

	dailyJsObject.on('started-camera', () => {
		dispatch({
			type: 'DAILY_ON_STARTED_CAMERA',
		});
	});
	dailyJsObject.on('live-streaming-error', callObjectError => {
		console.log({ callObjectError });

		dispatch({
			type: 'DAILY_ON_LIVE_STREAM_STOPPED',
		});
		setAlert(t('Unable to start live stream!'), 'danger');
	});

	dailyJsObject.on('camera-error', () => {
		dispatch({
			type: 'DAILY_ON_CAMERA_ERROR',
		});
		cleanUpBeforeUnmount();
		setAlert(t('Unable to detect camera!'), 'danger');
		startLeavingCall();
	});

	dailyJsObject.on('error', e => {
		dispatch({
			type: 'DAILY_ON_ERROR',
		});
		cleanUpBeforeUnmount();
		setAlert(e?.errorMsg ?? t('An error occurred! Try again'), 'danger');
	});
};

export {
	getRtmpUrl,
	getRatingEntity,
	LOCK_DIMENSIONS,
	getDailyCallItems,
	isAccountHostOrSeller,
	getProfileCardDetails,
	getLiveEventSectionTabs,
	initializeIVSPlayerEvents,
	isAudioEnabledForHostSeller,
	isVideoEnabledForHostSeller,
	checkCallObjectForNullUndefined,
};
