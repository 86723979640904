import { Text, StyleSheet } from 'react-native';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	name: string;
};

export const HostSellerName = ({ name }: Props) => {
	return <Text style={styles.text}>{name}</Text>;
};

const styles = StyleSheet.create({
	text: {
		fontWeight: '700',
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(14),
		color: 'white',
		marginBottom: 6,
	},
});
