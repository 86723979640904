import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, Linking } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { FILELINKS } from '../../../constants/KEYS';
import { TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const TermsAndConditions = () => {
	const handlePress = (url: string) => {
		Linking.canOpenURL(url).then(supported => {
			return Linking.openURL(url);
		});
	};
	const Link = styled.Text`
		cursor: pointer;
		color: ${palettes.grey[0]};
		width: auto;
		&:hover {
			text-decoration: underline;
		}
	`;
	const { t } = useTranslation();
	return (
		<Link
			style={[styles.termsAndConditions, styleTermsAndConditions()]}
			onPress={() => handlePress(FILELINKS.TOC)}
		>
			{t('Terms and Conditions')}
		</Link>
	);
};

export default TermsAndConditions;
function styleTermsAndConditions(): TStyleInputOrText {
	return {
		fontSize: getResponsiveStyle(11, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(20),
	};
}

const styles = StyleSheet.create({
	termsAndConditions: {
		textAlign: 'center',
		letterSpacing: 0.18,
		color: palettes.grey[0],
	},
});
