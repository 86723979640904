import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { tagStyle } from './CardTop';

type Props = {
	startingEventDateTime: string;
};

export const EventCardStartsAtDate = ({ startingEventDateTime }: Props) => {
	const { t } = useTranslation();
	return (
		<Text style={[tagStyle.eventMeta, tagStyle.eventStartingTime]}>
			{t('Starts at')} -{' '}
			{!!startingEventDateTime &&
				dayjs(+startingEventDateTime).format('HH:mm')}
		</Text>
	);
};
