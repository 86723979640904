import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../common/modal/ModalComponent';
import { useMutation } from '@apollo/client';
import {
	DELETE_ADDRESS_BOOK,
	EDIT_ADDRESS_BOOK,
} from '../../../graphql/profile/mutations';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { GET_ALL_ADDRESS_BOOKS } from '../../../graphql/profile/queries';
import { Button, Loader } from '../..';
import { useAppSelector } from '../../../redux/store';
import { shortenName } from '../../../utils/manipulateString';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

type Props = {
	index: number;
	defaultAddress?: boolean;
	isOnProfileScreen?: boolean;
	// addresses?: TAddressBook[],
	handlePress?: (
		values: Omit<TAddressBook, 'createdBy'>,
		index: number
	) => void;
	defaultAddressId?: string;
	setDefaultLoading?: (arg: boolean) => void;
	onPressEditDetails: (
		values: Omit<TAddressBook, 'createdBy'>,
		index: number
	) => void;
} & Omit<TAddressBook, 'createdBy'>;

const AddressComponent = ({
	name,
	phoneNumber,
	city,
	address,
	defaultAddress = false,
	_id,
	zipCode,
	onPressEditDetails,
	handlePress,
	defaultAddressId,
	setDefaultLoading,
	// addresses,
	index,
	countery,
	isOnProfileScreen,
}: Props) => {
	const { t } = useTranslation();
	const { isLessThanDesktopBase, isTabletView } =
		useIsSpecificViewportWidth();
	const primary = usePrimaryColors();

	const [deleteAddressModalState, setDeleteAddressModalState] =
		useState(false);
	const changeModalState = () => {
		setDeleteAddressModalState(!deleteAddressModalState);
	};

	const [selected, setSelected] = useState(false);

	const { setAlert } = useSetAlert();
	// const [defaultAddressId, setDefaultAddressId] = useState<string | undefined>('')
	// useEffect(() => {
	// 	const defaultAddress_ = addresses?.find((address: TAddressBook) => address.default === true)
	// 	setDefaultAddressId(defaultAddress_?._id)
	// }, [addresses])

	const handleAddressSelect = () => {
		setSelected(!selected);
	};

	const [editAddress, { loading: loadingedit }] =
		useMutation(EDIT_ADDRESS_BOOK);

	const unDefault = () => {
		editAddress({
			variables: {
				_id: defaultAddressId,
				args: {
					default: false,
				},
			},
		});
	};

	const setDefault = () => {
		editAddress({
			variables: {
				_id: _id,
				args: {
					default: true,
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_ALL_ADDRESS_BOOKS }],
			onCompleted: () => setDefaultLoading && setDefaultLoading(false),
		});
	};
	if (loadingedit) {
		setDefaultLoading && setDefaultLoading(true);
	} else {
		setDefaultLoading && setDefaultLoading(false);
	}
	const [deleteAddress, { loading: deletingAddress, data: deleteData }] =
		useMutation(DELETE_ADDRESS_BOOK, {
			variables: {
				_id,
				args: {
					_id,
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_ALL_ADDRESS_BOOKS }],
			onCompleted: data => {
				if (
					data.deleteAddressBook.statusCode === 200 &&
					data.deleteAddressBook.success
				) {
					setAlert(t('Address book deleted successfully!'), 'normal');
				} else {
					setAlert(t('Unable to delete address book!'), 'danger');
				}
			},
			onError: () => {
				setAlert(t('Unable to delete address book!'), 'danger');
			},
		});

	const containerWidth = isLessThanDesktopBase
		? '100%'
		: isOnProfileScreen
		? '30%'
		: !isLessThanDesktopBase
		? '45%'
		: isTabletView
		? '45%'
		: '100%';
	return (
		<View
			style={[
				styles.container,
				{
					maxWidth: containerWidth,
				},
				selected && { borderColor: primary[1] },
			]}
		>
			{/* {
				loadingedit ?
					<Loader /> : */}
			<Pressable
				onPress={() => {
					handlePress &&
						handlePress(
							{
								address,
								city,
								name,
								phoneNumber,
								countery,
								zipCode,
								_id,
							},
							index
						);
					handleAddressSelect();
				}}
				style={[
					styles.innerContainer,
					selected && styles.innerSelectedContainer,
					selected && { borderColor: primary[0] },
				]}
			>
				<View style={styles.headerContainer}>
					{/* <View style={styles.headerTextContainer}>
						<Text style={styles.textTitile}>{t('Name')}</Text>
						<Text style={styles.textSubtitle}>{name}</Text>
					</View> */}
					{/* {defaultAddress && (
						<View style={styles.defaultIndicator}>
							<Text style={styles.defaultIndicatorText}>
								{t('Default')}
							</Text>
						</View>
					)} */}
				</View>
				<View style={[styles.contentContainer, { width: '100%' }]}>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>
							{t('Billing Address')}
						</Text>
						<Text style={styles.textSubtitle}>{address}</Text>
					</View>
				</View>
				<View style={styles.contentContainer}>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>{t('City')}</Text>
						<Text style={styles.textSubtitle}>
							{shortenName(city)}
						</Text>
					</View>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>{t('Country')}</Text>
						<Text style={styles.textSubtitle}>
							{shortenName(countery)}
						</Text>
					</View>
				</View>
				<View style={styles.contentContainer}>
					{' '}
					{/* <View style={styles.textContainer}>
					<Text style={styles.textTitile}>{t('Zip Code')}</Text>
					<Text style={styles.textSubtitle}>{zipCode}</Text>
				</View> */}
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>{t('Zip Code')}</Text>
						<Text style={styles.textSubtitle}>{zipCode}</Text>
					</View>
					{/* <Text
						onPress={() =>
							onPressEditDetails(
								{
									address,
									city,
									name,
									countery,
									zipCode,
									_id,
								},
								index
							)
						}
						style={styles.hoverText}
					>
						{t('Edit Details')}
					</Text> */}
					<Text
						onPress={() => changeModalState()}
						style={styles.hoverText}
					>
						{t('Delete')}
					</Text>
					{/* {!defaultAddress && (
						<Text
							// onPress={() => changeModalState()}
							onPress={async () => {
								await unDefault();
								await setDefault();
							}}
							style={styles.hoverText}
						>
							{t('Set as Default')}
						</Text>
					)} */}
				</View>
				{deleteAddressModalState && (
					<ModalComponent
						header={`${t('Delete Address Book')}`}
						openState={deleteAddressModalState}
						onClose={changeModalState}
					>
						{deletingAddress ? (
							<Text>{t('Deleting Address Book...')}</Text>
						) : deleteData ? (
							<Text>{t('Address Book Deleted')}</Text>
						) : (
							<View>
								<Text>
									{t('Are you sure you want to delete?')}{' '}
								</Text>
								<Button
									title={t('Yes')}
									onPress={() => deleteAddress()}
								/>
							</View>
						)}
					</ModalComponent>
				)}
			</Pressable>
			{/* } */}
		</View>
	);
};

const styles = StyleSheet.create({
	selected: {
		borderColor: palettes.primary[1],
	},
	innerSelectedContainer: {
		// borderColor: palettes.primary[0],
		borderWidth: 1,
		borderRadius: 6,
	},
	innerContainer: {
		width: '100%',
		paddingTop: getResponsiveStyle(10),
		paddingLeft: getResponsiveStyle(20),
		paddingRight: getResponsiveStyle(20),
		paddingBottom: getResponsiveStyle(15),
	},
	container: {
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
		// maxWidth: getResponsiveStyle(440),
		width: '100%',
		padding: '1px',
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'flex-start',
	},
	headerTextContainer: {
		flexDirection: 'column',
		gap: 4,
	},
	textTitile: {
		color: palettes.grey[1],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	},
	textSubtitle: {
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(21, 'font'),
	},
	defaultIndicator: {
		paddingHorizontal: getResponsiveStyle(17),
		paddingTop: getResponsiveStyle(10),
		paddingBottom: getResponsiveStyle(8),
		backgroundColor: palettes.purple[0],
		borderRadius: 6,
	},
	defaultIndicatorText: {
		color: palettes.light[0],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	},
	contentContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
		width: '70%',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		marginTop: getResponsiveStyle(15),
	},
	textContainer: {
		flexDirection: 'column',
		// marginRight: getResponsiveStyle(90, 'dimensions'),
		gap: 6,
	},
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[1],
		cursor: 'pointer',
	},
});

export default AddressComponent;
