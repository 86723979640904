export const monthsByNumber = {
	January: 1,
	February: 2,
	March: 3,
	April: 4,
	May: 5,
	June: 6,
	July: 7,
	August: 8,
	September: 9,
	October: 10,
	November: 11,
	December: 12,
};

export const tConvert = (date: any, startDateGMT: any) => {
	const constructedMonth = date.getMonth() + 1;
	const startDate = `${startDateGMT[3]}-${
		constructedMonth < 10 ? `0${constructedMonth}` : constructedMonth
	}-${startDateGMT[2]}`;
	const contructedTime = startDateGMT[4].split(':');
	return {
		startDate,
		contructedTime,
	};
};

export const formatAMPM = (date: any) => {
	const day = date.toString().split(' ');
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = timeAMPM(hours);
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return `${day[1]} ${day[2]}, ${day[3]} ${strTime}`;
};
export const timeAMPM = (value: number | string) => {
	return value >= 12 ? 'pm' : 'am';
};
export const dayMonthYear = (dateObj: Date) => {
	const month = dateObj.getMonth(); //months from 1-12
	let day: string | number = dateObj.getDate();
	day = day < 10 ? '0' + day : day;
	const year = dateObj.getUTCFullYear();
	return `${day}/${month}/${year}`;
};
export const millisecondsInHoursSecondsAndMinutes = (ms: number) => {
	const daysMs = ms % (24 * 60 * 60 * 1000);
	const hours = formatDateValue(Math.floor(daysMs / (60 * 60 * 1000)));
	const hoursMs = ms % (60 * 60 * 1000);
	const minutes = formatDateValue(Math.floor(hoursMs / (60 * 1000)));
	const minutesMs = ms % (60 * 1000);
	const seconds = formatDateValue(Math.floor(minutesMs / 1000));
	return `${hours}:${minutes}:${seconds}`;
};
export const millisecondsInHoursSecondsAndMinutesAMPM = (ms: number) => {
	const daysMs = ms % (24 * 60 * 60 * 1000);
	const hours = formatDateValue(Math.floor(daysMs / (60 * 60 * 1000)));
	const hoursMs = ms % (60 * 60 * 1000);
	const minutes = formatDateValue(Math.floor(hoursMs / (60 * 1000)));
	const minutesMs = ms % (60 * 1000);
	const seconds = formatDateValue(Math.floor(minutesMs / 1000));
	return `${hours}:${minutes}:${seconds} ${timeAMPM(hours)}`;
};

const formatDateValue = (value: number) => {
	return value < 10 ? '0' + value : value;
};
