import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { FilterCategories, SectionHeading } from '../..';
import { i18n } from '../../../i18n';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProductCards from './ProductCards';

const DROPDOWN_CATEGORIES = [
	{
		label: i18n.t('All'),
		value: 'all',
	},
	{
		label: i18n.t('Skincare'),
		value: 'skincare',
	},
	{
		label: i18n.t('Personal Hygiene'),
		value: 'personal-hygiene',
	},
	{
		label: i18n.t('Face + Body'),
		value: 'face-body',
	},
];

type Props = {
	products: TGetAllProductsData[];
	onBrandDetailsScreen?: boolean;
	brand?: string;
};

const AllProducts = ({ products, brand }: Props) => {
	const { t } = useTranslation();
	return (
		<View style={styleContainer()}>
			<SectionHeading heading={t('All Products')} size="large" />
			{/* <FilterCategories
				secondLabel={''}
				dropdownCategories={DROPDOWN_CATEGORIES}
				pillCategories={DROPDOWN_CATEGORIES}
			/> */}
			<ProductCards products={products} brand={brand} />
		</View>
	);
};

export default AllProducts;
function styleContainer() {
	return { paddingTop: getResponsiveStyle(32) };
}
