import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CONVERSATIONS } from '../../graphql/chat/queries';
import { useAppDispatch } from '../../redux/store';

const useGetConversations = () => {
	const dispatch = useAppDispatch();

	const [getConversation, { data, error, loading }] = useLazyQuery(
		GET_CONVERSATIONS,
		{
			fetchPolicy: 'no-cache',
		}
	);

	const executeConversationsMutation = ({
		userId,
	}: {
		userId: string | undefined;
	}) => {
		getConversation({
			variables: {
				userId,
			},
		});
	};
	return { executeConversationsMutation, data, error, loading };
};

export default useGetConversations;
