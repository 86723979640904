import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../../redux/store';

const UserImageMessage = ({
	message,
}: {
	message: { message: string };
	isUser?: boolean;
}) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const userDetail = useAppSelector(state => state.auth.loggedInUserDetails);
	return (
		<>
			{/* <ModalComponent
				type="modal"
				header="Image"
				openState={true}
				onClose={() => null}
			>
				<Image
					style={[
						styles.largeImage,
						isLessThanDesktopBase && styles.mobileSizeImage,
					]}
					source={{
						uri: message.message,
					}}
				/>
			</ModalComponent> */}
			<View style={styles.flex1}>
				<Image
					style={[
						styles.image,
						isLessThanDesktopBase && styles.mobileSizeImage,
					]}
					source={{
						uri: message.message,
					}}
				/>
				<Image
					style={styles.profileImage}
					source={{
						uri: userDetail?.personalInformation?.profileImageLink,
					}}
				/>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flexDirection: 'row',
		marginTop: 15,
		height: 150,
		justifyContent: 'flex-end',
	},
	profileImage: {
		width: 30,
		height: 30,
		borderRadius: 25,
	},
	image: {
		width: '30%',
		height: '100%',
		borderRadius: 6,
		marginBottom: 10,
		marginRight: 20,
		cursor: 'pointer',
	},
	largeImage: {
		height: 400,
		width: '100%',
	},
	detailImage: {
		width: 224,
		height: 204,
	},
	mobileSizeImage: {
		width: 159,
		height: 126,
	},
	mobileSizeDetailImage: {
		width: 324,
		height: 304,
	},
});

export default UserImageMessage;
