import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	headers: TTableHeader[];
	type?: TTableTypes;
};

export const TableHeader = ({ headers, type = 'order' }: Props) => {
	const { t } = useTranslation();
	return (
		<View style={[styles.container, styleContainer()]}>
			{headers.map(({ headerLabel, width }) => (
				<Text
					key={headerLabel}
					style={[styles.header, styleHeader(width)]}
				>
					{headerLabel}
				</Text>
			))}
			{type !== 'profile' && <Text style={styles.action}></Text>}
			{type === 'profile' && (
				<Text style={styles.action}>{t('WATCH')}</Text>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		fontWeight: '400',
		color: palettes.grey[0],
		textTransform: 'uppercase',
	},
	container: {
		flexDirection: 'row',
	},
	action: {
		flex: 1,
		fontWeight: '400',
		color: palettes.grey[0],
		textTransform: 'uppercase',
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	},
});

const styleHeader = (width: string | number) => {
	return {
		width,
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleContainer = () => {
	return {
		marginBottom: getResponsiveStyle(10),
		paddingHorizontal: getResponsiveStyle(24),
	};
};
