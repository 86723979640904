import React, { useRef } from 'react';
import { StyleSheet, View, Image, Text } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import DeleteIcon from '../../common/icons/DeleteIcon';
// const Image = styled.Image`
// 	color: ${palettes.grey[1]};
// 	width: "100%";
// 	height: "100%";
// 	border-bottom: 1px solid transparent;
// 	padding-bottom: 2px;
// 	border-radius: 6px;
// 	margin-top: ${getResponsiveStyle(19)};
// `
const ImageDropZoneContainer = styled.View`
	cursor: pointer;
`;

type Props = {
	src: string;
	alt: string;
	containerStyles?: TStyle;
	id?: string;
	onRemove: (id: string) => void;
};

export const ImageContainer = ({
	alt,
	src: uri,
	containerStyles,
	onRemove,
	id,
}: Props) => {
	// const ref = useRef(null)
	// const isHovered = useHover(ref)
	return (
		<View style={containerStyles}>
			<Image
				// ref={ref}
				style={[
					{
						width: getResponsiveStyle(128, 'dimensions'),
						height: getResponsiveStyle(136, 'dimensions'),
						borderWidth: 1,
						borderColor: 'transparent',
						paddingBottom: 2,
						borderRadius: 6,
					},
				]}
				source={{ uri }}
			/>
			<Text onPress={() => onRemove(id ?? '')} style={styles.removeText}>
				{'Remove '} <DeleteIcon />
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	outerContainer: {},
	imageDropzoneContainer: {
		borderWidth: 1,
		borderRadius: 6,
		borderColor: palettes.grey[4],
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 3,
	},
	removeText: {
		color: 'red',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		marginTop: 4,
	},
});
