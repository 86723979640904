import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useWindowDimensions } from 'react-native';
import { AuthTemplate } from '../components';
import EventHostForm from '../components/create-event/event-host/EventHostForm';
import { setIsEditEvent } from '../redux/getEventSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
	setCurrentScreen,
	setIsVisitingAddHostScreenFromEventsScreen,
} from '../redux/uiSlice';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const EventHostScreen = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/event-host'));
	}, []);
	const { createEventSidebarArray, isVisitingAddHostScreenFromEventsScreen } =
		useAppSelector(state => ({
			createEventSidebarArray: state.ui.CreateEventSidebarArray,
			isVisitingAddHostScreenFromEventsScreen:
				state.ui.isVisitingAddHostScreenFromEventsScreen,
		}));
	const { t } = useTranslation();

	useEffect(() => {
		return () => {
			dispatch(setIsVisitingAddHostScreenFromEventsScreen(false));
			dispatch(setIsEditEvent(false));
		};
	}, []);

	return (
		<AuthTemplate
			heading={t('Add Host')}
			subText={t('Lets choose a host in order to liven the event up')}
			scrollItems={
				isVisitingAddHostScreenFromEventsScreen
					? []
					: createEventSidebarArray
			}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
			hasGoBack={!isVisitingAddHostScreenFromEventsScreen}
		>
			<EventHostForm />
		</AuthTemplate>
	);
};

export default EventHostScreen;
