import { tConvert } from '../../../utils/monthsUtils';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, DateTimePicker } from '../..';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { setEventTimeAndDuration } from '../../../redux/createEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import dayjs from 'dayjs';

const EventDurationForm = () => {
	const { t } = useTranslation();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);

	const isEditEvent = useAppSelector(state => state.getEvent.isEditEvent);
	const event = useAppSelector(state => state.getEvent.event);
	const date = new Date(+event.startingEventDateTime);
	const startDateGMT = date.toString().split(' ');

	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);
	const [eventTime, setEventTime] = useState({
		startDate: '',
		startTime: '',
	});

	useEffect(() => {
		if (isEditEvent) {
			const { contructedTime, startDate } = tConvert(date, startDateGMT);

			setEventTime({
				startDate: startDate,
				startTime: `${contructedTime[0]}:${contructedTime[1]}`,
			});
		}
	}, []);

	const dispatch = useAppDispatch();

	const onChangeTime: ChangeEventHandler<HTMLInputElement> = e => {
		setEventTime({
			...eventTime,
			[e.target.name]: e.target.value,
		});
	};

	const onNextButtonPress = () => {
		const dateTime = `${eventTime.startDate} ${eventTime.startTime}`;
		const dateTimeUnix = dayjs(dateTime).unix() * 1000;
		dispatch(setEventTimeAndDuration(dateTimeUnix.toString()));
		goToNextStepScreen();
	};
	return (
		<View>
			<View style={styleRowContainer()}>
				<DateTimePicker
					containerStyles={{
						marginBottom: getResponsiveStyle(24),
					}}
					label={t('Start Date')}
					dateTimeProps={{
						dateTimeProps: {
							name: 'startDate',
							onChange: onChangeTime,
							value: eventTime.startDate,
						},
						type: 'date',
						min: new Date().toISOString().split('T')[0],
					}}
				/>
				<DateTimePicker
					containerStyles={{
						marginBottom: getResponsiveStyle(24),
					}}
					label={t('Start Time')}
					dateTimeProps={{
						dateTimeProps: {
							name: 'startTime',
							onChange: onChangeTime,
							value: eventTime.startTime,
						},
						type: 'time',
					}}
				/>
			</View>

			<Button
				title={t('Next')}
				variant={
					eventTime.startDate && eventTime.startTime
						? 'primary'
						: 'disabled'
				}
				size="lg"
				onPress={onNextButtonPress}
				outerContainerProps={{
					style: {
						marginTop: getResponsiveStyle(46),
					},
				}}
			/>
		</View>
	);
};

export default EventDurationForm;

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(24),
		marginRight: getResponsiveStyle(-16),
	} as TStyle;
};
