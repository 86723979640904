import { Pressable, StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Brand_, setSelectedVoucher } from '../../../redux/walletSlice';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppSelector } from '../../../redux/store';
import { twoDp } from '../../../utils/twoDP';
import getSymbolFromCurrency from 'currency-symbol-map';
type Props = {
	brandName: string;
	description: string;
	country: string;
	balance: number;
	brandId: Brand_;
	voucherId: string;
	setSelectedVoucherAmountLeft?: React.Dispatch<React.SetStateAction<number>>;
	amountToBePaid?: number;
};

const VoucherComponent = ({
	brandName,
	country,
	balance,
	brandId,
	voucherId,
	description,
	setSelectedVoucherAmountLeft = () => {
		return;
	},
	amountToBePaid = 0,
}: Props) => {
	const { isLessThanDesktopBase, isTabletView } =
		useIsSpecificViewportWidth();
	const { t } = useTranslation();

	const primary = usePrimaryColors();

	// const [selected, setSelected] = useState(false);

	const dispatch = useDispatch();

	const { setAlert } = useSetAlert();

	const handleVoucherSelect = () => {
		// const bal = balance?.replace('$', '');
		dispatch(
			setSelectedVoucher({
				amount: balance.toString(),
				brandId,
				voucherId,
				description,
			})
		);
		if (balance - amountToBePaid <= 0) {
			setSelectedVoucherAmountLeft(0);
		} else {
			setSelectedVoucherAmountLeft(balance - amountToBePaid);
		}
		setAlert(
			t('Voucher selected, balance updated accordingly') + '!',
			'normal'
		);
	};

	const { selectedVoucher } = useAppSelector(state => state.wallet);
	const selected = selectedVoucher.voucherId === voucherId;

	return (
		<Pressable
			style={[
				styles.container,
				{
					maxWidth: !isLessThanDesktopBase
						? '42.5%'
						: isTabletView
						? '45%'
						: '100%',
				},
				selected && { borderColor: primary[1] },
			]}
			onPress={() => handleVoucherSelect()}
		>
			<View
				style={[
					styles.innerContainer,
					selected && styles.innerSelectedContainer,
					selected && { borderColor: primary[0] },
				]}
			>
				<View style={styles.headerContainer}>
					<View style={styles.headerTextContainer}>
						<Text style={styles.textTitile}>{t('Brand Name')}</Text>
						<Text style={styles.textSubtitle}>{brandName}</Text>
					</View>
				</View>
				{/* <View style={styles.contentContainer}>
				<View style={styles.textContainer}>
					<Text style={styles.textTitile}>{t('Description')}</Text>
					<Text style={styles.textSubtitle}>{description}</Text>
				</View>
			</View> */}
				<View style={styles.contentContainer}>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>{t('Balance')}</Text>
						<Text style={styles.textSubtitle}>
							{getSymbolFromCurrency('eur')}
							{balance.toFixed(2)}
						</Text>
					</View>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>{t('Country')}</Text>
						<Text style={styles.textSubtitle}>{country}</Text>
					</View>
				</View>
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	selected: {
		// borderColor: palettes.primary[1],
	},
	innerSelectedContainer: {
		// borderColor: palettes.primary[0],
		borderWidth: 1,
		borderRadius: 6,
	},
	innerContainer: {
		width: '100%',
		paddingTop: getResponsiveStyle(23),
		padding: getResponsiveStyle(21),
	},
	container: {
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
		// maxWidth: getResponsiveStyle(440),
		width: '100%',
		padding: '1px',
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'flex-start',
	},
	headerTextContainer: {
		flexDirection: 'column',
		gap: 4,
	},
	textTitile: {
		color: palettes.grey[1],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	},
	textSubtitle: {
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(21, 'font'),
	},
	defaultIndicator: {
		paddingHorizontal: getResponsiveStyle(17),
		paddingTop: getResponsiveStyle(10),
		paddingBottom: getResponsiveStyle(8),
		backgroundColor: palettes.purple[0],
		borderRadius: 6,
	},
	defaultIndicatorText: {
		color: palettes.light[0],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	},
	contentContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		marginTop: getResponsiveStyle(26),
	},
	textContainer: {
		flexDirection: 'column',
		gap: 6,
	},
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[1],
		cursor: 'pointer',
	},
});

export default VoucherComponent;
