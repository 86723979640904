import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type Tags = {
	INTEREST_TAGS: TTagType[];
	DROPDOWN_CATEGORIES: TTagType[];
	PILL_CATEGORIES: TTagType[];
	BRAND_PILL_CATEGORIES: TTagType[];
	BRAND_INIT_CATEGORIES: TTagType[];
	PILL_INIT_CATEGORIES: TTagType[];
};
const initialState: Tags = {
	INTEREST_TAGS: [],
	DROPDOWN_CATEGORIES: [],
	PILL_CATEGORIES: [],
	BRAND_PILL_CATEGORIES: [],
	PILL_INIT_CATEGORIES: [
		{ en: 'All', fr: 'Tous', value: 'all' },
		{
			en: 'Selected for you',
			fr: 'Recommandés pour vous',
			value: 'selected-for-you',
		},
		{ en: 'My followers', fr: 'Mes abonnés', value: 'my-followers' },
	],
	BRAND_INIT_CATEGORIES: [
		{ en: 'All', fr: 'Tous', value: 'all' },
		{
			en: 'Selected for you',
			fr: 'Recommandés pour vous',
			value: 'selected-for-you',
		},
		{ en: 'New Brands', fr: 'Nouvelles Marques', value: 'new-brand' },
		{
			en: 'Followed Brands',
			fr: 'Marques abonnées',
			value: 'followed-brand',
		},
	],
};
const tagSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setInteresTags(state, action: PayloadAction<TTagType[]>) {
			state.INTEREST_TAGS = action.payload;
		},
		setDropDownCategories(state, action: PayloadAction<TTagType[]>) {
			state.DROPDOWN_CATEGORIES = action.payload;
		},
		setPillCategories(state, action: PayloadAction<TTagType[]>) {
			state.PILL_CATEGORIES = action.payload;
		},
		setBrandPillCategories(state, action: PayloadAction<TTagType[]>) {
			state.BRAND_PILL_CATEGORIES = action.payload;
		},

		resetState() {},
	},
});
export const {
	setBrandPillCategories,
	setDropDownCategories,
	setInteresTags,
	setPillCategories,
} = tagSlice.actions;
export default tagSlice.reducer;
