import React, { useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import {
	LiveTVIcon,
	LogoutIcon,
	MicOutlineIcon,
	PhoneHandleIcon,
	VolumeIcon,
} from '../../common/icons';
import { LiveStreamActionWrapper } from './LiveStreamActionWrapper';
import ShareArrowIcon from '../../common/icons/ShareArrowIcon';
import { CartIconWithItems } from '../../common/desktop-navigation-bar/CartIconWithItems';
import RetailStoreIcon from '../../common/icons/RetailStoreIcon';
import BackEditIcon from '../../common/icons/BackEditIcon';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../../types/exportedTypes';

export type EventNavigationProps = {
	onPressMute: () => void;
	onPressHangButton: () => void;
	onPressVideoSellerOrHost: () => void;
	isVolumeDisabledForConsumer?: boolean;
	eventData?: TGetAllEventCommon;
	onPressTab: (tab: string) => void;
	typeOfAccount: string;
	isLiveStreaming: boolean;
	isAudioEnabledForHostSeller: boolean;
	isVideoEnabledForHostSeller: boolean;
};

const EventNavigation = ({
	onPressMute,
	onPressHangButton,
	onPressVideoSellerOrHost,
	onPressTab,
	isVolumeDisabledForConsumer,
	eventData,
	typeOfAccount,
	isLiveStreaming,
	isAudioEnabledForHostSeller,
	isVideoEnabledForHostSeller,
}: EventNavigationProps) => {
	const navigation = useNavigation<TUseNavigation>();
	return (
		<View style={styles.flex1}>
			{typeOfAccount === 'SELLER' ? (
				<LiveStreamActionWrapper
					icon={<MicOutlineIcon height="25" width="25" />}
					onPress={onPressMute}
					isBgRed={isAudioEnabledForHostSeller}
				/>
			) : (
				<LiveStreamActionWrapper
					icon={<VolumeIcon height="25" width="25" />}
					onPress={onPressMute}
					isBgRed={isVolumeDisabledForConsumer}
				/>
			)}
			{typeOfAccount === 'SELLER' && isLiveStreaming && (
				<LiveStreamActionWrapper
					icon={<LiveTVIcon height="25" width="25" />}
					onPress={onPressVideoSellerOrHost}
					isBgRed={isVideoEnabledForHostSeller}
				/>
			)}

			{typeOfAccount === 'SELLER' ? (
				<LiveStreamActionWrapper
					icon={
						<PhoneHandleIcon
							height="25"
							width="25"
							style={{
								marginTop: 4,
								marginRight: 3,
							}}
						/>
					}
					onPress={onPressHangButton}
					isBgRed={true}
				/>
			) : (
				<LiveStreamActionWrapper
					icon={<LogoutIcon height="25" width="25" />}
					onPress={onPressHangButton}
					isBgRed={true}
				/>
			)}
			<LiveStreamActionWrapper
				icon={
					<ShareArrowIcon
						width="25"
						height="25"
						fill="#fff"
						data={eventData}
					/>
				}
				onPress={() => null}
			/>
			<LiveStreamActionWrapper
				icon={
					<CartIconWithItems
						cartIconHeight={25}
						cartIconWidth={25}
						white
					/>
				}
				onPress={() => null}
			/>
			<LiveStreamActionWrapper
				icon={<RetailStoreIcon width="25" height="25" fill="#fff" />}
				onPress={() => onPressTab('Products')}
			/>
			<LiveStreamActionWrapper
				icon={
					<BackEditIcon
						width="25"
						height="25"
						fill="#fff"
						containerStyles={{ transform: [{ rotate: '90deg' }] }}
						onPress={() => window.history.go(-1)}
					/>
				}
				onPress={() => window.history.go(-1)}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		position: 'absolute',
		top: '10%', // You can adjust these values according to your layout
		right: 10,
		padding: 10,
		borderRadius: 5,
		zIndex: 1,
		// borderWidth: 1,
		gap: 10,
		alignItems: 'center',
	},
});

export default EventNavigation;
