import { useNavigation } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { useIsSpecificViewportWidth } from './useIsSpecificViewportWidth';
import { useTranslation } from 'react-i18next';
import {
	setCurrentScreen,
	setIsVisitingAddHostScreenFromEventsScreen,
} from '../redux/uiSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	CREATE_STRIPE_CUSTOMER,
	CREATE_TRANSFER_FROM_PENDING_TRFS,
	DELETE_PENDING_TRANSFER,
} from '../graphql/stripe/mutations';
import { GET_PENDING_TRANSFER, GET_STRIPE_ID } from '../graphql/stripe/queries';
import { setPendingIncome } from '../redux/walletSlice';
import { useHandleUnmount } from './useHandleUnmount';
import { setStripeID } from '../redux/stripeIdSlice';
import {
	GET_ALL_PUBLISHED_EVENTS_FOR_EVENT_SCREEN,
	GET_CONSUMER_PENDING_EVENTS,
	GET_PERSONALIZED_EVENTS,
	GET_SINGLE_EVENT,
	GET_UPCOMING_EVENTS,
	GET_UPCOMING_PLANNED_EVENTS_FOR_CONSUMERS,
} from '../graphql/events/queries';
import {
	setCurrentEvent,
	setEventIds,
	setEventsAffiliatedWithFollowing_,
	setIsEditEvent,
} from '../redux/getEventSlice';
import { IS_SUBSCRIBED_TO_EVENTS } from '../graphql/eventSubscription/queries';
import { GET_BRANDS_SELECTED_FOR_YOU } from '../graphql/brands/queries';
import {
	GET_ALL_CONSUMERS,
	GET_ALL_HOSTS,
	GET_ALL_SELLERS,
	IS_FOLLOWING,
	IS_FOLLOWING_ME,
} from '../graphql/network/queries';
import useGetStatus from '../api/status/useGetStatus';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import { setFollowedIds, setIsFollowingResult } from '../redux/userSlice';
import { IS_FOLLOWING_ME_REQUEST_SENT } from '../graphql/follow/queries';
import { useSetAlert } from './useSetAlerts';
import { FOLLOW_ME, UNFOLLOW_ME } from '../graphql/follow/mutations';
import { GET_ALL_USERS_BY_IDS } from '../graphql/auth/queries';
import { TUseNavigation } from '../types/exportedTypes';
import {
	setEventToAddHostsTo,
	setHostsInvited,
	setHostsInvitedIds,
} from '../redux/addHostToEventSlice';

const LIMIT = 10;

export const useHomeScreen = ({ getQueryFieldForUser }: any) => {
	const dispatch = useAppDispatch();
	const navigation = useNavigation<TUseNavigation>();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { t } = useTranslation();

	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	useEffect(() => {
		dispatch(setCurrentScreen('/'));
	}, []);

	const upcomingEventsVariable = useMemo(
		() => ({
			// startingEventDateTime: {
			// 	'$gte': Date.now().toString(),
			// },
			...getQueryFieldForUser(
				loggedInUserDetails?.typeOfAccount,
				loggedInUserDetails?._id
			),
		}),
		[loggedInUserDetails?.typeOfAccount, loggedInUserDetails?._id]
	);

	const { deletedStatusId } = useAppSelector(state => state.statusSlice);
	const [isSubscribedArray, setIsSubscribedArray] = useState([]);

	const [createTransferFromPending] = useMutation(
		CREATE_TRANSFER_FROM_PENDING_TRFS
	);
	const [deletePendingTransfer] = useMutation(DELETE_PENDING_TRANSFER);
	const [getPendingTransfer] = useLazyQuery(GET_PENDING_TRANSFER, {
		onCompleted: data => {
			if (data?.getPendingTransfer?.data?.total) {
				createTransferFromPending({
					variables: {
						sellerId: loggedInUserDetails?._id,
					},
					onCompleted: () => {
						deletePendingTransfer({
							variables: {
								args: { sellerId: loggedInUserDetails?._id },
							},
						});
					},
				});
			}
		},
	});

	const [getPendingTransferNoConnect] = useLazyQuery(GET_PENDING_TRANSFER, {
		onCompleted: data => {
			if (data?.getPendingTransfer?.data?.total) {
				let amount = 0;
				data?.getPendingTransfer?.data?.data?.map(
					(item: { amount: number }) => {
						amount += item.amount;
					}
				);

				dispatch(setPendingIncome(amount));
			}
		},
	});
	useEffect(() => {
		if (
			loggedInUserDetails?.connectAccountInfo &&
			loggedInUserDetails.connectAccountInfo.connectAccountId
		) {
			getPendingTransfer({
				variables: {
					args: { sellerId: loggedInUserDetails._id },
				},
			});
		}
	}, [loggedInUserDetails]);

	useEffect(() => {
		if (
			!loggedInUserDetails?.connectAccountInfo ||
			!loggedInUserDetails?.connectAccountInfo?.connectAccountId
		) {
			getPendingTransferNoConnect({
				variables: {
					args: { sellerId: loggedInUserDetails?._id },
				},
			});
		}
	}, [loggedInUserDetails]);
	const email = useAppSelector(state => state.createUser.args.email);
	const username = useAppSelector(state => state.createUser.args.username);

	const [statusPost, setStatusPost] = useState<StatusPost[]>([]);

	const [upcomingEvents, setUpcomingEvents] = useState<TGetAllEventsData[]>();

	const [statusUpdate, setStatusUpdate] = useState(false);

	const [publishedEvents, setPublishedEvents] =
		useState<TGetAllEventsData[]>();

	const [suggestedHostsData, setSuggestedHostsData] =
		useState<TGetAllUsersData[]>();

	const [suggestedBrandsData, setSuggestedBrandsData] =
		useState<TGetAllBrandsData[]>();

	const [eventsAffiliatedWithFollowing, setEventsAffiliatedWithFollowing] =
		useState<TGetAllEventsData[]>([]);

	const [suggestedSellersData, setSuggestedSellersData] =
		useState<TGetAllUsersData[]>();

	const [suggestedConsumersData, setSuggestedConsumersData] =
		useState<TGetAllUsersData[]>();

	const [consumerPendingEvents, setConsumerPendingEvents] =
		useState<TGetAllEventsData[]>();

	const [upcomingConsumerPlannedEvents, setUpcomingConsumerPlannedEvents] =
		useState<TGetAllEventsData[]>();

	const [createCustomer] = useMutation(CREATE_STRIPE_CUSTOMER);

	const [eventsAffiliatedCurrent, setEventsAffiliatedCurrent] = useState(0);

	const { shouldHideScreen } = useHandleUnmount();

	// const { subscribeToMore: subscribeToNotification, data: notificationData } =
	// 	useQuery(GET_ALL_NOTIFICATIONS);

	// let noOfUnread = 0;

	// useEffect(() => {
	// 	if (notificationData !== undefined) {
	// 		notificationData?.getAllNotifications?.data?.data?.map(
	// 			(data: NotificationType) => {
	// 				if (data.read === false) {
	// 					noOfUnread += 1;
	// 				}
	// 			}
	// 		);
	// 	}
	// 	dispatch(setNoOfNotifications(noOfUnread));
	// }, [notificationData]);

	// subscribeToNotification({
	// 	document: NOTIFICATION_SUBSCRIPTION,
	// 	variables: { userId: loggedInUserDetails?._id },
	// 	updateQuery: (prev, { subscriptionData }) => {
	// 		if (!subscriptionData.data) return prev;
	// 		const newNotification =
	// 			subscriptionData.data.notificationSubscription;
	// 		let oldObj = {};
	// 		if (
	// 			!!prev &&
	// 			!!prev?.getAllNotifications &&
	// 			!!prev?.getAllNotifications?.data &&
	// 			!!prev?.getAllNotifications?.data?.data
	// 		) {
	// 			oldObj = { ...prev.getAllNotifications.data.data };
	// 		}
	// 		// if (prev !== undefined) {
	// 		// 	if (prev.getAllNotifications !== undefined) {
	// 		// 		if (prev?.getAllNotifications?.data !== undefined) {
	// 		// 			if (prev.getAllNotifications?.data?.data !== undefined) {
	// 		// 				oldObj = { ...prev.getAllNotifications.data.data };
	// 		// 			}
	// 		// 		}
	// 		// 	}
	// 		// }
	// 		return {
	// 			getAllNotifications: {
	// 				data: {
	// 					data: [newNotification, oldObj],
	// 				},
	// 			},
	// 		};
	// 	},
	// });

	const { data: stripeIdData } = useQuery(GET_STRIPE_ID, {
		variables: {
			args: { email: email },
		},
	});

	const { data: getLoggedInStripeId } = useQuery(GET_STRIPE_ID, {
		variables: {
			args: { email: loggedInUserDetails?.email },
		},
		onCompleted: data => {
			data &&
				loggedInUserDetails?.email &&
				dispatch(setStripeID(data?.getStripeId?.data?.id));
		},
	});

	useEffect(() => {
		if (email) {
			if (stripeIdData) {
				if (stripeIdData.getStripeId.data) {
					dispatch(setStripeID(stripeIdData?.getStripeId?.data?.id));
				} else {
					createCustomer({
						variables: {
							args: {
								name: username,
								email: email,
							},
						},

						awaitRefetchQueries: true,
						refetchQueries: [
							{
								query: GET_STRIPE_ID,
								variables: {
									args: { email: email },
								},
							},
						],
					});
				}
			}
		} else {
			// !getLoggedInStripeId &&
			// 	createCustomer({
			// 		variables: {
			// 			args: {
			// 				name: username,
			// 				email: email,
			// 			},
			// 		},
			// 		awaitRefetchQueries: true,
			// 		refetchQueries: [
			// 			{
			// 				query: GET_STRIPE_ID,
			// 				variables: {
			// 					args: { email: email },
			// 				},
			// 			},
			// 		],
			// 		onCompleted: (data) => {
			// 			data &&
			// 				data.getStripeId &&
			// 				data.getStripeId.data &&
			// 				dispatch(setStripeID(data.getStripeId.data.id))
			// 		}
			// 	});
		}
	}, [stripeIdData]);

	const [userId] = useState(
		useAppSelector(state => state.auth.loggedInUserDetails?._id)
	);

	const runLeftSidebarQueriesBasedOnTypeOfAccount = (
		typeOfAccount: TTypeOfAccount,
		userId: string
	) => {
		if (typeOfAccount === 'SELLER' || typeOfAccount === 'HOST') {
			getUpcomingEvents({
				variables: {
					status: 'UPCOMING',
					// find: {
					// 	startingEventDateTime: {
					// 		'$gte': Date.now().toString(),
					// 	},
					// 	...getQueryFieldForUser(typeOfAccount, userId),
					// },
					limit: 3,
				},
			});

			getPublishedEvents({
				variables: {
					status: 'PUBLISHED',
					// find: {
					// 	statusOfEvent: 'PUBLISHED',
					// 	...getQueryFieldForUser(typeOfAccount, userId),
					// },
					limit: 3,
				},
			});
		}

		if (typeOfAccount === 'CONSUMER') {
			getConsumerPendingEvents({
				variables: {
					// status: 'PENDING_APPROVAL',
					// userId,
					limit: 3,
				},
			});
			getUpcomingPlannedEventsForConsumers({
				variables: {
					status: 'UPCOMING',
					find: upcomingEventsVariable as any,
					// userId,
				},
			});
		}
	};

	const runEventsAffiliatedQueryBasedOnTypeOfAccount = useCallback(
		(typeOfAccount: TTypeOfAccount, current: number) => {
			if (typeOfAccount === 'SELLER') {
				getEventsAffiliatedWithFollowing({
					variables: {
						limit: LIMIT,
						current: current,
						sort: { startingEventDateTime: 1 },
					},
				});
			}

			if (typeOfAccount === 'HOST') {
				getEventsAffiliatedWithFollowing({
					variables: {
						limit: LIMIT,
						current: current,
						sort: { startingEventDateTime: 1 },
						// find: JSON.stringify({
						// 	statusOfEvent: 'PUBLISHED',
						// }),
						// current: current,
					},
				});
			}

			if (typeOfAccount === 'CONSUMER') {
				getEventsAffiliatedWithFollowing({
					variables: {
						limit: LIMIT,
						current: current,
						sort: { startingEventDateTime: 1 },
						// find: JSON.stringify({
						// 	startingEventDateTime: {
						// 		'$gte': Date.now().toString(),
						// 	},
						// }),
						// current: current,
					},
				});
			}
		},
		[]
	);

	const getAffiliatedEventHeadings = (
		typeOfAccount?: TTypeOfAccount
	): string => {
		if (!typeOfAccount) return t('Events Affiliated with Following');

		switch (typeOfAccount) {
			case 'SELLER':
				return t('Events Affiliated with Following');
			case 'HOST':
				return t('Published events with following');
			case 'CONSUMER':
				return t('Upcoming events with following');
			default:
				return t('Events Affiliated with Following');
		}
	};

	useEffect(() => {
		const typeOfAccount = loggedInUserDetails?.typeOfAccount;
		const userId = loggedInUserDetails?._id;
		if (!!typeOfAccount && userId) {
			runLeftSidebarQueriesBasedOnTypeOfAccount(typeOfAccount, userId);
			runEventsAffiliatedQueryBasedOnTypeOfAccount(typeOfAccount, 0);
		}
	}, []);

	const [getUpcomingEvents, { loading: upcomingEventsLoading }] =
		useLazyQuery<TGetAllEventsResponse>(GET_UPCOMING_EVENTS, {
			onCompleted: data => {
				if (
					data.getAllEvents &&
					data.getAllEvents.data &&
					data.getAllEvents.success
				) {
					setUpcomingEvents(data?.getAllEvents?.data?.data);
				}
			},
			onError: () => {},
		});

	const [
		getConsumerPendingEvents,
		{ loading: consumerPendingEventsLoading },
	] = useLazyQuery<
		TGetAllConsumerPendingEventsResponse,
		TGetAllConsumerPendingEventsInputs
	>(GET_CONSUMER_PENDING_EVENTS, {
		onCompleted: data => {
			if (
				data.getPendingEvents &&
				data.getPendingEvents.data &&
				data.getPendingEvents.data.data &&
				data.getPendingEvents.success
			) {
				const filteredExpiredEvents =
					data.getPendingEvents.data.data.sort(
						(a, b) =>
							+a.startingEventDateTime - +b.startingEventDateTime
					);
				setConsumerPendingEvents(filteredExpiredEvents);
			}
		},
	});

	const [
		getUpcomingPlannedEventsForConsumers,
		{ loading: upcomingPlannedEventsForConsumersLoading },
	] = useLazyQuery<
		TGetUpcomingPlannedConsumersEventsResponse,
		TGetUpcomingPlannedConsumersEventsInputs
	>(GET_UPCOMING_PLANNED_EVENTS_FOR_CONSUMERS, {
		onCompleted: (data: any) => {
			if (
				data.getAllEvents &&
				data.getAllEvents.data &&
				data.getAllEvents.success &&
				data.getAllEvents.data.data
			) {
				const hours = 4 * 60 * 60 * 1000;
				const hourDate = new Date(hours).getTime();

				const filteredExpiredEvents = data.getAllEvents.data.data
					.filter((event: any) => {
						return (
							+event?.startingEventDateTime + hourDate >
							Date.now()
						);
					})
					.sort(
						(a: any, b: any) =>
							+a.startingEventDateTime - +b.startingEventDateTime
					);

				setUpcomingConsumerPlannedEvents(filteredExpiredEvents);
			}
		},
	});

	const [getPublishedEvents, { loading: publishedEventsLoading }] =
		useLazyQuery<TGetAllEventsResponse, TPaginatedOptions>(
			GET_ALL_PUBLISHED_EVENTS_FOR_EVENT_SCREEN,
			{
				onCompleted: (data: any) => {
					if (
						data.getPublishedEvents &&
						data.getPublishedEvents.data &&
						data.getPublishedEvents.success
					) {
						setPublishedEvents(
							data?.getPublishedEvents?.data?.data
						);
					}
				},
			}
		);

	const [
		getEventsAffiliatedWithFollowing,
		{ loading: eventsAffiliatedWithFollowingLoading },
	] = useLazyQuery<
		TGetAllPersonalizedEventsResponse,
		Omit<TPaginatedOptions, 'find'> & { find?: string }
	>(GET_PERSONALIZED_EVENTS, {
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			if (
				data.getPersonalizedEvents &&
				data.getPersonalizedEvents.data &&
				data.getPersonalizedEvents.success
			) {
				setEventsAffiliatedWithFollowing([
					...eventsAffiliatedWithFollowing,
					...data.getPersonalizedEvents.data.data,
				]);
				dispatch(
					setEventsAffiliatedWithFollowing_([
						...eventsAffiliatedWithFollowing,
						...data.getPersonalizedEvents.data.data,
					])
				);
			}
		},
		onError: () => {},
	});

	const eventsAffiliatedWithFollowingGlobal = useAppSelector(
		state => state.getEvent.eventsAffiliatedWithFollowing
	);
	const [isSubscribedToEvent] = useLazyQuery(IS_SUBSCRIBED_TO_EVENTS, {
		onCompleted: data =>
			setIsSubscribedArray(data?.isSubscribedToEvent?.data?.data),
	});
	useEffect(() => {
		if (loggedInUserDetails?.typeOfAccount !== 'SELLER') {
			const eventIds = eventsAffiliatedWithFollowing.map(
				event => event._id
			);
			dispatch(setEventIds(eventIds));
			isSubscribedToEvent({
				variables: {
					eventIds,
				},
			});
		}
	}, [eventsAffiliatedWithFollowing]);

	const [getSuggestedBrands, { loading: suggestedBrandsLoading }] =
		useLazyQuery(GET_BRANDS_SELECTED_FOR_YOU, {
			onCompleted: data => {
				if (
					data.getBrandsSelectedForYou &&
					data.getBrandsSelectedForYou.data &&
					data.getBrandsSelectedForYou.success &&
					!suggestedBrandsData
				) {
					setSuggestedBrandsData(
						data.getBrandsSelectedForYou.data.data
					);
				}
			},
		});

	const [getSuggestedHosts, { loading: suggestedHostsLoading }] =
		useLazyQuery<TGetAllHostsResponse, TGetAllUsersInputs>(GET_ALL_HOSTS, {
			onCompleted: data => {
				if (
					data.getAllHosts &&
					data.getAllHosts.data &&
					data.getAllHosts.success &&
					!suggestedHostsData
				) {
					setSuggestedHostsData(data.getAllHosts.data.data);
				}
			},
			onError: () => {},
		});

	const [getAllSellers, { loading: suggestedSellersLoading }] = useLazyQuery<
		TGetAllSellersResponse,
		TGetAllUsersInputs
	>(GET_ALL_SELLERS, {
		onCompleted: data => {
			if (
				data.getAllSellers &&
				data.getAllSellers.data &&
				data.getAllSellers.success &&
				!suggestedSellersData
			) {
				setSuggestedSellersData(data.getAllSellers.data.data);
			}
		},
	});

	const [getAllConsumers, { loading: suggestedConsumersLoading }] =
		useLazyQuery<TGetAllConsumersResponse, TGetAllUsersInputs>(
			GET_ALL_CONSUMERS,
			{
				onCompleted: data => {
					if (
						data.getAllConsumers &&
						data.getAllConsumers.data &&
						data.getAllConsumers.success &&
						!suggestedConsumersData
					) {
						setSuggestedConsumersData(
							data.getAllConsumers.data.data
						);
					}
				},
			}
		);

	const runQueryBasedOnTypeOfAccount = (typeOfAccount: TTypeOfAccount) => {
		if (typeOfAccount === 'SELLER' || typeOfAccount === 'CONSUMER') {
			getSuggestedHosts({
				variables: {
					limit: 5,
				},
			});

			getSuggestedBrands({
				variables: {
					limit: 5,
					productCurrent: 0,
					productLimit: 1,
				},
			});
		}

		if (typeOfAccount === 'HOST' || typeOfAccount === 'CONSUMER') {
			getAllSellers({
				variables: {
					limit: 5,
				},
			});
		}

		if (typeOfAccount === 'HOST') {
			getAllConsumers({
				variables: {
					limit: 5,
				},
			});
		}
	};

	useEffect(() => {
		runQueryBasedOnTypeOfAccount(
			loggedInUserDetails?.typeOfAccount ?? 'SELLER'
		);
	}, []);

	const {
		executeFollowingStatusQuery,
		loading: loading_status,
		data,
	} = useGetStatus();

	useEffect(() => {
		if (data) {
			const status = data?.getUserStatus?.data?.data;
			if (status && status.length && !statusUpdate) {
				setStatusPost(prev => [...prev, ...status]);
			} else {
				status &&
					status.length &&
					setStatusPost(prev => [status[0], ...prev]);
			}
		}
	}, [data]);

	const [skipDoc, setSkipDoc] = useState(0);

	const [showLoadMore, setShowLoadMore] = useState(true);

	useEffect(() => {
		const statusTotal = data?.getUserStatus?.data?.total;
		if (statusTotal) {
			statusTotal === 0 && setShowLoadMore(false);
		}
		if (statusPost.length === statusTotal) setShowLoadMore(false);
	}, [statusPost, data]);

	useEffect(() => {
		executeFollowingStatusQuery({ skipDoc });
	}, [skipDoc]);

	const onPressViewAllForSidebarEvents = () => {
		navigation.navigate('Events');
	};

	const onPressSeeAllForSidebarUsers = () => {
		navigation.navigate('Network');
	};

	const onPressSeeAllForSidebarBrands = () => {
		navigation.navigate('Brands');
	};

	const loadingState = useMemo(
		() =>
			getSingleLoadingState(
				suggestedBrandsLoading,
				// followedEventsLoading,
				upcomingEventsLoading,
				publishedEventsLoading,
				suggestedHostsLoading,
				suggestedSellersLoading,
				suggestedConsumersLoading
			),
		[
			suggestedBrandsLoading,
			// followedEventsLoading,
			upcomingEventsLoading,
			publishedEventsLoading,
			suggestedHostsLoading,
			suggestedSellersLoading,
			suggestedConsumersLoading,
		]
	);

	const [isFollowedIds, setIsFollowedIds] = useState<string[]>();
	const [isFollowedBrandIds, setIsFollowedBrandIds] = useState<string[]>();
	useEffect(() => {
		const followedIds: string[] = [];
		const followedBrandIds: string[] = [];
		if (suggestedHostsData) {
			suggestedHostsData.map(item => followedIds.push(item._id));
		}
		if (suggestedBrandsData) {
			suggestedBrandsData.map(item => followedBrandIds.push(item._id));
		}
		if (suggestedSellersData) {
			suggestedSellersData.map(item => followedIds.push(item._id));
		}
		if (suggestedConsumersData) {
			suggestedConsumersData.map(item => followedIds.push(item._id));
		}

		setIsFollowedIds(followedIds);
		setIsFollowedBrandIds(followedBrandIds);
		dispatch(setFollowedIds(followedIds));
	}, [
		suggestedBrandsData,
		suggestedConsumersData,
		suggestedHostsData,
		suggestedSellersData,
	]);

	const [isFollowingArray, setIsFollowingArray] = useState<
		TIsFollowingData[]
	>([]);
	const [isFollowingBrandArray, setIsFollowingBrandArray] = useState<
		TIsFollowingData[]
	>([]);

	const [checkFollowing] = useLazyQuery<
		TIsFollowingResponse,
		TIsFollowingInputs
	>(IS_FOLLOWING, {
		onCompleted: data => {
			if (
				data &&
				data.IsFollowing &&
				data.IsFollowing.data &&
				data.IsFollowing.data.data
			) {
				setIsFollowingArray(data?.IsFollowing?.data?.data);
				dispatch(setIsFollowingResult(data?.IsFollowing?.data?.data));
			}
		},
	});
	const [checkBrandFollowing] = useLazyQuery<
		TIsFollowingResponse,
		TIsFollowingInputs
	>(IS_FOLLOWING, {
		onCompleted: data => {
			if (
				data &&
				data.IsFollowing &&
				data.IsFollowing.data &&
				data.IsFollowing.data.data
			) {
				setIsFollowingBrandArray(data?.IsFollowing?.data?.data);
			}
		},
	});

	const netWorkFollowIds = useAppSelector(
		state => state.user.networkFollowedIds
	);
	useEffect(() => {
		!!isFollowedIds &&
			checkFollowing({
				variables: {
					args: {
						followedIds: netWorkFollowIds.length
							? netWorkFollowIds
							: isFollowedIds,
						type: 'USER',
					},
				},
			});
		!!isFollowedBrandIds &&
			checkBrandFollowing({
				variables: {
					args: {
						followedIds: isFollowedBrandIds,
						type: 'BRAND',
					},
				},
			});
	}, [isFollowedIds]);

	// follow me unfollow me for guest
	const [requestSendBooleanArray, setRequestSendBooleanArray] = useState<
		TIsFollowMeRequestData[]
	>([]);

	const [
		getIfRequestHaveBeenSent,
		{ loading: loadingIsFollowingMeRequestSent },
	] = useLazyQuery<
		TIsFollowMeRequestSentResponse,
		TIsFollowMeRequestSentInputs
	>(IS_FOLLOWING_ME_REQUEST_SENT, {
		onCompleted: data => {
			if (
				data.IsFollowMeRequestSent.data &&
				data.IsFollowMeRequestSent.success
			) {
				setRequestSendBooleanArray(
					data.IsFollowMeRequestSent.data.data
				);
			}
		},
		onError: () => {},
	});

	const [suggestedConsumersID, setSuggestedConsumersID] = useState<string[]>(
		[]
	);
	useEffect(() => {
		if (suggestedConsumersID) {
			getIfRequestHaveBeenSent({
				variables: {
					args: {
						ids: suggestedConsumersID,
					},
				},
			});
		}
	}, [suggestedConsumersID]);

	const { setAlert } = useSetAlert();

	useEffect(() => {
		let dataArray: string[] = [];
		suggestedConsumersData &&
			suggestedConsumersData.map(consumer =>
				dataArray.push(consumer._id)
			);

		setSuggestedConsumersID(dataArray);
	}, [suggestedConsumersData]);

	const refetchRequestSentStatusQuery = [
		{
			query: IS_FOLLOWING_ME_REQUEST_SENT,
			variables: {
				args: {
					ids: suggestedConsumersID ?? [],
				},
			} as TIsFollowMeRequestSentInputs,
		},
	];

	const [followMe, { loading: loadingFollowMe }] = useMutation<
		TFollowMeResponse,
		TFollowMeInputs
	>(FOLLOW_ME, {
		awaitRefetchQueries: true,
		refetchQueries: refetchRequestSentStatusQuery,
		onCompleted: data => {
			if (data.FollowMe.success && data.FollowMe.statusCode === 200) {
				setAlert(t('Follow request sent!'), 'normal');
			} else {
				setAlert(t('Unable to send follow request!'), 'danger');
			}
		},
		onError: () => {
			setAlert(t('Unable to send follow request!'), 'danger');
		},
	});

	// const isFollowingMeArray = useAppSelector(state => state.user.isFollowingMe)
	// isFollowingMeArray.find(i => i.user)

	// const [selected, setSelected] = useState('');
	const [
		followMeUnfollowMeButtonPressedId,
		setFollowMeUnfollowMeButtonPressedId,
	] = useState('');
	// const setSelectednow = async (userId: string) => {
	// 	await setSelected(userId);
	// };

	const onClickFollowMe = async (
		recipientId: string,
		hasRequestBeenSent: boolean | undefined,
		index?: number
	) => {
		const data = suggestedConsumersID;
		const userId = data?.find((_, i) => i === index);

		// if (user === undefined) {
		// 	setAlert(t('Unable to send request'), 'danger');
		// }

		// userId && setSelectednow(userId);
		setFollowMeUnfollowMeButtonPressedId(userId ?? '');
		const variables = {
			args: {
				recipientId,
			},
		};

		hasRequestBeenSent
			? //  || isFollowingMe
			  unFollowMe({
					variables,
			  })
			: followMe({
					variables,
			  });
	};

	const [unFollowMe, { loading: loadingUnfollowme }] = useMutation<
		TUnfollowMeResponse,
		TUnfollowMeInputs
	>(UNFOLLOW_ME, {
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: IS_FOLLOWING_ME,
				variables: {
					args: {
						followeMeIds: suggestedConsumersID,
					},
				},
			},
		],
		onCompleted: data => {
			if (data.UnFollowMe.success && data.UnFollowMe.statusCode === 200) {
				setAlert(
					'Successfully removed user from your followers',
					'normal'
				);
			} else {
				setAlert('Unable to remove user from your followers', 'danger');
			}
		},
		onError: () => {
			setAlert('Unable to remove user from your followers', 'danger');
		},
	});

	const [isFollowingMeArray, setIsFollowingMeArray] = useState<TODO[]>([]);
	const [checkIsFollowingMe] = useLazyQuery(IS_FOLLOWING_ME, {
		variables: {
			args: {
				followeMeIds: suggestedConsumersID,
			},
		},
		onCompleted: data => {
			data && setIsFollowingMeArray(data.IsFollowingMe?.data?.data);
		},
	});

	useEffect(() => {
		checkIsFollowingMe();
	}, [suggestedConsumersID]);

	const removeUserStatusFromState = (id: string) => {
		const filterStatusWithFollowExpect = statusPost?.filter(
			post => post?.user?._id !== id
		);
		setStatusPost(filterStatusWithFollowExpect);
	};

	useEffect(() => {
		if (deletedStatusId) {
			const filterStatusWithFollowExpect = statusPost?.filter(
				post => post?._id !== deletedStatusId
			);
			setStatusPost(filterStatusWithFollowExpect);
		}
	}, [deletedStatusId]);

	const onEndReached = () => {
		const updatedCurrentValue = eventsAffiliatedCurrent + 1;

		setEventsAffiliatedCurrent(updatedCurrentValue);

		loggedInUserDetails &&
			runEventsAffiliatedQueryBasedOnTypeOfAccount(
				loggedInUserDetails?.typeOfAccount,
				updatedCurrentValue
			);
	};

	/* -------------------------------------------------------------------------- */
	/*                                Event Details                               */
	/* -------------------------------------------------------------------------- */

	const [getEventDetails, { loading: loadingGetEventDetails }] = useLazyQuery<
		TGetSingleEventResponse,
		{ _id: string }
	>(GET_SINGLE_EVENT, {});

	const [getAllInvitedHosts, { loading: loadingAllInvitedHosts }] =
		useLazyQuery<TGetUsersByIds, TGetUsersByIdsInputs>(
			GET_ALL_USERS_BY_IDS,
			{
				onCompleted: data => {
					if (
						data &&
						data.getUsersByIds &&
						data.getUsersByIds.data &&
						data.getUsersByIds.success
					) {
						dispatch(setHostsInvited(data.getUsersByIds.data.data));
					}
				},
			}
		);

	const [idOfEventToAddHost, setIdOfEventToAddHost] = useState<string>();

	const onPressAddHostButton = useCallback(async (eventId: string) => {
		setIdOfEventToAddHost(eventId);
		dispatch(setIsVisitingAddHostScreenFromEventsScreen(true));
		dispatch(setIsEditEvent(false));
		const data = await getEventDetails({
			variables: {
				_id: eventId,
			},
		});

		if (data?.data?.getEvent?.data) {
			dispatch(setEventToAddHostsTo(data.data.getEvent.data));
			const hostIds = data.data.getEvent.data.affiliatedHosts?.map(
				host => host._id
			);
			dispatch(setHostsInvitedIds(hostIds));

			if (!!hostIds && hostIds.length > 0) {
				await getAllInvitedHosts({
					variables: {
						_ids: hostIds,
					},
				});
			}
			navigation.navigate('EventHost');
			dispatch(setCurrentEvent(data.data.getEvent.data));
		}
	}, []);

	const addHostLoadingState = useMemo(() => {
		return getSingleLoadingState(
			loadingAllInvitedHosts,
			loadingGetEventDetails
		);
	}, [loadingAllInvitedHosts, loadingGetEventDetails]);
	return {
		shouldHideScreen,
		isLessThanDesktopBase,
		loggedInUserDetails,
		upcomingEvents,
		upcomingEventsLoading,
		publishedEvents,
		publishedEventsLoading,
		upcomingConsumerPlannedEvents,
		upcomingPlannedEventsForConsumersLoading,
		consumerPendingEvents,
		consumerPendingEventsLoading,
		userId,
		eventsAffiliatedWithFollowingLoading,
		eventsAffiliatedWithFollowing,
		eventsAffiliatedWithFollowingGlobal,
		addHostLoadingState,
		idOfEventToAddHost,
		isSubscribedArray,
		statusPost,
		loading_status,
		showLoadMore,
		skipDoc,
		suggestedBrandsData,
		suggestedBrandsLoading,
		isFollowingArray,
		suggestedHostsData,
		suggestedHostsLoading,
		suggestedSellersData,
		suggestedSellersLoading,
		isFollowingBrandArray,
		suggestedConsumersData,
		suggestedConsumersLoading,
		followMeUnfollowMeButtonPressedId,
		loadingFollowMe,
		loadingUnfollowme,
		requestSendBooleanArray,
		isFollowingMeArray,
		setSkipDoc,
		getAffiliatedEventHeadings,
		onClickFollowMe,
		onPressSeeAllForSidebarBrands,
		onPressSeeAllForSidebarUsers,
		onEndReached,
		onPressAddHostButton,
		setStatusUpdate,
		executeFollowingStatusQuery,
		onPressViewAllForSidebarEvents,
	};
};
