import { resetBeforeRunningEventNavFunc } from '../../redux/getEventSlice';
import { getStateOfEvent } from './getStateOfEvent';
import { handleNavigationForConsumer } from './handleNavigationForConsumer';
import { handleNavigationForSellerAndHost } from './handleNavigationForSellerAndHost';
import {
	THandleNavigationParams,
	THandleNavigationToEvent,
} from './handleNavigationToEvent.type';
import { navigateToEventDetails } from './navigateToEventDetails';

export const handleNavigationToEvent = ({
	navigation,
	loggedInUserDetails,
	event,
	dispatch,
}: THandleNavigationToEvent) => {
	dispatch(resetBeforeRunningEventNavFunc());

	if (
		event?.typeOfEvent === 'PHYSICAL' ||
		(!event?.isHostApplicationAccepted && event?.isHostRequired)
	) {
		navigateToEventDetails(navigation, event?._id);
		return;
	}

	const typeOfAccount = loggedInUserDetails?.typeOfAccount;

	const stateOfEvent = getStateOfEvent(
		event.eventStreamTimeline,
		event.startingEventDateTime
	);

	const handleNavigationParams: THandleNavigationParams = {
		event,
		stateOfEvent,
		loggedInUserDetails,
		navigation,
		dispatch,
	};

	/**
	 * Kept separate functions even though there is duplication
	 * & similarity for easy readability and understanding. The
	 * functions are similar but there are minor differences
	 * which would need conditions, making code complex and
	 * difficult to follow
	 **/
	typeOfAccount === 'CONSUMER'
		? handleNavigationForConsumer(handleNavigationParams)
		: handleNavigationForSellerAndHost(handleNavigationParams);
};
