import { useLazyQuery } from '@apollo/client';
import { GET_LIVEEVENTMESSSAGE } from '../../graphql/chat/queries';
import { useAppDispatch } from '../../redux/store';

const useGetLiveEventMessage = () => {
	const dispatch = useAppDispatch();

	const [getConversation, { data, error, loading }] = useLazyQuery(
		GET_LIVEEVENTMESSSAGE,
		{
			fetchPolicy: 'no-cache',
		}
	);

	const executeGetLiveEventMessage = ({
		eventId,
	}: {
		eventId: string | undefined;
	}) => {
		getConversation({
			variables: {
				eventId,
			},
		});
	};
	return { executeGetLiveEventMessage, data, error, loading };
};

export default useGetLiveEventMessage;
