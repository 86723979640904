import React from 'react';
import { View, StyleSheet } from 'react-native';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
// import Skeleton from './Skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type Props = {
	hasBorderRight?: boolean;
};

export const StatsSkeleton = ({ hasBorderRight = false }: Props) => {
	return (
		<View
			style={[
				styles.container,
				hasBorderRight && styles.containerBorderRight,
			]}
		>
			<Skeleton
				width={getResponsiveStyle(24)}
				height={getResponsiveStyle(21)}
				style={{ marginBottom: getResponsiveStyle(5), borderRadius: 6 }}
			/>
			<Skeleton
				width={getResponsiveStyle(80)}
				height={getResponsiveStyle(12)}
				style={{ borderRadius: 6 }}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		width: '50%',
	},
	containerBorderRight: {
		borderRightWidth: 1,
	},
});
