import { useMutation } from '@apollo/client';
import { CREATE_PENDING_TRANSFER } from '../../graphql/stripe/mutations';

export const useCreatePendingTransfer = () => {
	type TQueryArgs = {
		sellerId: string;
		amount: number;
		chargeId: string;
		source_transaction: string;
		type?: 'BRAND' | 'SELLER';
	};
	type TQueryFn = (args: TQueryArgs) => void;
	const createPendingTransfer: TQueryFn = ({
		sellerId,
		amount,
		chargeId,
		source_transaction,
		type,
	}) => {
		let args = {
			sellerId,
			amount,
			chargeId,
			source_transaction,
			type,
		};

		createPendingTransfer_({
			variables: {
				args,
			},
		});
	};
	const [createPendingTransfer_] = useMutation(CREATE_PENDING_TRANSFER);

	return createPendingTransfer;
};
