import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';
import Profile from '../../common/profile/Profile';
import Loader from '../../../components/common/loader/Loader';
import { SectionHeading } from '../..';
import { t } from 'i18next';
import { TTopSidebarProfile } from '../Profile-Accounts/ProfileAccounts';
import ProfileListCardSkeleton from '../../common/skeleton-loaders/profile-card/ProfileListCardSkeleton';
import TopProfileSkeleton from '../../common/skeleton-loaders/profile-card/TopProfile';

type Props = {
	heading: string;
	profiles?: TTopSidebarProfile[];
	loading?: boolean;
};

const TopProfiles = ({ heading, profiles, loading }: Props) => {
	return (
		<View style={styles.container}>
			<View style={styles.headerContainer}>
				<Text style={styles.header}>
					{t('Top')} {heading}
				</Text>
				{/* <SectionHeading heading = {t(`Top ${heading}`)} hasSeeAllLabel/> */}
			</View>
			<View style={styles.profileListContainer}>
				{loading ? (
					<>
						<TopProfileSkeleton />
						<TopProfileSkeleton />
						<TopProfileSkeleton />
						<TopProfileSkeleton />
					</>
				) : profiles && profiles.length > 0 ? (
					profiles.map(profile => {
						return (
							<Profile
								key={profile.id}
								name={profile?.username}
								city={profile.city}
								countryCode={profile.countryCode}
								country={profile.country}
								profileImage={profile?.profileImage}
								isFollowing={false}
								id={profile.id}
								isBrandProfile={profile.brandId ? true : false}
								brandDetails={
									profile.brandId && {
										address: profile.address,
										name: profile.username,
										profileImage: profile.profileImage,
										additionalMedia:
											profile.additionalMedia,
										rating: profile.rating,
										description: profile.description,
										products: profile.products,
										// brandStatsArray: brandStatsArray,
										// followUser: followUser,
									}
								}
							/>
						);
					})
				) : (
					heading !== 'Hosts' &&
					heading !== 'Brands' && <Text>{t('No profile found')}</Text>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(22),
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header: {
		fontSize: getResponsiveStyle(18, 'font'),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	profileListContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: getResponsiveStyle(16),
		marginTop: getResponsiveStyle(24),
	},
});

export default TopProfiles;
