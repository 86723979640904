import * as React from 'react';
import Svg, {
	SvgProps,
	Path,
	Defs,
	LinearGradient,
	Stop,
} from 'react-native-svg';

const PeopleIconFilled = (props: SvgProps) => (
	<Svg viewBox="0 0 24 24" fill="none" height={34} width={34}>
		<Path
			d="M3.243 4.054a4.054 4.054 0 1 1 8.108 0 4.054 4.054 0 0 1-8.108 0Z"
			fill="url(#People-a)"
		/>
		<Path
			d="M10.918.413a5.119 5.119 0 0 1 1.514 3.641 5.119 5.119 0 0 1-1.514 3.641 4.054 4.054 0 1 0 0-7.282Z"
			fill="url(#People-b)"
		/>
		<Path
			d="M0 13.784A4.054 4.054 0 0 1 4.054 9.73h6.486a4.054 4.054 0 1 1 0 8.108H4.054A4.054 4.054 0 0 1 0 13.784Z"
			fill="url(#People-c)"
		/>
		<Path
			d="M15.676 13.784c0 1.108-.351 2.134-.948 2.973h1.218a4.054 4.054 0 0 0 0-8.108H10.54a5.135 5.135 0 0 1 5.135 5.135Z"
			fill="url(#People-d)"
		/>
		<Defs>
			<LinearGradient
				id="People-a"
				x1={3.262}
				y1={4.459}
				x2={20.674}
				y2={21.924}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="People-b"
				x1={3.262}
				y1={4.459}
				x2={20.674}
				y2={21.924}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="People-c"
				x1={3.262}
				y1={4.459}
				x2={20.674}
				y2={21.924}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="People-d"
				x1={3.262}
				y1={4.459}
				x2={20.674}
				y2={21.924}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
		</Defs>
	</Svg>
);

export default PeopleIconFilled;
