import { gql } from '@apollo/client';

export const CREATE_CART = gql`
	mutation createCart($args: CreateCartInputs!) {
		createCart(args: $args) {
			... on Response {
				success
				statusCode
				data {
					... on Cart {
						_id
						items {
							type
							quantity
							eventId {
								_id
								startingEventDateTime
								affiliatedHosts {
									_id
								}
								affiliatedSeller {
									_id
									email
									connectAccountInfo {
										connectAccountId
									}
								}
								eventStreamTimeline {
									startedAt
									endedAt
								}
								isHostRequired
								name
							}
							starterkitId {
								_id
								name
								description
								price
								brand {
									name
									_id
									connectAccountInfo {
										connectAccountId
										lastUrl
									}
								}
								# variants {
								# 	name
								# 	description
								# 	publishedStatus
								# 	images {
								# 		name
								# 		alt
								# 		src
								# 	}
								# }
								compareAtPrice
							}
							productId {
								_id
								variator
								value

								image {
									src
								}
								option {
									name
									values {
										compareAtPrice
										price
										name
									}

									inventory {
										quantity
										isTracked
										isAvailable
									}
								}
								vat
								associatedProduct {
									name
									description
									category
									publishedStatus
									brand {
										name
										_id
										connectAccountInfo {
											connectAccountId
											lastUrl
										}
									}
									images {
										name
										alt
										src
										type
									}
								}
							}
						}
						associatedUser {
							_id
						}
					}
				}
			}
		}
	}
`;

export const Edit_CART = gql`
	mutation editCart($args: EditCartInputs!) {
		editCart(args: $args) {
			... on Response {
				success
				statusCode
				data {
					... on Cart {
						items {
							eventId {
								_id
								startingEventDateTime
							}
						}
					}
				}
			}
		}
	}
`;

export const UPDATE_CART_QTY = gql`
	mutation updateCartQty($args: UpdateCartQtyInputs!) {
		updateCartQty(args: $args) {
			... on Response {
				success
				statusCode
			}
		}
	}
`;

export const REMOVE_FROM_CART = gql`
	mutation removeCart($args: DeleteCartItemsInputs) {
		removeCart(args: $args) {
			... on Response {
				success
				statusCode
				data {
					__typename
				}
			}
		}
	}
`;

export const DELETE_CART = gql`
	mutation deleteCart {
		deleteCart {
			... on Response {
				success
				statusCode
			}
		}
	}
`;
