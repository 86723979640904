import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Image,
	ListRenderItem,
	Pressable,
	StyleSheet,
	View,
} from 'react-native';
import { DraggableScrollView } from '../..';
import { GET_USER_TABLE_STATS } from '../../../graphql/auth/queries';
import { FOLLOW, UNFOLLOW } from '../../../graphql/follow/mutations';
import { IS_FOLLOWING } from '../../../graphql/network/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import { ProfileCard } from './ProfileCard';
import { ProfileCardSkeleton } from '../../common/skeleton-loaders/profile-card/ProfileCardSkeleton';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';

type Props = {
	isHost?: boolean;
	isSearch?: boolean;
	hasStats?: boolean;
	hasActionBtn?: boolean;
	hasRating?: boolean;
	hasEventAttendedOnly?: boolean;
	isUserCard?: boolean;
	isInvited?: boolean;
	isShowViewProfileBtn?: boolean;
	showGender?: boolean;
	data?: TGetAllUsersData[];
	loading?: boolean;
	// onClickHostFollowMeActionButton?: (
	// 	id: string,
	// 	hasRequestBeenSent: boolean
	// ) => void;
	onClickHostFollowMeActionButton?: (
		id: string,
		hasRequestBeenSent: boolean | undefined,
		index?: number
		// isFollowingMe: boolean
	) => void;
	requestSendBooleanArray?: TIsFollowMeRequestData[];
	type?: string;
	unfollowrequestSendBooleanArray?: TIsFollowMeRequestData[];
	followMeUnfollowMeButtonPressedId?: string;
	loadingFollowMe?: boolean;
	isFollowingBooleanArray?: TIsFollowingData[];
	setSelected?: (userId: string) => void;
	eventsAttendedCount?: EventAttendedCount[];
};

const ProfileCards = ({
	isHost,
	isSearch = false,
	hasStats = true,
	hasActionBtn,
	hasEventAttendedOnly,
	hasRating,
	isUserCard,
	isInvited = false,
	showGender = true,
	data,
	loading = false,
	requestSendBooleanArray,
	onClickHostFollowMeActionButton,
	type,
	unfollowrequestSendBooleanArray,
	followMeUnfollowMeButtonPressedId,
	loadingFollowMe,
	isFollowingBooleanArray,
	setSelected,
	eventsAttendedCount,
	isShowViewProfileBtn,
}: Props) => {
	const isFollowingMeArray = useAppSelector(
		state => state.user.isFollowingMe
	);

	const refetchIsFollowing = [
		IS_FOLLOWING,
		GET_USER_TABLE_STATS,
		// {
		// 	query: IS_FOLLOWING,
		// 	variables: {
		// 		args: {
		// 			followedIds: userIds,
		// 		},
		// 	} as TIsFollowingInputs,
		// },
		// {
		// 	query:GET_USER_TABLE_STATS,
		// 	variables:{
		// 		id:userIds
		// 	}
		// }
	];

	const [followAccount, { loading: loadingFollowAccount }] = useMutation<
		TFollowResponse,
		TFollowInputs
	>(FOLLOW, {
		awaitRefetchQueries: true,
		refetchQueries: refetchIsFollowing,
		onCompleted: data => {
			if (data.Follow.success && data.Follow.statusCode === 200) {
				setFollowUnfollowButtonPressedId('');
			}
		},
		onError: () => {
			setFollowUnfollowButtonPressedId('');
		},
	});

	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const [followUnfollowButtonPressedId, setFollowUnfollowButtonPressedId] =
		useState('');
	const onPressFollowUnfollowButton = async (index: number) => {
		if (!data) {
			setAlert(t('Unable to follow user'), 'danger');
		}

		const user = data?.find((_, i) => i === index);

		if (user === undefined) {
			setAlert(t('Unable to follow user'), 'danger');
		}
		user && setSelected && (await setSelected(user._id));

		setFollowUnfollowButtonPressedId(user?._id ?? '');

		const isFollowingUser = isFollowingBooleanArray?.find(
			item => item.userId === user?._id
		)?.isFollowing;

		const variables = {
			args: {
				followedId: user?._id ?? '',
			},
		};

		isFollowingUser
			? unFollowAccount({
					variables,
			  })
			: followAccount({
					variables,
			  });
	};

	const [unFollowAccount, { loading: loadingUnfollow }] = useMutation<
		TUnfollowResponse,
		TUnfollowInputs
	>(UNFOLLOW, {
		awaitRefetchQueries: true,
		refetchQueries: refetchIsFollowing,
		onCompleted: data => {
			if (data.UnFollow.success && data.UnFollow.statusCode === 200) {
				setFollowUnfollowButtonPressedId('');
			}
		},
		onError: () => {
			setFollowUnfollowButtonPressedId('');
		},
	});

	const loadingState = getSingleLoadingState(
		loadingFollowAccount,
		loadingUnfollow
		// userStatLoading
	);

	const renderItem: ListRenderItem<TGetAllUsersData> = ({ item, index }) => {
		const hasRequestBeenSent = requestSendBooleanArray?.find(
			i => i.userId === item._id
		)?.isFollowRequestSent;

		const hasUnfollowRequestBeenSent =
			unfollowrequestSendBooleanArray?.find(
				i => i.userId === item._id
			)?.isUnFollowRequestSent;

		const isFollowingMe = isFollowingMeArray?.find(
			i => i.userId === item._id
		)?.isFollowingMe;

		const loading_ =
			followMeUnfollowMeButtonPressedId === item._id && loadingFollowMe;

		const eventAttendedCountByUser = eventsAttendedCount?.find(
			user => user.user === item._id
		)?.eventsAttendedCount;

		return (
			<ProfileCard
				item={item}
				isHost={isHost}
				hasActionBtn={hasActionBtn}
				hasEventAttendedOnly={hasEventAttendedOnly}
				hasRating={hasRating}
				isUserCard={isUserCard}
				isInvited={isInvited}
				showGender={showGender}
				hasFollowHostRequestSent={hasRequestBeenSent}
				onClickHostFollowMeActionButton={
					onClickHostFollowMeActionButton
				}
				type={type}
				hasUnfollowRequestBeenSent={hasUnfollowRequestBeenSent}
				// followMeUnfollowMeButtonPressedId={followMeUnfollowMeButtonPressedId}
				loadingFollowMe={loading_}
				isFollowingMe={isFollowingMe}
				index={index}
				isSearch={isSearch}
				hasStats={hasStats}
				onPressFollowButton={onPressFollowUnfollowButton}
				isFollowingArray={isFollowingBooleanArray}
				loadingFollowUser={loadingState}
				followUnfollowButtonPressedId={followUnfollowButtonPressedId}
				eventAttendedCountByUser={eventAttendedCountByUser}
				isShowViewProfileBtn={isShowViewProfileBtn}
			/>
		);
	};
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<Pressable style={{ width: '100%' }}>
			<View style={[styles.container, styleContainer()]}>
				{data && data.length > 0 ? (
					<>
						<DraggableScrollView
							horizontal
							showsHorizontalScrollIndicator={false}
							style={styleDraggableScroll()}
							data={data}
							renderItem={(item: any) => renderItem(item)}
						/>
						{!isLessThanDesktopBase && data.length >= 3 && (
							<Image
								source={require('../../../assets/slider-gradient.png')}
								style={styles.scrollGradient}
							/>
						)}
					</>
				) : (
					<>
						{loading ? (
							[1, 2, 3, 4, 5].map(() => (
								<ProfileCardSkeleton
									type={type}
									isUserCard={isUserCard}
								/>
							))
						) : (
							<View
								style={[
									styles.containerEmpty,
									styleContainer(),
								]}
							>
								<NotAvailableMessage
									message={t('No Result Found!')}
								/>
							</View>
						)}
					</>
				)}
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		zIndex: 1,
		position: 'relative',
	},
	scrollGradient: {
		width: '102px',
		height: '100%',
		position: 'absolute',
		right: '-50px',
		zIndex: 2,
		cursor: 'auto',
	},
	containerEmpty: {
		borderRadius: 6,
		backgroundColor: 'white',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		height: getResponsiveStyle(309),
		marginBottom: getResponsiveStyle(10),
		marginTop: getResponsiveStyle(2),
		marginLeft: getResponsiveStyle(10),
		// shadowOffset: {
		// 	width: 0,
		// 	height: 2,
		// },
		// shadowRadius: 7,
		// shadowColor: 'rgba(0, 0, 0, 0.09)',
		// shadowOpacity: 1,
	},
});

const styleDraggableScroll = (): TStyle => {
	return [
		styles.container,
		{
			maxWidth: '100%',
			paddingBottom: getResponsiveStyle(20),
		},
	];
};

export default ProfileCards;

const styleContainer = () => {
	return { marginBottom: getResponsiveStyle(30) };
};
