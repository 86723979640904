enum UserType {
	BRAND = 0,
	CONSUMER,
	HOST,
	SELLER,
}

const user = UserType.SELLER;

export default user;
