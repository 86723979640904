import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import useInviteAllFollowers from '../api/events/useInviteAllFollowers';
import { useUpdateNewEvent } from '../api/events/useUpdateNewEvent';
import LottieMark from '../assets/LottieFIles/LottieMark.json';
import { AuthTemplate, Button } from '../components';
import { useSetAlert } from '../hooks/useSetAlerts';
import { setIsHostEditingEvent } from '../redux/getEventSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { TUseNavigation } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const EventCompleteScreen = () => {
	const navigation = useNavigation<TUseNavigation>();
	const { setAlert } = useSetAlert();

	const { isEditEvent, isAffiliatedHostEditingEvent } = useAppSelector(
		state => state.getEvent
	);

	const { isInviteAllGuest, isInviteAllHost } = useAppSelector(state => ({
		isInviteAllGuest: state.ui.isInviteAllGuest,
		isInviteAllHost: state.ui.isInviteAllHost,
	}));

	const isHostEditingLocation = useAppSelector(
		state => state.getEvent.isHostEditingEvent
	);

	const { executeInviteAllFollowersMutation } = useInviteAllFollowers();
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setCurrentScreen('/event-complete'));
	}, []);

	const onCompletedUpdateEvent = (data: any) => {
		if (data.editEvent.success && data.editEvent.data) {
			if (isInviteAllGuest) {
				executeInviteAllFollowersMutation({
					eventId: data?.editEvent?.data?._id,
					typeOfAccount: 'CONSUMER',
				});
			}

			if (isInviteAllHost) {
				executeInviteAllFollowersMutation({
					eventId: data?.editEvent?.data?._id,
					typeOfAccount: 'HOST',
				});
			}
			setAlert(t('Event updated successfully!'), 'normal');

			isHostEditingLocation && dispatch(setIsHostEditingEvent());
			navigation.navigate('Home');
		} else {
			setAlert(t('Event update failed!'), 'danger');
		}
	};

	const { updateNewEvent } = useUpdateNewEvent({
		onCompleted: onCompletedUpdateEvent,
	});

	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const { t } = useTranslation();
	return (
		<AuthTemplate
			heading={t('All set up!')}
			subText={t('Your event is under review.')}
			scrollItems={createEventSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<Lottie
				loop={false}
				animationData={LottieMark}
				play
				style={{ width: '100&', height: '45%' }}
			/>
			<Button
				title={t('Done')}
				outerContainerProps={{
					style: { marginTop: getResponsiveStyle(70) },
				}}
				variant="primary"
				size="lg"
				onPress={() => {
					if (isEditEvent && isAffiliatedHostEditingEvent) {
						updateNewEvent();
						return;
					} else if (isAffiliatedHostEditingEvent) {
						updateNewEvent();
						return;
					}
					navigation.navigate('Home');
					// setAlert(
					// 	t('Your event is awaiting approval from Admin') + '.',
					// 	'normal'
					// );
				}}
			/>
		</AuthTemplate>
	);
};

export default EventCompleteScreen;
