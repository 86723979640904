import { StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { Button } from '../..';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { NotAvailableMessage } from '../..';
import VoucherComponent from './VoucherComponent';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useTranslation } from 'react-i18next';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import {
	SelectedVoucher,
	setSelectedVoucher,
} from '../../../redux/walletSlice';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../../types/exportedTypes';
interface Brand_ {
	_id: string;
	name: string;
	address: {
		city: string;
		countryLabel: string;
	};
}
export interface Voucher {
	_id: string;
	brandName: string;
	description: string;
	amountLeft: number;
	country: string;
	brandId: Brand_;
}

type TProps = {
	onCheckout?: boolean;
	brandsInCart?: any;
	amountToBePaid?: number;
	selectedVoucher?: SelectedVoucher;
	chargeLoading?: boolean;
	setSelectedVoucherAmountLeft?: React.Dispatch<React.SetStateAction<number>>;
	payWithVoucher?: () => Promise<void>;
	total?: number;
	maxOrderForDelivery?: number;
	deliveryFee?: number;
};
const VoucherSection = ({
	onCheckout = false,
	brandsInCart = {},
	amountToBePaid,
	selectedVoucher = {} as SelectedVoucher,
	chargeLoading = false,
	setSelectedVoucherAmountLeft,
	payWithVoucher = async () => {
		return;
	},
	deliveryFee,
	maxOrderForDelivery,
	total,
}: TProps) => {
	const { t } = useTranslation();
	const userVouchers = useAppSelector(state => state.wallet.vouchers);
	const [viewAll, setViewAll] = useState<boolean>(false);
	const userVouchers_ = userVouchers?.filter(
		(voucher: Voucher) => voucher.amountLeft != 0
	);

	const cartBrand = Object.keys(brandsInCart)[0];
	const voucher_ =
		userVouchers_ && (!viewAll ? userVouchers_.slice(0, 2) : userVouchers_);

	const voucherArray = onCheckout
		? voucher_?.filter(voucher => voucher.brandId._id === cartBrand)
		: voucher_;
	const primary = usePrimaryColors();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setSelectedVoucher({} as SelectedVoucher));
	}, []);
	const navigation = useNavigation<TUseNavigation>();
	if (userVouchers && userVouchers?.length > 0) {
		return (
			<>
				<View style={styles.container}>
					<View
						style={[
							styles.headerContainer,
							{ marginBottom: getResponsiveStyle(24) },
						]}
					>
						<Text style={styles.headerText}>{t('Vouchers')}</Text>
						<Text
							style={[{ color: primary[0] }, styles.addText]}
							onPress={() => setViewAll(!viewAll)}
						>
							{!viewAll &&
								voucher_ &&
								voucher_.length < userVouchers_.length &&
								t('View All')}
						</Text>
					</View>

					{onCheckout && voucherArray?.length > 0 && (
						<Text style={styles.selectText}>
							{t('Select to apply voucher')}
						</Text>
					)}

					<View style={styles.contentContainer}>
						{voucherArray && voucherArray.length > 0 ? (
							voucherArray
								// .filter(
								// 	(voucher: Voucher) =>
								// 		voucher.amountLeft != 0
								// )
								.map((voucher: Voucher) => (
									<VoucherComponent
										brandName={voucher?.brandId?.name}
										country={
											voucher?.brandId?.address
												?.countryLabel
										}
										brandId={voucher?.brandId}
										description={voucher?.description}
										balance={voucher?.amountLeft}
										voucherId={voucher?._id}
										setSelectedVoucherAmountLeft={
											setSelectedVoucherAmountLeft
										}
										amountToBePaid={amountToBePaid}
									/>
								))
						) : (
							<NotAvailableMessage
								message={t(
									'No voucher found for this brand, hence you cannot make any purchase'
								)}
							/>
						)}
					</View>
				</View>
				{(!selectedVoucher?.amount ||
					(selectedVoucher?.amount && amountToBePaid === 0)) &&
					isLessThanDesktopBase && (
						<View style={{ display: 'flex', marginTop: '50%' }}>
							<View
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'row',
								}}
							>
								<Text style={{ fontWeight: 'bold' }}>
									{t('Sub Total')}
								</Text>
								<Text>{total}</Text>
							</View>

							<View
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'row',
									marginBottom: '20px',
								}}
							>
								<Text style={{ fontWeight: 'bold' }}>
									{t('Delivery Fee')}
								</Text>
								<Text>
									{total > maxOrderForDelivery
										? 0
										: deliveryFee}
								</Text>
							</View>

							<View
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'row',
								}}
							>
								<Text style={{ fontWeight: 'bold' }}>
									{t('Amount to be Paid')}
								</Text>
								<Text>{amountToBePaid}</Text>
							</View>
						</View>
					)}

				{selectedVoucher?.amount && amountToBePaid === 0 && (
					<View style={styles.buttonContainer}>
						{!isLessThanDesktopBase && (
							<Button
								title={t('Back')}
								variant={'grey-outline'}
								width={getResponsiveStyle(40, 'spacing')}
								outerContainerProps={{
									style: styleLoginButtonOuterContainer(),
								}}
								onPress={() =>
									navigation.navigate('CheckoutAddress')
								}
								size="lg"
								// onPress={handleSubmit}
							/>
						)}
						<Button
							title={t('Order now')}
							loading={chargeLoading}
							variant={cartBrand ? 'primary' : 'disabled'}
							outerContainerProps={{
								style: [
									styleLoginButtonOuterContainer(),
									{
										width: isLessThanDesktopBase
											? '100%'
											: '35%',
									},
								],
							}}
							size="lg"
							onPress={() => {
								!chargeLoading && payWithVoucher();
							}}
						/>
					</View>
				)}
			</>
		);
	} else {
		return (
			<>
				<View style={[styles.headerContainer]}>
					<Text style={styles.headerText}>{t('Vouchers')}</Text>
				</View>
				<NotAvailableMessage
					message={t(
						'No voucher found, hence you cannot make any purchase'
					)}
				/>
			</>
		);
	}
};

const styles = StyleSheet.create({
	indicatorContainer: {
		height: '500px',
	},
	addText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		cursor: 'pointer',
	},
	container: {
		width: '100%',
		// marginTop: 48,
		marginBottom: getResponsiveStyle(24),
	},
	selectText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(16, 'font'),
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(24, 'font'),
	},
	contentContainer: {
		flexDirection: 'row',
		gap: getResponsiveStyle(24),
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		marginTop: getResponsiveStyle(24),
	},
	noVoucherText: {
		fontWeight: '400',
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(25, 'font'),
		color: palettes.grey[0],
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(26),
	};
}
export default VoucherSection;
