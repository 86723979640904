import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CategoriesDropdown } from './CategoriesDropdown';
import { CategoryPillList } from './CategoryPillList';

type Props = {
	secondLabel: string;
	dropdownCategories: TTagType[];
	pillCategories: TTagType[];
	selectedCategory: string;
	onClick: (text: string) => void;
};

const FilterCategories = ({
	secondLabel,
	dropdownCategories: _dropdownCategories,
	pillCategories: _pillCategories,
	selectedCategory,
	onClick,
}: Props) => {
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const language = useAppSelector(state => state.ui.language);
	const { t } = useTranslation();
	const [dropdownCategories, setDropdownCategories] =
		useState<TTagType[]>(_dropdownCategories);

	const [pillCategories, setPillCategories] =
		useState<TTagType[]>(_pillCategories);

	const [dropdownDimensions, setDropdownDimensions] = useState<{
		dropdownWidth: number;
		dropdownLeft: number;
	} | null>(null);

	const [containerDimensions, setContainerDimensions] = useState<
		null | number
	>(null);
	const [putCategoriesInDropdown, setPutCategoriesInDropdown] =
		useState(false);

	useEffect(() => {
		if (containerDimensions && dropdownDimensions) {
			const { dropdownLeft, dropdownWidth } = dropdownDimensions;
			const sumOfDimensions = dropdownLeft + dropdownWidth;
			Math.abs(containerDimensions - sumOfDimensions) > 20 &&
				setPutCategoriesInDropdown(true);
		}
	}, [
		width,
		dropdownDimensions?.dropdownLeft,
		dropdownDimensions?.dropdownWidth,
		containerDimensions,
	]);

	useEffect(
		() => {
			setCategoriesResponsively(
				width,
				Array.from(_pillCategories),
				Array.from(_dropdownCategories),
				selectedCategory,
				setDropdownCategories,
				setPillCategories,

				putCategoriesInDropdown
			);
		},
		[
			// width,
			//  putCategoriesInDropdown
		]
	);

	return (
		<View
			style={[styles.container, styleContainer(width)]}
			onLayout={event => {
				setContainerDimensions(event.nativeEvent.layout.width);
			}}
		>
			{!isLessThanDesktopBase && (
				<ScrollView
					horizontal
					contentContainerStyle={styles.scrollContainer}
					showsHorizontalScrollIndicator={false}
				>
					<CategoryPillList
						onClick={category => onClick(category)}
						categories={pillCategories}
						selectedCategory={selectedCategory}
						label={secondLabel}
					/>
				</ScrollView>
			)}
			<View
				style={{
					justifyContent: 'center',
					paddingLeft: getResponsiveStyle(10),
				}}
				onLayout={event => {
					setDropdownDimensions({
						dropdownLeft: event.nativeEvent.layout.x,
						dropdownWidth: event.nativeEvent.layout.width,
					});
				}}
			>
				<CategoriesDropdown
					categories={dropdownCategories.map(item => {
						return {
							label: item[language],
							value: item.value,
						};
					})}
					label={`${t(secondLabel)}`}
					selectedCategory={selectedCategory}
					onClick={category => {
						if (width > 1280) {
							let x = dropdownCategories.find(
								x => x.value === category
							);
							let y = pillCategories.pop();

							x &&
								setPillCategories([
									...pillCategories.slice(
										0,
										pillCategories.length
									),
									x,
								]);
							y &&
								setDropdownCategories([
									y,
									...dropdownCategories.filter(
										x => x.value != category
									),
								]);
						}
						onClick(category);
					}}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',

		justifyContent: 'space-between',
		zIndex: 2,
	},
	scrollContainer: {
		justifyContent: 'center',
		alignItems: 'center',

		// marginTop: 40,
	},
});

const styleContainer = (width: number) => {
	return {
		marginBottom: getResponsiveStyle(24),
	};
};

const setCategoriesResponsively = (
	width: number,
	pillCategories: TTagType[],
	dropdownCategories: TTagType[],
	selectedCategory: string,
	setDropdownCategories: React.Dispatch<React.SetStateAction<TTagType[]>>,
	setPillCategories: React.Dispatch<React.SetStateAction<TTagType[]>>,
	putCategoriesInDropdown: boolean
) => {
	let x = dropdownCategories.find(x => x.value === selectedCategory);

	if (width < 1280 || putCategoriesInDropdown) {
		setDropdownCategories(() => [
			{
				en: pillCategories[0].en + ' Categories',
				fr: pillCategories[0].fr + 'Catégories',
				value: pillCategories[0].value,
			},
			...pillCategories.slice(2),
			...dropdownCategories,
		]);
		setPillCategories([]);
	} else if (!!x) {
		let y = pillCategories.pop();
		setPillCategories([
			...pillCategories.slice(0, pillCategories.length),
			x,
		]);
		y &&
			setDropdownCategories([
				y,
				...dropdownCategories.filter(x => x.value != selectedCategory),
			]);
	} else {
		setDropdownCategories(dropdownCategories);
		setPillCategories(pillCategories);
	}
};

export default FilterCategories;
