import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate } from '../components';
import EventDetailForm from '../components/create-event/event-detail/EventDetailForm';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const EventDetailScreen = () => {
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();

	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/event-detail'));
	}, []);
	return shouldHideScreen ? null : (
		<AuthTemplate
			heading={t('Event Details')}
			subText={t('Tell us what the event is about?')}
			scrollItems={createEventSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<EventDetailForm />
		</AuthTemplate>
	);
};

export default EventDetailScreen;
