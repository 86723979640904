import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export type PlaceholderUserIconProps = {
	outlineColor: string;
} & SvgProps;

const PlaceholderUserIcon = ({
	outlineColor,
	...props
}: PlaceholderUserIconProps) => (
	<Svg {...props} viewBox="0 0 24 24">
		<Path fill="none" d="M0 0h24v24H0z" />
		<Path
			fill={outlineColor}
			d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
		/>
	</Svg>
);

export default PlaceholderUserIcon;
