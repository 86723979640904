import React from 'react';
import {
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from 'react-native';

import { palettes } from '../../config';
import { usePrimaryColors } from '../../hooks/usePrimaryColors';

const UPInfoForm = (props: {
	onSubmit: (email: string, password: string, username: string) => void;
}) => {
	const primary = usePrimaryColors();
	const [email, onChangeEmail] = React.useState('');
	const [password, onChangePassword] = React.useState('');
	const [passwordConfirm, onChangePasswordConfirm] = React.useState('');
	const [username, onChangeUsername] = React.useState('');

	const handleSubmit = () => {
		props.onSubmit(email, password, username);
	};

	return (
		<>
			<View>
				<Text style={styles.text}>Username</Text>

				<TextInput
					style={styles.input}
					value={username}
					onChangeText={onChangeUsername}
				/>

				<Text style={styles.text}>Email</Text>

				<TextInput
					style={styles.input}
					value={email}
					onChangeText={onChangeEmail}
				/>

				<Text style={styles.text}>Password</Text>

				<TextInput
					style={styles.input}
					value={password}
					onChangeText={onChangePassword}
					secureTextEntry
				/>

				<Text style={styles.text}>Confirm Password</Text>

				<TextInput
					style={styles.input}
					value={passwordConfirm}
					onChangeText={onChangePasswordConfirm}
					secureTextEntry
				/>
			</View>

			<TouchableOpacity
				style={[styles.button, { backgroundColor: primary?.[0] }]}
				onPress={handleSubmit}
				activeOpacity={0.75}
			>
				<Text style={styles.buttonText}>Next</Text>
			</TouchableOpacity>
		</>
	);
};

const styles = StyleSheet.create({
	button: {
		justifyContent: 'center',
		width: 345,
		height: 48,
		marginTop: 10,
		borderRadius: 6,
	},
	buttonText: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 16,
		lineHeight: 16,
		textAlign: 'center',
		color: '#fff',
	},
	input: {
		width: 345,
		height: 48,
		marginTop: 10,
		marginBottom: 20,
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 20,
		lineHeight: 24,
		paddingLeft: 8,
		paddingRight: 8,
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
});

export default UPInfoForm;
