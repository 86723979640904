import { View, StyleSheet, Text, ScrollView, Dimensions } from 'react-native';
import React from 'react';
import Modal from 'react-native-modal';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';
import { useTranslation } from 'react-i18next';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

export type Props = {
	header?: string | JSX.Element;
	subtitle?: string | JSX.Element;
	onClose: () => void;
	openState: boolean;
	hasNoClose?: boolean;
	children: JSX.Element;
	type?: 'modal' | 'sidebar' | 'fullpage';
	contentMargin?: number;
	isDeleteModal?: boolean;
	isNotScrollable?: boolean;
	onCheckout?: boolean;
	onWithdraw?: boolean;
	isProductModal?: boolean;
};

const ModalComponent = ({
	type = 'modal',
	hasNoClose = false,
	...props
}: Props) => {
	const { t } = useTranslation();
	const { isMobileView } = useIsSpecificViewportWidth();
	const primary = usePrimaryColors();
	return (
		<View
			style={{
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				marginVertical: 20,
			}}
		>
			<Modal
				isVisible={props.openState}
				onBackdropPress={props.onClose}
				avoidKeyboard={true}
				backdropOpacity={0.3}
				onBackButtonPress={props.onClose}
				style={
					type === 'modal'
						? styles.modalContainer
						: styles.sidebarContainer
				}
				animationIn={type === 'modal' ? 'slideInUp' : 'slideInRight'}
				animationOut={
					type === 'modal' ? 'slideOutDown' : 'slideOutRight'
				}
			>
				<View
					style={
						type === 'modal'
							? styles.modalContentContainer
							: type === 'sidebar'
							? styles.sidebarContentContainer
							: styles.fullPageContainer
					}
				>
					{props.header && (
						<View style={styles.headerContainer}>
							<View style={styles.headerContentContainer}>
								{typeof props.header === 'string' ? (
									<Text
										style={[
											styles.header,
											mobileHeader(isMobileView),
										]}
									>
										{props.header}
									</Text>
								) : (
									props.header
								)}

								{hasNoClose ? (
									''
								) : (
									<Text
										style={styles.closeButton}
										onPress={props.onClose}
									>
										{t('Close')}
									</Text>
								)}
							</View>
							<Text
								style={
									props.onWithdraw
										? {
												fontWeight: '700',
												color: primary[0],
										  }
										: styles.subtitle
								}
							>
								{props.subtitle}
							</Text>
						</View>
					)}

					{!props.isNotScrollable ? (
						<ScrollView
							contentContainerStyle={{
								marginTop: getResponsiveStyle(
									!props.onCheckout
										? props.contentMargin
											? props.contentMargin
											: props.isDeleteModal ||
											  props.isProductModal
											? 0
											: 68
										: 30
								),
								marginBottom: 'auto',
							}}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
						>
							{props.children}
						</ScrollView>
					) : (
						props?.children
					)}
				</View>
			</Modal>
		</View>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		justifyContent: 'center',
		margin: 0,
		alignItems: 'center',
	},
	sidebarContainer: {
		justifyContent: 'flex-start',
		margin: 0,
		height: Dimensions.get('window').height,
		alignItems: 'flex-end',
	},
	modalContentContainer: {
		backgroundColor: palettes.light[0],
		shadowColor: palettes.grey[0],
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.18,
		shadowRadius: 4,
		elevation: 5,
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		padding: getResponsiveStyle(35),
		width: getResponsiveStyle(488),
		maxWidth: '95vw',
		// max
	},
	fullPageContainer: {
		backgroundColor: palettes.light[0],
		height: '100vh',
		width: '100vw',
		paddingHorizontal: '5vw',
		paddingTop: getResponsiveStyle(39),
		paddingBottom: getResponsiveStyle(32),
	},
	sidebarContentContainer: {
		backgroundColor: palettes.light[0],
		shadowColor: palettes.grey[0],
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.18,
		shadowRadius: 4,
		elevation: 5,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		maxWidth: '85vw',
		height: '100vh',
		width: getResponsiveStyle(520),
		paddingHorizontal: getResponsiveStyle(27),
		paddingTop: getResponsiveStyle(39),
		paddingBottom: getResponsiveStyle(32),
	},
	headerContentContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	headerContainer: {
		flexDirection: 'column',
		gap: getResponsiveStyle(15),
		paddingBottom: getResponsiveStyle(20),
	},
	header: {
		fontSize: getResponsiveStyle(24, 'font'),
		color: palettes.dark[0],
		fontWeight: 'bold',
		lineHeight: getResponsiveStyle(18),
	},
	subtitle: {
		fontSize: getResponsiveStyle(12, 'font'),
		color: palettes.grey[0],
		fontWeight: '400',
		lineHeight: getResponsiveStyle(12),
	},

	closeButton: {
		fontSize: getResponsiveStyle(14, 'font'),
		color: palettes.red[0],
		fontWeight: '600',
		lineHeight: getResponsiveStyle(14),
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		cursor: 'pointer',
	},
	contentContainer: {
		// marginTop: getResponsiveStyle(38),
	},
});
function mobileHeader(isMobileView: boolean) {
	return isMobileView && { fontSize: getResponsiveStyle(18, 'font') };
}

export default ModalComponent;
