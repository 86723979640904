import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const FacebookIcon = (props: SvgProps) => (
	<Svg viewBox="0 0 24 24" fill="none" {...props}>
		<G clipPath="url(#a)">
			<Path
				d="M24.626 12c0-6.627-5.442-12-12.155-12C5.757 0 .315 5.373.315 12c0 5.99 4.445 10.954 10.256 11.854V15.47H7.485V12h3.086V9.356c0-3.007 1.815-4.668 4.591-4.668 1.33 0 2.722.234 2.722.234v2.953H16.35c-1.51 0-1.981.925-1.981 1.875V12h3.371l-.539 3.469H14.37v8.385c5.81-.9 10.256-5.864 10.256-11.854Z"
				fill="#1877F2"
			/>
			<Path
				d="m17.202 15.469.54-3.469H14.37V9.75c0-.949.471-1.875 1.981-1.875h1.533V4.922s-1.391-.234-2.721-.234c-2.777 0-4.591 1.66-4.591 4.668V12H7.485v3.469h3.087v8.385c1.258.195 2.54.195 3.798 0V15.47h2.832Z"
				fill="#fff"
			/>
		</G>
		<Defs>
			<ClipPath id="a">
				<Path
					fill="#fff"
					transform="translate(.315)"
					d="M0 0h24.31v24H0z"
				/>
			</ClipPath>
		</Defs>
	</Svg>
);

export default FacebookIcon;
