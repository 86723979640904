import React from 'react';
import { View } from 'react-native';
import { Spacing } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { HostSellerLabel } from './HostSellerLabel';
import { HostSellerName } from './HostSellerName';
import { LiveLabel } from './LiveLabel';
import { ProfileImage } from './ProfileImage';

type Props = {
	isLiveStreaming: boolean;
} & Omit<TLiveEventAffiliatedHosts, '_id' | 'email'>;

export const HostDetails = ({
	username = '',
	personalInformation,
	isLiveStreaming,
}: Props) => {
	return (
		<View
			style={{
				position: 'absolute',
				left: getResponsiveStyle(24),
				top: getResponsiveStyle(24),
			}}
		>
			<Spacing containerStyles={{ alignItems: 'center' }}>
				<View>
					<ProfileImage uri={personalInformation?.profileImageLink} />
					{isLiveStreaming && <LiveLabel />}
				</View>
				<View>
					<HostSellerName name={username} />
					{/* <HostSellerLabel typeOfAccount={'HOST'} /> */}
				</View>
			</Spacing>
		</View>
	);
};
