import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL } from '../../graphql/auth/mutations';

type TVerifyEmailArgs = {
	uId: string;
	vId: string;
};

type Params =
	| {
			onCompleted?: TOnCompleteApiCall<TCheckUsernameEmailAvailabilityResponse>;
	  }
	| undefined;

export const useVerifyEmail = (params?: Params) => {
	const [verify, states] = useMutation<
		TCheckUsernameEmailAvailabilityResponse,
		TVerifyEmailArgs
	>(VERIFY_EMAIL, {
		onCompleted: params?.onCompleted,
	});

	const verifyEmail = ({ uId, vId }: { uId: string; vId: string }) => {
		verify({
			variables: {
				uId,
				vId,
			},
		});
	};

	return { verifyEmail, ...states };
};
