import React, { useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import VideoPlayBtn from './VideoPlayBtn';

const Video = ({ message }: { message: string }) => {
	const [play, setPlay] = useState<boolean>(false);
	const videoRef = useRef<any>();

	const handlePlay = () => {
		setPlay(!play);
		if (!play) {
			videoRef.current.play();
		} else {
			videoRef.current.pause();
		}
	};
	return (
		<View style={styles.videoRapper}>
			<video
				onPause={handlePlay}
				autoPlay={play}
				controls={play}
				className="media-video"
				src={message}
				ref={videoRef}
			/>
			{!play && <VideoPlayBtn onPress={handlePlay} />}
		</View>
	);
};

const styles = StyleSheet.create({
	videoRapper: {
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default Video;
