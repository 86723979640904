import React, { useEffect, useState } from 'react';
import {
	Platform,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Input, Label } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Picker } from '@react-native-picker/picker';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useMutation } from '@apollo/client';
import { UPDATE_CARD } from '../../../graphql/stripe/mutations';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { LIST_CARDS } from '../../../graphql/stripe/queries';
import { monthsByNumber } from '../../../utils/monthsUtils';
import { setEditCardModalState } from '../../../redux/getCardsSlice';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useTranslation } from 'react-i18next';

const schema = Yup.object({
	cardName: Yup.string().required('Required'),
	expiryMonth: Yup.string().required('Required'),
	expiryYear: Yup.string().required('Required'),
	cardNumber: Yup.string().required('Required'),
});

type TInitialValues = Yup.InferType<typeof schema>;

const AddCardForm = (props: {
	onSubmit: (
		cardName: string,
		expiryMonth: string,
		expiryYear: string,
		cardNumber: string
	) => void;
}) => {
	const cardToBeEditted = useAppSelector(
		state => state.userCards.cardToBeEditted
	);

	const getKeyByValue = (object: object, value: number) => {
		return Object.keys(object).find(key => object[key] === value);
	};
	const { t } = useTranslation();
	const initialValues: TInitialValues = {
		cardName: cardToBeEditted?.name,
		expiryMonth: getKeyByValue(
			monthsByNumber,
			parseInt(cardToBeEditted?.month)
		),
		expiryYear: cardToBeEditted?.year,
		cardNumber: cardToBeEditted?.cardNum,
	};

	const [cardName, setCardName] = useState('');
	const [exp_month, setExp_month] = useState(0);
	const [exp_year, setExp_year] = useState(0);

	const { setAlert } = useSetAlert();
	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);
	const dispatch = useAppDispatch();
	const [updateCard, { loading }] = useMutation(UPDATE_CARD, {
		variables: {
			args: {
				id: cardToBeEditted?.cardid,
				billing_details: {
					name: cardName,
				},
				card: {
					exp_month,
					exp_year,
				},
			},
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: LIST_CARDS,
				variables: {
					args: {
						id: stripeId_,
					},
				},
			},
		],
		onCompleted: data => {
			if (data.updateCard.success) {
				dispatch(setEditCardModalState());
				setAlert(t('Card details edited successfully'), 'normal');
			} else {
				setAlert(t('Unable to edit card details'), 'danger');
			}
		},
		onError: () => {
			setAlert(t('Unable to edit card details'), 'danger');
		},
	});

	const settingValues = (val: any) => {
		setCardName(val.cardName);
		setExp_month(monthsByNumber[val.expiryMonth]);
		setExp_year(parseInt(val.expiryYear));
	};

	const primary = usePrimaryColors();
	if (loading) {
		return <div>{t('Updating Card...')}</div>;
	} else {
		return (
			<Formik
				initialValues={initialValues}
				validationSchema={schema}
				onSubmit={async values => {
					await settingValues(values);
					await updateCard();
				}}
				// handleSubmit(values)
				// }}
			>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					dirty,
					isValid,
					errors,
					touched,
					setFieldValue,
				}) => (
					<>
						<View>
							<Input
								value={values.cardName}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(35),
								}}
								// editable={false}
								onChangeText={handleChange('cardName')}
								onBlur={handleBlur('cardName')}
								label={`${t('Name on Card')}`}
								error={touched.cardName ? errors.cardName : ''}
								// placeholder="Enter bank name"
							/>
							<Label
								error={
									touched.expiryYear || touched.expiryMonth
										? errors.expiryYear
										: ''
								}
								label={`${t('Expiration Date')}`}
							/>
							<View style={styles.joinedContainerWithGap}>
								<Picker
									selectedValue={values.expiryMonth}
									onValueChange={itemValue =>
										setFieldValue('expiryMonth', itemValue)
									}
									style={[
										styles.dropdownContainer,
										{
											flexBasis: '50%',
										},
										Platform.OS === 'web' &&
										!touched.expiryMonth &&
										!errors.expiryMonth
											? styleFocusOutline(primary)
											: styleErrorOutline(),

										// styleInput(decoration),
										!!errors.expiryMonth &&
											touched.expiryMonth &&
											styles.inputError,
									]}
									dropdownIconColor={palettes.grey[1]}
									onBlur={handleBlur('expiryMonth')}
									itemStyle={styles.dropdownItem}
								>
									<Picker.Item
										label={t('Month')}
										value=""
										enabled={false}
									/>
									<Picker.Item
										label={`${t('January')}`}
										value="January"
									/>
									<Picker.Item
										label={`${t('February')}`}
										value="February"
									/>
									<Picker.Item
										label={`${t('March')}`}
										value="March"
									/>
									<Picker.Item
										label={`${t('April')}`}
										value="April"
									/>
									<Picker.Item
										label={`${t('May')}`}
										value="May"
									/>
									<Picker.Item
										label={`${t('June')}`}
										value="June"
									/>
									<Picker.Item
										label={`${t('July')}`}
										value="July"
									/>
									<Picker.Item
										label={`${t('August')}`}
										value="August"
									/>
									<Picker.Item
										label={`${t('September')}`}
										value="September"
									/>
									<Picker.Item
										label={`${t('October')}`}
										value="October"
									/>
									<Picker.Item
										label={`${t('November')}`}
										value="November"
									/>
									<Picker.Item
										label={`${t('December')}`}
										value="December"
									/>
								</Picker>
								<Picker
									selectedValue={values.expiryYear}
									onValueChange={itemValue =>
										setFieldValue('expiryYear', itemValue)
									}
									style={[
										styles.dropdownContainer,
										{
											flexBasis: '50%',
										},
										Platform.OS === 'web' &&
										!touched.expiryYear &&
										!errors.expiryYear
											? styleFocusOutline(primary)
											: styleErrorOutline(),

										// styleInput(decoration),
										!!errors.expiryYear &&
											touched.expiryYear &&
											styles.inputError,
									]}
									dropdownIconColor={palettes.grey[1]}
									onBlur={handleBlur('expiryYear')}
									itemStyle={styles.dropdownItem}
								>
									<Picker.Item
										label={t('Year')}
										value=""
										enabled={false}
									/>

									<Picker.Item label="2027" value="2027" />
									<Picker.Item label="2026" value="2026" />
									<Picker.Item label="2025" value="2025" />
									<Picker.Item label="2024" value="2024" />
									<Picker.Item label="2023" value="2023" />
									<Picker.Item label="2022" value="2022" />
								</Picker>
							</View>
							<Input
								value={values.cardNumber}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(35),
								}}
								editable={false}
								onChangeText={handleChange('cardNumber')}
								onBlur={handleBlur('cardNumber')}
								label={`${t(
									'Credit Card Number(Cannot be Edited)'
								)}`}
								error={
									touched.cardNumber ? errors.cardNumber : ''
								}
							/>
						</View>

						<Button
							title={`${t('Save')}`}
							variant="primary"
							outerContainerProps={{
								style: styleLoginButtonOuterContainer(),
							}}
							size="lg"
							onPress={handleSubmit}
						/>
					</>
				)}
			</Formik>
		);
	}
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	countryContainer: {
		flexBasis: '50%',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		paddingLeft: getResponsiveStyle(19),
		marginBottom: getResponsiveStyle(35),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	joinedContainer: {
		flexDirection: 'row',

		// width: '100%',
		// flex: 1,
	},
	joinedContainerWithGap: {
		flexDirection: 'row',
		gap: getResponsiveStyle(10),
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
});

export default AddCardForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(46),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary[0],
		outerWidth: 1,
	} as any;
}
