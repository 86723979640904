import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TAddressBook = {
	name: string;
	firstName?: string;
	lastName?: string;
	address: string;
	city: string;
	zipCode: string;
	countery: string;
	phoneNumber?: string;
	email?: string;
};

type TCard = {
	name: string;
	expMonth: string;
	expYear: string;
	cvv: string;
	cardNo: string;
};
type TOneStopCheckoutInitialState = {
	card: TCard;
	address: TAddressBook;
	createdCardId: string;
};

const initialState: TOneStopCheckoutInitialState = {
	card: {
		name: '',
		expMonth: '',
		expYear: '',
		cvv: '',
		cardNo: '',
	},
	address: {
		name: '',
		address: '',
		city: '',
		zipCode: '',
		countery: '',
	},
	createdCardId: '',
};

const oneStopCheckoutSlice = createSlice({
	name: 'oneStopCheckoutSlice',
	initialState,
	reducers: {
		setPayingCardDetails(state, action: PayloadAction<TCard>) {
			state.card = action.payload;
		},
		setPayingAddressDetails(state, action: PayloadAction<TAddressBook>) {
			state.address = action.payload;
		},
		setCreatedCardId(state, action: PayloadAction<string>) {
			state.createdCardId = action.payload;
		},
	},
});

export default oneStopCheckoutSlice.reducer;
export const {
	setPayingAddressDetails,
	setPayingCardDetails,
	setCreatedCardId,
} = oneStopCheckoutSlice.actions;
