import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Checkbox, ProductCard } from '../..';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	containerStyles?: TODO;
};

const ProductCardWithCheckbox = ({ containerStyles }: Props) => {
	return (
		<View style={[styles.container]}>
			<ProductCard
				isLastItem={false}
				size="small"
				hasRating={false}
				containerStyles={styleProductContainer()}
			/>
			<Checkbox
				containerStyles={[
					styles.checkboxContainer,
					styleCheckboxContainer(),
				]}
			/>
		</View>
	);
};

export default ProductCardWithCheckbox;

const styles = StyleSheet.create({
	checkboxContainer: {
		position: 'absolute',
	},
	container: {
		position: 'relative',
	},
});

function styleCheckboxContainer() {
	return {
		top: getResponsiveStyle(16),
		left: getResponsiveStyle(16),
	};
}

function styleProductContainer(): TStyle {
	return {
		marginBottom: getResponsiveStyle(22),
	};
}
