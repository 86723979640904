import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_UNREAD_MESSAGES } from '../../graphql/chat/queries';
import { setUnReadMessages } from '../../redux/chatSlice';
import { useAppDispatch } from '../../redux/store';

const useGetUnreadMessages = () => {
	const dispatch = useAppDispatch();

	const [getUnreadMessages, { data, error, loading }] = useLazyQuery(
		GET_UNREAD_MESSAGES,
		{
			onCompleted(data) {
				dispatch(setUnReadMessages(data?.getUnreadMessages?.data));
			},
		}
	);

	const executeUnreadMessagesQuery = ({
		recipientId,
	}: {
		recipientId: string;
	}) => {
		getUnreadMessages({
			variables: {
				recipientId,
			},
		});
	};
	return { executeUnreadMessagesQuery, data, error, loading };
};

export default useGetUnreadMessages;
