import { gql } from '@apollo/client';

export const CREATE_VOUCHER = gql`
	mutation createVoucher($args: CreateVoucherInputs!) {
		createVoucher(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const UPDATE_VOUCHER = gql`
	mutation updateVoucher($args: UpdateVoucherInputs!) {
		updateVoucher(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;
