import React, { useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';

import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

function DescriptionContent({ content }: { content: String }) {
	const { t } = useTranslation();
	const [wordLength, setWordLength] = useState<number | undefined>(500);
	const { isMobileView } = useIsSpecificViewportWidth();
	return (
		<View style={[Styles.container, styleContainer()]}>
			<View style={Styles.headerContainer}>
				<Text style={[Styles.title, mobileTitle(isMobileView)]}>
					{t('Description')}
				</Text>
			</View>
			<View style={Styles.contentContainer}>
				<Text style={[Styles.subtitle, mobileSubTitle(isMobileView)]}>
					{content.substring(0, wordLength) || 'NULL'}
				</Text>
			</View>
			<View style={Styles.buttonContainer}>
				{content.length > 500 && wordLength !== undefined && (
					<Pressable onPress={() => setWordLength(undefined)}>
						<Text style={Styles.option}>{t('Learn More')}</Text>
					</Pressable>
				)}
			</View>
		</View>
	);
}

const Styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	buttonContainer: {
		marginTop: getResponsiveStyle(18),
	},
	headerContainer: {
		// marginTop: 54,
	},
	contentContainer: {
		marginTop: getResponsiveStyle(29),
	},
	option: {
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	subtitle: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: 'normal',
		color: palettes.dark[2],
	},
});
export default DescriptionContent;

function styleContainer() {
	return {
		// marginTop: getResponsiveStyle(54),
		// marginBottom: getResponsiveStyle(41),
	};
}
function mobileTitle(isMobileView: boolean) {
	return isMobileView && { fontSize: getResponsiveStyle(16, 'font') };
}

function mobileSubTitle(isMobileView: boolean) {
	return isMobileView && { fontSize: getResponsiveStyle(14, 'font') };
}
