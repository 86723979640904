import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../redux/store';
import './utils/Tile.css';
import { Dimensions } from 'react-native';

type Props = {
	key: string;
	videoTrackState: any;
	audioTrackState: any;
	onVideoHeight: (val: string) => void;
	eventDetails?: TSingleLiveEventData;
};

export const LiveEventTile = (props: Props) => {
	const videoEl = useRef<any>(null);
	const audioEl = useRef<any>(null);
	const [isStreamDeviceDesktop, setIsStreamDeviceDesktop] = useState(
		props.eventDetails?.deviceMetadata?.deviceType === 'Desktop'
	);

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const videoTrack = useMemo(() => {
		return props.videoTrackState?.track;
	}, [props.videoTrackState]);

	const audioTrack = useMemo(() => {
		return props.audioTrackState?.persistentTrack;
	}, [props.audioTrackState]);

	useEffect(() => {
		videoEl.current &&
			(videoEl.current.srcObject =
				videoTrack && new MediaStream([videoTrack]));
	}, [videoTrack]);

	useEffect(() => {
		audioEl.current &&
			(audioEl.current.srcObject =
				audioTrack && new MediaStream([audioTrack]));
	}, [audioTrack]);

	useEffect(() => {
		if (videoEl.current) {
			props.onVideoHeight(videoEl.current.offsetHeight);
		}
	}, [videoEl]);

	function getVideoComponent() {
		return (
			videoTrack && (
				<video
					autoPlay
					muted={true}
					playsInline
					ref={videoEl}
					className={isLessThanDesktopBase ? 'video-mobile' : 'video'}
					style={{
						transform: 'rotateY(180deg)',
						objectFit: 'cover',
						height:
							isLessThanDesktopBase &&
							Dimensions.get('window').height,
						width: isLessThanDesktopBase && '100%',
						// height: `${isLessThanDesktopBase && '800px'}`,
					}}
				/>
			)
		);
	}

	function getAudioComponent() {
		return <audio autoPlay muted={true} playsInline ref={audioEl} />;
	}

	return (
		<View
			style={[
				styles.container,
				isLessThanDesktopBase && {
					width: '100%',
					height: Dimensions.get('window').height,
				},
			]}
		>
			<div className="background" />
			{getVideoComponent()}
			{getAudioComponent()}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		justifyContent: 'center',
	},
});
