import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { FACEBOOK_AUTH } from '../../graphql/auth/mutations';
import { TUseNavigation } from '../../types/exportedTypes';

const useFacebookAuth = () => {
	const navigation = useNavigation<TUseNavigation>();
	const [facebookAuth, states] = useMutation(FACEBOOK_AUTH);

	const executeFacebookAuthMutation = ({
		token,
		isCompleteSignUpFromBackend,
	}: {
		token: string;
		isCompleteSignUpFromBackend?: boolean;
	}) => {
		facebookAuth({
			variables: {
				token,
				isCompleteSignUpFromBackend,
			},
		});
	};
	return { executeFacebookAuthMutation, ...states };
};

export default useFacebookAuth;
