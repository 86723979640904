import React, { useState } from 'react';
import { View, Image, Text, StyleSheet, Pressable } from 'react-native';
import { Button, CompanyCardStats, Ratings } from '../..';
import { palettes } from '../../../config';
import { i18n } from '../../../i18n';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Flag from '../flag/Flag';
import { useTranslation } from 'react-i18next';
import { shortenName } from '../../../utils/manipulateString';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

type TImageDimensions = {
	height: number;
	width: number;
};

type TSize = 'small' | 'large';

type Props = {
	companyName: string;
	city: string;
	countryCode: string;
	location?: string;
	rating: number;
	verified?: boolean;
	isProfile?: boolean;
	size?: TSize;
	profileImage?: string;
	onPressContainer?: () => void;
};

export const CompanyProfileNameRating = ({
	companyName,
	city,
	rating,
	countryCode,
	isProfile = false,
	verified,
	size = 'large',
	profileImage,
	onPressContainer,
}: Props) => {
	const { t } = useTranslation();
	const [showRatingModal, setShowRatingModal] = useState<boolean>(false);
	const { isDesktop, isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<>
			{/* <RatingModal
				onClose={() => setShowRatingModal(false)}
				openState={showRatingModal}
			/> */}
			<Pressable onPress={onPressContainer} style={styles.container}>
				<View
					style={[
						styles.image,
						styleImage(size),
						styles.imageContainer,
					]}
				>
					<Image
						source={
							!!profileImage
								? { uri: profileImage }
								: require('../../../assets/defaultProfileImage.svg')
						}
						style={styleProfileImage(size)}
					/>
				</View>
				<View>
					<Text style={[styles.companyName, styleCompanyName(size)]}>
						{companyName}
					</Text>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Flag countryCode={countryCode} />
						<Text
							style={[
								styles.companyLocation,
								styleCompanyLocation(size),
							]}
						>
							{city
								? !isDesktop
									? shortenName(city)
									: city
								: ' '}{' '}
						</Text>
					</View>

					{!isProfile && rating ? (
						<Pressable onPress={() => setShowRatingModal(true)}>
							<Ratings
								ratings={rating}
								containerStyles={styles.ratings}
								justifyContent={'flex-start'}
							/>
						</Pressable>
					) : (
						verified && (
							<Text style={styles.verifiedText}>
								{t('Account Verified')}
							</Text>
						)
					)}
				</View>
			</Pressable>
		</>
	);
};

const styles = StyleSheet.create({
	profileImage: {
		width: getResponsiveStyle(88, 'dimensions'),
		height: getResponsiveStyle(88, 'dimensions'),
		borderRadius: 9999,
	},
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	imageContainer: {
		borderRadius: 9999,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: palettes.grey[10],
	},
	ratings: {
		padding: 0,
		alignSelf: 'flex-start',
	},
	companyLocation: {
		color: palettes.grey[0],
	},
	companyName: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
	image: {
		borderRadius: 9999,
	},
	verifiedText: {
		fontSize: 12,
		fontWeight: '700',
		color: palettes.purple[0],
	},
});

function styleCompanyLocation(size: TSize) {
	return {
		fontSize: getResponsiveStyle(size === 'large' ? 16 : 12, 'font'),
		lineHeight: getResponsiveStyle(20),
		marginBottom: getResponsiveStyle(3),
	};
}

function styleCompanyName(size: TSize) {
	return {
		fontSize: getResponsiveStyle(size === 'large' ? 16 : 14, 'font'),
		lineHeight: getResponsiveStyle(18),
	};
}

function styleImage(size: TSize) {
	return {
		width: getResponsiveStyle(size === 'large' ? 88 : 56, 'dimensions'),
		height: getResponsiveStyle(size === 'large' ? 88 : 56, 'dimensions'),
		marginRight: getResponsiveStyle(10),
	};
}

function styleProfileImage(size: TSize) {
	return {
		width: getResponsiveStyle(size === 'large' ? 88 : 46, 'dimensions'),
		height: getResponsiveStyle(size === 'large' ? 88 : 46, 'dimensions'),
		borderRadius: 9999,
	};
}
