import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate, UserSelect } from '../components';
import { setTypeOfAccount } from '../redux/createUserSlice';
import { useAppDispatch } from '../redux/store';
import {
	setCurrentScreen,
	setPrimaryColors,
	setSignupSidebarValues,
} from '../redux/uiSlice';
import { TUseNavigation } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const UserSelectScreen = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigation = useNavigation<TUseNavigation>();

	const handleSelectUser = (userType: TTypeOfAccount | '') => {
		if (userType === '') return;
		dispatch(setTypeOfAccount(userType));
		dispatch(setPrimaryColors(userType));
		dispatch(setSignupSidebarValues(userType));
		navigation.navigate('PersonalInfo');
	};
	useEffect(() => {
		dispatch(setCurrentScreen('/user-select'));
	}, []);

	return (
		<AuthTemplate
			heading={t('Create Account')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={[]}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<UserSelect onSubmit={handleSelectUser} />
		</AuthTemplate>
	);
};

export default UserSelectScreen;
