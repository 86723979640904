export const SignupSidebarArray = [
	{
		id: 0,
		label: 'Create Account',
		screen: 'UserSelect',
	},
	{
		id: 1,
		label: 'Personal Information',
		screen: 'PersonalInfo',
	},
	{
		id: 2,
		label: 'Seller Agreement',
		screen: 'SellerAgreement',
	},
	{
		id: 3,
		label: 'Your Information',
		screen: 'YourInformation',
	},
	{
		id: 4,
		label: 'Payment Information',
		screen: 'CreditCardInformation',
	},
	{
		id: 5,
		label: 'Availability',
		screen: 'Availability',
	},
	{
		id: 6,
		label: 'Setting Catchment Area',
		screen: 'SettingCatchmentArea',
	},
	{
		id: 7,
		label: 'Products Upload',
		screen: 'ProductsUpload',
	},
	{
		id: 8,
		label: 'Verification',
		screen: 'SignupComplete',
	},
	{
		id: 9,
		label: '',
		screen: 'Home',
	},
];

export const CreateEventSidebarArray = [
	{
		id: 0,
		label: 'Event Details',
		screen: 'EventDetail',
	},
	{
		id: 1,
		label: 'Select Date & Time',
		screen: 'EventDuration',
	},
	{
		id: 2,
		label: 'Add Location',
		screen: 'EventLocation',
	},
	{
		id: 3,
		label: 'Add Catchment Area',
		screen: 'CatchmentArea',
	},
	{
		id: 6,
		label: 'Add Brand',
		screen: 'EventProduct',
	},
	{
		id: 4,
		label: 'Upload Media',
		screen: 'EventUpload',
	},
	{
		id: 5,
		label: 'Invite Guests',
		screen: 'EventInvite',
	},
	{
		id: 7,
		label: 'Add Host',
		screen: 'EventHost',
	},
	{
		id: 8,
		label: 'Complete',
		screen: 'EventComplete',
	},
];

export const EditUserSidebarItems = [
	{
		id: 0,
		label: 'Personal Information',
		screen: 'ProfilePersonalInformation',
	},
	{
		id: 1,
		label: 'Seller Agreement',
		screen: 'ProfilePersonalInformation',
	},
	{
		id: 2,
		label: 'Your Information',
		screen: 'ProfilePersonalInformation',
	},
	{
		id: 3,
		label: 'Payment Information',
		screen: 'ProfilePersonalInformation',
	},
];
