import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
// import Skeleton from '../Skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductCardSkeleton = () => {
	return (
		<View style={[styles.productDetail]}>
			<View style={{ flexDirection: 'row' }}>
				<Skeleton style={{ marginRight: 10 }} height={78} width={78} />

				<View style={styles.details}>
					<Skeleton
						width={getResponsiveStyle(180)}
						height={getResponsiveStyle(10)}
						style={{ borderRadius: 6 }}
					/>
					<Text style={{ fontSize: 14 }}>
						<Skeleton
							width={getResponsiveStyle(150)}
							height={getResponsiveStyle(8)}
							style={{ borderRadius: 6 }}
						/>
					</Text>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	details: { justifyContent: 'space-evenly' },
	userImg: { marginRight: 10, borderRadius: 27 },
	productDetail: {
		flexDirection: 'row',

		padding: 10,
		justifyContent: 'space-between',
	},
	view: {
		cursor: 'pointer',
		outlineStyle: 'none',
		borderBottomWidth: 1,
		borderBottomColor: 'transparent',
		transitionDuration: '200ms',
	},
});

export default ProductCardSkeleton;
