import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TStyle } from '../../../types/TStyle';

type TSize = number | 'large' | 'small' | undefined;

type Props = {
	size?: TSize;
	containerStyles?: TStyle;
	loaderStyles?: TStyle;
	fullView?: boolean;
	color?: string;
};

const Loader = ({
	fullView = false,
	size = 'large',
	containerStyles,
	loaderStyles,
	color,
}: Props) => {
	const primaryColors = usePrimaryColors();
	return (
		<View style={[fullView && styles.container, containerStyles]}>
			<ActivityIndicator
				color={color ?? primaryColors?.[0]}
				size={size}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
		minWidth: '100vw',
		zIndex: 1,
	},
});

export default Loader;
