import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { Hoverable } from 'react-native-web-hover';
import { LinearGradient } from 'expo-linear-gradient';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

const NotificationWrapper = ({
	children,
}: {
	children: React.ReactElement;
}) => {
	const primary = usePrimaryColors();

	return (
		<Hoverable
			children={({ hovered }) => (
				<View
					style={{
						borderLeftColor: hovered ? primary[0] : '#fff',
						borderLeftWidth: 2,
					}}
				>
					{React.cloneElement(children, { hovered })}
					{/* {hovered && (
						<LinearGradient
							colors={[
								'rgba(28, 28, 37, 0) 88.35%',
								'rgb(255, 255, 255) 2%',
							]}
							start={{
								x: 1,
								y: 0.5,
							}}
							end={{
								x: 1,
								y: 1,
							}}
							style={styles.gradientBox}
						/>
					)} */}
				</View>
			)}
		/>
	);
};

const styles = StyleSheet.create({
	gradientBox: {
		width: '100%',
		height: 70,
		position: 'absolute',
		bottom: 0,
	},
});

export default NotificationWrapper;
