import { ApolloError, useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import debounce from 'lodash.debounce';
import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View, Text } from 'react-native';
import { Input, Loader, NotAvailableMessage } from '../..';
import { palettes } from '../../../config';
import { SEARCH } from '../../../graphql/common/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { handleNavigationToEvent } from '../../../utils/handleNavigationToEvent';
import { verifyValueInDataPaginated } from '../../../utils/verifyValueInDataPaginated';
import { MagnifierGlassIcon } from '../icons';
import { SearchCard } from './SearchCard';
import SearchList from './SearchList';

const DEBOUNCE_INTERVAL = 200;

const isLastElementInArray = (array: any[], i: number) => {
	return array.length - 1 === i;
};
type Props = {
	type?: 'dropdown' | 'list';
	onPress?: () => any;
};
export const SearchWithDropdown = ({ type = 'dropdown', onPress }: Props) => {
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);
	const primary = usePrimaryColors();
	const dispatch = useAppDispatch();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [searchResult, setSearchResult] = useState<TSearch[]>();
	const [wasNoResultFound, setWasNoResultFound] = useState<boolean>();

	const [wasInputFocused, setWasInputFocused] = useState(false);
	const onChangeSearchText = (query: string) => {
		debouncedSearchQuery(query);

		// setSearchOn(true);
	};

	const [runSearchQuery, { loading: loadingSearchQuery }] = useLazyQuery<
		TSearchResponse,
		TSearchInputs
	>(SEARCH, {
		onCompleted: data => {
			const verifiedData = verifyValueInDataPaginated<
				TSearchResponse,
				TSearch,
				'searchBrandsUsersEvents'
			>(data, 'searchBrandsUsersEvents');

			if (!!verifiedData && verifiedData.length > 0) {
				setWasNoResultFound(false);
				setSearchResult(verifiedData);

				wasInputFocused &&
					verifiedData &&
					!isDropdownOpen &&
					setIsDropdownOpen(true);
			} else {
				setWasNoResultFound(true);
				setSearchResult(undefined);
			}
		},
		onError: (error: ApolloError) => {
			setWasNoResultFound(true);
			!!error && setSearchResult(undefined);
		},
	});
	const runSearchQueryWithVariables = (query: string) => {
		const limit = 5;
		runSearchQuery({
			variables: {
				query,
				limit,
			},
		});
	};

	const debouncedSearchQuery = debounce(
		runSearchQueryWithVariables,
		DEBOUNCE_INTERVAL
	);

	return (
		<ClickAwayListener
			onClickAway={() => {
				isDropdownOpen && setIsDropdownOpen(false);
				// isDropdownOpen && setSearchOn(false);
			}}
		>
			<View style={styles.container}>
				<View
					style={[
						{
							flexDirection: 'row',
							justifyContent: 'space-between',
							gap: 10,
							alignItems: 'center',
						},
						isLessThanDesktopBase && { width: '90vw' },
					]}
				>
					<Input
						decoration={
							loadingSearchQuery ? (
								<Loader size={'small'} />
							) : (
								<MagnifierGlassIcon style={styles.searchIcon} />
							)
						}
						placeholder={t('Search')}
						style={styleSearchInput(isLessThanDesktopBase)}
						onChangeText={onChangeSearchText}
						onFocus={() => {
							setWasInputFocused(true);
							// if(!isDropdownOpen && !!searchResult){
							// 	setIsDropdownOpen(true);
							// }
							// !isDropdownOpen && !!searchResult &&
						}}
						onBlur={() => {
							setWasInputFocused(false);
						}}
					/>
					{isLessThanDesktopBase && (
						<Text
							style={{
								color: primary[0],
								fontSize: getResponsiveStyle(16, 'font'),
							}}
							onPress={onPress}
						>
							{t('Cancel')}
						</Text>
					)}
				</View>

				{isDropdownOpen && !isLessThanDesktopBase && (
					<ScrollView
						style={[
							styles.dropdownContainer,
							styleDropdownContainer(),
						]}
					>
						{!!searchResult &&
						wasNoResultFound === false &&
						wasNoResultFound !== undefined ? (
							<>
								{searchResult.map((result, i) =>
									getSearchCardWithProps(
										result,
										isLastElementInArray(searchResult, i),
										navigation,
										loggedInUserDetails,
										dispatch
									)
								)}
							</>
						) : (
							wasNoResultFound !== undefined && (
								<View
									style={styles.notAvailableMessageContainer}
								>
									<NotAvailableMessage
										message={t('No results found!')}
									/>
								</View>
							)
						)}
					</ScrollView>
				)}
				{isLessThanDesktopBase && (
					<ScrollView
						style={[styles.listContainer, styleListContainer()]}
						contentContainerStyle={{ marginBottom: 'auto' }}
						showsVerticalScrollIndicator={false}
					>
						<SearchList searchData={searchResult} />
					</ScrollView>
				)}
			</View>
		</ClickAwayListener>
	);
};

const styles = StyleSheet.create({
	notAvailableMessageContainer: {
		paddingVertical: getResponsiveStyle(8),
		paddingHorizontal: getResponsiveStyle(18),
		alignItems: 'center',
	},
	container: {
		position: 'relative',
	},
	dropdownContainer: {
		width: '100%',
		position: 'absolute',
		height: 'max-content',
		borderRadius: 6,
		backgroundColor: 'white',
		zIndex: 5001,
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
		borderWidth: 1,
		borderColor: palettes.grey[3],
	},
	listContainer: {
		width: '100%',
		position: 'absolute',
		height: 'max-content',
		backgroundColor: 'white',
		zIndex: 5001,
	},
	searchIcon: {
		height: getResponsiveStyle(20, 'spacing'),
		width: getResponsiveStyle(20, 'spacing'),
	},
});

const styleSearchInput = (isLessThanDesktopBase: boolean) => {
	return {
		minWidth: isLessThanDesktopBase
			? '60vw'
			: getResponsiveStyle(320, 'dimensions'),
	};
};

const getLocation = (city?: string, countryLabel?: string) => {
	if (!!city && !!countryLabel) {
		return `${city}, ${countryLabel}`;
	} else if (!!city) {
		return city;
	} else if (!!countryLabel) {
		return countryLabel;
	} else {
		return '';
	}
};

const mapDataToSearchCardProps = (
	data: TSearch,
	navigation: TUseNavigation,
	loggedInUserDetails: any,
	dispatch: any
) => {
	switch (data.searchResultType) {
		case 'BRAND':
			return mapDataToBrandSearchCardProps(data, navigation);
		case 'EVENT':
			return mapDataToEventSearchCardProps(
				data,
				navigation,
				loggedInUserDetails,
				dispatch
			);
		case 'USER':
			return mapDataToUserSearchCardProps(data, navigation);
	}
};

function mapDataToUserSearchCardProps(
	data: TSearch,
	navigation: TUseNavigation
) {
	return {
		name: data.username ?? '',
		profileImage: data.personalInformation?.profileImageLink ?? '',
		address: getLocation(
			data.personalInformation?.address.city,
			data.personalInformation?.address.countryLabel
		),
		onPressContainer: () => {
			navigation.navigate('UserProfile', {
				userId: data._id,
			});
		},
	};
}

function mapDataToEventSearchCardProps(
	data: TSearch,
	navigation: TUseNavigation,
	loggedInUserDetails: any,
	dispatch: any
) {
	return {
		name: data.name ?? '',
		profileImage: data?.eventMedia?.[0]?.src ?? '',
		address:
			data.typeOfEvent === 'VIRTUAL' ? 'Live Event' : 'Physical Event',
		onPressContainer: () => {
			// @TODO add params to handleNavigationToEvent
			handleNavigationToEvent({
				navigation,
				loggedInUserDetails,
				event: data,
				dispatch,
			});
		},
	};
}

function mapDataToBrandSearchCardProps(
	data: TSearch,
	navigation: TUseNavigation
) {
	return {
		name: data.name ?? '',
		profileImage: data.profileImage ?? '',
		address: getLocation(
			data.personalInformation?.address.city,
			data.personalInformation?.address.countryLabel
		),
		onPressContainer: () => {
			navigation.navigate('BrandDetails', {
				_id: data._id,
			});
		},
	};
}

const getSearchCardWithProps = (
	data: TSearch,
	isLastElement: boolean,
	navigation: TUseNavigation,
	loggedInUserDetails: any,
	dispatch: any
) => {
	const propsToPass = mapDataToSearchCardProps(
		data,
		navigation,
		loggedInUserDetails,
		dispatch
	);

	return (
		<SearchCard
			key={data._id}
			isLastElement={isLastElement}
			{...propsToPass}
		/>
	);
};

function styleDropdownContainer() {
	return {
		top: getResponsiveStyle(64, 'dimensions'),
		minHeight: getResponsiveStyle(100, 'dimensions'),
		maxHeight: getResponsiveStyle(365, 'dimensions'),
		paddingVertical: getResponsiveStyle(8),
	};
}
function styleListContainer() {
	return {
		top: getResponsiveStyle(64, 'dimensions'),
		minHeight: '100vh',
		maxHeight: '100vh',
		paddingVertical: getResponsiveStyle(8),
	};
}
