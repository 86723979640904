import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { useAppSelector } from '../../../redux/store';
import { CategoryPill } from './CategoryPill';

type Props = {
	label: string;
	categories: TTagType[];
	selectedCategory?: string;
	onClick: (text: string) => void;
};

export const CategoryPillList = ({
	label,
	categories,
	selectedCategory,
	onClick,
}: Props) => {
	const { t } = useTranslation();
	const language = useAppSelector(state => state.ui.language);
	const onClickCategory = (category: string) => {
		onClick(category);
	};

	return (
		<View style={styles.categoryPillContainer}>
			{categories.map((category, index) => {
				return (
					<CategoryPill
						key={index}
						text={
							index === 1
								? `${t(label)} ${category[
										language
								  ]?.toLowerCase()}`
								: category[language]
						}
						selected={selectedCategory === category.value}
						onClick={onClickCategory}
						isFirstItem={index === 0}
						value={category.value}
					/>
				);
			})}
		</View>
	);
};

const styles = StyleSheet.create({
	categoryPillContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
});
