import { Platform } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';

const AgreementLink = styled.Text`
	color: ${palettes.grey[1]};
	padding-left: 4px;
	padding-right: 4px;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
	&:focus {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

type Props = {
	children: JSX.Element | string;
	file: TFile;
};

type TFile = 'PrivacyPolicy' | 'TOC' | 'SellerAgreement';

type TFileLinks = {
	[T in TFile]: string;
};

const FileLinks: TFileLinks = {
	TOC: 'https://ocpus-punch-dev.s3.eu-west-3.amazonaws.com/CGV+Ocpus.pdf',
	PrivacyPolicy:
		'https://ocpus-punch-dev.s3.eu-west-3.amazonaws.com/CGU+Ocpus+App%2BSite+Web.pdf',
	SellerAgreement:
		'https://ocpus-punch-dev.s3.eu-west-3.amazonaws.com/Contrat+Conseill%C3%A8re+X+Ocpus.pdf',
};

export const DownloadableFile = ({ file, children }: Props) => {
	return Platform.OS === 'web' ? (
		<AgreementLink>
			<a
				download
				style={{
					textDecoration: 'none',
					color: palettes.grey[1],
					borderBottom: 0,
				}}
				target={'_blank'}
				href={FileLinks[file]}
			>
				{children}
			</a>
		</AgreementLink>
	) : (
		<AgreementLink>{children}</AgreementLink>
	);
};
