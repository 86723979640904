import React, { useState } from 'react';
import {
	Platform,
	StyleProp,
	StyleSheet,
	TextInput,
	TextInputProps,
	TextStyle,
	View,
} from 'react-native';
import { Label } from '..';
import { palettes } from '../../config';
import { usePrimaryColors } from '../../hooks/usePrimaryColors';
import { TStyle } from '../../types/TStyle';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';

type Props = {
	containerStyles?: TStyle;
	innerContainerStyles?: TStyle;
	decoration?: JSX.Element;
	label?: string;
	labelProps?: TextInputProps;
	error?: string;
	actionText?: string;
} & TextInputProps;

const Input = ({
	style,
	containerStyles,
	decoration,
	label,
	labelProps,
	error,
	actionText,
	innerContainerStyles,
	...rest
}: Props) => {
	const [isFocused, setIsFocused] = useState(false);
	const primary = usePrimaryColors();
	const labelStyle = labelProps?.style;
	delete labelProps?.style;
	return (
		<View style={containerStyles}>
			{!!label && (
				<Label
					error={error}
					label={label}
					labelStyle={labelStyle}
					actionText={actionText}
				/>
			)}
			<View
				style={[
					styles.container,
					isFocused && {
						borderColor: primary?.[1],
						borderRadius: 8,
					},
					!!error && styles.error,
					innerContainerStyles,
				]}
				{...labelProps}
			>
				<TextInput
					style={[
						Platform.OS === 'web' && !error
							? styleFocusOutline(primary)
							: styleErrorOutline(),
						styles.input,
						styleInput(decoration),
						!!error && styles.inputError,
						style,
					]}
					{...rest}
					onFocus={event => {
						rest.onFocus && rest.onFocus(event);
						setIsFocused(true);
					}}
					onBlur={event => {
						rest.onBlur && rest.onBlur(event);
						setIsFocused(false);
					}}
				/>
				{decoration && (
					<View style={[styles.decoration, styleDecoration()]}>
						{decoration}
					</View>
				)}
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	input: {
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	focused: {
		borderWidth: 3,

		borderRadius: 8,
	},
	error: {
		borderWidth: 3,
		borderColor: `${palettes.red[0]}20`,
		borderRadius: 8,
	},
	container: {
		position: 'relative',
		zIndex: 10,
		borderWidth: 3,
		borderColor: 'transparent',
	},
	decoration: {
		height: '100%',
		position: 'absolute',
		justifyContent: 'center',
	},
});

export default Input;

const styleInput = (decoration: JSX.Element | undefined) => {
	return {
		paddingRight: decoration ? 40 : 24,
		height: getResponsiveStyle(48, 'dimensions'),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		paddingLeft: getResponsiveStyle(24),
	};
};

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as TODO;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary?.[0],
		outerWidth: 1,
	} as TODO;
}

function styleDecoration() {
	return { right: getResponsiveStyle(13, 'dimensions') };
}
