import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import { Button, Input, Label } from '../..';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { tConvert } from '../../../utils/monthsUtils';
import {
	resetAffiliatedBrands,
	resetAffiliatedHosts,
	resetEventMedia,
	resetInvitedPeople,
	setaffiliatedBrands,
	setAffiliatedHosts,
	setCatchmentAreas,
	setEventDetails,
	setEventLocation,
	setEventMedia,
	setEventTimeAndDuration,
	setInvitedPeople,
	setLiveShopping,
} from '../../../redux/createEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const validationSchema = Yup.object({
	title: Yup.string().required('Required'),
	description: Yup.string().required('Required'),
});

type TInitialValues = Yup.InferType<typeof validationSchema>;

const initialValues: TInitialValues = {
	description: '',
	title: '',
};

const EventDetailForm = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	// const { liveShopping, typeOfEvent } = useAppSelector(state => ({
	// 	liveShopping: state.createEvent.liveShopping,
	// 	typeOfEvent: state.createEvent.typeOfEvent,
	// }));

	const isEditEvent = useAppSelector(state => state.getEvent.isEditEvent);
	const event = useAppSelector(state => state.getEvent.event);

	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);

	const onClickLiveShoppingButtons = (value: boolean) => {
		dispatch(setLiveShopping(value));
	};

	const editValues: TInitialValues = {
		description: event.description,
		title: event.name,
	};

	useEffect(() => {
		if (isEditEvent) {
			const date = new Date(+event.startingEventDateTime);
			const startDateGMT = date.toString().split(' ');
			if (event.typeOfEvent === 'PHYSICAL') {
				const {
					address1,
					address2,
					city,
					zipCode,
					countryCode,
					countryLabel,
				} = event.eventLocation;
				const eventLocation = {
					address1: address1,
					address2: address2,
					city: city,
					zipCode: zipCode,
					countryCode: countryCode,
					countryLabel: countryLabel,
				};
				dispatch(
					setEventLocation(eventLocation as TCommonAddressInput)
				);
				dispatch(setCatchmentAreas(event.catchmentAreas));
			}

			dispatch(
				setEventDetails({
					description: event.description,
					name: event.name,
				})
			);
			const { contructedTime, startDate } = tConvert(date, startDateGMT);
			dispatch(
				setEventTimeAndDuration(
					// event.startingEventDateTime
					`${startDate} ${contructedTime[0]}:${contructedTime[1]}`
				)
			);
			dispatch(resetInvitedPeople([]));
			event?.invitedPeople?.map(userToInvite => {
				dispatch(setInvitedPeople(userToInvite?._id));
			});
			dispatch(resetAffiliatedBrands([]));
			event.affiliatedBrands.map(affiliatedBrand => {
				dispatch(setaffiliatedBrands(affiliatedBrand._id));
			});
			dispatch(resetAffiliatedHosts([]));
			event.affiliatedHosts.map(selectedHostToInvite => {
				dispatch(setAffiliatedHosts(selectedHostToInvite?._id ?? ''));
			});
			dispatch(resetEventMedia([]));
			event.eventMedia.map(media => dispatch(setEventMedia(media)));
		}
	}, []);

	return (
		<Formik
			validationSchema={validationSchema}
			enableReinitialize={true}
			initialValues={isEditEvent ? editValues : initialValues}
			onSubmit={values => {
				const { description, title: name } = values;
				dispatch(
					setEventDetails({
						description,
						name,
					})
				);
				goToNextStepScreen();
			}}
		>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				errors,
				touched,
				isValid,
				dirty,
				values,
			}) => (
				<>
					<View style={styleRowContainer()}>
						<Input
							label={t('Title')}
							value={values.title}
							containerStyles={[styles.flex1]}
							onChangeText={handleChange('title')}
							onBlur={handleBlur('title')}
							error={
								touched.title && errors.title
									? errors.title
									: ''
							}
						/>
					</View>
					<View style={styleRowContainer()}>
						<Input
							label={t('Description')}
							value={values.description}
							multiline
							containerStyles={styles.flex1}
							style={[styleDescription(), styles.description]}
							onChangeText={handleChange('description')}
							onBlur={handleBlur('description')}
							error={
								touched.description && errors.description
									? errors.description
									: ''
							}
						/>
					</View>
					{/* {typeOfEvent === 'PHYSICAL' && (
						<View>
							<Label label={t('With live shopping')} />
							<View style={styles.buttonsContainer}>
								<Button
									title={t('Yes')}
									variant={
										liveShopping
											? 'outline'
											: 'grey-outline'
									}
									size="lg"
									outerContainerProps={{
										style: styles.flex1,
									}}
									onPress={() =>
										onClickLiveShoppingButtons(true)
									}
								/>
								<Button
									title={t('No')}
									variant={
										!liveShopping &&
										liveShopping !== undefined
											? 'outline'
											: 'grey-outline'
									}
									size="lg"
									outerContainerProps={{
										style: styles.flex1,
									}}
									onPress={() =>
										onClickLiveShoppingButtons(false)
									}
								/>
							</View>
						</View>
					)} */}
					<Button
						title={t('Next')}
						variant={
							(isValid && dirty) || isEditEvent
								? // &&
								  // // if type of event is physical, then liveshopping should not
								  // // be undefined. In case of virtual event, live shopping should
								  // // not matter hence return true
								  // (typeOfEvent === 'PHYSICAL'
								  // 	? liveShopping !== undefined
								  // 	: true)
								  'primary'
								: 'disabled'
						}
						size="lg"
						onPress={handleSubmit}
						outerContainerProps={{
							style: {
								marginTop: getResponsiveStyle(40),
							},
						}}
					/>
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	buttonsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	flex1: {
		flex: 1,
	},
	description: {
		paddingVertical: 20,
	},
});

export default EventDetailForm;

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(34),
	} as TStyle;
};

function styleDescription() {
	return {
		height: getResponsiveStyle(152, 'dimensions'),
	};
}
