export const verifyValueInDataPaginated = <
	CompleteResponseType extends TAPIResponse<
		{
			total: number;
			data: SingleResponseType[];
		},
		QueryFunctionType
	>,
	SingleResponseType,
	QueryFunctionType extends string
>(
	data: CompleteResponseType,
	queryFunctionName: QueryFunctionType
) => {
	if (
		data &&
		data?.[queryFunctionName] &&
		data?.[queryFunctionName].data &&
		data?.[queryFunctionName].success &&
		data?.[queryFunctionName].data?.data
	) {
		return data?.[queryFunctionName].data?.data;
	} else {
		return undefined;
	}
};
