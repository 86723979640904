import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
	mutation createOrder($args: OrderInput) {
		createOrder(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on CreateOrderResponse {
						orderId
						brands
						items {
							affiliatedHost
							affiliatedSellerConnectId
							quantity
							price
							brand
							affiliatedSellerId
							selfHosted
							brandConnectId
							vat
							eventName
						}
					}
				}
			}
		}
	}
`;

export const CREATE_ADMIN_TAX_AND_REVENUE = gql`
	mutation createAdminRevenueAndTax($netRevenue: Float, $tax: Float) {
		createAdminRevenueAndTax(netRevenue: $netRevenue, tax: $tax) {
			statusCode
			success
			message
		}
	}
`;

export const SET_PAYMENT_STATUS = gql`
	mutation setOrderPaymentStatus(
		$orderId: String
		$paymentStatus: OrderPaymentStatusType
	) {
		setOrderPaymentStatus(
			orderId: $orderId
			paymentStatus: $paymentStatus
		) {
			statusCode
			success
			message
			data {
				... on Order {
					_id
					paymentStatus
				}
			}
		}
	}
`;
