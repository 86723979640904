import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { CREATE_STATUS } from '../../graphql/status/mutation';
import { TUseNavigation } from '../../types/exportedTypes';

const useCreateStatus = () => {
	const navigation = useNavigation<TUseNavigation>();
	const [createStatus, states] = useMutation(CREATE_STATUS);

	const executeCreateStatusMutation = ({
		status,
		file,
		fileType,
		isEventPost,
		event,
	}: {
		status: string;
		file: string;
		fileType: string;
		isEventPost?: boolean;
		event?: string;
	}) => {
		createStatus({
			variables: {
				status,
				file,
				fileType,
				isEventPost,
				event,
			},
		});
	};
	return { executeCreateStatusMutation, ...states };
};

export default useCreateStatus;
