import { gql } from '@apollo/client';

const CREATE_LIKE = gql`
	mutation likePost($likeType: TypeOfLike!, $parentId: ID!) {
		likePost(args: { likeType: $likeType, parentId: $parentId }) {
			statusCode
			success
		}
	}
`;

export { CREATE_LIKE };
