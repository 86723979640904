import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../auth/fragments';

const GET_USER_STATS = gql`
	query getUserDashboardStats($_id: ID) {
		getUserDashboardStats(_id: $_id) {
			statusCode
			success
			data {
				... on UserDashboardStats {
					eventsCount
					publishedEventsCount
					followersCount
					attendedEventsCount
					eventsInvitedCount
				}
			}
		}
	}
`;

const GET_USER_CATEGORY = gql`
	${USER_FRAGMENT}
	query getUsersByFavouriteCategories(
		$category: [String]
		$typeOfAccount: TypeOfAccount
	) {
		getUsersByFavouriteCategories(
			tags: $category
			typeOfAccount: $typeOfAccount
		) {
			statusCode
			success
			data {
				data {
					...UserFragment
				}
			}
		}
	}
`;

const GET_USER_FOLLOWERS = gql`
	${USER_FRAGMENT}
	query getFollowers($typeOfAccount: TypeOfAccount) {
		getFollowers(followersTypeOfAccount: $typeOfAccount) {
			statusCode
			success
			data {
				data {
					...UserFragment
				}
			}
		}
	}
`;

export { GET_USER_STATS, GET_USER_CATEGORY, GET_USER_FOLLOWERS };
