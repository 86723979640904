import { useLazyQuery, useMutation } from '@apollo/client';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, View, Text } from 'react-native';
import { Button, Ratings } from '../..';
import useGetEntityRating from '../../../api/rating/useGetEntityRating';
import { palettes } from '../../../config';
import { CREATE_CART } from '../../../graphql/cart/mutation';
import { GET_CART } from '../../../graphql/cart/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { twoDp } from '../../../utils/twoDP';
import { placeholderImage } from '../../../utils/productCategories';
import {
	setGlobalCartOpen,
	setUsersCart,
	setVisitorCart,
} from '../../../redux/userSlice';
import {
	isBuyingOngoing,
	isEevntOngoing,
} from '../../../utils/statusOfEvent/isEventOngoing';
import { isEventDone } from '../../../utils/statusOfEvent/isEventDone';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import {
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { useNavigation } from '@react-navigation/native';
import { countryAndCurrency } from '../../../utils/countryNamesWithCodes';

type TSize = 'small' | 'large';

type Props = {
	isLastItem?: boolean;
	containerStyles?: TStyle;
	optionValue?: string;
	hasRating?: boolean;
	size?: TSize;
	image?: TProductImage;
	name?: string;
	price?: number;
	compareAtPrice?: number;
	rating: number;
	id?: string;
	eventDetails?: TODO;
	variant?: Variant;
	brand?: string;
};

const ProductCard = ({
	isLastItem = false,
	containerStyles,
	optionValue,
	size = 'large',
	hasRating = true,
	image,
	name,
	price,
	compareAtPrice,
	brand,
	rating,
	id,
	eventDetails,
	variant,
}: Props) => {
	const [isLoadingVisitorAddToCart, setIsLoadingVisitorAddToCart] =
		useState(false);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const { visitorCartStore } = useAppSelector(state => state.user);
	const userId = useAppSelector(state => state.auth.loggedInUserDetails?._id);
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(
		state => state.auth
	);
	const dispatch = useAppDispatch();
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	let productBrand: string;
	if (brand) {
		productBrand = brand;
	} else if (eventDetails?.affiliatedBrands?.[0]?._id) {
		productBrand = eventDetails?.affiliatedBrands?.[0]?._id;
	} else if (
		eventDetails?.eventDetails?.eventDetails?.affiliatedBrands?.[0]?._id
	) {
		productBrand =
			eventDetails?.eventDetails?.eventDetails?.affiliatedBrands?.[0]
				?._id;
	}
	const brandInCartArray = usersCart.map(
		item =>
			item?.productId?.associatedProduct?.brand?._id ??
			item?.starterkitId?.brand?._id
	);
	const brandInCart = new Set(brandInCartArray);

	const [createCart, { loading: createLoading }] = useMutation(CREATE_CART);
	const { t } = useTranslation();

	const { setAlert } = useSetAlert();

	let inCart = false;
	usersCart.map(product_ => {
		if (product_?.type === 'Product' && product_?.productId?._id === id) {
			inCart = true;
		}
	});

	const [getUpdatedCart] = useLazyQuery(GET_CART, {
		onCompleted: data => {
			let cart: Cart = [];
			data?.getCart?.data?.data.map((item: TODO) => {
				cart.push(item.items);
			});
			dispatch(setUsersCart(cart));
			dispatch(setGlobalCartOpen(true));
		},
	});

	const availableVariantCombination: any = () => {
		const variantWithQuantity = Object.values(
			variant?.option?.variatorValues?.[0]?.variatorValues || {}
		)?.find((data: any) => data?.quantity >= 1);
		return variantWithQuantity;
	};

	const isItemOutOfStock = availableVariantCombination()
		? availableVariantCombination()?.quantity <= 0
		: +variant?.option?.inventory?.quantity <= 0;

	const buyNowText = isItemOutOfStock ? t('Out of stock') : t('Add to cart');

	const currencyCountry =
		eventDetails?.affiliatedBrands?.[0]?.address?.countryLabel ||
		eventDetails?.eventDetails?.eventDetails?.affiliatedBrands?.[0]?.address
			?.countryLabel;

	const findCurrencyAndRate = countryAndCurrency?.find(
		data => data?.country === currencyCountry
	);

	const mutateCart = () => {
		// if (isItemOutOfStock) {
		// 	setAlert(t('Out of stock'), 'danger');
		// 	return;
		// }
		if (!isUserLoggedIn) {
			if (brandInCart.size === 0 || brandInCart.has(productBrand)) {
				setIsLoadingVisitorAddToCart(true);
				const cartItem = {
					eventId: eventDetails?.eventDetails?.eventDetails,
					quantity: 1,
					productId: variant,
					type: 'Product',
					optionValue,
					price: Number(
						variant?.option?.values?.find(
							value => value.name === optionValue
						)?.price
					),
				};

				setTimeout(() => {
					if (visitorCartStore?.length) {
						const addToCart = [...visitorCartStore, cartItem];
						dispatch(setVisitorCart(addToCart as Cart));
					} else {
						dispatch(setVisitorCart([cartItem] as any));
					}
					setAlert(t('Item Added to Cart'), 'normal');
					setIsLoadingVisitorAddToCart(false);
					dispatch(setGlobalCartOpen(true));
				}, 500);
			} else {
				setAlert(
					t('You cannot add items of different brands to cart'),
					'danger'
				);
			}

			// dispatch(setTypeOfAccount('CONSUMER'));
			// dispatch(setPrimaryColors('CONSUMER'));
			// dispatch(setSignupSidebarValues('CONSUMER'));
			// navigation.navigate('PersonalInfo');
			return;
		}
		if (brandInCart.size === 0 || brandInCart.has(productBrand)) {
			createCart({
				variables: {
					args: {
						items: {
							eventId:
								eventDetails?.eventDetails?.eventDetails?._id,
							quantity: 1,
							productId: id,
							type: 'Product',
							price: parseInt(price),
							optionValue,
						},
					},
				},
				// awaitRefetchQueries: true,
				// refetchQueries: [{ query: GET_CART }],
				onCompleted: data => {
					if (data.createCart.success) {
						getUpdatedCart();
						// dispatch(setUsersCart(usersCart.concat(data.createCart.data.items)))
						setAlert(t('Item Added to Cart'), 'normal');
					} else {
						setAlert(t('Unable to add item to cart'), 'danger');
					}
				},
				onError: () => {
					setAlert(t('Unable to add item to cart'), 'danger');
				},
			});
		} else {
			setAlert(
				t('You cannot add items of different brands to cart'),
				'danger'
			);
		}
	};

	// const { executeEntityRatingQuery, data: productRating } =
	// 	useGetEntityRating();

	// useEffect(() => {
	// 	executeEntityRatingQuery({
	// 		entityId: id as string,
	// 	});
	// }, []);s

	const isGuestIdIncluded = () => {
		return (
			typeOfAccount === 'CONSUMER' &&
			eventDetails?.eventDetails?.eventDetails?.invitedPeople
				?.map((user: any) => user?._id)
				?.includes(loggedInUserDetails?._id)
		);
	};

	const isHostAbleToBuy = () => {
		return (
			typeOfAccount === 'HOST' &&
			!eventDetails?.eventDetails?.eventDetails?._id
		);
	};

	// const rating = productRating?.getAverageRating?.data?.averageEntityRating;
	const computedIsEventDone =
		(typeOfAccount === 'CONSUMER' || !typeOfAccount) &&
		eventDetails?.eventDetails?.eventDetails?._id &&
		!isEventDone(eventDetails?.eventDetails?.eventDetails);

	return (
		<>
			<View
				style={[
					styles.container,
					styleContainer(isLastItem, isLessThanDesktopBase, size),
					containerStyles,
				]}
			>
				<Image
					source={{ uri: image?.src ?? placeholderImage }}
					style={[styles.image, styleImage()]}
				/>
				<Text
					style={[
						styles.description,
						styleDescription(size),
						{
							textAlign: size === 'large' ? 'left' : 'center',
						},
					]}
					numberOfLines={2}
				>
					{name ?? t('Product Name')}
				</Text>
				<View
					style={[
						{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							flexDirection: 'row',
						},
						ratingAddToCartMobile(isLessThanDesktopBase),
					]}
				>
					<View
						style={
							(styles.priceRatingContainer,
							{
								alignItems:
									size === 'large' ? 'flex-start' : 'center',
							})
						}
					>
						<View style={styles.priceContainer}>
							{(compareAtPrice as number) > 0 && (
								<Text style={styles.startingPrice}>
									{getSymbolFromCurrency(
										findCurrencyAndRate?.currency || 'eur'
									)}
									{twoDp(
										((compareAtPrice as number) *
											(findCurrencyAndRate?.rate ||
												1)) as number
									)}
								</Text>
							)}
							{(availableVariantCombination()?.price ||
								price) && (
								<Text style={[styles.price]}>
									{getSymbolFromCurrency(
										findCurrencyAndRate?.currency || 'eur'
									)}{' '}
									{twoDp(
										(availableVariantCombination()?.price ||
											price) *
											(findCurrencyAndRate?.rate || 1)
									)}
								</Text>
							)}
						</View>
						{size === 'large' && (
							<Ratings
								ratings={rating}
								containerStyles={styles.ratingsContainer}
							/>
						)}
					</View>
					{(isHostAbleToBuy() ||
						(isGuestIdIncluded() &&
							isBuyingOngoing(
								eventDetails?.eventDetails?.eventDetails
							)) ||
						computedIsEventDone) &&
						(inCart ? (
							<Button
								title={t('In Cart')}
								disabled={true}
								variant="outline"
								size="sm"
							/>
						) : !inCart &&
						  (createLoading || isLoadingVisitorAddToCart) ? (
							// <Text>Adding to cart...</Text>
							<Button
								title={t('Adding to cart...')}
								variant="outline"
								size="sm"
							/>
						) : (
							!inCart && (
								<Button
									title={buyNowText}
									variant={
										isItemOutOfStock
											? 'disabled'
											: 'primary'
									}
									size="sm"
									onPress={() => mutateCart()}
								/>
							)
						))}
				</View>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		borderRadius: 6,
		flex: 1,
	},
	image: {
		width: '100%',
		borderRadius: 6,
	},
	description: {
		color: palettes.dark[2],
		height: 40,
	},
	priceContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	price: {
		color: '#F96152',
		fontWeight: '700',
		flex: 1,
	},
	startingPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#7C889B',
		marginRight: 10,
		textDecorationLine: 'line-through',
	},
	priceRatingContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		height: 35,
	},
	ratingsContainer: {
		paddingBottom: 0,
	},
});

export default ProductCard;
function styleDescription(size: TSize) {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: getResponsiveStyle(size === 'large' ? 12 : 8, 'font'),
	};
}

function styleImage() {
	return {
		height: getResponsiveStyle(240, 'dimensions'),
		marginBottom: getResponsiveStyle(8),
	};
}

function styleContainer(
	isLastItem: boolean,
	isLessThanDesktopBase: boolean,
	size: TSize
): TStyle {
	return {
		width: getResponsiveStyle(208, 'dimensions'),
		marginRight: getResponsiveStyle(
			isLastItem && !isLessThanDesktopBase ? 40 : 8
		),
		// maxWidth: getResponsiveStyle(size === 'large' ? 250 : 200),
	};
}

function ratingAddToCartMobile(isLessThanDesktopBase: boolean): TStyle {
	return {
		flexDirection: 'column',
		alignItems: 'left',
	};
}
