import { gql } from '@apollo/client';

export const GET_ALL_LIVE_EVENTS_FRAGMENT = gql`
	fragment LiveEventFragment on LiveEvent {
		_id
		name
		description
		isStreamGenerated
		affiliatedSeller {
			... on User {
				_id
				username
				email
				gender
				rating
				connectAccountInfo {
					connectAccountId
					lastUrl
				}
				personalInformation {
					description
					profileImageLink
					address {
						city
						countryCode
						countryLabel
					}
				}
			}
		}
		affiliatedHosts {
			... on User {
				_id
				username
				email
				gender
				personalInformation {
					description
					profileImageLink
					address {
						city
						countryCode
						countryLabel
					}
				}
			}
		}
		invitedPeople {
			... on User {
				_id
				username
				email
				gender
				personalInformation {
					description
					profileImageLink
					address {
						city
						countryCode
						countryLabel
					}
				}
			}
		}
		affiliatedBrands {
			_id
			profileImage
			address {
				city
				countryCode
				countryLabel
			}
			connectAccountInfo {
				connectAccountId
				lastUrl
			}
		}
		isHostRequired
		typeOfEvent
		isApproved
		createdAt
		startingEventDateTime
		eventMedia
		isSellerApplicationOpen
		isHostApplicationAccepted
		isApproved
		deviceMetadata {
			streamRecordingInstance
			deviceType
			os
		}
		eventStreamTimeline {
			startedAt
			endedAt
		}
	}
`;

export const GET_ALL_PHYSICAL_EVENTS_FRAGMENT = gql`
	fragment PhysicalEventFragment on PhysicalEvent {
		_id
		name
		description
		affiliatedSeller {
			... on User {
				_id
				username
				email
				gender
				rating
				connectAccountInfo {
					connectAccountId
					lastUrl
				}
				personalInformation {
					description
					profileImageLink
					address {
						city
						countryCode
						countryLabel
					}
				}
			}
		}
		affiliatedHosts {
			... on User {
				_id
				username
				email
				gender
				personalInformation {
					description
					profileImageLink
					address {
						city
						countryCode
						countryLabel
					}
				}
			}
		}
		invitedPeople {
			... on User {
				_id
				username
				email
				gender
				personalInformation {
					description
					profileImageLink
					address {
						city
						countryCode
						countryLabel
					}
				}
			}
		}
		affiliatedBrands {
			_id
			profileImage
			address {
				city
				countryCode
			}
		}
		isHostRequired
		typeOfEvent
		isApproved
		createdAt
		startingEventDateTime
		eventMedia
		isSellerApplicationOpen
		isHostApplicationAccepted
		catchmentAreas {
			city
			zipCode
		}
		eventLocation {
			address1
			address2
			city
			zipCode
			countryCode
			countryLabel
		}
	}
`;

// export const Get_ALL_EVENTS = gql`
// 	${GET_ALL_LIVE_EVENTS_FRAGMENT}
// 	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
// 	query getAllEvents {
// 		getAllEvents(sort: { startingEventDateTime: -1 }) {
// 			statusCode
// 			message
// 			success
// 			data {
// 				total
// 				data {
// 					...PhysicalEventFragment
// 					...LiveEventFragment
// 				}
// 			}
// 		}
// 	}
// `;

export const GET_ALL_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllEvents($find: JSON, $current: Int, $limit: Int, $sort: JSON) {
		getAllEvents(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;
export const GET_ALL_INVITED = gql`
	query getAllEvents($find: JSON, $current: Int, $limit: Int, $sort: JSON) {
		getAllEvents(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
			]
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on PhysicalEvent {
								_id
								name
								description
								isApproved
								invitedPeople {
									_id
									username
									email
									personalInformation {
										profileImageLink
										firstName
										lastName
										address {
											countryCode
											countryLabel
											zipCode
											city
										}
									}
								}
							}
							... on LiveEvent {
								_id
								name
								description
								isApproved
								invitedPeople {
									_id
									username
									email
									typeOfAccount
									personalInformation {
										profileImageLink
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_ALL_PUBLISHED_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllEvents($current: Int, $limit: Int, $sort: JSON) {
		getAllEvents(
			find: { statusOfEvent: "PUBLISHED" }
			current: $current
			limit: $limit
			sort: $sort
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_ALL_PUBLISHED_EVENTS_FOR_EVENT_SCREEN = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getPublishedEvents($current: Int, $limit: Int) {
		getPublishedEvents(current: $current, limit: $limit) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						...PhysicalEventFragment
						...LiveEventFragment
					}
				}
			}
		}
	}
`;

// populate: { path: "invitedPeople", model: "User" }
export const GET_PENDING_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllEvents($status: StatusOfEvent) {
		getAllEvents(status: $status, sort: { startingEventDateTime: -1 }) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_CONSUMER_PENDING_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getPendingEvents($current: Int, $limit: Int) {
		getPendingEvents(current: $current, limit: $limit) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						...PhysicalEventFragment
						...LiveEventFragment
					}
				}
			}
		}
	}
`;

export const GET_PAST_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllEvents($filterBy: FilterBy, $current: Int, $limit: Int) {
		getAllEvents(
			filterBy: $filterBy
			current: $current
			limit: $limit
			sort: { startingEventDateTime: -1 }
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
		) {
			statusCode
			message
			success
			data {
				total
				data {
					...PhysicalEventFragment
					...LiveEventFragment
				}
			}
		}
	}
`;

export const GET_SPECIFIC_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllEvents($find: JSON) {
		getAllEvents(find: $find, sort: { startingEventDateTime: -1 }) {
			statusCode
			message
			success
			data {
				total
				data {
					...PhysicalEventFragment
					...LiveEventFragment
				}
			}
		}
	}
`;

export const GET_UPCOMING_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllEvents($status: StatusOfEvent, $current: Int, $limit: Int) {
		getAllEvents(
			status: $status
			current: $current
			limit: $limit
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
			sort: { startingEventDateTime: 1 }
		) {
			statusCode
			message
			success
			data {
				total
				data {
					...PhysicalEventFragment
					...LiveEventFragment
				}
			}
		}
	}
`;

export const GET_ALL_UPCOMING_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllUpcomingEvents($current: Int, $limit: Int) {
		getAllUpcomingEvents(
			current: $current
			limit: $limit
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
		) {
			statusCode
			message
			success
			data {
				total
				data {
					...PhysicalEventFragment
					...LiveEventFragment
				}
			}
		}
	}
`;

export const GET_RAW_PAST_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getRawAllEvents($current: Int, $limit: Int) {
		getRawAllEvents(
			current: $current
			limit: $limit
			past: true
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
		) {
			statusCode
			message
			success
			data {
				total
				data {
					...PhysicalEventFragment
					...LiveEventFragment
				}
			}
		}
	}
`;

export const GET_RAW_ALL_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getRawAllEvents($current: Int, $limit: Int) {
		getRawAllEvents(
			current: $current
			limit: $limit
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
		) {
			statusCode
			message
			success
			data {
				total
				data {
					...PhysicalEventFragment
					...LiveEventFragment
				}
			}
		}
	}
`;

// populate: { path: "invitedPeople", model: "User" }
// 			sort: { startingEventDateTime: -1 }

export const GET_PERSONALIZED_EVENTS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getPersonalizedEvents($find: String, $current: Int, $limit: Int) {
		getPersonalizedEvents(
			find: $find
			current: $current
			limit: $limit
			populate: [
				{ path: "invitedPeople", model: "User" }
				{ path: "affiliatedBrands", model: "Brand" }
				{ path: "affiliatedSeller", model: "User" }
			]
		) {
			statusCode
			message
			success
			data {
				total
				data {
					...PhysicalEventFragment
					...LiveEventFragment
				}
			}
		}
	}
`;

export const GET_UPCOMING_PLANNED_EVENTS_FOR_CONSUMERS = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getAllEvents($status: StatusOfEvent) {
		getAllEvents(status: $status, sort: { startingEventDateTime: -1 }) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_EVENT_SEARCH_USERS = gql`
	query searchUsers($query: String!, $typeOfAccount: TypeOfAccount!) {
		searchUsers(query: $query, typeOfAccount: $typeOfAccount) {
			statusCode
			success
			message
			data {
				total
				data {
					... on User {
						_id
						username
						personalInformation {
							profileImageLink
							firstName
							lastName
							address {
								countryCode
								countryLabel
								zipCode
								city
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_STREAM_RECORD_LINK = gql`
	query getCloudRecording($_id: String!) {
		getCloudRecording(_id: $_id) {
			statusCode
			data {
				download_link
			}
		}
	}
`;

export const GET_RECOMMENDED_HOST = gql`
	query getRecommendedHosts(
		$catchmentAreas: [CatchmentAreaInput!]
		$current: Int
		$limit: Int
	) {
		getRecommendedHosts(
			catchmentAreas: $catchmentAreas
			current: $current
			limit: $limit
		) {
			statusCode
			success
			message
			data {
				total
				data {
					... on User {
						_id
						email
						typeOfAccount
						username
						website
						companyLegalName
						personalInformation {
							address {
								city
								countryLabel
								zipCode
								countryCode
							}
							profileImageLink
							firstName
							lastName
						}
					}
				}
			}
		}
	}
`;

export const GET_EVENTS_SELECTED_FOR_YOU = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getEventsSelectedForUser($current: Int, $limit: Int) {
		getEventsSelectedForUser(current: $current, limit: $limit) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_EVENTS_AROUND_YOU = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getEventsAroundYou(
		$status: TStatusType
		$distance: Int
		$current: Int
		$limit: Int
	) {
		getEventsAroundYou(
			status: $status
			distance: $distance
			current: $current
			limit: $limit
			populate: { path: "invitedPeople", model: "User" }
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_HOST_PUBLISHED_EVENTS_AROUND_YOU = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getEventsAroundUser($current: Int, $limit: Int) {
		getEventsAroundUser(current: $current, limit: $limit) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_HOST_PUBLISHED_EVENTS_SELECTED_FOR_YOU = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getEventsSelectedForUser($current: Int, $limit: Int) {
		getEventsSelectedForUser(current: $current, limit: $limit) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...PhysicalEventFragment
							...LiveEventFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_SINGLE_EVENT = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getEvent($_id: ID!, $typeOfAccount: TypeOfAccount) {
		getEvent(_id: $_id, typeOfAccount: $typeOfAccount) {
			statusCode
			message
			success
			data {
				...PhysicalEventFragment
				...LiveEventFragment
			}
		}
	}
`;

export const GET_SINGLE_LIVE_EVENT_DETAILS = gql`
	query getEvent($_id: ID!, $typeOfAccount: TypeOfAccount) {
		getEvent(
			_id: $_id
			typeOfAccount: $typeOfAccount
			populate: { path: "invitedPeople", model: "User" }
		) {
			statusCode
			success
			message
			data {
				... on LiveEvent {
					_id
					description
					name
					isStreamGenerated
					isApproved
					isHostApplicationAccepted
					startingEventDateTime
					socialStreamKey {
						facebookStreamKey {
							id
						}
					}
					eventStreamTimeline {
						startedAt
						endedAt
					}
					affiliatedHosts {
						_id
						email
						username
						personalInformation {
							profileImageLink
						}
					}
					affiliatedSeller {
						_id
						email
						username
						personalInformation {
							profileImageLink
							description
						}
						connectAccountInfo {
							connectAccountId
							userId
						}
					}
					affiliatedBrands {
						_id
						address {
							country
							countryCode
							countryLabel
						}
						connectAccountInfo {
							connectAccountId
							userId
						}
						brandProducts {
							_id
							name
							averagePrice
							shortDescription
							description
							publishedStatus
							rating
							images {
								_id
								src
								alt
							}
							variants {
								_id
								variator
								value
								option {
									name
									values {
										compareAtPrice
										price
										name
									}
									inventory {
										quantity
										isTracked
									}
								}
								GTIN
								image {
									src
									_id
								}

								weight
								vat
							}
						}
					}
					invitedPeople {
						_id
						email
						gender
						attendedEvents
						username
						personalInformation {
							profileImageLink
							firstName
							lastName
							address {
								countryCode
								countryLabel
								city
							}
						}
					}
					deviceMetadata {
						streamRecordingInstance
						deviceType
						os
					}
					isHostRequired
					liveStream {
						... on LiveStream {
							p2p {
								id
								name
								privacy
								url
							}
							stream {
								channel {
									arn
									authorized
									ingestEndpoint
									latencyMode
									name
									playbackUrl
									recordingConfigurationArn
									type
								}
								streamKey {
									arn
									value
								}
							}
							token
							# tokens {
							# 	sT
							# 	hT
							# }
						}
					}
				}
			}
		}
	}
`;

export const VALIDATE_ZIPCODE_CITY = gql`
	query validateZipcode($args: ZipcodeValidatorInputs!) {
		validateZipcode(args: $args) {
			statusCode
			success
			message
		}
	}
`;
