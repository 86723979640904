import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Pressable } from 'react-native-web-hover';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	title: string;
	text: string;
	onSubmit: (userType: TTypeOfAccount) => void;
	value?: TTypeOfAccount;
};

export const UserSelectButton = ({ text, title, onSubmit, value }: Props) => {
	const onSelectUserType = (userType: TTypeOfAccount) => {
		onSubmit(userType);
	};
	const typeOfAccount = useAppSelector(
		state => state.createUser.args.typeOfAccount
	);
	const primary = usePrimaryColors();
	return (
		<Pressable
			style={({ focused }) => [
				styles.button,
				styleButtonOuterContainer(),
				styles.buttonOuterContainer,
				(focused || typeOfAccount === value) && {
					borderColor: primary?.[1],
				},
			]}
		>
			{({ hovered, focused, pressed }) => (
				<View
					style={[
						styles.buttonInnerContainer,
						styleButtonInnerContainer(),

						(focused || typeOfAccount === value) && {
							borderColor: primary?.[0],
						},
						(pressed || typeOfAccount === value) && {
							borderColor: primary?.[0],
						},
					]}
				>
					<View
						style={{
							marginLeft: getResponsiveStyle(24),
							marginRight: getResponsiveStyle(24),
						}}
					>
						<Text
							style={[
								styles.title,
								styleTitle(),
								hovered && styles.titleHovered,
								(focused || typeOfAccount === value) &&
									styles.titleFocused,
								(pressed || typeOfAccount === value) &&
									styles.titlePressed,
							]}
						>
							{pressed && onSelectUserType(value ?? 'CONSUMER')}
							{title}
						</Text>

						<Text style={[styles.text, styleText()]}>{text}</Text>
					</View>
				</View>
			)}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	buttonOuterContainer: {
		borderWidth: 3,
		borderColor: 'transparent',
		borderRadius: 8,
	},
	button: {
		backgroundColor: '#fff',
	},
	buttonInnerContainer: {
		borderWidth: 1,
		borderColor: palettes.grey[3],
		borderRadius: 6,
	},
	buttonLabel: { fontWeight: 'bold' },
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',

		color: palettes.grey[0],
	},
	title: {
		fontStyle: 'normal',
		fontWeight: 'normal',

		color: palettes.dark[4],
	},
	titleFocused: {
		color: palettes.dark[0],
	},
	titleHovered: {
		color: palettes.dark[0],
	},
	titlePressed: {
		color: palettes.dark[0],
	},
});

const styleButtonInnerContainer = () => {
	return { paddingVertical: getResponsiveStyle(18) };
};

function styleText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
	};
}

function styleTitle() {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(6),
	};
}

function styleButtonOuterContainer() {
	return {
		marginBottom: getResponsiveStyle(12),
	};
}
