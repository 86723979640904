import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { SidebarEventList } from '../..';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	events: TGetAllEventsData[];
};

export const InfoSidebar = ({ events }: Props) => {
	const { t } = useTranslation();
	return (
		<View style={[styles.container, styleContainer()]}>
			<SidebarEventList
				heading={t('Brand Affiliated Events')}
				events={events}
			/>
		</View>
	);
};
function styleContainer() {
	return {
		paddingLeft: getResponsiveStyle(32),
		paddingTop: getResponsiveStyle(32),
	};
}

const styles = StyleSheet.create({
	container: {
		width: '20%',
		borderLeftWidth: 1,
		borderLeftColor: palettes.grey[5],
	},
});
