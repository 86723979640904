import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
// import Skeleton from './Skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BrandProductItem = () => {
	return (
		<View style={styles.productContainer}>
			<Skeleton
				width={getResponsiveStyle(180)}
				height={getResponsiveStyle(210)}
				style={{ borderRadius: 6 }}
			/>
			<Text style={styles.sometext}>
				<Skeleton
					width={getResponsiveStyle(100)}
					height={getResponsiveStyle(10)}
					style={{ borderRadius: 6 }}
				/>
			</Text>
			<View>
				<Text style={styles.sometext}>
					<Skeleton
						width={getResponsiveStyle(50)}
						height={getResponsiveStyle(6)}
						style={{ borderRadius: 6 }}
					/>
				</Text>
				<Skeleton
					width={getResponsiveStyle(80)}
					height={getResponsiveStyle(10)}
					style={{ borderRadius: 6 }}
				/>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	image: {
		borderRadius: 6,
	},
	sometext: {
		marginVertical: 3,
		width: '70%',
		display: 'flex',
		flexDirection: 'column',
	},

	productContainer: {
		marginRight: getResponsiveStyle(20),
	},
});

export default BrandProductItem;
