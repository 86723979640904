import { useTranslation } from 'react-i18next';
import { Text, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

export const LiveLabel = () => {
	const { t } = useTranslation();
	return <Text style={styles.label}>{t('Live')}</Text>;
};

const styles = StyleSheet.create({
	label: {
		textTransform: 'uppercase',
		fontSize: getResponsiveStyle(8, 'font'),
		lineHeight: getResponsiveStyle(10, 'font'),
		width: getResponsiveStyle(26, 'dimensions'),
		height: getResponsiveStyle(12, 'dimensions'),
		fontWeight: '700',
		borderRadius: 6,
		backgroundColor: palettes.red[0],
		color: palettes.light[0],
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginHorizontal: 'auto',
	},
});
