import React from 'react';
import { StyleSheet, View } from 'react-native';
import { GoBackButton, Logo } from '../../..';
import { palettes } from '../../../../config';
import { usePrimaryColors } from '../../../../hooks/usePrimaryColors';
import { NavLinks } from './NavLinks';

type Props = {
	hasLinks?: boolean;
	hasGoBack?: boolean;
	logoLocation?: 'left' | 'center' | 'hidden';
};

export const LogoAndNavLinks = ({
	logoLocation,
	hasGoBack,
	hasLinks,
}: Props) => {
	const primary = usePrimaryColors();
	return (
		<View style={styles.logoLinkContainer}>
			{logoLocation === 'left' && (
				<Logo
					tint={primary?.[0]}
					style={styles.logo}
					width={38}
					height={38}
					scale={1.3}
					viewBox="0 0 38 38"
				/>
			)}
			{hasGoBack && <GoBackButton />}
			{hasLinks && <NavLinks />}
		</View>
	);
};

const styles = StyleSheet.create({
	logoLinkContainer: { flexDirection: 'row', alignItems: 'center' },
	logo: { minHeight: 38 },
});
