import { gql } from '@apollo/client';

export const BRAND_PRODUCTS = gql`
	fragment BrandProducts on Product {
		_id
		name
		averagePrice
		shortDescription
		publishedStatus
		description
		rating
		createdAt
		category
		defaultVariant
		images {
			_id
			src
			alt
		}
		brand {
			name
			_id
			connectAccountInfo {
				connectAccountId
				lastUrl
			}
		}
		variants {
			_id
			variator
			value
			GTIN
			weight
			weightUnit
			image {
				src
				_id
			}
			option {
				name
				values {
					compareAtPrice
					price
					name
				}
				variatorValues
				inventory {
					quantity
					isTracked
					isAvailable
				}
			}
			vat
		}
	}
`;

export const BRAND_PRODUCTS_WITH_TOTAL = gql`
	${BRAND_PRODUCTS}
	fragment BrandProductsWithTotal on All {
		total
		data {
			... on Product {
				...BrandProducts
			}
		}
	}
`;

export const BRAND_FRAGMENT = gql`
	${BRAND_PRODUCTS_WITH_TOTAL}
	fragment BrandFragment on Brand {
		_id
		name
		profileImage
		address {
			address1
			countryLabel
			countryCode
			city
			address1
		}
		phoneNumber
		email
		rating
		tags
		additionalMedia
		description
		products(
			populate: ["images"]
			find: { publishedStatus: "ACTIVE" }
			current: 0
			limit: 10
		) {
			...BrandProductsWithTotal
		}
	}
`;
