import { useLazyQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import styled from 'rn-css';
import {
	GoBackButton,
	Loader,
	NotAvailableMessage,
	Table,
} from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { palettes } from '../config';
import { GET_SINGLE_ORDER } from '../graphql/order/queries';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../hooks/useSetAlerts';
import { TUseRoute } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { formatAMPM } from '../utils/monthsUtils';
import { twoDp } from '../utils/twoDP';
import { useAppDispatch } from '../redux/store';
import { setCurrentScreen } from '../redux/uiSlice';
const SingleOrderView = () => {
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const HEADERS = [
		{
			headerLabel: t('PRODUCTS'),
			width: getResponsiveStyle(230),
		},
		{
			headerLabel: t('DESCRIPTION'),
			width: getResponsiveStyle(230),
		},
		{
			headerLabel: t('SKU'),
			width: getResponsiveStyle(120),
		},
		{
			headerLabel: t('PRICE'),
			width: getResponsiveStyle(120),
		},
		{
			headerLabel: t('QTY'),
			width: getResponsiveStyle(90),
		},
		{
			headerLabel: t('TOTAL PRICE'),
			width: getResponsiveStyle(200),
		},
		{
			headerLabel: t('SUB TOTAL'),
			width: getResponsiveStyle(200),
		},
		{
			headerLabel: t('WATCH'),
			width: getResponsiveStyle(200),
		},
	];
	const FIELDS = [
		{ label: t('SubTotals'), value: 'SubTotals' },
		{ label: t('Shipping'), value: 'Shipping' },
		{ label: t('Discount'), value: 'Discount' },
		// { label: t('Total Tax'), value: 'Total Tax' },
		{ label: t('Total Ordered Amount'), value: 'Total Ordered Amount' },
	];
	const routes = useRoute<TUseRoute<'OrderDetails'>>();
	const [OrderProducts, setOrderProducts] = useState<any>([]);
	const [hovered, setHovered] = useState<number>();
	const [date, setDate] = useState<string>();
	const { _id, orderDetails } = routes.params;
	const [OrderDetails, setOrderDetails] = useState<Order>();
	const [OrderAccount, setOrderAccount] = useState<string[]>();

	const { isMobileView } = useIsSpecificViewportWidth();
	const [getSingleOrder, { loading: checkLoading }] = useLazyQuery(
		GET_SINGLE_ORDER,
		{
			onCompleted: data => {
				if (
					data &&
					data.getOrderById &&
					data.getOrderById.data &&
					data.getOrderById.success
				) {
					setOrderDetails(data.getOrderById.data);
				} else {
					setAlert(t('No available Orders'), 'danger');
				}
			},
			onError: error => {
				setAlert(t('No available Orders'), 'danger');
			},
		}
	);
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setCurrentScreen('/single-order'));
	}, []);
	useEffect(() => {
		setOrderDetails(orderDetails);
	}, [orderDetails?.orderId]);
	const ContentContainer = styled.View``;
	useEffect(() => {
		if (!OrderDetails) {
			return;
		} else if (!OrderDetails?.orderId) {
			getSingleOrder({ variables: { _id } });
		} else {
			let sub = 0;
			const dateObj = new Date(Number(OrderDetails?.purchasedOn));
			let dateString = formatAMPM(dateObj);
			let tax = 0;

			OrderDetails?.items?.map(item => {
				tax += item?.variant?.vat
					? (item?.variant?.vat *
							item?.variant?.option?.values.find(
								value => value.name === item.optionValue
							)?.price *
							item?.quantity) /
					  100
					: 0;
			});

			// const orderTax = OrderDetails?.items?.map(item => {
			// 	return item?.variant?.vat
			// 		? (item?.variant?.vat *
			// 				item?.variant?.option?.values.find(
			// 					value => value.name === item.optionValue
			// 				)?.price *
			// 				item?.quantity) /
			// 				100
			// 		: 0;
			// });

			setDate(dateString);
			OrderDetails &&
				setOrderAccount([
					twoDp(OrderDetails?.purchasedTotalAmount),
					String(OrderDetails?.deliveryFee),
					'0.00',
					// String(tax),
					twoDp(
						typeof OrderDetails?.purchasedTotalAmount === 'string'
							? parseFloat(OrderDetails?.purchasedTotalAmount) +
									OrderDetails?.deliveryFee
							: OrderDetails?.purchasedTotalAmount +
									OrderDetails?.deliveryFee
					),
				]);

			OrderDetails
				? setOrderProducts(
						OrderDetails?.items?.map(item => {
							let price =
								item?.variant?.option?.values.find(
									value => value.name === item.optionValue
								)?.price ?? item?.starterKit?.price;
							let qty = item.quantity;
							sub = sub + parseInt(price) * qty;
							return {
								name: item?.variant
									? item?.variant?.associatedProduct?.name +
									  ' -' +
									  item?.variant?.option?.name +
									  ' ' +
									  item?.variant?.option?.values.find(
											value =>
												value.name === item.optionValue
									  )?.name
									: item?.starterKit?.name ?? 'N/A',
								shortDescription: item?.variant
									? item?.variant?.associatedProduct
											?.shortDescription
									: item?.starterKit?.description ?? 'N/A',
								sku:
									item?.variant?.option?.inventory
										?.quantity ?? 'N/A',
								price:
									getSymbolFromCurrency('eur') +
										twoDp(price) ?? 'n/a',
								quantity: qty,
								totalPrice:
									getSymbolFromCurrency('eur') +
									twoDp(parseFloat(price) * qty),

								subTotal:
									getSymbolFromCurrency('eur') + twoDp(sub),
								watch: '',
								_id: item?.variant?._id,
								type: item.itemType,
								images: item?.variant?.image,
								variants: item?.variant,
							};
						})
				  )
				: null;
		}
	}, [OrderDetails?.orderId]);
	return (
		<DesktopTemplate>
			<GoBackButton route={'Profile'} />

			{checkLoading ? (
				<Loader fullView />
			) : (
				<>
					{OrderDetails?.orderId ? (
						<>
							<View
								style={{
									marginTop: getResponsiveStyle(28),
									width: '100%',
									justifyContent: 'space-between',
								}}
							>
								<View>
									<Text
										style={{
											fontSize: getResponsiveStyle(
												18,
												'font'
											),
											fontWeight: '400',
										}}
									>
										{OrderDetails?.orderId}
									</Text>
									<Text style={[hoverTextStyle1(9, hovered)]}>
										{date}
									</Text>
								</View>
							</View>
							<ScrollView
								horizontal
								style={{ paddingLeft: 40, width: '100%' }}
							>
								<View style={{}}>
									<View
										style={[
											styles.profileListContainer,
											{
												borderBottomColor:
													palettes.grey[5],
												borderBottomWidth: 1,
												marginTop:
													getResponsiveStyle(54),
											},
										]}
									>
										<Table
											type="singleOrder"
											headers={HEADERS}
											content={OrderProducts}
										/>
									</View>
									<View
										style={[
											styles.profileListContainer,
											{
												borderBottomColor:
													palettes.grey[5],
												borderBottomWidth: 1,
											},
										]}
									>
										<View
											style={[
												styles.profileListContainer,
												{
													borderBottomColor:
														palettes.grey[5],
													borderBottomWidth: 0,
													marginLeft:
														getResponsiveStyle(0),
													// position: 'absolute',
													left: '60%',
												},
											]}
										>
											{!isMobileView && (
												<View
													style={
														styles.sectionDivider
													}
												/>
											)}
											<View
												style={{
													// flexBasis: isLessThanDesktopBase ? 'auto' : '45%',
													// flex: 1,
													width: getResponsiveStyle(
														160
													),
													paddingTop: '50%',
													right: 0,
												}}
											>
												{FIELDS.map((field, index) => {
													return (
														<ContentContainer
															onMouseEnter={() =>
																setHovered(
																	index
																)
															}
															onMouseLeave={() =>
																setHovered(
																	undefined
																)
															}
														>
															<Text
																style={[
																	hoverTextStyle1(
																		index,
																		hovered
																	),
																]}
															>
																{field?.label}
															</Text>
														</ContentContainer>
													);
												})}
											</View>
											{!isMobileView && (
												<View
													style={
														styles.sectionDivider
													}
												/>
											)}
											<View
												style={{
													paddingTop: '50%',
													width: getResponsiveStyle(
														90
													),
												}}
											>
												{OrderAccount?.map(
													(field, index) => {
														return (
															<ContentContainer
																onMouseEnter={() =>
																	setHovered(
																		index
																	)
																}
																onMouseLeave={() =>
																	setHovered(
																		undefined
																	)
																}
															>
																<Text
																	style={[
																		hoverTextStyle1(
																			index,
																			hovered
																		),
																	]}
																>
																	{getSymbolFromCurrency(
																		'eur'
																	)}{' '}
																	{field}
																</Text>
															</ContentContainer>
														);
													}
												)}
											</View>
										</View>
									</View>
								</View>
							</ScrollView>
						</>
					) : (
						<>
							<View style={styles.container}>
								<NotAvailableMessage
									message={`${t('No available Orders')}`}
								/>
							</View>
						</>
					)}
				</>
			)}
		</DesktopTemplate>
	);
};
const styles = StyleSheet.create({
	sectionDivider: {
		width: 1,
		backgroundColor: palettes.grey[5],
	},
	container: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		height: '50%',
	},

	profileListContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
});
const hoverTextStyle1 = (index: number, hover?: number) => {
	return [
		{
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '10px',
			paddingLeft: '10px',
			color: palettes.grey[1],
		},
		index === hover
			? {
					color: 'black',
					fontWeight: 'bold',
			  }
			: { color: palettes.grey[1] },
	];
};
export default SingleOrderView;
