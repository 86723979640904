import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { INVITE_ALL_FOLLOWERS } from '../../graphql/events/mutations';
import { TUseNavigation } from '../../types/exportedTypes';

const useInviteAllFollowers = () => {
	const navigation = useNavigation<TUseNavigation>();
	const [inviteAllFollowers] = useMutation(INVITE_ALL_FOLLOWERS);

	const executeInviteAllFollowersMutation = ({
		eventId,
		typeOfAccount,
		isInviteAll,
		invitedUsersWithoutDuplicate,
	}: {
		eventId: string;
		typeOfAccount: string;
		isInviteAll?: boolean;
		invitedUsersWithoutDuplicate?: string[];
	}) => {
		inviteAllFollowers({
			variables: {
				eventId,
				typeOfAccount,
				isInviteAll,
				invitedUsersWithoutDuplicate,
			},
		});
	};
	return { executeInviteAllFollowersMutation };
};

export default useInviteAllFollowers;
