import React from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import EventDate from '../event-date/EventDate';
import { InvitationRequestNotification } from './InvitationRequestNotifcation';
import { LiveEventInvitation } from './LiveEventInvitation';
import NotificationWrapper from './NotificationWrapper';
import { ProfileViewNotification } from './ProfileViewNotification';
import { StandardNotification } from './StandardNotification';
import { TStyle } from '../../../types/TStyle';
import { StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { OrderReviewNotification } from './OrderReviewNotification';
import { TypeOfNotification } from '../../../types/notificationTypes';

interface Props {
	notification: NotificationType;
	removeFromNotification: (id: string) => void;
}

const NotificationLayouts = ({
	notification,
	removeFromNotification,
}: Props) => {
	if (
		notification.notificationType ===
		TypeOfNotification?.EVENT_INVITATION_NOTIFICATION
	) {
		return (
			<>
				<NotificationWrapper>
					<EventDate
						size="medium"
						eventHeadingStyles={styles.eventHeadingStyle}
						eventDetailsContainerStyles={eventDetailsContainer()}
						containerStyles={[
							eventContainer(),
							styles.eventContainer,
						]}
						type="notification"
						notificationItem={notification}
						removeFromNotification={removeFromNotification}

						// item={notification}
					/>
				</NotificationWrapper>
			</>
		);
	} else if (
		notification.notificationType ===
			TypeOfNotification?.EVENT_HOSTING_NOTIFICATION ||
		notification.notificationType ===
			TypeOfNotification?.EVENT_SUBSCRIPTION_NOTIFICATION
	) {
		return (
			<>
				<NotificationWrapper>
					<InvitationRequestNotification
						notification={notification}
						removeFromNotification={removeFromNotification}
					/>
				</NotificationWrapper>
			</>
		);
	} else if (
		notification.notificationType ===
			TypeOfNotification?.FOLLOW_NOTIFICATION ||
		notification.notificationType ===
			TypeOfNotification?.EVENT_HOSTING_NOTIFICATION_RESPONSE ||
		notification.followerId
	) {
		return (
			<>
				<NotificationWrapper>
					<ProfileViewNotification
						notification={notification}
						removeFromNotification={removeFromNotification}
					/>
				</NotificationWrapper>
			</>
		);
	} else if (
		notification.notificationType ===
		TypeOfNotification?.ORDER_PURCHASE_NOTIFICATION
	) {
		return (
			<>
				<NotificationWrapper>
					<OrderReviewNotification
						notification={notification}
						removeFromNotification={removeFromNotification}
					/>
				</NotificationWrapper>
			</>
		);
	} else if (
		notification.notificationType ===
		TypeOfNotification?.STATUS_NOTIFICATION
	) {
		return (
			<>
				<NotificationWrapper>
					<LiveEventInvitation />
				</NotificationWrapper>
			</>
		);
	} else {
		return (
			<>
				<NotificationWrapper>
					<StandardNotification
						notification={notification}
						removeFromNotification={removeFromNotification}
					/>
				</NotificationWrapper>
			</>
		);
	}
};

export default NotificationLayouts;

function eventContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(17),
	};
}

function eventDetailsContainer(): TStyle {
	return {
		width: `calc(100% - ${getResponsiveStyle(80)}px)`,
	};
}

const styles = StyleSheet.create({
	eventContainer: {
		borderTopWidth: 1,
		borderTopColor: palettes.grey[5],
	},
	eventHeadingStyle: {
		fontWeight: '600',
	},
});

{
	/* <NotificationWrapper>
								<NotificationTypeLayout
									title="Leslie Alexander"
									subtitle="Accept your Invite to add on Meetup Event Name"
									type="acceptance"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Floyd Miles"
									subtitle="Rejected your request."
									type="rejection"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Live Event"
									subtitle="Looking to indulge in events and buy products?"
									type="live-event"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Looking to indulge in events and buy products?"
									subtitle="Started following you."
									type="addition"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Albert Flores"
									subtitle="Send you a massage on your Inbox."
									type="message"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Annette Black"
									subtitle="Un-following your latest updates."
									type="minus"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Ralph Edwards"
									subtitle="Comment you on Meetup Event Name Here  Post."
									type="comment"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Voucher"
									subtitle="You Recived $1,200 reward voucher from Eleanor Pena Event"
									type="voucher"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Guy Hawkins"
									subtitle="@mention you on this Meetup Event Name Here Post."
									type="mention"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Ronald Richards"
									subtitle="Create new event Meetup Event Name Here today."
									type="new-event"
								/>
							</NotificationWrapper>
							<NotificationWrapper>
								<NotificationTypeLayout
									title="Darlene Robertson"
									subtitle="4.5 Rating on your event Meetup Event Name Here today."
									type="rating"
								/>
							</NotificationWrapper> */
}
