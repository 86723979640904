import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
import styled from 'rn-css';

const SVG = styled(Svg)`
	cursor: pointer;
`;

type Props = {
	tintColor?: string;
} & SvgProps;

const CrossIcon = ({ tintColor, onLayout, ...props }: Props) => (
	<SVG {...props} viewBox="0 0 24 24">
		<Path fill="none" d="M0 0h24v24H0z" />
		<Path
			fill={tintColor}
			d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
		/>
	</SVG>
);

export default CrossIcon;
