import { useAppSelector } from './../../redux/store';
import { useQuery } from '@apollo/client';
import { GET_USER } from '../../graphql/auth/queries';
import {
	setIsUserLoggedIn,
	setLoggedInUserDetails,
} from '../../redux/authSlice';
import { useAppDispatch } from '../../redux/store';
import { setPrimaryColors } from '../../redux/uiSlice';

const useGetUser = () => {
	const dispatch = useAppDispatch();

	const user = useAppSelector(state => state.auth.loggedInUserDetails);
	const onCompleted = (responseData: TGetUserResponse) => {
		if (responseData?.getUser?.success && responseData?.getUser?.data) {
			const data = responseData.getUser.data;
			if (!user) {
				dispatch(
					setLoggedInUserDetails({
						isUserLoggedIn: true,
						...data,
					})
				);
			}
			dispatch(setPrimaryColors(data?.typeOfAccount));
		}
	};
	const onError = () => {
		dispatch(setIsUserLoggedIn(false));
	};

	const { data, loading, error } = useQuery<TGetUserResponse>(GET_USER, {
		onCompleted,
		onError,
	});

	return {
		data,
		loading,
		error,
	};
};

export default useGetUser;
