import {
	currentTimeGreaterThanEventStartingLessThanBuying,
	currentTimeGreaterThanEventStartingLessThanBuyingPeriod,
} from '../handleNavigationToEvent/currentTimeGreaterThanEventStartingLessThanBuyingPeriod';
export const isEevntOngoing = (
	event: TGetAllEventCommon | TSingleLiveEventData
) => {
	if (!event) {
		return undefined;
	} else {
		return currentTimeGreaterThanEventStartingLessThanBuyingPeriod(
			event?.startingEventDateTime
		);
	}
};
export const isBuyingOngoing = (
	event: TGetAllEventCommon | TSingleLiveEventData
) => {
	if (!event) {
		return undefined;
	} else {
		return currentTimeGreaterThanEventStartingLessThanBuying(
			event?.startingEventDateTime
		);
	}
};
