import React from 'react';
import { Text, View } from 'react-native';
import { palettes } from '../../../../../config';
import { getResponsiveStyle } from '../../../../../utils/getResponsiveStyle';

type TBrandStatsProps = {
	isLastItem: boolean;
	entity: string;
	stat: number;
};

export const BrandStats = ({ isLastItem, ...det }: TBrandStatsProps) => {
	return (
		<View style={{ marginRight: isLastItem ? 0 : getResponsiveStyle(40) }}>
			<Text
				style={[
					{
						fontSize: getResponsiveStyle(14, 'font'),
						lineHeight: getResponsiveStyle(18, 'font'),
					},
					{
						color: palettes.primary[0],
						fontWeight: '600',
					},
				]}
			>
				{det.stat}
			</Text>
			<Text
				style={[
					{
						fontSize: getResponsiveStyle(12, 'font'),
						lineHeight: getResponsiveStyle(20, 'font'),
					},
					{
						color: palettes.grey[0],
						fontWeight: '400',
					},
				]}
			>
				{det.entity}
			</Text>
		</View>
	);
};
