import { useAppSelector } from '../../../redux/store';
import { useNavigation } from '@react-navigation/native';
import { t } from 'i18next';
import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import { Button, ChevronDown, HorizontalDivider, Profile } from '../..';
import { useLogout } from '../../../api/auth';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TUseNavigation } from '../../../types/exportedTypes';
import { TStyle, TStyleImage } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import LanguageSelector from '../language-selector/language-selector';
import UserFilledIcon from '../icons/User';
import Spacing from '../spacing/Spacing';
import { CartIconWithItems } from '../desktop-navigation-bar/CartIconWithItems';
import TranslateIcon from '../icons/TranslateIcon';
import { useDispatch } from 'react-redux';
import {
	setCurrentEvent,
	setIsAffiliatedHostEditingEvent,
	setIsEditEvent,
} from '../../../redux/getEventSlice';
import ModalComponent from '../modal/ModalComponent';
import LanguageSelectorMobile from '../language-selector/language-selector-mobile';
import { useTranslation } from 'react-i18next';
import { resetCreateEventSlice } from '../../../redux/createEventSlice';
import { Linking } from 'react-native';
import BrandCreate from '../brand-create-modal/BrandCreate';

const ProfileIconWithDropdown = () => {
	const { executeLogoutQuery } = useLogout();
	const navigator = useNavigation<TUseNavigation>();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isLessThanDesktopBase, isDesktop } = useIsSpecificViewportWidth();
	const { isUserLoggedIn } = useAppSelector(state => state.auth);

	const [open, setOpen] = useState(false);
	const [showSearchModal, setShowSearchModal] = useState(false);
	const onPressCreateEvent = () => {
		dispatch(setIsEditEvent(false));
		dispatch(setIsAffiliatedHostEditingEvent(false));
		dispatch(resetCreateEventSlice());
		navigator.navigate('CreateEvent');
	};

	const onClickProfile = () => {
		navigator.navigate('Profile');
	};

	const onClickLogout = () => {
		if (isUserLoggedIn) {
			executeLogoutQuery();
		}
		navigator.navigate('Login');
	};

	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	const [showBrandCreateModal, setShowBrandCreateModal] = useState(false);

	const handleBrandCreateRequest = () => {
		if (loggedInUserDetails?.affiliatedBrand) {
			Linking.openURL('https://admin.ocpus.io/');
		} else {
			setShowBrandCreateModal(true);
		}
	};
	return (
		<>
			<ModalComponent
				openState={showSearchModal}
				type="fullpage"
				onClose={() => {
					setShowSearchModal(false);
				}}
				header={`${t('Choose Language')}`}
				subtitle={`${t('More translations coming soon!')}`}
			>
				<LanguageSelectorMobile />
			</ModalComponent>
			<View style={{ position: 'absolute' }}>
				<BrandCreate
					isOpen={showBrandCreateModal}
					onPress={() => setShowBrandCreateModal(false)}
				/>
			</View>
			<ClickAwayListener onClickAway={() => setOpen(false)}>
				<View
					style={[
						styles.container,
						styleContainer(isLessThanDesktopBase),
					]}
				>
					<Pressable
						style={{ flexDirection: 'row', alignItems: 'center' }}
						onPress={() => setOpen(prev => !prev)}
					>
						{isDesktop && <LanguageSelector />}
						{loggedInUserDetails?.personalInformation
							?.profileImageLink ? (
							<Image
								source={{
									uri: loggedInUserDetails
										?.personalInformation?.profileImageLink,
								}}
								style={[
									styleProfileIcon(),
									{ borderRadius: 9999 },
								]}
							/>
						) : (
							<View
								style={[
									styleProfileIcon(),
									styles.imageContainer,
								]}
							>
								<Image
									style={[{ width: 30, height: 30 }]}
									source={require('../../../assets/defaultProfileImage.svg')}
								/>
							</View>
						)}

						{isDesktop &&
							(!open ? (
								<ChevronDown
									onPress={() => setOpen(prev => !prev)}
									containerStyles={
										styles.chevronContainerStyles
									}
									style={styleChevronIcon()}
								/>
							) : (
								<ChevronDown
									onPress={() => setOpen(prev => !prev)}
									containerStyles={
										styles.chevronContainerStyles
									}
									style={[
										styleChevronIcon(),
										styles.chevronIconClosed,
									]}
								/>
							))}
					</Pressable>
					{open && (
						<Pressable
							style={[
								styles.dropdownContainer,
								styleDropdownContainer(isLessThanDesktopBase),
							]}
							onPress={() => setOpen(false)}
						>
							<View style={[styleProfileContainer()]}>
								<Profile
									name={
										loggedInUserDetails?.username || 'USER'
									}
									city={
										loggedInUserDetails?.personalInformation
											?.address?.city || 'Paris'
									}
									country={
										loggedInUserDetails?.personalInformation
											?.address?.countryLabel || 'France'
									}
									countryCode={
										loggedInUserDetails?.personalInformation
											?.address?.countryCode || 'fr'
									}
									countryLabel={
										loggedInUserDetails?.personalInformation
											?.address?.countryLabel || 'France'
									}
									profileImage={
										loggedInUserDetails?.personalInformation
											?.profileImageLink
									}
									hasAccountVerified={
										loggedInUserDetails?.isVerified
									}
								/>
							</View>
							{!isDesktop && (
								<View style={[styleLinkContainer()]}>
									<ProfileTextMobile onPress={onClickProfile}>
										<Spacing>
											<UserFilledIcon
												fillColor={palettes.dark[4]}
											/>
											<Text>{t('Profile')}</Text>
										</Spacing>
									</ProfileTextMobile>
									<ProfileTextMobile>
										<CartIconWithItems hasText={true} />
									</ProfileTextMobile>
									<ProfileTextMobile
										onPress={() => setShowSearchModal(true)}
									>
										<Spacing>
											<TranslateIcon
												fillColor={palettes.dark[4]}
											/>
											<Text>{t('Language')}</Text>
										</Spacing>
									</ProfileTextMobile>
									{loggedInUserDetails?.typeOfAccount ===
										'SELLER' && (
										<View>
											<Button
												onPress={onPressCreateEvent}
												outerContainerProps={{
													style: {
														paddingHorizontal:
															getResponsiveStyle(
																24
															),
													},
												}}
												size="sm"
												variant="primary"
												title={`+ ${t('Create Event')}`}
											></Button>
											<Button
												onPress={
													handleBrandCreateRequest
												}
												outerContainerProps={{
													style: {
														paddingHorizontal:
															getResponsiveStyle(
																24
															),
													},
												}}
												size="sm"
												variant="primary"
												title={t('My Brand')}
											></Button>
										</View>
									)}
								</View>
							)}

							<HorizontalDivider
								paddingBottom={getResponsiveStyle(18)}
							/>
							{!isDesktop && (
								<View style={[styleLinkContainer()]}>
									<LogoutTextMobile onPress={onClickLogout}>
										{t('Logout')}
									</LogoutTextMobile>
								</View>
							)}
							{isDesktop && (
								<View style={[styleLinkContainer()]}>
									<ProfileText onPress={onClickProfile}>
										{t('Profile')}
									</ProfileText>
									<LogoutText onPress={onClickLogout}>
										{t('Logout')}
									</LogoutText>
								</View>
							)}
						</Pressable>
					)}
				</View>
			</ClickAwayListener>
		</>
	);
};

const PressableLink = styled(Text)`
	cursor: pointer;
	font-weight: 600;
	font-size: ${getResponsiveStyle(16, 'font')}px;
	line-height: ${getResponsiveStyle(16, 'font')}px;
	padding-left: ${getResponsiveStyle(24)}px;
	padding-right: ${getResponsiveStyle(24)}px;
	height: ${getResponsiveStyle(40, 'dimensions')}px;
	display: flex;
	align-items: center;
	&:hover {
		background: ${palettes.grey[6]};
	}
`;

const LogoutTextMobile = styled(PressableLink)`
	color: ${palettes.red[0]};
	font-size: ${getResponsiveStyle(14, 'font')}px;
	height: ${getResponsiveStyle(20, 'dimensions')}px;
`;

const ProfileText = styled(PressableLink)`
	color: ${palettes.dark[0]};
`;
const ProfileTextMobile = styled(PressableLink)`
	color: ${palettes.dark[4]};
	font-size: ${getResponsiveStyle(14, 'font')}px;
	font-weight: 500;
`;
const LogoutText = styled(PressableLink)`
	color: ${palettes.red[0]};
`;

function styleLinkContainer() {
	return {
		paddingBottom: getResponsiveStyle(16),
	};
}

function styleProfileContainer() {
	return {
		padding: getResponsiveStyle(24),
	};
}

function styleDropdownContainer(isLessThanDesktopBase: boolean): TStyle {
	return {
		minHeight: getResponsiveStyle(224, 'dimensions'),
		width: getResponsiveStyle(280, 'dimensions'),
		top: getResponsiveStyle(53, 'dimensions'),
		left: isLessThanDesktopBase ? 0 : 'auto',
		right: !isLessThanDesktopBase ? 0 : 'auto',
	};
}

function styleProfileIcon(): TStyleImage {
	return {
		height: getResponsiveStyle(48, 'dimensions'),
		width: getResponsiveStyle(48, 'dimensions'),
		marginRight: getResponsiveStyle(16),
		backgroundColor: palettes.grey[10],
	};
}

function styleContainer(isLessThanDesktopBase: boolean): TStyle {
	return {
		marginLeft: isLessThanDesktopBase ? 0 : getResponsiveStyle(24),
	};
}

function styleChevronIcon() {
	return {
		width: 8,
		height: 8,
	};
}

export default ProfileIconWithDropdown;

const styles = StyleSheet.create({
	container: {
		position: 'relative',
	},
	dropdownContainer: {
		height: 'fit-content',
		position: 'absolute',
		zIndex: 9999,
		backgroundColor: 'white',
		borderWidth: 1,
		borderColor: palettes.grey[5],
		shadowOffset: {
			width: 0,
			height: 30,
		},
		shadowRadius: 40,
		shadowColor: 'rgba(124, 136, 155, 0.18)',
		shadowOpacity: 1,
		borderRadius: 6,
	},
	imageContainer: {
		borderRadius: 9999,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	chevronContainerStyles: {
		minWidth: 8,
	},
	chevronIconClosed: {
		transform: [{ rotate: '180deg' }],
		marginTop: -7,
	},
});
