import React, { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useTranslation } from 'react-i18next';
import { View, Platform } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { ChevronDown, DateTimePicker } from '../..';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppSelector } from '../../../redux/store';
import { filterObject } from '../../../utils/filterObject';
import { findFilterVal } from '../../../utils/findFilterVal';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { formatAMPM } from '../../../utils/monthsUtils';
import { objectToDotNotation } from '../../../utils/objectToDotNotation';
import DateComponent from '../../events/dateComponent/DateComponent';
import { WebDateTimePicker } from '../date-time-picker/WebDateTimePicker';
import DropdownSmall from '../dropdown-small/DropdownSmall';
import ToggleLine from './ToggleLine';
type Props = {
	filterObj: TDropdownObject;
	items: TDropdownObject[];
	setFilters: (name: string, value: string) => void;
};
type FInputType = 'dropdown' | 'textField' | 'dateTime';
const ToggleDropdown = ({ filterObj, setFilters, items }: Props) => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState<null | string | number | false | true>(
		null
	);
	const [inputType, setInputType] = useState('');
	const filterValue = useAppSelector(state =>
		findFilterVal(state.filter, filterObj.value)
	);
	useEffect(() => {
		setValue(filterValue);
	}, [filterValue]);
	const typeofInput = (items: any[], value: string): FInputType => {
		if (items.length > 0) {
			return 'dropdown';
		} else if (value.includes('Date')) {
			return 'dateTime';
		}
		return 'textField';
	};
	useEffect(() => {
		setInputType(typeofInput(items, filterObj.value));
	}, []);
	return (
		<>
			{inputType === 'dropdown' && (
				<ClickAwayListener onClickAway={() => setOpen(false)}>
					<View style={{ marginRight: 18 }}>
						<DropdownSmall
							open={open}
							value={value}
							items={items}
							searchable={filterObj.value.includes('countryCode')}
							setOpen={(value: any) => {
								setOpen(value);
							}}
							searchTextInputProps={{
								style: {
									borderColor: primary[0],
									borderWidth: 1,
									borderRadius: getResponsiveStyle(6),
									height: getResponsiveStyle(30),
									padding: getResponsiveStyle(6),
									margin: getResponsiveStyle(6),
									marginTop: 0,
								},
							}}
							searchPlaceholder={`${t('Search')}...`}
							onSelectItem={(item: TDropdownObject) => {
								return setFilters(filterObj.value, item.value);
							}}
							containerStyle={{
								backgroundColor: palettes.grey[11],
								padding: getResponsiveStyle(6),
								borderRadius: 6,
							}}
							dropDownContainerStyle={{
								marginLeft: getResponsiveStyle(-8),
								marginTop: getResponsiveStyle(18),
								paddingLeft: 0,
								paddingRight: 0,
								backgroundColor: palettes.grey[11],
								width: 'max-content',
							}}
							setValue={setValue}
							placeholder={filterObj.label}
							// style={{ marginRight: 18 }}
							dropDownDirection="BOTTOM"
						/>
					</View>
				</ClickAwayListener>
			)}
			{inputType === 'textField' && (
				<ClickAwayListener onClickAway={() => setOpen(false)}>
					<View style={{ marginRight: 18 }}>
						<DropDownPicker
							setValue={setValue}
							value={value}
							items={[]}
							searchable
							dropDownDirection="BOTTOM"
							searchPlaceholder={`${t('Search')}...`}
							ListEmptyComponent={() => <></>}
							zIndex={2000}
							zIndexInverse={2000}
							listMode="FLATLIST"
							showTickIcon={false}
							disableBorderRadius={false}
							disableLocalSearch={true}
							searchTextInputProps={{
								defaultValue: value?.toString(),
								value: value?.toString(),
								// onFocus: () => setIsFocused(true),
								// onBlur: () => setIsFocused(false),
								style: {
									borderColor: primary[0],
									borderWidth: 1,
									borderRadius: getResponsiveStyle(6),
									height: getResponsiveStyle(30),
									padding: getResponsiveStyle(6),
								},
							}}
							placeholder={
								value && value?.length ? value : filterObj.label
							}
							// onChangeSearchText={text => {}}
							open={open}
							setOpen={setOpen}
							scrollViewProps={{
								showsVerticalScrollIndicator: false,
								showsHorizontalScrollIndicator: false,
							}}
							style={[
								{
									flexDirection: 'row',
									alignItems: 'center',
								},
								// style,
							]}
							textStyle={[
								{
									fontSize: getResponsiveStyle(14, 'font'),
									lineHeight: getResponsiveStyle(14, 'font'),
									color: palettes.grey[0],
									marginRight: 4,
								},
							]}
							containerStyle={[
								{
									width: 'max-content',
									backgroundColor: palettes.grey[11],
									padding: getResponsiveStyle(6),
									borderRadius: 6,
								},
							]}
							dropDownContainerStyle={[
								{
									padding: getResponsiveStyle(6),
									borderRadius: 6,
									width: getResponsiveStyle(200),
									marginLeft: getResponsiveStyle(-8),
									marginTop: getResponsiveStyle(18),
									backgroundColor: palettes.grey[11],
									borderColor: palettes.grey[3],
									borderTopLeftRadius: 6,
									borderTopRightRadius: 6,
									shadowOffset: {
										width: 0,
										height: 15,
									},
									shadowRadius: 25,
									shadowColor: 'rgba(124, 136, 155, 0.12)',
									shadowOpacity: 1,
									zIndex: 5999,
								},
								// dropDownContainerStyle,
							]}
							onChangeSearchText={(text: string) => {
								setValue(text);
								return setFilters(filterObj.value, text);
							}}
							listItemContainerStyle={[
								{
									paddingBottom: getResponsiveStyle(6),
									display: 'none',
									// height: getResponsiveStyle(25, 'dimensions')
									borderLeftColor: 'transparent',
								},
								// listItemContainerStyle,
							]}
							listItemLabelStyle={{
								display: 'flex',
								alignItems: 'center',
								lineHeight: 20,
							}}
							selectedItemContainerStyle={{
								borderLeftWidth: 2,
								borderLeftColor: primary[0],
							}}
							selectedItemLabelStyle={{
								color: palettes.dark[0],
							}}
							ArrowDownIconComponent={() => (
								<ChevronDown
									style={{
										width: 8,
										height: 8,
									}}
									onPress={() => setOpen(!open)}
								/>
							)}
							ArrowUpIconComponent={() => (
								<ChevronDown
									style={{
										width: 8,
										height: 8,
										transform: [{ rotate: '180deg' }],
										marginTop: -7,
									}}
									onPress={() => setOpen(!open)}
								/>
							)}
						/>
					</View>
				</ClickAwayListener>
			)}
			{inputType === 'dateTime' && (
				<ClickAwayListener onClickAway={() => setOpen(false)}>
					<View style={{ flexDirection: 'column' }}>
						<ToggleLine
							heading={
								value
									? formatAMPM(new Date(value))
									: t('Start Date')
							}
							setOpen={setOpen}
							open={open}
							viewStyle={{
								width: 'max-content',
								backgroundColor: palettes.grey[11],
								padding: getResponsiveStyle(6),
								borderRadius: 6,
							}}
						/>
						<View>
							<View
								style={{
									position: 'absolute',
									marginTop: getResponsiveStyle(6),
									backgroundColor: palettes.grey[11],
									borderColor: palettes.grey[3],
									borderRadius: 6,
								}}
							>
								{open && Platform.OS === 'web' && (
									<WebDateTimePicker
										{...{
											dateTimeProps: {
												name: 'startDate',
												onChange: e => {
													const date = new Date(
														e.target.value
													).getTime();
													setValue(e.target.value);
													setFilters(
														filterObj.value,
														date
													);
												},
												value: value,
											},
											inputStyles: {
												backgroundColor:
													palettes.grey[11],
												color: palettes.grey[0],
												borderColor: palettes.grey[3],
												borderRadius: 6,
											},
											type: 'datetime-local',
										}}
									/>
								)}
							</View>
						</View>
					</View>
				</ClickAwayListener>
			)}
		</>
	);
};
export default ToggleDropdown;
