import { View, Text, StyleSheet, Pressable } from 'react-native';
import React, { useEffect, useState } from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { ProfileCards } from '../..';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';
import styled from 'rn-css';
import { useLazyQuery } from '@apollo/client';
import { GET_USERS_EVENTS_ATTENDED_COUNT } from '../../../graphql/eventAttendance/queries';
import InviteLink from '../../common/invite-link/InviteLink';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { setIsInviteAllGuest } from '../../../redux/uiSlice';
import { useDispatch } from 'react-redux';

const StyledText = styled.Text`
	padding-bottom: 7;
	color: blue;
	margin-right: ${getResponsiveStyle(16)}px;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		border-bottom: 1px solid blue;
		color: blue;
	}
`;

const PeopleScrollSection = ({
	invitedPeople,
	handleEditEvent,
	isShowInviteLink,
	isShowViewProfileBtn,
}: {
	isShowInviteLink: boolean;
	isShowViewProfileBtn?: boolean;
	handleEditEvent: (text: string) => void;
	invitedPeople: InvitedPeople[];
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isMobileView } = useIsSpecificViewportWidth();
	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));
	const [invitedPeopleIds, setInvitedPeopleIds] = useState<string[]>([]);
	const [eventAttendedCount, setEventAttendedCount] = useState<
		EventAttendedCount[]
	>([]);

	const [getUserAttendanceCount] = useLazyQuery(
		GET_USERS_EVENTS_ATTENDED_COUNT,
		{
			onCompleted: data =>
				setEventAttendedCount(data?.getUserAttendanceCount?.data?.data),
		}
	);
	useEffect(() => {
		const ids = invitedPeople?.map(person => person._id);
		setInvitedPeopleIds(ids);
	}, [invitedPeople]);

	useEffect(() => {
		invitedPeopleIds.length &&
			getUserAttendanceCount({
				variables: {
					users: invitedPeopleIds,
				},
			});
	}, [invitedPeopleIds]);

	const isConsumer = loggedInUserDetails?.typeOfAccount === 'CONSUMER';
	return (
		<View style={[Styles.container, styleContainer()]}>
			<View style={[Styles.headerContainer, Styles.spacing]}>
				<Text style={[Styles.title, mobileTitle(isMobileView)]}>
					{t('Invited Guest')}
				</Text>
				{isShowInviteLink && (
					<InviteLink
						onClick={() => {
							dispatch(setIsInviteAllGuest(false));
							handleEditEvent('EventInvite');
						}}
					/>
					// <Pressable onPress={() => handleEditEvent('EventInvite')}>
					// 	<StyledText>Invite Guest</StyledText>
					// </Pressable>
				)}
			</View>
			<View style={Styles.sliderContainer}>
				<ProfileCards
					isHost={false}
					hasEventAttendedOnly
					hasActionBtn={!isConsumer}
					hasRating={false}
					isInvited={true}
					showGender={true}
					data={invitedPeople}
					eventsAttendedCount={eventAttendedCount}
					isShowViewProfileBtn={isShowViewProfileBtn}
				/>
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		// width: '100%',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	sliderContainer: {
		width: '100%',
		marginTop: getResponsiveStyle(29),
	},
	hoverText: {
		// marginTop: 20,
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
	spacing: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});
export default PeopleScrollSection;

function styleContainer() {
	return {
		// marginTop: getResponsiveStyle(55),
		// marginBottom: getResponsiveStyle(41),
	};
}
function mobileTitle(isMobileView: boolean) {
	return isMobileView && { fontSize: getResponsiveStyle(16, 'font') };
}
