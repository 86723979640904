import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getWidth } from '../utils/getWidth';

type TInitialState = {
	product: object;
};

const initialState: TInitialState = {
	product: {},
};

const eventProductDetailSlice = createSlice({
	name: 'eventProductDetail',
	initialState,
	reducers: {
		setEventProduct(state, action: PayloadAction<object>) {
			state.product = action.payload;
		},
	},
});

export const { setEventProduct } = eventProductDetailSlice.actions;

export default eventProductDetailSlice.reducer;
