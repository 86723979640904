import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';
import styled from 'rn-css';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const StyledText = styled.Text`
	padding-bottom: 7;
	color: blue;
	margin-right: ${getResponsiveStyle(16)}px;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		border-bottom: 1px solid blue;
		color: blue;
	}
`;

const InviteLink = ({ onClick }: { onClick: (text?: string) => void }) => {
	const { t } = useTranslation();
	return (
		<Pressable onPress={() => onClick()}>
			<StyledText>{t('Invite Guest')}</StyledText>
		</Pressable>
	);
};

export default InviteLink;
