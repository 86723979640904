import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	uri: string;
};

export const ProfileImage = ({ uri }: Props) => {
	const primary = usePrimaryColors();
	return (
		<View style={[styles.container, { borderColor: primary[0] }]}>
			<Image
				source={{
					uri: !!uri ? uri : 'https://via.placeholder.com/500',
				}}
				style={styles.image}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	image: {
		width: getResponsiveStyle(40),
		height: getResponsiveStyle(40),
		borderRadius: 9999,
		borderWidth: 2,
		borderColor: 'transparent',
	},
	container: {
		borderRadius: 9999,
		borderWidth: 1,
		marginBottom: 5,
	},
});
