import { gql } from '@apollo/client';

export const GET_USERS_EVENTS_ATTENDED_COUNT = gql`
	query getUserAttendanceCount($users: [ID!]) {
		getUserAttendanceCount(users: $users) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						data {
							... on GetUserAttendanceCountResponse {
								eventsAttendedCount
								user
							}
						}
					}
				}
			}
		}
	}
`;
