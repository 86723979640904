import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	AuthTemplate,
	Button,
	DayOfWeekSelector,
	HorizontalDivider,
	SelectedScheduals,
	TimeOfWeekSelector,
} from '../components';
import { useNextStepScreen } from '../hooks/useNextStepScreen';

import { useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const AvailabilityScreen = () => {
	const { t } = useTranslation();
	const DAYS_OF_WEEK = [
		t('All'),
		t('Mon'),
		t('Tue'),
		t('Wed'),
		t('Thu'),
		t('Fri'),
		t('Sat'),
		t('Sun'),
	];
	const [selectedDay, setSelectedDay] = useState<string>(DAYS_OF_WEEK[0]);

	const signupSidebarArray = useAppSelector(
		state => state.ui.SignupSidebarArray
	);
	const { goToNextStepScreen } = useNextStepScreen(signupSidebarArray);
	const onPressDay = (day: string) => {
		setSelectedDay(day);
	};

	return (
		<AuthTemplate
			heading={t('Availability')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<DayOfWeekSelector
				daysOfWeek={DAYS_OF_WEEK}
				onPressDay={onPressDay}
				selectedDay={selectedDay}
			/>
			<TimeOfWeekSelector />
			<Button
				title={t('Done')}
				size="lg"
				variant="primary"
				onPress={goToNextStepScreen}
			/>
			<HorizontalDivider paddingY={32} />
			<SelectedScheduals />
		</AuthTemplate>
	);
};

export default AvailabilityScreen;
