import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { useAppSelector } from '../../../redux/store';
import ChatInput from '../../live-event/product-section/event-chat/ChatInput';
import ContactMessage from '../../live-event/product-section/event-chat/ContactMessage';
import UserMessage from '../../live-event/product-section/event-chat/UserMessage';
type Props = {
	scrollViewRef: any;
	setMessage: (e: string) => void;
	handleMessageSend: (e: any) => any;
	messages: any[];
};
const ChatChild = ({
	scrollViewRef,
	setMessage,
	handleMessageSend,
	messages,
}: Props) => {
	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	return (
		<>
			<ScrollView
				// style={styles.chatBox}
				showsHorizontalScrollIndicator={false}
				ref={scrollViewRef}
				onContentSizeChange={() =>
					scrollViewRef.current.scrollToEnd({
						animated: false,
					})
				}
			>
				{messages && messages?.length ? (
					messages.map((message: TODO) => (
						<>
							{message?.sender === loggedInUserDetails?._id ? (
								<UserMessage message={message} />
							) : (
								<ContactMessage message={message} />
							)}
						</>
					))
				) : (
					<></>
				)}
			</ScrollView>

			<ChatInput
				handleChange={message => setMessage(message)}
				onMessageSend={handleMessageSend}
				showUpload={false}
				handleTypingFocusIn={() => null}
				handleTypingFocusOut={() => null}
			/>
		</>
	);
};
export default ChatChild;
