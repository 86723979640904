import { useLazyQuery } from '@apollo/client';
import { GET_FOLLOWING_STATUS } from '../../graphql/status/queries';

const useGetStatus = () => {
	const [getUserStatus, { data, error, loading }] = useLazyQuery(
		GET_FOLLOWING_STATUS,
		{
			fetchPolicy: 'no-cache',
		}
	);

	const executeFollowingStatusQuery = ({ skipDoc }: { skipDoc: number }) => {
		getUserStatus({
			variables: {
				skipDoc,
			},
		});
	};
	return { executeFollowingStatusQuery, data, error, loading };
};

export default useGetStatus;
