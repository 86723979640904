import { gql } from '@apollo/client';

export const CREATE_EVENT_INVITATION = gql`
	mutation createInvitation($args: EventInvitationInputs) {
		createInvitation(args: $args) {
			... on Response {
				success
				statusCode
				message
				data {
					__typename
				}
			}
		}
	}
`;

export const ACCEPT_OR_REJECT_INVITATION = gql`
	mutation acceptRejectInvitation($args: AcceptInvitationInputs) {
		acceptRejectInvitation(args: $args) {
			... on Response {
				success
				statusCode
				message
				data {
					__typename
				}
			}
		}
	}
`;
