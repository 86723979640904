import { useLazyQuery } from '@apollo/client';
import { GET_BRAND_STATS } from '../../graphql/brands/queries';
// import { TReturnUseApiHookLazyQuery } from '../../types/exportedTypes';

// type TGetSingleBrandArgs = {
// 	_id: string;
// };
// type TReturn = {
// 	getSingleBrand: (_id: string) => void;
// } & TReturnUseApiHookLazyQuery<TGetSingleBrandResponse, TGetSingleBrandArgs>;

export const useGetBrandStats = (params: any) => {
	const [executeQuery, states] = useLazyQuery(GET_BRAND_STATS, {
		onCompleted: params?.onCompleted,
	});

	const getBrandStats = (_ids: string[]) => {
		executeQuery({
			variables: {
				_ids,
			},
		});
	};

	return { getBrandStats, ...states };
};
