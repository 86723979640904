import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	heading: string;
	children: JSX.Element;
};

const ProfileEditInformationTemplate = ({ children, heading }: Props) => {
	const { t } = useTranslation();
	const { isMobileView } = useIsSpecificViewportWidth();
	return (
		<View>
			<View style={styles.headerContainer}>
				<Text style={[styles.header, mobileHeader(isMobileView)]}>
					{heading}
				</Text>
			</View>
			{children}
			{/* <Button
				title={t('Update')}
				size="lg"
				variant="primary"
				outerContainerProps={{
					style: styles.button,
				}}
			/> */}
		</View>
	);
};

const styles = StyleSheet.create({
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: getResponsiveStyle(35),
	},
	header: {
		fontWeight: 'bold',
		fontSize: getResponsiveStyle(24),
		color: palettes.dark[0],
	},
	hoverText: {
		// marginTop: 20,
		fontSize: getResponsiveStyle(14),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
	button: {
		marginTop: getResponsiveStyle(56),
	},
});
function mobileHeader(isMobileView: boolean) {
	return (
		isMobileView && {
			fontSize: getResponsiveStyle(18),
		}
	);
}

export default ProfileEditInformationTemplate;
