import { breakpoints, palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { StyleSheet } from 'react-native';
import { TStyle } from '../../../types/TStyle';

export const styles = StyleSheet.create({
	tabContainer: {
		marginBottom: 0,
		borderBottomWidth: 1,
		borderBottomColor: palettes.grey[5],
		width: '100vw',
	},
	container: {
		width: '100vw',
	},
	rightContainer: {
		flexDirection: 'column',
		gap: getResponsiveStyle(26),
		alignItems: 'flex-end',
	},
	tab: {
		textAlign: 'center',
		userSelect: 'none',
		paddingBottom: 12,
	},
	tabNotSelected: {
		color: palettes.grey[0],
		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		fontWeight: '700',
		paddingBottom: 12,
		borderBottomWidth: 1,
	},
	walletContainer: {
		flexDirection: 'column',
		gap: getResponsiveStyle(2),
		alignItems: 'flex-end',
	},
	walletValue: {
		fontSize: getResponsiveStyle(14, 'font'),
		// color: palettes.dark[0],
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
	},
	walletText: {
		fontSize: getResponsiveStyle(16, 'font'),
		color: palettes.grey[0],
		lineHeight: getResponsiveStyle(20, 'font'),
		fontWeight: 'normal',
	},
	walletButtons: {
		flex: 1,
	},
});

export const styleTabContainer = () => {
	return {
		paddingLeft: getResponsiveStyle(40),
		paddingRight: getResponsiveStyle(40),
	};
};

export const styleCompanyStatsContainer = (): TStyle => {
	return { marginBottom: getResponsiveStyle(23) };
};

export const styleCompanyDetailsContainer = (): TStyle => {
	return {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: getResponsiveStyle(22),
	};
};

export const styleGoBackButton = (): TStyle => {
	return { marginBottom: getResponsiveStyle(32) };
};

export const styleContainer = (width: number): TStyle => {
	return {
		paddingTop: getResponsiveStyle(32),
		marginLeft: width >= breakpoints.desktopBase ? -40 : -16,
	};
};

export const stylePaddings = (width: number) => {
	return {
		paddingLeft: width >= breakpoints.desktopBase ? 40 : 16,
		paddingRight: width >= breakpoints.desktopBase ? 40 : 16,
	};
};
export const styleViewContainer = () => {
	return {
		maxHeight: getResponsiveStyle(45),
		marginTop: getResponsiveStyle(22),
		marginLeft: 10,
		width: '100vw',
		maxWidth: getResponsiveStyle(464, 'dimensions'),
		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 1,
	} as TStyle;
};

export const styleTab = (
	primary: string[],
	selectedTab?: string,
	tab?: string
) => {
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(40),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		selectedTab === tab?.value ? styles.tabSelected : styles.tabNotSelected,
		selectedTab === tab?.value && { borderBottomColor: primary[0] },
	];
};
