import React, { useEffect, useState } from 'react';
import {
	ActivityIndicator,
	StyleSheet,
	Text,
	useWindowDimensions,
	View,
} from 'react-native';
import useGetComments from '../../../api/comment/queries';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
// import { Action, ActionLink as ViewAllLink } from '../../..';
import PostOrThreadComment from './post-or-thread/PostOrThreadComment';
// import { t, use } from 'i18next';
// import ActionLink from '../../common/styled-components/ActionLink';
// import ShareUpdateInput from '../ShareUpdateInput';
import Loader from '../../common/loader/Loader';
import { useTranslation } from 'react-i18next';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

type Props = {
	_id: string;
	user: TGetAllUsersData;
	file?: string;
	status?: string;
	level?: number;
	createdAt: string;
	refetchComment: boolean;
};

export const CommnentBlockThread = ({ level = 1, ...status }: Props) => {
	delete status.status;
	// delete status.user;
	const { width } = useWindowDimensions();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { t } = useTranslation();

	const { executeFollowingCommentsQuery, data, loading } = useGetComments();

	const [isThread, setIsThread] = useState(false);

	useEffect(() => {
		executeFollowingCommentsQuery({
			parentId: status._id,
		});
	}, [status.refetchComment]);

	// useEffect(() => {
	// 	executeFollowingCommentsQuery({
	// 		parentId: status._id,
	// 	});
	// }, [data]);

	const onSubmit = () => {
		executeFollowingCommentsQuery({
			parentId: status._id,
		});
	};

	return (
		<View style={styleContainer(width, level, isLessThanDesktopBase)}>
			{loading && (
				<Loader
					size="small"
					containerStyles={{
						margin: getResponsiveStyle(20),
					}}
				/>
			)}
			{!data?.getComments?.data?.data.length && !loading && (
				<Text style={styles.noComment}>{t('No Comments yet')}</Text>
			)}
			{data?.getComments?.data?.data?.map((data: any) => (
				<PostOrThreadComment
					showCommentBox={() => null}
					status={data.content}
					{...status}
					threadId={data._id}
					createdAt={data.createdAt}
					user={data.commentBy as TGetAllUsersData}
					showComment={() => setIsThread(!isThread)}
					inThread
					onSubmit={onSubmit}
				/>
			))}
			{/* <ActionLink
				style={{ width: '12%', marginBottom: 10, marginTop: 10 }}
			>
				{t('View all reply')}
			</ActionLink> */}
			{/* <ShareUpdateInput
				commentParentId={status._id}
				isCommentInput={true}
				inThread={isThread}
				onSubmit={onSubmit}
			/> */}
		</View>
	);
};

const styleContainer = (
	width: number,
	level: number,
	isLessThanDesktopBase: boolean
): TStyle => {
	return {
		paddingTop: getResponsiveStyle(25),
		paddingLeft: isLessThanDesktopBase ? 20 : 35 * level,
	};
};

const styles = StyleSheet.create({
	noComment: {
		textAlign: 'center',
		marginBottom: 20,
		fontWeight: '700',
		color: palettes.dark[0],
	},
});
