import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATIONS = gql`
	query getAllNotification {
		getAllNotifications(
			populate: [
				{ path: "sentBy", model: "User" }
				{ path: "event", model: "Event" }
				{ path: "eventId", model: "Event" }
				{
					path: "order"
					model: "Order"
					populate: [
						{
							path: "items"
							populate: [
								{
									path: "product"
									model: "Product"
									populate: [
										{ path: "brand", model: "Brand" }
									]
								}
								{
									path: "variant"
									model: "Variant"
									populate: [
										{
											path: "associatedProduct"
											model: "Product"
										}
									]
								}
								{
									path: "starterKit"
									model: "StarterKit"
									populate: [
										{ path: "brand", model: "Brand" }
										{ path: "variants", model: "Variant" }
									]
								}
							]
						}
					]
				}
			]
		) {
			success
			data {
				... on All {
					data {
						... on Notification {
							_id
							message
							notificationType
							subscriptionId
							eventId {
								_id
								name
								description
								typeOfEvent
								startingEventDateTime
								isHostApplicationAccepted
								eventStreamTimeline {
									startedAt
									endedAt
								}
								affiliatedSeller {
									_id
								}
								affiliatedBrands {
									_id
								}
								affiliatedHosts {
									_id
								}
								invitedPeople {
									_id
								}
							}
							createdAt
							read
							eventHostingId
							invitation
							newLocation {
								address1
								city
								zipCode
								countryCode
								countryLabel
								address2
							}
							event {
								_id
								name
								description
								typeOfEvent
								startingEventDateTime
								isHostApplicationAccepted
								eventStreamTimeline {
									startedAt
									endedAt
								}
								affiliatedSeller {
									_id
								}
								affiliatedBrands {
									_id
								}
								affiliatedHosts {
									_id
								}
								invitedPeople {
									_id
								}
							}
							eventType
							sentBy {
								_id
								username
								personalInformation {
									profileImageLink
									firstName
									address {
										city
										zipCode
										address1
										countryLabel
									}
								}
							}
							user {
								_id
							}
							order {
								_id
								associatedUser
								orderId
								paidWith
								items {
									optionValue
									variant {
										_id
										variator
										value
										option {
											name
										}
										associatedProduct {
											_id
											name
											description
											shortDescription
										}
									}
									product {
										_id
										name
										description

										brand {
											name
										}
										images {
											name
											alt
											src
										}
									}
									brand
									itemType
									starterKit {
										_id
										name
										description
										price
										# rating
										brand {
											name
										}
										variants {
											_id

											image {
												alt
												src
												category
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
