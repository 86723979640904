import {
	StyleSheet,
	Text,
	View,
	ActivityIndicator,
	ListRenderItem,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import BankComponent from './BankComponent';
import ModalComponent from '../../common/modal/ModalComponent';
import AddBankForm from './AddBankForm';
import { useAppSelector, useAppDispatch } from '../../../redux/store';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CONNECT_ACCOUNT } from '../../../graphql/stripe/queries';
import { Loader } from '../../../components';
import {
	CREATE_CONNECT_ACCOUNT,
	ONBOARD_ACCOUNT,
} from '../../../graphql/stripe/mutations';
import { getCountryValueFromLabel } from '../../../utils/getCountryValueFromLabel';
import {
	setConnectAccountId,
	setCreateBankModalState,
} from '../../../redux/bankSlice';
import { useTranslation } from 'react-i18next';
import AddCardBankPlaceholder from './AddCardBankPlaceholder';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import DraggableScrollView from '../../common/DraggableScrollView';
import { TStyle } from '../../../types/TStyle';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { loadStripe } from '@stripe/stripe-js';
import { stripe_key } from '../../../utils/stripeUtil';

const BankSection = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const createBankModalState = useAppSelector(
		state => state.userBanks.createBankModalState
	);

	const defaultLoading = useAppSelector(
		state => state.userBanks.changingDefault
	);
	const banks = useAppSelector(state => state.userBanks.UserBanks);

	const handleAddBank = () => {};
	const loggedInUserdetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);
	// const handleEditBank = () => {};

	const createConnectToken = async () => {
		const stripe = await loadStripe(stripe_key);

		const account = await stripe?.createToken('account', {
			business_type: 'individual',
			individual: {
				address: {
					city: loggedInUserdetails?.personalInformation.address.city,
					line1: loggedInUserdetails?.personalInformation.address
						.address1,
				},
				first_name: loggedInUserdetails?.personalInformation.firstName,
				email: loggedInUserdetails?.email,
			},
			tos_shown_and_accepted: true,
		});

		return account;
	};

	const country = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address
				.countryLabel
	);
	const { data: getConnetData } = useQuery(GET_CONNECT_ACCOUNT);
	const [
		createConnectAccount,
		{ data: createconnectdata, error: connectError },
	] = useMutation(CREATE_CONNECT_ACCOUNT);
	const [onBoardAccount] = useMutation(ONBOARD_ACCOUNT);
	const [onboardingFinish, setOnboardingFinish] = useState<boolean | string>(
		''
	);
	const [connectAccount, setConnectAccount] = useState('');

	const { setAlert } = useSetAlert();

	const onboardingFlow = async () => {
		if (
			getConnetData?.getConnectAccount === null ||
			getConnetData?.getConnectAccount?.data === null
		) {
			// if (!connectID) {
			setConnectAccount('No Connect account');
			const account = await createConnectToken();
			createConnectAccount({
				variables: {
					args: {
						country:
							getCountryValueFromLabel(country)?.toUpperCase(),
						token: account?.token?.id,
					},
				},
				awaitRefetchQueries: true,
				refetchQueries: [{ query: GET_CONNECT_ACCOUNT }],
				onCompleted: async data => {
					if (
						data &&
						data.createConnectAccount &&
						data.createConnectAccount.data
					) {
						await dispatch(
							setConnectAccountId(
								data.createConnectAccount.data.id
							)
						);
						await setConnectAccount('Account Created');
						await onBoardAccount({
							variables: {
								args: {
									account:
										data?.createConnectAccount?.data?.id,
									type: data?.createConnectAccount?.data
										?.type,
								},
							},
							awaitRefetchQueries: true,
							refetchQueries: [{ query: GET_CONNECT_ACCOUNT }],
							onCompleted: () => {
								setOnboardingFinish(true);
								dispatch(setCreateBankModalState());
							},
						});
					}
					// else {

					// }
				},
				onError: error => setAlert(error.message, 'danger'),
			});
		} else if (getConnetData?.getConnectAccount?.data?.flag === false) {
			setOnboardingFinish(false);
			await onBoardAccount({
				variables: {
					args: {
						account:
							getConnetData?.getConnectAccount?.data
								?.connectAccountId,
						type: getConnetData?.getConnectAccount?.data?.type,
					},
				},
				awaitRefetchQueries: true,
				refetchQueries: [{ query: GET_CONNECT_ACCOUNT }],
				onCompleted: data => data,
			});

			setOnboardingFinish(true);
		} else {
			setOnboardingFinish(true);
		}
	};

	useEffect(() => {
		if (connectError) {
			setAlert(connectError.message, 'normal');
			dispatch(setCreateBankModalState());
		}
	}, [connectError]);

	const renderItem: ListRenderItem<TBank> = ({ item, index }) => {
		if (item.default) {
			return (
				<BankComponent
					bankId={item.bankId}
					hide="yes"
					key={item.bankId}
					name={item.bank_name}
					defaultBank
					city={item.country}
					number={item.account_number}
				/>
			);
		} else {
			return (
				<BankComponent
					bankId={item.bankId}
					hide="no"
					key={item.bankId}
					name={item.bank_name}
					city={item.country}
					number={item.account_number}
				/>
			);
		}
	};
	const primary = usePrimaryColors();
	if (defaultLoading) {
		return (
			<View style={styles.indicatorContainer}>
				<Text style={styles.headerText}>{t('My Bank Accounts')}</Text>
				<ActivityIndicator size="large" />
			</View>
		);
	}
	return (
		<View style={styles.container}>
			<View style={styles.headerContainer}>
				<Text style={styles.headerText}>{t('My Bank Accounts')}</Text>
				{banks.length > 0 && (
					<Text
						style={[
							{
								color: primary[0],
								textDecorationLine: 'underline',
							},
							styles.addText,
						]}
						onPress={() => {
							onboardingFlow();
							dispatch(setCreateBankModalState());
						}}
					>
						{'+' + t('Add New Bank Account')}
					</Text>
				)}
			</View>

			{/* <View style={styles.contentContainer}> */}
			{banks.length ? (
				<DraggableScrollView
					data={banks}
					horizontal
					showsHorizontalScrollIndicator={false}
					renderItem={renderItem as any}
					style={styleDraggableScroll()}
				/>
			) : (
				<AddCardBankPlaceholder
					text={t('Bank Account')}
					onboard={onboardingFlow}
					setModalState={setCreateBankModalState}
				/>
			)}
			{/* {banks_.length > 0 ? (
					banks_.map((bank: any) => {
						if (bank.default) {
							return (
								<BankComponent
									bankId={bank.bankId}
									hide="yes"
									key={bank.bankId}
									name={bank.bank_name}
									defaultBank
									city={bank.country}
									number={bank.account_number}
								/>
							);
						} else {
							return (
								<BankComponent
									bankId={bank.bankId}
									hide="no"
									key={bank.bankId}
									name={bank.bank_name}
									city={bank.country}
									number={bank.account_number}
								/>
							);
						}
					})
				) : (
					<NotAvailableMessage message={t('No bank account saved')} />
				)}
				{banks_.length < banks.length && (
					<AddCardBankPlaceholder
						text="Bank Account"
						setModalState={setCreateBankModalState}
						onboard={onboardingFlow}
					/>
				)} */}
			{/* </View> */}

			<ModalComponent
				type="sidebar"
				header="Add Bank Account"
				openState={createBankModalState}
				onClose={() => dispatch(setCreateBankModalState())}
			>
				{connectAccount === 'No Connect account' ? (
					<View>
						<View>
							{t(
								'To add bank accounts for receiving payouts,your account has to be enabled, please wait...'
							)}
						</View>
						<Loader />
					</View>
				) : !onboardingFinish ? (
					<View>
						<View>
							{t(
								'Further verification is need, Onboarding in progress...'
							)}
						</View>
						<Loader />
					</View>
				) : getConnetData?.getConnectAccount?.data?.currently_due
						?.length > 1 ||
				  (getConnetData?.getConnectAccount?.data?.currently_due
						?.length === 1 &&
						!getConnetData?.getConnectAccount?.data?.currently_due?.includes(
							'external_account'
						)) ? (
					<View>
						<a
							href={
								getConnetData &&
								getConnetData.getConnectAccount?.data?.lastUrl
							}
						>
							{t('Click on this to provide verification details')}
						</a>
					</View>
				) : (
					<AddBankForm onSubmit={handleAddBank} />
				)}
			</ModalComponent>
		</View>
	);
};

const styles = StyleSheet.create({
	indicatorContainer: {
		height: '500px',
	},
	addText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		// color: palettes.grey[1],
		cursor: 'pointer',
	},
	container: {
		width: '100%',
		marginTop: 48,
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(24, 'font'),
	},
	contentContainer: {
		flexDirection: 'row',
		gap: getResponsiveStyle(24),
		flexWrap: 'wrap',
		alignItems: 'center',
		marginTop: getResponsiveStyle(24),
	},
	noBankText: {
		fontWeight: '400',
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(25, 'font'),
		color: palettes.grey[0],
	},
	container_: {
		flexDirection: 'row',
		zIndex: 1,
		position: 'relative',
	},
});

const styleDraggableScroll = (): TStyle => {
	return [
		styles.container_,
		{
			width: '100%',
			paddingBottom: getResponsiveStyle(20),
		},
	];
};

export default BankSection;
