import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import { Action, ActionLink, DropDownPickerSmall } from '..';
import { palettes } from '../../config';
import { useIsSpecificViewportWidth } from '../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../hooks/usePrimaryColors';
import { TStyleInputOrText } from '../../types/TStyle';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import ToggleButton from './toggle-button/Toggle';
import { setSort } from '../../redux/sortSlice';
import ClickAwayListener from 'react-click-away-listener';

type TSize = 'small' | 'medium' | 'large';

type Props = {
	heading: string;
	createLinkLabel?: string;
	hasViewAllLabel?: boolean;
	hasFilters?: boolean;
	filterItem?: TDropdownObject[];
	sortItem?: TDropdownObject[];
	hasSeeAllLabel?: boolean;
	hasBorderBottom?: boolean;
	containerStyles?: TStyleInputOrText;
	size?: TSize;
	stylesObject?: {
		headingStyles?: TStyleInputOrText;
	};
	onPressViewAll?: () => void;
	onPressSeeAll?: () => void;
};

const CreateLink = styled(Action)<{ primary: string }>`
	color: ${({ primary }) => primary};
	&:hover {
		border-bottom: 1px solid ${({ primary }) => primary};
	}
`;

const SectionHeading = ({
	heading,
	createLinkLabel,
	hasViewAllLabel = false,
	hasFilters = false,
	size = 'medium',
	filterItem,
	sortItem,
	hasSeeAllLabel = false,
	hasBorderBottom = false,
	containerStyles,
	stylesObject,
	onPressViewAll,
	onPressSeeAll,
}: Props) => {
	const { t } = useTranslation();
	const headingStyles = stylesObject?.headingStyles;
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState<null | string | number | false | true>(
		null
	);
	const sort = useAppSelector(state => state.sort);
	const dispatch = useAppDispatch();

	const primary = usePrimaryColors();
	return (
		<Text
			style={[
				styles.textContainer,
				styleTextContainer(size),
				containerStyles,
			]}
		>
			<Text
				style={[
					styleHeading(size, hasBorderBottom, primary),
					headingStyles,
				]}
			>
				{heading}
			</Text>
			{createLinkLabel && (
				<CreateLink primary={primary[0]}>
					+ {createLinkLabel}
				</CreateLink>
			)}
			{hasViewAllLabel && !hasSeeAllLabel && (
				<ActionLink onPress={onPressViewAll}>
					{t('View All')}
				</ActionLink>
			)}

			{hasSeeAllLabel && !hasViewAllLabel && (
				<ActionLink onPress={onPressSeeAll}>{t('See All')}</ActionLink>
			)}
			{hasFilters && (
				<View style={styles.filterContainer}>
					{filterItem && (
						<ToggleButton
							heading={t('All Filters')}
							filters={filterItem}
						/>
					)}

					{sortItem && (
						<DropDownPickerSmall
							open={open}
							value={value}
							items={sortItem}
							setOpen={(value: any) => {
								setOpen(value);
							}}
							setValue={setValue}
							onSelectItem={(item: {
								value: any;
								label: any;
							}) => {
								item &&
									dispatch(
										setSort({
											value: item.label,
											type: item.value,
											local: item.local,
										})
									);
							}}
							dropDownDirection="BOTTOM"
							dropDownContainerStyle={{
								display: 'flex',
								flexDirection: 'row-reverse',
								right: 0,
							}}
							listItemContainerStyle={{
								width: 'max-content',
								// marginTop: getResponsiveStyle(18),
								// paddingLeft: 0,
								// paddingRight: 0,
								// backgroundColor: palettes.grey[11],
							}}
							placeholderStyle={[
								sort.type
									? {
											color: primary[0],
									  }
									: {},
							]}
							placeholder={t('Sort By')}
							style={{ marginRight: 18 }}
						/>
					)}
				</View>
			)}
		</Text>
	);
};

const styles = StyleSheet.create({
	textContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	heading: {
		fontWeight: '700',
		color: palettes.dark[0],
	},
	action: {
		fontWeight: '600',
		textAlign: 'right',
		borderBottomWidth: 1,
		paddingBottom: 2,
	},
	viewAll: {
		color: palettes.grey[0],
		borderBottomColor: palettes.grey[0],
		borderBottomWidth: 1,
	},
	filterContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		zIndex: 999,
	},
});

const styleHeading = (
	size: TSize,
	hasBorderBottom: boolean,
	primary: string[]
): TStyleInputOrText => {
	return [
		styles.heading,
		size === 'small'
			? styleHeadingSmall()
			: size === 'medium'
			? styleHeadingMedium()
			: size === 'large' && styleHeadingLarge(),
		hasBorderBottom && styleHeadingBorderBottom(primary),
	];
};

export default SectionHeading;

const styleHeadingBorderBottom = (primary: string[]) => {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		paddingBottom: 9,
		borderBottomWidth: 1,
		borderBottomColor: primary[0],
		fontWeight: '600',
	};
};

const styleHeadingLarge = () => {
	return {
		fontSize: getResponsiveStyle(24, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
};

const styleHeadingMedium = () => {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
};

const styleHeadingSmall = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleTextContainer = (size: TSize) => {
	return { marginBottom: getResponsiveStyle(size === 'medium' ? 17 : 28) };
};
