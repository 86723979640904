import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { Button, Loader } from '../..';
import { palettes } from '../../../config';
import { DELETE_NOTIFICATION } from '../../../graphql/notification/mutation';
import { GET_ALL_NOTIFICATIONS } from '../../../graphql/notification/queries';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import RatingModal from '../rating-modal/RatingModal';
import { TypeOfNotification } from '../../../types/notificationTypes';

const getSecondsFromDate = 1000;
const getMinuteFromDate = 60000;
const getHourFromDate = 3600000;
const getDayFromDate = 86400000;
const aDay = 24;
const aMinute = 60;

const StyledClearText = styled(Text)`
	font-size: ${getResponsiveStyle(11)}px;
	line-height: ${getResponsiveStyle(11)}px;
	color: ${palettes.red[0]};
	cursor: pointer;
	padding-bottom: 2;
	border-bottom: 1px solid ${palettes.red[0]};
`;

export const OrderReviewNotification = ({
	notification,
	hovered,
	removeFromNotification,
}: {
	notification?: NotificationType;
	hovered?: boolean;
	removeFromNotification: (id: string) => void;
}) => {
	const [isRatingModal, setIsRatingModal] = useState(false);
	const ref = useRef(null);

	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const isHovered = useHover(ref);
	const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
		onCompleted: data => {
			// if (data && data.deleteNotification.success) {
			// 	setAlert(t('Notification successfully deleted'), 'normal');
			// } else {
			// 	setAlert(t('Could not delete notification'), 'danger');
			// }
		},
		onError: () => {
			setAlert(t('Could not delete notification'), 'danger');
		},
	});

	const getRatingEntity = (items: [Item_]) => {
		return items?.map(product => ({
			type:
				product?.starterKit?.name ||
				`${product?.variant?.associatedProduct?.name} ${product?.variant?.value} ` +
					`${
						product?.variant?.option?.name?.match(/default/i)
							? ''
							: `${product?.variant?.option?.name} ${product?.optionValue}`
					}`,
			_id:
				product?.starterKit?._id ||
				product?.variant?.associatedProduct?._id,
		}));
	};

	return (
		<>
			<RatingModal
				onClose={() => setIsRatingModal(false)}
				entities={getRatingEntity(notification?.order?.items)}
				ratingType={'PRODUCT'}
				openState={isRatingModal}
				deleteNotification={() => {
					removeFromNotification(notification?._id ?? '');
					deleteNotification({
						variables: {
							args: {
								id: notification?._id,
							},
						},
					});
				}}
			/>

			<View style={[styles.container, styleContainer()]} ref={ref}>
				<>
					<View style={{ flex: 1 }}>
						<View
							style={[
								styles.detailsContainer,
								styleDetailsContainer(),
							]}
						>
							{isHovered && (
								<TouchableOpacity
									onPress={() => {
										removeFromNotification(
											notification?._id ?? ''
										);
										deleteNotification({
											variables: {
												args: {
													id: notification?._id,
												},
											},
										});
									}}
								>
									<StyledClearText>
										{t('Clear')}
									</StyledClearText>
								</TouchableOpacity>
							)}

							{/* {isHovered && <StyledClearText>Clear</StyledClearText>} */}
						</View>
						<Text
							style={[
								styles.invitationText,
								styleInvitationText(),
							]}
						>
							{
								// (notification?.notificationType === 'ORDER_PURCHASE_NOTIFICATION') &&
								t(
									'It has been 3 days you made this order. How satisfied are you with the following products?'
								)
							}
							{/* {notification?.message} */}
						</Text>
						{Math.floor(
							(Date.now() - Number(notification?.createdAt)) /
								getSecondsFromDate
						) < aMinute ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notification?.createdAt)) /
										getSecondsFromDate
								)}
								{''}s {t('ago')}
							</Text>
						) : Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getMinuteFromDate
						  ) < aMinute ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notification?.createdAt)) /
										getMinuteFromDate
								)}
								{''}m {t('ago')}
							</Text>
						) : Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getHourFromDate
						  ) < aDay ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notification?.createdAt)) /
										getHourFromDate
								)}
								{''}h {t('ago')}
							</Text>
						) : (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notification?.createdAt)) /
										getDayFromDate
								)}{' '}
								{t('days ago')}
							</Text>
						)}

						{/* <Text style={[styles.eventDate]}>2h ago</Text> */}
						<View style={styles.actionContainer}>
							<Button
								title={`${t('Give your ratings')}`}
								variant="outline"
								size="sm"
								onPress={() => setIsRatingModal(true)}
								outerContainerProps={{
									style: styles.acceptButton,
								}}
							/>
						</View>
					</View>
				</>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	rejectButton: {
		flex: 0.75,
	},
	acceptButton: {
		flex: 1,
	},
	eventDate: {
		color: palettes.grey[2],
		marginTop: 6,
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(10),
	},
	actionContainer: {
		flexDirection: 'row',
	},
	invitationText: {
		color: palettes.grey[0],
	},
	name: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
	detailsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	container: {
		flexDirection: 'row',
		width: '100%',
		borderLeftWidth: 3,
		borderLeftColor: 'transparent',
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
	notificationIcon: {
		width: 28,
		height: 28,
	},
	notificationIconContainer: {
		width: getResponsiveStyle(56),
		height: getResponsiveStyle(56),
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: palettes.grey[4],
		borderWidth: 1,
		borderRadius: 9999,
	},
});

function styleInvitationText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleName() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
}

function styleDetailsContainer() {
	return {
		marginBottom: getResponsiveStyle(8),
	};
}

function styleProfileIcon() {
	return {
		width: getResponsiveStyle(56, 'dimensions'),
		height: getResponsiveStyle(56, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingBottom: getResponsiveStyle(15),
		paddingTop: getResponsiveStyle(15),
	};
}
