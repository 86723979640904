import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	deletedStatusId: string;
};

const initialState: TInitialState = {
	deletedStatusId: '',
};

const statusPostSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		setDeletedStatusId(state, action: PayloadAction<string>) {
			state.deletedStatusId = action.payload;
		},
	},
});

export const { setDeletedStatusId } = statusPostSlice.actions;

export default statusPostSlice.reducer;
