import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type TStat = { stat: number | string; label: string };

type Props = {
	stats: TStat[];
};

export const HostStatCard = ({ stats }: Props) => {
	return (
		<View style={[styles.container, styleContainer()]}>
			{stats.map(({ label, stat }) => (
				<View key={label} style={styles.innerContainer}>
					<Text style={[styles.stat, styleStat()]}>{stat}</Text>
					<Text style={[styles.label, styleLabel()]}>{label}</Text>
				</View>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',

		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
	innerContainer: { flex: 1, alignItems: 'center' },
	stat: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
	label: {
		color: palettes.grey[0],
	},
});

const styleLabel = () => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
	};
};

const styleStat = () => {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		marginBottom: getResponsiveStyle(3),
	};
};

function styleContainer() {
	return {
		paddingTop: getResponsiveStyle(16),
		marginBottom: getResponsiveStyle(16),
	};
}
