import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TBank = {
	bankId: string;
	account_name: string;
	country: string;
	account_number: string;
	bank_name: string;
	default: boolean;
};

type ConnectData = {
	connectAccountId: string;
	flag: boolean;
	lastUrl: string;
	userId: string;
};
type TGetBanksSliceInitialState = {
	UserBanks: [] | TBank[];
	defaultBank: TBank;
	changingDefault: boolean;
	loadingBanks: boolean;
	loadingConnectAccount: boolean;
	connectAccountId: string;
	connectAccountData: ConnectData;
	createBankModalState: boolean;
	bankComponentHeight: number;
};

const initialState: TGetBanksSliceInitialState = {
	UserBanks: [],
	defaultBank: {
		bankId: '',
		account_name: '',
		country: '',
		account_number: '',
		bank_name: '',
		default: false,
	},
	changingDefault: false,
	loadingBanks: false,
	loadingConnectAccount: false,
	connectAccountId: '',
	connectAccountData: {} as ConnectData,
	createBankModalState: false,
	bankComponentHeight: 0,
};

const userBankSlice = createSlice({
	name: 'getBanks',
	initialState,
	reducers: {
		setUserBanks(state, action: PayloadAction<TBank[]>) {
			state.UserBanks = action.payload;
		},
		setDefaultBank(state, action: PayloadAction<TBank>) {
			state.defaultBank = action.payload;
		},
		setdefaultloadingBank(state, action: PayloadAction<boolean>) {
			state.changingDefault = action.payload;
		},
		setloadingBanks(state, action: PayloadAction<boolean>) {
			state.loadingBanks = action.payload;
		},
		setConnectAccountId(state, action: PayloadAction<string>) {
			state.connectAccountId = action.payload;
		},
		setLoadingConnectAccount(state, action: PayloadAction<boolean>) {
			state.loadingConnectAccount = action.payload;
		},
		setConnectAccountData(state, action: PayloadAction<any>) {
			state.connectAccountData = action.payload;
		},
		setCreateBankModalState(state) {
			state.createBankModalState = !state.createBankModalState;
		},
		setBankComponentHeight(state, action: PayloadAction<number>) {
			state.bankComponentHeight = action.payload;
		},
	},
});

export default userBankSlice.reducer;
export const {
	setUserBanks,
	setDefaultBank,
	setdefaultloadingBank,
	setloadingBanks,
	setConnectAccountId,
	setLoadingConnectAccount,
	setConnectAccountData,
	setCreateBankModalState,
	setBankComponentHeight,
} = userBankSlice.actions;
