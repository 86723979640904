import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import dayjs from 'dayjs';

const DateComponent = ({ date }: { date: string | Date }) => {
	return (
		<View style={Styles.dateContainer}>
			<View style={Styles.dateContainerTop}>
				<Text style={Styles.dateContainerTopText}>
					{date && dayjs(+date).get('date')}
				</Text>
			</View>
			<View style={Styles.dateContainerBottom}>
				<Text style={Styles.dateContainerBottomText}>
					{date && dayjs(+date).format('MMM')}
				</Text>
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	dateContainer: {
		width: 72,
		height: 64,
		border: '1px solid #E0E4E9',
		borderRadius: 6,
		marginRight: 16,
	},
	dateContainerTop: {
		height: 48,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dateContainerTopText: {
		fontSize: 24,
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	dateContainerBottom: {
		height: 16,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: palettes.red[0],
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
	},
	dateContainerBottomText: {
		fontSize: 12,
		fontWeight: '600',
		color: '#FFF',
	},
});

export default DateComponent;
