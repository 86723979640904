import { gql } from '@apollo/client';

export const GET_ENTITY_RATING = gql`
	query getAverageRating($entityId: String) {
		getAverageRating(entityId: $entityId) {
			statusCode
			message
			data {
				... on RatingQueryResponse {
					averageEntityRating
				}
			}
		}
	}
`;

export const GET_RATINGS_AND_REVIEWS = gql`
	query getEntityRating($args: ReviewAndRantingQuery!) {
		getEntityRating(args: $args) {
			statusCode
			data {
				data {
					... on Rating {
						_id
						rating
						review
						createdAt
						ratedBy {
							username
							personalInformation {
								profileImageLink
								address {
									city
									country
									countryCode
									countryLabel
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_BRAND_RATING = gql`
	query getBrandRating($brandId: String) {
		getBrandRating(brandId: $brandId) {
			statusCode
			message
			data {
				rating
			}
		}
	}
`;
