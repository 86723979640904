import { gql } from '@apollo/client';
import { ADDRESS_BOOK_FRAGMENT } from './fragments';

export const EDIT_USER = gql`
	mutation editUser($email: String!, $args: EditUserInputs!) {
		editUser(email: $email, args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on User {
						_id
						username
						email
					}
				}
			}
		}
	}
`;

export const CREATE_ADDRESS_BOOK = gql`
	${ADDRESS_BOOK_FRAGMENT}
	mutation createAddressBook($args: AddressBookInputs!) {
		createAddressBook(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					...AddressBookFragment
				}
			}
		}
	}
`;

export const EDIT_ADDRESS_BOOK = gql`
	${ADDRESS_BOOK_FRAGMENT}
	mutation editAddressBook($_id: ID!, $args: EditAddressBook!) {
		editAddressBook(_id: $_id, args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					...AddressBookFragment
				}
			}
		}
	}
`;

export const DELETE_ADDRESS_BOOK = gql`
	${ADDRESS_BOOK_FRAGMENT}
	mutation deleteAddressBook($_id: ID!, $args: DeleteAddressBookInput!) {
		deleteAddressBook(_id: $_id, args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					...AddressBookFragment
				}
			}
		}
	}
`;
