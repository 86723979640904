import React, { useEffect, useState } from 'react';
import {
	ActivityIndicator,
	Pressable,
	StyleSheet,
	Text,
	TextInputProps,
	View,
} from 'react-native';
import { Input } from '..';
import { palettes } from '../../config';
import { TStyle, TStyleInputOrText } from '../../types/TStyle';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { AttachPictureIcon } from '../common/icons';
import ProfileIcon from '../common/ProfileIcon';
import { useTranslation } from 'react-i18next';
import Button from '../common/button/Button';
import useCreateStatus from '../../api/status/useCreateStatus';
import { useCreateMedia } from '../../api/common/useCreateMedia';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { usePrimaryColors } from '../../hooks/usePrimaryColors';
import useCreateComment from '../../api/comment/mutation';
import { useIsSpecificViewportWidth } from '../../hooks/useIsSpecificViewportWidth';
import Loader from '../common/loader/Loader';
type Props = {
	hasUpload?: boolean;
	hasSend?: boolean;
	user?: TGetAllUsersData;
	containerStyle?: TStyle;
	inputProps?: TextInputProps;
	isCommentInput?: boolean;
	commentParentId?: string;
	inThread?: boolean;
	onSubmit: (args: StatusSubmit) => void;
};

const ShareUpdateInput = ({
	hasUpload = false,
	hasSend = true,
	containerStyle,
	inputProps,
	isCommentInput = false,
	commentParentId,
	inThread,
	user,
	onSubmit,
}: Props) => {
	const [fileType, setFileType] = useState<string>('');
	const [file, setFile] = useState<string>('');
	const [status, setStatus] = useState<string>('');

	const { setAlert } = useSetAlert();

	const primary = usePrimaryColors();

	const { executeCreateStatusMutation, loading } = useCreateStatus();

	const { executeCreateCommentMutation } = useCreateComment();

	const { isMobileView } = useIsSpecificViewportWidth();

	const {
		uploadMedia,
		loading: loading_uploadMedia,
		data,
	} = useCreateMedia({});

	const { t } = useTranslation();
	const inputStyle = inputProps?.style;
	delete inputProps?.style;

	const handleFileChange = (e: any) => {
		const file = e.target.files[0];
		setFileType(file.type.split('/')[0].toUpperCase());

		if (file) {
			uploadMedia(file, 'USER', 'media-files');
		}
	};

	useEffect(() => {
		if (data) {
			setFile(data.createMedia.data?.src as string);
			setAlert(t('File uploaded successfully'), 'normal');
		}
	}, [data]);

	const createStatus = () => {
		if (status) {
			executeCreateStatusMutation({
				status,
				fileType,
				file,
			});
			setAlert(t('Status uploaded successfully'), 'normal');
			setFile('');
			setFileType('');
			setStatus('');
			onSubmit({
				commentType: inThread ? 'COMMENT_REPLY' : 'STATUS',
				content: status,
				parentId: commentParentId as string,
			});
		}
	};

	const createComment = () => {
		if (isCommentInput && status) {
			executeCreateCommentMutation({
				commentType: inThread ? 'COMMENT_REPLY' : 'STATUS',
				content: user?.username
					? `@${user?.username} ${status}`
					: status,
				parentId: commentParentId as string,
			});

			onSubmit({
				commentType: inThread ? 'COMMENT_REPLY' : 'STATUS',
				content: user?.username
					? `@${user?.username} ${status}`
					: status,
				parentId: commentParentId as string,
			});
			// setAlert('Status uploaded successfully', 'normal');
			// setFile('');
			// setFileType('');
			setStatus('');
		}
	};

	return (
		<View style={[styles.container, styleContainer(), containerStyle]}>
			<View
				style={[
					styles.profileImageContainer,
					styleProfileImageContainer(),
					isMobileView && {
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
					},
				]}
			>
				<ProfileIcon
					style={[
						styleProfileImage(),
						((status && isMobileView) || false) && {
							height: '30%',
						},
					]}
				/>
			</View>
			{hasSend && (
				<>
					<View
						style={[
							styles.attachPictureContainer,
							styleAttachPictureContainer(
								isCommentInput,
								isMobileView
							),
							((status && isMobileView) || false) && {
								height: '30%',
							},
						]}
					>
						<Pressable style={styles.attachPictureInnerContainer}>
							{loading_uploadMedia ? (
								<Loader size="small" />
							) : (
								<>
									{hasUpload && (
										<>
											<label
												style={{
													cursor: 'pointer',
												}}
												htmlFor="test-input"
											>
												<AttachPictureIcon
													style={styleAttachPictureIcon()}
												/>
											</label>

											<Text
												style={[
													styles.attachPictureText,
													styleAttachPictureText(),
												]}
											>
												{!isMobileView && (
													<label
														style={{
															cursor: 'pointer',
														}}
														htmlFor="test-input"
													>
														{t(
															`${
																fileType
																	? t(
																			'File Selected/Send'
																	  )
																	: t(
																			'Upload/Video'
																	  )
															}`
														)}
													</label>
												)}
												<input
													id="test-input"
													type={'file'}
													accept={'image/*,video/*'}
													style={{
														display: 'none',
													}}
													onChange={handleFileChange}
												/>
											</Text>
										</>
									)}
									<View
										style={{
											zIndex: 10,
											width: `${
												isCommentInput ? '100%' : '50%'
											}`,
											marginLeft: '0.7rem',
										}}
									>
										{!isMobileView && (
											<Button
												title={t('Post')}
												radius={6}
												outlineColor={primary?.[0]}
												onPress={
													isCommentInput
														? createComment
														: createStatus
												}
												loading={loading}
											/>
										)}
									</View>
								</>
							)}
						</Pressable>
					</View>
				</>
			)}
			<Input
				placeholder={t('Write Something') + ' ...'}
				placeholderTextColor={palettes.grey[2]}
				value={status}
				style={[
					styleInput(inputStyle, hasUpload, isMobileView),
					((status && isMobileView) || false) && {
						height: getResponsiveStyle(150),
					},
					isMobileView && {
						paddingTop: getResponsiveStyle(15),
					},
				]}
				numberOfLines={status ? 2 : 0}
				{...inputProps}
				multiline={isMobileView}
				onChangeText={text => setStatus(text)}
				onSubmitEditing={isCommentInput ? createComment : createStatus}
			/>
			<View style={styles.mobileUploadButton}>
				{((status && isMobileView) || false) && (
					<Button
						title={t('Post')}
						radius={6}
						outlineColor={primary[0]}
						onPress={isCommentInput ? createComment : createStatus}
						loading={loading}
					/>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		position: 'relative',
	},
	profileImage: {
		position: 'absolute',
	},
	profileImageContainer: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
	},
	input: {
		width: '100%',
		paddingLeft: 'calc(32px + 16px + 16px)',
		borderColor: palettes.grey[5],
		shadowOpacity: 0,
	},
	attachPictureContainer: {
		height: '100%',
		position: 'absolute',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		right: 0,
		top: 0,
		zIndex: 11,
	},
	mobileUploadButton: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		padding: getResponsiveStyle(10),
	},
	attachPictureInnerContainer: { flexDirection: 'row', alignItems: 'center' },

	attachPictureText: {
		color: palettes.grey[0],
	},
});

const styleInput = (
	inputStyle: TStyleInputOrText,
	hasUpload: boolean,
	isMobileView: boolean
) => {
	return [
		styles.input,
		{
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		{
			paddingRight: isMobileView
				? getResponsiveStyle(50, 'spacing')
				: hasUpload
				? getResponsiveStyle(250, 'spacing')
				: getResponsiveStyle(80, 'spacing'),
		},
		inputStyle,
	];
};

export default ShareUpdateInput;

const styleAttachPictureText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleAttachPictureIcon = () => {
	return {
		width: getResponsiveStyle(21, 'dimensions'),
		height: getResponsiveStyle(19, 'dimensions'),
		marginRight: getResponsiveStyle(7),
	};
};

const styleAttachPictureContainer = (
	isCommentInput: boolean,
	isMobileView: boolean
) => {
	return {
		right: getResponsiveStyle(
			isMobileView ? 0 : +`${isCommentInput ? 15 : 45}`,
			'dimensions'
		),
		// width: getResponsiveStyle(120, 'dimensions'),
	};
};

const styleProfileImage = () => {
	return {
		width: getResponsiveStyle(32, 'dimensions'),
		height: getResponsiveStyle(50, 'dimensions'),
	};
};

const styleContainer = () => {
	return { marginBottom: getResponsiveStyle(13) };
};
function styleProfileImageContainer() {
	return {
		width: getResponsiveStyle(40, 'dimensions'),
		paddingLeft: getResponsiveStyle(16),
	} as TStyle;
}
