import Svg, { SvgProps, Path } from 'react-native-svg';
import React from 'react';

const HomeIconOutline = (props: SvgProps) => {
	return (
		<Svg fill="none" viewBox="0 0 24 24" {...props}>
			<Path
				d="M5.56 3.827c2.542-1.847 3.814-2.77 5.265-2.824.117-.004.233-.004.35 0 1.451.053 2.723.977 5.265 2.824s3.813 2.77 4.313 4.135c.04.11.076.22.108.332.398 1.397-.088 2.892-1.059 5.88l-1.64 5.05a1.947 1.947 0 0 1-3.8-.602v-2.62c0-1.01-.818-1.828-1.827-1.828h-3.07c-1.009 0-1.827.819-1.827 1.828v2.62a1.947 1.947 0 0 1-3.8.602l-1.64-5.05c-.971-2.988-1.457-4.483-1.059-5.88.032-.112.068-.223.108-.332.5-1.364 1.77-2.288 4.313-4.135Z"
				stroke="#98A2B2"
			/>
		</Svg>
	);
};

export default HomeIconOutline;
