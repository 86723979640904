import { gql } from '@apollo/client';

export const ADD_TO_EVENT_ATTENDED = gql`
	mutation addEventAttendance($user: ID!, $event: ID!) {
		addEventAttendance(user: $user, event: $event) {
			... on Response {
				success
				statusCode
				message
			}
		}
	}
`;
