export const GENDER_CATEGORIES: TDropdownObject[] = [
	{ label: 'Male', value: 'MALE' },
	{ label: 'Female', value: 'FEMALE' },
];
export const RATING_CATEGORIES: TDropdownObject[] = [
	{ label: '5', value: '5' },
	{ label: '4', value: '4' },
	{ label: '3', value: '3' },
	{ label: '2', value: '2' },
	{ label: '1', value: '1' },
];
export const INTEREST_CATEGORIES: TDropdownObject[] = [
	{ label: 'Wellness products', value: 'wellness-products' },
	{
		label: 'Cosmetics and Personal Care products',
		value: 'cosmetics-and-personal-care',
	},
	{
		label: 'Household goods and durables products',
		value: 'household-durable-products',
	},
	{
		label: 'Clothing and accessories products',
		value: 'clothing-and-accessories',
	},
	{
		label: 'Home and kitchen products',
		value: 'home-and-kitchen',
	},
	{
		label: 'Foodstuff and beverages products',
		value: 'food-and-beverages',
	},
	{
		label: 'Leisure activities products',
		value: 'leisure-activities',
	},
	{ label: 'Other products', value: 'other-products' },
];
export const TYPE_OF_ACCOUNT: TDropdownObject[] = [
	{ label: 'Guest', value: 'CONSUMER' },
	{ label: 'Seller', value: 'SELLER' },
	{ label: 'Host', value: 'HOST' },
];
export const TYPE_OF_EVENT: TDropdownObject[] = [
	{ label: 'Physical', value: 'PHYSICAL' },
	{ label: 'Live', value: 'VIRTUAL' },
];
