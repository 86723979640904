import React from 'react';
import Svg, { Path, SvgProps, G } from 'react-native-svg';
import { TStyle } from '../../../types/TStyle';
import { Alert, Platform, Pressable, StyleSheet, Text } from 'react-native';
import { Share } from 'react-native';
import config from '../../../../config';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookMessengerIcon,
	FacebookMessengerShareButton,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
import { Popable } from 'react-native-popable';
import { FACEBOOK_CLIENT_ID } from '../../../constants/KEYS';

type Props = {
	containerStyles?: TStyle;
	onPress?: () => void;
	color?: string;
	data?: any;
	position?: string;
} & Omit<SvgProps, 'onPress' | 'color'>;

const ShareArrowIcon = ({
	containerStyles,
	onPress,
	color,
	data,
	position,
	...props
}: Props) => {
	const { t } = useTranslation();
	const isMac = navigator?.platform?.toUpperCase()?.includes('MAC');
	const shareMessagePhysical = `${t("Don't miss the event")} ${
		data?.affiliatedSeller?.username
	} ${t('the')} ${dayjs(+data?.startingEventDateTime)?.format(
		'YYYY-MM-DD'
	)} ${t('has')} ${dayjs(+data?.startingEventDateTime)?.format(
		'HH:mm:ss'
	)} ${t('Discover our latest offers!')} ${
		config.CLIENT_URL
	}/event/description?eventId=${data?._id}`;

	const shareMessageLive = `${data?.name} : ${t(
		'Available live and in replay. Click here'
	)} : ${config.CLIENT_URL}/event/description?eventId=${data?._id}`;
	const shareMessage =
		data?.typeOfEvent === 'VIRTUAL'
			? shareMessageLive
			: shareMessagePhysical;
	const onShare = async () => {
		try {
			const result = await Share.share({
				message: shareMessage,
			});
			if (result.action === Share.sharedAction) {
				if (result.activityType) {
					// shared with activity type of result.activityType
				} else {
					// shared
				}
			} else if (result.action === Share.dismissedAction) {
				// dismissed
			}
		} catch (error: any) {
			Alert.alert(error.message);
		}
	};

	const referer = document.referrer;

	const ua = navigator.userAgent;
	const isFbBrowser = ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
	const isInstagramBrowser =
		ua.indexOf('Instagram') > -1 || ua.indexOf('Instagram') > -1;

	return (
		<>
			{isMac || !!referer?.length ? (
				<Popable
					position="top"
					backgroundColor="transparent"
					// style={[
					// 	position === 'bottom' && {
					// 		position: 'absolute',
					// 		top: 20,
					// 		left: 30,
					// 	},
					// ]}
					content={
						<>
							<WhatsappShareButton
								style={styles.calenderOptions}
								url={shareMessage}
							>
								<WhatsappIcon size={25} round={true} />
							</WhatsappShareButton>
							<TwitterShareButton
								style={styles.calenderOptions}
								url={shareMessage}
							>
								<TwitterIcon size={25} round={true} />
							</TwitterShareButton>
							<FacebookShareButton
								style={styles.calenderOptions}
								url={`${config.CLIENT_URL}/event/description?eventId=${data?._id}`}
								hashtag="#"
							>
								<FacebookIcon size={25} round={true} />
							</FacebookShareButton>
							<TelegramShareButton
								style={styles.calenderOptions}
								url={shareMessage}
							>
								<TelegramIcon size={25} round={true} />
							</TelegramShareButton>
							<FacebookMessengerShareButton
								appId={FACEBOOK_CLIENT_ID}
								style={styles.calenderOptions}
								url={`${config.CLIENT_URL}/event/description?eventId=${data?._id}`}
							>
								<FacebookMessengerIcon size={25} round={true} />
							</FacebookMessengerShareButton>
						</>
					}
				>
					<Svg
						width={512}
						height={512}
						viewBox="0 0 512 512"
						preserveAspectRatio="xMidYMid meet"
						{...props}
					>
						<G
							transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
							fill={props.fill}
							stroke="none"
						>
							<Path d="M3416 4690 c-81 -26 -144 -97 -178 -202 -19 -55 -22 -94 -26 -310 -5 -279 3 -258 -92 -258 -71 0 -282 -28 -420 -56 -290 -57 -544 -145 -825 -284 -331 -163 -586 -344 -849 -600 -294 -287 -502 -571 -676 -924 -219 -444 -329 -876 -346 -1361 -5 -143 -4 -164 13 -195 37 -71 132 -102 201 -66 16 8 70 67 121 131 542 678 1270 1145 2096 1345 234 57 443 90 676 106 l97 7 4 -249 c6 -303 17 -349 102 -442 53 -57 101 -77 186 -76 88 1 136 20 225 87 33 25 335 321 671 659 517 520 615 622 642 675 102 196 107 387 15 573 -35 72 -67 106 -692 731 -732 731 -706 709 -846 715 -38 2 -83 -1 -99 -6z m765 -947 c460 -462 591 -599 610 -638 35 -74 34 -190 -3 -260 -19 -36 -194 -217 -642 -665 -338 -338 -619 -611 -623 -606 -4 4 -10 153 -13 330 -6 356 -5 355 -73 396 -78 48 -592 9 -962 -72 -763 -166 -1446 -534 -2021 -1090 l-121 -118 17 98 c184 1026 909 1909 1885 2295 333 132 642 197 1026 215 171 8 193 16 229 75 19 30 20 52 20 337 0 168 3 315 6 327 l6 23 37 -29 c20 -16 300 -294 622 -618z" />
						</G>
					</Svg>
				</Popable>
			) : (
				<Pressable style={containerStyles} onPress={onShare}>
					<Svg
						width={512}
						height={512}
						viewBox="0 0 512 512"
						preserveAspectRatio="xMidYMid meet"
						{...props}
					>
						<G
							transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
							fill={props.fill}
							stroke="none"
						>
							<Path d="M3416 4690 c-81 -26 -144 -97 -178 -202 -19 -55 -22 -94 -26 -310 -5 -279 3 -258 -92 -258 -71 0 -282 -28 -420 -56 -290 -57 -544 -145 -825 -284 -331 -163 -586 -344 -849 -600 -294 -287 -502 -571 -676 -924 -219 -444 -329 -876 -346 -1361 -5 -143 -4 -164 13 -195 37 -71 132 -102 201 -66 16 8 70 67 121 131 542 678 1270 1145 2096 1345 234 57 443 90 676 106 l97 7 4 -249 c6 -303 17 -349 102 -442 53 -57 101 -77 186 -76 88 1 136 20 225 87 33 25 335 321 671 659 517 520 615 622 642 675 102 196 107 387 15 573 -35 72 -67 106 -692 731 -732 731 -706 709 -846 715 -38 2 -83 -1 -99 -6z m765 -947 c460 -462 591 -599 610 -638 35 -74 34 -190 -3 -260 -19 -36 -194 -217 -642 -665 -338 -338 -619 -611 -623 -606 -4 4 -10 153 -13 330 -6 356 -5 355 -73 396 -78 48 -592 9 -962 -72 -763 -166 -1446 -534 -2021 -1090 l-121 -118 17 98 c184 1026 909 1909 1885 2295 333 132 642 197 1026 215 171 8 193 16 229 75 19 30 20 52 20 337 0 168 3 315 6 327 l6 23 37 -29 c20 -16 300 -294 622 -618z" />
						</G>
					</Svg>
				</Pressable>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	// addToCalender: {
	// 	paddingTop: getResponsiveStyle(7),
	// 	paddingBottom: getResponsiveStyle(7),
	// 	paddingRight: getResponsiveStyle(5),
	// 	paddingLeft: getResponsiveStyle(5),
	// 	backgroundColor: palettes?.red[0],
	// 	fontSize: getResponsiveStyle(14, 'font'),
	// 	color: palettes?.light[0],
	// 	borderRadius: 6,
	// 	textAlign: 'center',
	// },
	calenderOptions: {
		padding: 10,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
	},
});

export default ShareArrowIcon;
