import { View, StyleSheet } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import PlusIcon from '../PlusIcon';

const TimelinePlaceholder = () => {
	return (
		<View style={styles.outerCircle}>
			<View style={styles.innerCircle}>
				<PlusIcon height={11} width={11} />
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	outerCircle: {
		borderStyle: 'dashed',
		borderRadius: 9999,
		borderWidth: 2,
		borderColor: palettes.grey[5],
		height: 65,
		width: 65,
		justifyContent: 'center',
		alignItems: 'center',
	},
	innerCircle: {
		backgroundColor: palettes.grey[6],
		justifyContent: 'center',
		alignItems: 'center',
		padding: 3,
		height: 57,
		width: 57,
		borderRadius: 9999,
	},
});

export default TimelinePlaceholder;
