import { useLazyQuery } from '@apollo/client';
import { GET_SINGLE_EVENT } from '../graphql/events/queries';
import { useAppDispatch } from '../redux/store';
import { setBuyingFromEvent } from '../redux/userSlice';

type TParams = {
	id: string;
};
export const useGetEvent = ({ id }: TParams) => {
	const dispatch = useAppDispatch();
	const [getEvent] = useLazyQuery(GET_SINGLE_EVENT, {
		variables: { _id: id },
		onCompleted: data => {
			if (data !== null) {
				if (data.getEvent !== null) {
					dispatch(
						setBuyingFromEvent({
							_id: data?.getEvent.data?._id,
							typeOfEvent: data?.getEvent.data?.typeOfEvent,
							isHostApplicationAccepted:
								data?.getEvent.data?.isHostApplicationAccepted,
							startingEventDateTime:
								data?.getEvent.data?.startingEventDateTime,
							eventStreamTimeline:
								data?.getEvent.data?.eventStreamTimeline,
							affiliatedSeller:
								data?.getEvent.data?.affiliatedSeller,
							affiliatedBrands:
								data?.getEvent.data?.affiliatedBrands,
							affiliatedHosts:
								data?.getEvent.data?.affiliatedHosts,
							invitedPeople: data?.getEvent.data?.invitedPeople,
						})
					);
				}
			}
		},
	});

	return { getEvent };
};
