import { useLazyQuery } from '@apollo/client';
import { GET_ALL_LIKES } from '../../graphql/likes/queries';

const useGetAllLikes = () => {
	const [getAllLikes, { data, error, loading }] = useLazyQuery(GET_ALL_LIKES);

	const executeAllLikesQuery = ({ parentId }: { parentId: string }) => {
		getAllLikes({
			variables: {
				parentId,
			},
		});
	};
	return { executeAllLikesQuery, data, error, loading };
};

export default useGetAllLikes;
