import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { NotAvailableMessage, SectionHeading } from '..';
import { useIsSpecificViewportWidth } from '../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../types/TStyle';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { isLastArrElement } from '../../utils/isLastArrElement';
import EventDate from '../common/event-date/EventDate';
import Loader from '../common/loader/Loader';
import EventDateLoader from '../common/skeleton-loaders/event-loaders/EventDateLoader';

type Props = {
	heading: string | string[];
	itemsToRender?: number;
	dateHasPassed?: boolean;
	events?: TGetAllEventsData[];
	loading?: boolean;
	canClick?: boolean;
	onPressViewAll?: () => void;
	isGreyedOut?: boolean;
};

const SidebarEventList = ({
	heading,
	itemsToRender = 5,
	dateHasPassed,
	events,
	loading,
	canClick,
	onPressViewAll,
	isGreyedOut = false,
}: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	// const UpcomingEventsToRender = events ? events : createArray(15);
	const { t } = useTranslation();
	return (
		<View style={styleContainer()}>
			<SectionHeading
				hasViewAllLabel
				heading={heading}
				size="small"
				onPressViewAll={onPressViewAll}
			/>
			{loading ? (
				// <Loader size="small" />
				<ScrollView
					horizontal={isLessThanDesktopBase}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					style={{
						width: isLessThanDesktopBase ? '100vw' : 'auto',
					}}
				>
					<EventDateLoader />
					<EventDateLoader />
					<EventDateLoader />
				</ScrollView>
			) : (
				<>
					{events && events.length > 0 ? (
						<ScrollView
							horizontal={isLessThanDesktopBase}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
							style={{
								width: isLessThanDesktopBase ? '100vw' : 'auto',
							}}
						>
							{events
								.slice(0, itemsToRender)
								.map((item, index) => (
									<EventDate
										canClick={canClick}
										item={item}
										key={index}
										type="event"
										containerStyles={styleEventDateCard(
											index,
											isLessThanDesktopBase,
											events
										)}
										dateHasPassed={
											(!!item?.startingEventDateTime &&
												+item?.startingEventDateTime <
													Date.now()) ||
											isGreyedOut
										}
										eventDetailsContainerStyles={{
											width: getResponsiveStyle(
												130,
												'dimensions'
											),
										}}
									/>
								))}
						</ScrollView>
					) : (
						<NotAvailableMessage message={t('No event found')} />
					)}
				</>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		height: 'auto',
	},
});

const styleEventDateCard = (
	index: number,
	isLessThanDesktopBase: boolean,
	UpcomingEventsToRender: TGetAllEventsData[] | number[]
): TStyle => {
	return {
		marginBottom:
			isLastArrElement(UpcomingEventsToRender, index) ||
			isLessThanDesktopBase
				? 0
				: 16,
		width: isLessThanDesktopBase ? 232 : '100%',
		marginRight: isLessThanDesktopBase ? 20 : 0,
	};
};

export default SidebarEventList;

const styleContainer = () => {
	return [
		styles.container,
		{
			marginBottom: getResponsiveStyle(29),
		},
	];
};
