import { ApolloError } from '@apollo/client';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import { Button, Input } from '../..';
import { useCreateUser, useLogin } from '../../../api/auth';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { setBankAccountDetails } from '../../../redux/createUserSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setPrimaryColors } from '../../../redux/uiSlice';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const validationSchema = yup.object({
	IBAN: yup.number().required('Required'),
	bankName: yup.string().required('Required'),
	city: yup.string().required('Required'),
});

type IInitialValues = yup.InferType<typeof validationSchema>;

const initialValues: IInitialValues = {
	bankName: '',
	city: '',
	IBAN: '',
};

const Months = [
	{
		value: 'jan',
		label: 'January',
	},
	{
		value: 'feb',
		label: 'February',
	},
	{
		value: 'mar',
		label: 'March',
	},
	{
		value: 'april',
		label: 'April',
	},
];

type Props = {
	isProfile?: boolean;
};

const CreditCardInformationForm = ({ isProfile }: Props) => {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();

	const { signupSidebarArray, creditCardInformation } = useAppSelector(
		state => ({
			signupSidebarArray: state.ui.SignupSidebarArray,
			creditCardInformation: state.createUser.args.creditCardInformation,
		})
	);

	const [loadingSkip, setLoadingSkip] = useState(false);
	const [loadingNext, setLoadingNext] = useState(false);
	const [editInitialState, setEditInitialState] = useState<IInitialValues>({
		IBAN: '',
		bankName: '',
		city: '',
	});

	const { goToNextStepScreen } = useNextStepScreen(signupSidebarArray);

	const { executeLoginMutation } = useLogin();

	useEffect(() => {
		if (isProfile) {
			setEditInitialState({
				IBAN:
					creditCardInformation?.IBAN ??
					'1234567891234567891234567891234567',
				bankName: creditCardInformation?.bankName ?? 'Meezan',
				city: creditCardInformation?.city ?? 'Lahore',
			});
		}
	}, []);

	const onCompletedCreateUser = (data: TCreateUserResponse) => {
		setLoadingSkip(false);
		setLoadingNext(false);
		if (data.createUser.success) {
			dispatch(
				setPrimaryColors(
					data?.createUser.data?.typeOfAccount || 'SELLER'
				)
			);
			setAlert(
				t('Please see the email we sent you to create your account'),
				'normal'
			);
			// executeLoginMutation({
			// 	email: "",
			// 	password: ""
			// })
			goToNextStepScreen();
		} else {
			setAlert(t('Error occured during account creation'), 'danger');
		}
	};

	const onErrorCreateUser = (error: ApolloError) => {
		setLoadingSkip(false);
		setLoadingNext(false);
		if (error) {
			setAlert(error.message, 'danger');
		}
	};

	useEffect(() => {
		if (
			!!creditCardInformation?.IBAN &&
			!!creditCardInformation?.bankName &&
			!!creditCardInformation?.city
		)
			createUser();
	}, [
		creditCardInformation?.IBAN,
		creditCardInformation?.bankName,
		creditCardInformation?.city,
	]);

	const { createUser, loading: createUserLoading } = useCreateUser(
		onCompletedCreateUser,
		onErrorCreateUser
	);

	return (
		<Formik
			enableReinitialize
			initialValues={isProfile ? editInitialState : initialValues}
			validationSchema={validationSchema}
			onSubmit={values => {
				setLoadingNext(true);
				dispatch(setBankAccountDetails(values));
			}}
		>
			{({
				handleBlur,
				handleChange,
				handleSubmit,
				touched,
				errors,
				isValid,
				dirty,
				values,
			}) => (
				<View>
					<View style={styleRow()}>
						<Input
							value={values.IBAN}
							label={t('IBAN')}
							containerStyles={styles.flex1}
							onChangeText={handleChange('IBAN')}
							onBlur={handleBlur('IBAN')}
							error={
								touched.IBAN && errors.IBAN
									? `${errors.IBAN}`
									: ''
							}
						/>
					</View>

					<View style={styleRow()}>
						<Input
							value={values.bankName}
							label={t('Bank Name')}
							containerStyles={styles.flex1}
							onChangeText={handleChange('bankName')}
							onBlur={handleBlur('bankName')}
							error={
								touched.bankName && errors.bankName
									? errors.bankName
									: ''
							}
						/>
						<Input
							value={values.city}
							label={t('City')}
							containerStyles={styles.flex1}
							onChangeText={handleChange('city')}
							onBlur={handleBlur('city')}
							error={
								touched.city && errors.city ? errors.city : ''
							}
						/>
					</View>

					<Button
						title={isProfile ? t('Update') : t('Next')}
						outerContainerProps={{
							style: styleNextButton(),
						}}
						variant={
							!!values.bankName &&
							!!values.city &&
							!!values.IBAN &&
							(dirty || isProfile) &&
							isValid
								? 'primary'
								: 'disabled'
						}
						size="lg"
						onPress={handleSubmit}
						loading={loadingNext}
					/>
					{!isProfile && (
						<Button
							title={t('Skip')}
							variant="outline"
							size="lg"
							onPress={() => {
								setLoadingSkip(true);
								createUser();
							}}
							loading={loadingSkip}
						/>
					)}
				</View>
			)}
		</Formik>
	);
};

export default CreditCardInformationForm;

const styles = StyleSheet.create({
	dropdownPickerContainer: {
		width: 'calc(50% - 3px)',
	},
	flex1: {
		flex: 1,
	},
});

const styleRow = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(24),
	} as TStyle;
};
function styleNextButton() {
	return {
		marginTop: getResponsiveStyle(42),
		marginBottom: getResponsiveStyle(15),
	};
}
