import { useMutation } from '@apollo/client';
import { FOLLOW } from '../../graphql/follow/mutations';
import { TReturnUseApiHookMutation } from '../../types/exportedTypes';

type TFollowArgs = {
	args: {
		followedId: string;
	};
};

type TReturn = {
	followUser: (followedId: string) => void;
} & TReturnUseApiHookMutation<TFollowResponse, TFollowArgs>;

type Params = {
	onCompleted?: TOnCompleteApiCall<TFollowResponse>;
};

export const useFollow = (params?: Params): TReturn => {
	const [executeMutation, states] = useMutation<TFollowResponse, TFollowArgs>(
		FOLLOW,
		{
			onCompleted: params?.onCompleted,
		}
	);

	const followUser = (followedId: string) => {
		executeMutation({
			variables: {
				args: {
					followedId,
				},
			},
		});
	};

	return { followUser, ...states };
};
