import * as React from 'react';
import Svg, {
	SvgProps,
	Path,
	Defs,
	LinearGradient,
	Stop,
} from 'react-native-svg';

const ShoppingBackIconFilled = (props: SvgProps) => (
	<Svg width={20} height={21} viewBox="0 0 24 24" fill="none" {...props}>
		<Defs>
			<LinearGradient
				id="paint0_linear_748_607"
				x1={3.26157}
				y1={5.01296}
				x2={22.7083}
				y2={22.3651}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
		</Defs>
		<Path
			fillRule="evenodd"
			d="M10 0a5.125 5.125 0 00-5.081 4.456h-.59c-.888 0-1.624 0-2.201.08-.607.085-1.153.273-1.568.736C.145 5.736.02 6.3.002 6.912c-.016.582.065 1.314.163 2.196l.274 2.47c.205 1.844.366 3.293.64 4.42.28 1.154.697 2.055 1.471 2.748.775.693 1.72 1.008 2.905 1.159 1.157.147 2.626.147 4.497.147h.096c1.871 0 3.34 0 4.497-.148 1.184-.15 2.13-.465 2.905-1.158.774-.693 1.19-1.594 1.471-2.748.274-1.127.435-2.576.64-4.42l.274-2.47c.098-.882.18-1.614.163-2.197-.017-.612-.143-1.175-.558-1.639-.415-.463-.96-.65-1.568-.735-.577-.081-1.313-.081-2.2-.081h-.59A5.125 5.125 0 0010 0zm0 1.337a3.789 3.789 0 013.729 3.119H6.27A3.789 3.789 0 0110 1.336zM4.653 8.02a.668.668 0 100 1.337h10.694a.668.668 0 000-1.337H4.653z"
			fill="url(#paint0_linear_748_607)"
		/>
	</Svg>
);

export default ShoppingBackIconFilled;
