import React from 'react';
import { Pressable } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../../config';
import { usePrimaryColors } from '../../../../hooks/usePrimaryColors';
import { TRootStackParamList } from '../../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';

type Props = {
	onPress: (screenName: keyof TRootStackParamList) => void;
	screenName: keyof TRootStackParamList;
	label: string;
	isSelected: boolean;
};

export const NavLink = ({ label, onPress, screenName, isSelected }: Props) => {
	const primary = usePrimaryColors();
	return (
		<Pressable onPress={() => onPress(screenName)}>
			<StyledNavLink primary={primary} selected={isSelected}>
				{label}
			</StyledNavLink>
		</Pressable>
	);
};

const StyledNavLink = styled.Text<{ selected: boolean; primary: string[] }>`
	color: ${({ selected }) =>
		selected ? palettes?.dark?.[0] : palettes?.grey?.[0]};
	border-bottom: 1px solid
		${({ selected, primary }) => (selected ? primary?.[0] : 'transparent')};
	font-weight: ${({ selected }) => (selected ? '700' : '400')};
	padding-bottom: 7;
	margin-right: ${getResponsiveStyle(16)}px;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		border-bottom: 1px solid ${({ primary }) => primary?.[0]};
		color: ${palettes?.dark?.[0]};
	}
`;
