import React from 'react';
import { Pressable } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

const PencilWritingIcon = (props: SvgProps) => {
	const primary = usePrimaryColors();
	return (
		<Pressable onPress={props.onPress}>
			<Svg
				width={18}
				height={18}
				fill="none"
				viewBox="0 0 18 18"
				{...props}
			>
				<Path
					fill={props.fill ?? primary?.[0]}
					d="M2 16h1.414l9.314-9.314-1.414-1.414L2 14.586V16zm16 2H0v-4.243L13.435.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L6.243 16H18v2zM12.728 3.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z"
				/>
			</Svg>
		</Pressable>
	);
};

export default PencilWritingIcon;
