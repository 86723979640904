import React, { useEffect, useState } from 'react';
import {
	ActivityIndicator,
	Image,
	Pressable,
	StyleSheet,
	View,
} from 'react-native';
import { DraggableScrollView, ProductCard } from '../..';
import { useGetSingleBrandLazy } from '../../../api/brands/useGetSingleBrand';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProductCards from '../../brand-details/all-products/ProductCards';
import Loader from '../../common/loader/Loader';
import EventProductLoader from '../../common/skeleton-loaders/event-loaders/EventProductLoader';
// import ProductCard from './ProductCard';

const ProductCardSlider = (props: any) => {
	const { affiliatedBrands } = useAppSelector(state => state.getEvent.event);

	const [products, setProducts] = useState<TGetAllProductsData[]>([]);
	const [brandDetails, setBrandDetails] = useState<TGetAllBrandsData>();

	const onCompletedGetSingleBrandDetails = (
		data: TGetSingleBrandResponse
	) => {
		if (data.getBrand.success && data.getBrand.data) {
			props?.eventDetails?.setIsLoadingProduct(false);
			setBrandDetails(data.getBrand.data);
			setProducts(
				data?.getBrand?.data?.products?.data?.filter(
					product => product.publishedStatus === 'ACTIVE'
				)
			);
		}
	};

	const { getSingleBrand, loading } = useGetSingleBrandLazy({
		onCompleted: onCompletedGetSingleBrandDetails,
	});

	useEffect(() => {
		getSingleBrand(affiliatedBrands[0]?._id);
	}, []);

	// useEffect(() => {
	// }, [products]);

	const renderItem = (item: any) => {
		return (
			<>
				{loading ? (
					<Loader
						size="small"
						containerStyles={{
							margin: getResponsiveStyle(20),
						}}
					/>
				) : (
					<ProductCards products={[item.item]} eventDetails={props} />
				)}
			</>
		);
	};
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<Pressable>
			<View style={[styles.container, styleContainer()]}>
				{loading ? (
					<>
						<EventProductLoader />
						<EventProductLoader />
						<EventProductLoader />
					</>
				) : (
					<DraggableScrollView
						horizontal
						showsHorizontalScrollIndicator={false}
						style={styleDraggableScroll()}
						contentContainerStyle={{
							gap: getResponsiveStyle(20),
						}}
						data={products}
						renderItem={item => renderItem(item)}
					/>
				)}
				{!isLessThanDesktopBase && (
					<Image
						source={require('../../../assets/slider-gradient.png')}
						style={styles.scrollGradient}
					/>
				)}
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		maxWidth: '100%',
		zIndex: 1,
		position: 'relative',
	},
	scrollGradient: {
		width: '102px',
		height: '100%',
		position: 'absolute',
		right: '0px',
		zIndex: 2,
		cursor: 'auto',
	},
});

const styleDraggableScroll = (): TStyle => {
	return [
		styles.container,
		{
			maxWidth: '100%',
			paddingBottom: getResponsiveStyle(20),
		},
	];
};

export default ProductCardSlider;

const styleContainer = () => {
	return { marginBottom: getResponsiveStyle(30) };
};
