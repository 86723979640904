import React from 'react';
import {
	Image,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { Loader } from '../components';
import { EventImagePlaceholder } from '../components/common/event-card/EventImagePlaceholder';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import DescriptionContent from '../components/events/descriptionContent/DescriptionContent';
import DescriptionHeader from '../components/events/descriptionHeader/DescriptionHeader';
import DescriptionTabs from '../components/events/descriptionTabs/DescriptionTabs';
import DetailSidebar from '../components/events/detail-sidebar/DetailSidebar';
import LocationSection from '../components/events/location-section/LocationSection';
import PeopleScrollSection from '../components/events/people-scroll-section/PeopleScrollSection';
import ProductScrollSection from '../components/events/product-scroll-section/ProductScrollSection';
import { palettes } from '../config';
import {
	setIsHostEditingEvent,
} from '../redux/getEventSlice';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import styled from 'rn-css';
import { isEventDone } from '../utils/statusOfEvent/isEventDone';
import { EventCardTag } from '../components/common/event-card/EventCardTag';
import { isEventUpcoming } from '../utils/statusOfEvent/isEventUpcoming';
import { isEevntOngoing } from '../utils/statusOfEvent/isEventOngoing';
import EventVideo from '../components/common/video-play/Video';
import { Popable } from 'react-native-popable';
import { useEventDescription } from '../hooks/handleEvent/useEventDescription';

type Props = {
	route: TODO;
};

const StyledText = styled.Text`
	padding-bottom: 7;
	color: blue;
	margin-right: ${getResponsiveStyle(16)}px;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		border-bottom: 1px solid blue;
		color: blue;
	}
`;

function EventDescriptionScreen(props: Props) {
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();

	const {
		SECTION,
		isLessThanDesktopBase,
		isDesktop,
		selectedTab,
		image,
		loggedInUserDetails,
		isLoadingProduct,
		isSubscribed,
		eventData,
		eventLoading,
		isShowViewProfileBtn,
		getGoogleLink,
		getOutlookLink,
		isAllowedToSeeLocation,
		isEventEnded,
		isAffiliatedHost,
		isLocationAvailable,
		handleEditEvent,
		onPress,
		setIsLoadingProduct,
		getTabs
	} = useEventDescription({
		eventId: props.route?.params?.eventId
	})

	const dispatch = useDispatch();

	const eventId = props.route?.params?.eventId;

	const event = eventData?.getEvent?.data;

	const getClosedTag = () => {
		return <EventCardTag tagType="closed" />;
	};
	const getOngoingTag = () => {
		return <EventCardTag tagType="ongoing" />;
	};
	const getUpcomingTag = () => {
		return <EventCardTag tagType="upcoming" />;
	};

	const Tag = (): JSX.Element | null => {
		if (isEventDone(event)) {
			return getClosedTag();
		} else if (isEevntOngoing(event)) {
			return getOngoingTag();
		} else if (isEventUpcoming(event)) {
			return getUpcomingTag();
		} else {
			return null;
		}
	};

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasLinks: false,
				hasSearch: false,
				hasGoBack: true,
				logoLocation: 'center',
			}}
		>
			{eventLoading && isLoadingProduct ? (
				<Loader fullView />
			) : event ? (
				Object.keys(event).length > 0 && (
					<ScrollView style={Styles.container}>
						<View style={{ marginBottom: getResponsiveStyle(21) }}>
							<DescriptionTabs
								onPress={onPress}
								selectedTab={selectedTab}
								tabs={getTabs(SECTION)}
								isAllowedLocation={
									isLocationAvailable() &&
									isAllowedToSeeLocation()
								}
							/>
						</View>
						{!!image && !!image?.src ? (
							<>
								{image.type === 'VIDEO' ? (
									<EventVideo
										data={image}
										containerStyles={{
											height: getResponsiveStyle(
												480,
												'dimensions'
											),
										}}
									/>
								) : (
									<Image
										source={{
											uri: image?.src,
										}}
										style={Styles.image}
									/>
								)}
							</>
						) : (
							<View
								style={{
									height: getResponsiveStyle(
										480,
										'dimensions'
									),
									borderRadius: 6,
									overflow: 'hidden',
								}}
							>
								<EventImagePlaceholder />
								<Tag />
							</View>
						)}

						<DescriptionHeader
							title={event.name}
							subtitle={t(event.typeOfEvent)}
							startDate={event.startingEventDateTime}
							endDate={event.endingEventDate}
							eventEnded={isEventEnded}
							eventId={eventId}
							isSubscribed={isSubscribed}
						/>
						{!isEventDone(event) && (
							<View style={{ justifyContent: 'space-between' }}>
								{/* <Text
									style={[
										Styles.freeShipping,
										isDesktop && { order: 2 },
									]}
								>
									{t(
										'Free delivery for purchases over 59€ in France. Safe and secure payment - 3D secure.'
									)}
								</Text> */}
								<View
									style={[
										isDesktop && {
											width: getResponsiveStyle(150),
											marginTop: getResponsiveStyle(10),
										},
									]}
								>
									<Popable
										content={
											<>
												<Pressable
													style={
														Styles.calenderOptions
													}
													onPress={() =>
														getGoogleLink()
													}
												>
													<Text>Google</Text>
												</Pressable>
												<Pressable
													style={
														Styles.calenderOptions
													}
													onPress={() =>
														getOutlookLink()
													}
												>
													<Text>Outlook</Text>
												</Pressable>
											</>
										}
									>
										<Text style={[Styles.addToCalender]}>
											{t('Add to calendar')}
										</Text>
									</Popable>
								</View>
							</View>
						)}
						<View style={[Styles.layout, styleContainer()]}>
							<View style={Styles.content}>
								<View nativeID="Description">
									<DescriptionContent
										content={event.description}
									/>
								</View>
								<View nativeID="Products">
									<ProductScrollSection
										eventDetails={event}
										setIsLoadingProduct={
											setIsLoadingProduct
										}
									/>
								</View>
								{loggedInUserDetails?.typeOfAccount ===
									'SELLER' && (
										<View nativeID="Guest">
											<PeopleScrollSection
												invitedPeople={event.invitedPeople}
												handleEditEvent={text =>
													handleEditEvent(text)
												}
												isShowInviteLink={
													isAffiliatedHost() &&
													!isEventEnded()
												}
												isShowViewProfileBtn={
													isShowViewProfileBtn
												}
											/>
										</View>
									)}

								{event.typeOfEvent === 'PHYSICAL' && (
									<View style={Styles.spacing}>
										{isLocationAvailable() &&
											isAllowedToSeeLocation() && (
												<>
													<View nativeID="Location">
														<LocationSection />
													</View>
													{isAffiliatedHost() &&
														!isEventEnded() && (
															<Pressable
																onPress={() => {
																	handleEditEvent(
																		'EventLocation'
																	);
																	dispatch(
																		setIsHostEditingEvent()
																	);
																}}
															>
																<StyledText>
																	{t(
																		'Change Address'
																	)}
																</StyledText>
															</Pressable>
														)}
												</>
											)}
									</View>
								)}

								{/* <DiscussionSection /> */}
							</View>
							{!isLessThanDesktopBase && (
								<>
									<View
										style={[
											Styles.layoutDivider,
											styleContainerDivider(),
										]}
									></View>
									<View
										style={[
											Styles.sidebar,
											styleContainerSidebar(),
										]}
									>
										<DetailSidebar />
									</View>
								</>
							)}
						</View>
					</ScrollView>
				)
			) : (
				<Text>{t('Event not found')}</Text>
			)}
		</DesktopTemplate>
	);
}

const Styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	image: {
		width: '100%',
		height: getResponsiveStyle(480),
		resizeMode: 'cover',
		borderRadius: 6,
	},
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		// marginTop: 40,
	},
	content: {
		flex: 1,
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},
	sidebar: {
		flexBasis: getResponsiveStyle(312),
		flexGrow: 0,
		flexShrink: 0,
		// flex: "0 0 312px"
	},
	editText: {
		fontSize: getResponsiveStyle(16, 'font'),
		fontWeight: '600',
		color: 'blue',
		textDecoration: 'underline',
	},
	spacing: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	freeShipping: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: 'normal',
		color: palettes.dark[2],
		marginTop: getResponsiveStyle(10),
	},
	addToCalender: {
		paddingTop: getResponsiveStyle(7),
		paddingBottom: getResponsiveStyle(7),
		paddingRight: getResponsiveStyle(5),
		paddingLeft: getResponsiveStyle(5),
		backgroundColor: palettes?.red[0],
		fontSize: getResponsiveStyle(14, 'font'),
		color: palettes?.light[0],
		borderRadius: 6,
		textAlign: 'center',
	},
	calenderOptions: {
		padding: 10,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
	},
});

export default EventDescriptionScreen;

function styleContainer() {
	return {
		marginTop: getResponsiveStyle(40),
		// gap: getResponsiveStyle(32),
	};
}
function styleContainerDivider() {
	return {
		marginLeft: getResponsiveStyle(40),
		// gap: getResponsiveStyle(32),
	};
}
function styleContainerSidebar() {
	return {
		paddingHorizontal: getResponsiveStyle(32),
		// gap: getResponsiveStyle(32),
	};
}
