import * as React from 'react';
import { Pressable } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';
import { palettes } from '../../../config';

const ArrowIcon = (props: SvgProps) => (
	<Pressable>
		<Svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
			<Path
				d="M6.73716 3.14804L6.56645 2.97733V3.21875V11.2334H5.43312V3.21875V2.97733L5.26241 3.14804L1.75712 6.65333L0.955874 5.85208L5.99979 0.808169L11.0437 5.85208L10.2425 6.65333L6.73716 3.14804Z"
				fill={props.color || palettes.green[0]}
			/>
		</Svg>
	</Pressable>
);

export default ArrowIcon;
