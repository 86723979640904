import React, { useCallback, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Input } from '../..';
import { palettes } from '../../../config';
import { setaffiliatedBrands } from '../../../redux/createEventSlice';
import { useAppDispatch } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { MagnifierGlassIcon } from '../../common/icons';
import Loader from '../../common/loader/Loader';
import { DropdownCompanyProfile } from './DropdownCompanyProfile';

type Props = {
	onPressToSelectBrand: (brandSelected: TGetAllBrandsData) => void;
	allBrands: TGetAllBrandsResponse | undefined;
	loading: boolean;
	setSelectedBrands: React.Dispatch<
		React.SetStateAction<TGetAllBrandsData[]>
	>;
	selectedBrands: TGetAllBrandsData[];
};

export const EventBrandSearch = ({
	onPressToSelectBrand,
	allBrands: allBrandsProps,
	loading,
	setSelectedBrands,
	selectedBrands,
}: Props) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const [allBrands, setAllBrands] = useState<TGetAllBrandsData[]>();

	useEffect(() => {
		if (
			!!allBrandsProps &&
			!!allBrandsProps.getAllBrands &&
			!!allBrandsProps.getAllBrands.data &&
			!!allBrandsProps.getAllBrands.data.data
		) {
			setAllBrands(allBrandsProps?.getAllBrands.data?.data);
		}
	}, [allBrandsProps]);

	const onClickAddBrand = (brand: TGetAllBrandsData) => {
		dispatch(setaffiliatedBrands(brand?._id));
		setSelectedBrands([brand]);
		setIsDropdownOpen(false);
	};

	const onChangeSearchValue = (text: string) => {
		if (
			allBrandsProps &&
			allBrandsProps.getAllBrands &&
			allBrandsProps.getAllBrands.data &&
			allBrandsProps.getAllBrands.data.data
		) {
			setAllBrands(
				allBrandsProps.getAllBrands.data?.data.filter(({ name }) =>
					name.toLowerCase().includes(text)
				)
			);
		}
	};

	return (
		<ClickAwayListener
			onClickAway={() => {
				isDropdownOpen && setIsDropdownOpen(false);
			}}
		>
			<View style={styles.container}>
				<Input
					placeholder={t('Find Brands')}
					containerStyles={{ flex: 1 }}
					onChangeText={onChangeSearchValue}
					onFocus={() => {
						!isDropdownOpen && setIsDropdownOpen(true);
					}}
					decoration={
						<MagnifierGlassIcon
							height={getResponsiveStyle(20, 'dimensions')}
							width={getResponsiveStyle(20, 'dimensions')}
						/>
					}
				/>
				{isDropdownOpen && (
					<ScrollView
						style={[
							styles.dropdownContainer,
							styleDropdownContainer(),
						]}
					>
						{loading ? (
							<Loader size={'small'} />
						) : (
							!!allBrands &&
							allBrands
								.filter(
									brand =>
										!selectedBrands.some(
											selectedBrand =>
												selectedBrand._id === brand._id
										)
								)
								.map(brand => (
									<DropdownCompanyProfile
										key={brand._id}
										_id={brand._id}
										image={brand.profileImage}
										countryCode={
											brand?.address?.countryCode
										}
										name={brand.name}
										location={`${brand?.address?.address1}, ${brand?.address?.city}`}
										rating={
											brand?.rating ? +brand?.rating : 0
										}
										actionLabel={t('Add')}
										onPressActionButton={() =>
											onClickAddBrand(brand)
										}
										onPressContainer={() =>
											onPressToSelectBrand(brand)
										}
									/>
								))
						)}
					</ScrollView>
				)}
			</View>
		</ClickAwayListener>
	);
};

const styles = StyleSheet.create({
	container: {
		position: 'relative',
		width: '100%',
	},
	dropdownContainer: {
		width: '100%',
		position: 'absolute',
		height: 'max-content',
		borderRadius: 6,
		backgroundColor: 'white',
		zIndex: 5001,
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
		borderWidth: 1,
		borderColor: palettes.grey[3],
	},
});

function styleDropdownContainer() {
	return {
		top: getResponsiveStyle(64, 'dimensions'),
		minHeight: getResponsiveStyle(100, 'dimensions'),
		maxHeight: getResponsiveStyle(365, 'dimensions'),
		paddingVertical: getResponsiveStyle(16),
	};
}
