import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { useGetAllEvents } from '../api/events/useGetAllEvents';
import { Loader, PageDetailsHero } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ProfileAccounts from '../components/profile/Profile-Accounts/ProfileAccounts';
import { IS_SUBSCRIBED_TO_EVENTS } from '../graphql/eventSubscription/queries';
import {
	FOLLOW,
	FOLLOW_ME,
	UNFOLLOW,
	UNFOLLOW_ME,
} from '../graphql/follow/mutations';
import {
	IS_FOLLOWING_ME_REQUEST_SENT,
	IS_UNFOLLOWING_ME_REQUEST_SENT,
} from '../graphql/follow/queries';
import { IS_FOLLOWING, IS_FOLLOWING_ME } from '../graphql/network/queries';
import { GET_USER_BY_ID } from '../graphql/profile/queries';
import { GET_USER_STATS } from '../graphql/user/queries';
import { useGetRating } from '../hooks/useGetRating';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useSetAlert } from '../hooks/useSetAlerts';
import { setEventIds } from '../redux/getEventSlice';
import { useAppSelector, useAppDispatch } from '../redux/store';
import { TUseNavigation, TUseRoute } from '../types/exportedTypes';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import { shortenName } from '../utils/manipulateString';
import { setCurrentScreen } from '../redux/uiSlice';

const UserProfileScreen = () => {
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();
	const dispatch = useAppDispatch();
	const [AccStats, setAccStats] = useState<UserDashboardStats>();

	const getUserStat = (userType: TTypeOfAccount) => {
		switch (userType?.toUpperCase()) {
			case 'HOST':
				return HOST_STATS;
			case 'SELLER':
				return SELLER_STATS;
			case 'CONSUMER':
				return CONSUMER_STATS;
			case 'BRAND':
				return BRAND_STATS;
			default:
				return [];
		}
	};
	const navigation = useNavigation<TUseNavigation>();
	const { setAlert } = useSetAlert();

	const [userDetails, setUserDetails] = useState<
		TCommonResponseData | undefined
	>();

	const [isVisitingOtherUserProfile, setIsVisitingOtherUserProfile] =
		useState(true);

	const [isUserFollowing, setIsUserFollowing] = useState(false);
	const [isUserFollowingMe, setIsUserFollowingMe] = useState(false);
	const [isFollowMeRequestSent, setIsFollowMeRequestSent] = useState(false);
	const [isSubscribedArray, setIsSubscribedArray] = useState<
		isSubscribedResponse[]
	>([]);
	const route = useRoute<TUseRoute<'UserProfile'>>();

	// const { loading: eventLoading, data: eventData } = useQuery(GET_ALL_EVENTS);
	// const eventRecords = eventData?.getAllEvents?.data?.data;

	const {
		executeEventsQuery,
		data: eventFindData,
		loading: eventLoading,
	} = useGetAllEvents();

	const eventFindParam = useCallback(
		(userType?: TTypeOfAccount) => {
			switch (userType?.toUpperCase()) {
				case 'HOST':
					return {
						affiliatedHosts: route?.params?.userId,
						isHostApplicationAccepted: true,
					};
				case 'SELLER':
					return {
						affiliatedSeller: route?.params?.userId,
					};
				case 'CONSUMER':
					return {
						invitedPeople: route?.params?.userId,
					};
				default:
					return {};
			}
		},
		[route?.params?.userId]
	);

	useEffect(() => {
		if (userDetails?.typeOfAccount)
			executeEventsQuery(
				eventFindParam(userDetails?.typeOfAccount) as TGetAllEventFind
			);
	}, [userDetails?.typeOfAccount, route?.params?.userId]);

	const eventRecords = eventFindData?.getAllEvents?.data?.data;
	const SELLER_STATS = [
		{
			label: t('Events'),
			value: eventRecords?.length ?? 0,
		},
		// {
		// 	label: t('Views'),
		// 	value: 453,
		// },

		{
			label: t('Followers'),
			value: AccStats?.followersCount ?? 0,
		},
	];

	const HOST_STATS = [
		{
			label: t('Events'),
			value: eventRecords?.length ?? 0,
		},
		{
			label: t('Followers'),
			value: AccStats?.followersCount ?? 0,
		},
	];

	const CONSUMER_STATS = [
		{
			label: t('Events'),
			value: eventRecords?.length ?? 0,
		},
	];

	const BRAND_STATS = [
		{
			label: t('Followers'),
			value: AccStats?.followersCount ?? 0,
		},
		// {
		// 	label: t('Views'),
		// 	value: 453,
		// },

		{
			label: t('Events'),
			value: AccStats?.eventsCount ?? 0,
		},
	];

	const rating = useGetRating(route?.params?.userId as string);
	const [isSubscribedToEvent] = useLazyQuery(IS_SUBSCRIBED_TO_EVENTS, {
		onCompleted: data =>
			setIsSubscribedArray(data?.isSubscribedToEvent?.data?.data),
	});

	useEffect(() => {
		dispatch(setCurrentScreen('/user-select'));
	}, []);

	useEffect(() => {
		let eventIds: string[] = [];
		eventRecords?.map(event => eventIds.push(event._id));
		dispatch(setEventIds(eventIds));
		isSubscribedToEvent({
			variables: {
				eventIds,
			},
		});
	}, [eventRecords]);

	useEffect(() => {
		if (
			route?.params?.userId
			// && userDetails === undefined
		) {
			isFollowing({
				variables: {
					args: {
						followedIds: [route?.params.userId],
						type: 'USER',
					},
				},
			});

			getIfRequestHaveBeenSent();
			isFollowingMe();

			getUserById({
				variables: {
					_id: route?.params.userId,
				},
			});
			getUserStats({
				variables: {
					_id: route?.params.userId,
				},
			});
		} else {
			setIsVisitingOtherUserProfile(false);
		}
	}, [route?.params?.userId]);

	const [getUserStats] = useLazyQuery(GET_USER_STATS, {
		onCompleted: data => {
			if (
				data &&
				data.getUserDashboardStats.success &&
				data.getUserDashboardStats.data
			) {
				setAccStats(data.getUserDashboardStats.data);
			}
		},
	});
	const [isFollowing] = useLazyQuery<
		TIsFollowingResponse,
		TIsFollowingInputs
	>(IS_FOLLOWING, {
		onCompleted: data => {
			if (data && data.IsFollowing && data.IsFollowing.success) {
				data.IsFollowing.data?.data[0].isFollowing === true
					? setIsUserFollowing(true)
					: setIsUserFollowing(false);
			}
		},
	});
	const [isFollowingMe] = useLazyQuery(IS_FOLLOWING_ME, {
		variables: {
			args: {
				followeMeIds: [userDetails?._id],
			},
		},

		onCompleted: data => {
			if (data && data.IsFollowingMe && data.IsFollowingMe.success) {
				data.IsFollowingMe?.data?.data[0]?.isFollowingMe === true
					? setIsUserFollowingMe(true)
					: setIsUserFollowingMe(false);
			}
		},
	});

	const userDetailsNotFound = () => {
		setAlert(t('User not found!'), 'danger');
		navigation.navigate('Home');
	};

	const ids = useAppSelector(state => state.user.followedIds);
	const netWorkFollowIds = useAppSelector(
		state => state.user.networkFollowedIds
	);

	const [getUserById, { loading: userLoading }] =
		useLazyQuery<TGetUserByIdResponse>(GET_USER_BY_ID, {
			onCompleted: data => {
				if (!!data) {
					data?.getUserById?.data?.personalInformation !== null &&
						setUserDetails(data.getUserById.data);
				} else {
					userDetailsNotFound();
				}
			},
			onError: () => {
				userDetailsNotFound();
			},
		});

	const [followAccount, { loading: loadingFollow }] = useMutation<
		TFollowResponse,
		TFollowInputs
	>(FOLLOW, {
		onCompleted: data => {
			if (data.Follow.success && data.Follow.statusCode === 200) {
				setIsUserFollowing(true);
			}
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: IS_FOLLOWING,
				variables: {
					args: {
						followedIds: netWorkFollowIds.length
							? netWorkFollowIds
							: ids,
						type: 'USER',
					},
				},
			},
			GET_USER_STATS,
		],
	});
	const refetchRequestSentStatusQuery = [
		{
			query: IS_FOLLOWING_ME_REQUEST_SENT,
			variables: {
				args: {
					ids: [userDetails?._id] ?? [],
				},
			} as TIsFollowMeRequestSentInputs,
		},
		{
			query: IS_UNFOLLOWING_ME_REQUEST_SENT,
			variables: {
				args: {
					ids: [userDetails?._id] ?? [],
				},
			} as TIsFollowMeRequestSentInputs,
		},
	];

	const [followMe, { loading: loadingFollowMe }] = useMutation<
		TFollowMeResponse,
		TFollowMeInputs
	>(FOLLOW_ME, {
		awaitRefetchQueries: true,
		refetchQueries: refetchRequestSentStatusQuery,
		onCompleted: data => {
			if (data.FollowMe.success && data.FollowMe.statusCode === 200) {
				// setIsUserFollowingMe(true);
				setIsFollowMeRequestSent(true);
				setAlert(t('Follow request sent!'), 'normal');
			} else {
				if (
					data?.FollowMe?.statusCode === 400 &&
					data?.FollowMe?.message === 'User already follows you'
				) {
					setAlert(t('User already follows you'), 'danger');
				} else {
					setAlert(t('Unable to send follow request!'), 'danger');
				}
			}
		},
		onError: () => {
			setAlert(t('Unable to send follow request!'), 'danger');
		},
	});

	const [unFollowAccount, { loading: loadingUnfollow }] = useMutation<
		TUnfollowResponse,
		TUnfollowInputs
	>(UNFOLLOW, {
		onCompleted: data => {
			if (data.UnFollow.success && data.UnFollow.statusCode === 200) {
				setIsUserFollowing(false);
			}
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: IS_FOLLOWING,
				variables: {
					args: {
						followedIds: netWorkFollowIds.length
							? netWorkFollowIds
							: ids,
						type: 'USER',
					},
				},
			},
			GET_USER_STATS,
		],
	});

	const [unFollowMe, { loading: loadingUnfollowMe }] = useMutation<
		TUnfollowMeResponse,
		TUnfollowMeInputs
	>(UNFOLLOW_ME, {
		awaitRefetchQueries: true,
		refetchQueries: refetchRequestSentStatusQuery,
		onCompleted: data => {
			if (data.UnFollowMe.success && data.UnFollowMe.statusCode === 200) {
				setAlert(
					t('Successfully removed user from your followers'),
					'normal'
				);
				setIsUserFollowingMe(false);
			} else {
				setAlert(
					t('Unable to remove user from your followers'),
					'danger'
				);
			}
		},
		onError: () => {
			setAlert(t('Unable to remove user from your followers'), 'danger');
		},
	});
	const [
		getIfRequestHaveBeenSent,
		{ loading: loadingIsFollowingMeRequestSent },
	] = useLazyQuery<
		TIsFollowMeRequestSentResponse,
		TIsFollowMeRequestSentInputs
	>(IS_FOLLOWING_ME_REQUEST_SENT, {
		variables: {
			args: {
				ids: [userDetails?._id],
			},
		},
		onCompleted: data => {
			if (
				data.IsFollowMeRequestSent.data &&
				data.IsFollowMeRequestSent.success
			) {
				data.IsFollowMeRequestSent.data.data[0].isFollowRequestSent ===
				true
					? setIsFollowMeRequestSent(true)
					: setIsFollowMeRequestSent(false);
			}
		},
		onError: () => {},
	});

	const onClickFollow = (followedId: string) => {
		const variables = {
			args: {
				followedId,
			},
		};

		isUserFollowing
			? unFollowAccount({ variables })
			: followAccount({ variables });
	};
	const onClickFollowMe = (recipientId: string) => {
		const variables = {
			args: {
				recipientId,
			},
		};

		isUserFollowingMe ? unFollowMe({ variables }) : followMe({ variables });
	};

	const loadingState = getSingleLoadingState(
		loadingFollow,
		loadingUnfollow,
		loadingFollowMe,
		loadingUnfollowMe
	);

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasLinks: true,
				hasSearch: true,
				hasGoBack: false,
				logoLocation: 'left',
			}}
		>
			{userLoading ? (
				<Loader />
			) : !!userDetails ? (
				<>
					<PageDetailsHero
						_id={userDetails._id}
						profileImage={
							userDetails?.personalInformation?.profileImageLink
						}
						typeOfViewingAccount={userDetails.typeOfAccount}
						rating={rating}
						city={userDetails?.personalInformation?.address?.city}
						countryCode={
							userDetails?.personalInformation?.address
								?.countryCode
						}
						name={shortenName(userDetails.username) as string}
						key={'Profile'}
						showTabs={false}
						stats={getUserStat(userDetails?.typeOfAccount)}
						isProfile={false}
						isUser={false}
						verified={userDetails?.isVerified}
						followUser={
							userDetails?.typeOfAccount === 'CONSUMER'
								? onClickFollowMe
								: onClickFollow
						}
						followLoading={loadingState}
						isUserFollowed={isUserFollowing}
						isUserFollowingMe={isUserFollowingMe}
						isFollowMeRequestSent={isFollowMeRequestSent}
					/>

					<ProfileAccounts
						isVisiting={isVisitingOtherUserProfile}
						events={eventRecords}
						isSubscribedArray={isSubscribedArray}
						userType={userDetails?.typeOfAccount}
						eventLoading={eventLoading}
					/>
				</>
			) : (
				<Text>{t('User not found!')}</Text>
			)}
		</DesktopTemplate>
	);
};

export default UserProfileScreen;
