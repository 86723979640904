import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { Button } from '../components';
import { TUseRoute } from '../types/exportedTypes';
import { View } from 'react-native';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { FACEBOOK_CONFIG_ID } from '../constants/KEYS';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase';
import { Text } from 'react-native';
import { palettes } from '../config';

const MobileFacebookAuth = () => {
	const [response, setResponse] = useState<any>()
	const routes = useRoute<TUseRoute<'MobileFacebookAuth'>>();

	const routeParam = routes.params;

	const handleGetFacebookAuth = () => {
		if (routeParam?.identifier) {
			(window as any)?.FB.getLoginStatus(function (response: any) {
				if (response.status === 'connected') {
					setResponse(response)
					addDoc(
						collection(
							db,
							'facebookAuth',
							routeParam?.identifier,
							'token'
						),
						{
							response: response || {}
						}
					);
				} else {
					(window as any)?.FB?.login(
						(response: any) => {
							console.log({
								facebookResponse:
									response,
							});
							if (response?.authResponse) {
								setResponse(response)
								addDoc(
									collection(
										db,
										'facebookAuth',
										routeParam?.identifier,
										'token'
									),
									{
										response: response || {}
									}
								);
							}
						},
						{
							config_id: FACEBOOK_CONFIG_ID,
						}
					);
				}
			});
		}
	}
	return (
		<DesktopTemplate
			navigationBarProps={{
				hasLinks: false,
				hasSearch: false,
				hasGoBack: true,
				logoLocation: 'center',
				hasNoLoginNav: true
			}}
		>
			<View style={{
				justifyContent: "center",
				alignItems: "center",
				height: "100vh"
			}}>
				{response?.authResponse ?
					<Text style={{ fontSize: 24, color: palettes.green[0], fontWeight: 700 } as any}>Step completed, Please head back to the app to proceed.</Text>
					:
					<Button
						title="Authenticate Facebook."
						variant="primary"
						size="lg"
						onPress={handleGetFacebookAuth}
					/>
				}
				{/* <Button
					title="Back to app."
					variant="primary"
					size="lg"
					onPress={() => {
						window.location.href = "net.ocpusapp.android"
					}}
				/> */}
			</View>
		</DesktopTemplate>
	);
};

export default MobileFacebookAuth;
