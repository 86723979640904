import { useMutation } from '@apollo/client';
import { CREATE_COMMENT } from '../../graphql/comment/mutation';

const useCreateComment = () => {
	const [createComment, states] = useMutation(CREATE_COMMENT);

	const executeCreateCommentMutation = ({
		commentType,
		parentId,
		content,
	}: {
		commentType: string;
		parentId: string;
		content: string;
	}) => {
		createComment({
			variables: {
				commentType,
				parentId,
				content,
			},
		});
	};
	return { executeCreateCommentMutation, ...states };
};

export default useCreateComment;
