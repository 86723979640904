import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { LOGOUT } from '../../graphql/auth/queries';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { resetState as resetAuth } from '../../redux/authSlice';
import { useAppDispatch } from '../../redux/store';
import { resetState, setGlobalLoading } from '../../redux/uiSlice';
import { TUseNavigation } from '../../types/exportedTypes';

const useLogout = () => {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();

	const navigation = useNavigation<TUseNavigation>();

	const onCompleted = (data: TLogoutResponse) => {
		if (data.logout.success) {
			setAlert(data.logout.message, 'normal');
			sessionStorage.removeItem('userLoggedIn');
			dispatch(resetState());
			dispatch(resetAuth());
			navigation.navigate('Login');
		} else {
			setAlert(data.logout.message, 'danger');
		}
	};

	const [logout] = useLazyQuery<TLogoutResponse>(LOGOUT, {
		onCompleted,
	});

	const executeLogoutQuery = () => {
		dispatch(setGlobalLoading(true));
		logout();
	};

	return { executeLogoutQuery };
};

export default useLogout;
