import {
	resetAffiliatedBrands,
	resetAffiliatedHosts,
	resetEventMedia,
	resetInvitedPeople,
	setaffiliatedBrands,
	setAffiliatedHosts,
	setEventDetails,
	setEventLocation,
	setEventMedia,
	setEventTimeAndDuration,
	setInvitedPeople,
} from '../redux/createEventSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { tConvert } from '../utils/monthsUtils';

export const useUpdateEvent = () => {
	const event = useAppSelector(state => state.getEvent.event);

	const dispatch = useAppDispatch();
	const runUpdateEvent = () => {
		const date = new Date(+event.startingEventDateTime);
		const startDateGMT = date.toString().split(' ');

		if (event.typeOfEvent === 'PHYSICAL') {
			const {
				address1,
				address2,
				city,
				zipCode,
				countryCode,
				countryLabel,
			} = event?.eventLocation;
			const eventLocation = {
				address1: address1,
				address2: address2,
				city: city,
				zipCode: zipCode,
				countryCode: countryCode,
				countryLabel: countryLabel,
			};
			dispatch(setEventLocation(eventLocation as TCommonAddressInput));
		}

		dispatch(
			setEventDetails({
				description: event.description,
				name: event.name,
			})
		);
		const { contructedTime, startDate } = tConvert(date, startDateGMT);
		dispatch(
			setEventTimeAndDuration(
				// event.startingEventDateTime
				`${startDate} ${contructedTime[0]}:${contructedTime[1]}`
			)
		);
		dispatch(resetInvitedPeople([]));
		event.invitedPeople.map(userToInvite => {
			dispatch(setInvitedPeople(userToInvite._id));
		});
		dispatch(resetAffiliatedBrands([]));
		event.affiliatedBrands.map(affiliatedBrand => {
			dispatch(setaffiliatedBrands(affiliatedBrand._id));
		});
		dispatch(resetAffiliatedHosts([]));
		event.affiliatedHosts.map(selectedHostToInvite => {
			dispatch(setAffiliatedHosts(selectedHostToInvite?._id ?? ''));
		});
		dispatch(resetEventMedia([]));
		event.eventMedia.map(media => dispatch(setEventMedia(media)));
	};
	return { runUpdateEvent };
};
