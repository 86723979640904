import { currentTimeGreaterThanBuyingPeriod } from './currentTimeGreaterThanBuyingPeriod';
import { currentTimeGreaterThanExpiry } from './currentTimeGreaterThanExpiry';

export const getStateOfEvent = (
	eventStreamTimeline: TEventStreamTimeline | null,
	startingEventDateTime: string
): TStateOfEvent => {
	const isCurrentTimeGreaterThanExpiry = currentTimeGreaterThanExpiry(
		startingEventDateTime
	);

	const currentTime = new Date().getTime();
	const thirtyMinuteLess = new Date(
		+startingEventDateTime - 1000 * 60 * 30
	).getTime();

	if (+thirtyMinuteLess > currentTime) {
		return 'NotStarted';
	}

	if (
		eventStreamTimeline?.startedAt === null &&
		isCurrentTimeGreaterThanExpiry
	) {
		return 'Expired';
	}

	if (
		eventStreamTimeline?.startedAt === null &&
		!isCurrentTimeGreaterThanExpiry
	) {
		return 'Upcoming';
	}

	const isCurrentTimeGreaterThanBuyingPeriod =
		currentTimeGreaterThanBuyingPeriod(startingEventDateTime);

	if (
		eventStreamTimeline?.startedAt !== null &&
		!isCurrentTimeGreaterThanBuyingPeriod
	) {
		return 'Ongoing';
	}

	// if (
	// 	eventStreamTimeline?.startedAt &&
	// 	eventStreamTimeline?.endedAt &&
	// 	!isCurrentTimeGreaterThanBuyingPeriod
	// ) {
	// 	return 'Streamed';
	// }

	if (
		// !eventStreamTimeline?.startedAt &&
		// !eventStreamTimeline?.endedAt &&
		isCurrentTimeGreaterThanBuyingPeriod
	) {
		return 'Completed';
	}

	return 'Expired';
};
