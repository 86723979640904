import dayjs from 'dayjs';

export function currentTimeGreaterThanBuyingPeriod(
	eventStreamTimeline: string
) {
	const buyingPeriod = dayjs(Number(eventStreamTimeline)).add(7, 'days');
	const currentTimeGreaterThanBuyingPeriod = dayjs().isAfter(buyingPeriod);
	return currentTimeGreaterThanBuyingPeriod;
}

export function currentTimeGreaterThanBuying(eventStreamTimeline: string) {
	const buyingPeriod = dayjs(Number(eventStreamTimeline)).add(8, 'hours');
	const currentTimeGreaterThanBuyingPeriod = dayjs().isAfter(buyingPeriod);
	return currentTimeGreaterThanBuyingPeriod;
}
