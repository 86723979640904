import React from 'react';
import { Pressable } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

const InboxIcon = ({
	onPress,
	outlineColor = 'rgba(152,162,178,1)',
	...props
}: SvgProps & { outlineColor?: string }) => {
	return (
		<Pressable onPress={onPress}>
			<Svg viewBox="0 0 24 24" {...props}>
				<Path fill="none" d="M0 0h24v24H0z" />
				<Path
					d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 11h-3.416a5.001 5.001 0 0 1-9.168 0H4v5h16v-5zm0-2V5H4v7h5a3 3 0 0 0 6 0h5z"
					fill={outlineColor}
				/>
			</Svg>
		</Pressable>
	);
};

export default InboxIcon;
