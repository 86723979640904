import { currentTimeGreaterThanBuyingPeriod } from '../../../../utils/handleNavigationToEvent/currentTimeGreaterThanBuyingPeriod';
import {
	TInitialLoadIVSPlayerLoadingPayload,
	TLiveEventState,
	TRootActionState,
} from './LiveEventSection.types';
import { produce } from 'immer';
import { Reducer } from 'react';

export const liveEventInitialState: TLiveEventState = {
	ivsPlayerInstance: undefined,
	selectedSectionTab: 'Live',
	recordingId: '',
	playerScriptLoaded: false,
	selectedParticipantTab: 'Chat',
	isLiveStreaming: false,
	leftCall: false,
	isShowingRatingModal: false,
	loadingLiveStreaming: false,
	loadingCamera: false,
	loadingDaily: false,
	isHostOrSellerInMeeting: false,
	isHostOrSellerActiveForConsumer: false,
	isVolumeDisabledForConsumer: false,
	isLiveStreamForConsumerIdle: false,
	loadingPlayerIvs: false,
	dailyJsObject: null,
	callItems: {
		local: {
			audioTrackState: null,
			videoTrackState: null,
		},
	},
	dailyJsState: 'STATE_IDLE',
};

export const liveEventReducer: Reducer<TLiveEventState, TRootActionState> =
	produce((state, action) => {
		switch (action.type) {
			case 'SET_CALL_ITEM':
				state.callItems = action.payload;
				break;
			case 'SELECT_SECTION_TAB':
				state.selectedSectionTab = action.payload;
				break;
			case 'SELECT_PARTICIPANT_TAB':
				state.selectedParticipantTab = action.payload;
				break;
			case 'JOIN_CALL':
				state.dailyJsObject = action.payload;
				state.dailyJsState = 'STATE_JOINING';
				break;
			case 'RECORDING_STARTED':
				state.recordingId = action.payload;
				break;
			case 'LEAVE_CALL_ERROR':
				state.dailyJsObject = null;
				state.isLiveStreaming = false;
				state.dailyJsState = 'STATE_IDLE';
				state.callItems = liveEventInitialState.callItems;
				break;
			case 'LEAVE_CALL':
				state.dailyJsState = 'STATE_LEAVING';
				state.isLiveStreaming = false;
				state.callItems = liveEventInitialState.callItems;
				break;
			case 'SET_IVS_PLAYER':
				state.ivsPlayerInstance = action.payload;
				break;
			case 'SET_LOADING_LIVE_STREAMING':
				state.loadingLiveStreaming = action.payload;
				break;
			case 'TOGGLE_RATING_MODAL':
				state.isShowingRatingModal = !state.isShowingRatingModal;
				break;
			case 'SET_IS_LIVESTREAM_IDLE_FOR_CONSUMER':
				state.isLiveStreamForConsumerIdle = action.payload;
				break;
			case 'SET_LOADING_BOOLEANS_ON_JOINING_ROOM':
				state.loadingCamera = true;
				state.loadingDaily = true;
				break;
			case 'ON_LOAD_IVS_PLAYER':
				state.playerScriptLoaded = true;
				break;
			case 'SET_IVS_PLAYER_LOADING_FALSE':
				state.loadingPlayerIvs = false;
				break;
			case 'IVS_PLAYER_VOLUME_CHANGE':
				state.isVolumeDisabledForConsumer =
					action.payload === 0 ? true : false;
				break;
			case 'INITIAL_LOAD_IVS_PLAYER_LOADING':
				state.loadingPlayerIvs = getInitialIVSPlayerLoadingState(
					action.payload
				);
				break;
			case 'DAILY_ON_JOIN_MEETING':
				state.isHostOrSellerInMeeting = true;
				state.dailyJsState = 'STATE_JOINED';
				break;
			case 'DAILY_ON_LEFT_MEETING':
				state.isHostOrSellerInMeeting = false;
				state.dailyJsObject = null;
				state.dailyJsState = 'STATE_IDLE';
				state.callItems = liveEventInitialState.callItems;
				break;
			case 'DAILY_ON_LIVE_STREAM_STARTED':
				state.isLiveStreaming = true;
				state.loadingLiveStreaming = false;
				break;
			case 'DAILY_ON_LIVE_STREAM_STOPPED':
				state.isLiveStreaming = false;
				state.loadingLiveStreaming = false;
				break;
			case 'DAILY_ON_LOADED':
				state.loadingDaily = false;
				break;
			case 'DAILY_ON_STARTED_CAMERA':
				state.loadingCamera = false;
				break;
			case 'LEAVE_CALL':
				state.leftCall = true;
				break;
			case 'DAILY_ON_LIVE_STREAMING_ERROR':
				state.isLiveStreaming = false;
				state.loadingLiveStreaming = false;
				state.callItems = liveEventInitialState.callItems;
				break;
			case 'DAILY_ON_CAMERA_ERROR':
				state.loadingCamera = false;
				state.loadingDaily = false;
				state.isHostOrSellerInMeeting = false;
				state.dailyJsState = 'STATE_ERROR';
				state.callItems = liveEventInitialState.callItems;
				break;
			case 'DAILY_ON_ERROR':
				state.isHostOrSellerInMeeting = false;
				state.loadingCamera = false;
				state.loadingDaily = false;
				state.dailyJsState = 'STATE_ERROR';
				state.callItems = liveEventInitialState.callItems;
				break;

			case 'HOST_OR_SELLER_ACTIVE_CONSUMER':
				state.isHostOrSellerActiveForConsumer = action.payload;
				break;
			default:
				state;
		}
	});

const getInitialIVSPlayerLoadingState = ({
	eventStreamTimeline,
	typeOfAccount,
}: TInitialLoadIVSPlayerLoadingPayload): boolean => {
	// there may be a bug. Needs more testing
	// especially !currentTimeGreaterThanBuyingPeriod(eventStreamTimeline)
	if (
		typeOfAccount === 'CONSUMER' &&
		!eventStreamTimeline?.endedAt &&
		!currentTimeGreaterThanBuyingPeriod(eventStreamTimeline)
	) {
		return true;
	} else {
		false;
	}
	return false;
};
