import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PROFILE_STATS } from '../../graphql/chat/queries';
import { useAppDispatch } from '../../redux/store';

const useGetProfileStats = () => {
	const dispatch = useAppDispatch();

	const [getProfileStats, { data, error, loading, client }] = useLazyQuery(
		GET_PROFILE_STATS,
		{
			fetchPolicy: 'no-cache',
		}
	);

	const executeProfileStatsQuery = ({ _id }: { _id: string }) => {
		getProfileStats({
			variables: {
				_id,
			},
		});
	};
	return { executeProfileStatsQuery, data, error, loading };
};

export default useGetProfileStats;
