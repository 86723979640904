import React from 'react';
import { Pressable } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { TStyle } from '../../../types/TStyle';

type Props = {
	containerStyles?: TStyle;
	onPress?: () => void;
	color?: string;
} & Omit<SvgProps, 'onPress' | 'color'>;

const PlayButton = ({ containerStyles, onPress, color, ...props }: Props) => {
	return (
		<Pressable style={containerStyles} onPress={onPress}>
			<Svg
				width={props.width || 13}
				height={props.height || 14}
				viewBox="0 0 13 14"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
			>
				<Path d="M0,0 L0,14 L11,7 L0,0 Z" fill={color || '#000000'} />
			</Svg>
		</Pressable>
	);
};

export default PlayButton;
