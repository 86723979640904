import { gql } from '@apollo/client';

export const GET_CART = gql`
	query getCart {
		getCart {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on Cart {
								_id
								items {
									optionValue
									type
									quantity
									price
									eventId {
										_id
										startingEventDateTime
										affiliatedHosts {
											_id
										}
										affiliatedSeller {
											_id
											email
											connectAccountInfo {
												connectAccountId
											}
										}
										eventStreamTimeline {
											startedAt
											endedAt
										}
										isHostRequired
										name
									}
									starterkitId {
										_id
										name
										description
										price
										brand {
											name
											_id
											connectAccountInfo {
												connectAccountId
												lastUrl
											}
										}
										variants {
											_id
											variator
											value

											image {
												src
											}
											option {
												name
												values {
													compareAtPrice
													price
													name
												}
												inventory {
													quantity
													isTracked
													isAvailable
												}
											}
											vat
											associatedProduct {
												name
												shortDescription
												description
												publishedStatus
												images {
													name
													alt
													src
												}
											}
										}
									}
									productId {
										_id
										variator
										value

										image {
											src
										}
										option {
											name
											values {
												compareAtPrice
												price
												name
											}
											variatorValues
											inventory {
												quantity
												isTracked
												isAvailable
											}
										}
										vat
										associatedProduct {
											name
											description
											category
											publishedStatus
											brand {
												name
												_id
												connectAccountInfo {
													connectAccountId
													lastUrl
												}
											}
											images {
												name
												alt
												src
												type
											}
										}
									}
								}

								associatedUser {
									_id
								}
							}
						}
					}
				}
			}
		}
	}
`;
