import { useQuery, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Loader, ProfileTableControl } from '../..';
import { palettes } from '../../../config';
import { GET_ALL_MEMBERS } from '../../../graphql/network/queries';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import { useTranslation } from 'react-i18next';
import { GET_USER_TABLE_STATS } from '../../../graphql/auth/queries';
import { useAppSelector, useAppDispatch } from '../../../redux/store';
import { manipulateString } from '../../../utils/manipulateString';
import LoadMore from '../../common/load-more/LoadMore';
import { usePagination } from '../../../hooks/useCurrentForPagination';
import { resetSort } from '../../../redux/sortSlice';
import { resetFilters } from '../../../redux/filterSlice';
import { sortCheck, sortValue } from '../../../utils/handleSort';

const AllMembers = () => {
	const { t } = useTranslation();
	const HEADERS = [
		{
			headerLabel: t('USERNAME'),
			width: '17.5%',
		},

		{
			headerLabel: t('PROFILE'),
			width: '10%',
		},
		{
			headerLabel: t('GENDER'),
			width: '10%',
		},
		{
			headerLabel: t('FOLLOWERS'),
			width: '10%',
		},
		{
			headerLabel: t('CITY'),
			width: '10%',
		},
		{
			headerLabel: t('ZIPCODE'),
			width: '10%',
		},
		{
			headerLabel: t('COUNTRY'),
			width: '10%',
		},
		{
			headerLabel: t('EVENTS ATTENDED'),
			width: '10%',
		},
	];
	const membersSortItem = [
		{
			label: `${t('Username')}: A-Z`,
			local: false,
			value: { member: { username: 1 } },
		},
		{
			label: `${t('Username')}: Z-A`,
			local: false,
			value: { member: { username: -1 } },
		},

		{
			label: `${t('City')}: A-Z`,
			local: false,
			value: {
				member: { personalInformation: { address: { city: 1 } } },
			},
		},
		{
			label: `${t('City')}: Z-A`,
			local: false,
			value: {
				member: { personalInformation: { address: { city: -1 } } },
			},
		},
		{
			label: `${t('Country')}: A-Z`,
			local: false,
			value: {
				member: {
					personalInformation: { address: { countryLabel: 1 } },
				},
			},
		},
		{
			label: `${t('Country')}: Z-A`,
			local: false,
			value: {
				member: {
					personalInformation: { address: { countryLabel: -1 } },
				},
			},
		},
		{
			label: `${t('Events Attended')}: ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'eventsAttended', value: -1 },
		},
		{
			label: `${t('Events Attended')}: ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'eventsAttended', value: 1 },
		},
		{
			label: `${t('Followers')}: ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'followers', value: -1 },
		},
		{
			label: `${t('Followers')}: ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'followers', value: 1 },
		},
	];
	const membersFilters = [
		{ label: t('Profile'), value: 'typeOfAccount' },
		{ label: t('City'), value: 'useraddcity' },
		{ label: t('Zip Code'), value: 'useraddzipCode' },
		{ label: t('Country'), value: 'useraddcountryCode' },
		{ label: t('Gender'), value: 'gender' },
		{ label: t('Interest tags'), value: 'userinterestTags' },
	];

	const [allMembers, setAllMembers] = useState<TGetAllMembersUser[]>([]);
	const { setAlert } = useSetAlert();
	const dispatch = useAppDispatch();
	const [user, filter, sort] = useAppSelector(state => {
		return [state.auth.loggedInUserDetails, state.filter, state.sort];
	});
	const LIMIT = 10;
	const [allMembersCurrent, increaseAllMembersCurrent, setMembersCurrent] =
		usePagination();
	const [total, setTotal] = useState<number>(0);
	const onPressViewMore = () => {
		const increasedCurrent = increaseAllMembersCurrent();
		refetchGetAllMembers({
			limit: LIMIT,
			current: increasedCurrent,
			find: {
				...filter,
			},
		});
	};

	const handleError = () => {
		setAlert(t('Unable to fetch members!'), 'danger');
	};

	const [getUsersStats, { loading: userStatLoading, data: userStatDetails }] =
		useLazyQuery(GET_USER_TABLE_STATS);

	const [
		getAllMembers,
		{
			loading: allMembersLoading,
			refetch: refetchGetAllMembers,
			networkStatus: allMemberStatus,
		},
	] = useLazyQuery<TGetAllMembersUserResponse>(GET_ALL_MEMBERS, {
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: async data => {
			if (data.getAllMembers.data && data.getAllMembers.success) {
				setTotal(data.getAllMembers.data.total);
				let memberData: any[] = data.getAllMembers.data.data;
				let val = await getUsersStats({
					variables: { id: memberData.map(user => user._id) },
				});
				let resp = val.data.getUserStats.data.data;
				memberData = memberData.map(member => ({
					profileImageLink:
						member.personalInformation?.profileImageLink || null,
					countryCode:
						member?.personalInformation?.address?.countryCode,
					username: member.username,
					profile:
						member.typeOfAccount === 'CONSUMER'
							? t('Guest')
							: t(
									manipulateString.capitalize(
										member?.typeOfAccount
									)
							  ),
					gender: t(manipulateString.capitalize(member?.gender)),
					followers:
						resp?.find(
							(val: { userId: string }) =>
								val.userId === member._id
						).followersCount || 0,
					city: member?.personalInformation?.address?.city,
					zipcode: member?.personalInformation?.address?.zipCode,
					countryLabel:
						member?.personalInformation?.address?.countryLabel,
					eventsAttended:
						resp?.find(
							(val: { userId: string }) =>
								val.userId === member._id
						).eventsCount || 0,

					_id: member._id,
				}));
				setAllMembers([...allMembers, ...memberData]);
			} else {
				handleError();
			}
		},
		onError: () => {
			handleError();
		},
	});
	useEffect(() => {
		dispatch(resetSort());
		dispatch(resetFilters());
	}, []);
	useEffect(() => {
		setAllMembers([]);
		setMembersCurrent(0);
		setTotal(0);
		getAllMembers({
			variables: {
				limit: LIMIT,
				current: allMembersCurrent,
				find: {
					...filter,
				},
				sort: { ...sortCheck(sort) },
			},
		});
	}, [filter, sort]);

	return (
		<View style={styles.container}>
			{(allMembersLoading || userStatLoading) &&
			allMembers.length <= 0 ? (
				<Loader />
			) : (
				<>
					<ProfileTableControl
						heading={t('All Members')}
						data={allMembers.sort((a, b) => sortValue(a, b, sort))}
						hasFilter
						filterItem={membersFilters}
						sortItem={membersSortItem}
						headers={HEADERS}
						type="member"
						profileButton={true}
						messageButton={
							user?.typeOfAccount !== 'CONSUMER' ? true : false
						}
					/>
					{allMembers.length < total && (
						<LoadMore
							loading={allMemberStatus === 2 || userStatLoading}
							onClick={onPressViewMore}
						/>
					)}
				</>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(24),
		paddingBottom: getResponsiveStyle(24),
	},
	tabContainer: {
		flexDirection: 'row',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header: {
		fontSize: getResponsiveStyle(24),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	tableContainer: {
		marginTop: getResponsiveStyle(19),
	},
});

export default AllMembers;
