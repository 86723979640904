import { Image, ImageProps, Pressable } from 'react-native';
import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const EmptyHeartIcon = ({ ...props }: SvgProps) => {
	return (
		<Pressable>
			<Svg viewBox="0 0 24 24" width={24} height={24} {...props}>
				<Path fill="none" d="M0 0h24v24H0z" />
				<Path
					d="M12.001 4.529a5.998 5.998 0 0 1 8.242.228 6 6 0 0 1 .236 8.236l-8.48 8.492-8.478-8.492a6 6 0 0 1 8.48-8.464zm6.826 1.641a3.998 3.998 0 0 0-5.49-.153l-1.335 1.198-1.336-1.197a3.999 3.999 0 0 0-5.494.154 4 4 0 0 0-.192 5.451L12 18.654l7.02-7.03a4 4 0 0 0-.193-5.454z"
					fill="rgba(152,162,178,1)"
				/>
			</Svg>
		</Pressable>
	);
};

export default EmptyHeartIcon;
