import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import expireReducer from 'redux-persist-expire';
import uiReducer from './uiSlice';
import eventProductReducer from './eventProductDetail';
import createUserReducer from './createUserSlice';
import createEventReducer from './createEventSlice';
import chatReducer from './chatSlice';
import authReducer from './authSlice';
import stripeidReducer from './stripeIdSlice';
import userCardsReducer from './getCardsSlice';
import userBanksReducer from './bankSlice';
import getEventReducer from './getEventSlice';
import walletReducer from './walletSlice';
import userReducer from './userSlice';
import socialAuthReducer from './socialAuth';
import loaderReducer from './loaderSlice';
import addHostToEventReducer from './addHostToEventSlice';
import statusPostReducer from './statusPostSlice';
import addFilterReducer from './filterSlice';
import addSortReducer from './sortSlice';
import tagReducer from './tagsSlice';
import oneStopCheckoutReducer from './oneStopCheckoutSlice';
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
	getStripeId: stripeidReducer,
	userBanks: userBanksReducer,
	userCards: userCardsReducer,
	ui: uiReducer,
	auth: authReducer,
	eventProduct: eventProductReducer,
	createUser: createUserReducer,
	createEvent: createEventReducer,
	getEvent: getEventReducer,
	chat: chatReducer,
	wallet: walletReducer,
	user: userReducer,
	socialAuth: socialAuthReducer,
	loader: loaderReducer,
	addHostToEvent: addHostToEventReducer,
	statusSlice: statusPostReducer,
	filter: addFilterReducer,
	sort: addSortReducer,
	oneStopCheckout: oneStopCheckoutReducer,
	tags: tagReducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === 'ui/resetState') {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

const persistConfig = {
	transforms: [
		expireReducer('auth', {
			expireSeconds: 60 * 60 * 24,
			autoExpire: true,
		}),
	],
	key: 'root',
	version: 1,

	// whitelist: ['ui', 'tags', 'auth'],
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
	devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
