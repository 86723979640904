import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import { Button, Input } from '../..';
import useGetRecommendedHost from '../../../api/events/useGetRecommendedHost';
import { VALIDATE_ZIPCODE_CITY } from '../../../graphql/events/queries';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useUpdateEvent } from '../../../hooks/useUpdateEvent';
import { setCatchmentAreas } from '../../../redux/createEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const validationSchema = yup.object({
	// address1: yup.string().required('Required'),
	// address2: yup.string(),
	city1: yup.string().required('Required'),
	zipCode1: yup.string().required('Required'),
	city2: yup.string(),
	zipCode2: yup.string(),
	city3: yup.string(),
	zipCode3: yup.string(),
	city4: yup.string(),
	zipCode4: yup.string(),
	city5: yup.string(),
	zipCode5: yup.string(),
});

const editValidationSchema = yup.object({
	city1: yup.string(),
	zipCode1: yup.string(),
	city2: yup.string(),
	zipCode2: yup.string(),
	city3: yup.string(),
	zipCode3: yup.string(),
	city4: yup.string(),
	zipCode4: yup.string(),
	city5: yup.string(),
	zipCode5: yup.string(),
});

type TInitialValues = yup.InferType<typeof validationSchema>;

const initialValues: TInitialValues = {
	// address1: '',
	// address2: '',
	city1: '',
	zipCode1: '',
	city2: '',
	zipCode2: '',
	city3: '',
	zipCode3: '',
	city4: '',
	zipCode4: '',
	city5: '',
	zipCode5: '',
};

const editInitialValues = (values: CatchmentArea[]) => {
	const initialValues = {
		city1: values?.[0]?.city,
		zipCode1: values?.[0]?.zipCode,
		city2: values?.[1]?.city,
		zipCode2: values?.[1]?.zipCode,
		city3: values?.[2]?.city,
		zipCode3: values?.[2]?.zipCode,
		city4: values?.[3]?.city,
		zipCode4: values?.[3]?.zipCode,
		city5: values?.[4]?.city,
		zipCode5: values?.[4]?.zipCode,
	};
	return initialValues;
};

const EventAreaForm = () => {
	const dispatch = useAppDispatch();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const { isEditEvent } = useAppSelector(state => state.getEvent);
	const [inputCount, setInputCount] = useState(1);
	const [catchmentAreasEdit, setCatchmentAreasEdit] = useState<
		CatchmentArea[]
	>([]);
	const event = useAppSelector(state => state.getEvent.event);
	const [dropdownCountryValue, setDropdownCountryValue] =
		useState<TDropdownObject>();
	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);
	const { t } = useTranslation();
	const { runUpdateEvent } = useUpdateEvent();
	const { setAlert } = useSetAlert();

	const catchmentAreas: any = event.catchmentAreas;

	const navigation = useNavigation<TUseNavigation>();

	const { executeRecommendedHostQuery } = useGetRecommendedHost();

	useEffect(() => {
		if (isEditEvent) {
			// runUpdateEvent();
			setInputCount(catchmentAreas.length);
			setCatchmentAreasEdit(catchmentAreas);
		}
	}, []);

	const incrementArea = (values: any) => {
		// setInputCount(inputCount + 1);
		validateZipCodeCity({
			variables: {
				args: {
					zipcodeAndCities: [
						{
							zipcode: values[`zipCode${inputCount}`],
							city: values[`city${inputCount}`],
						},
					],
				},
			},
			onCompleted: data => {
				if (
					data?.validateZipcode?.success &&
					data?.validateZipcode?.statusCode === 200
				) {
					setInputCount(inputCount + 1);
				} else {
					setAlert(data?.validateZipcode?.message, 'warning');
				}
			},
			onError: e => {
				setAlert('An error occurred', 'warning');
			},
		});
	};

	const [validateZipCodeCity] = useLazyQuery(VALIDATE_ZIPCODE_CITY);

	return (
		<Formik
			enableReinitialize
			initialValues={
				isEditEvent
					? editInitialValues(catchmentAreasEdit)
					: (initialValues as any)
			}
			validationSchema={
				isEditEvent ? editValidationSchema : validationSchema
			}
			onSubmit={
				values => {
					const areaArr = [
						{ city: values.city1, zipCode: +values.zipCode1 },
						{ city: values.city2, zipCode: +values.zipCode2 },
						{ city: values.city3, zipCode: +values.zipCode3 },
						{ city: values.city4, zipCode: +values.zipCode4 },
						{ city: values.city5, zipCode: +values.zipCode5 },
					];
					const areaDataArr = [
						{ city: values.city1, zipcode: values.zipCode1 },
						{ city: values.city2, zipcode: values.zipCode2 },
						{ city: values.city3, zipcode: values.zipCode3 },
						{ city: values.city4, zipcode: values.zipCode4 },
						{ city: values.city5, zipcode: values.zipCode5 },
					];

					areaDataArr.splice(inputCount, 5 - inputCount);

					validateZipCodeCity({
						variables: {
							args: {
								zipcodeAndCities: areaDataArr,
							},
						},
						onCompleted: async data => {
							if (
								data?.validateZipcode?.success &&
								data?.validateZipcode?.statusCode === 200
							) {
								// if (isAffiliatedHostEditingEvent) {
								// 	navigation.navigate('EventComplete');
								// 	return;
								// }
								const validArea = areaArr.filter(
									area => area.city && area.zipCode
								);

								dispatch(
									setCatchmentAreas(
										validArea as CatchmentArea[]
									)
								);
								executeRecommendedHostQuery({
									catchmentAreas:
										validArea as CatchmentArea[],
									current: 0,
									limit: 5,
								});

								goToNextStepScreen();
							} else {
								setAlert(
									data?.validateZipcode?.message,
									'warning'
								);
							}
						},
						onError: e => {},
					});
				}

				// const allEqual = arr => arr.every(v => v === arr[0]);
				// if(allEqual(nxtScreen)){
				// 	goToNextStepScreen();
				// }
			}
		>
			{({
				handleBlur,
				handleChange,
				handleSubmit,
				errors,
				touched,
				isValid,
				dirty,
				values,
			}) => (
				<View>
					<View style={styleRowContainer()}>
						<Input
							label={t('City') + '*'}
							containerStyles={styles.flex1}
							value={
								values.city1 || catchmentAreasEdit?.[0]?.city
							}
							onChangeText={handleChange('city1')}
							onBlur={handleBlur('city1')}
							error={
								touched.city1 && errors.city1
									? errors.city1
									: ''
							}
						/>
						<Input
							label={t('Zip Code') + '*'}
							containerStyles={styles.flex1}
							onChangeText={handleChange('zipCode1')}
							onBlur={handleBlur('zipCode1')}
							value={
								values.zipCode1 ||
								catchmentAreasEdit?.[0]?.zipCode
							}
							error={
								touched.zipCode1 && errors.zipCode1
									? errors.zipCode1
									: ''
							}
						/>
					</View>
					{inputCount > 1 && (
						<View style={styleRowContainer()}>
							<Input
								label={t('City') + '*'}
								containerStyles={styles.flex1}
								value={
									values.city2 ||
									catchmentAreasEdit?.[1]?.city
								}
								onChangeText={handleChange('city2')}
								onBlur={handleBlur('city2')}
								error={
									touched.city2 && errors.city2
										? errors.city2
										: ''
								}
							/>
							<Input
								label={t('Zip Code') + '*'}
								containerStyles={styles.flex1}
								onChangeText={handleChange('zipCode2')}
								onBlur={handleBlur('zipCode2')}
								value={
									values.zipCode2 ||
									catchmentAreasEdit?.[1]?.zipCode
								}
								error={
									touched.zipCode2 && errors.zipCode2
										? errors.zipCode2
										: ''
								}
							/>
						</View>
					)}
					{inputCount > 2 && (
						<View style={styleRowContainer()}>
							<Input
								label={t('City') + '*'}
								containerStyles={styles.flex1}
								value={
									values.city3 ||
									catchmentAreasEdit?.[2]?.city
								}
								onChangeText={handleChange('city3')}
								onBlur={handleBlur('city3')}
								error={
									touched.city3 && errors.city3
										? errors.city3
										: ''
								}
							/>
							<Input
								label={t('Zip Code') + '*'}
								containerStyles={styles.flex1}
								onChangeText={handleChange('zipCode3')}
								onBlur={handleBlur('zipCode3')}
								value={
									values.zipCode3 ||
									catchmentAreasEdit?.[2]?.zipCode
								}
								error={
									touched.zipCode3 && errors.zipCode3
										? errors.zipCode3
										: ''
								}
							/>
						</View>
					)}
					{inputCount > 3 && (
						<View style={styleRowContainer()}>
							<Input
								label={t('City') + '*'}
								containerStyles={styles.flex1}
								value={
									values.city4 ||
									catchmentAreasEdit?.[3]?.city
								}
								onChangeText={handleChange('city4')}
								onBlur={handleBlur('city4')}
								error={
									touched.city4 && errors.city4
										? errors.city4
										: ''
								}
							/>
							<Input
								label={t('Zip Code') + '*'}
								containerStyles={styles.flex1}
								onChangeText={handleChange('zipCode4')}
								onBlur={handleBlur('zipCode4')}
								value={
									values.zipCode4 ||
									catchmentAreasEdit?.[3]?.zipCode
								}
								error={
									touched.zipCode4 && errors.zipCode4
										? errors.zipCode4
										: ''
								}
							/>
						</View>
					)}
					{inputCount > 4 && (
						<View style={styleRowContainer()}>
							<Input
								label={t('City') + '*'}
								containerStyles={styles.flex1}
								value={
									values.city5 ||
									catchmentAreasEdit?.[4]?.city
								}
								onChangeText={handleChange('city5')}
								onBlur={handleBlur('city5')}
								error={
									touched.city5 && errors.city5
										? errors.city5
										: ''
								}
							/>
							<Input
								label={t('Zip Code') + '*'}
								containerStyles={styles.flex1}
								onChangeText={handleChange('zipCode5')}
								onBlur={handleBlur('zipCode5')}
								value={
									values.zipCode5 ||
									catchmentAreasEdit?.[4]?.zipCode
								}
								error={
									touched.zipCode5 && errors.zipCode5
										? errors.zipCode5
										: ''
								}
							/>
						</View>
					)}
					<Button
						title={t('Add Another City')}
						outerContainerProps={{
							style: { marginTop: getResponsiveStyle(42) },
						}}
						variant={inputCount === 5 ? 'disabled' : 'grey'}
						size="lg"
						onPress={() => incrementArea(values)}
					/>
					<Button
						title={t('Next')}
						// outerContainerProps={{
						// 	style: { marginTop: getResponsiveStyle(42) },
						// }}
						variant={
							(isValid &&
								dirty &&
								!!dropdownCountryValue?.label) ||
							(values.city1 && values.zipCode1) ||
							isEditEvent
								? 'primary'
								: 'disabled'
						}
						size="lg"
						onPress={handleSubmit}
					/>
				</View>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	image: {
		width: '100%',
		height: '136px',
		borderRadius: 6,
	},
	flex1: {
		flex: 1,
	},
	description: {
		paddingVertical: 20,
	},
});

export default EventAreaForm;

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(24),
	} as TStyle;
};
function styleAddressContainer() {
	return { marginBottom: getResponsiveStyle(24) };
}

function styleAddressInput1() {
	return { marginBottom: getResponsiveStyle(16) };
}

function styleDescription() {
	return {
		height: getResponsiveStyle(80, 'dimensions'),
	};
}
