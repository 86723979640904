import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { HorizontalDivider } from '../..';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const SignupWithDivider = ({
	containerStyles,
	text,
}: {
	containerStyles?: StyleProp<ViewStyle>;
	text?: string;
}) => {
	const { t } = useTranslation();
	return (
		<View style={[styles.container, styleContainer(), containerStyles]}>
			<HorizontalDividerWrapper />
			<Text style={[styleText(), styles.text]}>
				{text || t('Or Sign up with')}
			</Text>
			<HorizontalDividerWrapper />
		</View>
	);
};

const HorizontalDividerWrapper = () => {
	return (
		<View style={styles.dividerContainer}>
			<HorizontalDivider />
		</View>
	);
};

export default SignupWithDivider;
function styleText() {
	return {
		paddingHorizontal: getResponsiveStyle(10, 'font'),
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(8, 'font'),
	};
}

function styleContainer() {
	return {
		marginBottom: getResponsiveStyle(38),
	};
}

const styles = StyleSheet.create({
	dividerContainer: {
		flex: 1,
	},
	text: {
		textTransform: 'uppercase',
		color: palettes.grey[0],
		fontWeight: '600',
	},
	container: {
		flexDirection: 'row',
		alignItems: 'center',
	},
});
