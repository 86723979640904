import React, { useEffect } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import { useAppSelector } from '../../../../redux/store';
import useGetMediaMessages from '../../../../api/chat/useGetMediaMessages';
import { getLinkFromString } from '../../../../utils/chatUtil';
import Link from './Link';
import Loader from '../../../common/loader/Loader';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';

const Links = () => {
	const chat = useAppSelector(state => state.chat.chat);

	const { executeMediaMessageQuery, loading, data } = useGetMediaMessages();

	useEffect(() => {
		if (chat?.conversationId)
			executeMediaMessageQuery({
				conversationId: chat?.conversationId,
				messageType: 'LINK',
			});
	}, [chat]);

	return (
		<ScrollView showsHorizontalScrollIndicator={false}>
			{loading && (
				<Loader
					size="small"
					containerStyles={{
						margin: getResponsiveStyle(20),
					}}
				/>
			)}
			<View style={styles.container}>
				{data?.getMediaMessages?.data.map(
					(media: any, index: number) => (
						<Link
							key={index}
							title={
								getLinkFromString(media.message)?.slice(0, 1)[0]
							}
							date={parseInt(media.createdAt)}
							variant={'grey'}
						/>
						// <Pressable onPress={() => >
						// 	<Text>
						// 		{getLinkFromString(media.message)
						// 			?.slice(0, 1)[0]
						// 			.substring(0, 30)}
						// 		...
						// 	</Text>
						// </Pressable>
					)
				)}
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingLeft: 20,
		paddingRight: 20,
		marginTop: 10,
		// width: '95%',
		flexWrap: 'wrap',
	},
	image: {
		width: 105,
		height: 96,
		borderRadius: 6,
		marginBottom: 10,
	},
	detailImage: {
		width: 224,
		height: 204,
	},
	mobileSizeImage: {
		width: 159,
		height: 126,
	},
	mobileSizeDetailImage: {
		width: 324,
		height: 304,
	},
});

export default Links;
