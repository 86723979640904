import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
// import { socket } from '../../../../App';
import useCreateLiveEventMessage from '../../../api/chat/useCreateLiveEventMessage';
import { useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import EventChatInput from '../event-section-mobile/EventChatInput';
import ProductDiscussion from '../event-section-mobile/ProductDiscussion';
import ProductView from '../event-section-mobile/ProductView';
import CustomLabel from '../product-section/CustomLabel';
import { testMobileProduct } from '../product-section/event-chat/People';
import { LiveEventMessage } from '../product-section/event-chat/EventChatBox';
import { db, storage } from '../../../services/firebase';
import {
	collection,
	query,
	where,
	onSnapshot,
	addDoc,
	Timestamp,
} from 'firebase/firestore';
import AsyncStorage from '@react-native-community/async-storage';
import ProductHighLightMarker from './ProductHighLightMarker';
import { LiveEventSectionStyles } from './utils/LiveEventSection.styles';
import Slider from '@react-native-community/slider';
import { Text } from 'react-native';
import PlayIcon from '../../common/icons/PlayIcon';
import PauseIcon from '../../common/icons/PauseIcon';

type Props = {
	products: TGetAllProductsData[];
	messages: LiveEventMessage[];
	eventDetails?: TSingleLiveEventData;
	totalVideoTime?: number;
	currentVideoTime: number;
	playVideo: boolean;
	highlightedEventProductsTime: number[];
	onMessageSend?: (e?: TODO) => void;
	sleekToTime?: (e?: TODO) => void;
	handlePauseAndPlay?: (e?: TODO) => void;
	handleChange?: (e?: string) => void;
	handleTypingFocusIn?: () => void;
};

export const LiveEventLiveTab = ({
	products,
	messages,
	eventDetails,
	totalVideoTime,
	currentVideoTime,
	playVideo,
	highlightedEventProductsTime,
	handleChange,
	onMessageSend,
	handleTypingFocusIn,
	handlePauseAndPlay,
	sleekToTime,
}: Props) => {
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const eventProduct = useAppSelector(state => state.eventProduct);

	const [anonymousUser, setAnonymousUser] = useState({});
	const [isFocused, setIsFocused] = useState(false);
	const [message, setMessage] = useState('');

	const { executeCreateLiveEventMessageMutation } =
		useCreateLiveEventMessage();

	useEffect(() => {
		if (!isUserLoggedIn) {
			AsyncStorage.getItem('vId').then(data => {
				if (data) {
					setAnonymousUser({
						_id: data,
					});
				}
			});
		}
	}, []);

	const handleMessageSend = async () => {
		// socket.emit('sendLiveEventMessage', {
		// 	message: { message, messageType: 'TEXT' },
		// 	user: loggedInUserDetails,
		// });
		if (!message) return;
		const anonymousUser = {} as any;
		if (!isUserLoggedIn) {
			let anonymousUserId;
			const storedAnonymousId = await AsyncStorage.getItem('vId');
			if (!storedAnonymousId) {
				anonymousUserId = uuidv4();
				await AsyncStorage.setItem('vId', anonymousUserId);
			}
			anonymousUser._id = anonymousUserId || storedAnonymousId;
			anonymousUser.username = await AsyncStorage.getItem(
				'anonymousUsername'
			);
			setAnonymousUser(anonymousUser);
		}

		await addDoc(
			collection(db, 'messages', eventDetails?._id, 'LiveChat'),
			{
				message: { message, messageType: 'TEXT' },
				user: loggedInUserDetails || anonymousUser,
				roomMessage: true,
				createdAt: Timestamp.fromDate(new Date()),
			}
		);
		if (isUserLoggedIn)
			executeCreateLiveEventMessageMutation({
				eventId: eventDetails?._id,
				message,
				sender: loggedInUserDetails?._id,
			});
		setMessage('');
	};

	const formatTime = (totalSeconds: number) => {
		const minutes = Math.floor(totalSeconds / 60);
		const seconds = Math.floor(totalSeconds % 60);

		const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		return formattedTime;
	};

	const [isHideProducts, setISHideProducts] = useState(true);
	return (
		<View
			style={{
				marginBottom: getResponsiveStyle(50),
				zIndex: isFocused && 999,
			}}
		>
			<ProductDiscussion messages={messages} />
			<CustomLabel
				labelText={'Products'}
				// labelLink={isHideProducts ? 'Hide' : 'Show'}
				onSelect={() => setISHideProducts(!isHideProducts)}
				selectText=""
				isMobile={true}
			/>
			{!eventDetails?.eventStreamTimeline?.endedAt && (
				<EventChatInput
					message={message}
					handleChange={val => {
						setMessage(val);
						handleChange(val);
					}}
					setIsFocused={setIsFocused}
					onMessageSend={handleMessageSend}
					handleTypingFocusIn={handleTypingFocusIn}
				/>
			)}
			{isHideProducts && (
				<ProductView products={products} eventDetails={eventDetails} />
			)}
			{totalVideoTime ? (
				<>
					<View
						style={{
							width: '92%',
							zIndex: 9999999999999,
							flexDirection: 'row',
							marginTop: 10,
						}}
					>
						<ProductHighLightMarker
							totalTime={totalVideoTime}
							highlightedEventProductsTime={
								highlightedEventProductsTime
							}
							onPressHighlight={sleekToTime}
						/>
					</View>
					<Slider
						minimumValue={0}
						maximumValue={1}
						value={currentVideoTime / totalVideoTime}
						style={{
							marginTop: 5,
						}}
						onValueChange={value => {
							const timeOnChange = value * totalVideoTime;
							if (
								Math.floor(currentVideoTime) !==
								Math.floor(timeOnChange)
							) {
								sleekToTime(timeOnChange);
							}
						}}
						// onSlidingComplete={onSliderSlidingComplete}
					/>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							{playVideo ? (
								<PauseIcon
									width={20}
									height={20}
									color={'white'}
									containerStyles={{
										marginRight: 10,
									}}
									onPress={handlePauseAndPlay}
								/>
							) : (
								<PlayIcon
									width={20}
									height={20}
									color={'white'}
									containerStyles={{
										marginRight: 10,
									}}
									onPress={handlePauseAndPlay}
								/>
							)}
							<Text style={{ color: 'white' }}>
								{formatTime(currentVideoTime)}
							</Text>
						</View>
						<Text style={{ color: 'white' }}>
							{formatTime(totalVideoTime)}
						</Text>
					</View>
				</>
			) : null}
		</View>
	);
};
