import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { useGetBrandStats } from '../../../../../api/brands/useGetBrandStats';
import { palettes } from '../../../../../config';
import { createArray } from '../../../../../utils/createArray';
import { getResponsiveStyle } from '../../../../../utils/getResponsiveStyle';
import { DropdownCompanyProfile } from '../../DropdownCompanyProfile';
import { AveragePrice } from './AveragePrice';
import { BrandStats } from './BrandStats';

type Props = {
	selectedBrand: TGetAllBrandsData;
	onPressActionButton: (brandId: string) => void;
	onPressContainer: (brand: TGetAllBrandsData) => void;
};

const SelectedBrand = ({
	selectedBrand,
	onPressActionButton,
	onPressContainer,
}: Props) => {
	const { t } = useTranslation();
	const { getBrandStats, data: brandStats } = useGetBrandStats({
		onCompleted: (data: any) => {},
	});

	useEffect(() => {
		if (selectedBrand?._id) {
			getBrandStats([selectedBrand?._id] as string[]);
		}
	}, []);

	const stats = brandStats?.getBrandStats?.data?.data?.[0];

	return (
		<>
			<DropdownCompanyProfile
				name={selectedBrand.name}
				_id={selectedBrand._id}
				countryCode={selectedBrand?.address?.countryCode}
				image={selectedBrand.profileImage}
				location={selectedBrand?.address?.city}
				rating={Number(selectedBrand.rating) ?? 0}
				onPressActionButton={() => {
					onPressActionButton(selectedBrand._id);
				}}
				actionLabel={t('Remove')}
				outlineColor={palettes.red[0]}
				containerStyles={{ marginBottom: getResponsiveStyle(22) }}
				onPressContainer={() => {
					onPressContainer(selectedBrand);
				}}
			/>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<View>
					<Text
						style={[
							{
								textTransform: 'uppercase',
								color: palettes.grey[0],
							},
							{
								fontSize: getResponsiveStyle(10, 'font'),
								lineHeight: getResponsiveStyle(14, 'font'),
								marginBottom: getResponsiveStyle(8),
							},
						]}
					>
						{t('Followers')}
					</Text>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<View style={{ flexDirection: 'row' }}>
							<BrandStats
								entity={t('guests')}
								stat={stats?.followers?.guestCount ?? 0}
								isLastItem={false}
							/>
							<BrandStats
								entity={t('sellers')}
								stat={stats?.followers?.sellersCount ?? 0}
								isLastItem={false}
							/>
							<BrandStats
								entity={t('hosts')}
								stat={stats?.followers?.hostCount ?? 0}
								isLastItem={true}
							/>
							{/* {createArray(3).map((_, i) => (
								<BrandStats key={i} isLastItem={i === 2} />
							))} */}
						</View>
					</View>
				</View>
				<View>{/* <AveragePrice average={406.27} /> */}</View>
			</View>
		</>
	);
};

export default SelectedBrand;
