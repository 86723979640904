import * as React from 'react';

/**
 * @param initialValue - Initial value, or function to get the initial value. Similar to `useState`,
 * only the value/function passed in the first time this is called is respected.
 * @returns The value. The identity of this value will always be the same.
 */
export function useConst<T>(initialValue: T | (() => T)): T {
	const ref = React.useRef<{ value: T }>();
	if (ref.current === undefined) {
		ref.current = {
			value:
				typeof initialValue === 'function'
					? (initialValue as Function)()
					: initialValue,
		};
	}
	return ref.current.value;
}
