import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import EllipsisIcon from '../icons/EllipsisIcon';

const StatusOptionDropdownItems = [
	// {
	// 	value: 'option',
	// 	label: 'option',
	// },
	{
		value: 'delete',
		label: 'DELETE',
	},
];

type Props = {
	handleClick: () => void;
	handleOptionSelect: (value: string) => void;
	openState: boolean;
};

const StatusOptionSelector = ({
	openState,
	handleClick,
	handleOptionSelect,
}: Props) => {
	return (
		<View style={styles.container}>
			<Pressable onPress={handleClick}>
				<EllipsisIcon />
			</Pressable>
			{openState && (
				<>
					{StatusOptionDropdownItems.map(option => (
						<Pressable
							onPress={() => {
								handleClick();
								handleOptionSelect(option.value);
							}}
							style={styles.select}
						>
							<Text style={styles.option}>{option.label}</Text>
						</Pressable>
					))}
				</>
			)}
		</View>
	);
};

export default StatusOptionSelector;

const styles = StyleSheet.create({
	select: {
		borderRadius: 6,
		position: 'absolute',
		backgroundColor: 'white',
		borderWidth: 1,
		right: -3,
		borderColor: palettes.grey[5],
		shadowOffset: {
			width: 0,
			height: 30,
		},
		shadowRadius: 40,
		shadowColor: 'rgba(124, 136, 155, 0.18)',
		shadowOpacity: 1,
		marginTop: getResponsiveStyle(20),
	},
	option: {
		fontSize: getResponsiveStyle(12, 'font'),
		color: palettes.grey[0],
		margin: getResponsiveStyle(3),
		paddingLeft: getResponsiveStyle(3),
		paddingRight: getResponsiveStyle(3),
		paddingTop: getResponsiveStyle(3),
		paddingBottom: getResponsiveStyle(3),
	},
	container: {
		marginRight: getResponsiveStyle(-10),
		position: 'relative',
	},
});
