import React from 'react';
import { View, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { EventPlaceholderIcon } from '../icons';

export const EventImagePlaceholder = () => {
	return (
		<View style={styles.container}>
			<EventPlaceholderIcon width={48} height={44} />
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		height: '100%',
		width: '100%',
		backgroundColor: palettes.grey[11],
		justifyContent: 'center',
		alignItems: 'center',
	},
});
