import { View, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import SalesSection from './SalesSection';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_GRAPH_STATS } from '../../../graphql/sales/queries';
import {
	GET_SELLERS_ORDERS_TODAY,
	GET_SELLERS_ORDERS_YESTERDAY,
} from '../../../graphql/order/queries';
import { useAppSelector } from '../../../redux/store';
// type Props = {

// 	prevData?:number[];
// 	currData?:number[];
// 	chartItemValues?: {
// 		title: string;
// 		percentageDifference: number;
// 		percentageIncrease: boolean;
// 		value: number | string;
// 		showPercentage?: boolean;
// 	}[];
// };
const ProfileStats = () => {
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();
	const { loading: salesGraphLoading } = useQuery(GET_GRAPH_STATS, {
		onCompleted: data => {
			if (
				data.getUserSalesGraphData.success &&
				data.getUserSalesGraphData.data
			) {
				let currMonth =
					data.getUserSalesGraphData.data.currentMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);
				let prevMonth =
					data.getUserSalesGraphData.data.previousMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);

				setPrevGraphData(prevMonth);
				setCurrGraphData(currMonth);
			}
		},
	});
	// const connectAccountID = useAppSelector(
	// 	state => state.userBanks.connectAccountId
	// );

	const connectID = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.connectAccountInfo?.connectAccountId
	);
	const id = useAppSelector(state => state.auth.loggedInUserDetails?._id);
	const [todayOrders, setTodayOrders] = useState<TSellersOrder_[]>([]);
	const [todayNoOfOrders, setTodayNoOfOrders] = useState(0);
	const [yesterdayOrders, setYesterdayOrders] = useState<TSellersOrder_[]>(
		[]
	);
	const [yesterdayNoOfOrders, setYesterdayNoOfOrders] = useState(0);
	const [getYesterdaysOrders] = useLazyQuery(GET_SELLERS_ORDERS_TODAY, {
		variables: {
			args: {
				connectAccount: connectID,
				sellerId: id,
			},
		},
		onCompleted: data => {
			setTodayNoOfOrders(data?.getOrdersSellerToday?.data?.total);
			setTodayOrders(data?.getOrdersSellerToday?.data?.data);
		},
	});

	const [getTodaysOrders] = useLazyQuery(GET_SELLERS_ORDERS_YESTERDAY, {
		variables: {
			args: {
				connectAccount: connectID,
				sellerId: id,
			},
		},
		onCompleted: data => {
			setYesterdayOrders(data?.getOrdersSellerYesterday?.data?.data);
			setYesterdayNoOfOrders(data?.getOrdersSellerYesterday?.data?.total);
		},
	});
	useEffect(() => {
		getYesterdaysOrders();
		getTodaysOrders();
	}, [connectID]);

	return (
		<View>
			<SalesSection
				prevData={PrevGraphData}
				currData={CurrGraphData}
				loadingCart={salesGraphLoading}
				todaysOrders={todayOrders}
				yesterdaysOrders={yesterdayOrders}
				todayNoOfOrders={todayNoOfOrders}
				yesterdayNoOfOrders={yesterdayNoOfOrders}
			/>
			{/* <OrderSection /> */}
			{/* <CustomerSection /> */}
		</View>
	);
};

export default ProfileStats;
