import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { CREATE_ORDER } from '../../graphql/order/mutations';
import { useSetAlert } from '../useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { getCountryLabelFromValue } from '../../utils/getCountryLabelFromValue';
import { setVisitorCart } from '../../redux/userSlice';
import { GET_CART } from '../../graphql/cart/queries';
import { DELETE_CART } from '../../graphql/cart/mutation';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../types/exportedTypes';
import useCreateAnonymousUser from '../../api/auth/useCreatAnonymousUser';

export const useCreateOrder = ({
	items,
	Total,
	deliveryFee,
	brandInCartIds,
}: any) => {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();

	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		state => state.auth
	);

	const [
		createOrder,
		{ loading: createOrderLoading, error: createOrderError, called, reset },
	] = useMutation(CREATE_ORDER);

	const { createAnonymousUser } = useCreateAnonymousUser({
		onCompleted: data => {
			// payNowCard(null, payNowData, data?.createAnonymousUser?.data?._id);
			handleCreateOrder(data?.createAnonymousUser?.data?._id);
		},
	});

	const [deleteCart, { loading: deleteCartLoading }] = useMutation(
		DELETE_CART,
		{
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_CART }],
		}
	);

	useEffect(() => {
		if (createOrderError) {
			setAlert(`${t('Unable to complete order')}`, 'danger');
		}
	}, [createOrderError]);

	const payingAddressDeets = useAppSelector(
		state => state.oneStopCheckout.address
	);
	const handleCreateOrder = (userId: any) => {
		if (called) return;
		createOrder({
			variables: {
				args: {
					items,
					associatedUser: isUserLoggedIn
						? loggedInUserDetails?._id
						: null,
					associatedAnonymousUser: !isUserLoggedIn ? userId : null,
					paidWith: 'CASH',
					orderPaymentStatus: 'ON_DELIVERY',
					brands: brandInCartIds,
					eventType: 'LIVE',
					purchasedTotalAmount: Total,
					deliveryFee,
					deliveryInfo: {
						name: payingAddressDeets?.name,
						phoneNumber: payingAddressDeets?.phoneNumber,
						emailAddress:
							payingAddressDeets?.email ||
							loggedInUserDetails?.email,
						deliveryAddress: {
							address: payingAddressDeets?.address,
							city: payingAddressDeets?.city,
							zipCode: payingAddressDeets?.zipCode,
							country: getCountryLabelFromValue(
								payingAddressDeets?.countery
							),
						},
					},
				},
			},
			onCompleted: async response => {
				// await setOrderItems(res?.createOrder?.data?.items)
				// console.log({ orderInfo: res, brandInCartIds });
				// const orderId = res?.createOrder?.data?.orderId;
				// const items = res?.createOrder?.data?.items;
				if (response?.createOrder?.success) {
					deleteCart();
					dispatch(setVisitorCart([]));
					setAlert(`${t('Your order has been placed!')}`, 'normal');
					navigation.navigate('PurchaseComplete');
				} else {
					setAlert(response?.createOrder?.message, 'normal');
					reset();
				}
			},
		});
	};

	const payOnDelivery = (payingAddressDeets: any) => {
		if (isUserLoggedIn) {
			handleCreateOrder(null);
		} else {
			createAnonymousUser({
				anonymousUserData: {
					email: payingAddressDeets.email,
					isCreateStripeUser: false,
					personalInformation: {
						firstName: payingAddressDeets.firstName,
						lastName: payingAddressDeets.lastName,
						phoneNumber: payingAddressDeets.phoneNumber,
					},
				},
			});
		}
	};

	return {
		createOrderLoading,
		deleteCartLoading,
		payOnDelivery,
	};
};
