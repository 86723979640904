import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'rn-css';
import { Button, Logo, NotificationIconWithDropdown } from '../../';
import useGetUnreadMessages from '../../../api/chat/useGetUnreadMessages';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { resetCreateEventSlice } from '../../../redux/createEventSlice';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import {
	setCurrentEvent,
	setIsAffiliatedHostEditingEvent,
	setIsEditEvent,
} from '../../../redux/getEventSlice';
import { useAppSelector } from '../../../redux/store';
import {
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import {
	TDesktopNavigationBar,
	TUseNavigation,
} from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { EmptyHeartIcon } from '../icons';
import InboxIcon from '../icons/InboxIcon';
import { ProfileIconWithDropdown } from '../profile-icon-with-dropdown';
import { CartIconWithItems } from './CartIconWithItems';
import { LogoAndNavLinks } from './logo-and-nav-links/LogoAndNavLinks';
import { SearchWithDropdown } from './SearchWithDropdown';
import LanguageSelector from '../language-selector/language-selector';
import { Linking } from 'react-native';
import BrandCreate from '../brand-create-modal/BrandCreate';
import { useLogout } from '../../../api/auth';

const CreateEventLink = styled.Text<{ primary: string[] }>`
	cursor: pointer;
	border-bottom: 1px solid transparent;
	margin-right: ${getResponsiveStyle(27)}px;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		padding-bottom: 7px;
		border-bottom: 1px solid ${({ primary }) => primary?.[0]};
	}
`;

const DesktopNavigationBar = ({
	containerStyles,
	hasCreateEvent = false,
	hasLinks = true,
	hasSearch = true,
	hasGoBack = false,
	logoLocation = 'left',
	hasCart = true,
}: TDesktopNavigationBar) => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	const navigation = useNavigation<TUseNavigation>();
	const dispatch = useDispatch();

	const { setAlert } = useSetAlert();
	const {
		isUserLoggedIn,
		typeOfAccount,
		connectAccountId,
		loggedInUserDetails,
		unReadMessages,
	} = useAppSelector(state => ({
		isUserLoggedIn: state.auth.isUserLoggedIn,
		loggedInUserDetails: state.auth.loggedInUserDetails,
		typeOfAccount: state.auth.loggedInUserDetails?.typeOfAccount,
		connectAccountId:
			state.auth.loggedInUserDetails?.connectAccountInfo
				?.connectAccountId,
		unReadMessages: state.chat.unReadMessages,
	}));

	const pathName = window.location.pathname?.split('/')?.[1];
	const isNoShowAuthButtons = ['checkout-address', 'event'].includes(
		pathName
	);

	const { executeUnreadMessagesQuery, data: unreadMessagesData } =
		useGetUnreadMessages();

	const prefixNumber = useCallback((number: number) => {
		return number === 0 ? null : number < 9 ? `0 ${number}` : number;
	}, []);

	useEffect(() => {
		loggedInUserDetails?._id &&
			executeUnreadMessagesQuery({
				recipientId: loggedInUserDetails?._id as string,
			});
	}, []);

	const onPressCreateEvent = () => {
		dispatch(setIsEditEvent(false));
		dispatch(setIsAffiliatedHostEditingEvent(false));
		dispatch(resetCreateEventSlice());
		navigation.navigate('CreateEvent');
	};

	const [showBrandCreateModal, setShowBrandCreateModal] = useState(false);

	const { executeLogoutQuery } = useLogout();

	const handleBrandCreateRequest = () => {
		if (loggedInUserDetails?.affiliatedBrand) {
			executeLogoutQuery()
			Linking.openURL('https://admin.ocpus.io/');
		} else {
			setShowBrandCreateModal(true);
		}
	};

	return (
		<View
			style={[styles.container, styleContainer(), containerStyles]}
			nativeID="desktop-navbar"
		>
			<LogoAndNavLinks
				{...{
					hasGoBack,
					hasLinks,
					isLoggedIn: isUserLoggedIn,
					logoLocation,
				}}
			/>

			{hasSearch && logoLocation === 'left' && <SearchWithDropdown />}

			{logoLocation === 'center' && (
				<View style={styles.centerIconContainer}>
					<Logo
						tint={primary?.[0]}
						style={styles.logo}
						width={38}
						height={38}
						scale={1.3}
						viewBox="0 0 38 38"
					/>
				</View>
			)}

			{/* {!isUserLoggedIn && (
				<View style={styles.signInContainer}>
					<Text
						style={[
							styles.alreadyHaveAnAccountText,
							styleAlreadyHaveAnAccountText(),
						]}
					>
						{t('Already have an account?')}
					</Text>
					<Button title={t('Sign In')} variant="outline" size="sm" />
				</View>
			)} */}

			<View style={{ position: 'absolute' }}>
				<BrandCreate
					isOpen={showBrandCreateModal}
					onPress={() => setShowBrandCreateModal(false)}
				/>
			</View>

			{isUserLoggedIn && isUserLoggedIn !== 'loading' && (
				<View style={styles.profileIconContainer}>
					{(hasCreateEvent || typeOfAccount === 'SELLER') && (
						<CreateEventLink
							onPress={handleBrandCreateRequest}
							style={[
								styles.createEvent,
								{ color: primary?.[0] },
							]}
							primary={primary}
						>
							{t('My Brand')}
						</CreateEventLink>
					)}
					{(hasCreateEvent || typeOfAccount === 'SELLER') && (
						<CreateEventLink
							onPress={onPressCreateEvent}
							style={[
								styles.createEvent,
								{ color: primary?.[0] },
							]}
							primary={primary}
						>
							+ {t('Create Event')}
						</CreateEventLink>
					)}
					<View style={styles.iconContainer}>
						{hasCart && <CartIconWithItems />}

						{typeOfAccount === 'BRAND' && (
							<EmptyHeartIcon
								style={styleEmptyHearIcon()}
								onPress={() => navigation.navigate('Chat')}
							/>
						)}

						{typeOfAccount !== 'CONSUMER' && (
							<>
								<InboxIcon
									style={styleInboxIcon()}
									onPress={() => navigation.navigate('Chat')}
								/>
								<Text
									style={[
										styles.messageCount,
										styleMessageCount(primary),
									]}
								>
									{prefixNumber(unReadMessages?.length)}
								</Text>
							</>
						)}
						<NotificationIconWithDropdown />
						<ProfileIconWithDropdown />
					</View>
				</View>
			)}

			{!isUserLoggedIn && (
				<View style={styles.authBtn}>
					{!isNoShowAuthButtons && (
						<>
							<Button
								onPress={() => {
									dispatch(setTypeOfAccount("SELLER"));
									dispatch(setPrimaryColors("SELLER"));
									dispatch(setSignupSidebarValues("SELLER"));
									navigation.navigate('PersonalInfo');
								}
								}
								title={t('Sell')}
								variant="primary"
								innerContainerProps={{
									style: {
										paddingLeft: '1.5rem',
										paddingRight: '1.5rem',
									},
								}}
							/>
							<Button
								onPress={() => navigation.navigate('Login')}
								title={t('Login')}
								variant="grey"
								innerContainerProps={{
									style: {
										backgroundColor: 'rgb(237, 239, 242)',
										paddingLeft: '1.5rem',
										paddingRight: '1.5rem',
									},
								}}
							/>
							<Button
								onPress={() => {
									dispatch(setTypeOfAccount('CONSUMER'));
									dispatch(setPrimaryColors('CONSUMER'));
									dispatch(
										setSignupSidebarValues('CONSUMER')
									);
									navigation.navigate('PersonalInfo');
								}}
								title={t('Sign up')}
								variant="primary"
								innerContainerProps={{
									style: {
										paddingLeft: '1.5rem',
										paddingRight: '1.5rem',
									},
								}}
							/>
						</>
					)}
					<View
						style={{
							marginLeft: getResponsiveStyle(10),
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<View
							style={{
								marginRight: getResponsiveStyle(10),
							}}
						>
							<CartIconWithItems />
						</View>
						<LanguageSelector />
					</View>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	iconContainer: {
		alignItems: 'center',
		flexDirection: 'row',
	},
	authBtn: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	messageCount: {
		fontWeight: '600',
		// position: 'absolute',
		// right: getResponsiveStyle(-12),
		// top: getResponsiveStyle(-8),
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'relative',
		zIndex: 999,
		minHeight: getResponsiveStyle(98, 'dimensions'),
	},
	link: {
		color: palettes.grey[0],
		paddingBottom: 7,
	},
	profileIconContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	createEvent: {
		alignItems: 'center',
		paddingBottom: 1,
	},
	logo: { minHeight: 38 },
	centerIconContainer: {
		position: 'absolute',
		left: `calc(50% - ${getResponsiveStyle(16)}px)`,
	},
	alreadyHaveAnAccountText: {
		color: palettes.dark[4],
	},
	signInContainer: { flexDirection: 'row', alignItems: 'center' },
});

export default DesktopNavigationBar;

function styleMessageCount(primary: string[]) {
	return {
		// marginLeft: getResponsiveStyle(2),
		marginRight: getResponsiveStyle(0),
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		color: primary?.[0],
		marginBottom: getResponsiveStyle(10),
	};
}

function styleContainer() {
	return { paddingBottom: getResponsiveStyle(40) };
}

function styleInboxIcon() {
	return {
		width: getResponsiveStyle(20, 'dimensions'),
		height: getResponsiveStyle(18, 'dimensions'),
		marginLeft: getResponsiveStyle(24),
	};
}

function styleEmptyHearIcon() {
	return {
		width: getResponsiveStyle(20, 'dimensions'),
		height: getResponsiveStyle(18, 'dimensions'),
	};
}
