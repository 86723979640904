import { gql } from '@apollo/client';

export const IS_FOLLOWING_ME_REQUEST_SENT = gql`
	query IsFollowMeRequestSent($args: IsFollowRequestSentInput) {
		IsFollowMeRequestSent(args: $args) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on IsFollowMeRequestSentResponse {
								userId
								isFollowRequestSent
								isUnFollowRequestSent
							}
						}
					}
				}
			}
		}
	}
`;

export const IS_UNFOLLOWING_ME_REQUEST_SENT = gql`
	query isUnFollowMeRequestSent($args: IsFollowRequestSentInput) {
		IsUnFollowMeRequestSent(args: $args) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						... on IsFollowMeRequestSentResponse {
							userId
							isUnFollowRequestSent
						}
					}
				}
			}
		}
	}
`;
