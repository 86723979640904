import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_BRAND_REQUEST } from '../../graphql/brands/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';

type TParams = {
	onCompleted?: (data: TBrandRequestResponse) => void;
	_onError?: (error: ApolloError) => void | undefined;
};

const useBrandCreateRequest = ({ onCompleted, _onError }: TParams) => {
	const { setAlert } = useSetAlert();
	const onError = (error: ApolloError) => {
		if (error) {
			setAlert(error.message, 'danger');
		}
	};
	const [createStatus, states] = useMutation<TBrandRequestResponse>(
		CREATE_BRAND_REQUEST,
		{
			onCompleted,
			onError: error => {
				_onError === undefined ? onError(error) : _onError(error);
			},
		}
	);

	const executeBrandCreateRequest = (brandRequestData: BrandRequestType) => {
		createStatus({
			variables: {
				args: brandRequestData,
			},
		});
	};
	return { executeBrandCreateRequest, ...states };
};

export default useBrandCreateRequest;
