import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import Button from '../../button/Button';
// import Skeleton from '../Skeleton';
import Skeleton from 'react-loading-skeleton';
import { StatsSkeleton } from '../StatsSkeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProfileStatsSkeleton = () => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<>
			<View
				style={[
					styleProfileContainer(),
					isLessThanDesktopBase && styles.profileContent,
				]}
			>
				<View
					style={[
						styles.alignCenter,
						isLessThanDesktopBase && styles.profile,
					]}
				>
					<Skeleton
						width={getResponsiveStyle(80)}
						height={getResponsiveStyle(80)}
						style={{
							...styleProfilePicture(),
							// isLessThanDesktopBase && {
							// 	marginRight: 10,
							// },
							...{
								borderRadius: 9999,
								marginRight: isLessThanDesktopBase ? 10 : 0,
							},
						}}
					/>
					<View
						style={[!isLessThanDesktopBase && styles.alignCenter]}
					>
						<Skeleton
							width={getResponsiveStyle(150)}
							height={getResponsiveStyle(15)}
							style={{ borderRadius: 6 }}
						/>
						<Skeleton
							width={getResponsiveStyle(130)}
							height={getResponsiveStyle(10)}
							style={{ borderRadius: 6 }}
						/>
					</View>
				</View>
			</View>
			<View style={styles.statsContainer}>
				<StatsSkeleton />
				<StatsSkeleton />
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		borderBottomWidth: 1,
	},
	profileContainer: {
		alignItems: 'center',
	},
	profileContent: {
		flexDirection: 'row',
		marginHorizontal: '10px',
	},
	profile: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	name: {
		fontWeight: '600',
	},
	location: {
		fontWeight: '400',
	},
	statsContainer: {
		flexDirection: 'row',
		width: '100%',
	},
	alignCenter: { alignItems: 'center' },
});

export default ProfileStatsSkeleton;

const styleProfilePicture = () => {
	return {};
};

const styleProfileContainer = () => {
	return [
		styles.profileContainer,
		{
			marginBottom: getResponsiveStyle(20),
		},
	];
};
