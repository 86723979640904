import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	StyleProp,
	StyleSheet,
	useWindowDimensions,
	View,
	ViewStyle,
} from 'react-native';
import { breakpoints } from '../../../config';
import { ExpireEventAfter7Hours } from '../../../constants/expireEventAfterHours';
import { IS_SUBSCRIBED_TO_EVENTS } from '../../../graphql/eventSubscription/queries';
import { useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { hasEventExpired } from '../../../utils/hasEventExpired';
import { CardBottom } from './CardBottom';
import { CardTop, EventCardTopProps } from './CardTop';

type Props = {
	size?: 'large' | 'small';
	containerStyles?: TStyle;
	item: TGetAllEventsData;
	publishStatus?: 'time' | 'published';
	canSubscribe?: boolean;
	isSubscribed?: boolean;
	heading?: string;
	isEventPost?: boolean;
	runIsSubscribeCheck?: () => void;
} & EventCardTopProps;

const Event = ({
	size = 'small',
	containerStyles,
	item,
	type,
	canSubscribe,
	isSubscribed,
	addButtonProps,
	isEventPost,
	runIsSubscribeCheck,
	heading,
}: Props) => {
	const width = useWindowDimensions().width;
	const [isEventExpired, setIsEventExpired] = useState(false);
	const [isSubscribedUser, setIsSubscribedUser] = useState<boolean>();
	const [canSub, setCanSub] = useState(false);
	const { t } = useTranslation();

	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(
		state => state.auth
	);

	const [isSubscribedToEvent] = useLazyQuery(IS_SUBSCRIBED_TO_EVENTS, {
		onCompleted: data => {
			if (!isSubscribedUser) {
				setIsSubscribedUser(
					data?.isSubscribedToEvent?.data?.data?.[0]?.isSubscribed
				);
			}
		},
	});

	const runSubscribeCheck = () => {
		setTimeout(() => {
			isSubscribedToEvent({
				variables: { eventIds: [item?._id] },
			});
		}, 1500);
	};

	// console.log({ item });
	const isSubConsumer = useCallback(() => {
		if (
			item?.typeOfEvent === 'PHYSICAL' &&
			loggedInUserDetails?.typeOfAccount === 'CONSUMER' &&
			!item.affiliatedHosts.length
		) {
			return false;
		}
		return (
			(!item?.invitedPeople
				?.map(data => data._id)
				?.includes(loggedInUserDetails?._id as any) &&
				loggedInUserDetails?.typeOfAccount === 'CONSUMER') ||
			(!item?.affiliatedHosts
				?.map(data => data._id)
				?.includes(loggedInUserDetails?._id as any) &&
				loggedInUserDetails?.typeOfAccount === 'HOST' &&
				item?.typeOfEvent === 'PHYSICAL')
		);
	}, [item]);

	useEffect(() => {
		const isExpired = hasEventExpired(
			ExpireEventAfter7Hours,
			new Date(+item?.startingEventDateTime),
			Date.now()
		);
		setIsEventExpired(isExpired);
	}, []);
	useEffect(() => {
		if (
			(loggedInUserDetails?.typeOfAccount !== 'SELLER' &&
				[
					'Published Events Selected For You',
					'Published Events Around You',
					'Upcoming Events Selected For You',
					'Upcoming Events Around You',
					// 'Published events with following',
				].includes(heading as any)) ||
			isSubConsumer()
		) {
			setCanSub(true);
		} else {
			setCanSub(false);
		}
		if (
			[t('All Upcoming Events')].includes(heading as any) &&
			!isUserLoggedIn
		) {
			setCanSub(true);
		}
	}, [heading, isSubConsumer(), item]);

	return (
		// <Pressable onPress={handleNavigation}>
		<View
			style={[
				styleContainer(width, size, containerStyles),
				isEventPost && { maxWidth: '100%' },
			]}
		>
			<CardTop
				item={item}
				type={type}
				canSubscribe={canSub}
				isSubscribed={isSubscribed}
				addButtonProps={addButtonProps}
				runIsSubscribeCheck={runIsSubscribeCheck}
				{...{ isEventExpired, setIsEventExpired }}
			/>
			<CardBottom
				item={item}
				canSubscribe={canSub}
				isSubscribed={isSubscribed || isSubscribedUser}
				runIsSubscribeCheck={runSubscribeCheck}
				{...{ isEventExpired, setIsEventExpired }}
			/>
		</View>
		// </Pressable>
	);
};

const getResponsiveEventCardStyles = (width: number) => {
	return width >= breakpoints.desktopBase
		? styleDesktop()
		: width >= breakpoints.tabletSm
		? styleTablet()
		: styleMobile();
};

const styles = StyleSheet.create({
	container: {
		width: '100%',
		borderRadius: 6,
		// cursor: 'pointer',
		// box-shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
	},
});

export default Event;

const styleContainer = (
	width: number,
	size: string,
	containerStyles?: StyleProp<ViewStyle>
): StyleProp<ViewStyle> => {
	return [
		styles.container,
		getResponsiveEventCardStyles(width),
		size === 'small' && styleSmall(),
		containerStyles,
	];
};

export const styleSmall = () => {
	return {
		marginBottom: getResponsiveStyle(24),
		height: getResponsiveStyle(340, 'dimensions'),
		maxWidth: '32%',
	};
};

export const styleMobile = () => {
	return {
		marginBottom: getResponsiveStyle(10),
		height: getResponsiveStyle(340, 'dimensions'),
		maxWidth: '100%',
	};
};

export const styleTablet = () => {
	return {
		marginBottom: getResponsiveStyle(10),
		height: getResponsiveStyle(320, 'dimensions'),
		maxWidth: '100%',
	};
};

export const styleDesktop = () => {
	return {
		marginBottom: getResponsiveStyle(24),
		height: getResponsiveStyle(380, 'dimensions'),
		maxWidth: '49%',
	};
};
