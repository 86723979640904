import { gql } from '@apollo/client';

const GET_STATUS_COMMENT = gql`
	query getComments($parentId: ID!) {
		getComments(find: { parentId: $parentId }) {
			statusCode
			data {
				data {
					... on Comment {
						_id
						content
						createdAt
						commentBy {
							username
							personalInformation {
								profileImageLink
								address {
									countryCode
									countryLabel
									city
								}
							}
						}
					}
				}
			}
		}
	}
`;

export { GET_STATUS_COMMENT };
