import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';

export type AboutEventProps = {
	about: string;
};

const AboutEvent = ({ about }: AboutEventProps) => {
	const { t } = useTranslation();
	return (
		<>
			<View style={styles.container}>
				<Text style={styles.text}>{t('About')}</Text>
				<Text style={styles.subText}>{about}</Text>
			</View>
			<LinearGradient
				colors={[
					'rgba(28, 28, 37, 0) 4.43%',
					'rgb(255, 255, 255) 68.35%',
				]}
				start={{ x: 1, y: 0.5 }}
				end={{ x: 1, y: 1 }}
				style={styles.gradientBox}
			/>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingTop: 15,
		paddingBottom: 20,
	},
	text: {
		fontSize: 24,
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
		marginBottom: 10,
	},
	subText: {
		fontSize: 16,
		fontWeight: 'normal',
		color: palettes.dark[4],
	},
	image: {
		width: '100%',
		height: '32rem',
		resizeMode: 'cover',
		borderRadius: 6,
	},
	gradientBox: {
		width: '100%',
		height: 70,
		position: 'absolute',
		bottom: 0,
	},
});

export default AboutEvent;
