import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { GET_USER_STATS } from '../../graphql/user/queries';
import { setUserStats } from '../../redux/userSlice';

const useGetUserDashboardStats = ({
	isCurrentUserStat,
}: {
	isCurrentUserStat?: boolean;
}) => {
	const dispatch = useDispatch();
	const onCompleted = (data: any) => {
		const stats: UserDashboardStats = data?.getUserDashboardStats?.data;
		if (isCurrentUserStat) dispatch(setUserStats(stats));
	};
	const [getUserDashboardStats, { data, error, loading }] = useLazyQuery(
		GET_USER_STATS,
		{
			onCompleted,
			onError: data => {},
		}
	);

	const executeUserDashboardStatsQuery = (_id?: string) => {
		getUserDashboardStats({
			variables: {
				_id,
			},
		});
	};
	return { executeUserDashboardStatsQuery, data, error, loading };
};

export default useGetUserDashboardStats;
