import { t } from 'i18next';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import EventDeleteBtn from './EventDeleteBtn';
import Checkbox from '../checkbox/Checkbox';
import ModalComponent from '../modal/ModalComponent';
import ActionLink from '../../common/styled-components/ActionLink';

type Props = {
	onCancelDelete: () => void;
	types?: string | string[];
	onConfirmDelete: () => void;
	onConfirmCheck?: () => void;
	checked?: boolean;
	isHideCheck?: boolean;
	isOpen: boolean;
	loading?: boolean;
	deleteText?: string;
	header?: string;
	info?: string;
};

const DeleteText = styled.Text`
	position: relative;
	font-size: ${getResponsiveStyle(18, 'font')}px;
	font-weight: 400;
	color: #616e82;
	line-height: ${getResponsiveStyle(27, 'font')}px;
	width: max-content;
	max-width: 90%;
	margin-bottom: ${getResponsiveStyle(60, 'spacing')}px;
`;

const DeleteModal = ({ ...props }: Props) => {
	// const [confirmCheck, setConfirmCheck] = useState<boolean>(false);
	return (
		<ModalComponent
			type="modal"
			header={props.header as string}
			openState={props.isOpen}
			onClose={props.onCancelDelete}
			isDeleteModal={true}
		>
			<>
				{/* {props.loading && (
					<Loader
						size="small"
						containerStyles={{
							margin: getResponsiveStyle(20),
						}}
					/>
				)} */}
				<View style={Styles.deleteModalContent}>
					<DeleteText>{props.info}</DeleteText>
					{!props?.isHideCheck && (
						<Checkbox
							hintText={`${t('Don’t ask me again')}`}
							value={props.checked}
							onChange={props.onConfirmCheck}
						/>
					)}
				</View>

				<View style={Styles.deleteModalActionBtn}>
					<ActionLink
						onPress={props.onCancelDelete}
						style={{
							fontSize: getResponsiveStyle(18, 'font'),
							lineHeight: getResponsiveStyle(14, 'font'),
							width: 'max-content',
							cursor: 'pointer',
						}}
					>
						{t('Cancel')}
					</ActionLink>
					<EventDeleteBtn
						loading={props.loading}
						onPress={props.onConfirmDelete}
						content={
							props?.deleteText ? props?.deleteText : t('Delete')
						}
					/>
				</View>
			</>
		</ModalComponent>
	);
};

const Styles = StyleSheet.create({
	deleteModalContent: {
		marginBottom: getResponsiveStyle(20),
		paddingBottom: getResponsiveStyle(20),
		color: palettes.grey[0],
		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 1,
	},
	deleteModalActionBtn: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
});

export default DeleteModal;
