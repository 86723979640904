import {
	useNavigation,
	useNavigationState,
	useRoute,
} from '@react-navigation/native';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { palettes } from '../../../config';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	icon: {
		selected: React.FC<SvgProps>;
		notSelected: React.FC<SvgProps>;
	};
	label?: string;
	marginRight: number;
	to: string;
};

export const MobileNavigationBottomIcon = ({
	icon: Icon,
	label = '',
	marginRight,
	to,
}: Props) => {
	const navigation = useNavigation<TUseNavigation>();
	const route = useRoute();
	return (
		<Pressable
			style={[styles.container]}
			onPress={() => navigation.navigate(to)}
		>
			<View>
				{route.name === to ? (
					<Icon.selected height={34} width={34} />
				) : (
					<Icon.notSelected height={34} width={34} />
				)}
			</View>
			<Text style={[styles.text, styleText(), { marginRight }]}>
				{label}
			</Text>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	text: {
		color: palettes.dark[0],
		flex: 1,
		width: '100%',
		textAlign: 'center',
		// marginRight: 10,
	},
	container: {
		flex: 1,
		alignItems: 'center',
		// backgroundColor: 'beige',
	},
});
function styleText() {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(13, 'font'),
		marginTop: getResponsiveStyle(6),
	};
}
