import { useLazyQuery } from '@apollo/client';
import { GET_STATUS_COMMENT } from '../../graphql/comment/queries';

const useGetComments = () => {
	const [getComments, { data, error, loading }] =
		useLazyQuery(GET_STATUS_COMMENT);

	const executeFollowingCommentsQuery = ({
		parentId,
	}: {
		parentId: string;
	}) => {
		getComments({
			variables: {
				parentId,
			},
		});
	};
	return { executeFollowingCommentsQuery, data, error, loading };
};

export default useGetComments;
