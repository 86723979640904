import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
type Props = {
	fillColor?: string;
	props: SvgProps;
};
const CartIcon = ({ fillColor = 'rgba(152,162,178,1)', props }: Props) => (
	<Svg viewBox="0 0 24 24" {...props}>
		<Path fill="none" d="M0 0h24v24H0z" />
		<Path
			d="M4 16V4H2V2h3a1 1 0 0 1 1 1v12h12.438l2-8H8V5h13.72a1 1 0 0 1 .97 1.243l-2.5 10a1 1 0 0 1-.97.757H5a1 1 0 0 1-1-1zm2 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
			fill={fillColor}
		/>
	</Svg>
);

export default CartIcon;
