import { View } from 'react-native';
import { TCalculateAllEventCardColumns } from './allEventsSection.utils';

export const EmptyEventPlaceholder = ({
	index,
	placeholdersToAddArrayLength,
}: {
	index: number;
	placeholdersToAddArrayLength: number;
}) => {
	return (
		<View
			style={{
				marginRight:
					placeholdersToAddArrayLength - 1 === index ? 0 : 16,
				flex: 1,
			}}
		></View>
	);
};
