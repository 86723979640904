import { View, Text, StyleSheet, Pressable } from 'react-native';
import React, { useState, useEffect } from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Loader, LoadMore, Table } from '../..';
import { CategoriesDropdown } from '../../common/filter-categories/CategoriesDropdown';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import ModalComponent from '../../common/modal/ModalComponent';
import WithdrawForm from './WithdrawForm';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_TRANSACTIONS } from '../../../graphql/order/queries';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import { usePagination } from '../../../hooks/useCurrentForPagination';
import getSymbolFromCurrency from 'currency-symbol-map';
import { twoDp } from '../../../utils/twoDP';
type Transaction = {
	customerImage: string;
	orderId: string;
	purchaseDate: string;
	brandName: string;
	eventType: string;
	baseTotal: string;
	purchasedTotalAmount: string;
	customerName: string;
	watch: string;
	purchasedOn: string;
	items: any[];
};
const TransactionSection = () => {
	const primary = usePrimaryColors();
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const SECTION_TABS = [
		{ label: t('All'), value: 'All' },
		{ label: t('Purchase'), value: 'Purchase' },
		{ label: t('Refund'), value: 'Refund' },
		{ label: t('Withdraw'), value: 'Withdraw' },
	];
	const [transaction, setTransaction] = useState<Transaction[]>([]);
	const [total, setTotal] = useState(0);
	const LIMIT = 10;
	const [selectedTab, setSelectedTab] = React.useState<string>(
		SECTION_TABS[0].value
	);
	const [WithdrawModalState, setWithdrawModalState] = React.useState(false);
	const [allTransactionsCurrent, increaseAllTransactionsCurrent] =
		usePagination();

	const HEADERS = [
		{
			headerLabel: `${t('Order')}#`,
			width: getResponsiveStyle(101),
		},
		{
			headerLabel: t('Purchased on'),
			width: getResponsiveStyle(200),
		},
		{
			headerLabel: t('Products'),
			width: getResponsiveStyle(200),
		},
		{
			headerLabel: t('Event type'),
			width: getResponsiveStyle(121),
		},
		{
			headerLabel:
				'(' + getSymbolFromCurrency('eur') + ') ' + t('Base total'),
			width: getResponsiveStyle(101),
		},
		{
			headerLabel:
				'(' +
				getSymbolFromCurrency('eur') +
				') ' +
				t('Purchased total'),
			width: getResponsiveStyle(121),
		},
		{
			headerLabel: t('Customers'),
			width: getResponsiveStyle(232),
		},
	];
	const [
		getTransactions,
		{
			loading: getTransactionsLoading,
			networkStatus: transactionStatus,
			refetch: transactionsRefetch,
		},
	] = useLazyQuery(GET_USER_TRANSACTIONS, {
		notifyOnNetworkStatusChange: true,
		onCompleted: data => {
			if (
				data &&
				data.getSellerTransactions &&
				data.getSellerTransactions.success &&
				data.getSellerTransactions.data &&
				data.getSellerTransactions.data.data
			) {
				setTotal(data.getSellerTransactions.data.total);
				const x = setData(data.getSellerTransactions.data.data);
				setTransaction([...transaction, ...x]);
			} else {
				setAlert(t('Unable to fetch Transactions'), 'danger');
			}
		},
		onError: error => {
			setAlert(error.message, 'danger');
		},
	});
	const setData = (val: any[]) => {
		return val.map((transaction: any) => {
			let qty = 0;
			transaction.items.map((product: any) => (qty += product.quantity));
			const date = new Date(Number(transaction.purchasedOn));

			let val: Transaction & { deliveryFee: string; _id: string } = {
				orderId: `#${transaction?.orderId}`,
				purchaseDate:
					date?.getDate() +
					'/' +
					(date?.getMonth() + 1) +
					'/' +
					date?.getFullYear(),
				brandName:
					transaction?.brands?.length > 1
						? t('Multiple')
						: transaction?.brands[0]?.name,
				eventType: transaction?.eventType,
				baseTotal: twoDp(transaction?.purchasedTotalAmount),
				purchasedTotalAmount: twoDp(transaction?.purchasedTotalAmount),
				customerName: transaction?.associatedUser?.username,
				watch: '',
				customerImage:
					transaction?.associatedUser?.personalInformation
						?.profileImageLink,
				deliveryFee: transaction?.deliveryFee,
				items: transaction?.items,

				purchasedOn: transaction?.purchasedOn,
				_id: transaction?._id,
			};
			return val;
		});
	};
	const returnRequest = () => {
		switch (selectedTab) {
			case 'All':
				return null;
			case 'Purchase':
				return { orderStatus: 'DELIVERED' };
			case 'Refund':
				return { orderStatus: 'REFUNDED' };
			default:
				return null;
		}
	};
	useEffect(() => {
		setTotal(0);
		let find = returnRequest();
		setTransaction([]);
		getTransactions({
			variables: {
				find,
				limit: LIMIT,
				current: allTransactionsCurrent,
			},
		});
	}, [selectedTab]);

	const changeWithdrawModalState = () => {
		setWithdrawModalState(!WithdrawModalState);
	};
	const onPress = (tab: string) => {
		setSelectedTab(tab);

		if (tab === 'Withdraw') {
			setWithdrawModalState(true);
		}
		// TODO: Set wondows scroll here
	};
	const onPressViewMore = () => {
		let find = returnRequest();
		transactionsRefetch({
			find,
			limit: LIMIT,
			current: increaseAllTransactionsCurrent(),
		});
	};

	return (
		<View style={styles.container}>
			<View style={styles.headerContainer}>
				<Text style={styles.header}>{t('Transactions')}</Text>
				{!isLessThanDesktopBase && (
					<View style={styles.tabContainer}>
						{SECTION_TABS.map(tab => (
							<Pressable
								key={tab.value}
								onPress={() => onPress(tab.value)}
							>
								<Text
									style={styleTab(
										selectedTab,
										tab.value,
										width,
										primary
									)}
								>
									{tab.label}
								</Text>
							</Pressable>
						))}
					</View>
				)}
				{/* <View style={styles.filterContainer}>
					<CategoriesDropdown
						categories={SORT_BY_FILTER}
						label=""
						placeholder={t('Sort By')}
					/>
					<CategoriesDropdown
						categories={DATE_FILTER}
						label=""
						placeholder={t('Date')}
					/>
				</View> */}
			</View>
			{isLessThanDesktopBase && (
				<View
					style={[
						styles.tabContainer,
						{
							justifyContent: 'center',
							marginTop: getResponsiveStyle(25),
						},
					]}
				>
					{SECTION_TABS.map(tab => (
						<Pressable
							key={tab.value}
							onPress={() => onPress(tab.value)}
						>
							<Text
								style={styleTab(
									selectedTab,
									tab.value,
									width,
									primary
								)}
							>
								{tab.label}
							</Text>
						</Pressable>
					))}
				</View>
			)}
			{selectedTab != 'Withdraw' && (
				<View style={styles.tableContainer}>
					{transaction && transaction.length > 0 ? (
						<Table
							content={transaction}
							headers={HEADERS}
							type="profile"
						/>
					) : getTransactionsLoading ? (
						<Loader />
					) : (
						<NotAvailableMessage
							message={t('No available Orders')}
						/>
					)}

					{transaction.length < total && (
						<LoadMore
							loading={transactionStatus === 2}
							onClick={onPressViewMore}
						/>
					)}
				</View>
			)}

			<ModalComponent
				type="sidebar"
				header={t('Withdraw')}
				openState={WithdrawModalState}
				onClose={changeWithdrawModalState}
			>
				<WithdrawForm onSubmit={() => {}} />
			</ModalComponent>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(46),
	},
	tabContainer: {
		flexDirection: 'row',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header: {
		fontSize: getResponsiveStyle(24),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],

		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 1,
	},
	tableContainer: {
		marginTop: getResponsiveStyle(50),
	},
});

export default TransactionSection;

const styleTab = (
	selectedTab: string,
	tab: string,
	width: number,
	primary: string[]
) => {
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		selectedTab === tab
			? { ...styles.tabSelected, borderBottomColor: primary[0] }
			: styles.tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};
