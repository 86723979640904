import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import useGetBrandRating from '../../../api/rating/useGetBrandRating';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { createArray } from '../../../utils/createArray';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import CompanyCardVertical from '../../common/company-card-vertical/CompanyCardVertical';
import ProductCard from '../../common/product-card/ProductCard';

type Props = {
	brandDetails: TGetAllBrandsData | undefined;
};

export const SidebarBrandDetails = ({ brandDetails }: Props) => {
	const { executeBrandRatingQuery, data: brandRatingData } =
		useGetBrandRating();

	useEffect(() => {
		executeBrandRatingQuery({
			brandId: brandDetails?._id as string,
		});
	}, []);
	const brandRating = brandRatingData?.getBrandRating?.data?.rating;

	const { t } = useTranslation();
	return (
		<View>
			<View style={{ alignItems: 'center' }}>
				<CompanyCardVertical
					hasRating
					containerStyles={{
						marginBottom: getResponsiveStyle(28),
					}}
					location={brandDetails?.address.city}
					name={brandDetails?.name}
					rating={brandRating}
					imageUrl={brandDetails?.profileImage}
				/>
			</View>
			<View style={[styles.contentContainer, styleContentContainer()]}>
				<Text style={[styleProductSelected(), styles.productSelected]}>
					{t('Products')} ({brandDetails?.products.total})
				</Text>
			</View>
			<View
				style={[
					styles.productListContainer,
					styleProductListContainer(),
				]}
			>
				{brandDetails &&
					brandDetails.products.data &&
					brandDetails.products.data.length > 0 &&
					brandDetails.products.data.map(
						({
							_id,
							images,
							name,
							variants,
							defaultVariant,
							brand,
							rating,
						}) => (
							<ProductCard
								key={_id}
								isLastItem={false}
								size="small"
								hasRating={false}
								containerStyles={styleProductContainer()}
								image={images[0]}
								name={name}
								rating={rating}
								optionValue={
									variants[defaultVariant ?? 0]?.option
										?.values[0]?.name
								}
								price={
									variants[defaultVariant ?? 0]?.option
										?.values[0]?.price
								}
								variant={variants[defaultVariant ?? 0]}
								brand={brand?._id}
							/>
						)
					)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	productListContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'space-between',
	},
	productSelected: {
		color: palettes.dark[4],
	},
	contentContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: '100%',
	},
	container: {
		width: '100vw',
	},
});

function styleProductContainer(): TStyle {
	return {
		marginBottom: getResponsiveStyle(22),
	};
}

function styleProductSelected() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	};
}

function styleContentContainer() {
	return {
		marginBottom: getResponsiveStyle(22),
	};
}

function styleProductListContainer() {
	return {
		marginRight: getResponsiveStyle(-16),
		paddingRight: getResponsiveStyle(-16),
	};
}
