import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../../config';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { CrossIcon } from '../../../common/icons';

export const SelectedSchedual = () => {
	return (
		<View style={[styles.container]}>
			<View style={styles.crossIconContainer}>
				<CrossIcon
					style={styles.crossIcon}
					height={12}
					width={12}
					tintColor={palettes.grey[3]}
				/>
			</View>
			<Text style={styles.day}>Sunday</Text>
			<Text style={styles.week}>10:30 PM / 8:00 PM</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	week: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		color: palettes.grey[0],
	},
	day: {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
		marginBottom: getResponsiveStyle(5),
		color: palettes.dark[4],
	},
	crossIcon: {
		alignSelf: 'flex-end',
		height: 16,
		width: 16,
	},
	crossIconContainer: {
		justifyContent: 'flex-end',
		flexDirection: 'row',
		position: 'absolute',
		right: getResponsiveStyle(6),
		top: getResponsiveStyle(6),
	},
	container: {
		position: 'relative',
		borderRadius: 6,
		padding: getResponsiveStyle(20),
		marginRight: getResponsiveStyle(16),
		marginBottom: getResponsiveStyle(16),
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 6,
		shadowColor: 'rgba(124, 136, 155, 0.06)',
		shadowOpacity: 1,
		borderWidth: 1,
		borderColor: palettes.grey[5],
		flex: 1,
		minWidth: `calc(50% - ${getResponsiveStyle(16)}px)`,
		maxWidth: `calc(50% - ${getResponsiveStyle(16)}px)`,
	},
});
