import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate, Button, Upload } from '../components';
import { palettes } from '../config';
import { useNextStepScreen } from '../hooks/useNextStepScreen';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { TStyle } from '../types/TStyle';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const ProductsUploadScreen = () => {
	const { t } = useTranslation();
	const signupSidebarArray = useAppSelector(
		state => state.ui.SignupSidebarArray
	);
	const { goToNextStepScreen } = useNextStepScreen(signupSidebarArray);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/product-upload'));
	}, []);
	return (
		<AuthTemplate
			heading={t('Products Upload')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<Upload
				hint={t('Drag and drop or browse your files for a .PDF file')}
				label={t('Upload CSV')}
				actionText={t('Download')}
				containerStyles={styleDropzoneContainer()}
				dropzoneStyles={styleDropzone()}
			/>
			<Button
				title={t('Next')}
				variant="primary"
				size="lg"
				outerContainerProps={{
					style: styleButton(),
				}}
				onPress={goToNextStepScreen}
			/>
		</AuthTemplate>
	);
};

export default ProductsUploadScreen;

function styleDropzoneContainer(): TStyle {
	return {
		backgroundColor: `${palettes.grey[4]}10`,
	};
}

function styleDropzone(): TStyle {
	return {
		height: getResponsiveStyle(269, 'dimensions'),
	};
}

function styleButton() {
	return {
		marginTop: getResponsiveStyle(42),
	};
}
