import React, { useCallback, useEffect, useMemo } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import { palettes } from '../../../../config';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import styled from 'rn-css';
import Flag from '../../../common/flag/Flag';
import { FemaleGenderIcon, MaleGenderIcon } from '../../../common/icons';
import { useTranslation } from 'react-i18next';
import { useGetAllEvents } from '../../../../api/events/useGetAllEvents';
import Loader from '../../../common/loader/Loader';

type Props = RoomData;

const ContactCard = ({ userDetails }: Props) => {
	const {
		executeEventsQuery,
		data: eventFindData,
		loading: eventLoading,
	} = useGetAllEvents();

	const eventFindParam = useCallback((userType?: TTypeOfAccount) => {
		switch (userType?.toUpperCase()) {
			case 'HOST':
				return {
					affiliatedHosts: userDetails?._id,
					isHostApplicationAccepted: true,
				};
			case 'SELLER':
				return {
					affiliatedSeller: userDetails?._id,
				};
			case 'CONSUMER':
				return {
					invitedPeople: userDetails?._id,
				};
			default:
				return {};
		}
	}, []);

	useEffect(() => {
		executeEventsQuery(
			eventFindParam(userDetails?.typeOfAccount) as TGetAllEventFind
		);
	}, [userDetails]);
	const numberOfEvent = useMemo(
		() => eventFindData?.getAllEvents?.data?.total,
		[eventFindData]
	);

	const { t } = useTranslation();
	return (
		<View style={{ marginTop: getResponsiveStyle(10) }}>
			{/* <Label {...{ label: "Invite Send" }} /> */}
			<View style={styles.flex1}>
				<View style={styles.userDetail}>
					<View style={styles.imageWithIcon}>
						{userDetails?.personalInformation?.profileImageLink ? (
							<Image
								style={styles.userImg}
								source={{
									uri: userDetails?.personalInformation
										?.profileImageLink,
								}}
							/>
						) : (
							<Image
								style={styles.userImg}
								source={require('../../../../assets/defaultProfileImage.svg')}
							/>
						)}

						<View style={styles.icon}>
							{userDetails?.gender?.toLowerCase() === 'male' && (
								<MaleGenderIcon />
							)}
							{userDetails?.gender?.toLowerCase() ===
								'female' && <FemaleGenderIcon />}
						</View>
					</View>
					<View style={styles.user}>
						<Text style={styles.name}>
							{`${userDetails?.username}`}
						</Text>
						<Text style={styles.userName}>
							@{userDetails?.username}
						</Text>
						<Text
							style={{
								fontSize: getResponsiveStyle(12, 'font'),
								lineHeight: 20,
								marginTop: getResponsiveStyle(2),
							}}
						>
							{userDetails?.typeOfAccount && (
								<Flag
									countryCode={
										userDetails?.personalInformation
											?.address?.countryCode
									}
								/>
							)}
							<Text
								style={{
									fontSize: getResponsiveStyle(12, 'font'),
									lineHeight: 20,
									color: palettes.dark[4],
								}}
							>
								{
									userDetails?.personalInformation?.address
										?.city
								}
								,
								{
									userDetails?.personalInformation?.address
										?.countryLabel
								}
							</Text>
						</Text>
					</View>
				</View>
				{userDetails?.typeOfAccount && (
					<Text style={{ fontSize: 12 }}>
						{!numberOfEvent ? (
							<Loader size={'small'} />
						) : (
							<>
								{t('Event Attended')} / {numberOfEvent}
							</>
						)}
					</Text>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	name: {
		fontWeight: '600',
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: 18,
		color: palettes.dark[0],
		textTransform: 'capitalize',
	},
	flex1: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		// alignItems: 'center',
		marginTop: '15px',
	},
	icon: {
		position: 'absolute',
		bottom: -8,
		left: '27%',
	},
	action: {
		color: '#F96152',
	},
	user: { justifyContent: 'space-evenly' },
	userImg: {
		height: '56px',
		width: '56px',
		marginRight: '15px',
		borderRadius: 60,
	},
	userDetail: {
		flexDirection: 'row',
	},
	userName: {
		// width: '75%',
		lineHeight: 20,
		fontSize: getResponsiveStyle(12, 'font'),
		color: palettes.dark[4],
		marginTop: getResponsiveStyle(2),
	},
	imageWithIcon: {
		position: 'relative',
	},
});

const AgreementLink = styled.Text`
	color: ${palettes.grey[1]};
	margin-left: 4px;
	margin-right: 4px;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
	&:focus {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

export default ContactCard;
