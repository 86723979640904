import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ShareUpdateInput from '../ShareUpdateInput';
// import { CommentBlockHeader } from './CommentBlockHeader';
import { CommentBlockPost } from './CommentBlockPost';
import { CommnentBlockThread } from './CommnentBlockThread';

type Props = {
	_id: string;
	user: TGetAllUsersData;
	file?: string;
	fileType?: string;
	status?: string;
	createdAt: string;
	rating?: number;
	thread?: any[];
	header?: boolean;
	isRatingType?: boolean;
	isProductCardRating?: boolean;
};

const CommentBlock = ({ thread, header = true, ...status }: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [isThread, setIsThread] = useState(false);
	const [isRefetchComment, setIsRefetchComment] = useState(false);
	const [showCommentInput, setShowCommentInput] = useState(false);
	const [commentOpen, setCommentOpen] = useState(false);
	const [commentControl, setCommentControl] = useState(false);

	const refetchComment = () => {
		setIsThread(!isThread);
		setShowCommentInput(!showCommentInput);
		setCommentOpen(true);
	};

	return (
		<View
			style={[
				styleContainer(),
				{
					paddingTop: header ? getResponsiveStyle(17) : 0,
				},
				status.isProductCardRating && { border: 'none' },
				status.isProductCardRating && {
					paddingTop: 0,
					marginBottom: 0,
				},
			]}
		>
			{/* {header && <CommentBlockHeader />} */}
			<CommentBlockPost
				showCommentBox={() => {
					setShowCommentInput(!showCommentInput);
					setCommentControl(!commentControl);
					setCommentOpen(false);
				}}
				refetchComment={() => setIsRefetchComment(!isRefetchComment)}
				showComment={() => setIsThread(!isThread)}
				commentOpen={commentOpen}
				{...status}
			/>
			{isThread && (
				<CommnentBlockThread
					refetchComment={isRefetchComment}
					{...status}
					level={2}
				/>
			)}
			{commentControl && (
				<ShareUpdateInput
					commentParentId={status._id}
					isCommentInput={true}
					inThread={isThread}
					onSubmit={() => refetchComment()}
				/>
			)}
		</View>
	);
};

const styleContainer = (): TStyle => {
	return [
		styles.container,
		{
			paddingHorizontal: getResponsiveStyle(24),
			// paddingTop:  getResponsiveStyle(17),
			marginBottom: getResponsiveStyle(16),
		},
	];
};

const styles = StyleSheet.create({
	container: {
		borderWidth: 1,
		borderColor: palettes.grey[5],
		borderRadius: 6,
	},
});

export default CommentBlock;
