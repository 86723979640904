import React, { useEffect, useState } from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-web-hover';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import EventProductCard from './EventProductCard';
import { LinearGradient } from 'expo-linear-gradient';
import { LiveEventProductViewProps } from '../../event-section-mobile/ProductView';
import ModalComponent from '../../../common/modal/ModalComponent';
import ProductPriceInfo from './product-detail/ProductPriceInfo';
import ProductDescription from './product-detail/ProductDescription';
import { useTranslation } from 'react-i18next';
import ProductListSkeleton from '../../../common/skeleton-loaders/product-card/productListCardSkeleton';
import ProductView from '../../../common/templates/ProductView';
import { setEventProduct } from '../../../../redux/eventProductDetail';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import { db } from '../../../../services/firebase';
import AsyncStorage from '@react-native-community/async-storage';

type Props = {
	expandHeight?: boolean;
	isMobileView?: boolean;
	loadingSkeleton?: boolean;
	eventDetails: TSingleLiveEventData;
} & LiveEventProductViewProps;

const EventProduct = ({
	expandHeight,
	isMobileView,
	products,
	loadingSkeleton,
	eventDetails,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { loggedInUserDetails } = useAppSelector(state => state.auth);
	const [listedVariants, setVariant] = useState<Variant[]>([]);
	const [product, setProduct] = useState<Product>();
	const [selectedVariant, setSelectedVariant] = useState<PopulatedVariant>();
	const [modalState, setModalState] = useState<boolean>(false);
	const transformToPopulated = (
		variant: Variant,
		product: Product
	): PopulatedVariant => {
		return {
			_id: variant._id,
			variator: variant?.variator,
			value: variant?.value,
			vat: variant?.vat,
			requiresShipping: true,
			image: variant?.image,
			option: variant?.option,
			GTIN: variant?.GTIN,
			associatedProduct: {
				_id: product._id,
				name: product.name,
				shortDescription: product.shortDescription,
				description: product.description,
				category: product.category,
				publishedStatus: product.publishedStatus,
				images: product.images,
				brand: product.brand,
				averagePrice: product?.averagePrice,
				defaultVariant: product?.defaultVariant,
				variants: product?.variants,
			},
		};
	};
	const onChangeVariant = (variant: Variant) => {
		product && setSelectedVariant(transformToPopulated(variant, product));
	};
	const availableVariantCombination: any = (variant: Variant) => {
		const variantWithQuantity = Object.values(variant?.option?.variatorValues?.[0]?.variatorValues || {})?.find((data: any) => data?.quantity >= 1)
		return variantWithQuantity
	}
	const addSelectedProductInTime = async (eventProduct: any) => {
		try {
			await addDoc(
				collection(
					db,
					'eventSelectedProduct',
					eventDetails?._id,
					'product'
				),
				{
					eventProduct,
					createdAt: Timestamp.fromDate(new Date()),
				}
			);
		} catch (error) {
			console.log({ error }, 'why it failed');
		}
	};
	const onPressProduct = (variant: Variant, product: Product) => {
		if (loggedInUserDetails?.typeOfAccount === 'SELLER') {
			dispatch(setEventProduct({}));
			setTimeout(() => {
				dispatch(
					setEventProduct(transformToPopulated(variant, product))
				);
			}, 500);
			addSelectedProductInTime(transformToPopulated(variant, product));
		}
		setSelectedVariant(transformToPopulated(variant, product));
		setVariant(product.variants);
		setProduct(product);

		setModalState(true);
	};

	useEffect(() => {
		AsyncStorage.setItem('countryLabel', eventDetails?.affiliatedBrands?.[0]?.address?.countryLabel);
	}, [])

	// console.log({ price: products?.[0]?.variants?.[0]?.option });


	return (
		<View style={styles.flex1}>
			<ScrollView
				style={[
					styles.container,
					expandHeight && {
						height: `${isMobileView ? '80vh' : '80vh'}`,
					},
				]}
				showsHorizontalScrollIndicator={false}
			>
				{loadingSkeleton && <ProductListSkeleton />}
				{products?.map(product =>
					product?.variants.map(variant => (
						<Pressable
							key={variant?.value}
							onPress={() => onPressProduct(variant, product)}
						>
							<EventProductCard
								shortDesc={product?.shortDescription?.substring(
									0,
									80
								)}
								currencyCountry={eventDetails?.affiliatedBrands?.[0]?.address?.countryLabel}
								price={availableVariantCombination(variant)?.price || variant?.option?.values[0]?.price}
								imageLink={variant?.image?.src}
								productName={product.name}
							/>
						</Pressable>
					))
				)}
			</ScrollView>
			{!isLessThanDesktopBase && (
				<LinearGradient
					colors={[
						'rgba(28, 28, 37, 0) 4.43%',
						'rgb(255, 255, 255) 68.35%',
					]}
					start={{ x: 1, y: 0.5 }}
					end={{ x: 1, y: 1 }}
					style={styles.gradientBox}
				/>
			)}
			{selectedVariant && (
				<ModalComponent
					type={isLessThanDesktopBase ? 'fullpage' : 'sidebar'}
					header={`${t('Product View')}`}
					isProductModal
					openState={modalState}
					isNotScrollable
					onClose={() => setModalState(false)}
				>
					<View style={{ marginVertical: 20 }}>
						<ProductView
							variant={selectedVariant}
							setModalState={setModalState}
							productData={product}
							eventDetails={eventDetails}
						/>
					</View>
				</ModalComponent>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flex: 1,
		marginBottom: 15,
	},
	container: {
		// borderWidth: 2,
		// borderColor: palettes.grey[4],
		// height: '32vh',
		width: '100%',
		overflow: 'scroll',
		position: 'relative',
	},
	gradientBox: {
		width: '100%',
		height: 50,
		position: 'absolute',
		bottom: 0,
	},
	image: {
		width: '100%',
		height: '36vh',
		borderRadius: 4,
	},
});

export default EventProduct;
