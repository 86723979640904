import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	token: string;
};

const initialState: TInitialState = {
	token: '',
};

const socialAuthSlice = createSlice({
	name: 'socialAuth',
	initialState,
	reducers: {
		setSocialAuthToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		},
	},
});

export const { setSocialAuthToken } = socialAuthSlice.actions;

export default socialAuthSlice.reducer;
