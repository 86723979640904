import React, { useState } from 'react';
import {
	View,
	Text,
	Pressable,
	StyleProp,
	TextStyle,
	ViewStyle,
} from 'react-native';
import { ChevronDown } from '../..';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
type Props = {
	heading: string[] | string;
	open: boolean;
	setOpen: (val: boolean) => void;
	textStyle?: StyleProp<TextStyle>;
	viewStyle?: StyleProp<ViewStyle>;
};
const ToggleLine = ({
	heading,
	open,
	viewStyle,
	textStyle = {},
	setOpen,
}: Props) => {
	return (
		<Pressable onPress={() => setOpen(!open)}>
			<View
				style={[
					{
						flexDirection: 'row',
						alignItems: 'center',
						marginRight: 18,
					},
					viewStyle,
				]}
			>
				<Text
					style={[
						{
							fontSize: getResponsiveStyle(14, 'font'),
							lineHeight: getResponsiveStyle(14, 'font'),
							color: palettes.grey[0],
							marginRight: 4,
						},
						textStyle,
					]}
				>
					{heading}
				</Text>
				{!open ? (
					<ChevronDown
						style={{
							width: 8,
							height: 8,
						}}
						onPress={() => setOpen(!open)}
					/>
				) : (
					<ChevronDown
						style={{
							width: 8,
							height: 8,
							transform: [{ rotate: '180deg' }],
							marginTop: -7,
						}}
						onPress={() => setOpen(!open)}
					/>
				)}
			</View>
		</Pressable>
	);
};
export default ToggleLine;
