import React from 'react';
import { View, StyleSheet } from 'react-native';
import { hexToRGB } from '../../../utils/hexToRgba';

type Props = {
	isLastItem: boolean;
};

export const Progress = ({ isLastItem }: Props) => {
	return (
		<View
			style={[
				styles.progressContainer,
				{ marginRight: isLastItem ? 0 : 14 },
			]}
		>
			<View style={styles.progress}></View>
		</View>
	);
};

const styles = StyleSheet.create({
	progressContainer: {
		height: 3,
		marginBottom: 16,
		backgroundColor: hexToRGB('#ffffff', 0.3),
		borderRadius: 999,
		overflow: 'hidden',
		flex: 1,
	},
	progress: {
		width: '70%',
		backgroundColor: 'white',
		height: 3,
		borderRadius: 999,
	},
});
