import { useLazyQuery } from '@apollo/client';
import { GET_USER } from '../../graphql/auth/queries';
import {
	setIsUserLoggedIn,
	setLoggedInUserDetails,
} from '../../redux/authSlice';
import { useAppDispatch } from '../../redux/store';

const useGetUserLazy = () => {
	const dispatch = useAppDispatch();

	const onCompleted = (responseData: TGetUserResponse) => {
		if (responseData?.getUser.success && responseData.getUser.data) {
			const data = responseData.getUser.data;
			dispatch(
				setLoggedInUserDetails({
					isUserLoggedIn: undefined,
					...data,
				})
			);
		}
	};
	const onError = () => {
		dispatch(setIsUserLoggedIn(false));
	};

	const [getUser, { data, loading, error }] = useLazyQuery<TGetUserResponse>(
		GET_USER,
		{
			fetchPolicy: 'no-cache',
			onCompleted,
			onError,
		}
	);

	return {
		getUser,
		data,
		loading,
		error,
	};
};

export default useGetUserLazy;
