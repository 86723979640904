import { useMutation } from '@apollo/client';
import { DELETE_CONVERSATION } from '../../graphql/chat/mutation';

const useDeleteConversation = () => {
	const [deleteConversation, { data, error, loading }] =
		useMutation(DELETE_CONVERSATION);

	const executeDeleteConversationMutation = ({
		conversationId,
	}: {
		conversationId: string;
	}) => {
		deleteConversation({
			variables: {
				conversationId,
			},
		});
	};
	return { executeDeleteConversationMutation, data, error, loading };
};

export default useDeleteConversation;
