import {
	ActivityIndicator,
	StyleSheet,
	Text,
	View,
	ListRenderItem,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import CardComponent from './CardComponent';
import AddCardForm from './AddCardForm';
import ModalComponent from '../../common/modal/ModalComponent';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import EditCardForm from './EditCardForm';
import {
	setCreateCardModalState,
	setEditCardModalState,
} from '../../../redux/getCardsSlice';
import { useTranslation } from 'react-i18next';
import AddCardBankPlaceholder from './AddCardBankPlaceholder';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import DraggableScrollView from '../../common/DraggableScrollView';
import { TStyle } from '../../../types/TStyle';
import { setSelectedCardFn } from '../../../redux/walletSlice';
import { setWasCardUsed } from '../../../redux/userSlice';
interface Props {
	onCheckout?: string;
	orderNow: (args: any) => void;
	chargingLoading?: boolean;
	isShowCardSection?: boolean;
	selectedCard?: string;
	setSelectedCard?: (string: any) => void;
	total?: number;
	maxOrderForDelivery?: number;
	deliveryFee?: number;
	amountToBePaid?: number;
}

export interface TCard {
	cardId: string;
	name: string;
	address: string;
	number: string;
	brand: string;
	default: boolean;
	exp_month: string;
	exp_year: string;
}

const CardSection = ({
	onCheckout,
	orderNow,
	chargingLoading,
	isShowCardSection,
	selectedCard,
	setSelectedCard,
	total,
	maxOrderForDelivery,
	deliveryFee,
	amountToBePaid,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [useNewCard, setUseNewCard] = useState(false);
	// const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const editCardModalState = useAppSelector(
		state => state.userCards.editCardModalState
	);
	const createCardModalState = useAppSelector(
		state => state.userCards.createCardModalState
	);

	const handleAddCard = () => {};
	const handleEditCard = () => {};
	const whichCardToUseText = useNewCard
		? t('Use a saved card')
		: '+' + t('Use a new Card');

	const [viewAll, setViewAll] = useState(false);

	const cards = useAppSelector(state => state.userCards.UserCards);
	const cards_ = viewAll || onCheckout ? cards : cards.slice(0, 2);

	const onBoardDummy = () => {
		return null;
	};
	const primary = usePrimaryColors();

	useEffect(() => {
		dispatch(setWasCardUsed(false));
		dispatch(setSelectedCardFn({} as TCard));
		setSelectedCard && setSelectedCard('');
	}, []);

	const handleCardSelect = (item: TCard) => {
		if (onCheckout) {
			setSelectedCard && setSelectedCard(item?.cardId);
			dispatch(setSelectedCardFn(item));
		}
	};
	const renderItem: ListRenderItem<TCard> = ({ item, index }) => {
		return (
			<CardComponent
				onCheckout={onCheckout && 'yes'}
				key={item.cardId}
				hide="no"
				cardId={item.cardId}
				name={item.name}
				address={item.address}
				paymentMethod={`${item.brand}  ${item.number}`}
				handleSelect={() => handleCardSelect(item)}
				selectedCard={selectedCard}
			/>
		);
		// }
	};

	const onPress = () => {
		if (onCheckout) {
			dispatch(setCreateCardModalState());
		} else {
			cards_.length < cards.length
				? setViewAll(!viewAll)
				: dispatch(setCreateCardModalState());
		}
	};

	useEffect(() => {
		if (useNewCard) {
			setSelectedCard && setSelectedCard('');
			dispatch(setSelectedCardFn({} as TCard));
		}
	}, [useNewCard]);

	return (
		<View style={styles.container}>
			<View style={styles.headerContainer}>
				{<Text style={styles.headerText}>{t('Payment Mode')}</Text>}

				{!onCheckout && cards.length && (
					<Text
						style={[
							{
								color: primary[0],
								textDecorationLine: 'underline',
							},
							styles.addText,
						]}
						onPress={() => onPress()}
					>
						{'+' + t('Add New Payment Method')}
					</Text>
				)}

				{onCheckout &&
					(cards.length ? (
						<Text
							style={[
								{
									color: primary[0],
									textDecorationLine: 'underline',
								},
								styles.addText,
							]}
							onPress={() => setUseNewCard(!useNewCard)}
						>
							{whichCardToUseText}
						</Text>
					) : null)}
			</View>

			<DraggableScrollView
				data={cards}
				horizontal
				showsHorizontalScrollIndicator={false}
				renderItem={renderItem as any}
				style={styleDraggableScroll()}
			/>
			{!cards.length && !onCheckout ? (
				<AddCardBankPlaceholder
					text={t('Card')}
					onboard={onBoardDummy}
					setModalState={setCreateCardModalState}
				/>
			) : (
				onCheckout && (
					<AddCardForm
						orderNow={data => orderNow(data)}
						onSubmit={handleAddCard}
						onCheckout
						chargingLoading={chargingLoading}
						isShowCardSection={isShowCardSection}
						selectedCard={selectedCard}
						showForm={cards.length <= 0 || useNewCard}
						total={total}
						deliveryFee={deliveryFee}
						maxOrderForDelivery={maxOrderForDelivery}
						amountToBePaid={amountToBePaid}
					/>
				)
			)}

			<ModalComponent
				type="sidebar"
				header={`${t('Add Credit Card')}`}
				openState={createCardModalState}
				onClose={() => dispatch(setCreateCardModalState())}
			>
				<AddCardForm
					showForm
					isShowCardSection
					orderNow={orderNow}
					onSubmit={handleAddCard}
				/>
			</ModalComponent>

			<ModalComponent
				type="sidebar"
				header={`${t('Edit Credit Card')}`}
				openState={editCardModalState}
				onClose={() => dispatch(setEditCardModalState())}
			>
				<EditCardForm onSubmit={handleEditCard} />
			</ModalComponent>
		</View>
	);
};

const styles = StyleSheet.create({
	indicatorContainer: {
		height: '500px',
	},
	addText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		// color: palettes.grey[1],
		cursor: 'pointer',
	},
	container: {
		width: '100%',
		// marginTop: 43,
	},
	container_: {
		flexDirection: 'row',
		zIndex: 1,
		position: 'relative',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(24, 'font'),
		marginBottom: getResponsiveStyle(14, 'spacing'),
	},
	contentContainer: {
		flexDirection: 'row',
		gap: getResponsiveStyle(24),
		flexWrap: 'wrap',
		alignItems: 'center',
		// marginTop: getResponsiveStyle(24),
		display: 'flex',
	},
	selectText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(16, 'font'),
	},
	noCardText: {
		fontWeight: '400',
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(25, 'font'),
		color: palettes.grey[0],
	},
});

const styleDraggableScroll = (): TStyle => {
	return [
		styles.container_,
		{
			width: '100%',
			paddingBottom: getResponsiveStyle(20),
		},
	];
};

export default CardSection;
