import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const MicOutlineIcon = (props: SvgProps) => (
	<Svg width={16} height={16} fill="none" viewBox="0 0 16 16" {...props}>
		<Path
			d="M11.319 13.38a6.704 6.704 0 0 1-2.569.829v3.041h-1.5v-3.041A6.752 6.752 0 0 1 1.291 8.25h1.511a5.251 5.251 0 0 0 7.403 4.015l-1.163-1.162A3.75 3.75 0 0 1 4.25 7.5V6.31L.045 2.106l1.061-1.061 14.85 14.85-1.062 1.06-3.575-3.576ZM5.775 7.834l1.89 1.89a2.251 2.251 0 0 1-1.89-1.89Zm7.756 3.535-1.082-1.081a5.208 5.208 0 0 0 .748-2.039h1.512a6.713 6.713 0 0 1-1.178 3.12ZM11.35 9.188l-1.161-1.16c.04-.17.062-.346.062-.528v-3a2.25 2.25 0 0 0-4.364-.774L4.764 2.603A3.75 3.75 0 0 1 11.75 4.5v3c0 .586-.136 1.165-.4 1.688h-.001Z"
			fill="#fff"
		/>
	</Svg>
);

export default MicOutlineIcon;
