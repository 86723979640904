import React from 'react';
import { Image, View, StyleSheet, StyleSheetProperties } from 'react-native';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

type Props = {
	uri: string;
	marginLeft?: number;
	marginRight?: number;
};

const Timeline = ({ uri, marginLeft, marginRight }: Props) => {
	const primary = usePrimaryColors();
	return (
		<View
			style={{
				marginLeft,
				marginRight,
				height: 65,
				width: 65,
				justifyContent: 'center',
				alignItems: 'center',
				borderStyle: 'solid',
				borderRadius: 9999,
				borderWidth: 1,
				borderColor: primary[0],
			}}
		>
			<Image source={{ uri }} style={styles.innerCircle} />
		</View>
	);
};

const styles = StyleSheet.create({
	outerCircle: {},
	innerCircle: {
		// backgroundColor: palettes.grey[6],
		justifyContent: 'center',
		alignItems: 'center',
		padding: 3,
		height: 57,
		width: 57,
		borderRadius: 9999,
	},
});

export default Timeline;
