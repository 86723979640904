import { TUseNavigation } from '../../types/exportedTypes';

export const navigateToLiveEvent = (
	navigation: TUseNavigation,
	eventId: string
) => {
	navigation.navigate('LiveEvent', {
		eventId,
	});
};
