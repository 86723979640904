import React from 'react';
import DropDownPicker, {
	DropDownPickerProps,
} from 'react-native-dropdown-picker';
import { ChevronDown } from '../..';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {} & DropDownPickerProps;

const DropdownSmall = ({
	containerStyle,
	textStyle,
	style,
	dropDownContainerStyle,
	listItemContainerStyle,
	open,
	setOpen,
	onChangeValue,
	...props
}: Props) => {
	const primary = usePrimaryColors();
	return (
		<DropDownPicker
			{...props}
			zIndex={2000}
			zIndexInverse={2000}
			listMode="SCROLLVIEW"
			showTickIcon={false}
			disableBorderRadius={false}
			open={open}
			setOpen={setOpen}
			onChangeValue={onChangeValue}
			scrollViewProps={{
				showsVerticalScrollIndicator: false,
				showsHorizontalScrollIndicator: false,
			}}
			style={[
				{
					flexDirection: 'row',
					alignItems: 'center',
				},
				style,
			]}
			textStyle={[
				{
					fontSize: getResponsiveStyle(14, 'font'),
					lineHeight: getResponsiveStyle(14, 'font'),
					color: palettes.grey[0],
					marginRight: 4,
				},
				textStyle,
			]}
			containerStyle={[
				{
					width: 'max-content',
				},
				containerStyle,
			]}
			dropDownContainerStyle={[
				{
					padding: getResponsiveStyle(6),
					paddingLeft: 0,
					borderRadius: 6,
					marginTop: getResponsiveStyle(8),
					borderColor: palettes.grey[3],
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
					width: 'auto',
					shadowOffset: {
						width: 0,
						height: 15,
					},
					shadowRadius: 25,
					shadowColor: 'rgba(124, 136, 155, 0.12)',
					shadowOpacity: 1,
					zIndex: 999,
				},
				dropDownContainerStyle,
			]}
			listItemContainerStyle={[
				{
					paddingBottom: getResponsiveStyle(6),
					// height: getResponsiveStyle(25, 'dimensions'),
					paddingHorizontal: getResponsiveStyle(8),
					borderLeftWidth: 2,
					borderLeftColor: 'transparent',
				},
				listItemContainerStyle,
			]}
			listItemLabelStyle={{
				display: 'flex',
				alignItems: 'center',
				lineHeight: 20,
			}}
			selectedItemContainerStyle={{
				borderLeftWidth: 2,
				borderLeftColor: primary?.[0],
			}}
			selectedItemLabelStyle={{
				color: palettes.dark[0],
			}}
			ArrowDownIconComponent={() => (
				<ChevronDown
					style={{
						width: 8,
						height: 8,
					}}
					onPress={() => setOpen(!open)}
				/>
			)}
			ArrowUpIconComponent={() => (
				<ChevronDown
					style={{
						width: 8,
						height: 8,
						transform: [{ rotate: '180deg' }],
						marginTop: -7,
					}}
					onPress={() => setOpen(!open)}
				/>
			)}
		/>
	);
};

export default DropdownSmall;
