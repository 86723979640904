import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const StarHalfIcon = (props: SvgProps) => {
	return (
		<Svg width={15} height={14} fill="none" viewBox="0 0 15 14" {...props}>
			<Path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m7.525 0 2.274 4.608 5.087.743-3.68 3.585.868 5.064-4.549-2.392L2.976 14l.869-5.064-3.68-3.585 5.086-.743L7.525 0Zm2.13 5.597 3.082.45-2.23 2.173a1 1 0 0 0-.287.885l.526 3.067-2.756-1.45a1 1 0 0 0-.49-.114V2.31l.025-.05 1.378 2.79a1 1 0 0 0 .752.547Z"
				fill="#FEBF3B"
			/>
		</Svg>
	);
};

export default StarHalfIcon;
