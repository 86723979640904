import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable, Share, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { palettes } from '../../../config';
import {
	SUBSCRIBE_TO_EVENT,
	UNSUBSCRIBE_FROM_EVENT,
} from '../../../graphql/eventSubscription/mutation';
import { IS_SUBSCRIBED_TO_EVENTS } from '../../../graphql/eventSubscription/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import { useAppSelector } from '../../../redux/store';
import {
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { handleNavigationToEvent } from '../../../utils/handleNavigationToEvent';
import { manipulateString, shortenName } from '../../../utils/manipulateString';
import Button from '../button/Button';
import { ProfileStack } from './ProfileStack';
import { ViewDetailsButton } from './ViewAllButton';
import Flag from '../flag/Flag';
import Ratings from '../ratings/Ratings';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { isEventDone } from '../../../utils/statusOfEvent/isEventDone';
import ShareArrowIcon from '../icons/ShareArrowIcon';

type props = {
	item: TGetAllEventsData;
	canSubscribe?: boolean;
	isSubscribed?: boolean;
	runIsSubscribeCheck?: () => void;
};
const SellerProfile = ({
	affiliatedSeller,
}: {
	affiliatedSeller: TGetAllUsersData;
}) => {
	return (
		<View style={styles.sellerProfile}>
			<View>
				<Image
					source={{
						uri: affiliatedSeller?.personalInformation
							?.profileImageLink,
					}}
					style={styles.sellerProfileImage}
				/>
			</View>
			<View>
				<Text style={{ fontWeight: '600' }}>
					{affiliatedSeller?.username}
				</Text>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{affiliatedSeller?.username && (
						<Flag
							countryCode={
								affiliatedSeller?.personalInformation?.address
									?.countryCode
							}
						/>
					)}
					<Text>
						{
							affiliatedSeller?.personalInformation?.address
								?.countryLabel
						}
					</Text>
				</View>
				<Ratings
					ratings={+affiliatedSeller?.rating}
					paddingBottom={0}
				/>
			</View>
		</View>
	);
};
export const CardBottom = ({
	item,
	canSubscribe,
	isSubscribed,
	runIsSubscribeCheck,
}: props) => {
	const { t } = useTranslation();

	const primary = usePrimaryColors();

	const invitedPeople = useMemo(() => {
		return item?.invitedPeople?.map(i => {
			return i?.personalInformation?.profileImageLink;
		});
	}, [item?.invitedPeople]);

	const dispatch = useDispatch();
	const navigation = useNavigation<TUseNavigation>();
	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const { language } = useAppSelector(state => state.ui);

	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);

	const isConsumerSubscribed = () => {
		return (
			isSubscribed &&
			typeOfAccount === 'CONSUMER' &&
			item?.typeOfEvent === 'VIRTUAL'
		);
	};

	const eventIds = useAppSelector(state => state.getEvent.eventIds);

	const [subscribeToEvent, { loading: subscribeLoading }] = useMutation(
		SUBSCRIBE_TO_EVENT,
		{
			variables: {
				affiliatedEvent: item?._id,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: IS_SUBSCRIBED_TO_EVENTS,
					variables: {
						eventIds,
					},
				},
				// {
				// 	query: GET_PERSONALIZED_EVENTS,
				// 	variables: {
				// 		limit: 10,
				// 		current: 0,
				// 		sort: { startingEventDateTime: 1 },
				// 	},
				// },
			],
			onCompleted: () => runIsSubscribeCheck && runIsSubscribeCheck(),
		}
	);

	const [unSubscribeFromEvent, { loading: unsubscribeLoading }] = useMutation(
		UNSUBSCRIBE_FROM_EVENT,
		{
			variables: {
				affiliatedEvent: item?._id,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: IS_SUBSCRIBED_TO_EVENTS,
					variables: {
						eventIds,
					},
				},
			],
		}
	);

	const handleSubToEvent = () => {
		if (isUserLoggedIn) {
			subscribeToEvent();
		} else {
			dispatch(setTypeOfAccount('CONSUMER'));
			dispatch(setPrimaryColors('CONSUMER'));
			dispatch(setSignupSidebarValues('CONSUMER'));
			navigation.navigate('PersonalInfo');
		}
	};

	const getSubscribeTags = () => {
		return subscribeLoading ? (
			<Button title={`${t('Subscribing')}...`} variant="primary" />
		) : unsubscribeLoading ? (
			<Button title={`${t('Unsubscribing')}...`} variant="primary" />
		) : isSubscribed ? (
			isConsumerSubscribed() ? (
				<Button
					title={`${t('UnSubscribe')}`}
					variant="primary"
					onPress={unSubscribeFromEvent}
				/>
			) : (
				<Button
					title={`${t('UnSubscribe')}`}
					variant="primary"
					onPress={unSubscribeFromEvent}
				/>
			)
		) : (
			<Button
				title={`+${t('Subscribe')}`}
				variant="primary"
				onPress={handleSubToEvent}
			/>
		);
	};

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<View style={[styles.cardBottom, styleCardBottom()]}>
			<View style={[styles.dateContainer, styleDateContainer()]}>
				<Text
					style={[
						styles.dateContainerMonth,
						styleDateContainerMonth(primary),
					]}
				>
					{(item?.startingEventDateTime &&
						dayjs(+item.startingEventDateTime)
							.locale(language === 'fr' ? 'fr' : 'en')
							.format('MMM')) || <Text></Text>}
				</Text>
				<Text
					style={[styles.dateContainerDate, styleDateContainerDate()]}
				>
					{(item?.startingEventDateTime &&
						dayjs(+item.startingEventDateTime).get('date')) || (
						<Text></Text>
					)}
				</Text>
			</View>
			<View style={styles.detailsContainer}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
					}}
				>
					<View style={{ flexDirection: 'column' }}>
						<Pressable
							onPress={() =>
								handleNavigationToEvent({
									event: item,
									navigation,
									loggedInUserDetails,
									dispatch,
								})
							}
						>
							<Text
								style={[
									styles.detailsHeading,
									styleDetailsHeading(),
								]}
								numberOfLines={2}
							>
								{isLessThanDesktopBase
									? shortenName(
											manipulateString.capitalize(
												item?.name
											),
											35
									  )
									: shortenName(
											manipulateString.capitalize(
												item?.name
											),
											25
									  ) || <Text></Text>}
							</Text>
						</Pressable>
						<Text
							style={[styles.detailsText, styleDetailsText()]}
							numberOfLines={2}
						>
							{shortenName(
								manipulateString.capitalize(item?.description),
								30
							) || <Text></Text>}
						</Text>
					</View>
					<View
						style={[
							styles.shareContainer,
							{ backgroundColor: primary?.[0] },
						]}
					>
						<ShareArrowIcon
							width="20"
							height="20"
							fill="#fff"
							position="bottom"
							data={item}
						/>
					</View>
				</View>
				<View style={{ flexDirection: 'column' }}>
					{/* {item?.invitedPeople?.length > 0 && (
						<Text
							style={[
								styles.peopleAreGoing,
								stylePeopleAreGoing(),
							]}
						>
							{t('People are going')}
						</Text>
					)} */}
					<View style={styles.profileStackRow}>
						{/* <View>
							{item?.invitedPeople?.length > 0 && (
								<ProfileStack profiles={invitedPeople} />
							)}
						</View> */}
						<SellerProfile
							affiliatedSeller={item?.affiliatedSeller}
						/>
						{!isEventDone(item) && typeOfAccount !== 'SELLER' && (
							<Button
								title={`${t('Shop')}`}
								variant="primary"
								onPress={() =>
									handleNavigationToEvent({
										event: item,
										navigation,
										loggedInUserDetails,
										dispatch,
									})
								}
								innerContainerProps={{
									style: {
										paddingLeft: '2rem',
										paddingRight: '2rem',
									},
								}}
							/>
						)}
						{isUserLoggedIn && typeOfAccount === 'SELLER' && (
							<ViewDetailsButton event={item} />
						)}
					</View>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	cardBottom: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: 'white',
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		height: '50%',
		zIndex: 9999999999,
	},
	shareContainer: {
		padding: 5,
		borderRadius: 6,
	},
	subScribeText: {
		fontWeight: 'bold',
		color: palettes?.primary?.[0],
	},
	dateContainer: {
		minWidth: 'max-content',
		textTransform: 'uppercase',
		textAlign: 'center',
	},
	dateContainerMonth: {
		fontWeight: '600',
	},
	dateContainerDate: {
		fontWeight: '400',
		color: palettes?.dark?.[0],
	},
	detailsContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		flexShrink: 1,
		justifyContent: 'space-between',
	},
	detailsHeading: {
		fontWeight: '700',
		color: palettes?.dark?.[0],
		marginBottom: 3,
	},
	detailsText: {
		fontWeight: '400',
		color: palettes?.dark?.[4],
		marginBottom: 13,
	},
	peopleAreGoing: {
		fontWeight: '600',
		color: palettes?.dark?.[0],
	},
	profileStackRow: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'center',
	},
	viewDetailsText: {
		fontWeight: '600',
		color: palettes.grey[1],
		textDecorationLine: 'underline',
		fontSize: 14,
		lineHeight: 14,
	},
	sellerProfile: {
		flexDirection: 'row',
		gap: '0.5rem',
		alignItems: 'center',
		marginLeft: '-3rem',
	},
	sellerProfileImage: {
		height: '3rem',
		width: '3rem',
		borderRadius: 10000,
	},
	sellerProfileName: {},
});

const stylePeopleAreGoing = () => {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		marginBottom: getResponsiveStyle(5),
	};
};

const styleDetailsText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
};

const styleDetailsHeading = () => {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
	};
};

const styleDateContainerDate = () => {
	return {
		fontSize: getResponsiveStyle(24, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
	};
};

const styleDateContainerMonth = (primary: string[]) => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		marginBottom: getResponsiveStyle(4),
		color: primary?.[0],
	};
};

const styleDateContainer = () => {
	return { paddingRight: getResponsiveStyle(15) };
};

const styleCardBottom = () => {
	return {
		paddingVertical: getResponsiveStyle(19),
		paddingLeft: getResponsiveStyle(17),
		paddingRight: getResponsiveStyle(17),
	};
};
