import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { SIGN_IN } from '../../graphql/auth/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { setLoggedInUserDetails } from '../../redux/authSlice';
import { useAppDispatch } from '../../redux/store';
import { setLanguage, setPrimaryColors } from '../../redux/uiSlice';
import { TOnErrorApiCall } from '../../types/exportedTypes';
import { useTranslation } from 'react-i18next';
import { formatEmail } from '../../utils/formatEmail';
import { i18n } from '../../i18n';

type Props = {
	_hideAlert?: boolean;
	onCompleted?: (data: TLoginResponse) => void;
	onError?: TOnErrorApiCall;
};

const useLogin = (props?: Props) => {
	const [hideAlert, _] = useState<boolean>(props?._hideAlert ?? false);
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();

	const onError: TOnErrorApiCall = error => {
		if (error) {
			setAlert(error.message, 'danger');
			props?.onError && props?.onError(error);
		}
	};

	const onCompleted = (data: TLoginResponse) => {
		if (data.login.success && data.login.data) {
			const responseData = data.login.data;
			{
				!hideAlert;
			}
			const language =
				responseData.defaultLanguage === 'ENGLISH' ? 'en' : 'fr';
			i18n.changeLanguage(language);
			dispatch(setPrimaryColors(responseData.typeOfAccount));
			dispatch(setLanguage(language as any));
			sessionStorage.setItem('userLoggedIn', 'true');
			dispatch(
				setLoggedInUserDetails({
					isUserLoggedIn: true,
					...responseData,
				})
			);
			props?.onCompleted && props?.onCompleted(data);
		} else {
			setAlert(data.login.message, 'danger');
		}
	};

	const [login, { data, error, loading }] = useMutation<TLoginResponse>(
		SIGN_IN,
		{
			onCompleted,
			onError,
		}
	);

	const executeLoginMutation = ({
		email,
		password,
	}: {
		email: string;
		password: string;
	}) => {
		login({
			variables: {
				email: formatEmail(email),
				password,
			},
		});
	};
	return { executeLoginMutation, data, error, loading };
};

export default useLogin;
