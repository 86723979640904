import React from 'react';
import {
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	useWindowDimensions,
	View,
} from 'react-native';
import { AuthHeading, Logo, Support } from '../components';
import { palettes } from '../config';
import { usePrimaryColors } from '../hooks/usePrimaryColors';

const WelcomeScreen = () => {
	const dimensions = useWindowDimensions();
	const primary = usePrimaryColors();
	const handlePressContinue = () => {
		// TODO: handle continue
		// props.navigation.navigate("Login");
	};

	const handlePressSignUp = () => {
		// TODO: handle sign up
		// props.navigation.navigate("UserSelect");
	};

	const handlePressHelp = () => {
		// TODO: handle help
	};

	return (
		<ScrollView
			contentContainerStyle={styles.container}
			showsVerticalScrollIndicator={false}
		>
			<View
				style={{
					alignItems: 'center',
					marginTop: dimensions.height * 0.16,
				}}
			>
				<Logo width={72} height={65} tint={primary[0]} />
			</View>

			<View
				style={{
					alignItems: 'center',
					marginTop: dimensions.height * 0.06,
					width: 345,
				}}
			>
				<AuthHeading
					title="Welcome Back!"
					text="Your profile is incomplete. Please complete your profile details to get started!"
					authType="login"
				/>
			</View>

			<View
				style={{
					alignItems: 'center',
					marginTop: dimensions.height * 0.04,
				}}
			>
				<TouchableOpacity
					style={{
						...styles.containerButtonContinue,
						backgroundColor: primary[0],
					}}
					onPress={handlePressContinue}
					activeOpacity={0.75}
				>
					<Text style={styles.textButtonContinue}>Continue</Text>
				</TouchableOpacity>
			</View>

			<View
				style={{
					alignItems: 'center',
					marginTop: dimensions.height * 0.27,
				}}
			>
				<TouchableOpacity
					style={{
						...styles.containerButtonSignUp,
						borderColor: primary[0],
					}}
					onPress={handlePressSignUp}
					activeOpacity={0.75}
				>
					<Text
						style={{
							...styles.textButtonSignUp,
							color: primary[0],
						}}
					>
						Sign Up
					</Text>
				</TouchableOpacity>
			</View>

			<View
				style={{
					alignItems: 'center',
					marginTop: dimensions.height * 0.03,
					width: 345,
				}}
			>
				<Support onSubmit={handlePressHelp} />
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		// backgroundColor: "#333",
	},
	containerButtonContinue: {
		justifyContent: 'center',
		width: 345,
		height: 48,
		borderRadius: 6,
	},
	containerButtonSignUp: {
		justifyContent: 'center',
		width: 345,
		height: 48,
		// marginTop: 10,
		// marginBottom: 10,
		borderRadius: 6,
		borderWidth: 1,
		backgroundColor: '#fff',
	},
	textButtonContinue: {
		// fontFamily: "Maison Neue",
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 16,
		lineHeight: 16,
		/* identical to box height */
		textAlign: 'center',
		color: '#fff',
	},
	textButtonSignUp: {
		// fontFamily: "Maison Neue",
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 16,
		lineHeight: 16,
		/* identical to box height */
		textAlign: 'center',
	},
});

export default WelcomeScreen;
