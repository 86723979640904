import { useLazyQuery } from '@apollo/client';
import { GET_TAGS } from '../../graphql/config/queries';

type TQueryArgs = {
	key?: any;
	onCompleted?: (data: any) => void;
};
export const useGetTags = () => {
	const [getAppTags_, { data, loading }] = useLazyQuery(GET_TAGS);

	const getAppTags = ({ key, onCompleted }: TQueryArgs) => {
		getAppTags_({
			variables: {
				key,
			},
			...(onCompleted && { onCompleted: data => onCompleted(data) }),
		});
	};

	return { getAppTags, data, loading };
};
