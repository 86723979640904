import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCard } from '../components/profile/Profile-Wallet/CardSection';

export type Brand_ = {
	_id: string;
	name: string;
	address: {
		city: string;
		countryLabel: string;
	};
};
type Voucher = {
	_id: string;
	brandName: string;
	description: string;
	amountLeft: number;
	country: string;
	brandId: Brand_;
};

export type SelectedVoucher = {
	brandId: Brand_;
	amount: string;
	voucherId: string;
	description: string;
};
type TWalletInitialState = {
	amount: number;
	vouchers: Voucher[] | null;
	withdrawModalState: boolean;
	pendingIncome: number;
	stripePendingBalance: number;
	selectedVoucher: SelectedVoucher;
	selectedCard: TCard;
};

const initialState: TWalletInitialState = {
	amount: 0,
	vouchers: null,
	withdrawModalState: false,
	pendingIncome: 0,
	stripePendingBalance: 0,
	selectedVoucher: {} as SelectedVoucher,
	selectedCard: {} as TCard,
};

const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setWalletAmount(state, action: PayloadAction<number>) {
			state.amount = action.payload;
		},
		setVouchers(state, action: PayloadAction<Voucher[]>) {
			state.vouchers = action.payload;
		},
		setSelectedVoucher(state, action: PayloadAction<SelectedVoucher>) {
			state.selectedVoucher = action.payload;
		},
		setSelectedCardFn(state, action: PayloadAction<TCard>) {
			state.selectedCard = action.payload;
		},
		setWithdrawModalState(state) {
			state.withdrawModalState = !state.withdrawModalState;
		},
		setPendingIncome(state, action: PayloadAction<number>) {
			state.pendingIncome = action.payload;
		},
		setStripePendingBalance(state, action: PayloadAction<number>) {
			state.stripePendingBalance = action.payload;
		},
	},
});

export default walletSlice.reducer;
export const {
	setWalletAmount,
	setVouchers,
	setWithdrawModalState,
	setPendingIncome,
	setStripePendingBalance,
	setSelectedVoucher,
	setSelectedCardFn,
} = walletSlice.actions;
