import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useEffect, useState, useMemo } from 'react';
import { DropzoneProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import * as Yup from 'yup';
import { Button, Checkbox, Input, Upload } from '../../';
import useGetUserLazy from '../../../api/auth/useGetUserLazy';
import { useCreateMedia } from '../../../api/common/useCreateMedia';
import { palettes } from '../../../config';
import { GET_USER } from '../../../graphql/auth/queries';
import { EDIT_USER } from '../../../graphql/profile/mutations';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import {
	setCGUCGVLink,
	setSellerAgreement,
} from '../../../redux/createUserSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { createRequiredLabels } from '../../../utils/createRequiredLabels';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { manipulateString } from '../../../utils/manipulateString';
import { DownloadableFile } from './DownloadableFile';

type TResetFormManually = {
	ids: string[];
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	setFieldError: (field: string, message: string | undefined) => void;
	setFieldTouched: (
		field: string,
		isTouched?: boolean | undefined,
		shouldValidate?: boolean | undefined
	) => void;
};
const IdsToResetAfterEdit = [
	'businessLegalName',
	'companyRegistrationNumber',
	// 'vat',
];

const resetFormManually = ({
	ids,
	setFieldError,
	setFieldTouched,
	setFieldValue,
}: TResetFormManually) => {
	ids.forEach(id => {
		setFieldValue(id, '');
		setFieldError(id, '');
		setFieldTouched(id, false);
	});
};

const validationSchema = Yup.object({
	businessLegalName: Yup.string().min(3, 'Min length: 3'),
	companyRegistrationNumber: Yup.string(),
	// vat: Yup.string(),
	businessAgreement: Yup.boolean().required('Required'),
	CITI_APE_NAF: Yup.string(),
});

type TInitialState = Yup.InferType<typeof validationSchema>;

const initialState: TInitialState = {
	businessLegalName: '',
	companyRegistrationNumber: '',
	// vat: '',
	businessAgreement: false,
	CITI_APE_NAF: '',
};

type Props = {
	isProfile?: boolean;
};

const SellerAgreementForm = ({ isProfile = false }: Props) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();

	const {
		SignupSidebarArray,
		typeOfAccount,
		signedDocuments,
		loggedInUserDetails,
		fileNames,
	} = useAppSelector(state => ({
		fileNames: state.createUser.args.signedDocuments,
		SignupSidebarArray: state.ui.SignupSidebarArray,
		typeOfAccount: state.createUser.args.typeOfAccount,
		signedDocuments: state.createUser.args.signedDocuments,
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	const { goToNextStepScreen } = useNextStepScreen(SignupSidebarArray);

	const [initialStateForEdit, setInitialStateForEdit] =
		useState<TInitialState>(initialState);

	useEffect(() => {
		if (isProfile) {
			// filter empty string values
			setInitialStateForEdit({
				businessLegalName: loggedInUserDetails?.companyLegalName,
				companyRegistrationNumber:
					loggedInUserDetails?.companyRegistrationNumber ?? '',
				// vat: loggedInUserDetails?.vat ?? '',
				CITI_APE_NAF: '',
				businessAgreement: true,
			});
		}
	}, []);

	const onCompleted = (data: TMediaResponse) => {
		if (data.createMedia.success) {
			dispatch(setCGUCGVLink(data.createMedia.data?.name ?? ''));
			setAlert(t('Uploaded successfully'), 'normal');
		} else {
			setAlert(t('Uploading failed'), 'danger');
		}
	};

	const onDrop: DropzoneProps['onDrop'] = selectedFiles => {
		if (selectedFiles.length > 0) {
			selectedFiles.map(files =>
				uploadMedia(files, 'USER', 'signed-document')
			);
		}
	};
	const { uploadMedia, loading: loadingUploadMedia } = useCreateMedia({
		onCompleted,
	});

	let files = useMemo(() => {
		return fileNames;
	}, [onCompleted]);

	const { getUser } = useGetUserLazy();

	const [editUser, { loading: loadingEditUser }] = useMutation<
		TEditUserResponse,
		TEditUserInputs
	>(EDIT_USER, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_USER }],
		onCompleted: data => {
			if (data.editUser.success && data.editUser.data) {
				setAlert(t('Seller agreement information updated!'), 'normal');
				getUser();
			} else {
				setAlert(
					t('Unable to update seller agreement information'),
					'danger'
				);
			}
		},
		onError: () => {
			setAlert(
				t('Unable to update seller agreement information'),
				'danger'
			);
		},
	});

	const handleEditUserSubmit = async (
		values: Omit<TInitialState, 'businessAgreement' | 'CITI_APE_NAF'>
	) => {
		if (
			!(
				(values.businessLegalName && values.companyRegistrationNumber)
				// values.vat
			)
		)
			return;

		return await editUser({
			variables: {
				email: loggedInUserDetails?.email ?? '',
				args: {
					companyLegalName: values.businessLegalName,
					companyRegistrationNumber: values.companyRegistrationNumber,
					// vat: +values.vat,
				},
			},
		});
	};

	const getCGUCGVLabel = () => {
		return (
			<DownloadableFile file="SellerAgreement">
				{t('CGU/CGV Download')}
			</DownloadableFile>
		);
	};

	const getAgreementStatement = () => {
		// const TOC = (
		// 	<DownloadableFile file="TOC">
		// 		{t('TOC PP', { returnObjects: true })[1]}
		// 	</DownloadableFile>
		// );

		const PrivacyPolicy = (
			<DownloadableFile file="PrivacyPolicy">
				<>
					{t('TOC PP', { returnObjects: true })[1]}{' '}
					{t('TOC PP', { returnObjects: true })[2]}{' '}
					{t('TOC PP', { returnObjects: true })[3]}
				</>
			</DownloadableFile>
		);

		return (
			<Text style={[styles.agreementText, styleAgreementText()]}>
				{t('TOC PP', { returnObjects: true })[0]}
				{PrivacyPolicy}
			</Text>
		);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={isProfile ? initialStateForEdit : initialState}
			validationSchema={validationSchema}
			onSubmit={values => {
				const {
					businessLegalName,
					companyRegistrationNumber,
					// vat,
					CITI_APE_NAF,
				} = values;

				dispatch(
					setSellerAgreement({
						businessLegalName,
						companyRegistrationNumber,
						// vat: manipulateString.extractFloatsFromString(vat),
						CITI_APE_NAF: CITI_APE_NAF ?? '',
					})
				);
				goToNextStepScreen();
			}}
		>
			{({
				handleBlur,
				handleChange,
				handleSubmit,
				isValid,
				dirty,
				setFieldValue,
				setFieldError,
				setFieldTouched,
				values,
				touched,
				errors,
			}) => (
				<>
					<Input
						value={values.businessLegalName}
						label={t('Business Legal Name')}
						onChangeText={handleChange('businessLegalName')}
						onBlur={handleBlur('businessLegalName')}
						containerStyles={{
							marginBottom: getResponsiveStyle(24),
						}}
						error={
							touched.businessLegalName &&
							errors.businessLegalName
								? errors.businessLegalName
								: ''
						}
					/>
					<Input
						value={values.companyRegistrationNumber}
						label={t('Company Registration Number')}
						onChangeText={handleChange('companyRegistrationNumber')}
						onBlur={handleBlur('companyRegistrationNumber')}
						containerStyles={{
							marginBottom: getResponsiveStyle(24),
						}}
						keyboardType="numeric"
						error={
							touched.companyRegistrationNumber &&
							errors.companyRegistrationNumber
								? errors.companyRegistrationNumber
								: ''
						}
					/>
					{/* <Input
						value={values.vat}
						label={t('VAT')}
						onChangeText={handleChange('vat')}
						onBlur={handleBlur('vat')}
						containerStyles={{
							marginBottom: getResponsiveStyle(24),
						}}
						error={touched.vat && errors.vat ? errors.vat : ''}
					/> */}
					{!isProfile && (
						<Input
							value={values.CITI_APE_NAF}
							label={t('CITI/APE/NAF')}
							onChangeText={handleChange('CITI_APE_NAF')}
							onBlur={handleBlur('CITI_APE_NAF')}
							containerStyles={{
								marginBottom: getResponsiveStyle(24),
							}}
							error={
								touched.CITI_APE_NAF && errors.CITI_APE_NAF
									? errors.CITI_APE_NAF
									: ''
							}
						/>
					)}
					{!isProfile && (
						<Upload
							label={t('Signed Documents') + '*'}
							actionText={getCGUCGVLabel()}
							hint={
								files.length > 0
									? files.map(i => {
											return <p>{i}</p>;
									  })
									: t(
											'Drag and drop or browse your files for a .PDF file'
									  )
							}
							containerStyles={{
								marginBottom: getResponsiveStyle(24),
							}}
							onDrop={onDrop}
							accept={'.jpg,.pdf,.doc,docx'}
							loading={loadingUploadMedia}
						/>
					)}
					{!isProfile && (
						<Checkbox
							hintText={getAgreementStatement()}
							label={t('Business Agreement')}
							value={values.businessAgreement}
							onChange={value =>
								setFieldValue('businessAgreement', value)
							}
						/>
					)}
					<Button
						title={isProfile ? t('Update') : t('Next')}
						outerContainerProps={{
							style: { marginTop: getResponsiveStyle(70) },
						}}
						variant={
							isValid &&
							(dirty || isProfile) &&
							(values.businessAgreement || isProfile) &&
							(signedDocuments.length > 0 || isProfile)
								? 'primary'
								: 'disabled'
						}
						size="lg"
						loading={loadingEditUser}
						onPress={async () => {
							if (isProfile) {
								await handleEditUserSubmit(values);
								// error when using resetForm from formik
								resetFormManually({
									ids: IdsToResetAfterEdit,
									setFieldError,
									setFieldTouched,
									setFieldValue,
								});
							} else {
								handleSubmit();
							}
						}}
					/>
				</>
			)}
		</Formik>
	);
};
const styles = StyleSheet.create({
	agreementText: {
		color: palettes.dark[4],
	},
});

export default SellerAgreementForm;
function styleAgreementText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	};
}
