import React, { useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';

export type ProductHighLightMarkerProps = {
	totalTime: number;
	highlightedEventProductsTime: number[];
	onPressHighlight: (e: any) => void;
};

const ProductHighLightMarker = ({
	totalTime,
	highlightedEventProductsTime,
	onPressHighlight,
}: ProductHighLightMarkerProps) => {
	return (
		<>
			{highlightedEventProductsTime?.map(data => (
				<Pressable
					onPress={() => onPressHighlight(data)}
					style={{
						left: `${(data / totalTime) * 100}%`,
						bottom: '3%',
						position: 'absolute',
					}}
				>
					<View style={[styles.marker]}></View>
				</Pressable>
			))}
		</>
	);
};

const styles = StyleSheet.create({
	flex1: {
		// flex: 1,
		// marginBottom: 15,
		// width: "40%",
		flexDirection: 'row',
		// position: "relative",
		zIndex: 99999999999999,
	},
	marker: {
		height: 8,
		width: 18,
		borderRadius: 6,
		backgroundColor: palettes.grey[2],
		zIndex: 99999999999999,
		cursor: 'pointer',
	},
});

export default ProductHighLightMarker;
