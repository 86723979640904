const checkIfFloat = (splitAtPeriod: string[]) => {
	return splitAtPeriod.length === 1 ? false : true;
};

export const splitFloat: (num: number, shouldRound?: boolean) => number[] = (
	num,
	shouldRound = false
) => {
	const splitAtPeriod = num.toString().split('.');
	if (!checkIfFloat(splitAtPeriod)) return [+splitAtPeriod[0], 0];

	return splitAtPeriod.map((num, _, arr) =>
		num === arr.at(-1)
			? shouldRound
				? Math.round(+`.${num}`)
				: +`.${num}`
			: +num
	);
};
