import React from 'react';
import { View } from 'react-native';
// import useGetComments from '../../../api/comment/queries';
import PostOrThreadComment from './post-or-thread/PostOrThreadComment';

type Props = {
	_id: string;
	user: TGetAllUsersData;
	file?: string;
	fileType?: string;
	status?: string;
	rating?: number;
	createdAt: string;
	commentOpen?: boolean;
	showComment: () => void;
	showCommentBox: () => void;
	refetchComment: () => void;
	isRatingType?: boolean;
};

export const CommentBlockPost = (status: Props) => {
	return (
		<View>
			<PostOrThreadComment
				{...status}
				showComment={() => status.showComment()}
				inThread={false}
				onSubmit={() => status.refetchComment()}
			/>
		</View>
	);
};
