import React from 'react';
import { palettes } from '../../../config';
import { EyeCloseIcon, EyeOpenIcon } from '../icons';

type Props = {
	isPasswordHidden: boolean;
	onPressEye: () => void;
};

const PasswordHideShow = ({ isPasswordHidden, onPressEye }: Props) => {
	return isPasswordHidden ? (
		<EyeCloseIcon onPress={onPressEye} width={24} height={24} />
	) : (
		<EyeOpenIcon
			onPress={onPressEye}
			width={24}
			height={24}
			fill={palettes.grey[1]}
		/>
	);
};

export default PasswordHideShow;
