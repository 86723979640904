import { default as React, useLayoutEffect, useState } from 'react';
import { FlatList, StyleSheet, useWindowDimensions, View } from 'react-native';
import { EventCard, LoadMore } from '../..';
import { createArray } from '../../../utils/createArray';
import {
	calculateColumnsForEvents,
	shouldAddPlaceholderToEvents,
} from './allEventsSection.utils';
import { EmptyEventPlaceholder } from './EmptyEventPlaceholder';
import { TAddButtonProps } from '../../common/event-card/CardTop';

export type AllEventsDesktopProps = {
	eventsToRender: TGetAllEventsData[] | undefined;
	onPressViewMore?: () => void;
	refetchLoading?: boolean;
	totalEventsToRender?: number | undefined;
	addButtonProps?: TAddButtonProps;
};

export const AllEventsDesktop = ({
	eventsToRender,
	onPressViewMore,
	totalEventsToRender,
	refetchLoading,
	addButtonProps,
}: AllEventsDesktopProps) => {
	const { width } = useWindowDimensions();
	const [flatListColumns, setFlatListColumns] = useState(3);

	useLayoutEffect(() => {
		const result = calculateColumnsForEvents(width);
		setFlatListColumns(result);
	}, [width]);

	return (
		<View style={styles.allEventsContainer}>
			{!!eventsToRender && (
				<FlatList
					numColumns={flatListColumns}
					data={eventsToRender}
					keyExtractor={item => item._id}
					key={flatListColumns}
					columnWrapperStyle={{ justifyContent: 'space-between' }}
					contentContainerStyle={{
						justifyContent: 'space-between',
					}}
					renderItem={({ item, index }) => {
						/**
						 * In a row of items, if index + 1 % columns === 0,
						 * then this means that this is the last item in the row (not the array but row)
						 * Eg. If there are 25 items, index === 4, columns === 5, then
						 * index + 1 % 5 === 0, thus, it is last element in the row
						 */
						const isLastElementInRow =
							(index + 1) % flatListColumns === 0;

						/**
						 * This is to add placeholders at the last row.
						 * The reason for this is because there is justify-center (see columnWrapperStyle).
						 * If the items in the row are not equal to number of columns,
						 * then events will have more space between them and the layout
						 * of the grid will be disturbed. This adds empty placeholders to maintain grid
						 * structure
						 */
						const _shouldAddPlaceholderToEvents =
							shouldAddPlaceholderToEvents(
								eventsToRender?.length,
								flatListColumns
							);

						const shouldAddPlaceholderAtLastIndex =
							_shouldAddPlaceholderToEvents.shouldAddPlaceholder &&
							eventsToRender?.length - 1 === index;

						const placeholdersToAdd = createArray(
							_shouldAddPlaceholderToEvents.placeholdersToAdd
						);

						return (
							<>
								<EventCard
									size="large"
									item={item}
									containerStyles={styleSingleEventWeb(
										isLastElementInRow
									)}
									addButtonProps={addButtonProps}
								/>
								{shouldAddPlaceholderAtLastIndex &&
									placeholdersToAdd.map((_, i) => (
										<EmptyEventPlaceholder
											key={i}
											index={i}
											placeholdersToAddArrayLength={
												placeholdersToAdd.length
											}
										/>
									))}
							</>
						);
					}}
				/>
			)}
			{(() => {
				if (
					eventsToRender?.length >= 24 &&
					eventsToRender?.length < totalEventsToRender
				) {
					return (
						<LoadMore
							loading={refetchLoading}
							onClick={() => onPressViewMore && onPressViewMore()}
						/>
					);
				}

				return null;
			})()}
		</View>
	);
};

const styles = StyleSheet.create({
	allEventsContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		flexGrow: 1,
		paddingBottom: 50,
	},
});

const styleSingleEventWeb = (isLastElement: boolean) => {
	return {
		// marginRight: isLastElement ? 0 : 35,
		width: '440px',
		// minWidth: '30.7%',
		// maxWidth: '30.7%',
		marginRight: isLastElement ? 0 : 16,
		flex: 1,
	};
};
