import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { FemaleGenderIcon, MaleGenderIcon } from '../../common/icons';
import ProfileIcon from '../../common/ProfileIcon';

type Props = {
	gender?: 'male' | 'female';
	showGender?: boolean;
	item?: TGetAllUsersData;
};

export const ProfileImageWithGender = ({
	gender,
	showGender = true,
	item,
}: Props) => {
	return (
		<View style={styles.imageContainer}>
			<ProfileIcon
				imageSrc={item?.personalInformation?.profileImageLink}
				style={styleProfileContainer()}
			/>
			{showGender && (
				<View
					style={[
						styles.userGenderContainer,
						styleUserGenderContainer(),
					]}
				>
					{gender?.toLowerCase() === 'male' && <MaleGenderIcon />}
					{gender?.toLowerCase() === 'female' && <FemaleGenderIcon />}
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	imageContainer: {
		position: 'relative',
		alignSelf: 'center',
	},
	profileImage: {},
	userGenderContainer: {
		position: 'absolute',
		left: '46%',
	},
});

const styleUserGenderContainer = () => {
	return {
		width: getResponsiveStyle(18, 'dimensions'),
		height: getResponsiveStyle(18, 'dimensions'),
		bottom: getResponsiveStyle(-9, 'dimensions'),
		transform: [
			{
				translateX: getResponsiveStyle(-9, 'dimensions'),
			},
		],
	};
};

const styleProfileContainer = () => {
	return {
		width: getResponsiveStyle(80, 'dimensions'),
		height: getResponsiveStyle(80, 'dimensions'),
	};
};
