import { View, Text, Image, StyleSheet } from 'react-native';
import React from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Loader } from '../..';
import Chart from './Chart';
type Props = {
	data1?: number[];
	data2?: number[];
	loading: boolean;
};
const ChartComponent = ({ data1, data2, loading = false }: Props) => {
	return (
		<View style={Styles.container}>
			{data1 && data2 ? (
				<Chart data1={data1} data2={data2} />
			) : (
				loading && <Loader />
			)}
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		width: '100%',
		height: getResponsiveStyle(500),
	},
	image: {
		width: '100%',
		height: '100%',
	},
});
export default ChartComponent;
