import { View, StyleSheet } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
// import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import AllBrands from './AllBrands';
// import TopSellers from '../Top-Sellers/TopSellers';
// import { useQuery } from '@apollo/client';

const ProfileBrands = () => {
	// const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<View style={styles.layout}>
			<View style={styles.content}>
				<AllBrands />
			</View>
			{/* {!isLessThanDesktopBase && (
				<>
					<View style={styles.layoutDivider} />
					<View style={styles.sidebar}>
						<TopSellers />
					</View>
				</>
			)} */}
		</View>
	);
};

const styles = StyleSheet.create({
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		marginTop: getResponsiveStyle(18),
	},
	content: {
		flex: 1,
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
		marginLeft: getResponsiveStyle(40),
	},
	sidebar: {
		flexBasis: getResponsiveStyle(312),
		flexGrow: 0,
		flexShrink: 0,
		paddingHorizontal: getResponsiveStyle(32),
	},
});

export default ProfileBrands;
