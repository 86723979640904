import { gql } from '@apollo/client';
export const GET_GRAPH_STATS = gql`
	query getUserSalesGraphData {
		getUserSalesGraphData {
			statusCode
			success
			message
			data {
				... on GraphDataResponse {
					previousMonth {
						dataPoint
						week
					}
					currentMonth {
						dataPoint
						week
					}
				}
			}
		}
	}
`;
