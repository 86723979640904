import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { RATE_ENTITY } from '../../graphql/rating/mutation';
import { useSetAlert } from '../../hooks/useSetAlerts';

const useRateEntity = () => {
	const { setAlert } = useSetAlert();
	const [rateEntity, states] = useMutation(RATE_ENTITY, {
		onCompleted: data => {
			setAlert(t('Rating successful!'), 'normal');
		},
	});

	const executeRateEntityMutation = ({
		parentId,
		rating,
		review,
		ratingType,
	}: {
		parentId: string;
		rating: number;
		review: string;
		ratingType: string;
	}) => {
		rateEntity({
			variables: {
				args: {
					parentId,
					rating,
					review,
					ratingType,
				},
			},
		});
	};
	return { executeRateEntityMutation, ...states };
};

export default useRateEntity;
