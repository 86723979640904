import { Circle, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { AuthTemplate, Button } from '../..';
import { useCreateUser } from '../../../api/auth';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setPrimaryColors } from '../../../redux/uiSlice';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const WIDTH_OF_MAP = 700;
const MAX_WIDTH_OF_FORM_CONTAINER = 464;

const makeMapCenter = () => {
	return getResponsiveStyle(
		-Math.abs((WIDTH_OF_MAP - MAX_WIDTH_OF_FORM_CONTAINER) / 2)
	);
};

const SettingCatchmentArea = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const {
		signupSidebarArray,
		// location
	} = useAppSelector(state => ({
		signupSidebarArray: state.ui.SignupSidebarArray,
		// location: {
		// 	lat: state.createUser.args.settingCatchmentArea?.lat ?? 0,
		// 	lng: state.createUser.args.settingCatchmentArea?.lng ?? 0,
		// },
	}));
	const { setAlert } = useSetAlert();

	// TODO: hide api key in .env
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDX6Oyc7LdkX1z7jaZQYBXObAK9FCLGzaw',
	});

	const [map, setMap] = React.useState(null);
	const [radius, setRadius] = useState(1500);

	const onLoad = React.useCallback(function callback(map) {
		const bounds = new window.google.maps.LatLngBounds();
		map.fitBounds(bounds);
		setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);
	return (
		<AuthTemplate
			heading={t('Setting Catchment Area')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			authType="signup"
			contentStyles={styleContent()}
		>
			{/* {isLoaded && (
				<GoogleMap
					mapContainerStyle={styleImage()}
					center={{ lat: 31.522159, lng: 74.43763 }}
					zoom={10}
					onLoad={onLoad}
					onUnmount={onUnmount}
					onClick={e => {
						dispatch(
							setSettingCatchmentAreaLocation({
								lat: e.latLng?.lat() ?? 0,
								lng: e.latLng?.lng() ?? 0,
							})
						);
					}}
				>
					<Circle
						center={location}
						radius={radius}
						editable
						options={{
							fillColor: 'rgba(102, 69, 244, 0.3)',
							strokeColor: '#6645F4',
						}}
						onRadiusChanged={function (this: TODO) {
							const updatedRadius = this.getRadius() as number;
							dispatch(
								setSettingCatchmentAreaRadius({
									radius: updatedRadius,
								})
							);
						}}
					/>
				</GoogleMap>
			)} */}
			{/* <Button
				title={t('Done')}
				variant={
					location.lat !== 0 && location.lng !== 0 && Boolean(radius)
						? 'primary'
						: 'disabled'
				}
				size="lg"
				outerContainerProps={{
					style: styleDoneButtonContainer(),
				}}
			/> */}
			<Button title={t('Skip')} variant="outline" size="lg" />
		</AuthTemplate>
	);
};

export default SettingCatchmentArea;
function styleContent(): TStyle {
	return { marginTop: getResponsiveStyle(40) };
}

function styleDoneButtonContainer() {
	return {
		marginBottom: getResponsiveStyle(8),
	};
}

function styleImage() {
	return {
		height: getResponsiveStyle(416, 'dimensions'),
		width: getResponsiveStyle(700, 'dimensions'),
		left: makeMapCenter(),
		marginBottom: getResponsiveStyle(64),
	};
}

const styles = StyleSheet.create({
	image: {
		borderRadius: 6,
	},
});
