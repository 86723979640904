import { useFocusEffect } from '@react-navigation/native';
import { useState } from 'react';

export const useHandleUnmount = () => {
	const [shouldHide, setShouldHide] = useState(false);

	useFocusEffect(() => {
		setShouldHide(false);
		return () => {
			setShouldHide(true);
		};
	});
	return { shouldHideScreen: shouldHide };
};
