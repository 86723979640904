import React from 'react';
import { Pressable } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { TStyle } from '../../../types/TStyle';

type Props = {
	containerStyles?: TStyle;
	onPress?: () => void;
	color?: string;
} & Omit<SvgProps, 'onPress' | 'color'>;

const PlayIcon = ({ containerStyles, onPress, color, ...props }: Props) => {
	return (
		<Pressable style={containerStyles} onPress={onPress}>
			<Svg viewBox="0 0 24 24" {...props}>
				<Path fill="none" d="M0 0h24v24H0z" />
				<Path
					fill={color}
					d="M19.376 12.416 8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"
				/>
			</Svg>
		</Pressable>
	);
};

export default PlayIcon;
