import { StyleSheet } from 'react-native';
import { useDimensions } from 'react-native-web-hooks';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { isAccountHostOrSeller } from './LiveEventSection.utils';

export const LiveEventSectionStyles = StyleSheet.create({
	tilesLoaderContainer: {
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
	},
	desktopHighlightContainer: {
		position: 'absolute',
		height: 50,
		width: 50,
		bottom: '10%',
		left: '2%',
		zIndex: 9999999999999,
	},
	highlightedProductMarkerContainer: {
		position: 'absolute',
		left: '50%',
		transform: [{ translateX: '-50%' }],
		width: '97%',
		bottom: '5%',
		// backgroundColor: 'red',
		zIndex: 9999999999999,
		flexDirection: 'row',
	},
	playButtonContainer: {
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	startLiveStreamContainer: {
		position: 'absolute',
		zIndex: 50000,
		top: '45%',
		left: '42%',
	},
	liveStreamActionButtons: {
		bottom: getResponsiveStyle(32),
		position: 'absolute',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
		flex: 1,
	},
	streamContainer: {
		backgroundColor: '#101625',
		borderRadius: 6,
		overflow: 'hidden',
		position: 'relative',
	},
	container: {
		position: 'relative',
		width: '75%',
		paddingRight: 10,
		paddingLeft: 30,
	},
	eventContent: {
		position: 'absolute',
		width: '100%',
		height: '100vh',
	},
	eventHeader: {
		width: '100%',
		paddingTop: 20,
		zIndex: 1000,
		paddingLeft: 20,
		paddingRight: 20,
	},
	eventProductDiscussion: {
		flex: 1,
		width: '100%',
		paddingTop: 20,
		paddingLeft: 20,
		paddingRight: 20,
	},
	mobileContainer: {
		width: '100%',
		height: '100vh',
		// backgroundColor: 'yellow',
		paddingRight: 0,
		paddingLeft: 0,
	},
	mobileVideo: {
		height: '100vh',
		borderRadius: 0,
	},
	video: {
		borderRadius: 6,
	},
	scrollContainer: {
		height: '85vh',
		overflow: 'scroll',
	},
});

export function stylesLiveStreamContainer(
	videoHeight: string,
	consumerVideoHeight: string,
	typeOfAccount?: TTypeOfAccount
) {
	const { window } = useDimensions();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return [
		{
			minHeight: isAccountHostOrSeller(typeOfAccount)
				? isLessThanDesktopBase
					? window.height
					: +videoHeight || 720
				: +consumerVideoHeight || window.height,
			maxHeight: isAccountHostOrSeller(typeOfAccount)
				? isLessThanDesktopBase
					? window.height
					: 790
				: 790,
		},
		LiveEventSectionStyles.streamContainer,
	];
}
