import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text, Pressable, ScrollView } from 'react-native';
import {
	NotAvailableMessage,
	ProfileCards,
	ProfileList,
	ScrollEventSection,
} from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import SectionHeading from '../SectionHeading';
type Props = {
	searchData: TSearch[];
};
const SearchList = ({ searchData }: Props) => {
	const { t } = useTranslation();
	const { width } = useIsSpecificViewportWidth();
	const primary = usePrimaryColors();
	const [selectedTab, setSelectedTab] = useState<string>('all');
	const [events, setEvents] = useState<TSearch[]>([]);
	const [network, setNetwork] = useState<TSearch[]>([]);
	const [brand, setBrands] = useState<TSearch[]>([]);
	const navigation = useNavigation<TUseNavigation>();
	const TABS = [
		{ label: t('All'), value: 'all' },
		{ label: t('Brands'), value: 'BRAND' },
		{ label: t('Network'), value: 'USER' },
		{ label: t('Events'), value: 'EVENT' },
	];
	useEffect(() => {
		let event = searchData?.filter(
			search => search.searchResultType === 'EVENT'
		);
		let brand = searchData?.filter(
			search => search.searchResultType === 'BRAND'
		);
		let user = searchData?.filter(
			search => search.searchResultType === 'USER'
		);
		setNetwork(user);
		setEvents(event);
		setBrands(brand);
	}, [searchData]);
	const onPress = (tab: string) => {
		setSelectedTab(tab);
	};

	const onPressViewAllForSidebarEvents = () => {
		navigation.navigate('Events');
	};

	const onPressSeeAllForSidebarUsers = () => {
		navigation.navigate('Network');
	};

	const onPressSeeAllForSidebarBrands = () => {
		navigation.navigate('Brands');
	};

	return (
		<View style={{ marginBottom: '80px' }}>
			{searchData && searchData.length && (
				<View style={styles.tabContainer}>
					{TABS.map(tab => (
						<Pressable
							key={tab.value}
							onPress={() => onPress(tab.value)}
						>
							<Text
								style={styleTab(
									selectedTab,
									tab.value,
									width,
									primary
								)}
							>
								{tab.label}
							</Text>
						</Pressable>
					))}
				</View>
			)}
			{!!(selectedTab === 'all' || selectedTab === 'BRAND') && brand && (
				<>
					{brand?.length ? (
						<View style={styles.categoryContainer}>
							<ProfileList
								heading={`${brand.length} ${
									brand.length > 1
										? t('Brands found')
										: t('Brand found')
								}`}
								isBrandProfile
								// hasAddIcon
								onPressSeeAll={onPressSeeAllForSidebarBrands}
								items={brand}
								section="Brand"
								isFollowingArray={[]}
								onClickUnFollowActionButton={() => {}}
							/>
						</View>
					) : (
						selectedTab === 'BRAND' && (
							<View style={styles.categoryContainer}>
								<NotAvailableMessage
									message={`${t('No Brand found')}`}
								/>
							</View>
						)
					)}
				</>
			)}
			{!!(selectedTab === 'all' || selectedTab === 'EVENT') &&
				events &&
				(events?.length ? (
					<View style={styles.categoryContainer}>
						<ScrollEventSection
							hasViewAllLabel
							onPressViewAll={onPressViewAllForSidebarEvents}
							heading={`${events.length} ${
								events.length > 1
									? t('Events found')
									: t('Event found')
							}`}
							events={events as unknown as TGetAllEventCommon[]}
						/>
					</View>
				) : (
					selectedTab === 'EVENT' && (
						<View style={styles.categoryContainer}>
							<NotAvailableMessage
								message={`${t('No event found')}`}
							/>
						</View>
					)
				))}
			{!!(selectedTab === 'all' || selectedTab === 'USER') &&
				network &&
				(network?.length ? (
					<View style={styles.categoryContainer}>
						<SectionHeading
							hasViewAllLabel
							onPressViewAll={onPressSeeAllForSidebarUsers}
							heading={`${network.length} ${
								network.length > 1
									? t('Users found')
									: t('User found')
							}`}
						/>
						<ProfileCards
							data={network as unknown as TGetAllUsersData[]}
							hasActionBtn
							hasStats={false}
							isSearch={true}
							type={'host'}
						/>
					</View>
				) : (
					selectedTab === 'USER' && (
						<View style={styles.categoryContainer}>
							<NotAvailableMessage
								message={`${t('No Profile found')}`}
							/>
						</View>
					)
				))}
		</View>
	);
};
const styles = StyleSheet.create({
	tabContainer: {
		flexDirection: 'row',
		borderBottomWidth: 1,
		borderBottomColor: palettes.grey[5],
	},
	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],

		fontWeight: '400',
	},
	categoryContainer: {
		paddingVertical: getResponsiveStyle(15),
		marginBottom: 'auto',
	},
});
const styleTab = (
	selectedTab: string,
	tab: string,
	width: number,
	primary: string[]
) => {
	let tabSelected = {
		color: palettes.dark[0],
		paddingBottom: 14,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 1,
		borderBottomColor: primary[0],
	};
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
			paddingBottom: getResponsiveStyle(10),
		},
		selectedTab === tab ? tabSelected : styles.tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};
export default SearchList;
