import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AppleIcon = (props: SvgProps) => (
	<Svg fill="none" viewBox="0 0 24 24" {...props}>
		<Path
			d="M16.29 12.726c.033 3.625 3.18 4.832 3.215 4.847-.027.085-.503 1.72-1.658 3.407-.998 1.46-2.035 2.913-3.667 2.943-1.604.03-2.12-.951-3.953-.951-1.834 0-2.407.921-3.925.98-1.576.06-2.775-1.577-3.782-3.031-2.057-2.974-3.63-8.404-1.518-12.07C2.05 7.032 3.924 5.88 5.958 5.85c1.548-.03 3.008 1.04 3.954 1.04.945 0 2.72-1.287 4.585-1.098.781.033 2.974.316 4.381 2.376-.113.07-2.615 1.527-2.588 4.558Zm-3.015-8.901c.837-1.013 1.4-2.422 1.246-3.825-1.206.048-2.664.803-3.528 1.815-.776.897-1.454 2.331-1.271 3.706 1.344.104 2.717-.683 3.553-1.696"
			fill="#CBD3D7"
		/>
	</Svg>
);

export default AppleIcon;
