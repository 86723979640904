import React from 'react';
import { Pressable } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

const InviteAddUserIcon = (props: SvgProps) => {
	const primary = usePrimaryColors();
	return (
		<Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
			<Path
				d="M17.6842 18.5681H17.9666V18.2857V15.1395H19.9282V18.2857V18.5681H20.2105H23.7176V20.2891H20.2105H19.9282V20.5714V23.7176H17.9666V20.5714V20.2891H17.6842H14.1771V18.5681H17.6842ZM0.287257 23.7166C0.331094 22.4659 0.66837 21.2355 1.27938 20.1075C1.93639 18.8946 2.89302 17.8312 4.07814 17.0004C5.26333 16.1696 6.64487 15.594 8.11721 15.3194C9.51618 15.0586 10.9604 15.0763 12.3492 15.3704V17.1561C11.2743 16.8655 10.142 16.7867 9.0285 16.9265C7.78701 17.0823 6.60325 17.5056 5.57678 18.1624C4.55022 18.8193 3.70999 19.6913 3.129 20.7072C2.59565 21.6397 2.29559 22.6677 2.25006 23.7175L0.287257 23.7166ZM10.1053 13.4319C6.04658 13.4319 2.80867 10.4639 2.80867 6.85714C2.80867 3.25043 6.04658 0.282353 10.1053 0.282353C14.1639 0.282353 17.4019 3.25043 17.4019 6.85714C17.4019 10.4639 14.1639 13.4319 10.1053 13.4319ZM10.1053 11.7109C13.0255 11.7109 15.4402 9.56472 15.4402 6.85714C15.4402 4.14957 13.0255 2.00336 10.1053 2.00336C7.185 2.00336 4.77028 4.14957 4.77028 6.85714C4.77028 9.56472 7.185 11.7109 10.1053 11.7109Z"
				fill="#98A2B2"
				stroke="#F2F4F6"
				strokeWidth="0.564706"
			/>
		</Svg>
	);
};

export default InviteAddUserIcon;
