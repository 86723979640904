import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import store from '../redux/store';
const resources = {
	en: {
		translation: en,
	},
	fr: {
		translation: fr,
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: store?.getState()?.ui?.language ?? 'fr',
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
