import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { GET_MEDIA } from '../../../graphql/common/queries';
import { GET_PERSONALIZED_EVENTS } from '../../../graphql/events/queries';
import {
	SUBSCRIBE_TO_EVENT,
	UNSUBSCRIBE_FROM_EVENT,
} from '../../../graphql/eventSubscription/mutation';
import { IS_SUBSCRIBED_TO_EVENTS } from '../../../graphql/eventSubscription/queries';
import { setTypeOfEvent } from '../../../redux/createEventSlice';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import {
	setCurrentEvent,
	setIsAffiliatedHostEditingEvent,
	setIsEditEvent,
	setIsEditEventFromLink,
} from '../../../redux/getEventSlice';
import { useAppSelector } from '../../../redux/store';
import {
	setCreateEventSidebarValues,
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { handleNavigationToEvent } from '../../../utils/handleNavigationToEvent';
import { hexToRGB } from '../../../utils/hexToRgba';
import { isAffiliatedHost } from '../../../utils/isAssociatedWithEvent';
import { isEventDone } from '../../../utils/statusOfEvent/isEventDone';
import { isEevntOngoing } from '../../../utils/statusOfEvent/isEventOngoing';
import { isEventPendingHost } from '../../../utils/statusOfEvent/isEventPendingHost';
import { isEventUpcoming } from '../../../utils/statusOfEvent/isEventUpcoming';
import { EventCardAddHostTag } from './EventCardAddHostTag';
import { EventCardStartsAtDate } from './EventCardStartsAtDate';
import { EventCardTag } from './EventCardTag';
import { EventImagePlaceholder } from './EventImagePlaceholder';
import Logo from '../Logo';
import PlayButton from '../icons/PlayButton';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import useGetMedia from '../../../api/common/useGetMedia';
import EventVideo from '../video-play/Video';

const Image = styled.Image`
	backdrop-filter: blur(60px);
`;

export type TAddButtonProps = {
	onPressAddHostButton: (eventId: string) => void;
	loading: boolean;
	idOfEvent: string;
};

export type EventCardTopProps = {
	item: TGetAllEventsData;
	type?: 'time' | 'published';
	canSubscribe?: boolean;
	isSubscribed?: boolean;
	addButtonProps?: TAddButtonProps;
	runIsSubscribeCheck?: () => void;
};

const BrandImage = memo(({ item }: { item: TGetAllEventsData }) => {
	//TODO:
	// don't forget to apply condition to when the event livestream screen is still available (1 week)
	const isReplayEvent =
		item?.deviceMetadata?.streamRecordingInstance &&
		item?.eventStreamTimeline?.endedAt;

	return (
		<View
			style={{
				height: '4rem',
				width: '100%',
				// backgroundColor: 'red',
				position: 'absolute',
				left: '2%',
				top: '5%',
				paddingRight: '1rem',
				borderRadius: 1000,
				flexDirection: 'row',
				justifyContent: 'space-between',
				// alignItems: 'center',
			}}
		>
			<Image
				source={{
					uri: item?.affiliatedBrands?.[0]?.profileImage,
				}}
				style={[
					styles.image,
					{
						borderRadius: 1000,
						height: '4rem',
						width: '4rem',
						border: '1px solid #fff',
					},
				]}
			/>
			{item?.typeOfEvent === 'VIRTUAL' && (
				<View style={styles.liveTagContainer}>
					<Logo
						tint={palettes?.red[0]}
						style={styles.logo}
						width={15}
						height={15}
						scale={1.3}
						viewBox="0 0 38 38"
					/>
					<View style={styles.liveTag}>
						<Text style={styles.liveTagText}>
							{isReplayEvent ? 'REPLAY' : 'LIVE'}
						</Text>
					</View>
				</View>
			)}
		</View>
	);
});

export const CardTop = ({
	item,
	type = 'time',
	canSubscribe,
	isSubscribed,
	addButtonProps,
	runIsSubscribeCheck,
}: EventCardTopProps) => {
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const loggedInUserId = useAppSelector(
		state => state.auth.loggedInUserDetails?._id
	);

	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	// const { data: mediaDescriptionData } = useQuery(GET_MEDIA, {
	// 	variables: {
	// 		_id: item?.eventMedia && item?.eventMedia[0],
	// 	},
	// });

	const { data: mediaDescriptionData, executeMediaQuery } = useGetMedia();

	// console.log({ mediaDescriptionData });

	useEffect(() => {
		if (item?.eventMedia[0]) {
			executeMediaQuery({
				_id: item?.eventMedia && item?.eventMedia[0],
			});
		}
	}, []);

	const isConsumerSubscribed = () => {
		return (
			isSubscribed &&
			typeOfAccount === 'CONSUMER' &&
			item?.typeOfEvent === 'VIRTUAL'
		);
	};

	const navigation = useNavigation<TUseNavigation>();

	const eventIds = useAppSelector(state => state.getEvent.eventIds);

	const [subscribeToEvent, { loading: subscribeLoading }] = useMutation(
		SUBSCRIBE_TO_EVENT,
		{
			variables: {
				affiliatedEvent: item?._id,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: IS_SUBSCRIBED_TO_EVENTS,
					variables: {
						eventIds,
					},
				},
				// {
				// 	query: GET_PERSONALIZED_EVENTS,
				// 	variables: {
				// 		limit: 10,
				// 		current: 0,
				// 		sort: { startingEventDateTime: 1 },
				// 	},
				// },
			],
			onCompleted: () => runIsSubscribeCheck && runIsSubscribeCheck(),
		}
	);

	// console.log({ isSubscribed, eventName: item?.name });

	const [unSubscribeFromEvent, { loading: unsubscribeLoading }] = useMutation(
		UNSUBSCRIBE_FROM_EVENT,
		{
			variables: {
				affiliatedEvent: item?._id,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: IS_SUBSCRIBED_TO_EVENTS,
					variables: {
						eventIds,
					},
				},
			],
		}
	);

	// console.log({ eventData: item });

	const mediaDescription = mediaDescriptionData?.getMedia?.data;

	const showAddHostButtonBooleanValue = showAddHostButton({
		isHostApplicationOpen: !item?.isHostApplicationAccepted,
		typeOfAccount: typeOfAccount ?? 'CONSUMER',
		isOwnerOfEvent: item?.affiliatedSeller?._id === loggedInUserId,
		event: item,
	});

	const dispatch = useDispatch();

	const handleEditEvent = (page: string) => {
		dispatch(setTypeOfEvent(item.typeOfEvent));
		if (item.typeOfEvent) {
			dispatch(setCurrentEvent(item));
			dispatch(setCreateEventSidebarValues(item?.typeOfEvent));
			dispatch(setIsEditEventFromLink(true));
			dispatch(setIsEditEvent(true));
			dispatch(setIsAffiliatedHostEditingEvent(true));
			navigation.navigate(page as any);
		}
	};

	const getSubscribeTags = () => {
		return subscribeLoading ? (
			<EventCardTag tagType="subscribing" />
		) : unsubscribeLoading ? (
			<EventCardTag tagType="unsubscribing" />
		) : isSubscribed ? (
			isConsumerSubscribed() ? (
				isEevntOngoing(item) ? (
					getOngoingTag()
				) : isEventUpcoming(item) ? (
					getUpcomingTag()
				) : (
					<EventCardTag
						tagType="unsubscribe"
						onPress={() => unSubscribeFromEvent()}
					/>
				)
			) : (
				<EventCardTag
					tagType="unsubscribe"
					onPress={() => unSubscribeFromEvent()}
				/>
			)
		) : (
			// <EventCardTag
			// 	tagType="subscribe"
			// 	onPress={() => subscribeToEvent()}
			// />
			<></>
		);
	};

	const getAddHostTag = () => {
		return <EventCardAddHostTag eventId={item?._id} {...addButtonProps} />;
	};

	const getClosedTag = () => {
		return <EventCardTag tagType="closed" />;
	};

	const getUpcomingTag = () => {
		return (
			<>
				{/* <View style={{ position: 'absolute' }}> */}
				<EventCardTag
					tagType="upcoming"
					style={{
						width: 'max-content',
					}}
				/>
				{/* </View> */}
				{/* {isAffiliatedHost(item, loggedInUserDetails) && (
					<View style={{ position: 'absolute', right: '100px' }}>
						<Pressable
							onPress={() => handleEditEvent('EventInvite')}
						>
							<EventCardTag
								tagType="inviteGuest"
								style={{
									width: 'max-content',
								}}
							/>
						</Pressable>
					</View>
				)} */}
			</>
		);
	};
	const getOngoingTag = () => {
		return <EventCardTag tagType="ongoing" />;
	};

	const Tag = (): JSX.Element | null => {
		if (isEventDone(item)) {
			return getClosedTag();
		}
		// else if (showAddHostButtonBooleanValue && !canSubscribe) {
		// 	// return getAddHostTag();
		// } else if (canSubscribe) {
		// 	// return getSubscribeTags();
		// }
		else if (isEevntOngoing(item)) {
			return getOngoingTag();
		} else if (isEventUpcoming(item)) {
			return getUpcomingTag();
		} else {
			return null;
		}
	};

	const navigateToGuestSignUp = () => {
		dispatch(setTypeOfAccount('CONSUMER'));
		dispatch(setPrimaryColors('CONSUMER'));
		dispatch(setSignupSidebarValues('CONSUMER'));
		navigation.navigate('PersonalInfo');
	};

	return (
		<View style={styles.cardTop}>
			<Pressable
				style={{ height: '100%', width: '100%' }}
				onPress={() =>
					handleNavigationToEvent({
						event: item,
						navigation,
						loggedInUserDetails,
						dispatch,
					})
				}
			>
				{!!item?.eventMedia && !!mediaDescription?.src ? (
					<>
						{mediaDescription.type === 'VIDEO' ? (
							<EventVideo
								data={mediaDescription}
								containerStyles={{
									height: '100%',
									width: '100%',
								}}
								buttonStyle={{ borderRadius: 0 }}
								buttonHeight={35}
								buttonWidth={35}
							/>
						) : (
							<Image
								source={{
									uri:
										item?.eventMedia &&
										mediaDescription?.src,
								}}
								style={styles.image}
							/>
						)}
					</>
				) : (
					<EventImagePlaceholder />
				)}

				{item?.affiliatedBrands?.[0]?.profileImage && (
					<BrandImage item={item} />
				)}

				{!!item?.startingEventDateTime && (
					<EventCardStartsAtDate
						startingEventDateTime={item?.startingEventDateTime}
					/>
				)}
				<Tag />
			</Pressable>
		</View>
	);
};

type TShowAddHostButtonParams = {
	typeOfAccount: TTypeOfAccount;
	isHostApplicationOpen: boolean;
	isOwnerOfEvent: boolean;
	event: TGetAllEventCommon;
};

const showAddHostButton = ({
	typeOfAccount,
	isHostApplicationOpen = true,
	isOwnerOfEvent,
	event,
}: TShowAddHostButtonParams): boolean => {
	return (
		typeOfAccount === 'SELLER' &&
		isHostApplicationOpen &&
		isOwnerOfEvent &&
		isEventPendingHost(event) &&
		event?.isHostRequired
	);
};

export const tagStyle = StyleSheet.create({
	eventMeta: {
		borderRadius: 6,
		paddingVertical: 5,
		paddingHorizontal: 10,
		textTransform: 'uppercase',
		fontWeight: '700',
		fontSize: 10,
		lineHeight: 14,
		textAlign: 'center',
		color: 'white',
		backgroundColor: hexToRGB(palettes.dark[0], 0.2),
	},
	eventStatus: {
		position: 'absolute',
		bottom: 10,
		right: 10,
	},
	eventStartingTime: {
		position: 'absolute',
		left: 10,
		bottom: 10,
	},
});

const styles = StyleSheet.create({
	liveTagContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		height: '1.5rem',
		gap: '0.5rem',
	},
	videoContainer: {
		position: 'relative',
		height: '100%',
		width: '100%',
	},
	playButton: {
		position: 'absolute',
		left: '45%',
		top: '40%',
		zIndex: 10,
	},
	logo: {
		// minHeight: 38,
	},
	cardTop: {
		position: 'relative',
		height: '50%',
		zIndex: 1,
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		overflow: 'hidden',
	},
	eventHeartContainer: {
		position: 'absolute',
		right: 10,
		top: 10,
		backgroundColor: hexToRGB(palettes.dark[0], 0.2),
		borderRadius: 9999,
		padding: 7,
		height: 28,
		width: 28,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		height: '100%',
		width: '100%',
	},
	liveTag: {
		width: '3.5rem',
		height: '1.5rem',
		backgroundColor: palettes.red[0],
		borderRadius: 6,
		justifyContent: 'center',
		alignItems: 'center',
		// marginTop: '0.7rem',
	},
	liveTagText: {
		color: '#fff',
		fontWeight: '600',
	},
});
