import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PlusIcon = ({
	tint = 'rgba(124,136,155,1)',
	...props
}: SvgProps & { tint?: string }) => {
	return (
		<Svg width={24} height={24} viewBox="0 0 24 24" scale={1.7} {...props}>
			<Path fill="none" d="M0 0h24v24H0z" />
			<Path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill={tint} />
		</Svg>
	);
};

export default PlusIcon;
