import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	addressBookLoading: boolean;
	voucherLoading: boolean;
};

const initialState: TInitialState = {
	addressBookLoading: false,
	voucherLoading: false,
};

const loaderSlice = createSlice({
	name: 'loader',
	initialState,
	reducers: {
		setAddressBookLoading(state, action: PayloadAction<boolean>) {
			state.addressBookLoading = action.payload;
		},
		setVoucherLoading(state, action: PayloadAction<boolean>) {
			state.voucherLoading = action.payload;
		},
	},
});

export const { setAddressBookLoading, setVoucherLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
