import { gql } from '@apollo/client';

export const GET_STRIPE_ID = gql`
	query getStripeId($args: GetUserStripeIdInputs) {
		getStripeId(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on StripeId {
						id
					}
				}
			}
		}
	}
`;

export const GET_CONNECT_ACCOUNT = gql`
	query getConnectAccount {
		getConnectAccount {
			... on Response {
				statusCode
				success
				message
				data {
					... on ConnectAccount {
						userId
						connectAccountId
						flag
						lastUrl
						type
						currently_due
					}
				}
			}
		}
	}
`;

export const LIST_CARDS = gql`
	query listCards($args: ListCardsInput!) {
		listCards(args: $args) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						data {
							... on CardsList {
								cardId
								name
								number
								brand
								address
								exp_month
								exp_year
								default
							}
						}
					}
				}
			}
		}
	}
`;

export const LIST_BANKS = gql`
	query listBanks($args: ListCardsInput!) {
		listBanks(args: $args) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						data {
							... on BanksList {
								bankId
								account_name
								account_number
								country
								bank_name
								default
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_BALANCE = gql`
	query getBalance($args: GetBalanceInputs) {
		getBalance(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on ConnectBalance {
						available {
							amount
							currency
						}
						pending {
							amount
							currency
						}
					}
				}
			}
		}
	}
`;

export const GET_PENDING_TRANSFER = gql`
	query getPendingTransfer($args: GetPendingTransfer) {
		getPendingTransfer(args: $args) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on PendingTransfer {
								amount
								sellerId
								corresponingChargeIdsAndAmount {
									chargeId
									amount
								}
							}
						}
					}
				}
			}
		}
	}
`;
