import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setDefaultCard } from '../../redux/getCardsSlice';
import { useLazyQuery } from '@apollo/client';
import { GET_APP_CONFIG } from '../../graphql/config/queries';
import { getWilayaGroupFee } from '../../utils/countryNamesWithCodes';

export const useCheckoutCommon = () => {
	const dispatch = useAppDispatch();

	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	// states
	const [deliveryFee, setDeliveryFee] = useState(0);
	const [maxOrderForDeliveryFee, setMaxOrderForDeliveryFree] = useState(0);
	const [amountToBePaid, setAmountToBePaid] = useState<number>(0);

	let Total = 0;
	usersCart.map(product => {
		Total +=
			product.type === 'Product'
				? product?.price * product.quantity
				: product.starterkitId.price * product.quantity;
	});

	const [getAppConfig_] = useLazyQuery(GET_APP_CONFIG);

	// useEffect(() => {
	//     getAppConfig_({
	//         variables: {
	//             key: 'MAXIMUM_ELIGIBLE_FREE_SHIPPING_AMOUNT',
	//         },
	//         onCompleted: data => {
	//             setDeliveryFee(
	//                 Total < +data?.getAppConfig?.data?.value?.value2
	//                     ? +data?.getAppConfig?.data?.value?.value1
	//                     : 0
	//             );
	//             setMaxOrderForDeliveryFree(
	//                 data?.getAppConfig?.data?.value?.value2
	//             );
	//         },
	//     });
	// }, []);

	// useEffect(() => {
	// 	if (Total < +maxOrderForDeliveryFee) {
	// 		setAmountToBePaid(Total + deliveryFee);
	// 	} else {
	// 		setAmountToBePaid(Total);
	// 	}
	// }, [deliveryFee, maxOrderForDeliveryFee]);

	useEffect(() => {
		setAmountToBePaid(Total + deliveryFee);
	}, [deliveryFee]);

	const cards = useAppSelector(state => state.userCards.UserCards);
	useEffect(() => {
		for (let i = 0; i < cards.length; i++) {
			if (cards[i].default) {
				dispatch(setDefaultCard(cards[i]));
				break;
			}
		}
	}, [cards]);

	const onChangeWilaya = (wilaya: string) => {
		setDeliveryFee(getWilayaGroupFee(wilaya, Total * 10) as number);
	};

	return {
		amountToBePaid,
		deliveryFee,
		maxOrderForDeliveryFee,
		Total,
		setAmountToBePaid,
		onChangeWilaya,
	};
};
