import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate, Button } from '../components';
import EventUploadForm from '../components/create-event/event-upload/EventUploadForm';
import { useNextStepScreen } from '../hooks/useNextStepScreen';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const EventUploadMediaScreen = (props: { navigation: any }) => {
	const { t } = useTranslation();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/event-upload'));
	}, []);
	return (
		<AuthTemplate
			heading={t('Upload Media')}
			subText={t('Lets customize the event!')}
			scrollItems={createEventSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<EventUploadForm />
		</AuthTemplate>
	);
};

export default EventUploadMediaScreen;
