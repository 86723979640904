import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { Button } from '../..';
import { palettes } from '../../../config';
import { ACCEPT_OR_REJECT_INVITATION } from '../../../graphql/eventInvitation.ts/mutations';
import { GET_PERSONALIZED_EVENTS } from '../../../graphql/events/queries';
import { DELETE_NOTIFICATION } from '../../../graphql/notification/mutation';
import { GET_ALL_NOTIFICATIONS } from '../../../graphql/notification/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { setEventsAffiliatedWithFollowing_ } from '../../../redux/getEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { handleNavigationToEvent } from '../../../utils/handleNavigationToEvent/';
import Loader from '../loader/Loader';

type TSize = 'small' | 'large' | 'medium';

type Props = {
	containerStyles?: TStyle;
	dateHasPassed?: boolean;
	size?: TSize;
	eventDetailsContainerStyles?: TStyle;
	eventHeadingStyles?: TStyleInputOrText;
	item?: TGetAllEventsData;
	type?: 'event' | 'notification';
	notificationItem?: NotificationType;
	hovered?: boolean;
	canClick?: boolean;
	hideDay?: boolean;
	removeFromNotification: (id: string) => void;
};

const StyledClearText = styled(Text)`
	font-size: ${getResponsiveStyle(11)}px;
	line-height: ${getResponsiveStyle(11)}px;
	color: ${palettes.red[0]};
	cursor: pointer;
	padding-bottom: 2;

	border-bottom: 1px solid ${palettes.red[0]};
`;

const getSecondsFromDate = 1000;
const getMinuteFromDate = 60000;
const getHourFromDate = 3600000;
const getDayFromDate = 86400000;
const aDay = 24;
const aMinute = 60;
const EventDate = ({
	containerStyles,
	dateHasPassed,
	size = 'large',
	eventDetailsContainerStyles,
	eventHeadingStyles,
	item,
	type,
	notificationItem,
	hovered,
	canClick,
	hideDay = false,
	removeFromNotification,
}: Props) => {
	// const eventId = notificationItem?.eventId;
	const { t } = useTranslation();

	const { setAlert } = useSetAlert();
	const [deleteNotification, { loading }] = useMutation(DELETE_NOTIFICATION, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
		onCompleted: data => {
			// if (data && data.deleteNotification.success) {
			// 	setAlert(t('Notification successfully deleted'), 'normal');
			// } else {
			// 	setAlert(t('Could not delete notification'), 'danger');
			// }
		},
		onError: () => {
			setAlert(t('Could not delete notification'), 'danger');
		},
	});

	const [deleteNotification_] = useMutation(DELETE_NOTIFICATION, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
	});
	const eventIds = useAppSelector(state => state.getEvent.eventIds);

	// const refetchQueries = [
	// 	GET_PERSONALIZED_EVENTS,
	// 	{
	// 		query: IS_SUBSCRIBED_TO_EVENTS,
	// 		variables: {
	// 			eventIds
	// 		}
	// 	},
	// ];

	const [getEventsAffiliatedWithFollowing] = useLazyQuery<
		TGetAllPersonalizedEventsResponse,
		Omit<TPaginatedOptions, 'find'> & { find?: string }
	>(GET_PERSONALIZED_EVENTS, {
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			if (
				data.getPersonalizedEvents &&
				data.getPersonalizedEvents.data &&
				data.getPersonalizedEvents.success
			) {
				dispatch(
					setEventsAffiliatedWithFollowing_([
						// ...eventsAffiliatedWithFollowing,
						...data.getPersonalizedEvents.data.data,
					])
				);
			}
		},
		onError: () => {},
	});

	const [acceptRejectInvitation, { loading: loadingAcceptOrReject }] =
		useMutation(ACCEPT_OR_REJECT_INVITATION, {
			// awaitRefetchQueries: true,
			// refetchQueries: [{query: GET_PERSONALIZED_EVENTS}],
			onCompleted: data => {
				setAlert(
					t('Notification response sent successfully'),
					'normal'
				);
				getEventsAffiliatedWithFollowing({
					variables: {
						limit: 10,
						current: 0,
						sort: { startingEventDateTime: 1 },
					},
				});
				deleteNotification_({
					variables: {
						args: {
							id: notificationItem?._id,
						},
					},
				});
			},
			onError: () => {
				setButtonValue('');
				setAlert(t('An error occured!'), 'danger');
			},
		});

	const dispatch = useAppDispatch();
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);
	const navigation = useNavigation<TUseNavigation>();

	const event: any = {
		_id: notificationItem?.eventId?._id,
		typeOfEvent: notificationItem?.eventId?.typeOfEvent,
		isHostApplicationAccepted:
			notificationItem?.eventId?.isHostApplicationAccepted,
		startingEventDateTime: notificationItem?.eventId?.startingEventDateTime,
		eventStreamTimeline: notificationItem?.eventId?.eventStreamTimeline,
		affiliatedSeller: notificationItem?.eventId?.affiliatedSeller,
		affiliatedBrands: notificationItem?.eventId?.affiliatedBrands,
		affiliatedHosts: notificationItem?.eventId?.affiliatedHosts,
		invitedPeople: notificationItem?.eventId?.invitedPeople,
	};
	const handleNavigation = () => {
		if (notificationItem) {
			handleNavigationToEvent({
				navigation,
				dispatch,
				loggedInUserDetails,
				event,
			});
			// const typeOfEvent: TTypeOfEvent =
			// 	notificationItem?.eventId.typeOfEvent;

			// const isExpired = hasEventExpired(
			// 	ExpireEventAfter7Hours,
			// 	new Date(+notificationItem?.eventId.startingEventDateTime),
			// 	Date.now()
			// );

			// if (isExpired || typeOfEvent === 'PHYSICAL') {
			// 	navigation.navigate('EventDescription', {
			// 		eventId: notificationItem?.eventId._id,
			// 	});
			// } else if (typeOfEvent === 'VIRTUAL' && !isExpired) {
			// 	navigation.navigate('LiveEvent', {
			// 		eventId: notificationItem?.eventId._id,
			// 	});
			// }
		} else if (item) {
			handleNavigationToEvent({
				navigation,
				dispatch,
				loggedInUserDetails,
				event: item,
			});
			// const typeOfEvent: TTypeOfEvent = item.typeOfEvent;

			// const isExpired = hasEventExpired(
			// 	ExpireEventAfter7Hours,
			// 	new Date(+item.startingEventDateTime),
			// 	Date.now()
			// );

			// if (isExpired || typeOfEvent === 'PHYSICAL') {
			// 	navigation.navigate('EventDescription', {
			// 		eventId: item._id,
			// 	});
			// } else if (typeOfEvent === 'VIRTUAL' && !isExpired) {
			// 	navigation.navigate('LiveEvent', {
			// 		eventId: item._id,
			// 	});
			// }
		}
	};

	const [buttonValue, setButtonValue] = useState('');

	const primary = usePrimaryColors();

	const ref = useRef(null);
	const isHovered = useHover(ref);

	return (
		<TouchableOpacity
			onPress={
				canClick
					? () => {
							handleNavigation();
					  }
					: undefined
			}
		>
			<View style={[styles.container, containerStyles]} ref={ref}>
				<View style={styleDateContainer(size)}>
					<Text style={styleDate(size)}>
						{(type === 'event' ? (
							item?.startingEventDateTime &&
							new Date(
								Number(item?.startingEventDateTime)
							).getDate()
						) : (
							<>
								{notificationItem?.eventId
									?.startingEventDateTime &&
									new Date(
										Number(
											notificationItem?.eventId
												?.startingEventDateTime
										)
									).getDate()}
							</>
						)) || <Text></Text>}
					</Text>
					<Text style={styleMonth(dateHasPassed, size)}>
						{(type === 'event' && item?.startingEventDateTime ? (
							new Date(
								Number(item?.startingEventDateTime)
							).toLocaleString('default', { month: 'short' })
						) : (
							<>
								{notificationItem?.eventId
									?.startingEventDateTime &&
									new Date(
										Number(
											notificationItem?.eventId
												?.startingEventDateTime
										)
									).toLocaleString('default', {
										month: 'short',
									})}
							</>
						)) || <Text></Text>}
					</Text>
				</View>
				<View
					style={[
						styles.eventDetailsContainer,
						eventDetailsContainerStyles,
					]}
				>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',

							alignItems: 'flex-start',
						}}
					>
						<Text
							style={[
								styles.eventName,
								styleEventName(size),
								eventHeadingStyles,
							]}
							numberOfLines={1}
						>
							{(type === 'event'
								? item?.name
								: notificationItem?.eventId?.name) || ''}
						</Text>
						{isHovered && type === 'notification' && (
							<TouchableOpacity
								onPress={() => {
									removeFromNotification(
										notificationItem?._id ?? ''
									);
									deleteNotification({
										variables: {
											args: {
												id: notificationItem?._id,
											},
										},
									});
								}}
							>
								<StyledClearText>{t('Clear')}</StyledClearText>
							</TouchableOpacity>
						)}
					</View>
					<Text
						style={styleEventDescription()}
						numberOfLines={
							size === 'large' || size === 'medium' ? 2 : 1
						}
					>
						{type === 'event' && item?.description}
					</Text>
					<Text
						style={styleEventDescription()}
						numberOfLines={
							size === 'large' || size === 'medium' ? 2 : 1
						}
					>
						{type === 'notification' && (
							<>
								<Text>{`${
									notificationItem?.sentBy?.username
								} ${t('has invited you to')} `}</Text>
								<Text
									style={{
										color: primary[0],
										textDecorationLine: 'underline',
									}}
									onPress={() => handleNavigation()}
								>
									{notificationItem?.eventId?.name}
								</Text>
							</>
						)}
					</Text>

					{type === 'notification' ? (
						Math.floor(
							(Date.now() - Number(notificationItem?.createdAt)) /
								getSecondsFromDate
						) < aMinute ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notificationItem?.createdAt)) /
										getSecondsFromDate
								)}
								{''}s {t('ago')}
							</Text>
						) : Math.floor(
								(Date.now() -
									Number(notificationItem?.createdAt)) /
									getMinuteFromDate
						  ) < aMinute ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notificationItem?.createdAt)) /
										getMinuteFromDate
								)}
								{''}m {t('ago')}
							</Text>
						) : Math.floor(
								(Date.now() -
									Number(notificationItem?.createdAt)) /
									getHourFromDate
						  ) < aDay ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notificationItem?.createdAt)) /
										getHourFromDate
								)}
								{''}h {t('ago')}
							</Text>
						) : (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() -
										Number(notificationItem?.createdAt)) /
										getDayFromDate
								)}{' '}
								{t('days ago')}
							</Text>
						)
					) : (
						// for events
						!hideDay &&
						(Math.floor(
							(Date.now() - Number(item?.createdAt)) /
								getSecondsFromDate
						) < aMinute ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() - Number(item?.createdAt)) /
										getSecondsFromDate
								)}
								{''}s {t('ago')}
							</Text>
						) : Math.floor(
								(Date.now() - Number(item?.createdAt)) /
									getMinuteFromDate
						  ) < aMinute ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() - Number(item?.createdAt)) /
										getMinuteFromDate
								)}
								{''}m {t('ago')}
							</Text>
						) : Math.floor(
								(Date.now() - Number(item?.createdAt)) /
									getHourFromDate
						  ) < aDay ? (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() - Number(item?.createdAt)) /
										getHourFromDate
								)}
								{''}h {t('ago')}
							</Text>
						) : (
							<Text style={[styles.eventDate]}>
								{Math.floor(
									(Date.now() - Number(item?.createdAt)) /
										getDayFromDate
								)}{' '}
								{t('days ago')}
							</Text>
						))
					)}
					{type === 'notification' && (
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							{
								<>
									<Button
										title={
											loadingAcceptOrReject &&
											buttonValue === 'accept' ? (
												<Loader size={'small'} />
											) : (
												t('Accept')
											)
										}
										// width={getResponsiveStyle(17)}
										outlineColor={palettes.grey[0]}
										onPress={() => {
											if (!buttonValue) {
												setButtonValue('accept');
												acceptRejectInvitation({
													variables: {
														args: {
															invitation:
																notificationItem?.invitation,
															actionType:
																'ACCEPT',
														},
													},
												});
											} else {
												setAlert(
													t('you already clicked'),
													'warning'
												);
											}
										}}
									/>
									<Button
										title={
											loadingAcceptOrReject &&
											buttonValue === 'reject' ? (
												<Loader size={'small'} />
											) : (
												t('Reject')
											)
										}
										// width={getResponsiveStyle(15)}
										outlineColor={palettes.red[0]}
										onPress={() => {
											if (!buttonValue) {
												setButtonValue('reject');
												acceptRejectInvitation({
													variables: {
														args: {
															invitation:
																notificationItem?.invitation,
															actionType:
																'REJECT',
														},
													},
												});
											} else {
												setAlert(
													'you already clicked',
													'warning'
												);
											}
										}}
									/>
								</>
							}
						</View>
					)}
				</View>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	acceptButton: {
		flex: 1,
	},
	container: {
		flexDirection: 'row',
	},
	eventDate: {
		color: palettes.grey[2],
		marginTop: 6,
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	},
	dateContainer: {
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	date: {
		fontWeight: '700',
		color: palettes.dark[0],
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
	},
	month: {
		textTransform: 'uppercase',
		fontWeight: '600',
		width: '100%',
		textAlign: 'center',
		letterSpacing: 1,
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		borderWidth: 1,
		paddingVertical: 1,
	},
	monthRed: {
		borderColor: palettes.red[0],
		backgroundColor: palettes.red[0],
		color: 'white',
		borderWidth: 1,
	},
	monthGrey: {
		borderColor: palettes.grey[4],
		backgroundColor: palettes.grey[4],
		color: palettes.grey[1],
	},
	eventDetailsContainer: {
		width: 'calc(100% - 72px)',
	},
	eventName: {
		color: palettes.dark[0],
		fontWeight: '700',
	},
	eventDescription: {
		color: palettes.dark[4],
	},
});

export default EventDate;

export const styleDateContainer = (size: TSize) => {
	return [
		styles.dateContainer,
		size === 'large'
			? {
					marginRight: getResponsiveStyle(16),
					width: getResponsiveStyle(72, 'dimensions'),
					height: getResponsiveStyle(64, 'dimensions'),
			  }
			: size === 'medium'
			? {
					marginRight: getResponsiveStyle(16),
					width: getResponsiveStyle(64, 'dimensions'),
					height: getResponsiveStyle(64, 'dimensions'),
			  }
			: size === 'small' && {
					width: getResponsiveStyle(40, 'dimensions'),
					height: getResponsiveStyle(40, 'dimensions'),
					marginRight: getResponsiveStyle(10),
			  },
	];
};

export const styleDate = (size: string) => {
	return [
		styles.date,
		size === 'large' || size === 'medium'
			? {
					fontSize: getResponsiveStyle(24, 'font'),
					lineHeight: getResponsiveStyle(24, 'font'),
			  }
			: {
					fontSize: getResponsiveStyle(16, 'font'),
					lineHeight: getResponsiveStyle(21, 'font'),
			  },
	];
};

export const styleMonth = (
	dateHasPassed: boolean | undefined,
	size: string
) => {
	return [
		styles.month,
		dateHasPassed ? styles.monthGrey : styles.monthRed,
		size === 'large' || size === 'medium'
			? {
					fontSize: getResponsiveStyle(12, 'font'),
					lineHeight: getResponsiveStyle(12, 'font'),
			  }
			: {
					fontSize: getResponsiveStyle(8, 'font'),
					lineHeight: getResponsiveStyle(11, 'font'),
			  },
	];
};
export function styleEventName(size: string) {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(size === 'medium' ? 18 : 24, 'font'),
	};
}

export function styleEventDescription() {
	return [
		styles.eventDescription,
		{
			fontSize: getResponsiveStyle(12, 'font'),
			lineHeight: getResponsiveStyle(16, 'font'),
		},
	];
}
