import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { DropDownPickerSmall } from '../..';
import { EDIT_USER } from '../../../graphql/profile/mutations';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setLanguage } from '../../../redux/uiSlice';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const LanguageDropdownItems = [
	{
		value: 'en',
		label: 'EN',
	},
	{
		value: 'fr',
		label: 'FR',
	},
];

const LanguageSelector = () => {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const language = useAppSelector(s => s.ui.language);
	const { i18n } = useTranslation();

	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	const [editUser, { loading: loadingEditUser }] = useMutation<
		TEditUserResponse,
		TEditUserInputs
	>(EDIT_USER, {
		awaitRefetchQueries: true,
		onCompleted: data => {
			if (data.editUser.success && data.editUser.data) {
			}
		},
		onError: () => {},
	});

	useEffect(() => {
		language && i18n.changeLanguage(language);
	}, [language]);

	const handleLanguageChange = (value: () => TLanguages) => {
		i18n.changeLanguage(value());
		dispatch(setLanguage(value()));
		editUser({
			variables: {
				args: {
					defaultLanguage: value() === 'en' ? 'ENGLISH' : 'FRENCH',
				},
				email: loggedInUserDetails?.email ?? '',
			},
		});
	};

	return (
		<View style={styleContainer()}>
			<DropDownPickerSmall
				items={LanguageDropdownItems}
				open={open}
				setOpen={setOpen}
				value={language}
				setValue={(value: any) => {
					value !== null && handleLanguageChange(value);
				}}
				dropDownContainerStyle={styleDropdownContainer()}
				listItemContainerStyle={styleListItemContainer()}
			/>
		</View>
	);
};

export default LanguageSelector;
function styleContainer() {
	return {
		marginRight: getResponsiveStyle(12),
	};
}

function styleDropdownContainer() {
	return {
		width: getResponsiveStyle(52, 'dimensions'),
		paddingRight: getResponsiveStyle(8),
		paddingTop: getResponsiveStyle(8),
	};
}

function styleListItemContainer() {
	return {
		marginBottom: getResponsiveStyle(8),
	};
}
