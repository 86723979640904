import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useTranslation } from 'react-i18next';
import {
	Image,
	Platform,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	TextInputProps,
	View,
} from 'react-native';
import styled from 'rn-css';
import { Badge, ChevronDown, Input } from '../..';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Label, { LabelProps } from '../label/Label';
import Loader from '../loader/Loader';
import Flag from '../flag/Flag';
import InviteAll from './InviteAll';
import useSearchEventUser from '../../../api/events/useSearchEventUser';

type TDropdownPosition = 'top' | 'bottom';

type Props = {
	inputProps?: Omit<
		TextInputProps,
		'editable' | 'selectTextOnFocus' | 'placeholderTextColor'
	>;
	isMulti?: boolean;
	containerStyles?: TStyle;
	labelProps?: LabelProps;
	values?: TDropdownObject | TDropdownObject[];
	setValue?: React.Dispatch<React.SetStateAction<TODO>>;

	options?: TDropdownObject[];
	isSearchable?: boolean;
	onChangeValue?: (itemSelected: TDropdownObject, index: number) => void;
	error?: string;
	onPressCrossOnBadge?: (value: string) => void;
	loading?: boolean;
	dropdownPosition?: TDropdownPosition;
	fromEventInvite?: boolean;
	InviteAllFollowersByType?: ({ inviteAll }: { inviteAll: boolean }) => void;
	inviteText?: string;
	inviteAllText?: string;
	showInviteAll?: boolean;
	isSearchFromBackend?: boolean;
	typeOfSearch?: string;
	disabledInviteAllButton?: boolean;
	disabledInviteAllByTypeButton?: boolean;
};

const DropdownTextContainer = styled(Pressable)`
	cursor: pointer;
`;

const DropdownText = styled(Text)<{ isItemSelected: boolean }>`
	color: ${({ isItemSelected }) =>
		isItemSelected ? palettes.dark[0] : palettes.grey[0]};
	&:hover {
		color: ${palettes.dark[0]};
	}
`;

const Dropdown = ({
	values: values,
	options: optionsProps = [],
	onChangeValue: onChangeValueProps,
	onPressCrossOnBadge: onPressCrossOnBadgeFromProps,
	isMulti: isMultiProps = false,
	containerStyles,
	inputProps,
	labelProps,
	isSearchable = false,
	setValue,
	error,
	loading = false,
	dropdownPosition = 'bottom',
	fromEventInvite = false,
	InviteAllFollowersByType,
	inviteText,
	inviteAllText,
	showInviteAll,
	disabledInviteAllButton,
	disabledInviteAllByTypeButton,
	isSearchFromBackend,
	typeOfSearch,
}: Props) => {
	delete inputProps?.placeholder;
	delete inputProps?.onBlur;

	const primaryColors = usePrimaryColors();
	const { t } = useTranslation();

	const [showMenu, setShowMenu] = useState(false);
	const [options, setOptions] = useState(optionsProps);
	const [searchQuery, setSearchQuery] = useState('');
	const [isMenuOpened, setIsMenuOpened] = useState(
		showMenu && (!loading ? options.length > 0 : true)
	);
	const [inputContainerHeight, setInputContainerHeight] = useState(
		getResponsiveStyle(64, 'dimensions')
	);
	const [isInputFocused, setIsInputFocused] = useState(false);

	const { executeEventSearchQuery, loading: loading_user } =
		useSearchEventUser({
			onCompleted(data) {
				const users = data?.searchUsers?.data?.data.map((user: any) => {
					return {
						city: user?.personalInformation?.address?.city,
						country:
							user?.personalInformation?.address?.countryLabel,
						countryCode:
							user?.personalInformation?.address?.countryCode,
						label: user?.username,
						profileImage:
							user?.personalInformation?.profileImageLink,
						value: user?._id,
					};
				});
				setOptions(users as any);
			},
		});

	const lastItemIndex = useMemo(() => options.length - 1, [options]);

	const isMulti = isMultiProps ? isMultiProps : Array.isArray(values);

	useEffect(() => {
		const _isMenuOpened =
			showMenu && (!loading ? options.length > 0 : true);
		setIsMenuOpened(_isMenuOpened);
	}, [showMenu, options, optionsProps, loading]);

	useEffect(() => {
		setOptions(optionsProps);
	}, [optionsProps]);

	const onChangeSearchValue = (text: string) => {
		if (isSearchFromBackend) {
			executeEventSearchQuery({
				query: text,
				typeOfAccount: typeOfSearch,
			});
		} else {
			return setOptions(
				optionsProps.filter(
					({ label, value }) =>
						value.toLowerCase().includes(text) ||
						`${label}`.toLowerCase().includes(text)
				)
			);
		}
	};

	const handleHideMenu = () => {
		setShowMenu(false);
	};

	const toggleShowMenu = () => {
		setShowMenu(prev => !prev);
	};

	const isItemSelected = (item: TDropdownObject) => {
		if (isMulti) {
			return values?.some(i => i.value === item.value);
		} else return item.value === values?.value;
	};

	const onChangeValue = useCallback((item: TDropdownObject) => {
		if (!setValue) return;
		isMulti ? setValue([...values, item]) : setValue(item);
	}, []);

	const onPressCrossOnBadge = (value: string) => {
		if (!isMulti || !setValue) return;
		const filteredValues: any = Array.from(values).filter(
			v => v.value !== value
		);
		setValue(filteredValues ?? []);
	};

	return (
		<View style={[styles.container, containerStyles]}>
			{labelProps?.label && <Label {...labelProps} />}
			<ClickAwayListener onClickAway={handleHideMenu}>
				<View
					onLayout={event => {
						setInputContainerHeight(
							event.nativeEvent.layout.height
						);
					}}
					style={[styles.innerContainer]}
				>
					<Pressable
						style={[
							styles.chevronOuterContainer,
							styleChevronOuterContainer(),
						]}
					>
						<ChevronDown
							style={[styles.chevron, outlineNoneForWeb()]}
							containerStyles={[
								styles.chevronInnerContainer,
								styleChevronInnerContainer(),
								outlineNoneForWeb(),
							]}
							onPress={toggleShowMenu}
						/>
					</Pressable>
					{isMulti && (
						<Pressable
							onPress={toggleShowMenu}
							style={[
								styles.multiBadgeOuterContainer,
								showMenu && {
									borderColor: `${primaryColors[0]}20`,
								},
							]}
						>
							<View
								style={[
									styles.multiBadgeInnerContainer,
									styleMultiBadgeInnerContainer(),
									showMenu && {
										borderColor: primaryColors[0],
									},
								]}
							>
								<View style={styles.badgesContainer}>
									{values?.map(({ label, value }, i) => (
										<Badge
											key={value}
											label={label}
											value={value}
											isLastItem={i === value.length - 1}
											onPress={() =>
												onPressCrossOnBadgeFromProps
													? onPressCrossOnBadgeFromProps(
															value
													  )
													: onPressCrossOnBadge(value)
											}
										/>
									))}
								</View>
							</View>
						</Pressable>
					)}
					{!isMulti && (
						<Pressable onPress={toggleShowMenu}>
							<Input
								editable={isSearchable}
								value={
									isInputFocused
										? searchQuery
										: !isMulti && !!values?.value
										? values?.label
										: ''
								}
								selectTextOnFocus={isSearchable}
								onChangeText={text => {
									const _text = text.trim().toLowerCase();
									onChangeSearchValue(_text);
									setSearchQuery(_text);
								}}
								onFocus={() => setIsInputFocused(true)}
								onBlur={() => setIsInputFocused(false)}
								placeholder={
									!isMulti && !!values?.value
										? values?.label
										: inputProps?.placeholder ??
										  `${
												inputProps?.placeholder ||
												t('Search')
										  }...`
								}
								placeholderTextColor={stylePlaceholderTextColor(
									isMulti,
									values
								)}
								innerContainerStyles={styleSearchInputInnerContainer(
									showMenu,
									primaryColors
								)}
								error={error}
								style={[
									styleSearchInput(),
									Platform.OS === 'web' &&
										!isSearchable &&
										({ cursor: 'pointer' } as any),

									showMenu && {
										borderColor: primaryColors[0],
									},
								]}
								{...inputProps}
							/>
						</Pressable>
					)}
					{(isMenuOpened || searchQuery !== '') && (
						<ScrollView
							style={[
								styles.dropdownContainer,
								styleDropdownContainer(
									inputContainerHeight,
									dropdownPosition
								),
							]}
						>
							{loading || loading_user ? (
								<Loader size={'small'} />
							) : (
								<>
									{showInviteAll && (
										<>
											<InviteAll
												InviteAllFollowersByType={() =>
													InviteAllFollowersByType({
														inviteAll: true,
													})
												}
												text={inviteAllText}
												disabled={
													disabledInviteAllButton
												}
											/>
											<InviteAll
												InviteAllFollowersByType={() =>
													InviteAllFollowersByType({
														inviteAll: false,
													})
												}
												text={inviteText}
												disabled={
													disabledInviteAllByTypeButton
												}
											/>
										</>
									)}
									{options.map((item, index) => (
										<DropdownTextContainer
											onPress={() => {
												onChangeValueProps
													? onChangeValueProps(
															item,
															index
													  )
													: onChangeValue(item);
												setShowMenu(false);
												setSearchQuery('');
											}}
											key={item.value}
											style={
												[
													styleDropdownTextContainer(
														index,
														lastItemIndex
													),
													styles.dropdownTextContainer,
													isItemSelected(item) && {
														borderLeftColor:
															primaryColors[0],
													},
												] as TStyle
											}
										>
											<DropdownText
												isItemSelected={isItemSelected(
													item
												)}
												style={styleDropdownText()}
											>
												{!fromEventInvite ? (
													<>{t(item.label as any)}</>
												) : (
													<View
														style={{
															display: 'flex',
															flexDirection:
																'row',
														}}
													>
														<View
															style={[
																styles.image,
																styleImage(
																	'small'
																),
																styles.imageContainer,
															]}
														>
															<Image
																source={
																	!!item.profileImage
																		? {
																				uri: item.profileImage,
																		  }
																		: require('../../../assets/defaultProfileImage.svg')
																}
																style={styleProfileImage(
																	'small'
																)}
															/>
														</View>
														<View
															style={{
																display: 'flex',
																marginTop: 10,
															}}
														>
															<Text
																style={{
																	fontWeight:
																		'700',
																	color: palettes
																		.dark[0],
																	marginBottom: 5,
																}}
															>
																{item.label}
															</Text>
															<View
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'row',
																}}
															>
																<Flag
																	countryCode={
																		item.countryCode
																	}
																/>
																{item.city},
																{item.country}
															</View>
														</View>
													</View>
												)}
											</DropdownText>
										</DropdownTextContainer>
									))}
								</>
							)}
						</ScrollView>
					)}
				</View>
			</ClickAwayListener>
		</View>
	);
};

const styles = StyleSheet.create({
	dropdownTextContainer: {
		display: 'flex',
		justifyContent: 'center',
		borderLeftWidth: 2,
		borderLeftColor: 'transparent',
	},
	dropdownContainer: {
		position: 'absolute',
		left: 3,
		right: 3,
		minHeight: 100,
		borderRadius: 6,
		marginTop: 10,
		backgroundColor: 'white',
		'shadowOffset': {
			'width': 0,
			'height': 15,
		},
		'shadowRadius': 25,
		'shadowColor': 'rgba(124, 136, 155, 0.12)',
		'shadowOpacity': 1,
		borderWidth: 1,
		borderColor: palettes.grey[3],
	},
	badgesContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
	},
	multiBadgeInnerContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		borderRadius: 6,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: 'auto',
	},
	multiBadgeOuterContainer: {
		borderWidth: 3,
		borderRadius: 8,
		borderColor: 'transparent',
	},
	chevronInnerContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 15,
		width: 15,
	},
	chevron: {
		height: 15,
		width: 15,
	},
	chevronOuterContainer: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		zIndex: 5001,
	},
	container: {
		zIndex: 5000,
		width: '100%',
	},
	innerContainer: {
		position: 'relative',
	},

	profileImage: {
		width: getResponsiveStyle(88, 'dimensions'),
		height: getResponsiveStyle(88, 'dimensions'),
		borderRadius: 9999,
	},

	imageContainer: {
		borderRadius: 9999,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: palettes.grey[10],
	},

	image: {
		borderRadius: 9999,
	},
});

export default Dropdown;
function styleDropdownText(): any {
	return {
		lineHeight: getResponsiveStyle(16, 'font'),
		fontSize: getResponsiveStyle(16, 'font'),
	};
}

function styleDropdownTextContainer(index: number, lastItemIndex: number) {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		// marginBottom: index === lastItemIndex ? 0 : getResponsiveStyle(8),
		paddingVertical: getResponsiveStyle(8),
	};
}

function styleDropdownContainer(
	inputContainerHeight: number,
	dropdownPosition: TDropdownPosition
) {
	return {
		paddingVertical: getResponsiveStyle(20),
		maxHeight: getResponsiveStyle(250, 'dimensions'),
		[dropdownPosition === 'top' ? 'bottom' : 'top']: inputContainerHeight,
	};
}

function styleSearchInput() {
	return { paddingRight: getResponsiveStyle(32) };
}

function styleSearchInputInnerContainer(
	showMenu: boolean,
	primaryColors: string[]
): TStyle {
	return {
		borderColor: showMenu ? primaryColors[1] : 'transparent',
		borderRadius: 8,
	};
}

function stylePlaceholderTextColor(
	isMulti: boolean,
	values: TDropdownObject | undefined
) {
	return !isMulti && !!values?.value ? palettes.dark[0] : palettes.grey[0];
}

function styleMultiBadgeInnerContainer() {
	return {
		minHeight: getResponsiveStyle(48, 'dimensions'),
		paddingLeft: getResponsiveStyle(24),
		paddingRight: getResponsiveStyle(32),
		paddingVertical: getResponsiveStyle(16),
	};
}

function outlineNoneForWeb() {
	return (
		Platform.OS === 'web' &&
		({
			outline: 'none',
		} as any)
	);
}

function styleChevronInnerContainer() {
	return {
		marginTop: getResponsiveStyle(8),
	};
}

function styleChevronOuterContainer() {
	return {
		right: getResponsiveStyle(16),
		top: getResponsiveStyle(23) - 7,
	};
}

function styleImage(size: string) {
	return {
		width: getResponsiveStyle(size === 'large' ? 88 : 56, 'dimensions'),
		height: getResponsiveStyle(size === 'large' ? 88 : 56, 'dimensions'),
		marginRight: getResponsiveStyle(10),
	};
}

function styleProfileImage(size: string) {
	return {
		width: getResponsiveStyle(size === 'large' ? 88 : 46, 'dimensions'),
		height: getResponsiveStyle(size === 'large' ? 88 : 46, 'dimensions'),
		borderRadius: 9999,
	};
}
