import countryNamesWithCodes from './countryNamesWithCodes';

export const getCountryLabelFromValue: (code?: string) => string = (
	code = 'gb'
) => {
	return (
		countryNamesWithCodes.find(country => country.value === code)?.label ??
		'United Kingdom'
	);
};
