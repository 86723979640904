import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate } from '../components';
import EventAreaForm from '../components/create-event/event-area/EventAreaForm';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const EventCatchmentArea = () => {
	const { t } = useTranslation();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/catchment-area'));
	}, []);
	return (
		<AuthTemplate
			heading={t('Add Catchment Area')}
			subText={t('Help individuals find the venue?')}
			scrollItems={createEventSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<EventAreaForm />
		</AuthTemplate>
	);
};

export default EventCatchmentArea;
