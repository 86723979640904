import React from 'react';
import { StyleSheet, View } from 'react-native';
import { palettes } from '../../../../config';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../../types/TStyle';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const StatusSkeleton = () => {
	const { isDesktop } = useIsSpecificViewportWidth();
	return (
		<View style={[styleContainer()]}>
			<View
				style={{
					flexDirection: 'row',
					marginTop: getResponsiveStyle(10),
				}}
			>
				<Skeleton
					width={getResponsiveStyle(40)}
					height={getResponsiveStyle(40)}
					circle={true}
					style={styles.image}
				/>
				<View style={{ marginTop: getResponsiveStyle(6) }}>
					<View
						style={{
							justifyContent: 'space-evenly',
							marginLeft: getResponsiveStyle(7.5),
						}}
					>
						<Skeleton
							width={getResponsiveStyle(180)}
							height={getResponsiveStyle(12.5)}
							style={{
								borderRadius: 6,
							}}
						/>
						<Skeleton
							width={getResponsiveStyle(85)}
							height={getResponsiveStyle(12.5)}
							style={styles.radius}
						/>
					</View>
				</View>
			</View>
			<View
				style={[
					styles.status,
					{
						marginLeft: isDesktop ? getResponsiveStyle(50) : 0,
					},
				]}
			>
				<Skeleton
					width={'100%'}
					height={getResponsiveStyle(30)}
					style={{
						borderRadius: 6,
						marginBottom: getResponsiveStyle(12),
					}}
				/>
				<View
					style={[
						styles.textContainer,
						{ marginTop: getResponsiveStyle(1) },
					]}
				></View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginBottom: getResponsiveStyle(15),
		paddingTop: getResponsiveStyle(17),
		borderWidth: 1,
		height: getResponsiveStyle(190),
		borderColor: palettes.grey[5],
		borderRadius: 10,
	},
	textContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottomColor: palettes.grey[5],
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
	image: {
		borderRadius: 9999,
		marginRight: getResponsiveStyle(10),
		marginTop: getResponsiveStyle(20),
	},
	status: {
		marginTop: getResponsiveStyle(10),
	},
	radius: {
		borderRadius: 6,
	},
});

const styleContainer = (): TStyle => {
	return [
		styles.container,
		{
			paddingHorizontal: getResponsiveStyle(24),
			// paddingTop:  getResponsiveStyle(17),
			marginBottom: getResponsiveStyle(16),
		},
	];
};

export default StatusSkeleton;
