import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import { Flag, Ratings } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle, TStyleImage } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { shortenName } from '../../../utils/manipulateString';

type Props = {
	hasRating?: boolean;
	containerStyles?: TStyle;
	imageUrl?: string;
	countryCode?: string;
	name?: string;
	location?: string;
	rating?: string;
};

const CompanyCardVertical = ({
	hasRating,
	containerStyles,
	imageUrl,
	location,
	countryCode,
	name,
	rating,
}: Props) => {
	const { isDesktop, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View
			style={[
				isDesktop
					? styles.companyImageProfileContainerDesktop
					: styles.companyImageProfileContainerMobile,
				containerStyles,
			]}
		>
			<Image
				source={{ uri: imageUrl ?? 'https://via.placeholder.com/500' }}
				style={[
					styles.image,
					isDesktop && styleImageDesktop(),
					isLessThanDesktopBase && styleImageMobile(),
				]}
			/>

			<View
				style={[isDesktop && styles.companyNameLocationRatingContainer]}
			>
				<Text style={[styles.brandName, styleBrandName()]}>
					{name ?? ' '}
				</Text>
				<Text style={[styles.location, styleLocation()]}>
					<>
						<Flag countryCode={countryCode} /> {''}
						{!isDesktop
							? shortenName(location) ?? ' '
							: location ?? ' '}
					</>
				</Text>
				{isLessThanDesktopBase && rating !== null && (
					<Ratings
						ratings={Number(rating) ?? 3}
						paddingBottom={0}
						justifyContent={'flex-start'}
					/>
				)}
			</View>
			{hasRating && rating !== null && (
				<Ratings ratings={Number(rating) ?? 3} />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	companyImageProfileContainerDesktop: {
		flexDirection: 'column',
		alignItems: 'center',
	},
	companyImageProfileContainerMobile: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	companyNameLocationRatingContainer: {
		alignItems: 'center',
	},
	image: {
		borderRadius: 9999,
	},
	brandName: {
		fontWeight: '600',
		color: palettes.dark[0],
	},
	location: {
		fontWeight: '400',
		color: palettes.dark[4],
	},
});

function styleLocation() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: getResponsiveStyle(7),
	};
}

function styleBrandName() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: getResponsiveStyle(3),
	};
}

function styleImageDesktop() {
	return {
		width: getResponsiveStyle(80, 'dimensions'),
		height: getResponsiveStyle(80, 'dimensions'),
		marginBottom: getResponsiveStyle(15),
	};
}

function styleImageMobile(): TStyleImage {
	return {
		width: getResponsiveStyle(54, 'dimensions'),
		height: getResponsiveStyle(54, 'dimensions'),
		marginRight: getResponsiveStyle(13),
	};
}

export default CompanyCardVertical;
