import { useLazyQuery } from '@apollo/client';
import { GET_CONVERSATION_BY_PARTICIPANT } from '../../graphql/chat/queries';

const useGetConversationByParticipants = () => {
	const [getConversationByParticipants, { data, error, loading }] =
		useLazyQuery(GET_CONVERSATION_BY_PARTICIPANT);

	const executeConversationMutation = ({
		participants,
	}: {
		participants: string[];
	}) => {
		getConversationByParticipants({
			variables: {
				participants,
			},
		});
	};
	return { executeConversationMutation, data, error, loading };
};

export default useGetConversationByParticipants;
