import { useLazyQuery } from '@apollo/client';
import { CHECK_USERNAME_EMAIL_AVAILABILITY } from '../../graphql/auth/queries';

type TCheckUsernameEmailAvailabilityArgs = {
	data: {
		username: string;
		email: string;
	};
};

type Params =
	| {
			onCompleted?: TOnCompleteApiCall<TCheckUsernameEmailAvailabilityResponse>;
	  }
	| undefined;

export const useCheckUsernameEmailExists = (params?: Params) => {
	const [verify, states] = useLazyQuery<
		TCheckUsernameEmailAvailabilityResponse,
		TCheckUsernameEmailAvailabilityArgs
	>(CHECK_USERNAME_EMAIL_AVAILABILITY, {
		onCompleted: params?.onCompleted,
	});

	const verifyUsernameEmailAvailability = ({
		email,
		username,
	}: {
		username: string;
		email: string;
	}) => {
		verify({
			variables: {
				data: { email, username },
			},
		});
	};

	return { verifyUsernameEmailAvailability, ...states };
};
