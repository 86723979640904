import React from 'react';
import { View, StyleSheet, Image, Text, Pressable } from 'react-native';
import { Progress } from './Progress';
import { palettes } from '../../../config';
import { hexToRGB } from '../../../utils/hexToRgba';
import { MultipleProgress } from './MultipleProgress';
import { CrossIcon } from '../../common/icons';

const Story = () => {
	return (
		<View style={styles.container}>
			<Pressable style={styles.closeCrossContainer}>
				<CrossIcon style={styles.closeCross} />
			</Pressable>
			<View style={styles.storyContainer}>
				<Image
					source={{ uri: 'https://via.placeholder.com/1000' }}
					style={styles.story}
				/>
				<View style={styles.metaContainer}>
					<MultipleProgress />
					<View style={styles.profileTimeContainer}>
						<View style={styles.profileContainer}>
							<Image
								source={{
									uri: 'https://via.placeholder.com/50/000',
								}}
								style={styles.profilePicture}
							/>
							<Text style={styles.username}>
								Brooklyn Simmons
							</Text>
						</View>
						<Text style={styles.time}>4 hr ago</Text>
					</View>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: hexToRGB(palettes.dark[0], 0.7),
		zIndex: 999,
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	storyContainer: {
		position: 'relative',
		width: 380,
		height: 640,
		borderRadius: 6,
		overflow: 'hidden',
	},
	story: {
		width: 380,
		height: 640,
		borderRadius: 6,
		overflow: 'hidden',
	},
	metaContainer: {
		position: 'absolute',
		left: 36,
		right: 36,
		top: 20,
		zIndex: 1000,
	},
	profileTimeContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	profileContainer: { flexDirection: 'row', alignItems: 'center' },
	profilePicture: {
		borderRadius: 9999,
		width: 30,
		height: 30,
		marginRight: 7,
	},
	username: {
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 18,
		color: 'white',
	},
	time: {
		fontWeight: '400',
		fontSize: 12,
		lineHeight: 18,
		color: 'white',
	},
	closeCrossContainer: {
		position: 'absolute',
		zIndex: 1001,
		right: 40,
		top: 44,
	},
	closeCross: {
		height: 18,
		width: 18,
	},
});

export default Story;
