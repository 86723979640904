import {
	GoogleMap,
	GoogleMapProps as GMapProps,
	useJsApiLoader,
} from '@react-google-maps/api';
import React from 'react';
import { View } from 'react-native';
import { TStyle } from '../../../types/TStyle';

type GoogleMapProps = {
	googleMapProps?: Omit<GMapProps, 'onLoad' | 'onUnmount'>;
	containerStyles?: TStyle;
};

const GoogleMaps = ({ googleMapProps, containerStyles }: GoogleMapProps) => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY ?? '',
	});
	const [map, setMap] = React.useState(null);

	const onLoad = React.useCallback(function callback(map) {
		const bounds = new window.google.maps.LatLngBounds();
		map.fitBounds(bounds);
		setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);
	return (
		<View style={[containerStyles]}>
			{isLoaded && (
				<GoogleMap
					{...googleMapProps}
					center={
						googleMapProps?.center ?? {
							lat: 6.60631,
							lng: 3.26383,
						}
					}
					zoom={googleMapProps?.zoom ?? 100}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{googleMapProps?.children}
				</GoogleMap>
			)}
		</View>
	);
};

export default GoogleMaps;
