import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const HeartIcon = (props: SvgProps) => {
	return (
		<Svg width={16} height={15} fill="none" viewBox="0 0 16 15" {...props}>
			<Path
				fill="#fff"
				d="M8 1.223a4.798 4.798 0 0 1 6.783 6.771l-6.784 6.794-6.782-6.794A4.801 4.801 0 0 1 8 1.224z"
			/>
		</Svg>
	);
};

export default HeartIcon;
