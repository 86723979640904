import {
	View,
	StyleSheet,
	useWindowDimensions,
	Text,
	TouchableOpacity,
	Image,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import {
	DraggableScrollView,
	ScrollEventSection,
	SectionHeading,
	Button,
} from '../..';
import CommentBlock from '../../home/comment-block/CommentBlock';
import TopProfiles from '../Top-Profiles/TopProfiles';
import { useLazyQuery, useQuery } from '@apollo/client';
// import {
// 	GET_ALL_HOSTS,
// 	GET_ALL_SELLERS,
// } from '../../../graphql/network/queries';
import { GET_ALL_BRANDS } from '../../../graphql/brands/queries';
import { ProfileCard } from '../../../components/network/profile-cards/ProfileCard';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	setCurrentEvent,
	setIsAffiliatedHostEditingEvent,
	setIsEditEvent,
} from '../../../redux/getEventSlice';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
	TDesktopNavigationBar,
	TUseNavigation,
} from '../../../types/exportedTypes';
import BrandsImage from '../../../assets/groupBrand.svg';
import HostsImage from '../../../assets/groupHost.svg';
import { GET_ALL_HOSTS, GET_ALL_SELLERS } from '../../../graphql/auth/queries';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native-web-hover';
import StatusSkeleton from '../../common/skeleton-loaders/status-loader/StatusSkeleton';
import useGetStatus from '../../../api/status/useGetStatus';
import { GET_STATUSES_BY_USER } from '../../../graphql/status/queries';
import { TUseRoute } from '../types/exportedTypes';
import useGetReviewsAndRating from '../../../api/rating/useGetReviewsAndRating';
import { resetCreateEventSlice } from '../../../redux/createEventSlice';

type Props = {
	isVisiting?: boolean;
	events?: any[];
	userType?: string;
	eventLoading?: boolean;
	isSubscribedArray?: isSubscribedResponse[];
};

export type TTopSidebarProfile = {
	username?: string;
	id?: string;
	brandId?: string;
	city?: string;
	country?: string;
	countryCode?: string;
	profileImage?: string;
	isFollowing?: boolean;

	// brands
	address?: TODO;
	name?: string;
	additionalMedia?: string[];
	rating?: TODO;
	description?: string;
	products?: TODO;
	brandStatsArray?: TODO;
	followUser?: TODO;
};

// export type TTopSidebarBrandProfile = {
// 	username: string;
// 	// id?: string;
// 	brandId?: string;
// 	city: string;
// 	country: string;
// 	countryCode?: string;
// 	profileImage: string;
// 	isFollowing: boolean;
// }

const mapUserDataForSidebarProfiles = (
	data: TGetAllUsersData[]
): TTopSidebarProfile[] => {
	return data.map(profile => ({
		username: profile.username,
		city: profile.personalInformation.address.city,
		countryCode: profile.personalInformation.address.countryCode,
		country: profile.personalInformation.address.countryLabel,
		id: profile._id,
		profileImage: profile.personalInformation.profileImageLink,
		isFollowing: false,
	}));
};

const ProfileAccounts = ({
	isVisiting,
	events,
	userType,
	eventLoading,
	isSubscribedArray,
}: Props) => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	const dispatch = useAppDispatch();
	const navigation = useNavigation<TUseNavigation>();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const [allHostsData, setAllHostsData] = useState<TTopSidebarProfile[]>();
	const [allSellersData, setAllSellersData] =
		useState<TTopSidebarProfile[]>();
	const [allBrandsData, setAllBrandsData] = useState<TTopSidebarProfile[]>();

	const [statusPost, setStatusPost] = useState<StatusPost[]>([]);
	const [ratingsReviews, setRatingsReviews] = useState<RatingsAndReviews[]>(
		[]
	);
	const [statusUpdate, setStatusUpdate] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState(true);
	const [skipDoc, setSkipDoc] = useState(0);

	const route = useRoute<TUseRoute<'UserProfile'>>();
	const user = useAppSelector(state => state.auth.loggedInUserDetails?._id);

	const { loading: allHostsLoading } = useQuery<
		TGetAllUsersResponse,
		TGetAllUsersInputs
	>(GET_ALL_HOSTS, {
		variables: { limit: 5 },
		onCompleted: data => {
			if (
				data &&
				data.getAllUsers &&
				data.getAllUsers.data &&
				data.getAllUsers.success &&
				data.getAllUsers.data.data &&
				!allHostsData
			) {
				const _allHostsData = mapUserDataForSidebarProfiles(
					data.getAllUsers.data.data
				);
				setAllHostsData(_allHostsData);
			}
		},
		onError: () => {},
	});

	const { loading: allSellersLoading } = useQuery<
		TGetAllUsersResponse,
		TGetAllUsersInputs
	>(GET_ALL_SELLERS, {
		variables: { limit: 5 },
		onCompleted: data => {
			if (
				data &&
				data.getAllUsers &&
				data.getAllUsers.data &&
				data.getAllUsers.success &&
				data.getAllUsers.data.data &&
				!allSellersData
			) {
				const _allSellersData = mapUserDataForSidebarProfiles(
					data.getAllUsers.data.data
				);
				setAllSellersData(_allSellersData);
			}
		},
		onError: () => {},
	});

	const { loading: allBrandsLoading } = useQuery<TGetAllBrandsResponse>(
		GET_ALL_BRANDS,
		{
			variables: { limit: 5 },
			onCompleted: data => {
				if (
					data &&
					data.getAllBrands &&
					data.getAllBrands.data &&
					data.getAllBrands.success &&
					data.getAllBrands.data.data &&
					!allBrandsData
				) {
					const _allSellersData: TTopSidebarProfile[] =
						data.getAllBrands.data.data.map(profile => ({
							address: profile.address,
							name: profile.name,
							profileImage: profile.profileImage,
							additionalMedia: profile.additionalMedia,
							rating: profile.rating,
							description: profile.description,
							products: profile.products,
							// brandStatsArray: profile.,
							// followUser: followUser,
							username: profile.name,
							city: profile.address.city,
							country: profile.address.countryLabel,
							id: profile._id,
							brandId: profile._id,
							countryCode: profile.address.countryCode,
							// profileImage: profile.profileImage,
							isFollowing: false,
						}));
					setAllBrandsData(_allSellersData);
				}
			},
		}
	);
	const connectID = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.connectAccountInfo?.connectAccountId
	);
	const onPressCreateEvent = () => {
		dispatch(setIsEditEvent(false));
		dispatch(setIsAffiliatedHostEditingEvent(false));
		dispatch(resetCreateEventSlice());
		navigation.navigate('CreateEvent');
	};

	const [
		getStatusesByUser,
		{ data: statusesByUserData, loading: loadingStatusesByUser },
	] = useLazyQuery(GET_STATUSES_BY_USER);

	const { executeGetReviewsAndRating } = useGetReviewsAndRating({
		onCompleted(data) {
			setRatingsReviews(data.getEntityRating.data.data as any);
		},
	});

	useEffect(() => {
		if (statusesByUserData) {
			const status = statusesByUserData?.getStatusesByUser?.data?.data;
			if (status?.length && !statusUpdate) {
				setStatusPost(prev => [...prev, ...status]);
			} else {
				status?.length && setStatusPost(prev => [status[0], ...prev]);
			}
		}
	}, [statusesByUserData]);

	useEffect(() => {
		const statusTotal = statusesByUserData?.getStatusesByUser?.data?.total;
		statusTotal === 0 && setShowLoadMore(false);

		if (statusPost.length === statusTotal) setShowLoadMore(false);
	}, [statusPost, statusesByUserData]);

	useEffect(() => {
		getStatusesByUser({
			variables: {
				current: skipDoc,
				userId: route.params ? route.params?.userId : user,
			},
		});

		executeGetReviewsAndRating({
			args: {
				find: {
					_id: route.params ? route.params?.userId : user,
				},
				populate: ['ratedBy'],
			},
		});
	}, [skipDoc]);

	const renderItem = ({ item }: { item: RatingsAndReviews }) => {
		return (
			<CommentBlock
				status={item.review}
				user={item.ratedBy}
				_id={item._id}
				createdAt={item?.createdAt}
				rating={item.rating}
				isRatingType
			/>
		);
	};

	return (
		<View style={styles.layout}>
			<View style={styles.content}>
				{/* <TimelineContainer /> */}
				{userType !== 'BRAND' ? (
					<>
						<ScrollEventSection
							heading={t('All Events')}
							events={events}
							loading={eventLoading}
							isSubscribedArray={isSubscribedArray}
						/>

						{!events ||
							(events.length == 0 &&
								userType === 'SELLER' &&
								!isVisiting && (
									<View style={styles.container_3}>
										<TouchableOpacity
											style={{ display: 'flex' }}
											onPress={() => onPressCreateEvent()}
										>
											<Text
												style={styles.container3_plus}
											>
												+
											</Text>
											<Text
												style={styles.container3_text}
											>
												{t('Create Event')}
											</Text>
										</TouchableOpacity>
									</View>
								))}
						{!!ratingsReviews.length && (
							<>
								<Text style={styles.reviewsHeading}>
									{t('Reviews & Ratings')}
								</Text>

								<FlatList
									data={ratingsReviews}
									keyExtractor={(item: any) => item?._id}
									renderItem={renderItem}
								/>
							</>
						)}

						{
							loadingStatusesByUser &&
								[...Array(5).keys()].map(_ => (
									<StatusSkeleton />
								))
							// <Loader
							// 	size="small"
							// 	containerStyles={{
							// 		margin: getResponsiveStyle(20),
							// 	}}
							// />
						}

						{statusesByUserData && showLoadMore && (
							<Button
								title={t('Load More')}
								onPress={() => setSkipDoc(skipDoc + 1)}
							/>
						)}
					</>
				) : (
					<>
						<SectionHeading
							heading="Brands"
							hasViewAllLabel
							size="large"
						/>
						<DraggableScrollView
							data={allBrandsData}
							horizontal
							renderItem={() => <ProfileCard isBrand />}
						/>
						<ScrollEventSection
							hasViewAllLabel
							heading={t('All Events')}
							events={events}
							loading={eventLoading}
						/>
					</>
				)}
			</View>
			{!isLessThanDesktopBase && (
				<>
					<View style={styles.layoutDivider} />

					<View style={styles.sidebar}>
						{userType !== 'BRAND' && (
							<TopProfiles
								heading={t('Brands')}
								profiles={allBrandsData}
								loading={allBrandsLoading}
							/>
						)}

						{userType !== 'SELLER' && (
							<TopProfiles
								heading={t('Sellers')}
								profiles={allSellersData}
								loading={allSellersLoading}
							/>
						)}
						{userType !== 'HOSTS' && (
							<TopProfiles
								heading={t('Hosts')}
								profiles={allHostsData}
								loading={allHostsLoading}
							/>
						)}
						{userType === 'BRAND' && (
							<TopProfiles
								heading={t('Brands')}
								profiles={allBrandsData}
								loading={allBrandsLoading}
							/>
						)}
						<View style={styles.container2}>
							{!allBrandsData ||
								(allBrandsData.length === 0 && (
									<>
										<View style={styles.container2_top}>
											<Text
												style={styles.container2_text}
											>
												{t('Top Brands not')}
											</Text>
											<Text style={styles.viewAll}>
												{t('View All')}
											</Text>
										</View>
										<View style={styles.container2_mid}>
											<img
												src={BrandsImage}
												style={{
													width: '50%',
													margin: 'auto',
												}}
											/>
											<Text
												style={[
													styles.findText,
													styleContainer2(primary),
												]}
											>
												{t('Find Brands near by')}
											</Text>
										</View>
									</>
								))}

							{!allHostsData ||
								(allHostsData.length === 0 && (
									<>
										<View style={styles.container2_top}>
											<Text
												style={styles.container2_text}
											>
												{t('Top Hosts')}
											</Text>
											<Text style={styles.viewAll}>
												{t('View All')}
											</Text>
										</View>
										<View style={styles.container2_mid}>
											<img
												src={HostsImage}
												style={{
													width: '50%',
													margin: 'auto',
												}}
											/>
											<Text
												style={[
													styles.findText,
													styleContainer2(primary),
												]}
											>
												{t('Find Hosts near by')}
											</Text>
										</View>
									</>
								))}
						</View>
					</View>
				</>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		marginTop: getResponsiveStyle(18),
	},
	content: {
		flex: 1,
		paddingTop: getResponsiveStyle(20),
	},
	container2: {
		flex: 1,
		paddingTop: getResponsiveStyle(20),
		display: 'flex',
		justifyContent: 'center',
	},
	container2_top: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		justifyContent: 'space-between',
		marginBottom: getResponsiveStyle(150),
	},
	container2_text: {
		fontWeight: '700',
		fontSize: getResponsiveStyle(15, 'font'),
	},
	container2_mid: {
		marginBottom: getResponsiveStyle(150),
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
		marginLeft: getResponsiveStyle(40),
	},
	sidebar: {
		flexBasis: getResponsiveStyle(312),
		flexGrow: 0,
		flexShrink: 0,
		paddingHorizontal: getResponsiveStyle(32),
	},
	container_1: {
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1,
		flexDirection: 'row',
	},
	container_2: {
		textDecorationLine: 'underline',
		fontWeight: '600',
	},
	container_3: {
		width: '100%',
		borderStyle: 'dashed',
		borderWidth: 1,
		borderRadius: 6,
		borderColor: palettes.grey[4],
		height: getResponsiveStyle(150),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 100,
	},
	container_3_: {
		width: '100%',
		height: getResponsiveStyle(150),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 100,
	},
	container3_text: {
		color: palettes.grey[1],
		textDecorationLine: 'underline',
	},
	container3_plus: {
		color: palettes.grey[1],
		textAlign: 'center',
		fontSize: getResponsiveStyle(30, 'font'),
	},
	findText: {
		textDecorationLine: 'underline',
		// fontWeight: getResponsiveStyle(600, 'font'),
		margin: 'auto',
		fontWeight: '600',
		marginTop: getResponsiveStyle(20),
	},
	viewAll: {
		color: palettes.grey[1],
		textDecorationLine: 'underline',
		textDecorationColor: palettes.grey[1],
	},
	reviewsHeading: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: '700',
		marginBottom: getResponsiveStyle(18, 'spacing'),
	},
});

export default ProfileAccounts;
function styleContainer2(primary: string[]) {
	return {
		color: primary[0],
		textDecorationColor: primary[0],
	};
}
