import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, StyleSheet, Text } from 'react-native';
import { DraggableScrollView, ProductCard } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { setEventProduct } from '../../../redux/eventProductDetail';
import { useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ModalComponent from '../../common/modal/ModalComponent';
import ProductView from '../../common/templates/ProductView';

type Props = { products: TGetAllProductsData[]; brand?: string };

const ProductCards = ({ products, brand }: Props) => {
	const { t } = useTranslation();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [openState, setOpenState] = useState(false);
	const [product, setProduct] = useState<Product>();
	const [currentVariant, setCurrentVariant] = useState<Variant>();

	return (
		<>
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				style={[styleDraggableScroll(isLessThanDesktopBase)]}
			>
				{products?.map(product =>
					product?.variants?.map(variant => (
						<Pressable
							onPress={() => {
								setProduct(product);
								setCurrentVariant(variant);
								setOpenState(true);
							}}
						>
							<ProductCard
								name={product?.name + ' ' + (variant?.value ? variant?.value : "")}
								optionValue={variant?.option?.values[0]?.name}
								price={+variant?.option?.values[0]?.price}
								id={variant?._id}
								image={variant?.image}
								rating={product?.rating}
								variant={variant}
								brand={brand}
							/>
						</Pressable>
					))
				)}
			</ScrollView>
			{currentVariant && product && (
				<ModalComponent
					onClose={() => {
						setOpenState(false);
					}}
					isProductModal
					header={`${t('Product View')}`}
					openState={openState}
					type={isLessThanDesktopBase ? 'fullpage' : 'sidebar'}
				>
					<>
						<ProductView
							productData={product as TGetAllProductsData}
							variant={{
								_id: currentVariant._id,
								variator: currentVariant?.variator,
								value: currentVariant?.value,
								option: currentVariant.option,
								vat: currentVariant?.vat,
								requiresShipping: true,
								image: currentVariant?.image,
								associatedProduct: {
									_id: product?._id,
									name: product?.name,
									brand: product?.brand,
									shortDescription: product?.shortDescription,
									description: product?.description,
									category: product.category,
									publishedStatus: product?.publishedStatus,
									images: product?.images as any,
									brand: product?.brand as any,
								},
							}}
						/>
					</>
				</ModalComponent>
			)}
		</>
	);
};

const styleDraggableScroll = (isLessThanDesktopBase: boolean): TStyle => {
	return {
		width: `calc(100% + ${isLessThanDesktopBase
				? getResponsiveStyle(16)
				: getResponsiveStyle(32)
			}px)`,
		paddingBottom: getResponsiveStyle(20),
		paddingLeft: getResponsiveStyle(8),
	};
};

const styles = StyleSheet.create({
	title: {
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
		marginBottom: 10,
		marginTop: 15,
	},
});

export default ProductCards;
