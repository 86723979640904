import React, { useState } from 'react';
import {
	Platform,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Input, Label } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Picker } from '@react-native-picker/picker';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useMutation } from '@apollo/client';
import { CREATE_PAYOUT } from '../../../graphql/stripe/mutations';
import { GET_BALANCE } from '../../../graphql/stripe/queries';
import Currencies from '../../../utils/currencies';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useTranslation } from 'react-i18next';
import { setWithdrawModalState } from '../../../redux/walletSlice';

const WithdrawForm = (props: {
	onSubmit: (amount: string, destinationAccount: string) => void;
	setAmountToWithdraw: (val: number) => void;
	amountToWithdraw: number;
}) => {
	const Banks = useAppSelector(state => state.userBanks.UserBanks);
	const walletAmount = useAppSelector(state => state.wallet.amount);
	const { t } = useTranslation();
	const primaryColors = usePrimaryColors();
	const handleSubmit = (values: {
		amount: string;
		destinationAccount: string;
	}) => {
		props.onSubmit(values.amount, values.destinationAccount);
	};

	const schema = Yup.object({
		destinationAccount: Yup.string().required('Required'),
		amount: Yup.string().required('Required'),
	});

	type TInitialValues = Yup.InferType<typeof schema>;

	const initialValues: TInitialValues = {
		amount: '',
		destinationAccount: '',
	};

	const [payout, { data, loading }] = useMutation(CREATE_PAYOUT);
	const connectAccoundId = useAppSelector(
		state => state.userBanks.connectAccountId
	);

	const country = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address
				.countryLabel
	);
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const currency = Currencies[country as keyof typeof Currencies];
	const validate = (values: TInitialValues) => {
		const errors = { amount: '' };
		if (parseInt(values.amount) > walletAmount / 100) {
			errors.amount = `${t('Insufficient Balance. Current balance is')} ${
				walletAmount / 100
			}${getSymbolFromCurrency(currency)}`;
			return errors;
		} else if (parseInt(values.amount) < 1) {
			errors.amount = `${t('Amount must be greater than 1')}`;
			return errors;
		} else {
			return;
		}
	};

	const debouncedRemainingAmountUpdate = (val: number) => {
		props.setAmountToWithdraw(val);
	};
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={schema}
			validate={validate}
			onSubmit={values => {
				handleSubmit(values);

				payout({
					variables: {
						args: {
							amount: parseFloat(values.amount) * 100,
							currency: currency.toLowerCase(),
							destination: values.destinationAccount,
							accountId: connectAccoundId,
						},
					},
					refetchQueries: [
						{
							query: GET_BALANCE,
							variables: {
								args: {
									accountId: connectAccoundId,
								},
							},
						},
					],
					onCompleted: data => {
						if (data.createPayout.success) {
							setAlert(t('Withdraw Successful'), 'normal');
							dispatch(setWithdrawModalState());
						} else {
							setAlert(
								t('Unable to complete withdrawal'),
								'danger'
							);
						}
						props.setAmountToWithdraw(0);
					},
					onError: error => {
						setAlert(t('Unable to complete withdrawal'), 'danger');
					},
				});
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				dirty,
				isValid,
				errors,
				touched,
				setFieldValue,
			}) =>
				!loading ? (
					!data ? (
						<>
							<View>
								<Label
									error={
										touched.destinationAccount
											? errors.destinationAccount
											: ''
									}
									label={t('To')}
								/>
								<Picker
									selectedValue={values.destinationAccount}
									onValueChange={itemValue =>
										setFieldValue(
											'destinationAccount',
											itemValue
										)
									}
									style={[
										styles.dropdownContainer,
										Platform.OS === 'web' &&
										!touched.destinationAccount &&
										!errors.destinationAccount
											? styleFocusOutline(primaryColors)
											: styleErrorOutline(),

										// styleInput(decoration),
										!!errors.destinationAccount &&
											touched.destinationAccount &&
											styles.inputError,
									]}
									dropdownIconColor={palettes.grey[1]}
									onBlur={handleBlur('destinationAccount')}
									itemStyle={styles.dropdownItem}
								>
									<Picker.Item
										label={t('Select Bank Account')}
										value=""
										enabled={false}
									/>
									{Banks.map(bank => (
										<Picker.Item
											label={bank.bank_name}
											value={bank.bankId}
										/>
									))}
								</Picker>

								<Input
									value={values.amount}
									style={[styles.input]}
									containerStyles={{
										marginBottom: getResponsiveStyle(35),
									}}
									onChangeText={val => {
										setFieldValue('amount', val);
										// handleChange('amount');
										debouncedRemainingAmountUpdate(
											Number(val)
										);
									}}
									onBlur={handleBlur('amount')}
									label={`${t(
										'Enter Amount'
									)} ${getSymbolFromCurrency(currency)}`}
									error={touched.amount ? errors.amount : ''}

									// placeholder="Enter account number"
								/>
							</View>

							<Button
								title={t('Withdraw')}
								variant="primary"
								outerContainerProps={{
									style: styleLoginButtonOuterContainer(),
								}}
								size="lg"
								onPress={handleSubmit}
							/>
						</>
					) : (
						<Text>{t('Withdrawal successful')}</Text>
					)
				) : (
					<Text>{t('Processing Withdrawal...')}</Text>
				)
			}
		</Formik>
	);
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		paddingLeft: getResponsiveStyle(19),
		marginBottom: getResponsiveStyle(35),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
});

export default WithdrawForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(46),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary[0],
		outerWidth: 1,
	} as any;
}
