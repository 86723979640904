import { useMutation } from '@apollo/client';
import { useCreatePendingTransfer } from '../../api/stripe/useCreatePendingTransfer';
import { useCreateTransfer } from '../../api/stripe/useCreateTransfer';
import { CREATE_BRAND_TRANSFER } from '../../graphql/brands/mutations';
import { CREATE_ADMIN_TAX_AND_REVENUE } from '../../graphql/order/mutations';
import { CREATE_VOUCHER } from '../../graphql/vouchers/mutation';
import { useAppSelector } from '../../redux/store';
import {
	brandShare,
	brandShareWithoutHostAndSeller,
	hostShare,
	sellersShare,
} from '../../utils/stripeUtil';

type TQueryArgs = {
	chargeId: string;
	items: Items[];
	amountToBePaid: number;
	deliveryFee: number;
};

type TQueryFn = (args: TQueryArgs) => void;
export const useOnCompleteOrderWithCard = () => {
	// !------ SELLER OR CONSUMER MAKING A PURCHASE (AS THEY CAN ONLY USE CARDS)   -------! //
	const createTransfer = useCreateTransfer();
	const createPendingTransfer = useCreatePendingTransfer();
	const [createBrandTransfer] = useMutation(CREATE_BRAND_TRANSFER);
	const [createVoucher] = useMutation(CREATE_VOUCHER);
	const [createAdminRevenueAndTax] = useMutation(
		CREATE_ADMIN_TAX_AND_REVENUE
	);

	const selectedVoucher = useAppSelector(
		state => state.wallet.selectedVoucher
	);

	const onCompleteOrderWithCard: TQueryFn = ({
		chargeId,
		items,
		deliveryFee,
		amountToBePaid,
	}) => {
		const brandsInCartEarnings: TODO = {};
		const hostsInCartEarnings: TODO = {};
		let ocpusEarnings = 0;
		const totalAmountBeforeVoucher = items.reduce(
			(acc: number, item) => acc + item.price,
			0
		);
		// items.reduce((acc: number, item) => acc + item.price, 0) +
		// deliveryFee;
		// const deliveryPercentage = deliveryFee / totalAmountBeforeVoucher;
		items.map((item, index) => {
			//IN THE PRESENCE OF A VOUCHER (which will only occur when a host is buying that is in the absence of a seller and a host)
			// so this calculation will underneath affect only brands and ocpus's money
			//  get what percentage of the total amount each product's price is, then we remove this percent off the voucher from it
			const pricePercentage = item.price / totalAmountBeforeVoucher;
			const price = !selectedVoucher.amount
				? item.price //the user will pay normal item price if not using voucher
				: item.price - pricePercentage * +selectedVoucher.amount; //the user will pay normal item price - it's percentage out of the voucher
			const vat = item.vat ? item.vat : 0;

			const revenueBeforeTax = price;
			const revenueAfterTax = price / (1 + vat / 100);
			const computedDeliveryFee = index === 0 ? deliveryFee : 0;
			// CREATE AN OBJECT FOR THE BRANDS EARNINGS
			// if (item.brand && item.affiliatedSellerId) {
			// 	if (!brandsInCartEarnings[item.brand]) {
			// 		brandsInCartEarnings[item.brand] = brandShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueBeforeTax,
			// 			sellersRevenue: revenueAfterTax,
			// 		});
			// 	} else {
			// 		brandsInCartEarnings[item.brand] += brandShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueBeforeTax,
			// 			sellersRevenue: revenueAfterTax,
			// 		});
			// 	}
			// }

			if (item.brand) {
				if (!brandsInCartEarnings[item.brand]) {
					brandsInCartEarnings[item.brand] =
						brandShareWithoutHostAndSeller({
							quantity: item.quantity,
							revenue: revenueBeforeTax + computedDeliveryFee,
						});
				} else {
					brandsInCartEarnings[item.brand] +=
						brandShareWithoutHostAndSeller({
							quantity: item.quantity,
							revenue: revenueBeforeTax + computedDeliveryFee,
						});
				}
			}

			// MAKE BRAND CONNECT TRANSFER OR PENDING TRANSFER(TO BE TRANSFERRED IMMEDIATELY THE BRND OWNS A CONNECT ACCOUNT)
			// if (item.brand && !item.brandConnectId && item.affiliatedSellerId) {
			// 	createPendingTransfer({
			// 		sellerId: item.brand,
			// 		amount: brandShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueBeforeTax,
			// 			sellersRevenue: revenueAfterTax,
			// 		}),
			// 		chargeId: chargeId,
			// 		source_transaction: chargeId,
			// 		type: 'BRAND',
			// 	});
			// }

			if (item.brand && !item.brandConnectId) {
				createPendingTransfer({
					sellerId: item.brand,
					amount: brandShareWithoutHostAndSeller({
						quantity: item.quantity,
						revenue: revenueBeforeTax + computedDeliveryFee,
					}),
					chargeId: chargeId,
					source_transaction: chargeId,
					type: 'BRAND',
				});
			}

			if (item.brand && item.brandConnectId) {
				createTransfer({
					currency: 'eur',
					amount: brandShareWithoutHostAndSeller({
						quantity: item.quantity,
						revenue: revenueBeforeTax + computedDeliveryFee,
					}),
					destination: item.brandConnectId,
					chargeId: chargeId,
				});
			}

			// if (item.brand && item.brandConnectId && item.affiliatedSellerId) {
			// 	createTransfer({
			// 		currency: 'eur',
			// 		amount: brandShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueBeforeTax,
			// 			sellersRevenue: revenueAfterTax,
			// 		}),
			// 		destination: item.brandConnectId,
			// 		chargeId: chargeId,
			// 	});
			// }

			// MAKE SELLER CONNECT TRANSFER OR PENDING TRANSFER(TO BE TRANSFERRED IMMEDIATELY THE BRND OWNS A CONNECT ACCOUNT)
			// if (item.affiliatedSellerConnectId && !item.selfHosted) {
			// 	createTransfer({
			// 		currency: 'eur',
			// 		amount: sellersShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueAfterTax,
			// 		}),
			// 		destination: item.affiliatedSellerConnectId,
			// 		chargeId: chargeId,
			// 	});
			// }

			// if (item.affiliatedSellerConnectId && item.selfHosted) {
			// 	createTransfer({
			// 		currency: 'eur',
			// 		amount: sellersShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueAfterTax,
			// 		}),
			// 		destination: item.affiliatedSellerConnectId,
			// 		chargeId: chargeId,
			// 	});
			// }

			// if (
			// 	!item.affiliatedSellerConnectId &&
			// 	item.affiliatedSellerId &&
			// 	!item.selfHosted
			// ) {
			// 	createPendingTransfer({
			// 		sellerId: item.affiliatedSellerId,
			// 		amount: sellersShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueAfterTax,
			// 		}),
			// 		chargeId: chargeId,
			// 		source_transaction: chargeId,
			// 	});
			// }

			// if (
			// 	!item.affiliatedSellerConnectId &&
			// 	item.affiliatedSellerId &&
			// 	item.selfHosted
			// ) {
			// 	createPendingTransfer({
			// 		sellerId: item.affiliatedSellerId,
			// 		amount: sellersShare({
			// 			quantity: item.quantity,
			// 			revenue: revenueAfterTax,
			// 		}),
			// 		chargeId: chargeId,
			// 		source_transaction: chargeId,
			// 	});
			// }

			// MAKE HOST VOUCHER OBJECT
			if (item?.affiliatedHost) {
				if (!hostsInCartEarnings[item?.affiliatedHost]) {
					hostsInCartEarnings[item?.affiliatedHost] = {};
					hostsInCartEarnings[item?.affiliatedHost][item?.brand] =
						parseFloat(
							hostShare({
								quantity: item.quantity,
								revenue: revenueBeforeTax,
							})
						);
				} else if (
					!hostsInCartEarnings[item?.affiliatedHost][item?.brand]
				) {
					hostsInCartEarnings[item?.affiliatedHost][item?.brand] =
						parseFloat(
							hostShare({
								quantity: item?.quantity,
								revenue: revenueBeforeTax,
							})
						);
				} else {
					hostsInCartEarnings[item?.affiliatedHost][item?.brand] +=
						parseFloat(
							hostShare({
								quantity: item?.quantity,
								revenue: revenueBeforeTax,
							})
						);
				}
			}

			ocpusEarnings +=
				0.04 * (revenueBeforeTax + computedDeliveryFee) * item.quantity;
		});

		// SEND OUT OCPUS'S MONEY
		const deliveryFee_ = deliveryFee;
		// !selectedVoucher.amount
		// 	? deliveryFee
		// 	: deliveryFee - deliveryPercentage * +selectedVoucher.amount;
		const ocpusRevenue = ocpusEarnings - (0.25 + 0.015 * amountToBePaid); //charge ocpus pays for using stripe
		createAdminRevenueAndTax({
			variables: {
				netRevenue: parseFloat(ocpusRevenue.toFixed(2)),
				tax: 0,
			},
		});

		// THIS WILL CREATE A RECORD OF THIS AS A BRAND EARNING
		Object.entries(brandsInCartEarnings).map((earnings: TODO) => {
			createBrandTransfer({
				variables: {
					amount: parseFloat(earnings[1].toFixed(2)),
					brandId: earnings[0],
				},
			});
		});

		// SEND OUT HOST'S ACCUMULATED VOUCHER
		Object.keys(hostsInCartEarnings).map((host: TODO) => {
			Object.entries(hostsInCartEarnings[host]).map((entry: TODO) => {
				createVoucher({
					variables: {
						args: {
							brandId: entry[0],
							amountSpent: 0,
							amountLeft: entry[1],
							user: host,
						},
					},
				});
			});
		});
	};

	return onCompleteOrderWithCard;
};
