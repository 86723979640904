import { gql } from '@apollo/client';

export const IS_SUBSCRIBED_TO_EVENTS = gql`
	query isSubscribedToEvent($eventIds: [ID!]) {
		isSubscribedToEvent(eventIds: $eventIds) {
			success
			statusCode
			message
			data {
				total
				data {
					... on IsSubscribedToEventResponse {
						eventId
						isSubscribed
					}
				}
			}
		}
	}
`;
