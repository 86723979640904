import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

type Props = {
	color?: string;
} & Omit<SvgProps, 'color'>;

const Lock2Icon = ({ color, ...props }: Props) => (
	<Svg viewBox="0 0 24 24" {...props}>
		<Path fill="none" d="M0 0h24v24H0z" />
		<Path
			d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zM5 12v8h14v-8H5zm6 2h2v4h-2v-4zm6-4V9A5 5 0 0 0 7 9v1h10z"
			fill={color}
		/>
	</Svg>
);

export default Lock2Icon;
