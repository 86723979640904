import { Pressable } from 'react-native';
import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const HeartWithOverflowLineIcon = ({
	onPress,
	outlineColor = false,
	...props
}: SvgProps & { outlineColor?: boolean }) => {
	return (
		<Pressable onPress={onPress}>
			<Svg viewBox="0 0 24 24" width={24} height={24} {...props}>
				<Path fill="none" d="M0 0h24v24H0z" />
				<Path
					d="M20.243 4.757a6 6 0 0 1 .236 8.236l-8.48 8.492-8.478-8.492a6 6 0 0 1 8.48-8.464 5.998 5.998 0 0 1 8.242.228zM5.172 6.172a4 4 0 0 0-.192 5.451L12 18.654l7.02-7.03a4 4 0 0 0-5.646-5.64l-4.202 4.203-1.415-1.414 2.825-2.827-.082-.069a4 4 0 0 0-5.328.295z"
					fill={`${outlineColor && '#FF0000'}`}
				/>
			</Svg>
		</Pressable>
	);
};

export default HeartWithOverflowLineIcon;
