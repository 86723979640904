import { gql } from '@apollo/client';

export const GET_MEDIA = gql`
	query getMedia($_id: ID!) {
		getMedia(_id: $_id) {
			statusCode
			success
			message
			data {
				... on Media {
					_id
					src
					alt
					name
					type
					thumbnailSrc
				}
			}
		}
	}
`;

export const SEARCH = gql`
	query searchBrandsUsersEvents($query: String, $limit: Int) {
		searchBrandsUsersEvents(query: $query, limit: $limit) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on SearchBrandsUsersEventsResponse {
								_id
								searchResultType
								personalInformation {
									firstName
									lastName
									website
									phoneNumber
									description
									profileImageLink
									address {
										address1
										address2
										zipCode
										countryCode
										city
										countryLabel
									}
								}

								email
								typeOfAccount
								isVerified
								profileStatus
								gender
								accountStatus
								website
								kybStatus
								followers
								attendedEvents
								typeOfEvent
								statusOfEvent
								startingEventDateTime
								address {
									city
									countryLabel
								}
								phoneNumber
								vat
								rating
								description
								username
								profileImage
								name
								affiliatedSeller
								invitedPeople {
									... on User {
										_id
										username
										personalInformation {
											profileImageLink
										}
									}
								}
								affiliatedHosts
								eventMedia {
									_id
									src
								}
							}
						}
					}
				}
			}
		}
	}
`;
