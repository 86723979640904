import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { Style } from 'rn-css/dist/types';
import { useConst } from '../../../hooks/useConst';
import { tagStyle } from './CardTop';

type TTagType =
	| 'subscribe'
	| 'unsubscribe'
	| 'subscribing'
	| 'unsubscribing'
	| 'closed'
	| 'upcoming'
	| 'inviteGuest'
	| 'ongoing';

type TLabelDict = {
	[T in TTagType]: string;
};

type Props = {
	tagType: TTagType;
	onPress?: () => void;
	style?: Style;
};

export const EventCardTag = ({ style, tagType, onPress }: Props) => {
	const { t } = useTranslation();

	const LabelDict: TLabelDict = useConst({
		closed: t('Closed'),
		upcoming: t('Upcoming'),
		subscribe: '+ ' + t('Subscribe'),
		unsubscribe: '- ' + t('UnSubscribe'),
		subscribing: t('Subscribing') + '...',
		unsubscribing: t('Unsubscribing') + '...',
		inviteGuest: '+ ' + t('Add Guest'),
		ongoing: t('Ongoing'),
	});

	return (
		<Text
			style={[style, tagStyle.eventMeta, tagStyle.eventStatus]}
			onPress={onPress}
		>
			{LabelDict[tagType]}
		</Text>
	);
};
