import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProfileIcon from '../../common/ProfileIcon';
import { Stats } from './Stats';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';
import { Flag } from '../..';
import useGetUserDashboardStats from '../../../api/user/useGetUserDashBoardStats';
import { useLazyQuery } from '@apollo/client';
import { GET_SELLERS_ORDERS } from '../../../graphql/order/queries';

const ProfileStatsCard = () => {
	const { t } = useTranslation();
	const userDetails = useAppSelector(state => state.auth.loggedInUserDetails);
	const stats = useAppSelector(state => state.user.stats);

	const { executeUserDashboardStatsQuery } = useGetUserDashboardStats({
		isCurrentUserStat: true,
	});

	useEffect(() => {
		executeUserDashboardStatsQuery();
	}, []);

	// const [connectID, setConnectID] = useState();
	// useQuery(GET_CONNECT_ACCOUNT, {
	// 	onCompleted: data =>
	// 		data &&
	// 		data.getConnectAccount &&
	// 		data.getConnectAccount.data &&
	// 		setConnectID(data.getConnectAccount.data.connectAccountId),
	// });

	const connectID = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.connectAccountInfo?.connectAccountId
	);
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);

	const id = useAppSelector(state => state.auth.loggedInUserDetails?._id);

	const [totalSales, setTotalSales] = useState(0);
	const [getSales] = useLazyQuery(GET_SELLERS_ORDERS, {
		variables: {
			args: {
				connectAccount: connectID ?? '',
				sellerId: id,
			},
		},
		onCompleted: data =>
			data &&
			data.getOrdersSeller &&
			data.getOrdersSeller.data &&
			setTotalSales(data.getOrdersSeller.data.total),
	});

	useEffect(() => {
		typeOfAccount === 'SELLER' && getSales();
	}, [connectID, typeOfAccount]);
	return (
		<View style={styleContainer()}>
			<View style={styleProfileContainer()}>
				<ProfileIcon
					imageSrc={
						userDetails?.personalInformation?.profileImageLink
					}
					style={styleProfilePicture()}
				/>
				<Text style={styleName()}>{`${userDetails?.username}`}</Text>
				<Text style={styleLocation()}>
					{userDetails?.personalInformation?.address?.city}{' '}
					<Flag
						countryCode={
							userDetails?.personalInformation?.address
								?.countryCode
						}
					/>
				</Text>
			</View>
			<View style={styles?.statsContainer}>
				{/* {invites &&
				<Stats
				label={t('Invites')}
				stat={invites||0}
				hasBorderRight
				/>}
				{events&&
				<Stats
				label={t('Events')}
					stat={events||0}
					hasBorderRight={invites?false:true}

				/>}
				{sales&&<Stats
				label={t('Sales')}
				stat={sales||0}
				/>}
				{followers&& <Stats 
				label={t('Followers')}
				stat={followers||0}
				/>}
				<Stats
					label={t('Events')}
					stat={stats?.eventsCount}
					hasBorderRight
				/> */}
				{userDetails?.typeOfAccount === 'CONSUMER' && (
					<>
						<Stats
							label={t('Invites')}
							stat={stats?.eventsInvitedCount || 0}
							hasBorderRight
						/>
						<Stats
							label={t('Events')}
							stat={stats?.eventsCount || 0}
						/>
					</>
				)}
				{userDetails?.typeOfAccount === 'SELLER' && (
					<>
						<Stats
							label={t('Events')}
							stat={stats?.eventsCount || 0}
							hasBorderRight
						/>
						<Stats label={t('Sales')} stat={totalSales} />
					</>
				)}
				{userDetails?.typeOfAccount === 'HOST' && (
					<>
						<Stats
							label={t('Events')}
							stat={stats?.eventsCount || 0}
							hasBorderRight
						/>
						<Stats
							label={t('Followers')}
							stat={stats?.followersCount || 0}
						/>
					</>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		borderBottomWidth: 1,
		borderBottomColor: palettes.grey[5],
	},
	profileContainer: {
		alignItems: 'center',
	},
	name: {
		fontWeight: '600',
		color: palettes.dark[0],
	},
	location: {
		fontWeight: '400',
		color: palettes.grey[0],
	},
	statsContainer: {
		flexDirection: 'row',
		width: '100%',
	},
});

export default ProfileStatsCard;

const styleLocation = () => {
	return [
		styles.location,
		{
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(20, 'font'),
		},
	];
};

const styleName = () => {
	return [
		styles.name,
		{
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(18, 'font'),
		},
	];
};

const styleProfilePicture = () => {
	return {
		marginBottom: getResponsiveStyle(13),
		width: getResponsiveStyle(80),
		height: getResponsiveStyle(80),
		backgroundColor: palettes.grey[10],
	};
};

const styleProfileContainer = () => {
	return [
		styles.profileContainer,
		{
			marginBottom: getResponsiveStyle(41),
		},
	];
};

const styleContainer = () => {
	return [
		styles.container,
		{
			paddingVertical: getResponsiveStyle(26),
			marginBottom: getResponsiveStyle(28),
		},
	];
};
