import { gql } from '@apollo/client';
import { USER_RESPONSE_FRAGMENT, GET_ALL_USER_FRAGMENT } from './fragments';

const GET_USER = gql`
	${USER_RESPONSE_FRAGMENT}

	query getUser {
		getUser {
			...UserResponseFragment
		}
	}
`;

const CHECK_USERNAME_EMAIL_AVAILABILITY = gql`
	query checkUsernameEmail($data: CheckUsernameEmailAvailability) {
		checkUsernameEmailAvailability(data: $data) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

const LOGOUT = gql`
	${USER_RESPONSE_FRAGMENT}
	query logout {
		logout {
			...UserResponseFragment
		}
	}
`;

const GET_ALL_USERS = gql`
	${GET_ALL_USER_FRAGMENT}

	query getAllUsers(
		$find: UserQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;

const GET_ALL_USERS_BY_IDS = gql`
	${GET_ALL_USER_FRAGMENT}
	query getUsersByIds(
		$_ids: [ID!]
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getUsersByIds(
			_ids: $_ids
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;

const GET_ALL_SELLERS = gql`
	${GET_ALL_USER_FRAGMENT}

	query getAllUsers(
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: { typeOfAccount: SELLER }
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;

const GET_ALL_HOSTS = gql`
	query getAllUsers(
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: { typeOfAccount: HOST }
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on User {
								username
								_id
								email
								isVerified
								personalInformation {
									address {
										city
										country
										countryCode
									}
									profileImageLink
								}
							}
						}
					}
				}
			}
		}
	}
`;

const GET_ALL_CONSUMERS = gql`
	${GET_ALL_USER_FRAGMENT}

	query getAllUsers(
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: { typeOfAccount: CONSUMER }
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;
const GET_USER_TABLE_STATS = gql`
	query getUserStats($id: [ID!]) {
		getUserStats(userIds: $id) {
			statusCode
			success
			message
			data {
				... on StatUserResponseData {
					total
					data {
						... on StatUserResponseEach {
							userId
							productsCount
							eventsCount
							followersCount
						}
					}
				}
			}
		}
	}
`;

export {
	GET_USER,
	LOGOUT,
	GET_ALL_USERS,
	CHECK_USERNAME_EMAIL_AVAILABILITY,
	GET_ALL_SELLERS,
	GET_USER_TABLE_STATS,
	GET_ALL_HOSTS,
	GET_ALL_CONSUMERS,
	GET_ALL_USERS_BY_IDS,
};
