import { useNavigation } from '@react-navigation/native';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { ChevronDown } from '../..';
import { palettes } from '../../../config';
import { setCurrentChat } from '../../../redux/chatSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProfileIcon from '../ProfileIcon';

const MobileChatHeading = ({
	chat,
	tab,
	onPress,
}: {
	chat: ChatContactProps;
	onPress: Dispatch<SetStateAction<string>>;
	tab: string;
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const navigation = useNavigation<TUseNavigation>();
	return (
		<View style={[styles.textStyle]}>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<ChevronDown
					style={[styleChevron(), styles.chevron]}
					onPress={() => {
						tab === 'Info'
							? onPress('People')
							: dispatch(setCurrentChat({} as ChatContactProps));
					}}
				/>
				{tab === 'Info' && (
					<View>
						<Text
							style={[
								styles.infoStyle,
								{ color: palettes.dark[0] },
							]}
						>
							{t('Info')}
						</Text>
					</View>
				)}

				{tab !== 'Info' && (
					<>
						<ProfileIcon
							style={styleProfileIcon()}
							imageSrc={
								chat?.contactPerson?.personalInformation
									?.profileImageLink
							}
						/>
						<View>
							<Text
								style={styles.nameStyle}
							>{`${chat?.contactPerson?.personalInformation.firstName} ${chat?.contactPerson?.personalInformation.lastName}`}</Text>
							<Text
								style={styles.locationStyle}
							>{`${chat?.contactPerson?.personalInformation.address.city} ${chat?.contactPerson?.personalInformation.address.countryLabel}`}</Text>
						</View>
					</>
				)}
			</View>
			{tab !== 'Info' && (
				<View>
					<Text
						style={styles.infoStyle}
						onPress={() => {
							onPress('Info');
						}}
					>
						{t('Info')}
					</Text>
				</View>
			)}
		</View>
	);
};
const styles = StyleSheet.create({
	textStyle: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	nameStyle: {
		fontSize: getResponsiveStyle(16, 'font'),
		fontWeight: 'bold',
	},
	locationStyle: {
		color: palettes.grey[2],
	},
	infoStyle: {
		color: palettes.grey[0],
		fontWeight: 'bold',
	},
	chevron: {
		transform: [{ rotate: '90deg' }],
	},
});
export default MobileChatHeading;

const styleProfileIcon = () => {
	return {
		height: getResponsiveStyle(48, 'dimensions'),
		width: getResponsiveStyle(48, 'dimensions'),
		marginRight: getResponsiveStyle(16),
		backgroundColor: palettes.grey[10],
	};
};

function styleChevron() {
	return {
		width: getResponsiveStyle(7),
		height: getResponsiveStyle(12),
		marginRight: getResponsiveStyle(12),
		marginLeft: getResponsiveStyle(-5),
	};
}
