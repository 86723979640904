const Currencies = {
	'Argentina': 'ARS',
	'Australia': 'AUD',
	'Austria': 'EUR',

	'Belgium': 'EUR',

	'Bolivia': 'BOB',
	'Brazil': 'BRL',
	'Bulgaria': 'BGN',

	'Canada': 'CAD',
	'Chile': 'CLP',
	'Colombia': 'COP',
	'Costa Rica': 'CRC',
	'Croatia': 'HRK',

	'Cyprus': 'EUR',

	'Czech Republic': 'CZK',

	'Denmark': 'DKK',

	'Dominican Republic': 'DOP',
	'Egypt': 'EGP',
	'Estonia': 'EUR',

	'Finland': 'EUR',

	'France': 'EUR',

	'Germany': 'EUR',

	'Greece': 'EUR',
	'Hong Kong': 'HKD',
	'Hungary': 'HUF',
	'Iceland': 'EUR',
	'India': 'INR',
	'Indonesia': 'IDR',
	'Ireland': 'EUR',
	'Israel': 'ILS',
	'Italy': 'EUR',

	'Japan': 'JPY',
	'Kenya': 'KES',
	'Latvia': 'EUR',

	'Liechtenstein': 'CHF',
	'Lithuania': 'EUR',
	'Luxembourg': 'EUR',

	'Malaysia': 'MYR',
	'Malta': 'EUR',

	'Mexico': 'MXN',
	'Netherlands': 'EUR',

	'New Zealand': 'NZD',
	'Norway': 'NOK',
	'Paraguay': 'PYG',
	'Peru': 'PEN',
	'Philippines': 'PHP',
	'Poland': 'PLN',

	'Portugal': 'EUR',

	'Romania': 'RON',

	'Saudi Arabia': 'SAR',
	'Singapore': 'SGD',
	'Slovakia': 'EUR',

	'Slovenia': 'EUR',

	'South Africa': 'ZAR',
	'Spain': 'EUR',

	'Sweden': 'SEK',

	'Switzerland': 'CHF',
	'Thailand': 'THB',
	'Trinidad & Tobago': 'TDD',
	'Turkey': 'TRY',
	'United Arab Emirates': 'AED',
	'United Kingdom': 'GBP',
	'United States': 'USD',
	'Uruguay': 'UYU',
};

export default Currencies;
// country the user is in : {
// "currencies supported by such country" :[Array of countries where the bank of such currency can be located]
// }
