import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { Action, Loader } from '../..';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const LoadMoreText = styled(Action)<{ primary: string[] }>`
	color: ${({ primary }) => primary?.[0]};
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(16, 'font')}px;
	padding-bottom: 0;
	&:hover {
		border-bottom: 1px solid ${({ primary }) => primary?.[0]};
	}
`;

type Props = {
	onClick: () => void;
	loading?: boolean;
	containerStyles?: TStyle;
};

const LoadMore = ({ onClick, loading = false, containerStyles }: Props) => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	return (
		<View style={[styles.container, containerStyles]}>
			{loading ? (
				<Loader />
			) : (
				<LoadMoreText
					onPress={onClick}
					primary={primary}
					style={[styles.text]}
				>
					{t('Load More')}
				</LoadMoreText>
			)}
		</View>
	);
};

export default LoadMore;

const styles = StyleSheet.create({
	text: {
		fontWeight: '700',
		width: 'max-content',
		textAlign: 'center',
	},
	pressableContainer: {
		width: 'max-content',
	},
	container: {
		alignItems: 'center',
		paddingBottom: getResponsiveStyle(20),
	},
});
