import React, { useState } from 'react';
import {
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	View,
} from 'react-native';
import {
	AuthHeading,
	DesktopNavigationBar,
	DumbComponentForFlex,
} from '../../..';
import { breakpoints } from '../../../../config';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../../hooks/usePrimaryColors';
import { TDesktopNavigationBar } from '../../../../types/exportedTypes';
import { TStyle, TStyleInputOrText } from '../../../../types/TStyle';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import SignupHeaderSmall from '../../../auth/signup-header-small/SignupHeaderSmall';
import Logo from '../../Logo';
import SidebarNavigationScroll from './sidebar-navigation-scroll/SidebarNavigationScroll';
import TopNavigationScroll from './top-navigation-scroll/TopNavigationScroll';
import { useAppSelector } from '../../../../redux/store';

type TNavProps = Omit<
	TDesktopNavigationBar,
	'containerStyles' | 'hasCart' | 'hasCreateEvent'
>;

type Props = {
	heading: string;
	subText: string;
	contentStyles?: TStyle;
	authType?: 'login' | 'signup';
	scrollItems: TStepTemplateScrollItems[];
	navHeaderPropsDesktop?: TNavProps;
	hasGoBack?: boolean;
};

const AuthTemplate: React.FC<Props> = ({
	heading,
	subText,
	contentStyles,
	authType = 'signup',
	scrollItems,
	navHeaderPropsDesktop,
	children,
	hasGoBack = true,
}) => {
	const { isLessThanDesktopBase, isDesktop, width } =
		useIsSpecificViewportWidth();
	const primaryColors = usePrimaryColors();

	const { isSocialAuth, indexOfPrimaryColors } = useAppSelector(
		states => states.ui
	);

	const [isConsumerSignUp, setIsConsumerSignUp] = useState(
		indexOfPrimaryColors === 0
	);
	return (
		<ScrollView
			contentContainerStyle={styles.containerContent}
			style={[styles.container, styleContainer(width)]}
			showsVerticalScrollIndicator={false}
		>
			{isDesktop && authType !== 'login' && (
				<DesktopNavigationBar
					containerStyles={styles.desktopNavigationContainer}
					hasLinks={false}
					hasSearch={false}
					hasGoBack={hasGoBack}
					logoLocation={authType === 'login' ? 'hidden' : 'center'}
					{...navHeaderPropsDesktop}
				/>
			)}
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'position' : 'padding'}
				style={[
					styleKeyboardAvoidingView(isLessThanDesktopBase),
					styles.keyboardAvoidingView,
				]}
			>
				{authType === 'signup' &&
					!isConsumerSignUp &&
					!isLessThanDesktopBase && (
						<SidebarNavigationScroll scrollItems={scrollItems} />
					)}

				{isDesktop && (
					<DumbComponentForFlex
						flex={+`${scrollItems.length ? 0.69 : 1}`}
					/>
				)}
				<View
					style={styleContentWrapper(isLessThanDesktopBase, authType)}
				>
					{authType === 'login' && isLessThanDesktopBase && (
						<Logo
							tint={primaryColors[0]}
							style={[styles.logo]}
							width={38}
							height={38}
							scale={1.3}
							viewBox="0 0 38 38"
							onPress={() => {}}
						/>
					)}

					{authType === 'signup' && isLessThanDesktopBase && (
						<SignupHeaderSmall />
					)}

					{authType === 'signup' &&
						!isConsumerSignUp &&
						isLessThanDesktopBase && (
							<TopNavigationScroll scrollItems={scrollItems} />
						)}

					<AuthHeading
						text={subText}
						title={heading}
						authType={authType}
					/>

					<View style={[styles.content, contentStyles]}>
						{children}
					</View>
				</View>
				{isDesktop && <DumbComponentForFlex flex={1} />}
			</KeyboardAvoidingView>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	desktopNavigationContainer: {
		width: '100%',
	},
	keyboardAvoidingView: {
		width: '100%',
		flexDirection: 'row',
	},
	content: {
		width: '100%',
	},
	containerContent: {
		alignItems: 'center',
		minHeight: '100vh',
	},
	container: {
		width: '100vw',
		minHeight: '100vh',
	},
	logo: { alignSelf: 'center', minHeight: 38 },
});

export default AuthTemplate;

const styleContainer = (width: number) => {
	return {
		paddingVertical: width >= breakpoints.desktopBase ? 32 : 16,
		paddingLeft: width >= breakpoints.desktopBase ? 40 : 16,
		paddingRight: width >= breakpoints.desktopBase ? 40 : 16,
	} as TStyle;
};

function styleContentWrapper(
	isLessThanDesktopBase: boolean,
	authType: string
): TStyle {
	return {
		maxWidth: getResponsiveStyle(464, 'dimensions'),
		width: '100%',
		alignItems:
			isLessThanDesktopBase || authType === 'signup'
				? 'center'
				: 'flex-start',
	};
}

function styleKeyboardAvoidingView(
	isLessThanDesktopBase: boolean
): TStyleInputOrText {
	return {
		justifyContent: isLessThanDesktopBase ? 'center' : 'flex-start',
	};
}
