import React from 'react';
import { StyleSheet, View } from 'react-native';

import ProductCardSkeleton from './productCardSkeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductListSkeleton = () => {
	return (
		<View style={styles.container}>
			<ProductCardSkeleton />
			<ProductCardSkeleton />
			<ProductCardSkeleton />
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		overflow: 'hidden',
	},
});
export default ProductListSkeleton;
