import { gql } from '@apollo/client';

const GET_ALL_LIKES = gql`
	query getAllLikes($parentId: ID!) {
		getAllLikes(find: { parentId: $parentId }) {
			statusCode
			success
			data {
				total
				data {
					... on Like {
						parentId
						likedBy
					}
				}
			}
		}
	}
`;

export { GET_ALL_LIKES };
