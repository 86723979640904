import { useMutation } from '@apollo/client';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { Loader } from '../..';
import { palettes } from '../../../config';
import { DELETE_NOTIFICATION } from '../../../graphql/notification/mutation';
import { GET_ALL_NOTIFICATIONS } from '../../../graphql/notification/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { InboxIcon } from '../icons';
import PasswordIcon from '../icons/PasswordIcon';
import {
	TNotificationMessage,
	TypeOfNotification,
} from '../../../types/notificationTypes';
import { useNavigation } from '@react-navigation/native';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { handleNavigationToEvent } from '../../../utils/handleNavigationToEvent';
import { TUseNavigation } from '../../../types/exportedTypes';

// import ProfileIcon from '../ProfileIcon';

const StyledClearText = styled(Text)`
	font-size: ${getResponsiveStyle(11)}px;
	line-height: ${getResponsiveStyle(11)}px;
	color: ${palettes.red[0]};
	cursor: pointer;
	padding-bottom: 2;

	border-bottom: 1px solid ${palettes.red[0]};
`;

const getSecondsFromDate = 1000;
const getMinuteFromDate = 60000;
const getHourFromDate = 3600000;
const getDayFromDate = 86400000;
const aDay = 24;
const aMinute = 60;

export const StandardNotification = ({
	notification,
	hovered,
	removeFromNotification,
	type = 'standard',
}: {
	notification: NotificationType;
	hovered?: boolean;
	removeFromNotification: (id: string) => void;
	type?: 'standard' | 'password';
}) => {
	const primary = usePrimaryColors();

	const ref = useRef(null);
	const isHovered = useHover(ref);
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();
	const dispatch = useAppDispatch();
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	const event: any = {
		_id: notification?.event?._id,
		typeOfEvent: notification?.event?.typeOfEvent,
		isHostApplicationAccepted:
			notification?.event?.isHostApplicationAccepted,
		startingEventDateTime: notification?.event?.startingEventDateTime,
		eventStreamTimeline: notification?.event?.eventStreamTimeline,
		affiliatedSeller: notification?.event?.affiliatedSeller,
		affiliatedBrands: notification?.event?.affiliatedBrands,
		affiliatedHosts: notification?.event?.affiliatedHosts,
		invitedPeople: notification?.event?.invitedPeople,
	};

	const handleNavigation = () => {
		handleNavigationToEvent({
			navigation,
			dispatch,
			loggedInUserDetails,
			event,
		});
	};
	const [deleteNotification, { loading }] = useMutation(DELETE_NOTIFICATION, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],

		onError: () => {
			setAlert(t('Could not delete notification'), 'danger');
		},
	});

	return (
		<View style={[styles.container, styleContainer()]} ref={ref}>
			<View style={styles.notificationIconContainer}>
				{type === 'standard' && (
					<InboxIcon
						style={[styles.notificationIcon]}
						// isPressable={false}
						outlineColor={primary[0]}
					/>
				)}
				{type === 'password' && (
					<PasswordIcon
						style={[styles.notificationIcon]}
						// isPressable={false}
						outlineColor={primary[0]}
					/>
				)}
			</View>
			<View style={[detailsContainer()]}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',

						alignItems: 'flex-start',
					}}
				>
					<Text
						style={[
							styleEventName(),
							styles.eventName,
							isHovered && { width: 200 },
						]}
						numberOfLines={isHovered && 1}
					>
						{notification?.notificationType !==
							TypeOfNotification.EVENT_ABOUT_TO_START_NOTIFICATION &&
							notification?.notificationType !==
								TypeOfNotification.EVENT_ABOUT_TO_END_NOTIFICATION &&
							notification?.notificationType !==
								TypeOfNotification.ADMIN_NOTIFICATION &&
							notification?.notificationType !==
								TypeOfNotification.APPROVED_OR_REJECTED_USER_NOTIFICATION &&
							notification?.notificationType !==
								TypeOfNotification.EVENT_MODIFIED_NOTIFICATION &&
							notification?.sentBy?.username}
					</Text>
					{isHovered && (
						<TouchableOpacity
							onPress={() => {
								removeFromNotification(notification._id);
								deleteNotification({
									variables: {
										args: {
											id: notification._id,
										},
									},
								});
							}}
						>
							<StyledClearText>{t('Clear')}</StyledClearText>
						</TouchableOpacity>
					)}
				</View>

				<Text
					style={[styleEventDescription(), styles.eventDescription]}
				>
					{/* Accepted or rejected Notification */}
					{notification?.notificationType ===
						TypeOfNotification.APPROVED_OR_REJECTED_USER_NOTIFICATION &&
						t('Your account has been') + ' ' + notification.message}

					{/* 'EVENT_ABOUT_TO_START_NOTIFICATION' */}
					{
						notification?.notificationType ===
							TypeOfNotification.EVENT_ABOUT_TO_START_NOTIFICATION &&
							(parseInt(notification?.message) < 1
								? notification?.event?.name +
								  ' ' +
								  t('will begin in less than an hour')
								: notification?.event?.name +
								  ' ' +
								  t('will begin in') +
								  ' ' +
								  notification?.message +
								  ' ' +
								  t('hours'))
						// ``
					}

					{/* EVENT_ABOUT_TO_END_NOTIFICATION */}
					{notification?.notificationType ===
						TypeOfNotification.EVENT_ABOUT_TO_END_NOTIFICATION &&
						notification?.event?.name + ' ' + t('has just ended')}

					{/* EVENT APPROVED OR REJECTED NOTIFICATION */}
					{notification?.notificationType ===
						TypeOfNotification.EVENT_APPROVED_OR_REJECTED_NOTIFICATION &&
						t('Your event,') +
							' ' +
							notification?.event?.name.toUpperCase() +
							' ' +
							t('has just been') +
							' ' +
							notification.message}

					{/* CONSUMER SUBSCRIPTION TO LIVE EVENT NOTIFICATION  */}
					{notification?.notificationType ===
						TypeOfNotification.CONSUMER_EVENT_SUBSCRIPTION_NOTIFICATION &&
						notification?.sentBy?.username +
							' ' +
							t('has subscribed to your event') +
							' ' +
							notification?.eventId?.name}

					{/* EVENT SUBSCRIPTION ACCEPTANCE */}
					{notification?.notificationType ===
						TypeOfNotification.EVENT_SUBSCRIPTION_NOTIFICATION_ACCEPTED &&
						notification?.sentBy?.username +
							' ' +
							t('has accepted your subscription request for') +
							' ' +
							notification?.eventId?.name}

					{/* EVENT UNSUBSCRIPTION_NOTIFICATION */}
					{notification.notificationType ===
						TypeOfNotification.EVENT_UNSUBSCRIPTION_NOTIFICATION &&
						notification?.sentBy?.username +
							' ' +
							t('has unsubscribed from your event') +
							' ' +
							notification?.eventId?.name}

					{/* WITHDRAW HOSTING INVITATION */}
					{notification.notificationType ===
						TypeOfNotification.WITHDRAW_EVENT_HOSTING_NOTIFICATION &&
						notification.message ===
							TNotificationMessage.WITHDRAW_EVENT_HOSTING_NOTIFICATION_GUEST &&
						notification?.sentBy?.username +
							' ' +
							t(
								'has withrawn the invitation sent to you to join'
							) +
							' ' +
							notification?.event?.name}

					{/* EVENT INVITATION NOTIFICATION RESPONSE */}
					{notification.notificationType ===
						TypeOfNotification.EVENT_INVITATION_NOTIFICATION_RESPONSE &&
						notification.message ===
							TNotificationMessage.ACCEPTED_INVITATION &&
						notification?.sentBy?.username +
							' ' +
							t(
								'has accepted the invitation you sent to them to join'
							) +
							' ' +
							notification?.event?.name}

					{notification.notificationType ===
						TypeOfNotification.EVENT_INVITATION_NOTIFICATION_RESPONSE &&
						notification.message ===
							TNotificationMessage.REJECTED_INVITATION &&
						notification?.sentBy?.username +
							' ' +
							t(
								'has rejected the invitation you sent to them to join'
							) +
							' ' +
							notification?.event?.name}

					{notification.notificationType ===
						TypeOfNotification.HOST_NOTIFICATION_TO_INVITE_GUEST && (
						<>
							<Text>{t('You can now invite guests to')}</Text>
							<Text> </Text>
							<Text
								style={{ color: primary[0] }}
								onPress={() => handleNavigation()}
							>
								{notification?.event?.name}
							</Text>
						</>
					)}

					{notification.notificationType ===
						TypeOfNotification.WITHDRAW_EVENT_HOSTING_NOTIFICATION &&
						notification.message ===
							TNotificationMessage.WITHDRAW_EVENT_HOSTING_NOTIFICATION &&
						notification?.sentBy?.username +
							' ' +
							t(
								'has withrawn the invitation sent to you to host'
							) +
							' ' +
							notification?.event?.name}

					{/* CONSUMER EVENT SUBSCRIPTION RESPONSE NOTIFICATION */}
					{notification.notificationType ===
						TypeOfNotification.EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION &&
						notification.message ===
							TNotificationMessage.EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION_REJECTED &&
						t('your subscription to event') +
							' ' +
							notification?.eventId?.name +
							' ' +
							t('has been rejected by') +
							' ' +
							notification?.sentBy?.username}

					{notification.notificationType ===
						TypeOfNotification.EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION &&
						notification.message ===
							TNotificationMessage.EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION_ACCEPTED &&
						t('your subscription to event') +
							' ' +
							notification?.eventId?.name +
							' ' +
							t('has been accepted by') +
							' ' +
							notification?.sentBy?.username}

					{/* EVENT MODIFIED NOTIFICATION */}
					{notification.notificationType ===
						TypeOfNotification.EVENT_MODIFIED_NOTIFICATION && (
						<Text>
							{t('Event') + ' '}
							<Text
								style={{ color: primary[0] }}
								onPress={() => handleNavigation()}
							>
								{notification?.event?.name}
							</Text>
							{' ' + t('has just been modified')}
						</Text>
					)}

					{/* NEW PHYSICAL EVENT NOTIFICATION */}
					{notification.notificationType ===
						TypeOfNotification.NEW_PHYSICAL_EVENT_PUBLISHED && (
						<Text>
							{t('A new physical event') + ' '}
							<Text
								style={{ color: primary[0] }}
								onPress={() => handleNavigation()}
							>
								{notification?.event?.name}
							</Text>
							{' ' + t('was created by')}
							{' ' + notification?.sentBy?.username}
						</Text>
					)}

					{/* EVENT_LOCATION_UPDATE_NOTIFICATION */}
					{notification.notificationType ===
						TypeOfNotification.EVENT_LOCATION_UPDATE_NOTIFICATION && (
						<Text>
							{t('Location for event') + ' '}
							<Text style={{ fontWeight: '700' }}>
								{notification?.event?.name}
							</Text>

							{' ' + t('is now') + ' '}
							<Text
								style={{ color: primary[0], fontWeight: '700' }}
							>
								{!notification.newLocation
									? notification?.sentBy?.personalInformation
											?.address?.address1 +
									  ', ' +
									  notification?.sentBy?.personalInformation
											?.address?.city +
									  ', ' +
									  notification?.sentBy?.personalInformation
											?.address?.zipCode +
									  ', ' +
									  notification?.sentBy?.personalInformation
											?.address?.countryLabel
									: notification?.newLocation?.address1 +
									  ', ' +
									  notification?.newLocation?.city +
									  ', ' +
									  notification?.newLocation?.zipCode +
									  ', ' +
									  notification?.newLocation?.countryLabel}
							</Text>
						</Text>
					)}
				</Text>
				<Text style={[styleEventDate(), styles.eventDate]}>
					{Math.floor(
						(Date.now() - Number(notification?.createdAt)) /
							getSecondsFromDate
					) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getSecondsFromDate
							)}
							{''}s {t('ago')}
						</Text>
					) : Math.floor(
							(Date.now() - Number(notification?.createdAt)) /
								getMinuteFromDate
					  ) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getMinuteFromDate
							)}
							{''}m {t('ago')}
						</Text>
					) : Math.floor(
							(Date.now() - Number(notification?.createdAt)) /
								getHourFromDate
					  ) < aDay ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getHourFromDate
							)}
							{''}h {t('ago')}
						</Text>
					) : (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getDayFromDate
							)}{' '}
							{t('days ago')}
						</Text>
					)}
				</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	eventDescription: {
		color: palettes.dark[4],
	},
	eventDate: {
		color: palettes.grey[2],
		marginTop: 6,
	},
	eventName: {
		fontWeight: '600',
		color: palettes.dark[0],
	},
	notificationIcon: {
		width: 28,
		height: 28,
	},
	notificationIconContainer: {
		width: getResponsiveStyle(56),
		height: getResponsiveStyle(56),
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: palettes.grey[4],
		borderWidth: 1,
		borderRadius: 9999,
	},
	container: {
		flexDirection: 'row',
		borderTopWidth: 1,
		borderTopColor: palettes.grey[5],
		alignItems: 'center',
		gap: getResponsiveStyle(16),
	},
});

function styleEventDescription() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleEventName() {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(5),
	};
}
function styleEventDate() {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}
function detailsContainer() {
	return {
		width: `calc(100% - ${getResponsiveStyle(56 + 16 + 24)}px)`,
	};
}

function styleNotificationIconContainer(primary: string[]) {
	return {
		right: getResponsiveStyle(10, 'font'),
		bottom: getResponsiveStyle(-6, 'font'),
		backgroundColor: primary[0],
	};
}

function styleProfileIcon() {
	return {
		width: getResponsiveStyle(56, 'dimensions'),
		height: getResponsiveStyle(56, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(15),
	};
}
