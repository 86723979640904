import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import { Button, DropDownPickerLarge, Input } from '../..';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useUpdateEvent } from '../../../hooks/useUpdateEvent';
import { setEventLocation } from '../../../redux/createEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import { TStyle } from '../../../types/TStyle';
import countryNamesWithCodes from '../../../utils/countryNamesWithCodes';
import { getCountryLabelFromValue } from '../../../utils/getCountryLabelFromValue';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Dropdown from '../../common/dropdown/Dropdown';

const validationSchema = yup.object({
	address1: yup.string().required('Required'),
	address2: yup.string(),
	city: yup.string().required('Required'),
	zipCode: yup.string(),
});

type TInitialValues = yup.InferType<typeof validationSchema>;

const initialValues: TInitialValues = {
	address1: '',
	address2: '',
	city: '',
	zipCode: '',
};

const EventLocationForm = () => {
	const dispatch = useAppDispatch();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const { isEditEvent, isAffiliatedHostEditingEvent } = useAppSelector(
		state => state.getEvent
	);
	const event = useAppSelector(state => state.getEvent.event);
	const [dropdownCountryValue, setDropdownCountryValue] =
		useState<TDropdownObject>();
	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);
	const { t } = useTranslation();
	const { runUpdateEvent } = useUpdateEvent();

	const editValues: TInitialValues = event.eventLocation;

	const navigation = useNavigation<TUseNavigation>();

	useEffect(() => {
		if (isEditEvent) {
			runUpdateEvent();
			// const dropdownEditCountryValue = {
			// 	value: editValues?.countryCode,
			// 	label: editValues?.countryLabel,
			// };
			// setDropdownCountryValue(dropdownEditCountryValue);
		}
	}, []);

	return (
		<Formik
			initialValues={isEditEvent ? editValues : initialValues}
			validationSchema={validationSchema}
			onSubmit={values => {
				const { address1, address2, city, zipCode } = values;
				if (!dropdownCountryValue) return;
				dispatch(
					setEventLocation({
						city,
						zipCode,
						geoCodedAddress: '',
						countryCode: dropdownCountryValue?.value,
						countryLabel: dropdownCountryValue?.label,
						address1: address1,
						address2: address2 ?? '',
					})
				);
				if (isAffiliatedHostEditingEvent) {
					navigation.navigate('EventComplete');
					return;
				}
				goToNextStepScreen();
			}}
		>
			{({
				handleBlur,
				handleChange,
				handleSubmit,
				errors,
				touched,
				isValid,
				dirty,
				values,
			}) => (
				<View>
					<View style={styleAddressContainer()}>
						<Input
							label={t('Address') + '*'}
							containerStyles={[
								styles.flex1,
								styleAddressInput1(),
							]}
							value={values.address1}
							onChangeText={handleChange('address1')}
							onBlur={handleBlur('address1')}
							error={
								touched.address1 && errors.address1
									? errors.address1
									: ''
							}
						/>
						<Input
							containerStyles={styles.flex1}
							onChangeText={handleChange('address2')}
							value={values.address2}
							onBlur={handleBlur('address2')}
							error={
								touched.address2 && errors.address2
									? errors.address2
									: ''
							}
						/>
					</View>
					<View style={styleRowContainer()}>
						<Input
							label={t('City') + '*'}
							containerStyles={styles.flex1}
							value={values.city}
							onChangeText={handleChange('city')}
							onBlur={handleBlur('city')}
							error={
								touched.city && errors.city ? errors.city : ''
							}
						/>
						<Input
							label={t('Zip Code')}
							containerStyles={styles.flex1}
							onChangeText={handleChange('zipCode')}
							onBlur={handleBlur('zipCode')}
							value={values.zipCode}
							error={
								touched.zipCode && errors.zipCode
									? errors.zipCode
									: ''
							}
						/>
					</View>
					<View style={[styleRowContainer(), { zIndex: 5000 }]}>
						<View style={{ width: '100%' }}>
							<Dropdown
								labelProps={{
									label: t('Country') + '*',
								}}
								options={countryNamesWithCodes}
								values={dropdownCountryValue}
								setValue={setDropdownCountryValue}
								inputProps={{
									placeholder: t('Country'),
								}}
								isSearchable
							/>
						</View>
					</View>
					<Button
						title={t('Next')}
						outerContainerProps={{
							style: { marginTop: getResponsiveStyle(42) },
						}}
						variant={
							(isValid &&
								dirty &&
								!!dropdownCountryValue?.label) ||
							dropdownCountryValue?.value
								? 'primary'
								: 'disabled'
						}
						size="lg"
						onPress={handleSubmit}
					/>
				</View>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	image: {
		width: '100%',
		height: '136px',
		borderRadius: 6,
	},
	flex1: {
		flex: 1,
	},
	description: {
		paddingVertical: 20,
	},
});

export default EventLocationForm;

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(24),
	} as TStyle;
};
function styleAddressContainer() {
	return { marginBottom: getResponsiveStyle(24) };
}

function styleAddressInput1() {
	return { marginBottom: getResponsiveStyle(16) };
}
