import React from 'react';
import { View } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
// import Skeleton from '../Skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';

const EventDateLoader = () => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View
			style={{
				flexDirection: 'row',
				marginBottom: getResponsiveStyle(15),
				marginRight: getResponsiveStyle(isLessThanDesktopBase ? 10 : 0),
			}}
		>
			<Skeleton
				width={getResponsiveStyle(70)}
				height={getResponsiveStyle(65)}
				style={styles.date}
			/>
			<View>
				<Skeleton
					width={getResponsiveStyle(71)}
					height={getResponsiveStyle(10)}
					style={{ ...styles.name, ...styles.radius }}
				/>
				<Skeleton
					width={getResponsiveStyle(85)}
					height={getResponsiveStyle(10)}
					style={{ ...styles.desc, ...styles.radius }}
				/>

				<Skeleton
					width={getResponsiveStyle(50)}
					height={getResponsiveStyle(5)}
					style={styles.radius}
				/>
			</View>
		</View>
	);
};

const styles = {
	container: {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(15),
	},
	date: { borderRadius: 6, marginRight: getResponsiveStyle(10) },
	info: {
		alignItems: 'center',
	},
	name: {
		marginBottom: getResponsiveStyle(10),
	},
	desc: {
		marginBottom: getResponsiveStyle(15),
	},
	radius: {
		borderRadius: 6,
	},
};

export default EventDateLoader;
