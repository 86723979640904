import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import ChatTab from '../product-section/ChatTab';
import EventChatBox, {
	LiveEventMessage,
} from '../product-section/event-chat/EventChatBox';
import PeopleSection from '../product-section/event-chat/PeopleSection';
import { getLiveEventSectionTabs } from './utils/LiveEventSection.utils';

type Props = {
	handleParticipantsOptions: (tab: string) => void;
	selectedParticipantTab: string;
	liveEventAudience: RoomData[];
	messages: LiveEventMessage[];
};

export const LiveEventParticipants = ({
	handleParticipantsOptions,
	selectedParticipantTab,
	liveEventAudience,
	messages,
}: Props) => {
	const { t } = useTranslation();
	return (
		<Fragment>
			<ChatTab
				onPress={handleParticipantsOptions}
				selectedTab={selectedParticipantTab}
				tabs={getLiveEventSectionTabs(t, 'participant')}
				center={true}
			/>
			{selectedParticipantTab ===
			getLiveEventSectionTabs(t, 'participant')[1].value ? (
				<PeopleSection
					expandHeight={true}
					roomData={liveEventAudience}
				/>
			) : (
				<View style={{ flex: 1, marginBottom: 15 }}>
					<EventChatBox
						messages={messages}
						expandHeight={true}
						isMobileView={true}
					/>
				</View>
			)}
		</Fragment>
	);
};
