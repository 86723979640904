import React from 'react';
import { StyleSheet } from 'react-native';
import { palettes } from '../../../../config';
import { TSVGIcon } from '../../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';

type Props = {
	icon: TSVGIcon;
	label: string;
	onClick?: () => void;
};

export const SignupCompanyButton = ({ icon: Icon, label, onClick }: Props) => {
	return (
		<button
			style={{
				background: 'white',
				outline: 'none',
				boxShadow: 'none',
				border: `1px solid ${palettes.grey[3]}`,
				borderRadius: 6,
				display: 'flex',
				// height: '100%',
				maxHeight: 'min-height',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
				flex: 1,
				// gridTemplateRows: '24px 1fr'
			}}
			onClick={() => onClick && onClick()}
		>
			<Icon
				height={getResponsiveStyle(24, 'dimensions')}
				width={getResponsiveStyle(24, 'dimensions')}
			/>
			<p
				style={{
					fontSize: getResponsiveStyle(14, 'font'),
					marginLeft: getResponsiveStyle(10),
				}}
			>
				{label}
			</p>
			{/* <Pressable
				style={({ hovered }) => [
					styles.container,
					styleContainer(),
					hovered && styles.hovered,
				]}
			>
				{() => (
					<View style={[styles.innerContainer]}>
						<Icon
							height={getResponsiveStyle(24, 'dimensions')}
							width={getResponsiveStyle(24, 'dimensions')}
						/>
						<Text style={[styleText(), styles.text]}>{label}</Text>
					</View>
				)}
			</Pressable> */}
		</button>
	);
};

const styles = StyleSheet.create({
	innerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: 6,
		borderWidth: 1,
		borderColor: palettes.grey[3],
		height: '100%',
		justifyContent: 'center',
	},
	text: {
		color: palettes.grey[8],
	},
	container: {
		flex: 1,
		width: '100%',
		borderWidth: 3,
		borderColor: 'transparent',
	},
	hovered: {
		borderColor: palettes.grey[0] + '20',
		borderRadius: 8,
	},
});

function styleText() {
	return {
		marginLeft: getResponsiveStyle(10),
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	};
}

function styleContainer() {
	return {
		height: getResponsiveStyle(48, 'dimensions'),
		marginBottom: getResponsiveStyle(48),
		marginRight: getResponsiveStyle(8),
	};
}
