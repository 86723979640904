import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_EVENT_SEARCH_USERS } from '../../graphql/events/queries';
import { useAppDispatch } from '../../redux/store';

type Params = {
	onCompleted: TOnCompleteApiCall<TGetAllEventUserResponse>;
};

const useSearchEventUser = (params?: Params) => {
	const dispatch = useAppDispatch();

	const [getEventUser, { data, error, loading }] = useLazyQuery(
		GET_EVENT_SEARCH_USERS,
		{
			onCompleted: params?.onCompleted,
		}
	);

	const executeEventSearchQuery = ({
		query,
		typeOfAccount,
	}: {
		query: string | undefined;
		typeOfAccount: string | undefined;
	}) => {
		getEventUser({
			variables: {
				query,
				typeOfAccount,
			},
		});
	};
	return { executeEventSearchQuery, data, error, loading };
};

export default useSearchEventUser;
