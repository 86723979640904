import React, { useEffect, useLayoutEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
// import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../../redux/store';
import useGetMediaMessages from '../../../../api/chat/useGetMediaMessages';
// import styled from 'rn-css';
import Video from './Video';
import Loader from '../../../common/loader/Loader';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';

const Videos = () => {
	const chat = useAppSelector(state => state.chat.chat);

	// const [play, setPlay] = useState<boolean>(false);

	const { executeMediaMessageQuery, loading, data } = useGetMediaMessages();
	// const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	// const MediaRapper = styled.View`
	// 	display: inline-block;
	// 	width: 100%;
	// 	padding-left: 10px;
	// 	margin-top: 10px;
	// `;

	// const video = React.useRef(null);

	useLayoutEffect(() => {
		const videos = document.getElementsByClassName('media-video');
		for (let i = 0; i < videos.length; i++) {
			videos[i]?.setAttribute(
				'style',
				`height: 100%; 
					object-fit: cover;
					width: 90%;
					border-radius: 6px;
					margin-top: 10px;
					cursor: pointer;
					`
			);
		}

		// document.querySelector('media-video') &&
		// 	document.querySelector('media-video')?.setAttribute(
		// 		'style',
		// 		`height: 100%;
		// 			object-fit: cover;
		// 			width: 90%;
		// 			border-radius: 6px;
		// 			margin-bottom: 10px;
		// 			cursor: pointer;
		// 			`
		// 	);
	}, [data]);

	// const handlePlay = () => {
	// 	setPlay(!play);
	// };

	useEffect(() => {
		if (chat?.conversationId)
			executeMediaMessageQuery({
				conversationId: chat?.conversationId,
				messageType: 'VIDEO',
			});
	}, [chat]);

	return (
		<ScrollView
			contentContainerStyle={styles.scrollContainer}
			showsHorizontalScrollIndicator={false}
		>
			{loading && (
				<Loader
					size="small"
					containerStyles={{
						margin: getResponsiveStyle(20),
					}}
				/>
			)}
			{data?.getMediaMessages?.data.map((media: any, index: number) => (
				<Video key={index} message={media.message} />
				// <View style={styles.videoRapper}>
				// 	<video
				// 		onPause={handlePlay}
				// 		controls={play}
				// 		className="media-video"
				// 		src={media.message}
				// 	/>
				// 	{!play && <VideoPlayBtn onPress={handlePlay} />}
				// </View>
			))}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	scrollContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		// marginTop: 40,
	},
	container: {
		paddingLeft: 20,
		paddingRight: 20,
		marginTop: 10,
		// width: '95%',
		flexWrap: 'wrap',
	},
	video: {
		width: 105,
		height: 106,
		borderRadius: 6,
	},
	videoRapper: {
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default Videos;
