import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { palettes } from '../../../config';
import { useDidMountEffect } from '../../../hooks/useDidMountEffect';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { CartIconWithItems } from '../../common/desktop-navigation-bar/CartIconWithItems';
import MicFilledIcon from '../../common/icons/Mic';
import UserFilledIcon from '../../common/icons/User';
import ViewFilledIcon from '../../common/icons/View';
import {
	calculateTimeFromSpecificTime,
	getTotalInvitedPeople,
} from '../event-section/LiveEventStats';
import Logo from '../../common/Logo';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

type Props = {
	totalUsersInRoom: number;
	totalInvitedPeople: number;
	liveStreamStartedAtTime: string | null | undefined;
	isHostRequired: boolean;
	isLiveStreaming: boolean;
	typeOfAccount?: TTypeOfAccount;
	selectedTab: string;
};

const StreamHeader = ({
	selectedTab,
	totalInvitedPeople,
	totalUsersInRoom,
	isHostRequired,
	liveStreamStartedAtTime,
	isLiveStreaming,
	typeOfAccount,
}: Props) => {
	const { isDesktop } = useIsSpecificViewportWidth();
	const iconFill: string = selectedTab !== 'Live' ? palettes.dark[4] : '#fff';
	const textColor: object | boolean = selectedTab !== 'Live' && {
		color: palettes.dark[4],
	};

	const primary = usePrimaryColors();

	const [seconds, setSeconds] = useState(0);
	const [timeSinceLiveStreamStarted, setTimeSinceLiveStreamStarted] =
		useState('');
	const [intervalInstance, setIntervalInstance] = useState<NodeJS.Timer>();

	useEffect(() => {
		let interval: NodeJS.Timer;
		if (liveStreamStartedAtTime) {
			interval = setInterval(() => {
				setSeconds(seconds + 1);
				const _time = calculateTimeFromSpecificTime(
					liveStreamStartedAtTime
				);
				setTimeSinceLiveStreamStarted(_time);
			}, 1000);
			setIntervalInstance(intervalInstance);
		}

		return () => {
			if (liveStreamStartedAtTime) {
				clearInterval(interval);
			}
			if (intervalInstance) {
				clearInterval(intervalInstance);
			}
		};
	}, [seconds]);

	useDidMountEffect(() => {
		if (!isLiveStreaming && intervalInstance) {
			clearInterval(intervalInstance);
		}
	}, [isLiveStreaming]);

	const showEventStartedAtTime =
		liveStreamStartedAtTime &&
		(isLiveStreaming || typeOfAccount === 'CONSUMER');

	return (
		<View style={styles.container}>
			<View style={styles.profile}>
				{/* <Image
					style={styles.backArrow}
					source={require('../../../assets/live-event/BackArrow.png')}
				/> */}
				{/* <Image
					style={styles.profileImage}
					source={require('../../../assets/live-event/liveEventProfile2.png')}
				/> */}
				{selectedTab === 'Products' && (
					<View>
						<CartIconWithItems black={true} />
					</View>
				)}
			</View>

			<View style={styles.stats}>
				<View style={styles.iconContainer}>
					<ViewFilledIcon fillColor={iconFill} />
					<Text style={[styles.text, textColor]}>
						{totalUsersInRoom}
					</Text>
				</View>
				<Logo
					tint={primary?.[0]}
					// style={styles.logo}
					width={20}
					height={20}
					scale={1.3}
					viewBox="0 0 20 20"
				/>
				{/* <View style={styles.iconContainer}>
					<UserFilledIcon fillColor={iconFill} />
					<Text style={[styles.text, textColor]}>
						{getTotalInvitedPeople(
							totalInvitedPeople,
							isHostRequired
						)}
					</Text>
				</View> */}
				{/* <View style={styles.iconContainer}>
					<MicFilledIcon fillColor={iconFill} />
					{showEventStartedAtTime ? (
						<Text style={[styles.text, textColor]}>
							{timeSinceLiveStreamStarted}
						</Text>
					) : (
						<Text style={[styles.text, textColor]}>00:00:00</Text>
					)}
				</View>
				{selectedTab === 'Live' && (
					<View>
						<CartIconWithItems white />
					</View>
				)} */}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// position: 'absolute',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	profile: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	text: {
		color: palettes.light[0],
		fontSize: 12,
		lineHeight: 14,
		fontWeight: '600',
		fontStyle: 'normal',
	},
	profileImage: {
		height: 40,
		width: 40,
		// marginLeft: 10,
	},
	stats: {
		flexDirection: 'row',
		gap: 15,
		// width: '45%',
		// justifyContent: 'space-between',
		// backgroundColor:'black'
	},
	icons: {
		width: 13.04,
		height: 10.85,
	},
	iconContainer: {
		alignItems: 'center',
	},
	backArrow: {
		height: 12,
		width: 7.33,
	},
});

export default StreamHeader;
