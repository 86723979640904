import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
	AuthTemplate,
	ForgotPasswordForm,
	LoginFooter,
	LoginHeroImage,
	TermsAndConditions,
} from '../components';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../types/TStyle';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useAppDispatch } from '../redux/store';
import { setCurrentScreen } from '../redux/uiSlice';

const ForgotPassword = () => {
	const { t } = useTranslation();
	const { isDesktop } = useIsSpecificViewportWidth();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/forgot-password'));
	}, []);
	return (
		<View style={isDesktop && styles.container}>
			<LoginHeroImage />
			<AuthTemplate
				heading={t('Forgot Password') + '?'}
				subText={t('Enter your email address to get reset link.')}
				contentStyles={styleMainContent(isDesktop)}
				authType={'login'}
				scrollItems={[]}
				hasGoBack={false}
			>
				<ForgotPasswordForm />
				{isDesktop && <LoginFooter />}
				{isDesktop && <TermsAndConditions />}
			</AuthTemplate>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		minWidth: '100vw',
		height: '100vh',
	},
});

function styleMainContent(isDesktop: boolean): TStyle {
	return {
		marginTop: getResponsiveStyle(isDesktop ? 0 : 40),
	};
}

export default ForgotPassword;
