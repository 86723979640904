import React, { useRef } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { LiveEventMessage } from '../product-section/event-chat/EventChatBox';
import { ChatData } from './ChatData';
import EventChatMessage from './EventChatMessage';

type Props = {
	messages: LiveEventMessage[];
};

const ProductDiscussion = ({ messages }: Props) => {
	const scrollViewRef: any = useRef();
	const { width } = useIsSpecificViewportWidth();
	return (
		<View style={styles.container}>
			{/* <LinearGradient
				colors={[
					'rgb(255, 255, 255) 68.35%',
					'rgba(28, 28, 37, 0) 4.43%',
				]}
				start={{ x: 1, y: 0.5 }}
				end={{ x: 1, y: 1 }}
				style={styles.gradientBox}
			/> */}
			<ScrollView
				style={styles.chatContainer}
				showsHorizontalScrollIndicator={false}
				ref={scrollViewRef}
				onContentSizeChange={() =>
					scrollViewRef?.current?.scrollToEnd({ animated: true })
				}
			>
				{messages.map(data => (
					<EventChatMessage message={data} />
				))}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// justifyContent: 'flex-start',
	},
	chatContainer: {
		height: 130,
		width: '100%',
		overflow: 'scroll',
		paddingTop: 15,
	},
	gradientBox: {
		width: '100%',
		height: 20,
		position: 'absolute',
		top: 0,
	},
});

export default ProductDiscussion;
