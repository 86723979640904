import { useRoute } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
	DesktopNavigationBar,
	Loader,
	MobileNavigationBarTop,
} from '../components';
import LiveEventSection from '../components/live-event/event-section/';
import ProductSection from '../components/live-event/product-section/ProductSection';
import { palettes } from '../config';
import { TUseRoute } from '../types/exportedTypes';
import { TStyle } from '../types/TStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
// import EventSource from 'react-native-event-source';
import AnonymousChatModal from '../components/common/anonymous-chat/Anonymous';
import EmailCollection from '../components/common/email-collection/EmailCollection';
import { useLiveEventScreen } from '../hooks/useLiveEventScreen';

//https://stackoverflow.com/questions/11642926/stop-close-webcam-stream-which-is-opened-by-navigator-mediadevices-getusermedia
export const stopVideoAndAudio = async (stream: MediaStream) => {
	stream?.getTracks()?.forEach(function (track) {
		track?.stop();
	});
};

// export const getMediaStream = async () => {
// 	return await navigator.mediaDevices.getUserMedia({
// 		audio: true,
// 		video: true,
// 	});
// };

const LiveEventScreen = () => {
	const { params } = useRoute<TUseRoute<'LiveEvent'>>();

	const {
		shouldHideScreen,
		loadingGetSingleLiveEventDetails,
		loadingStartLiveEvent,
		liveEventDetails,
		products,
		showLocks,
		messages,
		showUsernameModal,
		username,
		showEmailCollectionModal,
		isUserLoggedIn,
		isLessThanDesktopBase,
		highlightedEventProductsTime,
		setLiveStreamAccessToken,
		onTimeUpdate,
		setVisitorEmail,
		setUsername,
		setShowUsernameModal,
		setShowEmailCollectionModal,
	} = useLiveEventScreen({
		eventId: params?.eventId,
	});

	return shouldHideScreen ? null : (
		<View style={{ position: 'relative' }}>
			{getSingleLoadingState(
				loadingGetSingleLiveEventDetails,
				loadingStartLiveEvent
			) ? (
				<Loader fullView />
			) : (
				liveEventDetails && (
					<View style={styles.container}>
						{showUsernameModal && !username && (
							<View style={{ position: 'absolute' }}>
								<AnonymousChatModal
									onPress={data => {
										setShowUsernameModal(false);
										//@ts-ignore
										setUsername(data);
									}}
									isOpen={showUsernameModal}
								/>
							</View>
						)}

						{showEmailCollectionModal &&
							username &&
							!isUserLoggedIn && (
								<View style={{ position: 'absolute' }}>
									<EmailCollection
										onPress={data => {
											setShowEmailCollectionModal(false);
											setVisitorEmail(data);
										}}
										isOpen={showEmailCollectionModal}
									/>
								</View>
							)}
						{!isLessThanDesktopBase && (
							<View
								style={{
									paddingLeft: 20,
									paddingRight: 20,
									paddingTop: 15,
									zIndex: 99999,
								}}
							>
								{!isLessThanDesktopBase && (
									<DesktopNavigationBar
										containerStyles={styleNavDesktop(true)}
										hasLinks={false}
										hasSearch={false}
										hasGoBack={true}
										logoLocation={'center'}
									/>
								)}
								{isLessThanDesktopBase && (
									<MobileNavigationBarTop
										containerStyles={styleNavMobile(true)}
									/>
								)}
							</View>
						)}
						<View
							style={{
								flexDirection: 'row',
								height: 'calc(100% - 135px)',
							}}
						>
							<LiveEventSection
								about={liveEventDetails.description}
								products={products}
								showLocks={showLocks}
								messages={messages}
								onTimeUpdate={onTimeUpdate}
								setLiveStreamAccessToken={
									setLiveStreamAccessToken
								}
								highlightedEventProductsTime={
									highlightedEventProductsTime
								}
								handleChange={() => {
									if (!isUserLoggedIn) {
										setShowUsernameModal(true);
									}
								}}
								// handleTypingFocusIn={() => {
								// 	if (!isUserLoggedIn) {
								// 		setShowUsernameModal(true)
								// 	}
								// }}
								//@ts-ignore
								eventDetails={liveEventDetails}
								userId={liveEventDetails.affiliatedSeller._id}
								liveStream={liveEventDetails.liveStream}
								username={
									liveEventDetails.affiliatedSeller.username
								}
								userDescription={
									liveEventDetails?.affiliatedSeller
										?.personalInformation?.description
								}
								userProfileImageLink={
									liveEventDetails.affiliatedSeller
										.personalInformation
										?.profileImageLink ?? ''
								}
								hostDetails={
									liveEventDetails.affiliatedHosts[0]
								}
								liveEventId={params.eventId}
							/>
							{!isLessThanDesktopBase && (
								//@ts-ignore
								<ProductSection
									showLocks={showLocks}
									messages={messages}
									brands={liveEventDetails.affiliatedBrands}
									products={products}
									eventDetails={liveEventDetails}
									handleChange={() => {
										if (!isUserLoggedIn) {
											setShowUsernameModal(true);
										}
									}}
									// handleTypingFocusIn={() => {
									// 	if (!isUserLoggedIn) {
									// 		setShowUsernameModal(true)
									// 	}
									// }}
								/>
							)}
						</View>
					</View>
				)
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: '100vw',
		height: '100vh',
	},
	image: {
		width: '100%',
		height: '480px',
		resizeMode: 'cover',
		borderRadius: 6,
	},
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		// marginTop: 40,
	},
	content: {
		flex: 1,
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},
	sidebar: {
		// width: 312,
		flexBasis: 312,
		flexGrow: 0,
		flexShrink: 0,
		// flex: "0 0 312px"
	},
});

export default LiveEventScreen;

const styleNavMobile = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 16,
	};
};

const styleNavDesktop = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 40,
	};
};
