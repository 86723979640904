import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import ProfileIcon from '../../../common/ProfileIcon';
import { Stats } from '../../../home/profile-card/Stats';
import { palettes } from '../../../../config';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import useGetProfileStats from '../../../../api/chat/useGetProfileStats';
import { useAppSelector } from '../../../../redux/store';
import useGetUserDashboardStats from '../../../../api/user/useGetUserDashBoardStats';
import ProfileStatsSkeleton from '../../../common/skeleton-loaders/chat-loader/ProfileStatsSkeleton';

const ChatProfileStatsCard = () => {
	const [profile, setProfile] = useState<TGetAllUsersData>();
	const { t } = useTranslation();
	const { executeProfileStatsQuery, data } = useGetProfileStats();
	const chat = useAppSelector(state => state.chat.chat);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { executeUserDashboardStatsQuery, data: userStats } =
		useGetUserDashboardStats({ isCurrentUserStat: false });

	useEffect(() => {
		executeProfileStatsQuery({
			_id: chat?.contactPerson?._id,
		});
		executeUserDashboardStatsQuery(chat?.contactPerson?._id);
	}, [chat]);

	useEffect(() => {
		setProfile({} as TGetAllUsersData);
		if (data?.getUserById) {
			setProfile(data?.getUserById?.data);
		}
	}, [data]);

	const userStatsData = userStats?.getUserDashboardStats?.data;

	return (
		<View style={styleContainer()}>
			{profile?._id ? (
				<>
					<View>
						<View
							style={[
								styles.alignCenter,
								isLessThanDesktopBase && styles.profile,
							]}
						>
							<ProfileIcon
								style={[
									styleProfilePicture(),
									isLessThanDesktopBase && {
										marginRight: 10,
									},
								]}
								imageSrc={
									profile?.personalInformation
										?.profileImageLink
								}
							/>
							<View
								style={[
									!isLessThanDesktopBase &&
										styles.alignCenter,
								]}
							>
								<Text style={styleName()}>
									{`${profile?.username}`}
								</Text>
								<Text style={styleLocation()}>
									<Text style={styleCityName()}>
										{`${profile?.personalInformation?.address?.city}, ${profile?.personalInformation?.address?.countryLabel}`}
									</Text>
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.statsContainer}>
						<Stats
							label={t('Followers')}
							stat={userStatsData?.followersCount || 0}
						/>
						<Stats
							label={t('Events')}
							stat={userStatsData?.eventsCount || 0}
							hasBorderRight
						/>
					</View>
				</>
			) : (
				<ProfileStatsSkeleton />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		borderBottomWidth: 1,
		borderBottomColor: palettes.grey[5],
	},
	profileContainer: {
		alignItems: 'center',
	},
	profileContent: {
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},
	profile: {
		flexDirection: 'row',
		// alignItems: 'center',
	},
	name: {
		fontWeight: '600',
		color: palettes.dark[0],
	},
	location: {
		fontWeight: 'normal',
		color: palettes.grey[0],
	},
	statsContainer: {
		flexDirection: 'row',
		width: '100%',
	},
	alignCenter: { alignItems: 'center' },
});

export default ChatProfileStatsCard;

const styleLocation = () => {
	return [
		styles.location,
		{
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(20, 'font'),
		},
	];
};

const styleName = () => {
	return [
		styles.name,
		{
			fontSize: getResponsiveStyle(18, 'font'),
			lineHeight: getResponsiveStyle(18, 'font'),
			textTransform: 'capitalize',
		},
	];
};

const styleCityName = () => {
	return [
		styles.name,
		{
			fontSize: getResponsiveStyle(14, 'font'),
			fontWeight: 'normal',
			lineHeight: getResponsiveStyle(18, 'font'),
			color: palettes.grey[0],
			textTransform: 'capitalize',
		},
	];
};

const styleProfilePicture = () => {
	return {
		marginBottom: getResponsiveStyle(13),
		width: getResponsiveStyle(80),
		height: getResponsiveStyle(80),
	};
};

// const styleProfileContainer = () => {
// 	return [
// 		styles.profileContainer,
// 		{
// 			marginBottom: getResponsiveStyle(20),
// 		},
// 	];
// };

const styleContainer = () => {
	return [
		styles.container,
		{
			paddingVertical: getResponsiveStyle(6),
			marginBottom: getResponsiveStyle(8),
		},
	];
};
