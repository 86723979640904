import { gql } from '@apollo/client';

const CREATE_STATUS = gql`
	mutation createStatus(
		$status: String!
		$file: String!
		$fileType: String!
		$isEventPost: Boolean
		$event: ID
	) {
		createStatus(
			args: {
				status: $status
				file: $file
				fileType: $fileType
				isEventPost: $isEventPost
				event: $event
			}
		) {
			statusCode
			message
		}
	}
`;

const DELETE_STATUS = gql`
	mutation deleteStatus($_id: ID!) {
		deleteStatus(_id: $_id) {
			statusCode
			success
		}
	}
`;

export { CREATE_STATUS, DELETE_STATUS };
