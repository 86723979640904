import { Image, ImageProps, Pressable } from 'react-native';
import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const AttachPictureIcon = (props: SvgProps) => {
	return (
		<Pressable>
			<Svg
				width={21}
				height={21}
				viewBox="0 0 22 21"
				fill="none"
				{...props}
			>
				<Path
					fill="#7C889B"
					stroke="#fff"
					strokeWidth={0.3}
					d="M17.358 15.25v-2.725h1.617V15.4h2.875v1.617h-2.875v2.875h-1.617v-2.875h-2.875V15.4h2.875v-.15zM5.542 16.358h6.725v1.617h-10.4a.802.802 0 0 1-.8-.802V1.827a.808.808 0 0 1 .8-.802h17.266c.442 0 .8.36.8.802v8.481h-1.616V2.642H2.683V16.57l.256-.257 9.477-9.476 2.726 2.725v2.287l-2.62-2.619-.106-.106-.106.106-6.874 6.872-.256.256h.362zm1.125-11.5a1.767 1.767 0 1 1 0 3.534 1.767 1.767 0 0 1 0-3.534z"
				/>
			</Svg>
		</Pressable>
	);
};

export default AttachPictureIcon;
