import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, Pressable, StyleSheet, Platform } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setLanguage } from '../../../redux/uiSlice';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CheckIcon } from '../icons';
type LanguageItems = {
	value: TLanguages;
	label: string;
	subVal: string | string[];
};
const LanguageSelectorMobile = () => {
	const dispatch = useAppDispatch();
	const { i18n, t } = useTranslation();
	const primary = usePrimaryColors();
	const [selectedTab, setSelectedTab] = useState<TLanguages>();
	const LanguageItems: LanguageItems[] = [
		{
			value: 'en',
			label: 'English (U.K)',
			subVal: t('English'),
		},
		{
			value: 'fr',
			label: 'Français (France)',
			subVal: t('French'),
		},
	];

	const language = useAppSelector(s => s.ui.language);

	useEffect(() => {
		language && i18n.changeLanguage(language);
	}, [language]);
	const onSelect = (value: TLanguages) => {
		value !== language && setSelectedTab(value);
	};

	return (
		<View style={[{ height: '70vh' }, styleContainer()]}>
			{LanguageItems.map(item => {
				return (
					<Pressable onPress={() => onSelect(item.value)}>
						<View style={[styles.selectedView]}>
							<View
								style={[
									styleViewContainer(
										item.value,
										language,
										primary,
										selectedTab
									),
								]}
							>
								<Text
									style={[
										styles.labelStyle,
										selectedStyle(item.value, selectedTab),
									]}
								>
									{item.label}
								</Text>
								<Text
									style={[
										styles.subValStyle,
										selectedStyle(item.value, selectedTab),
									]}
								>
									{item.subVal}
								</Text>
							</View>
							{selectedTab === item.value &&
								selectedTab !== language && (
									<View
										style={{
											alignItems: 'center',
											flexDirection: 'row',
										}}
									>
										<CheckIcon color={primary[0]} />
										<Text
											style={{
												color: primary[0],
												fontWeight: '600',
											}}
										>
											{t('Selected')}
										</Text>
									</View>
								)}
							{item.value === language && (
								<View>
									<Text style={{ color: primary[0] }}>
										{t('Current')}
									</Text>
								</View>
							)}
						</View>
					</Pressable>
				);
			})}

			<Button
				size="lg"
				variant="primary"
				outerContainerProps={{
					style: {
						// marginTop: getResponsiveStyle(30),
						...Platform.select({
							native: {
								bottom: 0,
							},
							default: {
								top: '60%',
							},
						}),
						position: 'absolute',
						left: '0',
						right: '0',
					},
				}}
				onPress={() => {
					selectedTab && dispatch(setLanguage(selectedTab));
				}}
				title={`${t('Apply Language')}`}
			/>
		</View>
	);
};

export default LanguageSelectorMobile;
const styles = StyleSheet.create({
	labelStyle: {
		fontSize: getResponsiveStyle(18, 'font'),
	},
	subValStyle: {
		fontSize: getResponsiveStyle(10, 'font'),
	},
	selectedStyle: {},
	currentStyle: {},
	selectedView: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',

		paddingVertical: getResponsiveStyle(10),
	},
});
function styleContainer() {
	return {
		marginRight: getResponsiveStyle(12),
	};
}

function styleViewContainer(
	current: string,
	language: TLanguages,
	color: string[],
	selectedTab?: TLanguages
) {
	return language === current
		? {
				borderLeftWidth: 2,
				paddingLeft: 10,
				borderLeftColor: color[0],
		  }
		: current === selectedTab
		? { borderLeftWidth: 2, paddingLeft: 10, borderLeftColor: color[3] }
		: {};
}

function styleListItemContainer() {
	return {
		marginBottom: getResponsiveStyle(8),
	};
}
function selectedStyle(current: string, selectedTab?: TLanguages) {
	return selectedTab && selectedTab === current
		? {
				color: palettes.dark[1],
		  }
		: {
				color: palettes.grey[0],
		  };
}
