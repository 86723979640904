import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { DropzoneProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import * as Yup from 'yup';
import { Button, Dropdown, DropDownPickerLarge, Input, Upload } from '../..';
import { useCreateUser } from '../../../api/auth';
import useGetUserLazy from '../../../api/auth/useGetUserLazy';
import { useCreateMedia } from '../../../api/common/useCreateMedia';
import { palettes } from '../../../config';
import { EDIT_USER } from '../../../graphql/profile/mutations';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import {
	setProfileImage,
	setYourInformation,
} from '../../../redux/createUserSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setPrimaryColors } from '../../../redux/uiSlice';
import { TStyle } from '../../../types/TStyle';
import countryNamesWithCodes from '../../../utils/countryNamesWithCodes';
import { createRequiredLabels } from '../../../utils/createRequiredLabels';
import { getCountryLabelFromValue } from '../../../utils/getCountryLabelFromValue';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import PlusIcon from '../../home/PlusIcon';

const DropdownEventType = [
	{
		label: 'Public',
		value: 'PUBLIC',
	},
	{
		label: 'Private',
		value: 'PRIVATE',
	},
];

const UploadText = styled.Text`
	font-size: ${getResponsiveStyle(12)}px;
	line-height: ${getResponsiveStyle(12)}px;
	color: ${palettes.grey[1]};
	padding-bottom: 2px;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

// type TInitialBrandValues = {
// 	accountStatus?: string;
// 	adminNote?: string;
// 	businessPhoneNumber?: string;
// 	civility?: string;
// 	kybStatus?: string;
// 	region?: string;
// 	vat?: string;
// };

const UserTypeIsBrand: boolean = false;

const brandsSchema = Yup.object({
	region: Yup.string(),
	civility: Yup.string(),
	businessPhoneNumber: Yup.string(),
	vat: Yup.string(),
	kybStatus: Yup.string(),
	accountStatus: Yup.string(),
	adminNote: Yup.string(),
});

const validationSchema = Yup.object({
	firstName: Yup.string().min(3, 'Min length: 3').required('Required'),
	lastName: Yup.string().min(3, 'Min length: 3').required('Required'),
	gender: Yup.mixed<TGender>().required('Required'),
	phoneNumber: Yup.string().required('Required'),
	address1: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	zipCode: Yup.string().required('Required'),
	countryCode: Yup.string().required('Required'),
	website: Yup.string(),
	description: Yup.string(),
	numericSignature: Yup.string(),
	interestTags: Yup.array().of(Yup.string()),
	eventTypes: Yup.string(),
	favouriteCategories: Yup.array()
		.of(Yup.mixed<TTagType>())
		.min(1, 'Required')
		.required('Required'),
});

const editSchema = Yup.object({
	firstName: Yup.string().min(3, 'Min length: 3').required('Required'),
	lastName: Yup.string().min(3, 'Min length: 3').required('Required'),
	phoneNumber: Yup.string().required('Required'),
	address1: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	zipCode: Yup.string().required('Required'),
	countryCode: Yup.string().required('Required'),
	website: Yup.string(),
	description: Yup.string(),
	numericSignature: Yup.string(),
	interestTags: Yup.array().of(Yup.string()),
	favouriteCategories: Yup.array()
		.of(Yup.mixed<TDropdownObject>())
		.min(1, 'Required')
		.required('Required'),
});

const updatedSchema = UserTypeIsBrand
	? validationSchema.concat(brandsSchema)
	: validationSchema;

// type TInitialCommonValues = Yup.InferType<typeof updatedSchema>;

// const initialBrandState = {
// 	accountStatus: '',
// 	adminNote: '',
// 	businessPhoneNumber: '',
// 	civility: '',
// 	kybStatus: '',
// 	region: '',
// 	vat: '',
// };

export type TInitialYourInformationValues = {
	favouriteCategories: TTagType[];
	interestTags: string[];
	address1: string;
	city: string;
	countryCode: string;
	countryLabel: string;
	zipCode: string;
	address2: string;
	description: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	profileImageLink: string;
	website: string;
	numericSignature: string;
	eventType: TEventType;
	gender: TGender;
};

const initialEmptyValues: TInitialYourInformationValues = {
	favouriteCategories: [],
	interestTags: [''],
	address1: '',
	city: '',
	countryCode: '',
	countryLabel: '',
	zipCode: '',
	address2: '',
	description: '',
	firstName: '',
	lastName: '',
	phoneNumber: '',
	profileImageLink: '',
	website: '',
	numericSignature: '',
	eventType: 'PRIVATE',
	gender: 'MALE',
};

type Props = {
	isProfile?: boolean;
};

const YourInformationForm = ({ isProfile = false }: Props) => {
	const { isDesktop } = useIsSpecificViewportWidth();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const language = useAppSelector(state => state.ui.language);
	const dispatch = useAppDispatch();
	const DropdownGenderValues = [
		{
			label: t('Male'),
			value: 'MALE',
		},
		{
			label: t('Female'),
			value: 'FEMALE',
		},
		{
			label: t('Other'),
			value: 'OTHER',
		},
	];
	const {
		profileImageLink,
		userSelectedTypeOfAccount,
		signupSidebarArray,
		personalInformation,
		loggedInUserDetails,
		args,
	} = useAppSelector(state => ({
		userSelectedTypeOfAccount: state.createUser.args.typeOfAccount,
		signupSidebarArray: state.ui.SignupSidebarArray,
		profileImageLink:
			state.createUser.args?.personalInformation?.profileImageLink,
		personalInformation: state.createUser.args.personalInformation,
		email: state.createUser.args.email,
		password: state.createUser.args.password,
		loggedInUserDetails: state.auth.loggedInUserDetails,
		args: state.createUser.args,
	}));

	const { goToNextStepScreen } = useNextStepScreen(signupSidebarArray);

	const [dropdownOpen, setDropdownOpen] = useState({
		gender: false,
		country: false,
		eventTypes: false,
		favouriteCategories: false,
	});
	const [initialValues, setInitialValues] = useState(initialEmptyValues);

	const [editUserInitialState, setEditUserInitialState] =
		useState<TInitialYourInformationValues>();

	useEffect(() => {
		if (
			!!args.email &&
			!!args.personalInformation.firstName &&
			!!args.personalInformation.lastName
		) {
			setInitialValues({
				...initialValues,
				firstName: args.personalInformation.firstName,
				lastName: args.personalInformation.lastName,
			});
		}
	}, []);

	useEffect(() => {
		if (isProfile) {
			setEditUserInitialState({
				profileImageLink:
					loggedInUserDetails?.personalInformation.profileImageLink ??
					'',
				firstName:
					loggedInUserDetails?.personalInformation.firstName ?? '',
				lastName:
					loggedInUserDetails?.personalInformation.lastName ?? '',
				website: loggedInUserDetails?.website ?? '',
				description:
					loggedInUserDetails?.personalInformation.description ?? '',
				numericSignature: loggedInUserDetails?.numericSignature ?? '',
				favouriteCategories: memoizedDropdownCategories.filter(
					loggedInValue =>
						loggedInUserDetails?.personalInformation.favouriteCategories?.includes(
							loggedInValue.value
						)
				),
				phoneNumber:
					loggedInUserDetails?.personalInformation.phoneNumber ?? '',
				interestTags:
					loggedInUserDetails?.personalInformation.interestTags ?? [],
				address1:
					loggedInUserDetails?.personalInformation.address.address1 ??
					'',
				address2:
					loggedInUserDetails?.personalInformation.address.address2 ??
					'',
				city:
					loggedInUserDetails?.personalInformation.address.city ?? '',
				countryCode:
					loggedInUserDetails?.personalInformation.address
						.countryCode ?? '',
				countryLabel:
					loggedInUserDetails?.personalInformation.address
						.countryLabel ?? '',
				zipCode:
					loggedInUserDetails?.personalInformation.address.zipCode ??
					'',
				//are filtered out when sending edit user request
				eventType: 'PRIVATE',
				gender: 'MALE',
			});
			dispatch(
				setProfileImage(
					loggedInUserDetails?.personalInformation.profileImageLink ??
						''
				)
			);
		}
	}, []);

	const onDropProfileImage: DropzoneProps['onDrop'] = acceptedFiles => {
		if (acceptedFiles.length > 0) {
			uploadMedia(acceptedFiles[0], 'USER', 'profile-image');
		}
	};

	const onCompletedUploadMedia = (data: TMediaResponse) => {
		if (data.createMedia.success && data.createMedia.data) {
			dispatch(setProfileImage(data.createMedia.data.src));
			setAlert(t('Image uploaded successfully'), 'normal');
		} else {
			setAlert(t('Image uploading failed'), 'danger');
		}
	};

	const onCompletedCreateUser = (data: TCreateUserResponse) => {
		if (data?.createUser?.success) {
			dispatch(
				setPrimaryColors(
					data?.createUser?.data?.typeOfAccount || 'SELLER'
				)
			);
			// setAlert(
			// 	t('Please see the email we sent you to create your account'),
			// 	'normal'
			// );
			goToNextStepScreen();
		} else {
			setAlert(t('Error occurred during account creation'), 'danger');
		}
	};
	const { INTEREST_TAGS, DROPDOWN_CATEGORIES, BRAND_PILL_CATEGORIES } =
		useAppSelector(state => state.tags);

	const memoizedDropdownCategories = useMemo(
		() => [...DROPDOWN_CATEGORIES, ...BRAND_PILL_CATEGORIES],
		[]
	);

	const removeDuplicateFromArrayObj = (combinedArr: TTagType[]) => {
		const newArr: TTagType[] = [];
		for (let i = 0; i < combinedArr.length; i++) {
			const element = combinedArr[i];
			const findElement = newArr.findIndex(
				item => item.value === element.value
			);

			if (findElement === -1) {
				newArr.push(element);
			}
		}
		return newArr;
	};

	const categorySort = (array: TTagType[]) => {
		return array
			.map(category => {
				return {
					...category,
					label: category[language],
				};
			})
			.sort((a, b) => String(a.label).localeCompare(String(b.label)));
	};

	useEffect(() => {
		if (personalInformation.address?.countryLabel !== '') {
			userSelectedTypeOfAccount !== 'SELLER'
				? createUser()
				: goToNextStepScreen();
		}
	}, [personalInformation.address?.countryLabel]);

	const { createUser, loading: loadingCreateUser } = useCreateUser(
		onCompletedCreateUser
	);
	const { uploadMedia, loading: loadingUploadMedia } = useCreateMedia({
		onCompleted: onCompletedUploadMedia,
	});

	const { getUser } = useGetUserLazy();

	const [editUser, { loading: loadingEditUser }] = useMutation<
		TEditUserResponse,
		TEditUserInputs
	>(EDIT_USER, {
		onCompleted: data => {
			if (data.editUser.success && data.editUser.data) {
				setAlert(t('User information updated!'), 'normal');
				getUser();
			} else {
				setAlert(t('Unable to update user information'), 'danger');
			}
		},
		onError: () => {
			setAlert(t('Unable to update user information'), 'danger');
		},
	});

	const loadingState = getSingleLoadingState(
		loadingCreateUser,
		loadingEditUser
	);
	const handleEditUserSubmit = (values: TInitialYourInformationValues) => {
		const {
			gender,
			eventType,
			website,
			numericSignature,
			address1,
			address2,
			city,
			countryCode,
			countryLabel,
			zipCode,
			...rest
		} = values;
		const mappedFavouriteCategoriesValues = rest.favouriteCategories.map(
			categories => categories.value
		);
		editUser({
			variables: {
				email: loggedInUserDetails?.email ?? '',
				args: {
					website,
					numericSignature,
					personalInformation: {
						...rest,
						profileImageLink,
						address: {
							address1,
							address2,
							city,
							countryCode,
							countryLabel,
							zipCode,
						},
						favouriteCategories: mappedFavouriteCategoriesValues,
					},
				},
			},
		});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={
				isProfile
					? editUserInitialState ?? initialValues
					: initialValues
			}
			validationSchema={isProfile ? editSchema : updatedSchema}
			onSubmit={values => {
				const personalInformation = {
					...values,
					countryLabel: getCountryLabelFromValue(values.countryCode),
					profileImageLink: profileImageLink ?? '',
				};
				dispatch(setYourInformation(personalInformation));
			}}
		>
			{({
				dirty,
				isValid,
				handleSubmit,
				handleChange,
				handleBlur,
				setFieldValue,
				setFieldTouched,
				values,
				touched,
				errors,
			}) => (
				<>
					<View
						style={[styleRowContainer(), { alignItems: 'center' }]}
					>
						<Upload
							label={t('Profile Image')}
							hint={t('Add Image')}
							containerStyles={stylesProfileImageContainer()}
							uploadIcon={<PlusIcon height={14} width={14} />}
							accept={'image/*'}
							onDrop={onDropProfileImage}
							loading={loadingUploadMedia}
						/>

						<Image
							source={{
								uri: profileImageLink,
							}}
							style={{
								borderRadius: 9999,
								height: getResponsiveStyle(120, 'dimensions'),
								width: getResponsiveStyle(120, 'dimensions'),
								marginLeft: getResponsiveStyle(16),
								marginTop: getResponsiveStyle(24, 'font'),
							}}
						/>
					</View>
					<View style={styleRowContainer()}>
						<Input
							value={values.firstName}
							label={createRequiredLabels(t('First Name'))}
							containerStyles={styles.flex1}
							onChangeText={handleChange('firstName')}
							onBlur={handleBlur('firstName')}
							error={
								touched.firstName && errors.firstName
									? errors.firstName
									: ''
							}
						/>
						<Input
							value={values.lastName}
							label={createRequiredLabels(t('Last Name'))}
							containerStyles={styles.flex1}
							onChangeText={handleChange('lastName')}
							onBlur={handleBlur('lastName')}
							error={
								touched.lastName && errors.lastName
									? errors.lastName
									: ''
							}
						/>
					</View>

					<View style={[styleRowContainer(), { zIndex: 5000 }]}>
						{!isProfile && (
							<DropDownPickerLarge
								items={DropdownGenderValues}
								open={dropdownOpen.gender}
								setOpen={() =>
									setDropdownOpen({
										country: false,
										eventTypes: false,
										favouriteCategories: false,
										gender: !dropdownOpen.gender,
									})
								}
								setValue={(item: any) => {
									setFieldValue('gender', item());
								}}
								value={values.gender}
								placeholder=""
								label={createRequiredLabels(t('Gender'))}
								outerContainerStyles={{ width: '50%' }}
								onClose={() => {
									!values.gender &&
										setFieldTouched('gender', true);
								}}
								error={
									touched.gender && !values.gender
										? errors.gender
										: ''
								}
							/>
						)}
						<Input
							value={values.phoneNumber}
							label={createRequiredLabels(t('Phone Number'))}
							containerStyles={styles.flex1}
							onChangeText={handleChange('phoneNumber')}
							onBlur={handleBlur('phoneNumber')}
							error={
								touched.phoneNumber && errors.phoneNumber
									? errors.phoneNumber
									: ''
							}
						/>
					</View>

					{/* {!isProfile && ( */}
					<>
						<View style={styleAddressContainer()}>
							<Input
								label={createRequiredLabels(t('Address'))}
								containerStyles={[
									styles.flex1,
									styleAddressInput1(),
								]}
								value={values.address1}
								onChangeText={handleChange('address1')}
								onBlur={handleBlur('address1')}
								error={
									touched.address1 && errors.address1
										? errors.address1
										: ''
								}
							/>
							<Input
								containerStyles={[
									styles.flex1,
									{ marginTop: getResponsiveStyle(16) },
								]}
								onChangeText={handleChange('address2')}
								value={values.address2}
								onBlur={handleBlur('address2')}
								error={
									touched.address2 && errors.address2
										? errors.address2
										: ''
								}
							/>
						</View>
						<View style={styleRowContainer()}>
							<Input
								label={createRequiredLabels(t('City'))}
								containerStyles={styles.flex1}
								onChangeText={handleChange('city')}
								value={values.city}
								onBlur={handleBlur('city')}
								error={
									touched.city && errors.city
										? errors.city
										: ''
								}
							/>
							<Input
								label={createRequiredLabels(t('Zip Code'))}
								containerStyles={styles.flex1}
								onChangeText={handleChange('zipCode')}
								value={values.zipCode}
								onBlur={handleBlur('zipCode')}
								error={
									touched.zipCode && errors.zipCode
										? errors.zipCode
										: ''
								}
							/>
						</View>
						<View style={[styleRowContainer(), { zIndex: 5000 }]}>
							<DropDownPickerLarge
								items={countryNamesWithCodes}
								open={dropdownOpen.country}
								setOpen={() =>
									setDropdownOpen({
										country: !dropdownOpen.country,
										eventTypes: false,
										favouriteCategories: false,
										gender: false,
									})
								}
								searchable
								setValue={(value: any) => {
									setFieldValue('countryCode', value());
								}}
								value={values.countryCode}
								placeholder=""
								containerStyle={{ width: '100%' }}
								label={createRequiredLabels(t('Country'))}
								onOpen={() =>
									setFieldTouched('countryCode', true)
								}
								onClose={() => {
									!values.countryCode &&
										setFieldTouched('countryCode', true);
								}}
								error={
									touched.countryCode && !values.countryCode
										? errors.countryCode
										: ''
								}
							/>
						</View>
					</>
					{/* )} */}
					{/* {userSelectedTypeOfAccount!=="CONSUMER" &&<View style={styleRowContainer()}>
						<Input
							value={values.website}
							label={t('Website')}
							containerStyles={styles.flex1}
							onChangeText={handleChange('website')}
							onBlur={handleBlur('website')}
							error={
								touched.website && errors.website
									? errors.website
									: ''
							}
						/>
					</View>} */}

					<View style={styleRowContainer()}>
						<Input
							value={values.description}
							label={t('Description')}
							multiline
							containerStyles={styles.flex1}
							style={[
								styleDescription(isDesktop),
								styles.description,
							]}
							onChangeText={handleChange('description')}
							onBlur={handleBlur('description')}
							error={
								touched.description && errors.description
									? errors.description
									: ''
							}
						/>
					</View>

					{/* {userSelectedTypeOfAccount!=="CONSUMER" &&
					<View style={styleRowContainer()}>
						<Input
							value={values.numericSignature}
							label={t('Numeric Signature')}
							containerStyles={styles.flex1}
							decoration={<UploadText>{t('Upload')}</UploadText>}
							onChangeText={handleChange('numericSignature')}
							onBlur={handleBlur('numericSignature')}
							error={
								touched.numericSignature &&
								errors.numericSignature
									? errors.numericSignature
									: ''
							}
						/>
					</View>} */}

					{/* Brands Inputs -- start */}
					{/* {UserTypeIsBrand && (
						<>
							<View style={styleRowContainer()}>
								<Input
									label={t('Region')}
									containerStyles={styles.flex1}
									onChangeText={handleChange('region')}
									onBlur={handleBlur('region')}
									error={
										touched.region && errors.region
											? errors.region
											: ''
									}
								/>
								<Input
									label={t('Civility')}
									containerStyles={styles.flex1}
									onChangeText={handleChange('civility')}
									onBlur={handleBlur('civility')}
									error={
										touched.civility && errors.civility
											? errors.civility
											: ''
									}
								/>
							</View>
							<View style={styleRowContainer()}>
								<Input
									label={t('Business Phone Number')}
									containerStyles={{ flex: 0.69 }}
									onChangeText={handleChange(
										'businessPhoneNumber'
									)}
									onBlur={handleBlur('businessPhoneNumber')}
									error={
										touched.businessPhoneNumber &&
										errors.businessPhoneNumber
											? errors.businessPhoneNumber
											: ''
									}
								/>
								<Input
									label={t('VAT')}
									containerStyles={{ flex: 0.31 }}
									onChangeText={handleChange('vat')}
									onBlur={handleBlur('vat')}
									error={
										touched.vat && errors.vat
											? errors.vat
											: ''
									}
								/>
							</View>
						</>
					)} */}
					{/* Brands Inputs -- end */}

					<View style={[styleRowContainer(), { zIndex: 5001 }]}>
						<Dropdown
							isSearchable
							isMulti
							containerStyles={styles.flex1}
							options={categorySort(
								INTEREST_TAGS.filter(
									category =>
										category.value !== 'my-followers'
								)
							)}
							inputProps={{
								placeholder: t('Interest tags hint'),
							}}
							labelProps={{
								label: t('Interest tags'),
							}}
							values={categorySort(
								INTEREST_TAGS.filter(item =>
									values.interestTags.includes(item.value)
								)
							)}
							onChangeValue={value => {
								setFieldValue('interestTags', [
									...values.interestTags,
									value.value,
								]);
							}}
							onPressCrossOnBadge={value => {
								const filteredInterestTags =
									values.interestTags.filter(
										item => item !== value
									);

								setFieldValue(
									'interestTags',
									filteredInterestTags
								);
							}}
						/>
					</View>
					{/* 
					{!isProfile && (
						<>
							{userSelectedTypeOfAccount === 'CONSUMER' && (
								<View
									style={[
										styleRowContainer(),
										{
											zIndex: dropdownOpen.eventTypes
												? 5001
												: 5000,
										},
									]}
								>
									<DropDownPickerLarge
										items={DropdownEventType}
										open={dropdownOpen.eventTypes}
										setOpen={() =>
											setDropdownOpen({
												country: false,
												eventTypes:
													!dropdownOpen.eventTypes,
												favouriteCategories: false,
												gender: false,
											})
										}
										setValue={(item: any) => {
											setFieldValue('eventType', item());
										}}
										value={values.eventType}
										placeholder=""
										containerStyle={{ width: '100%' }}
										label={t('Event Types')}
										onOpen={() =>
											setFieldTouched('eventType', true)
										}
									/>
								</View>
							)}
						</>
					)} */}

					<View
						style={[
							styleRowContainer(),
							{
								zIndex: dropdownOpen.favouriteCategories
									? 5001
									: 5000,
							},
						]}
					>
						<Dropdown
							options={categorySort(
								removeDuplicateFromArrayObj(
									memoizedDropdownCategories
								)?.filter(
									category =>
										category.value !== 'my-followers'
								)
							)}
							onChangeValue={item => {
								setFieldValue('favouriteCategories', [
									...values.favouriteCategories,
									item,
								]);
							}}
							inputProps={{
								onBlur: () => {
									values.favouriteCategories.length <= 0 &&
										setFieldTouched(
											'favouriteCategories',
											true
										);
								},
							}}
							values={categorySort(values.favouriteCategories)}
							labelProps={{
								label: createRequiredLabels(
									t('Favourite Product Categories')
								),
							}}
							onPressCrossOnBadge={value => {
								const filteredValues: any = Array.from(
									values.favouriteCategories
								).filter(v => v.value !== value);
								setFieldValue(
									'favouriteCategories',
									filteredValues
								);
							}}
						/>
					</View>

					{/* Brand inputs -- start */}
					{/* {UserTypeIsBrand && (
						<>
							<View style={styleRowContainer()}>
								<Input
									label={t('KYB status')}
									containerStyles={styles.flex1}
									onChangeText={handleChange('kybStatus')}
									onBlur={handleBlur('kybStatus')}
									error={
										touched.kybStatus && errors.kybStatus
											? errors.kybStatus
											: ''
									}
								/>
								<Input
									label={t('Account status')}
									containerStyles={styles.flex1}
									onChangeText={handleChange('accountStatus')}
									onBlur={handleBlur('accountStatus')}
									error={
										touched.accountStatus &&
										errors.accountStatus
											? errors.accountStatus
											: ''
									}
								/>
							</View>

							<View style={styleRowContainer()}>
								<Input
									label={t('Admin note')}
									containerStyles={styles.flex1}
									onChangeText={handleChange('adminNote')}
									onBlur={handleBlur('adminNote')}
									error={
										touched.adminNote && errors.adminNote
											? errors.adminNote
											: ''
									}
								/>
							</View>
						</>
					)} */}
					{/* Brand inputs -- end */}

					<Button
						title={isProfile ? t('Update') : t('Next')}
						outerContainerProps={{
							style: { marginTop: getResponsiveStyle(42) },
						}}
						variant={
							(dirty || isProfile) &&
							isValid &&
							values.favouriteCategories.length > 0
								? 'primary'
								: 'disabled'
						}
						size="lg"
						onPress={() =>
							isProfile
								? handleEditUserSubmit(values)
								: handleSubmit()
						}
						loading={loadingState}
					/>
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flex: 1,
	},
	description: {
		paddingVertical: 20,
	},
});

export default YourInformationForm;

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(24),
	} as TStyle;
};
function stylesProfileImageContainer(): TStyle {
	return {
		width: getResponsiveStyle(128, 'dimensions'),
		height: getResponsiveStyle(136, 'dimensions'),
		marginBottom: getResponsiveStyle(24),
	};
}

function styleAddressContainer() {
	return { marginBottom: getResponsiveStyle(13) };
}

function styleAddressInput1() {
	return { marginBottom: getResponsiveStyle(6) };
}

function styleDescription(isDesktop: boolean) {
	return {
		height: getResponsiveStyle(isDesktop ? 160 : 80, 'dimensions'),
	};
}
