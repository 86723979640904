import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	stat: number;
};

export const EventAttendedStat = ({ stat }: Props) => {
	const { t } = useTranslation();
	return (
		<View style={[styles.container, styleContainer()]}>
			<View style={styles.innerContainer}>
				<Text style={[styles.label, styleLabel()]}>
					{t('Event Attended')}
				</Text>
				<Text style={styles.divider}>/</Text>
				<Text style={[styles.stat, styleStat()]}>{stat}</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',

		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
	innerContainer: {
		flex: 1,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	stat: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
	label: {
		color: palettes.grey[0],
	},
	divider: {
		marginLeft: getResponsiveStyle(5, 'spacing'),
		marginRight: getResponsiveStyle(5, 'spacing'),
	},
});

const styleLabel = () => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
	};
};

const styleStat = () => {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		marginBottom: getResponsiveStyle(3),
	};
};

function styleContainer() {
	return {
		paddingTop: getResponsiveStyle(16),
		marginBottom: getResponsiveStyle(16),
	};
}
