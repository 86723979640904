import React from 'react';
import { Text } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import styled from 'rn-css';

const StyledPressableContainer = styled(Text)<{ isPressable: boolean }>`
	cursor: ${({ isPressable }) => (isPressable ? 'pointer' : 'normal')};
`;

type Props = {
	onPressIcon?: () => void;
	isPressable?: boolean;
	outlineColor?: string;
} & SvgProps;

const NotificationBellIcon = ({
	onPressIcon,
	isPressable = true,
	outlineColor = 'rgba(152,162,178,1)',
	...props
}: Props) => {
	return (
		<StyledPressableContainer
			isPressable={isPressable}
			onPress={onPressIcon}
		>
			<Svg viewBox="0 0 24 24" {...props}>
				<Path fill="none" d="M0 0h24v24H0z" />
				<Path
					d="M20 17h2v2H2v-2h2v-7a8 8 0 1 1 16 0v7zm-2 0v-7a6 6 0 1 0-12 0v7h12zm-9 4h6v2H9v-2z"
					fill={outlineColor}
				/>
			</Svg>
		</StyledPressableContainer>
	);
};

export default NotificationBellIcon;
