import { useMutation } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { SET_PAYMENT_STATUS } from '../graphql/order/mutations';
import { CHARGE_CUSTOMER_WITHOUT_CARD } from '../graphql/stripe/mutations';
import { stripe_key } from '../utils/stripeUtil';
import { useSetAlert } from './useSetAlerts';
import { updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useCheckOut } from './handleCheckout/useCheckOut';

type TQueryArgs = {
	// onChargingCustomerSuccess: (data: TODO, items: [], orderId: string) => void;
	// confirmPayment?: any;
	amountToBePaid: any,
	deliveryFee: any,
	Total: any,
	brandInCartIds: any,
	items: any,
	maxOrderForDeliveryFee: any,
	setAmountToBePaid: any;
	// setLoadingStripe3Ds?: any;
};

type TFnArgs = {
	args: TODO;
	orderId: string;
	items: [];
};
export const useOneStopCardPayment = ({
	// onChargingCustomerSuccess,
	// confirmPayment,
	amountToBePaid,
	deliveryFee,
	Total,
	brandInCartIds,
	items,
	maxOrderForDeliveryFee,
	setAmountToBePaid,
	// setLoadingStripe3Ds,
}: TQueryArgs) => {
	const {
		confirmPayment,
		onChargingCustomerSuccess,
		loadingStripe3Ds,
		setLoadingStripe3Ds,
	} = useCheckOut({
		amountToBePaid,
		deliveryFee,
		Total,
		brandInCartIds,
		items,
		maxOrderForDeliveryFee,
		setAmountToBePaid
	})
	const [
		chargeCustomerWithoutSavingCard,
		{ loading: chargingWithoutCardLoading, data: chargingWithoutCardData },
	] = useMutation(CHARGE_CUSTOMER_WITHOUT_CARD);

	const [setPaymentStatus] = useMutation(SET_PAYMENT_STATUS);
	const { setAlert } = useSetAlert();

	const chargeCustomerWithoutSavingCardFn = ({
		args,
		items,
		orderId,
	}: TFnArgs) => {
		chargeCustomerWithoutSavingCard({
			variables: { args },
			onCompleted: async data => {
				if (
					data?.chargeCustomerWithoutSavingCard?.message ===
					'Charge not completed'
				) {
					setLoadingStripe3Ds(true);
					const stripe = await loadStripe(stripe_key);
					await stripe?.handleCardAction(
						data?.chargeCustomerWithoutSavingCard?.data
							?.client_secret
					);
					setLoadingStripe3Ds(false);
					confirmPayment({
						variables: {
							args: {
								paymentIntentKey:
									data?.chargeCustomerWithoutSavingCard?.data
										?.id,
							},
						},
						onCompleted: async (res: any) => {
							if (res?.confirmCharge?.success) {
								await onChargingCustomerSuccess(
									res,
									items,
									orderId
								);
							} else {
								setAlert(
									'Unable to complete payment',
									'danger'
								);
								await setPaymentStatus({
									variables: {
										orderId,
										paymentStatus: 'FAILED',
									},
								});
							}
						},
					});
				} else if (
					data?.chargeCustomerWithoutSavingCard?.message ===
					'Charge succeeded'
				) {
					await onChargingCustomerSuccess(data, items, orderId);
				} else {
					setAlert('Unable to complete payment', 'danger');
				}
			},

			onError: async () => {
				await setPaymentStatus({
					variables: {
						orderId,
						paymentStatus: 'FAILED',
					},
				});
			},
		});
	};

	return {
		chargingWithoutCardLoading,
		chargingWithoutCardData,
		chargeCustomerWithoutSavingCardFn,
	};
};
