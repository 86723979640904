import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Pressable } from 'react-native-web-hover';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CheckIcon } from '../icons';
import Label from '../label/Label';
import styled from 'rn-css';

type Props = {
	hintText?: string | JSX.Element;
	label?: string;
	value?: boolean;
	isLink?: boolean;
	onChange?: (value: boolean) => void;
	onPressLink?: () => void;
	containerStyles?: TStyle;
};

const Link = styled.Text`
		cursor: pointer;
		color: ${palettes.grey[0]};
		width: auto;
		&:hover {
			text-decoration: underline;
		}
	`;

const Checkbox = ({
	hintText,
	label,
	isLink,
	onChange,
	onPressLink,
	value = false,
	containerStyles,
}: Props) => {
	const primary = usePrimaryColors();
	return (
		<>
			{label && <Label label={label} />}
			<View style={[styles.container, containerStyles]}>
				<Pressable
					style={({ pressed, focused }) =>
						styleOuterContainer(pressed, focused, primary)
					}
				>
					{({ pressed, focused }) => (
						<Text
							onPress={() => onChange && onChange(!value)}
							style={[
								styles.innerContainer,
								(pressed || focused || value) && {
									borderColor: primary[0],
								},
							]}
						>
							{value && <CheckIcon color={primary[0]} />}
						</Text>
					)}
				</Pressable>
				{hintText && (
					<>
						{isLink ?
							<Link
								style={[styles.termsAndConditions, styleTermsAndConditions()]}
								onPress={onPressLink}
							>
								{hintText}
							</Link> :
							<Text style={{ marginLeft: getResponsiveStyle(11) }}>
								{hintText}
							</Text>
						}
					</>

				)}
			</View>
		</>
	);
};

function styleTermsAndConditions(): TStyleInputOrText {
	return {
		// fontSize: getResponsiveStyle(11, 'font'),
		marginLeft: getResponsiveStyle(11),
		lineHeight: getResponsiveStyle(16, 'font'),
		// marginBottom: getResponsiveStyle(20),
	};
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		height: 'max-content',
		alignItems: 'center',
	},
	termsAndConditions: {
		textAlign: 'center',
		letterSpacing: 0.18,
		color: palettes.grey[0],
	},
	innerContainer: {
		borderRadius: 6,
		width: 25,
		height: 25,
		borderColor: palettes.grey[4],
		borderWidth: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white',
	},
	pressableContainer: {
		borderWidth: 3,
		borderColor: 'transparent',
		height: 'max-content',
	},
});

export default Checkbox;
function styleOuterContainer(
	pressed: boolean,
	focused: boolean | undefined,
	primary: string[]
) {
	return [
		styles.pressableContainer,
		(pressed || focused) && {
			borderRadius: 8,
			borderColor: primary[1],
		},
	];
}
