import { useLazyQuery } from '@apollo/client';
import { GET_SINGLE_BRAND } from '../../graphql/brands/queries';
import { TReturnUseApiHookLazyQuery } from '../../types/exportedTypes';

type TGetSingleBrandArgs = {
	_id?: string;
	productCurrent?: number;
	productLimit?: number;
	productFind?:TODO;
};

type Params =
	| {
			onCompleted?: TOnCompleteApiCall<TGetSingleBrandResponse>;
	  }
	| undefined;

type TReturn = {
	getSingleBrand: (
		_id?: string,
		productCurrent?: number,
		productLimit?: number,
		productFind?:TODO
	) => void;
} & TReturnUseApiHookLazyQuery<TGetSingleBrandResponse, TGetSingleBrandArgs>;

export const useGetSingleBrandLazy: (params?: Params) => TReturn = params => {
	const [executeQuery, states] = useLazyQuery<
		TGetSingleBrandResponse,
		TGetSingleBrandArgs
	>(GET_SINGLE_BRAND, {
		notifyOnNetworkStatusChange:true,
		onCompleted: params?.onCompleted,
	});

	const getSingleBrand = (
		_id?: string,
		productCurrent?: number,
		productLimit?: number,
		productFind?:number
	) => {
		executeQuery({
			variables: {
				_id,
				productCurrent,
				productLimit,
				productFind
			},
		});
	};

	return { getSingleBrand, ...states };
};
