import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { LoadMore, ProfileTableControl } from '../..';
import { palettes } from '../../../config';
import { useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Loader from '../../common/loader/Loader';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import { useTranslation } from 'react-i18next';
import { usePagination } from '../../../hooks/useCurrentForPagination';
import { GET_ALL_VOUCHERs } from '../../../graphql/vouchers/queries';
import { twoDp } from '../../../utils/twoDP';

const HEADERS = [
	{
		headerLabel: 'Date',
		width: '20.0%',
	},
	{
		headerLabel: 'Brand Name',
		width: '20.0%',
	},
	{
		headerLabel: 'Brand Id',
		width: '20.0%',
	},
	{
		headerLabel: 'Amount Spent',
		width: '20.0%',
	},
	{
		headerLabel: 'Amount Left',
		width: '20.0%',
	},
];

const getFormattedDate = (dateTime: number) => {
	const date = new Date(dateTime);
	return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`;
};

const AllVouchers = () => {
	const { t } = useTranslation();
	const [tableData, setTableData] = useState<(string | number)[][]>([]);
	const [total, setTotal] = useState<number>(0);
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const LIMIT = 2;

	const [allVouchersCurrent, increaseAllVouchersCurrent] = usePagination();
	const OnPressViewMore = () => {
		const increaseCurrent = increaseAllVouchersCurrent();
		refetchVouchers({
			limit: LIMIT,
			current: increaseCurrent,
		});
	};

	useEffect(() => {
		if (typeOfAccount === 'HOST') {
			getHostVouchers({
				variables: {
					limit: LIMIT,
					current: allVouchersCurrent,
				},
			});
		}
	}, [typeOfAccount]);

	const [
		getHostVouchers,
		{
			loading: loadingGetHostVouchers,
			refetch: refetchVouchers,
			networkStatus: vouchersNetworkStatus,
		},
	] = useLazyQuery<TGetAllHostVouchersResponse>(GET_ALL_VOUCHERs, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,

		onCompleted: data => {
			if (data?.getAllVouchers?.data && data?.getAllVouchers?.success) {
				!total && setTotal(data?.getAllVouchers?.data?.total);
				const _tableData = data?.getAllVouchers?.data?.data?.map(
					({
						amountLeft,
						amountSpent,
						brandId,
						brandName,
						createdAt,
					}) => {
						return [
							getFormattedDate(parseInt(createdAt)),
							brandId.name,
							brandId._id,
							twoDp(amountSpent),
							twoDp(amountLeft),
						];
					}
				);

				setTableData([...tableData, ..._tableData]);
			}
		},
		onError: () => {},
	});
	return (
		<View style={styles.container}>
			{!!tableData && tableData.length > 0 ? (
				<>
					<ProfileTableControl
						heading={t('All Vouchers')}
						data={tableData}
						headers={HEADERS}
						type="vouchers"
					/>
					{tableData.length < total && (
						<LoadMore
							onClick={OnPressViewMore}
							loading={vouchersNetworkStatus === 2}
						/>
					)}
				</>
			) : loadingGetHostVouchers ? (
				<Loader />
			) : (
				<NotAvailableMessage message={t('No vouchers found!')} />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(24),
	},
	tabContainer: {
		flexDirection: 'row',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header: {
		fontSize: getResponsiveStyle(24),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	tableContainer: {
		marginTop: getResponsiveStyle(19),
	},
});

export default AllVouchers;

// import { View, StyleSheet } from 'react-native';
// import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
// import { palettes } from '../../../config';
// import { Loader, LoadMore, ProfileTableControl } from '../..';
// import { useLazyQuery, useQuery } from '@apollo/client';
// import { GET_ORDERS } from '../../../graphql/order/queries';
// import { manipulateString } from '../../../utils/manipulateString';
// import {usePagination } from '../../../hooks/useCurrentForPagination';
// import { useAppSelector } from '../../../redux/store';

// interface Orders {
// 	// image: string,
// 	orderId: string;
// 	purchasedOn: string;
// 	brandName: string;

// 	// baseTotal: '$943.65',
// 	purchasedTotal: string;
// 	rate: string;
// 	status: string;
// 	watch: string;

// 	// orderStatus: string,
// }

// const HEADERS = [
// 	{
// 		headerLabel: 'ORDER#',
// 		width: '10%',
// 	},
// 	{
// 		headerLabel: 'PURCHASED ON',
// 		width: '10%',
// 	},
// 	{
// 		headerLabel: 'BRAND NAME',
// 		width: '20%',
// 	},

// 	// {
// 	// 	headerLabel: 'Base Total',
// 	// 	width: '15.11%',
// 	// },
// 	{
// 		headerLabel: 'PURCHASED TOTAL',
// 		width: '10%',
// 	},
// 	{
// 		headerLabel: 'QTY',
// 		width: '7.5%',
// 	},
// 	{
// 		headerLabel: 'STATUS',
// 		width: '10%',
// 	},
// 	{
// 		headerLabel: 'WATCH',
// 		width: '10%',
// 	},
// ];

// const AllOrders = () => {
// 	const { t } = useTranslation();
// 	const LIMIT = 2;
// 	const [allOrders, setAllOrders] = useState<Orders[]>([]);
// 	const loggedInUserDetails = useAppSelector(
// 		state => state.auth.loggedInUserDetails
// 	);
// 	const [allOrdersCurrent, increaseAllOrdersCurrent] =
// 		usePagination();
// 	const OnPressViewMore = () => {
// 		const increaseCurrent = increaseAllOrdersCurrent();
// 		refetchOrders({
// 			_id: loggedInUserDetails?._id,
// 			limit: LIMIT,
// 			current: increaseCurrent,
// 		});
// 	};
// 	const [
// 		getAllUsersOrders,
// 		{ data: getOrders, loading: getLoading, refetch: refetchOrders,networkStatus:getOrderNetwork },
// 	] = useLazyQuery(GET_ORDERS, {
// 		fetchPolicy: 'no-cache',
// 		notifyOnNetworkStatusChange:true,
// 		onCompleted: data => {
// 			if (
// 				data.getAllUserOrders.data.data &&
// 				data.getAllUserOrders.data &&
// 				data.getAllUserOrders
// 			) {
// 				let vals=data.getAllUserOrders.data.data.map((item: Order) => {
// 					let qty = 0;
// 					item.items.map((product: any) => (qty += product.quantity));
// 					const date = new Date(Number(item.purchasedOn));
// 					let val: Orders = {
// 						orderId: `#${item.orderId}`,
// 						purchasedOn: `${date.getDate()}/${
// 							date.getMonth() + 1
// 						}/${date.getFullYear()}`,
// 						brandName: item.brand,

// 						purchasedTotal: item.purchasedTotalAmount,
// 						rate: 'x' + qty,
// 						status: String(item.orderStatus)
// 							.split('_')
// 							.map(x => {
// 								return manipulateString.capitalize(x);
// 							})
// 							.join(' '),
// 						watch: '',
// 					};
// 					return val
// 				});
// 				vals = vals.filter(item => {
// 					const x = allOrders.find(
// 						element => element.orderId === item.orderId
// 					);
// 					if (!x) {
// 						return item;
// 					}
// 				});
// 				setAllOrders([...allOrders,...vals]);
// 			}
// 		},
// 	});
// 	useEffect(() => {
// 		getAllUsersOrders({
// 			variables: {

// 					_id: loggedInUserDetails?._id,
// 					current:allOrdersCurrent,
// 					limit:LIMIT

// 			},
// 		});
// 	}, []);

// 	return (
// 		<>
// 			{allOrders && allOrders.length > 0? (
// 				<View style={styles.container}>
// 					<ProfileTableControl
// 						heading={t('All Orders')}
// 						data={allOrders}
// 						headers={HEADERS}
// 						type="order"
// 					/>
// 					<LoadMore onClick={OnPressViewMore} loading={getOrderNetwork===2}/>
// 				</View>
// 			):<Loader/>}
// 		</>
// 	);
// };

// const styles = StyleSheet.create({
// 	container: {
// 		marginTop: getResponsiveStyle(24),
// 	},
// 	tabContainer: {
// 		flexDirection: 'row',
// 	},
// 	headerContainer: {
// 		flexDirection: 'row',
// 		alignItems: 'center',
// 		justifyContent: 'space-between',
// 	},
// 	header: {
// 		fontSize: getResponsiveStyle(24),
// 		color: palettes.dark[0],
// 		fontWeight: 'bold',
// 	},
// 	filterContainer: {
// 		display: 'flex',
// 		flexDirection: 'row',
// 		alignItems: 'center',
// 		gap: getResponsiveStyle(20.51, 'spacing'),
// 	},
// 	tableContainer: {
// 		marginTop: getResponsiveStyle(19),
// 	},
// });

// export default AllOrders;
