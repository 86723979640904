import { currentTimeGreaterThanBuyingPeriod } from '../handleNavigationToEvent/currentTimeGreaterThanBuyingPeriod';

export const isEventDone = (
	event: TGetAllEventCommon | TSingleLiveEventData
) => {
	return (
		// !!event?.eventStreamTimeline &&
		// !!event?.eventStreamTimeline?.endedAt &&
		currentTimeGreaterThanBuyingPeriod(event?.startingEventDateTime)
	);
};
