import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Label } from '../../';
import { useCreateNewEvent } from '../../../api/events/useCreateNewEvent';
import useInviteAllFollowers from '../../../api/events/useInviteAllFollowers';
import { useUpdateNewEvent } from '../../../api/events/useUpdateNewEvent';
import useCreateStatus from '../../../api/status/useCreateStatus';
import { palettes } from '../../../config';
import { GET_ALL_USERS } from '../../../graphql/auth/queries';
import { GET_USER_FOLLOWERS } from '../../../graphql/user/queries';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import {
	appendHostsInvited,
	removeFromHostsInvited,
	setHostsInvited,
} from '../../../redux/addHostToEventSlice';
import {
	removeFromAffiliatedHosts,
	resetCreateEventSlice,
	setAffiliatedHosts,
} from '../../../redux/createEventSlice';
import { setRecommendedHost } from '../../../redux/getEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	setIsInviteAllHost,
	setIsInviteAllHostUsers,
	setIsVisitingAddHostScreenFromEventsScreen,
} from '../../../redux/uiSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Dropdown from '../../common/dropdown/Dropdown';
import EventInviteCard from '../event-invite/InviteCard';
import { useGetAllUsersLazy } from '../../../api/auth/useGetAllUsersLazy';

const EventHostForm = () => {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();

	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const {
		isVisitingAddHostScreenFromEventsScreen,
		hostsInvited,
		isInviteAllGuest,
		isInviteAllGuestUsers,
		isInviteAllHost,
		isInviteAllHostUsers,
	} = useAppSelector(state => ({
		isVisitingAddHostScreenFromEventsScreen:
			state.ui.isVisitingAddHostScreenFromEventsScreen,
		hostsInvited: state.addHostToEvent.hostsInvited,
		isInviteAllGuest: state.ui.isInviteAllGuest,
		isInviteAllGuestUsers: state.ui.isInviteAllGuestUsers,
		isInviteAllHost: state.ui.isInviteAllHost,
		isInviteAllHostUsers: state.ui.isInviteAllHostUsers,
	}));

	const { isEditEvent, recommendedHost } = useAppSelector(
		state => state.getEvent
	);

	const event = useAppSelector(state => state.getEvent.event);
	const eventState = useAppSelector(state => state.createEvent);

	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);

	const [allHostOptions, setAllHostOptions] = useState<TDropdownObject[]>();
	const [dummyAllHostOptions, setDummyAllHostOptions] =
		useState<TDropdownObject[]>();

	const [selectedHostToInvite, setSelectedHostToInvite] =
		useState<TDropdownObject>();

	const [disabledInviteAllByTypeButton, setDisableInviteByTypeAllButton] =
		useState(false);
	const [disabledInviteAllButton, setDisableInviteAllButton] =
		useState(false);

	const [allHostsResponseData, setAllHostsResponseData] = useState<
		TGetAllUsersData[]
	>([]);

	const [selectedHostsToInvite, setSelectedHostsToInvite] = useState<
		TGetAllUsersData[]
	>([]);

	const [buttonPressed, setButtonPressed] = useState<
		'skip' | 'next' | 'self-host'
	>();

	const onPressInviteUser = () => {
		if (!selectedHostToInvite) return;

		if (isVisitingAddHostScreenFromEventsScreen) {
			const completeUserObject = allHostsResponseData.find(
				hostData => hostData._id === selectedHostToInvite?.value
			);
			dispatch(
				appendHostsInvited(
					!!completeUserObject ? [completeUserObject] : []
				)
			);
		}

		dispatch(setAffiliatedHosts(selectedHostToInvite?.value ?? ''));
		// @TODO replace with find??
		setSelectedHostsToInvite(() => {
			return [
				...selectedHostsToInvite,
				...allHostsResponseData.filter(
					hostData => hostData._id === selectedHostToInvite?.value
				),
			];
		});
		allHostOptions &&
			setAllHostOptions(() => {
				return allHostOptions.filter(
					host => host.value !== selectedHostToInvite.value
				);
			});
		setSelectedHostToInvite({
			label: '',
			value: '',
		});
	};

	const onInviteHost = (id: string) => {
		const index = recommendedHost.findIndex(user => user._id === id);
		const hostUser = recommendedHost.find(user => user._id === id);
		setSelectedHostsToInvite((): any => {
			return [...selectedHostsToInvite, hostUser];
		});
		dispatch(setAffiliatedHosts(hostUser?._id ?? ''));

		allHostOptions &&
			setAllHostOptions(() => {
				return allHostOptions.filter(host => host.value !== id);
			});

		// if (index !== -1) {
		// 	const duplicateHostArr = [...recommendedHost];
		// 	duplicateHostArr.splice(index, 1);
		// 	dispatch(setRecommendedHost(duplicateHostArr));
		// }
	};

	const onRemoveUser = (id: string) => {
		const completeUserObject = allHostsResponseData.find(
			hostData => hostData._id === id
		);
		dispatch(
			removeFromHostsInvited(
				completeUserObject ?? ({} as TGetAllUsersData)
			)
		);

		dispatch(removeFromAffiliatedHosts(id));
		setSelectedHostsToInvite(prev =>
			prev.filter(person => person._id !== id)
		);

		setAllHostOptions([
			...(!!allHostOptions ? allHostOptions : []),
			{
				label: completeUserObject?.username ?? '',
				value: completeUserObject?._id ?? '',
				city: completeUserObject?.personalInformation?.address?.city,
				country:
					completeUserObject?.personalInformation?.address
						?.countryLabel,
				countryCode:
					completeUserObject?.personalInformation?.address
						?.countryCode,
				profileImage:
					completeUserObject?.personalInformation?.profileImageLink,
			},
		]);
	};

	useEffect(() => {
		if (isEditEvent && !isVisitingAddHostScreenFromEventsScreen) {
			!!event.affiliatedHosts &&
				setSelectedHostsToInvite(
					event.affiliatedHosts as unknown as TGetAllUsersData[]
				);

			setAllHostOptions(() => {
				return allHostOptions?.filter(
					host =>
						!event.affiliatedHosts
							.map(user => user._id)
							.includes(host.value)
				);
			});
		}

		return () => {
			dispatch(setIsVisitingAddHostScreenFromEventsScreen(false));
			dispatch(setHostsInvited([]));
			// setAllHostOptions(undefined);
		};
	}, [dummyAllHostOptions]);

	/******************** API ********************** */
	/******************** START - API - useGetAllUsers ********************** */

	const [getAllHosts, { loading: loadingAllHosts }] =
		useLazyQuery<TGetAllUsersResponse>(GET_ALL_USERS, {
			onCompleted: data => {
				if (
					data.getAllUsers.success &&
					data.getAllUsers.data?.data &&
					allHostOptions === undefined
				) {
					const _hosts = data.getAllUsers.data.data.map(host => ({
						label: host.username,
						value: host._id,
						city: host?.personalInformation?.address?.city,
						country:
							host?.personalInformation?.address?.countryLabel,
						countryCode:
							host?.personalInformation?.address?.countryCode,
						profileImage:
							host?.personalInformation?.profileImageLink,
					}));

					setAllHostsResponseData(data.getAllUsers.data.data);

					// add host flow
					// @TODO move setAllHostOptions in else
					if (isVisitingAddHostScreenFromEventsScreen) {
						const filteredHostOptions = _hosts.filter(host => {
							return !hostsInvited.some(
								hostInvited => hostInvited._id === host.value
							);
						});
						setAllHostOptions(filteredHostOptions);
						setDummyAllHostOptions(filteredHostOptions);
					} else {
						setAllHostOptions(_hosts);
						setDummyAllHostOptions(_hosts);
					}
				}
			},
		});
	useEffect(() => {
		setSelectedHostsToInvite(
			!!hostsInvited && hostsInvited.length > 0 ? hostsInvited : []
		);
		allHostOptions === undefined &&
			getAllHosts({
				variables: {
					find: {
						typeOfAccount: 'HOST',
						profileStatus: 'ACTIVE',
					},
				},
			});

		if (isEditEvent) {
			setSelectedHostsToInvite(
				event.affiliatedHosts as unknown as TGetAllUsersData[]
			);
		}
	}, []);

	/******************** END - API - useGetAllUsers ********************** */

	const { executeInviteAllFollowersMutation } = useInviteAllFollowers();
	const { executeCreateStatusMutation } = useCreateStatus();

	const onCompletedCreateNewEvent: TOnCompletedCreateNewEvent = data => {
		if (data.createEvent.success && data.createEvent.data) {
			dispatch(resetCreateEventSlice());
			const eventData = data.createEvent.data;
			if (isInviteAllGuest) {
				executeInviteAllFollowersMutation({
					eventId: data?.createEvent?.data?._id,
					typeOfAccount: 'CONSUMER',
				});
			}
			if (isInviteAllGuestUsers) {
				executeInviteAllFollowersMutation({
					eventId: data?.createEvent?.data?._id,
					typeOfAccount: 'CONSUMER',
					isInviteAll: true,
				});
			}
			executeCreateStatusMutation({
				status: eventData.name,
				fileType: 'EVENT',
				isEventPost: true,
				event: eventData._id,
				file: '',
			});
			if (isInviteAllHost) {
				executeInviteAllFollowersMutation({
					eventId: data?.createEvent?.data?._id,
					typeOfAccount: 'HOST',
				});
			}

			if (isInviteAllHostUsers) {
				executeInviteAllFollowersMutation({
					eventId: data?.createEvent?.data?._id,
					typeOfAccount: 'HOST',
					isInviteAll: true,
				});
			}

			setAlert(t('Event created successfully!'), 'normal');
			goToNextStepScreen();
		} else {
			setAlert(t('Event created failed!'), 'danger');
		}
	};

	const onCompletedUpdateEvent = (data: any) => {
		if (data.editEvent.success && data.editEvent.data) {
			setAlert(t('Event updated successfully!'), 'normal');
			isVisitingAddHostScreenFromEventsScreen
				? navigation.navigate('Home')
				: goToNextStepScreen();
		} else {
			setAlert(t('Event update failed!'), 'danger');
		}
	};

	const { createNewEvent, loading } = useCreateNewEvent({
		onCompleted: onCompletedCreateNewEvent,
	});

	const { updateNewEvent } = useUpdateNewEvent({
		onCompleted: onCompletedUpdateEvent,
	});

	const inviteAllGuestFollowers = ({ inviteAll }: { inviteAll: boolean }) => {
		if (inviteAll) {
			setDisableInviteAllButton(true);
			dispatch(setIsInviteAllHostUsers(true));
			getAllUsersLazy({ typeOfAccount: 'HOST', profileStatus: 'ACTIVE' });
		} else {
			setDisableInviteByTypeAllButton(true);
			dispatch(setIsInviteAllHost(true));
			executeUserByFollowers({ variables: { typeOfAccount: 'HOST' } });
		}
		setAlert(t('All Host Followers Invited'), 'normal');
		setDisableInviteAllButton(true);
	};

	// GET FOLLOWERS

	const [executeUserByFollowers] = useLazyQuery(GET_USER_FOLLOWERS, {
		fetchPolicy: 'cache-and-network',
		onCompleted: (data: any) => {
			if (
				data.getFollowers &&
				data.getFollowers.data &&
				data.getFollowers.success
			) {
				setSelectedHostsToInvite(data?.getFollowers?.data?.data);
			}
		},
	});

	const { getAllUsersLazy } = useGetAllUsersLazy({
		onCompleted: data => {
			setSelectedHostsToInvite(
				data?.getAllUsers?.data?.data as TGetAllUsersData[]
			);
		},
	});

	const selectedHostsToInviteWithoutDuplicate = selectedHostsToInvite?.filter(
		(value, index, self) =>
			index === self.findIndex(user => user._id === value._id)
	);

	return (
		<View>
			<View
				style={{
					flexDirection: 'row',
					zIndex: 5000,
					alignItems: 'flex-end',
				}}
			>
				<View style={{ flex: 1, zIndex: 5000 }}>
					<Dropdown
						labelProps={{
							label: t('Invite'),
						}}
						loading={loadingAllHosts}
						options={allHostOptions}
						setValue={setSelectedHostToInvite}
						InviteAllFollowersByType={inviteAllGuestFollowers}
						values={selectedHostToInvite}
						disabledInviteAllButton={disabledInviteAllButton}
						disabledInviteAllByTypeButton={
							disabledInviteAllByTypeButton
						}
						inputProps={{ placeholder: 'Search name / location' }}
						inviteText={t('Invite All Host Following You')}
						inviteAllText={t('Invite All Host')}
						isSearchable
						fromEventInvite
						showInviteAll
						typeOfSearch={'HOST'}
						isSearchFromBackend
					/>
				</View>
				<Button
					title={t('Invite')}
					size="lg"
					variant={
						selectedHostToInvite?.label &&
						selectedHostToInvite.value
							? 'outline'
							: 'disabled'
					}
					onPress={onPressInviteUser}
				/>
			</View>

			<View
				style={{
					marginTop: getResponsiveStyle(32),
				}}
			>
				<Label label={t('Recommended Hosts')} />
				{recommendedHost
					.filter(
						host =>
							!selectedHostsToInvite
								.map(val => val._id)
								.includes(host._id)
					)
					.slice(0, 3)
					.map((eventItem: TGetAllUsersData) => {
						return (
							<EventInviteCard
								isForRecommend
								key={eventItem._id}
								id={eventItem?._id}
								userName={eventItem?.username}
								userCountry={
									eventItem?.personalInformation?.address
										?.countryLabel
								}
								userImage={
									eventItem?.personalInformation
										?.profileImageLink
								}
								onInvite={onInviteHost}
								onRemove={onRemoveUser}
								countryCode={
									eventItem?.personalInformation?.address
										?.countryCode
								}
								city={
									eventItem?.personalInformation?.address
										?.city
								}
							/>
						);
					})}
			</View>

			{selectedHostsToInvite.length > 0 && (
				<ScrollView
					style={{
						marginTop: getResponsiveStyle(32),
						height: getResponsiveStyle(200),
						paddingLeft: getResponsiveStyle(10),
						paddingRight: getResponsiveStyle(10),
					}}
				>
					<Label label={t('Invite Send')} />
					{selectedHostsToInviteWithoutDuplicate.map(eventItem => {
						return (
							<EventInviteCard
								key={eventItem._id}
								id={eventItem?._id}
								userName={eventItem?.username}
								userCountry={
									eventItem?.personalInformation?.address
										?.countryLabel
								}
								userImage={
									eventItem?.personalInformation
										?.profileImageLink
								}
								onRemove={onRemoveUser}
								countryCode={
									eventItem?.personalInformation?.address
										?.countryCode
								}
								city={
									eventItem?.personalInformation?.address
										?.city
								}
							/>
						);
					})}
				</ScrollView>
			)}

			<Button
				title={t('Next')}
				outerContainerProps={{
					style: { marginTop: getResponsiveStyle(70) },
				}}
				size="lg"
				loading={loading && buttonPressed === 'next'}
				onPress={() => {
					setButtonPressed('next');
					isVisitingAddHostScreenFromEventsScreen
						? updateNewEvent(undefined, true)
						: isEditEvent
						? updateNewEvent()
						: createNewEvent({
								wereHostsSkipped: false,
								isHostRequired: true,
						  });
				}}
				variant={
					selectedHostsToInvite.length > 0 ? 'primary' : 'disabled'
				}
			/>
			{/* <Button
					title={t('Skip')}
					outerContainerProps={{
						style: { marginTop: getResponsiveStyle(4) },
					}}
					loading={loading}
					size="lg"
					variant={'outline'}
					onPress={() => {	
							isEditEvent
								? updateNewEvent()
								: createNewEvent({
										wereHostsSkipped: true,
										isHostRequired: true,
								  });
						
							goToNextStepScreen();
					}}
				/> */}
			{/* {!isVisitingAddHostScreenFromEventsScreen && ( */}
			<>
				<Button
					title={t('Skip')}
					size="lg"
					outerContainerProps={{
						style: { marginTop: getResponsiveStyle(8) },
					}}
					loading={loading && buttonPressed === 'skip'}
					onPress={() => {
						setButtonPressed('skip');
						isEditEvent
							? updateNewEvent(true)
							: createNewEvent({ wereHostsSkipped: true });
					}}
					variant={'outline'}
				/>
				{eventState.typeOfEvent === 'VIRTUAL'
					? !isEditEvent && (
							<Button
								title={t('Self-host')}
								size="lg"
								outerContainerProps={{
									style: {
										marginTop: getResponsiveStyle(8),
									},
								}}
								loading={
									loading && buttonPressed === 'self-host'
								}
								onPress={() => {
									setButtonPressed('self-host');
									createNewEvent({
										wereHostsSkipped: true,
										isHostRequired: false,
									});
								}}
								variant={
									selectedHostsToInvite.length > 0
										? 'disabled'
										: 'outline'
								}
							/>
					  )
					: ''}
			</>
			{/* )} */}
		</View>
	);
};

export default EventHostForm;
