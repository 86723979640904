import React from 'react';
import {
	ActivityIndicator,
	Platform,
	Pressable,
	StyleSheet,
} from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	icon: JSX.Element;
	isBgRed?: boolean;
	onPress: () => void;
	loading?: boolean;
};

export const LiveStreamActionWrapper = ({
	icon: Icon,
	onPress,
	isBgRed = false,
	loading = false,
}: Props) => {
	return (
		<Pressable
			onPress={onPress}
			style={[
				styles.container,
				{
					backgroundColor: isBgRed
						? palettes.red[4]
						: `${palettes.light[0]}10`,
				},
				Platform.OS === 'web' &&
					({
						backdropFilter: `blur(40px)`,
					} as any),
			]}
		>
			{loading ? (
				<ActivityIndicator color={palettes.light[0]} size={'small'} />
			) : (
				Icon
			)}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		borderRadius: 9999,
		alignItems: 'center',
		justifyContent: 'center',
		height: getResponsiveStyle(48, 'dimensions'),
		width: getResponsiveStyle(48, 'dimensions'),
		zIndex: 1,
	},
});
