import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LiveTVIcon = (props: SvgProps) => (
	// <Svg width={18} height={18} fill="none" viewBox="0 0 24 24" {...props}>
	// 	<Path fill="none" d="M0 0h24v24H0z" />
	// 	<Path
	// 		d="M15.414 5h5.594c.548 0 .992.445.992 1v14c0 .552-.455 1-.992 1H2.992A.994.994 0 0 1 2 20V6c0-.552.455-1 .992-1h5.594L6.05 2.464 7.464 1.05 11.414 5h1.172l3.95-3.95 1.414 1.414L15.414 5zM4 7v12h16V7H4z"
	// 		fill="rgba(255,255,255,1)"
	// 	/>
	// </Svg>

	<Svg width="18" height="12" viewBox="0 0 18 12" {...props}>
		<Path
			d="M12.75 3.9L16.6597 1.1625C16.716 1.12309 16.7819 1.09987 16.8504 1.09538C16.9189 1.09088 16.9874 1.10528 17.0483 1.137C17.1091 1.16872 17.1602 1.21654 17.1957 1.27526C17.2313 1.33398 17.2501 1.40134 17.25 1.47V10.53C17.2501 10.5987 17.2313 10.666 17.1957 10.7247C17.1602 10.7835 17.1091 10.8313 17.0483 10.863C16.9874 10.8947 16.9189 10.9091 16.8504 10.9046C16.7819 10.9001 16.716 10.8769 16.6597 10.8375L12.75 8.1V11.25C12.75 11.4489 12.671 11.6397 12.5303 11.7803C12.3897 11.921 12.1989 12 12 12H1.5C1.30109 12 1.11032 11.921 0.96967 11.7803C0.829018 11.6397 0.75 11.4489 0.75 11.25V0.75C0.75 0.551088 0.829018 0.360322 0.96967 0.21967C1.11032 0.0790178 1.30109 0 1.5 0H12C12.1989 0 12.3897 0.0790178 12.5303 0.21967C12.671 0.360322 12.75 0.551088 12.75 0.75V3.9ZM12.75 6.26925L15.75 8.36925V3.63L12.75 5.73V6.2685V6.26925ZM2.25 1.5V10.5H11.25V1.5H2.25ZM3.75 3H5.25V4.5H3.75V3Z"
			fill="white"
		/>
	</Svg>
);

export default LiveTVIcon;
