import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import { SectionHeading } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const LearnMore = styled.Text`
	color: ${palettes.grey[0]};
	font-weight: 600;
	padding-bottom: 2px;
	max-width: max-content;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[0]};
	}
`;

type Props = {
	description: TGetAllBrandsData['description'];
};

export const About = ({ description }: Props) => {
	const { t } = useTranslation();
	const [numberOfLines, setNumberOfLines] = useState<number | undefined>(
		undefined
	);
	const [requireLoadMore, setRequireLoadMore] = useState(false);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [clickedOnLoadMore, setClickedOnLoadMore] = useState(false);

	const onTextLayout = (e: LayoutChangeEvent) => {
		const lines = Math.floor(
			e.nativeEvent.layout.height / getResponsiveStyle(24)
		);
		if (lines > 3 && !clickedOnLoadMore) {
			setNumberOfLines(3);
			setRequireLoadMore(true);
		}
	};

	return (
		<View style={styleContainer(isLessThanDesktopBase)}>
			<SectionHeading heading={t('About')} size="large" />
			<Text
				style={[styles.text, styleText()]}
				onLayout={onTextLayout}
				numberOfLines={numberOfLines}
			>
				{description}
			</Text>
			{numberOfLines !== undefined && description && requireLoadMore && (
				<LearnMore
					style={styleLearnMore()}
					onPress={() => {
						setClickedOnLoadMore(true);
						setRequireLoadMore(false);
						setNumberOfLines(undefined);
					}}
				>
					{t('Learn More')}
				</LearnMore>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	text: {
		color: palettes.dark[2],
	},
});

function styleLearnMore(): TODO {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
}

function styleContainer(isLessThanDesktopBase: boolean): TStyle {
	return {
		paddingRight: getResponsiveStyle(40),
		paddingTop: getResponsiveStyle(32),
		width: isLessThanDesktopBase ? '100%' : '80%',
	};
}

function styleText() {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(28, 'font'),
		marginBottom: getResponsiveStyle(18),
	};
}
