import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Logo } from '..';
import HeroImage from '../../assets/loginscreen.png';
import { palettes } from '../../config';
import { useIsSpecificViewportWidth } from '../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../types/TStyle';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';

const LoginHeroImage = () => {
	const { isDesktop } = useIsSpecificViewportWidth();

	return isDesktop ? (
		<View style={styles.imageContainer}>
			<Image source={HeroImage} style={[styles.image]} />
			{/* <Logo
				tint="white"
				containerStyles={styleLogoContainer()}
				style={[styleLogo()]}
			/> */}
		</View>
	) : (
		<View></View>
	);
};

const styles = StyleSheet.create({
	image: {
		width: '100%',
		height: '100%',
		resizeMode: 'inherit',
	},
	imageContainer: {
		width: '55vw',
		height: '100%',
		position: 'relative',
		overflow: 'hidden',
		backgroundColor: palettes.dark[0],
	},
});

function styleImage() {
	return {
		marginTop: getResponsiveStyle(80),
		marginBottom: getResponsiveStyle(20),
	};
}

function styleLogoContainer(): TStyle {
	return {
		position: 'absolute',
		left: 18,
		top: 18,
	};
}

function styleLogo(): TStyle {
	return {
		width: getResponsiveStyle(40, 'dimensions'),
		height: getResponsiveStyle(36, 'dimensions'),
	};
}

export default LoginHeroImage;
