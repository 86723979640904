import { gql } from '@apollo/client';

export const ADDRESS_BOOK_FRAGMENT = gql`
	fragment AddressBookFragment on AddressBook {
		_id
		name
		phoneNumber
		address
		city
		zipCode
		countery
		createdBy
		default
	}
`;
