import config from '../../config';
import { twoDp } from './twoDP';

//TODO: update stripe key on deploy to live

// admin@ocpus.net
// 'pk_live_51KlikAGPsYminZ9Ggkrs7wV0O8PN21takBv7eNsGYcidKGa4L1eRnCR95jwqEnbkk6PIndMZW4GFhHMc1HobgNPq0003Ic6c4T'
export const stripe_key =
	'pk_live_51KlikAGPsYminZ9Ggkrs7wV0O8PN21takBv7eNsGYcidKGa4L1eRnCR95jwqEnbkk6PIndMZW4GFhHMc1HobgNPq0003Ic6c4T' ||
	config.STRIPE_LIVE_KEY;
type TShare = {
	revenue: number;
	quantity: number;
	sellersRevenue?: number;
};

// I multpiply these returned amounts from each brand and seller transfer by 100 because stripe makes transfer in the loswest currency unit (in this case "cent")
// so to make a trnasfer of 1euro, the amount has to be 100 (in cent)
export const sellersShare = ({ quantity, revenue }: TShare) => {
	return Number(twoDp(0.25 * revenue * quantity * 100));
};

export const sellersShareWithSelfHost = ({ quantity, revenue }: TShare) => {
	return Number(twoDp(0.25 * revenue * quantity * 100));
};

export const brandShare = ({ quantity, revenue, sellersRevenue }: TShare) => {
	const sellersMoney = Number(twoDp(0.25 * sellersRevenue * quantity));
	const ocpusMoney = Number(twoDp(0.15 * revenue * quantity));
	const brandMoney = revenue * quantity - (sellersMoney + ocpusMoney);
	return Number(twoDp(brandMoney * 100));
};

export const brandShareWithoutHostAndSeller = ({
	quantity,
	revenue,
}: TShare) => {
	return Number(twoDp(0.96 * revenue * quantity * 100));
};

export const brandShareOnVoucherPayment = (amountPaidWithCard: number) => {
	return Number(twoDp(0.85 * amountPaidWithCard));
};

export const hostShare = ({ quantity, revenue }: TShare) => {
	return twoDp(0.1 * revenue * quantity);
};
