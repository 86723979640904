import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Label, SelectButton } from '../../..';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
// import { DayOfWeek } from './DayOfWeek';

type Props = {
	onPressDay: (day: string) => void;
	selectedDay: string;
	daysOfWeek: string[];
};

const DayOfWeekSelector = ({ daysOfWeek, onPressDay, selectedDay }: Props) => {
	return (
		<View style={styles.container}>
			<Label label="Select Day" />
			<View style={styles.dayOfWeeksContainer}>
				{daysOfWeek.map(day => (
					<SelectButton
						onPressButton={onPressDay}
						text={day}
						key={day}
						selected={selectedDay === day}
					/>
				))}
			</View>
		</View>
	);
};

export default DayOfWeekSelector;

const styles = StyleSheet.create({
	container: {
		marginBottom: getResponsiveStyle(28),
	},
	dayOfWeeksContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginRight: getResponsiveStyle(-16),
	},
});
