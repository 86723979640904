import { t } from 'i18next';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ModalComponent from '../modal/ModalComponent';
import Logo from '../Logo';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import Input from '../Input';
import Button from '../button/Button';
import AsyncStorage from '@react-native-community/async-storage';

type Props = {
	onPress: (e?: string) => void;
	types?: string | string[];
	// onConfirmDelete: () => void;
	// onConfirmCheck?: () => void;
	// checked?: boolean;
	isOpen: boolean;
	loading?: boolean;
	header?: string;
	info?: string;
};

const Title = styled.Text`
	position: relative;
	font-size: ${getResponsiveStyle(18, 'font')}px;
	font-weight: 400;
	color: #616e82;
	line-height: ${getResponsiveStyle(27, 'font')}px;
	width: max-content;
	max-width: 90%;
	// margin-bottom: ${getResponsiveStyle(60, 'spacing')}px;
`;

const AnonymousChatModal = ({ ...props }: Props) => {
	// const [confirmCheck, setConfirmCheck] = useState<boolean>(false);
	const primary = usePrimaryColors();
	const [username, setUsername] = useState('');
	return (
		<ModalComponent
			type="modal"
			header={props.header as string}
			openState={props.isOpen}
			// onClose={props.onPress}
			isDeleteModal={true}
		>
			<>
				<View style={Styles.deleteModalContent}>
					<Logo
						tint={primary?.[0]}
						width={38}
						height={38}
						scale={1.3}
						viewBox="0 0 38 38"
						style={{
							marginTop: 10,
						}}
					/>
					<Title>Welcome to Ocpus</Title>
					<Input
						placeholder="User Name*"
						containerStyles={{
							width: '100%',
						}}
						value={username}
						onChangeText={text => setUsername(text)}
					/>
					<Button
						title={`Next`}
						variant={username ? 'primary' : 'disabled'}
						size="md"
						outerContainerProps={{
							style: {
								width: '100%',
							},
						}}
						onPress={() => {
							AsyncStorage.setItem('anonymousUsername', username);
							props?.onPress(username);
						}}
					/>
				</View>
			</>
		</ModalComponent>
	);
};

const Styles = StyleSheet.create({
	deleteModalContent: {
		// marginBottom: getResponsiveStyle(20),
		// paddingBottom: getResponsiveStyle(20),
		color: palettes.grey[0],
		width: '100%',
		// borderBottomColor: palettes.grey[5],
		// borderBottomWidth: 1,
		// justifyContent: "center",
		alignItems: 'center',
	},
	deleteModalActionBtn: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
});

export default AnonymousChatModal;
