import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	event: TGetAllEventCommon;
	isEditEvent: boolean;
	isEditEventFromLink: boolean;
	recommendedHost: TGetAllUsersData[];
	isAffiliatedHostEditingEvent: boolean;
	eventIds: string[];
	showLocks: boolean;
	disableLivestreamFromIVS: boolean;
	disableDailyRoomNStream: boolean;
	isHostEditingEvent: boolean;
	eventsAffiliatedWithFollowing: TGetAllEventsData[];
};

const initialState: TInitialState = {
	event: {
		_id: '',
		affiliatedBrands: [],
		affiliatedHosts: [],
		catchmentAreas: [],
		affiliatedSeller: {
			_id: '',
			personalInformation: {
				profileImageLink: '',
			},
			username: '',
		},
		createdAt: '',
		description: '',
		eventMedia: [],
		invitedPeople: [],
		isHostApplicationAccepted: true,
		isSellerApplicationOpen: true,
		name: '',
		startingEventDateTime: '',
		isApproved: false,
		isHostRequired: false,
		typeOfEvent: 'VIRTUAL',
		eventLocation: {
			address1: '',
			city: '',
			countryCode: '',
			countryLabel: '',
		},
		isStreamGenerated: false,
		eventStreamTimeline: null,
	},
	isEditEvent: false,
	isEditEventFromLink: false,
	isAffiliatedHostEditingEvent: false,
	eventIds: [],
	recommendedHost: [],
	showLocks: false,
	disableLivestreamFromIVS: false,
	disableDailyRoomNStream: false,
	isHostEditingEvent: false,
	eventsAffiliatedWithFollowing: [],
};

const eventSlice = createSlice({
	name: 'getEvent',
	initialState,
	reducers: {
		setCurrentEvent(state, action: PayloadAction<TGetAllEventCommon>) {
			state.event = action.payload;
		},
		setIsEditEvent(state, action: PayloadAction<boolean>) {
			state.isEditEvent = action.payload;
		},
		setIsEditEventFromLink(state, action: PayloadAction<boolean>) {
			state.isEditEventFromLink = action.payload;
		},
		setRecommendedHost(state, action: PayloadAction<any>) {
			state.recommendedHost = action.payload;
		},
		setIsAffiliatedHostEditingEvent(state, action: PayloadAction<boolean>) {
			state.isAffiliatedHostEditingEvent = action.payload;
		},
		setEventIds(state, action: PayloadAction<string[]>) {
			state.eventIds = action.payload;
		},
		showLocksToUnInvitedUser(state, action: PayloadAction<boolean>) {
			state.showLocks = action.payload;
		},
		disableLiveStreamFromIVS(state) {
			state.disableLivestreamFromIVS = true;
		},
		setDisableDailyRoomNStream(state) {
			state.disableDailyRoomNStream = true;
		},
		resetBeforeRunningEventNavFunc(state) {
			state.showLocks = false;
			state.disableLivestreamFromIVS = false;
			state.disableDailyRoomNStream = false;
		},
		setIsHostEditingEvent(state) {
			state.isHostEditingEvent = !state.isHostEditingEvent;
		},
		setEventsAffiliatedWithFollowing_(
			state,
			action: PayloadAction<TGetAllEventsData[]>
		) {
			state.eventsAffiliatedWithFollowing = action.payload;
		},
	},
});

export const {
	setCurrentEvent,
	setIsEditEvent,
	setIsEditEventFromLink,
	setRecommendedHost,
	setIsAffiliatedHostEditingEvent,
	setEventIds,
	showLocksToUnInvitedUser,
	disableLiveStreamFromIVS,
	setDisableDailyRoomNStream,
	resetBeforeRunningEventNavFunc,
	setIsHostEditingEvent,
	setEventsAffiliatedWithFollowing_,
} = eventSlice.actions;

export default eventSlice.reducer;
