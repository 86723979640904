import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Image,
	StyleSheet,
	Text,
	useWindowDimensions,
	View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { EventCard } from '../../..';
import { palettes } from '../../../../config';
import { GET_ALL_USERS_BY_IDS } from '../../../../graphql/auth/queries';
import { GET_SINGLE_EVENT } from '../../../../graphql/events/queries';
import { IS_SUBSCRIBED_TO_EVENTS } from '../../../../graphql/eventSubscription/queries';
import { useFonts } from '../../../../hooks/useCutomFonts';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import {
	setEventToAddHostsTo,
	setHostsInvited,
	setHostsInvitedIds,
} from '../../../../redux/addHostToEventSlice';
import {
	setCurrentEvent,
	setIsEditEvent,
} from '../../../../redux/getEventSlice';
import { setIsVisitingAddHostScreenFromEventsScreen } from '../../../../redux/uiSlice';
import { TUseNavigation } from '../../../../types/exportedTypes';
import { TStyle, TStyleImage } from '../../../../types/TStyle';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import VideoPlayBtn from '../../../chat/profile-detail/sub-component/VideoPlayBtn';
import Ratings from '../../../common/ratings/Ratings';
import ShareUpdateInput from '../../ShareUpdateInput';
import { CommentBlockFooter } from './CommentBlockFooter';
import { CommentUserPictureRow } from './CommentUserPictureRow';
type Props = {
	_id: string;
	user: TGetAllUsersData;
	file?: string;
	fileType?: string;
	status?: string;
	event?: TGetAllEventCommon;
	rating?: number;
	createdAt: string;
	inThread?: boolean;
	threadId?: string;
	commentOpen?: boolean;
	isRatingType?: boolean;
	showComment: () => void;
	showCommentBox: () => void;
	onSubmit: (args: StatusSubmit) => void;
};

const PostOrThreadComment = ({
	inThread = true,
	showComment,
	showCommentBox,
	onSubmit,
	...status
}: Props) => {
	const { width } = useWindowDimensions();
	const [play, setPlay] = useState<boolean>(false);
	const [isSubscribed, setIsSubscribed] = useState<boolean>();
	const videoRef = useRef<any>();

	const dispatch = useDispatch();

	const navigation = useNavigation<TUseNavigation>();

	const [getEventDetails, { loading: loadingGetEventDetails }] = useLazyQuery<
		TGetSingleEventResponse,
		{ _id: string }
	>(GET_SINGLE_EVENT, {});

	const [getAllInvitedHosts, { loading: loadingAllInvitedHosts }] =
		useLazyQuery<TGetUsersByIds, TGetUsersByIdsInputs>(
			GET_ALL_USERS_BY_IDS,
			{
				onCompleted: data => {
					if (
						data &&
						data.getUsersByIds &&
						data.getUsersByIds.data &&
						data.getUsersByIds.success
					) {
						dispatch(setHostsInvited(data.getUsersByIds.data.data));
					}
				},
			}
		);

	const [idOfEventToAddHost, setIdOfEventToAddHost] = useState<string>();
	const onPressAddHostButton = useCallback(async (eventId: string) => {
		setIdOfEventToAddHost(eventId);
		dispatch(setIsVisitingAddHostScreenFromEventsScreen(true));
		dispatch(setIsEditEvent(false));
		const data = await getEventDetails({
			variables: {
				_id: eventId,
			},
		});

		if (data?.data?.getEvent?.data) {
			dispatch(setEventToAddHostsTo(data.data.getEvent.data));
			const hostIds = data.data.getEvent.data.affiliatedHosts?.map(
				host => host._id
			);
			dispatch(setHostsInvitedIds(hostIds));

			if (!!hostIds && hostIds.length > 0) {
				await getAllInvitedHosts({
					variables: {
						_ids: hostIds,
					},
				});
			}
			navigation.navigate('EventHost');
			dispatch(setCurrentEvent(data.data.getEvent.data));
		}
	}, []);

	const [isSubscribedToEvent] = useLazyQuery(IS_SUBSCRIBED_TO_EVENTS, {
		onCompleted: data => {
			if (!isSubscribed) {
				setIsSubscribed(
					data?.isSubscribedToEvent?.data?.data?.[0]?.isSubscribed
				);
			}
		},
	});

	const runIsSubscribeCheck = () => {
		setTimeout(() => {
			isSubscribedToEvent({
				variables: { eventIds: [status.event?._id] },
			});
		}, 1500);
	};

	useEffect(() => {
		if (status.event?._id) {
			isSubscribedToEvent({
				variables: { eventIds: [status.event?._id] },
			});
		}
	}, []);

	const handlePlay = () => {
		setPlay(!play);
		if (!play) {
			videoRef.current.play();
		} else {
			videoRef.current.pause();
		}
	};

	const [showReplyInput, setShowReplyInput] = useState(false);

	const { isDesktop } = useIsSpecificViewportWidth();

	useEffect(() => {
		useFonts().then(() => null);
	}, []);

	return (
		<View>
			<CommentUserPictureRow {...status} />

			<View style={isDesktop && styleContentBodyContainer()}>
				{status.file && !inThread && status.fileType === 'IMAGE' && (
					<Image
						source={{ uri: status.file }}
						style={styleContentBodyImage(width)}
					/>
				)}

				{!status.file &&
					!inThread &&
					status.fileType === 'EVENT' &&
					status?.event && (
						<EventCard
							isSubscribed={isSubscribed}
							item={status.event}
							isEventPost
							runIsSubscribeCheck={runIsSubscribeCheck}
							addButtonProps={{
								onPressAddHostButton,
								loading: loadingAllInvitedHosts,
								idOfEvent: idOfEventToAddHost ?? '',
							}}
						/>
					)}

				{status.file && !inThread && status.fileType === 'VIDEO' && (
					<View
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: 10,
						}}
					>
						<video
							onPause={handlePlay}
							autoPlay={play}
							controls={play}
							className="media-video"
							src={status.file}
							ref={videoRef}
							style={{
								height: getResponsiveStyle(240),
								width: '100%',
								objectFit: 'fill',
								borderRadius: '6px',
							}}
						/>
						{!play && <VideoPlayBtn onPress={handlePlay} />}
					</View>
					// <Image
					// 	source={{ uri: status.file }}
					// 	style={styleContentBodyImage(width)}
					// />
				)}
				{status.isRatingType && (
					<View style={{ alignSelf: 'flex-start' }}>
						<Ratings ratings={status.rating as number} />
					</View>
				)}
				<Text style={styleContentBodyText(inThread, width)}>
					{status.status}
				</Text>

				{!status.isRatingType && (
					<CommentBlockFooter
						showComment={showComment}
						showReplyInput={() =>
							setShowReplyInput(!showReplyInput)
						}
						statusId={status._id}
						threadId={status.threadId}
						inThread={inThread}
						onSubmit={onSubmit}
						commentOpen={status.commentOpen}
						showCommentBox={showCommentBox}
					/>
				)}

				{showReplyInput && (
					<ShareUpdateInput
						inThread={inThread}
						user={status.user}
						commentParentId={status._id}
						isCommentInput={true}
						containerStyle={styles.inputContainer}
						onSubmit={onSubmit}
					/>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	contentBodyText: {
		color: palettes.dark[0],
		// fontFamily: 'maisonNeue',
	},
	contentBodyImage: {
		width: '100%',
		borderRadius: 6,
	},
	inputContainer: {
		// marginLeft: 70,
		marginTop: 16,
		marginBottom: 16,
	},
});

const styleContentBodyImage = (width: number): TStyleImage => {
	return [
		styles.contentBodyImage,
		{
			minHeight: getResponsiveStyle(240, 'dimensions'),
			marginBottom: getResponsiveStyle(16),
		},
	];
};

const styleContentBodyText = (inThread: boolean, width: number) => {
	return [
		styles.contentBodyText,
		{
			paddingBottom: getResponsiveStyle(inThread ? 16 : 24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(22, 'font'),
		},
	];
};

const styleContentBodyContainer = (): TStyle => {
	return {
		paddingLeft: 56 + 16,
	};
};

export default PostOrThreadComment;
