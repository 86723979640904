import { View } from 'react-native';
import React, { useEffect } from 'react';
import BankSection from './BankSection';
import CardSection from './CardSection';
import TransactionSection from './TransactionsSection';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	setDefaultCard,
	setloadingCards,
	setUserCards,
} from '../../../redux/getCardsSlice';
import { useQuery } from '@apollo/client';
import { LIST_BANKS, LIST_CARDS } from '../../../graphql/stripe/queries';
import {
	setDefaultBank,
	setloadingBanks,
	setLoadingConnectAccount,
	setUserBanks,
} from '../../../redux/bankSlice';
import { Loader } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const ProfileWallet = () => {
	const dispatch = useAppDispatch();
	const cards = useAppSelector(state => state.userCards.UserCards);
	const banks = useAppSelector(state => state.userBanks.UserBanks);
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	useEffect(() => {
		for (let i = 0; i < banks.length; i++) {
			if (banks[i].default) {
				dispatch(setDefaultBank(banks[i]));
				break;
			}
		}
		for (let i = 0; i < cards.length; i++) {
			if (cards[i].default) {
				dispatch(setDefaultCard(cards[i]));
				break;
			}
		}
	}, [cards, banks]);

	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);
	const connectAccountId_ = useAppSelector(
		state => state.userBanks.connectAccountId
	);
	const { loading: CardsLoading, data: cardsListed } = useQuery(LIST_CARDS, {
		variables: {
			args: {
				id: stripeId_,
			},
		},
		onCompleted: res => {
			dispatch(setUserCards(res?.listCards?.data?.data));
		},
	});

	const connectID = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.connectAccountInfo?.connectAccountId
	);
	const { loading: banksLoading, data: banksListed } = useQuery(LIST_BANKS, {
		variables: {
			args: {
				id: connectID,
				// id: connectAccountId_,
			},
		},
		onCompleted: res => {
			dispatch(setUserBanks(res?.listBanks?.data?.data));
		},
	});
	if (CardsLoading || banksLoading) {
		dispatch(setloadingCards(true));
		dispatch(setloadingBanks(true));
		dispatch(setLoadingConnectAccount(true));
		return (
			<>
				<View style={{ marginTop: getResponsiveStyle(24) }}></View>
				<Loader />
			</>
		);
	}

	if (cardsListed && banksListed) {
		dispatch(setloadingCards(false));
		dispatch(setloadingBanks(false));
		return (
			<View>
				{typeOfAccount === 'SELLER' && <BankSection />}
				<CardSection />
				{typeOfAccount === 'SELLER' && <TransactionSection />}
			</View>
		);
	} else {
		return null;
	}
};

export default ProfileWallet;
