const breakpoints = {
	tabletSm: 640,
	tabletLg: 768,
	desktopBase: 1024,
	desktopSm: 1280,
	desktopMd: 1440,
	desktopLg: 1920,
};

export default breakpoints;
