import React from 'react';
import { View, StyleSheet } from 'react-native';
import { CompanyCardStats } from '../..';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	containerStyles?: TStyle;
	stats: TStat[];
};

export const CompanyStats = ({ containerStyles, stats }: Props) => {
	return (
		<View style={[styles.statsContainer, containerStyles]}>
			{stats.map((stat, index, arr) => {
				const shouldAddBorderRight = arr.length - 1 !== index;
				return (
					<CompanyCardStats
						key={stat.label}
						label={stat.label}
						stat={stat.value}
						containerStyles={[
							styles.stat,
							styleStatPaddings(shouldAddBorderRight),
						]}
					/>
				);
			})}
		</View>
	);
};

function styleStatPaddings(shouldAddBorderRight: boolean): TStyle {
	return {
		paddingHorizontal: getResponsiveStyle(40),
		borderRightColor: palettes.grey[5],
		paddingBottom: 0,
		// dynamically add key value to object
		...(shouldAddBorderRight && { borderRightWidth: 1 }),
	};
}

const styles = StyleSheet.create({
	stat: {
		borderBottomWidth: 0,
	},
	middleStat: {
		borderLeftWidth: 1,
		borderRightWidth: 1,
		borderLeftColor: palettes.grey[5],
		borderRightColor: palettes.grey[5],
	},
	statsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
});
