import * as React from 'react';
import Svg, {
	SvgProps,
	Path,
	Defs,
	LinearGradient,
	Stop,
} from 'react-native-svg';

const HomeIconFilled = (props: SvgProps) => (
	<Svg fill="none" viewBox="0 0 24 24" {...props}>
		<Path
			d="M7.66 4.89c2.599-1.888 3.898-2.832 5.381-2.887.12-.004.239-.004.358 0 1.483.055 2.782.999 5.38 2.886 2.599 1.888 3.898 2.832 4.408 4.226.041.112.078.225.11.34.407 1.428-.089 2.955-1.081 6.01l-1.677 5.16a1.99 1.99 0 0 1-3.883-.615v-2.678a1.867 1.867 0 0 0-1.867-1.868H11.65a1.867 1.867 0 0 0-1.867 1.868v2.678a1.99 1.99 0 0 1-3.883.615l-1.677-5.16c-.992-3.055-1.488-4.582-1.082-6.01.033-.115.07-.228.11-.34.511-1.394 1.81-2.338 4.408-4.226Z"
			fill="url(#a)"
		/>
		<Defs>
			<LinearGradient
				id="a"
				x1={6.333}
				y1={7}
				x2={25.778}
				y2={24.778}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
		</Defs>
	</Svg>
);

export default HomeIconFilled;
