import { useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { useGetAllEvents } from '../api/events/useGetAllEvents';
import { Loader, PageDetailsHero } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ProfileAccount from '../components/profile/Profile-Account/ProfileAccount';
import ProfileAccounts from '../components/profile/Profile-Accounts/ProfileAccounts';
import AddressSection from '../components/profile/Profile-Address-Book/AddressSection';
import AddressSectionProfile from '../components/profile/Profile-Address-Book/AddressSectionProfile';
import ProfileBrands from '../components/profile/Profile-Brands/ProfileBrands';
import ProfileMembers from '../components/profile/Profile-Members/ProfileMembers';
import ProfileOrders from '../components/profile/Profile-Orders/ProfileOrders';
import ProfileStats from '../components/profile/Profile-Stats/ProfileStats';
import ProfileVouchers from '../components/profile/Profile-Vouchers/ProfileVouchers';
import ProfileWallet from '../components/profile/Profile-Wallet/ProfileWallet';
import { GET_CONNECT_ACCOUNT } from '../graphql/stripe/queries';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { setConnectAccountData, setConnectAccountId } from '../redux/bankSlice';
import { resetFilters } from '../redux/filterSlice';
import { resetSort } from '../redux/sortSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setCurrentScreen, setEditUserSidebarItems } from '../redux/uiSlice';
import { shortenName } from '../utils/manipulateString';

const ProfileScreen = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { shouldHideScreen } = useHandleUnmount();

	useEffect(() => {
		dispatch(setCurrentScreen('/profile-screen'));
	}, []);

	const stripeId = useAppSelector(state => state.getStripeId.stripeID);

	const stats = useAppSelector(state => state.user.stats);
	const STATS = [
		{
			label: t('Followers'),
			value: stats?.followersCount,
		},
		{
			label: t('Events'),
			value: stats?.eventsCount,
		},
	];
	const GUEST_STATS = [
		{
			label: t('Events'),
			value: stats?.eventsCount,
		},
	];

	const TABS = [
		{ label: t('Accounts'), value: 'Accounts' },
		{ label: t('Members'), value: 'Members' },
		{ label: t('Brands'), value: 'Brands' },
		{ label: t('Wallet'), value: 'Wallet' },
		{ label: t('Hidden Settings'), value: 'Hidden Settings' },
	];

	const SELLER_TABS = [
		{ label: t('Accounts'), value: 'Accounts' },
		{ label: t('Stats'), value: 'Stats' },
		{ label: t('Members'), value: 'Members' },
		{ label: t('Brands'), value: 'Brands' },
		{ label: t('Wallet'), value: 'Wallet' },
	];

	const HOSTS_TABS = [
		{ label: t('Accounts'), value: 'Accounts' },
		{ label: t('Members'), value: 'Members' },
		{ label: t('Brands'), value: 'Brands' },
		{ label: t('Address Book'), value: 'Address Book' },
		{ label: t('Vouchers'), value: 'Vouchers' },
		{ label: t('Orders'), value: 'Orders' },
		{ label: t('Cards'), value: 'Cards' },
	];

	const GUEST_TABS = [
		{ label: t('Accounts'), value: 'Accounts' },
		{ label: t('Members'), value: 'Members' },
		{ label: t('Brands'), value: 'Brands' },
		{ label: t('Address Book'), value: 'Address Book' },
		{ label: t('Orders'), value: 'Orders' },
		{ label: t('Cards'), value: 'Cards' },
	];

	const [selectedTab, setSelectedTab] = useState(TABS[0].value);

	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	const getTabs = (userType?: TTypeOfAccount) => {
		switch (userType?.toUpperCase()) {
			case 'HOST':
				return HOSTS_TABS;
			case 'SELLER':
				return SELLER_TABS;
			case 'CONSUMER':
				return GUEST_TABS;
			default:
				return [];
		}
	};

	const eventFindParam = useCallback(
		(userType?: TTypeOfAccount) => {
			switch (userType?.toUpperCase()) {
				case 'HOST':
					return {
						affiliatedHosts: loggedInUserDetails?._id,
						isHostApplicationAccepted: true,
					};
				case 'SELLER':
					return {
						affiliatedSeller: loggedInUserDetails?._id,
					};
				case 'CONSUMER':
					return {
						invitedPeople: loggedInUserDetails?._id,
					};
				default:
					return {};
			}
		},
		[loggedInUserDetails?.typeOfAccount]
	);

	const onPressTab = (tab: string) => {
		dispatch(resetSort());
		dispatch(resetFilters());
		setSelectedTab(tab);
	};

	useEffect(() => {
		if (loggedInUserDetails && loggedInUserDetails.typeOfAccount) {
			dispatch(
				setEditUserSidebarItems(loggedInUserDetails.typeOfAccount)
			);
		}
	}, [loggedInUserDetails]);
	useEffect(() => {
		dispatch(resetSort());
		dispatch(resetFilters());
	}, []);

	// const { loading: eventLoading, data: eventData } = useQuery(GET_ALL_EVENTS);

	const {
		executeEventsQuery,
		data: eventFindData,
		loading: eventLoading,
	} = useGetAllEvents();

	useEffect(() => {
		executeEventsQuery(
			eventFindParam(
				loggedInUserDetails?.typeOfAccount
			) as TGetAllEventFind
		);
	}, []);

	const { data: connectAccountData } = useQuery(GET_CONNECT_ACCOUNT);

	if (connectAccountData != undefined) {
		if (connectAccountData?.getConnectAccount?.data != null) {
			dispatch(
				setConnectAccountData(connectAccountData.getConnectAccount.data)
			);
			dispatch(
				setConnectAccountId(
					connectAccountData.getConnectAccount.data.connectAccountId
				)
			);
		}
	}
	const eventRecords = eventFindData?.getAllEvents?.data?.data;

	return shouldHideScreen ? null : (
		<DesktopTemplate
			hasBackButton
			navigationBarProps={{
				hasLinks: true,
				hasSearch: true,
				hasGoBack: false,
				logoLocation: 'left',
			}}
		>
			{eventLoading ? (
				<Loader />
			) : loggedInUserDetails &&
			  Object.keys(loggedInUserDetails).length > 0 ? (
				<>
					<PageDetailsHero
						_id={loggedInUserDetails._id}
						city={
							loggedInUserDetails?.personalInformation?.address
								?.city
						}
						countryCode={
							loggedInUserDetails?.personalInformation?.address
								?.countryCode
						}
						name={shortenName(loggedInUserDetails?.username)}
						key={'Profile'}
						tabs={getTabs(loggedInUserDetails.typeOfAccount)}
						onPressTab={onPressTab}
						selectedTab={selectedTab}
						profileImage={
							loggedInUserDetails?.personalInformation
								?.profileImageLink
						}
						stats={
							loggedInUserDetails.typeOfAccount === 'CONSUMER'
								? GUEST_STATS
								: STATS
						}
						isProfile={true}
						// verified={loggedInUserDetails?.isVerified}
					/>
					{selectedTab === TABS[0].value && (
						<ProfileAccounts
							events={eventRecords}
							userType={loggedInUserDetails.typeOfAccount}
						/>
					)}
					{selectedTab === 'Stats' && <ProfileStats />}
					{selectedTab === 'Members' && <ProfileMembers />}
					{selectedTab === 'Brands' && <ProfileBrands />}
					{(selectedTab === 'Wallet' || selectedTab === 'Cards') && (
						<ProfileWallet />
					)}
					{selectedTab === 'Hidden Settings' && <ProfileAccount />}
					{(selectedTab === 'Address Book' ||
						selectedTab === 'Addresses') && (
						<AddressSectionProfile />
					)}
					{selectedTab === 'Orders' && <ProfileOrders />}
					{selectedTab === 'Vouchers' && <ProfileVouchers />}
				</>
			) : (
				<Text>{t('User not found!')}</Text>
			)}
		</DesktopTemplate>
	);
};

export default ProfileScreen;
