export const findFilterVal = (filter: any, value: string) => {
	if (value?.includes('user')) {
		if (value?.includes('add')) {
			return filter?.personalInformation?.address?.[
				value?.split('add')[1]
			];
		} else return filter?.personalInformation?.favouriteCategories;
	} else if (value?.includes('brand')) {
		return filter?.address
			? filter?.address?.[value?.split('add')[1]]
			: null;
	} else if (value?.includes('event')) {
		return filter?.eventLocation
			? filter?.eventLocation?.[value?.split('add')[1]]
			: null;
	} else return filter[value];
};
