import React from 'react';
import { Pressable } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

const PasswordIcon = ({
	onPress,
	outlineColor = 'rgba(152,162,178,1)',
	...props
}: SvgProps & { outlineColor?: string }) => {
	const primary = usePrimaryColors();
	return (
		<Pressable onPress={onPress}>
			<Svg
				width="21"
				height="20"
				viewBox="0 0 21 20"
				fill="none"
				{...props}
			>
				<Path
					d="M7 12H4C2.34315 12 1 10.6569 1 9V4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V8"
					stroke={primary[0]}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<Path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M13 13.9998L12.4145 13.4143C12.1493 13.149 11.7895 13 11.4144 13C11.0393 13 10.6795 13.149 10.4143 13.4143V13.4143C9.93758 13.891 9.86359 14.6379 10.2375 15.1988L12.1775 18.1093C12.5485 18.6658 13.173 19 13.8418 19H17.6378C18.6465 19 19.4973 18.2489 19.6224 17.248L19.9843 14.3512C20.1177 13.284 19.3831 12.3024 18.3217 12.1293L16 11.7507V9.5C16 8.67157 15.3284 8 14.5 8V8C13.6715 8 13 8.67157 13 9.5V13.9998Z"
					stroke={primary[0]}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<Path
					d="M8.01045 6.46463C8.02475 6.47893 8.02903 6.50044 8.02129 6.51912C8.01355 6.53781 7.99532 6.54999 7.9751 6.54999C7.95487 6.54999 7.93664 6.53781 7.9289 6.51912C7.92116 6.50044 7.92544 6.47893 7.93974 6.46463V6.46463C7.95926 6.44514 7.99088 6.44514 8.0104 6.46463"
					stroke={primary[0]}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<Path
					d="M11.0105 6.46463C11.0248 6.47893 11.029 6.50044 11.0213 6.51912C11.0136 6.53781 10.9953 6.54999 10.9751 6.54999C10.9549 6.54999 10.9366 6.53781 10.9289 6.51912C10.9212 6.50044 10.9254 6.47893 10.9397 6.46463V6.46463C10.9593 6.44514 10.9909 6.44514 11.0104 6.46463"
					stroke={primary[0]}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<Path
					d="M5.01045 6.46463C5.02475 6.47893 5.02903 6.50044 5.02129 6.51912C5.01355 6.53781 4.99532 6.54999 4.9751 6.54999C4.95487 6.54999 4.93664 6.53781 4.9289 6.51912C4.92116 6.50044 4.92544 6.47893 4.93974 6.46463V6.46463C4.95926 6.44514 4.99088 6.44514 5.0104 6.46463"
					stroke={primary[0]}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</Svg>
		</Pressable>
	);
};

export default PasswordIcon;
