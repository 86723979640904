import { gql } from '@apollo/client';

export const FOLLOW = gql`
	mutation Follow($args: FollowInput) {
		Follow(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const UNFOLLOW = gql`
	mutation unfollow($args: UnFollowInput) {
		UnFollow(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const FOLLOW_ME = gql`
	mutation FollowMe($args: FollowMeInput) {
		FollowMe(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const UNFOLLOW_ME = gql`
	mutation UnFollowMe($args: UnFollowMeInput) {
		UnFollowMe(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const UNFOLLOW_ME_ = gql`
	mutation UnFollowMeWithoutRequest($args: UnFollowMeInput) {
		UnFollowMeWithoutRequest(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const FOLLOW_BRAND = gql`
	mutation FollowBrand($args: FollowInput) {
		FollowBrand(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const UNFOLLOW_BRAND = gql`
	mutation UnFollowBrand($args: UnFollowInput) {
		UnFollowBrand(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;
