import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ArrowIcon from '../../common/icons/ArrowIcon';
import getSymbolFromCurrency from 'currency-symbol-map';

type Props = {
	heading: string;
	value: number;
	percentage: number | string;
	percentageIncrease?: boolean;
};

const StyledChartItem = styled.View`
	border-bottom: 2px solid ${palettes.grey[5]};
	&:hover {
		border-bottom: 2px solid ${palettes.purple[0]};
	}
	&:focus {
		border-bottom: 2px solid ${palettes.purple[0]};
	}
`;
const ChartDetailItem = ({ percentageIncrease = true, ...props }: Props) => {
	return (
		<StyledChartItem style={Styles.chatDetailItemContainer}>
			<View style={Styles.chatDetailItemTextContainer}>
				<View style={Styles.chatDetailItemTextMainContainer}>
					<Text style={Styles.chatDetailItemTextMainTitle}>
						{props.heading !== "Today's orders"
							? `${getSymbolFromCurrency(
									'eur'
							  )} ${new Intl.NumberFormat('en-US').format(
									props.value
							  )}`
							: new Intl.NumberFormat('en-US').format(
									props.value
							  )}
					</Text>
					<Text style={Styles.chatDetailItemTextMainSubtitle}>
						{props.heading}
					</Text>
				</View>
				{props.percentage !== 'nil' ? (
					<View style={Styles.chartDetailIconIndicatorContainer}>
						<ArrowIcon
							color={
								percentageIncrease
									? palettes.green[0]
									: palettes.red[0]
							}
							style={{
								transform: !percentageIncrease
									? [{ rotate: '180deg' }]
									: [],
							}}
						/>

						<Text
							style={[
								Styles.chartDetailIconIndicatorText,
								{
									color: percentageIncrease
										? palettes.green[0]
										: palettes.red[0],
								},
							]}
						>
							{props.percentage}%
						</Text>
					</View>
				) : (
					<View style={Styles.chartDetailIconIndicatorContainer}>
						{/* <Text
								style={[
									Styles.chartDetailIconIndicatorText,
									{
										color: palettes.green[0]

									},
								]}
							>
								{props.percentage}
							</Text> */}
					</View>
				)}
			</View>
		</StyledChartItem>
	);
};

const Styles = StyleSheet.create({
	chatDetailItemContainer: {
		flexBasis: getResponsiveStyle(328),
		paddingTop: getResponsiveStyle(21, 'spacing'),
		paddingBottom: getResponsiveStyle(20, 'spacing'),
	},
	chatDetailItemTextContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: getResponsiveStyle(8.81, 'spacing'),
	},
	chatDetailItemTextMainContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: getResponsiveStyle(9, 'spacing'),
		color: palettes.dark[0],
		fontWeight: 'normal',
	},
	chatDetailItemTextMainTitle: {
		fontSize: getResponsiveStyle(34, 'font'),
		lineHeight: getResponsiveStyle(34, 'font'),
		color: palettes.dark[0],
	},
	chatDetailItemTextMainSubtitle: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		color: palettes.grey[0],
	},
	chartDetailIconIndicatorContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(4.22),
	},
	chartDetailIconIndicatorText: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: '400',
		color: palettes.green[0],
	},
});

export default ChartDetailItem;
