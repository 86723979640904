import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { AuthTemplate, PersonalInformationForm } from '../components';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const PersonalInfoScreen = () => {
	const { width } = useWindowDimensions();
	const signupSidebarArray = useAppSelector(
		state => state.ui.SignupSidebarArray
	);
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/personal-info'));
	}, []);

	return (
		<AuthTemplate
			heading={t('Personal Information')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<PersonalInformationForm />
		</AuthTemplate>
	);
};

const styles = StyleSheet.create({});

export default PersonalInfoScreen;
