import { useLazyQuery } from '@apollo/client';
import { GET_CONVERSATION } from '../../graphql/chat/queries';

const useGetConversation = () => {
	const [getConversation, { data, error, loading }] = useLazyQuery(
		GET_CONVERSATION,
		{
			fetchPolicy: 'no-cache',
		}
	);

	const executeConversationQuery = ({
		conversationId,
	}: {
		conversationId: string;
	}) => {
		getConversation({
			variables: {
				conversationId,
			},
		});
	};
	return { executeConversationQuery, data, error, loading };
};

export default useGetConversation;
