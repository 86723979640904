import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const MaleGenderIcon = (props: SvgProps) => (
	<Svg width={22} height={22} fill="none" viewBox="0 0 22 22">
		<Circle
			cx={11}
			cy={11}
			r={10}
			fill="#E0DAFD"
			stroke="#fff"
			strokeWidth={2}
		></Circle>
		<Path
			fill="#6645F4"
			d="M12.074 9.331l1.488-1.489h-1.93V7H15v3.368h-.842v-1.93l-1.49 1.489a3.158 3.158 0 11-.595-.596zm-1.916 4.827a2.316 2.316 0 100-4.632 2.316 2.316 0 000 4.632z"
		></Path>
	</Svg>
);

export default MaleGenderIcon;
