import { gql } from '@apollo/client';

export const CREATE_BRAND_TRANSFER = gql`
	mutation createBrandTransfer($amount: Float, $brandId: ID) {
		createBrandTransfer(amount: $amount, brandId: $brandId) {
			success
			statusCode
			message
			data {
				... on BrandEarning {
					brandId
					earnings {
						amount
						earnedDate
					}
					currency
					grossEarnings
				}
			}
		}
	}
`;

export const CREATE_BRAND_REQUEST = gql`
	mutation createBrandRequest($args: BrandRequestInputs!) {
		createBrandRequest(args: $args) {
			statusCode
			success
			data {
				__typename
				... on BrandRequest {
					name
					phoneNumber
				}
			}
		}
	}
`;
