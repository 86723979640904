import { TUseNavigation } from '../../types/exportedTypes';

export const navigateToEventDetails = (
	navigation: TUseNavigation,
	eventId: string
) => {
	navigation.navigate('EventDescription', {
		eventId,
	});
};
