import { Image, ImageProps, Pressable, View, ViewProps } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';

const DotIcon = ({ style, ...props }: ViewProps) => {
	return (
		<View
			{...props}
			style={[
				{
					width: 2,
					height: 2,
					borderRadius: 9999,
					backgroundColor: palettes.grey[3],
				},
				style,
			]}
		></View>
	);
};

export default DotIcon;
