import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, View } from 'react-native';
import { useAppSelector } from '../../../../redux/store';
import {
	TNavigationLink,
	TRootStackParamList,
	TUseNavigation,
} from '../../../../types/exportedTypes';
import { NavLink } from './NavLink';

export const NavLinks = () => {
	const route = useRoute();
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();
	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		state => state.auth
	);

	// inorder to highlight the same nav link for multiple screens
	// add the screen names to highlightForLinks array
	const NavigationLinks: TNavigationLink[] = [
		{
			label: t('Events'),
			screenName: 'Events',
			highlightForLinks: ['Events'],
		},
		{
			label: t('News Feed'),
			screenName: 'Home',
			highlightForLinks: ['Home'],
		},
		// {
		// 	label: t('Events'),
		// 	screenName: 'Events',
		// 	highlightForLinks: ['Events'],
		// },
		{
			label: t('Network'),
			screenName: 'Network',
			highlightForLinks: ['Network'],
		},
		{
			label: t('Brands'),
			screenName: 'Brands',
			highlightForLinks: ['BrandDetails', 'Brands'],
		},
	];
	const GuestNavigationLinks: TNavigationLink[] = [
		{
			label: 'Live Shopping',
			screenName: 'Events',
			highlightForLinks: ['Events'],
		},
		{
			label: t('News Feed'),
			screenName: 'Home',
			highlightForLinks: ['Home'],
		},
		// {
		// 	label: t('Events'),
		// 	screenName: 'Events',
		// 	highlightForLinks: ['Events'],
		// },
	];
	const VisitorNavigationLinks: TNavigationLink[] = [
		{
			label: 'Live Shopping',
			screenName: 'EventsAuthorized',
			highlightForLinks: ['EventsAuthorized'],
		},
	];
	const onPressLink = (screenName: keyof TRootStackParamList) => {
		navigation.navigate(screenName);
	};
	const displayIndex = !isUserLoggedIn ? 1 : 4;
	const navLinks = isUserLoggedIn
		? loggedInUserDetails?.typeOfAccount === 'CONSUMER'
			? GuestNavigationLinks
			: NavigationLinks
		: VisitorNavigationLinks;
	return (
		<View style={styles.linkContainer}>
			{navLinks.map(({ screenName, label, highlightForLinks }) => (
				<NavLink
					key={label}
					screenName={screenName}
					label={label}
					onPress={onPressLink}
					isSelected={highlightForLinks.includes(
						route.name as keyof TRootStackParamList
					)}
				/>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	linkContainer: {
		flexDirection: 'row',
		marginLeft: Dimensions.get('window').width * 0.02,
	},
});
