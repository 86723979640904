import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { TSVGIcon } from '../../../types/exportedTypes';
import { splitFloat } from '../../../utils/splitFloat';
import {
	StarEmptyIcon,
	StarFilledIcon,
	StarHalfIcon,
} from '../../common/icons';

type Props = {
	ratings: number;
	justifyContent?:
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'space-between'
		| 'space-around'
		| 'space-evenly'
		| undefined;
	containerStyles?: {};
	width?: number;
	marginRight?: number;
	paddingBottom?: number;
	height?: number;
};

const roundHalf = (num: number) => {
	return Math.round(num * 2) / 2;
};

const Ratings = ({
	ratings,
	justifyContent = 'center',
	paddingBottom = 18,
	height = 14,
	width = 14.7,
	marginRight = 5,
}: Props) => {
	const getStarsToRender: (ratings: number) => TSVGIcon[] = useCallback(
		(ratings = 0) => {
			const splitFloatArray = splitFloat(ratings, true);

			const filledStars: TSVGIcon[] = Array(splitFloatArray[0]).fill(
				<StarFilledIcon
					style={{
						height,
						width,
						marginRight,
					}}
				/>
			);
			const halfFilledStars: TSVGIcon[] = Array(splitFloatArray[1]).fill(
				<StarHalfIcon
					style={{
						height,
						width,
						marginRight,
					}}
				/>
			);

			const emptyStarsToRender =
				5 - (filledStars.length + halfFilledStars.length);

			const emptyStars: TSVGIcon[] = Array(emptyStarsToRender).fill(
				<StarEmptyIcon
					style={{
						height,
						width,
						marginRight,
					}}
				/>
			);

			const allStarsToRender = [
				...filledStars,
				...halfFilledStars,
				...emptyStars,
			];

			return allStarsToRender;
		},
		[ratings]
	);

	return (
		<View
			style={[
				styles.container,
				{
					paddingBottom,
					justifyContent,
				},
			]}
		>
			{ratings > 0 &&
				getStarsToRender(roundHalf(ratings)).map((star, index) => (
					<View key={index}>{star}</View>
				))}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	star: {
		height: 14,
		width: 14.7,
		marginRight: 5,
	},
});

export default Ratings;
