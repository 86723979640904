import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PeopleIconOutline = (props: SvgProps) => (
	<Svg viewBox="0 0 24 24" fill="none" {...props}>
		<Path
			d="M4.88 5.226a2.88 2.88 0 0 0 2.88 2.88v1a3.88 3.88 0 0 1-3.88-3.88h1Zm2.88 2.88a2.88 2.88 0 0 0 2.88-2.88h1a3.88 3.88 0 0 1-3.88 3.88v-1Zm2.88-2.88a2.88 2.88 0 0 0-2.88-2.88v-1a3.88 3.88 0 0 1 3.88 3.88h-1Zm-2.88-2.88a2.88 2.88 0 0 0-2.88 2.88h-1a3.88 3.88 0 0 1 3.88-3.88v1Zm3.38 10.14H4.38v-1h6.76v1Zm-6.76 5.76h6.76v1H4.38v-1Zm6.76 0a2.88 2.88 0 0 0 2.881-2.88h1a3.88 3.88 0 0 1-3.88 3.88v-1Zm-9.64-2.88a2.88 2.88 0 0 0 2.88 2.88v1a3.88 3.88 0 0 1-3.88-3.88h1Zm2.88-2.88a2.88 2.88 0 0 0-2.88 2.88h-1a3.88 3.88 0 0 1 3.88-3.88v1Zm6.76-1a3.88 3.88 0 0 1 3.881 3.88h-1a2.88 2.88 0 0 0-2.88-2.88v-1ZM12.547 8.198c.178-.285.511-.437.847-.437a2.535 2.535 0 0 0 0-5.07c-.336 0-.67-.153-.847-.439a17.823 17.823 0 0 0-.024-.038c-.296-.467-.146-1.128.404-1.188a4.225 4.225 0 1 1 0 8.4c-.55-.061-.7-.723-.404-1.19l.024-.038ZM15.977 17.132a.713.713 0 0 1 .602-.357h.196a2.535 2.535 0 1 0 0-5.07h-.196a.713.713 0 0 1-.602-.358c-.313-.52.026-1.333.633-1.333h.165a4.225 4.225 0 1 1 0 8.45h-.165c-.607 0-.946-.811-.633-1.332Z"
			fill="#98A2B2"
		/>
		<Path
			d="M12.547 8.198c.178-.285.511-.437.847-.437a2.535 2.535 0 0 0 0-5.07c-.336 0-.67-.153-.847-.439a17.823 17.823 0 0 0-.024-.038c-.296-.467-.146-1.128.404-1.188a4.225 4.225 0 1 1 0 8.4c-.55-.061-.7-.723-.404-1.19l.024-.038ZM15.977 17.132a.713.713 0 0 1 .602-.357h.196a2.535 2.535 0 1 0 0-5.07h-.196a.713.713 0 0 1-.602-.358c-.313-.52.026-1.333.633-1.333h.165a4.225 4.225 0 1 1 0 8.45h-.165c-.607 0-.946-.811-.633-1.332Z"
			stroke="#fff"
			strokeWidth={0.5}
		/>
	</Svg>
);

export default PeopleIconOutline;
