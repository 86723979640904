import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, ImageProps, Pressable, View, StyleSheet } from 'react-native';
import { useLogout } from '../../api/auth';
import { TUseNavigation } from '../../types/exportedTypes';

const ProfileIcon = ({
	style,
	imageSrc,
	testID,
	...props
}: {
	style: {};
	imageSrc?: string;
	testID?: string;
	// Omit<ImageProps, 'source'>
}) => {
	const navigator = useNavigation<TUseNavigation>();
	const { executeLogoutQuery } = useLogout();

	const handleNavigation = () => {
		// navigator.navigate('CreateEvent');
		executeLogoutQuery();
	};
	if (imageSrc) {
		return (
			<Image
				source={{
					uri: imageSrc ? imageSrc : testID,
					// ? testID
					// : 'http://via.placeholder.com/500',
				}}
				{...props}
				style={[style, { borderRadius: 9999 }]}
			/>
		);
	} else {
		return (
			<View style={[style, styles.imageContainer]}>
				<Image
					source={require('../../assets/defaultProfileImage.svg')}
					style={[{ width: 30, height: 30 }]}
					{...props}
				/>
			</View>
		);
	}
};

export default ProfileIcon;

const styles = StyleSheet.create({
	imageContainer: {
		borderRadius: 9999,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
