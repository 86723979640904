import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	error: string;
};

export const ErrorMessage = ({ error }: Props) => {
	return (
		<Text style={[!!error && styles.error, styleErrorMessage()]}>
			{error}
		</Text>
	);
};

function styleErrorMessage() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
	};
}

const styles = StyleSheet.create({
	error: {
		color: palettes.red[0],
		marginRight: 5,
	},
});
