import { View, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';
import { Loader, LoadMore, ProfileTableControl } from '../..';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ORDERS } from '../../../graphql/order/queries';
import { manipulateString } from '../../../utils/manipulateString';
import { usePagination } from '../../../hooks/useCurrentForPagination';
import { useAppSelector } from '../../../redux/store';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';

interface Orders {
	orderId: string;
	purchasedOn: string;
	brandName: string;
	purchasedTotal: string;
	rate: number;
	status: string;
	watch: string;
	items: any;
	deliveryFee: number;

	// orderStatus: string,
}
const AllOrders = () => {
	const { t } = useTranslation();
	const HEADERS = [
		{
			headerLabel: t('ORDER#'),
			width: '15%',
		},
		{
			headerLabel: t('PURCHASED ON'),
			width: '15%',
		},
		{
			headerLabel: t('BRAND NAME'),
			width: '20%',
		},
		{
			headerLabel: t('PURCHASED TOTAL'),
			width: '15%',
		},
		{
			headerLabel: t('QTY'),
			width: '10%',
		},
		{
			headerLabel: t('STATUS'),
			width: '20%',
		},
		{
			headerLabel: t('WATCH'),
			width: '10%',
		},
	];

	const LIMIT = 10;
	const [total, setTotal] = useState(0);
	const [allOrders, setAllOrders] = useState<Orders[]>([]);
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);
	const [allOrdersCurrent, increaseAllOrdersCurrent] = usePagination();
	const OnPressViewMore = () => {
		const increaseCurrent = increaseAllOrdersCurrent();
		refetchOrders({
			_id: loggedInUserDetails?._id,
			limit: LIMIT,
			current: increaseCurrent,
		});
	};
	const [
		getAllUsersOrders,
		{
			data: getOrders,
			loading: getLoading,
			refetch: refetchOrders,
			networkStatus: getOrderNetwork,
		},
	] = useLazyQuery(GET_ORDERS, {
		fetchPolicy: 'cache-and-network',
		notifyOnNetworkStatusChange: true,
		onCompleted: data => {
			if (
				data.getAllUserOrders.data.data &&
				data.getAllUserOrders.data &&
				data.getAllUserOrders
			) {
				setTotal(data.getAllUserOrders.data.total);
				let vals = data.getAllUserOrders.data.data.map(
					(item: Order) => {
						let qty = 0;
						item.items.map(
							(product: any) => (qty += product.quantity)
						);
						const date = new Date(Number(item.purchasedOn));
						let val: Orders = {
							orderId: `#${item.orderId}`,
							purchasedOn: item.purchasedOn,
							brandName:
								item?.brands?.length > 1
									? `${t('Multiple')}`
									: item?.brands[0]?.name,
							purchasedTotalAmount: item?.purchasedTotalAmount,
							rate: qty,
							status: String(item.orderStatus)
								.split('_')
								.map(x => {
									return manipulateString.capitalize(x);
								})
								.join(' '),
							watch: '',
							deliveryFee: item.deliveryFee,
							items: item.items,
							_id: item._id,
						};
						return val;
					}
				);
				vals = vals.filter(item => {
					const x = allOrders.find(
						element => element.orderId === item.orderId
					);
					if (!x) {
						return item;
					}
				});
				setAllOrders([...allOrders, ...vals]);
			}
		},
	});
	useEffect(() => {
		getAllUsersOrders({
			variables: {
				_id: loggedInUserDetails?._id,
				current: allOrdersCurrent,
				limit: LIMIT,
			},
		});
	}, []);

	return (
		<>
			{allOrders && allOrders.length > 0 ? (
				<View style={styles.container}>
					<ProfileTableControl
						heading={t('All Orders')}
						data={allOrders}
						headers={HEADERS}
						type="order"
					/>
					{allOrders.length < total && (
						<LoadMore
							onClick={OnPressViewMore}
							loading={getOrderNetwork === 2}
						/>
					)}
				</View>
			) : getLoading ? (
				<Loader />
			) : (
				<NotAvailableMessage message={t('No available Orders')} />
			)}
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(24),
	},
	tabContainer: {
		flexDirection: 'row',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header: {
		fontSize: getResponsiveStyle(24),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	tableContainer: {
		marginTop: getResponsiveStyle(19),
	},
});

export default AllOrders;
