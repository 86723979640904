import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import PlayButton from '../icons/PlayButton';
// import videoThumbnailUrl from 'video-thumbnail-url';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { StyleSheet } from 'react-native';
import { TStyle } from '../../../types/TStyle';

type Props = {
	buttonStyle?: TStyle;
	containerStyles?: TStyle;
	buttonHeight?: number;
	buttonWidth?: number;
	data: TMedia;
};

const Video = ({
	buttonStyle,
	containerStyles,
	data,
	buttonHeight,
	buttonWidth,
}: Props) => {
	const [playVideo, setPlayVideo] = useState(false);
	const videoRef = useRef<any>();
	const [poster, setPoster] = useState('');

	const handlePlay = () => {
		setPlayVideo(!playVideo);
		if (!playVideo) {
			videoRef.current.play();
		} else {
			videoRef.current.pause();
		}
	};

	const generateThumbnail = async () => {};

	useEffect(() => {
		generateThumbnail();
	}, []);

	return (
		<View style={[styles.videoContainer, containerStyles]}>
			{!playVideo && (
				<PlayButton
					height={getResponsiveStyle(buttonHeight || 80)}
					width={getResponsiveStyle(buttonWidth || 80)}
					containerStyles={styles.playButton}
					color="#ffffffb3"
					onPress={handlePlay}
				/>
			)}
			<video
				onPause={handlePlay}
				playsInline
				poster={data?.thumbnailSrc}
				// autoPlay={playVideo}
				// controls={play}
				style={{
					...{
						width: '100%',
						height: '100%',
						borderRadius: 6,
						objectFit: 'cover',
					},
					...(buttonStyle as Object),
				}}
				muted={false}
				src={data?.src}
				ref={videoRef}
			/>
		</View>
	);
};

export default Video;

const styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	videoContainer: {
		position: 'relative',
	},
	playButton: {
		position: 'absolute',
		left: '45%',
		top: '45%',
		zIndex: 10,
	},
	video: {
		width: '100%',
		height: '100%',
		borderRadius: 6,
	},
});
