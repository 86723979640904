import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';
import { FOLLOW, UNFOLLOW } from '../../../graphql/follow/mutations';
import { IS_FOLLOWING } from '../../../graphql/network/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import ShopIcon from '../../common/icons/Shop';
import UserFollowIcon from '../../common/icons/UserFollow';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../../types/exportedTypes';
import { setSignUpSubscribeEvent } from '../../../redux/userSlice';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import {
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';

export type LiveEventProfileCardProps = {
	username: string;
	userDescription: string;
	userProfileImageLink: string;
	userId: string;
	eventDetails?: TGetAllEventCommon;
	loadingRecording?: boolean;
	handleRecord?: () => void;
	isStreamEnded?: boolean;
};

const LiveEventProfileCard = ({
	userDescription,
	userProfileImageLink,
	username,
	userId,
	eventDetails,
	loadingRecording,
	handleRecord,
	isStreamEnded,
}: LiveEventProfileCardProps) => {
	const { t } = useTranslation();
	const [isUserFollowed, setIsUserFollowed] = useState(false);
	const navigation = useNavigation<TUseNavigation>();
	const dispatch = useAppDispatch();
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const primary = usePrimaryColors();

	/****************** START - API - follow ********************** */

	const [followAccount, { loading: loadingFollow }] = useMutation<
		TFollowResponse,
		TFollowInputs
	>(FOLLOW, {
		onCompleted: data => {
			if (data.Follow.success && data.Follow.statusCode === 200) {
				setIsUserFollowed(true);
			}
		},
	});

	/****************** End - API - follow ************************ */

	/******************** START - API - isFollowing ***********************/

	useQuery<TIsFollowingResponse, TIsFollowingInputs>(IS_FOLLOWING, {
		variables: {
			args: {
				followedIds: [userId],
				type: 'USER',
			},
		},
		onCompleted: data => {
			if (data.IsFollowing.success && data.IsFollowing.data) {
				data.IsFollowing.data.data[0].isFollowing
					? setIsUserFollowed(true)
					: setIsUserFollowed(false);
			}
		},
	});

	/******************** END  -  API - isFollowing ***********************/

	/******************** START - API - unfollow ***********************/
	const [unFollowAccount, { loading: loadingUnfollow }] = useMutation<
		TUnfollowResponse,
		TUnfollowInputs
	>(UNFOLLOW, {
		onCompleted: data => {
			if (data.UnFollow.success && data.UnFollow.statusCode === 200) {
				setIsUserFollowed(false);
			}
		},
	});

	/******************** END  -  API - unfollow ***********************/

	const followUnfollowMutationFunction = (id: string) => {
		const variables = {
			args: {
				followedId: id,
			},
		};
		isUserFollowed
			? unFollowAccount({
					variables,
			  })
			: followAccount({
					variables,
			  });
	};

	const loadingState = getSingleLoadingState(loadingFollow, loadingUnfollow);

	const handleSubToEvent = () => {
		if (!isUserLoggedIn) {
			navigation.navigate('PersonalInfo');
			dispatch(setTypeOfAccount('CONSUMER'));
			dispatch(setPrimaryColors('CONSUMER'));
			dispatch(setSignupSidebarValues('CONSUMER'));
			setSignUpSubscribeEvent(eventDetails as TGetAllEventCommon);
		}
	};

	return (
		<View>
			<View style={styles.flex1}>
				<View style={styles.userDetail}>
					{userProfileImageLink ? (
						<Image
							style={styles.userImg}
							source={{
								uri:
									userProfileImageLink ??
									require(`../../../assets/live-event/LiveEventProfile.png`),
							}}
						/>
					) : (
						<View
							style={[
								styles.imagePlaceholderOuterContainer,
								{
									borderColor: primary[0],
								},
							]}
						>
							<View style={styles.imagePlaceholderInnerContainer}>
								<Image
									source={require('../../../assets/defaultProfileImage.svg')}
									style={{ height: 30, width: 30 }}
								/>
							</View>
						</View>
					)}
					<View style={styles.user}>
						<Text style={styles.name}>{username}</Text>
						<Text style={styles.title}>{userDescription}</Text>
					</View>
				</View>
				<View style={styles.buttons}>
					{/* <Button
						title={t('Visit Shop')}
						variant={'grey-outline'}
						decoration={
							<ShopIcon
								containerStyles={{
									marginRight: getResponsiveStyle(8),
								}}
							/>
						}
					/> */}

					{typeOfAccount && typeOfAccount !== 'SELLER' && (
						<Button
							title={isUserFollowed ? t('Unfollow') : t('Follow')}
							size="sm"
							decoration={
								<UserFollowIcon
									containerStyles={{
										marginRight: getResponsiveStyle(8),
									}}
									fill={
										getSingleLoadingState(
											loadingFollow,
											loadingUnfollow
										)
											? palettes.grey[1]
											: primary[0]
									}
								/>
							}
							variant={
								getSingleLoadingState(
									loadingFollow,
									loadingUnfollow
								)
									? 'disabled'
									: 'outline'
							}
							onPress={() =>
								followUnfollowMutationFunction(userId)
							}
							loading={getSingleLoadingState(
								loadingFollow,
								loadingUnfollow
							)}
						/>
					)}
					{isStreamEnded && (
						<Button
							title={`${t('Play Recording')}`}
							variant="primary"
							loading={loadingRecording}
							onPress={handleRecord}
						/>
					)}
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	name: {
		fontSize: 24,
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	actionText: {
		fontSize: 16,
		fontStyle: 'normal',
		marginLeft: 5,
	},
	title: {
		fontSize: 16,
		fontStyle: 'normal',
		color: palettes.dark[4],
	},
	flex1: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 15,
		marginBottom: 15,
	},
	actionButton: {
		justifyContent: 'space-evenly',
		alignItems: 'center',
		flexDirection: 'row',
		width: 'max-content',
		height: 40,
		boxSizing: 'border-box',
		borderRadius: 6,
		padding: 10,
		marginLeft: 5,
	},
	imagePlaceholderOuterContainer: {
		marginRight: 15,
		borderRadius: 9999,
		height: 'max-content',
		width: 'max-content',
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: 1,
	},
	imagePlaceholderInnerContainer: {
		borderWidth: 2,
		borderColor: 'white',
		borderRadius: 9999,
		height: getResponsiveStyle(50, 'dimensions'),
		width: getResponsiveStyle(50, 'dimensions'),
		backgroundColor: palettes.grey[10],
		justifyContent: 'center',
		alignItems: 'center',
	},
	shopButton: {
		borderColor: '#506279',
		borderWidth: 1,
	},
	followButton: {
		borderWidth: 1,
	},
	buttons: {
		flexDirection: 'row',
	},
	user: { justifyContent: 'space-evenly' },
	userImg: {
		height: getResponsiveStyle(56, 'dimensions'),
		width: getResponsiveStyle(56, 'dimensions'),
		marginRight: 15,
		borderRadius: 50,
	},
	userDetail: {
		flexDirection: 'row',
	},
});

export default LiveEventProfileCard;
