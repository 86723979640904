import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { ChevronDown } from '../..';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import { Pressable as WebHoverPressable } from 'react-native-web-hover';
import { TUseNavigation } from '../../../types/exportedTypes';
type Props = {
	containerStyles?: TStyle;
	route?: string;
};

const GoBackButton = ({ containerStyles, route }: Props) => {
	const navigation = useNavigation<TUseNavigation>();
	const { t } = useTranslation();

	const onPressButton = () => {
		if (route) {
			navigation.navigate(route);
		} else {
			navigation.canGoBack()
				? navigation.goBack()
				: navigation.navigate('Home');
		}
	};

	return (
		<WebHoverPressable
			style={({ hovered }) => styleHover(containerStyles, hovered)}
		>
			{({ pressed }) => {
				pressed && onPressButton();
				return (
					<Pressable
						style={[styles.goBackContainer, styleGoBackContainer()]}
						onPress={onPressButton}
					>
						<ChevronDown
							style={styles.chevron}
							onPress={onPressButton}
						/>{' '}
						<Text style={[styles.goBackText, styleGoBackText()]}>
							{t('Back')}
						</Text>
					</Pressable>
				);
			}}
		</WebHoverPressable>
	);
};

function styleHover(containerStyles: TODO, hovered?: boolean): TStyle {
	return {
		...containerStyles,
		borderWidth: 3,
		borderRadius: 80,
		borderColor: hovered ? `${palettes.grey[3]}20` : 'transparent',
		height: 'max-content',
		width: 'max-content',
	};
}

const styles = StyleSheet.create({
	chevron: {
		transform: [
			{
				rotate: '90deg',
			},
		],
		maxWidth: 10,
		maxHeight: 15,
		marginLeft: -10,
	},
	goBackContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		maxWidth: 'max-content',
		borderRadius: 50,
		borderWidth: 1,
		borderColor: palettes.grey[3],
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 6,
		shadowColor: 'rgba(124, 136, 155, 0.06)',
		shadowOpacity: 1,
	},
	goBackText: {
		color: palettes.dark[0],
		userSelect: 'none',
	},
});

function styleGoBackText() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
}

function styleGoBackContainer() {
	return {
		paddingVertical: getResponsiveStyle(9, 'font'),
		paddingHorizontal: getResponsiveStyle(16, 'font'),
	};
}

export default GoBackButton;
