import React from 'react';
import { EventCard, LoadMore } from '../..';
import { FlatList, StyleSheet } from 'react-native';
import { AllEventsDesktopProps } from './AllEventsDesktop';

export const AllEventsMobile = ({
	eventsToRender,
	addButtonProps,
	totalEventsToRender,
	refetchLoading,
	onPressViewMore,
}: AllEventsDesktopProps) => {
	return !!eventsToRender ? (
		<>
			<FlatList
				data={eventsToRender}
				renderItem={({ item }) => (
					<EventCard
						size="large"
						containerStyles={styles.eventCardMobile}
						item={item}
						addButtonProps={addButtonProps}
					/>
				)}
			/>
			{eventsToRender?.length >= 24 &&
				eventsToRender?.length < (totalEventsToRender as number) && (
					<LoadMore
						loading={refetchLoading}
						onClick={() => onPressViewMore && onPressViewMore()}
					/>
				)}
		</>
	) : null;
};

const styles = StyleSheet.create({
	eventCardMobile: {
		// width: 'calc(100vw - 32px)',
		width: '100%',
	},
});
