import { useQuery } from '@apollo/client';
import { GET_ALL_BRANDS } from '../../graphql/brands/queries';
import { TOnErrorApiCall } from '../../types/exportedTypes';

type Params = {
	onCompleted?: TOnCompleteApiCall<TGetAllBrandsResponse>;
};

export const useGetAllBrands = ({ onCompleted }: Params) => {
	const onError: TOnErrorApiCall = error => {};

	const returnFields = useQuery<
		TGetAllBrandsResponse,
		Omit<TPaginatedOptions, 'find'>
	>(GET_ALL_BRANDS, {
		onCompleted,
		onError,
	});
	return { ...returnFields };
};
