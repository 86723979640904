import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TStripeIDSliceInitialState = {
	stripeID: string;
	clientSecret: string;
	ephemeralSecret: string;
};

const initialState: TStripeIDSliceInitialState = {
	stripeID: '',
	clientSecret: '',
	ephemeralSecret: '',
};

const stripeIdSlice = createSlice({
	name: 'stripeId',
	initialState,
	reducers: {
		setStripeID(state, action: PayloadAction<string>) {
			state.stripeID = action.payload;
		},
		setClientSecret(state, action: PayloadAction<string>) {
			state.clientSecret = action.payload;
		},
		setEphemeralSecret(state, action: PayloadAction<string>) {
			state.ephemeralSecret = action.payload;
		},
	},
});

export default stripeIdSlice.reducer;
export const { setStripeID, setClientSecret, setEphemeralSecret } =
	stripeIdSlice.actions;
