import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

type Props = {
	color: string;
} & SvgProps;

const CheckIcon = ({ color, ...props }: Props) => (
	<Svg width={24} height={24} {...props}>
		<Path
			fill={color}
			d="m10 15.172 9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
		/>
	</Svg>
);

export default CheckIcon;
