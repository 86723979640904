import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Progress } from './Progress';
import { createArray } from '../../../utils/createArray';

export const MultipleProgress = () => {
	const ToMapOverArray = createArray(3);
	return (
		<View style={styles.container}>
			{ToMapOverArray.map((item, index) => (
				<Progress
					key={index}
					isLastItem={ToMapOverArray.length - 1 === index}
				/>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
	},
});
