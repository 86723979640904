import { useLazyQuery, useQuery } from '@apollo/client';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageBackground, Pressable, Text, View } from 'react-native';
import { Button, DumbComponentForFlex, GoBackButton, Tabs } from '../..';
import { palettes } from '../../../config';
import { GET_BALANCE } from '../../../graphql/stripe/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	setStripePendingBalance,
	setWalletAmount,
	setWithdrawModalState,
} from '../../../redux/walletSlice';
import Currencies from '../../../utils/currencies';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { twoDp } from '../../../utils/twoDP';
import WithdrawForm from '../../profile/Profile-Wallet/WithdrawForm';
import DraggableScrollView from '../DraggableScrollView';
import ModalComponent from '../modal/ModalComponent';
import { CompanyProfileNameRating } from './PageProfileNameRating';
import { CompanyStats } from './PageStats';
import {
	styleCompanyDetailsContainer,
	styleCompanyStatsContainer,
	styleContainer,
	styleGoBackButton,
	stylePaddings,
	styles,
	styleTab,
	styleTabContainer,
	styleViewContainer,
} from './profileDetailsHero.styles';

type Props = {
	name: string;
	city: string;
	countryCode: string;
	profileImage?: string;
	rating?: number;
	stats: TStat[];
	tabs?: { value: string; label: string }[];
	onPressTab?: (selectedTab: string) => void;
	selectedTab?: string;
	isProfile?: boolean;
	verified?: boolean;
	showTabs?: boolean;
	isUser?: boolean;
	isUserFollowed?: boolean;
	isUserFollowingMe?: boolean;
	isFollowMeRequestSent?: boolean;
	followLoading?: boolean;
	followUser?: (followedId: string) => void;
	typeOfViewingAccount?: string;
	_id: string;
};

const BrandDetailsHero = ({
	city,
	countryCode,
	name,
	onPressTab,
	rating,
	selectedTab,
	stats,
	tabs,
	isProfile = false,
	verified = false,
	showTabs = true,
	isUserFollowed,
	isUserFollowingMe,
	isFollowMeRequestSent,
	followLoading,
	followUser,
	profileImage,
	typeOfViewingAccount,
	_id,
}: Props) => {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const walletAmount = useAppSelector(state => state.wallet.amount);
	const [amountToWithdraw, setAmountToWithdraw] = useState(0);
	const { t } = useTranslation();
	const primary = usePrimaryColors();

	const withdrawModalState = useAppSelector(
		state => state.wallet.withdrawModalState
	);

	const connectAccountId = useAppSelector(
		state => state.userBanks.connectAccountId
	);

	const country = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address
				.countryLabel
	);

	const banks = useAppSelector(state => state.userBanks.UserBanks);

	const connectID = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.connectAccountInfo?.connectAccountId
	);

	const stripePendingBalance = useAppSelector(
		state => state.wallet.stripePendingBalance
	);
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);

	const pendingIncome = useAppSelector(state => state.wallet.pendingIncome);

	const changeWithdrawalModalState = () => {
		dispatch(setWithdrawModalState());
	};

	const currency = Currencies[country as keyof typeof Currencies];

	const [getConnectBalance] = useLazyQuery(GET_BALANCE, {
		variables: {
			args: {
				accountId: connectAccountId,
			},
		},
		onCompleted: data => {
			const amount = data?.getBalance?.data?.available?.[0]?.amount;

			const pendingAmount = data?.getBalance?.data?.pending?.[0]?.amount;
			dispatch(setWalletAmount(amount));
			dispatch(setStripePendingBalance(pendingAmount));
		},
	});

	useEffect(() => {
		connectAccountId && getConnectBalance();
	}, [connectAccountId]);

	return (
		<ImageBackground
			style={[styles.container, styleContainer(width)]}
			source={require('../../../assets//images/page-hero-background.png')}
		>
			<View style={stylePaddings(width)}>
				{!isProfile && (
					<GoBackButton containerStyles={styleGoBackButton()} />
				)}
				<View style={[styleCompanyDetailsContainer()]}>
					<CompanyProfileNameRating
						companyName={name}
						city={city}
						countryCode={countryCode}
						rating={rating || 0}
						isProfile={isProfile}
						verified={verified}
						profileImage={profileImage}
					/>

					<DumbComponentForFlex flex={0.81} />
					{!isLessThanDesktopBase && <CompanyStats stats={stats} />}
					<DumbComponentForFlex flex={1} />

					{!isProfile ? (
						typeOfAccount === 'CONSUMER' &&
						typeOfViewingAccount === 'CONSUMER' ? (
							''
						) : (
							<Button
								title={
									typeOfViewingAccount === 'CONSUMER'
										? !isFollowMeRequestSent &&
										  !isUserFollowingMe
											? t('Follow Me')
											: isFollowMeRequestSent &&
											  !isUserFollowingMe
											? t('Request sent')
											: t('Unfollow Me')
										: isUserFollowed
										? t('Unfollow')
										: t('Follow')
								}
								variant={
									(followLoading ? 'disabled' : 'outline') &&
									(typeOfViewingAccount === 'CONSUMER' &&
									isFollowMeRequestSent &&
									!isUserFollowingMe
										? 'disabled'
										: 'outline')
								}
								size="sm"
								onPress={() =>
									followUser && followUser(_id ?? '')
								}
								loading={followLoading}
							/>
						)
					) : (
						<View style={styles.rightContainer}>
							{!isLessThanDesktopBase &&
							connectID &&
							typeOfAccount === 'SELLER' ? (
								<View style={styles.walletContainer}>
									<View
										style={{
											display: 'flex',
											flexDirection: 'row',
											gap: 20,
										}}
									>
										<View>
											<Text
												style={[
													{ color: palettes.red[0] },
													styles.walletValue,
												]}
											>
												{getSymbolFromCurrency(
													currency
												)}
												{twoDp(
													stripePendingBalance / 100
												)}
											</Text>
											<Text style={{ fontSize: 14 }}>
												Pending
											</Text>
										</View>

										<View>
											<Text
												style={[
													{ color: 'green' },
													styles.walletValue,
												]}
											>
												{getSymbolFromCurrency(
													currency
												)}
												{twoDp(walletAmount / 100)}
											</Text>
											<Text style={{ fontSize: 14 }}>
												Available
											</Text>
										</View>
									</View>
									<Text style={styles.walletText}>
										{t('My Wallet')}
									</Text>
								</View>
							) : (
								!isLessThanDesktopBase &&
								!connectID &&
								typeOfAccount === 'SELLER' && (
									<View style={styles.walletContainer}>
										<View>
											<Text style={styles.walletValue}>
												{getSymbolFromCurrency('eur')}
												{twoDp(pendingIncome / 100)}
												{/* {getSymbolFromCurrency(currency)} */}
											</Text>
										</View>
										<Text style={styles.walletText}>
											{t('My Wallet')}
										</Text>
									</View>
								)
							)}
							<View
								style={{
									flexDirection: 'row',
								}}
							>
								{isProfile && (
									<Button
										title={t('Edit')}
										variant="grey"
										size="sm"
										onPress={
											onPressTab
												? () =>
														onPressTab(
															'Hidden Settings'
														)
												: () => null
										}
									/>
								)}
								{!isLessThanDesktopBase &&
									selectedTab === 'Wallet' && (
										<Button
											title={t('Withdraw')}
											variant="outline"
											size="sm"
											onPress={() => {
												banks.length
													? changeWithdrawalModalState()
													: setAlert(
															t(
																'You do not have any bank account saved'
															),
															'danger'
													  );
											}}
										/>
									)}
							</View>
						</View>
					)}
				</View>
				{isLessThanDesktopBase && (
					<>
						<CompanyStats
							stats={stats}
							containerStyles={styleCompanyStatsContainer()}
						/>
						{selectedTab === 'Wallet' && (
							<View
								style={{
									marginBottom: getResponsiveStyle(38),
									flexDirection: 'row',
								}}
							>
								<Button
									title={t('Withdraw')}
									variant="outline"
									outerContainerProps={{
										style: styles.walletButtons,
									}}
									onPress={changeWithdrawalModalState}
								/>
							</View>
						)}
					</>
				)}
			</View>
			{showTabs &&
				(!isLessThanDesktopBase && tabs && onPressTab && selectedTab ? (
					<Tabs
						tabs={tabs}
						onPress={onPressTab}
						selectedTab={selectedTab}
						containerStyle={[
							styles.tabContainer,
							styleTabContainer(),
						]}
					/>
				) : (
					<DraggableScrollView
						data={tabs}
						style={styleViewContainer()}
						showsHorizontalScrollIndicator={false}
						renderItem={({ item, index }: TODO) => {
							return !item?.value.includes('Hidden') ? (
								<Pressable
									key={index}
									onPress={
										onPressTab
											? () => onPressTab(item.value)
											: () => null
									}
								>
									<Text
										style={styleTab(
											primary,
											selectedTab,
											item
										)}
									>
										{item.label}
									</Text>
								</Pressable>
							) : (
								<></>
							);
						}}
					/>
				))}
			<ModalComponent
				type="sidebar"
				header={`${t('Withdraw Amount')}`}
				subtitle={
					<View style={{ display: 'flex' }}>
						<View>
							{t('Available Balance')} : {'   '}{' '}
							{getSymbolFromCurrency('eur')}
							{Number(twoDp(walletAmount / 100)) -
								amountToWithdraw}
						</View>
						<View style={{ marginTop: '5px' }}>
							{t('Pending Balance')} : {'   '}
							{getSymbolFromCurrency('eur')}
							{twoDp(stripePendingBalance / 100)}
						</View>
					</View>
				}
				openState={withdrawModalState}
				onClose={changeWithdrawalModalState}
				onWithdraw={true}
			>
				<WithdrawForm
					onSubmit={() => {}}
					amountToWithdraw={amountToWithdraw}
					setAmountToWithdraw={setAmountToWithdraw}
				/>
			</ModalComponent>
		</ImageBackground>
	);
};

export default BrandDetailsHero;
