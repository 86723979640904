import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions, View } from 'react-native';
import { AuthTemplate, Button, SellerAgreementForm } from '../components';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { TUseNavigation } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const SellerAgreementScreen = () => {
	const { width } = useWindowDimensions();
	const navigation = useNavigation<TUseNavigation>();
	const signupSidebarArray = useAppSelector(
		state => state.ui.SignupSidebarArray
	);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setCurrentScreen('/seller-agreement'));
	}, []);
	return (
		<AuthTemplate
			heading={t('Seller Agreement')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<SellerAgreementForm />
			{/* <Button
				title={t('Skip')}
				outerContainerProps={{
					style: { marginTop: getResponsiveStyle(8) },
				}}
				variant="outline"
				size="lg"
				onPress={() => navigation.navigate('YourInformation')}
			/> */}
		</AuthTemplate>
	);
};

export default SellerAgreementScreen;
