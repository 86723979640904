import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, useWindowDimensions, View } from 'react-native';
// import useGetAppSettings from '../api/appSettings/useGetAppSettings';
import {
	Button,
	CommentBlock,
	DraggableScrollView,
	Loader,
	ProfileList,
	ProfileStatsCard,
	ScrollEventSection,
	ShareAnUpdate,
	SidebarEventList,
} from '../components';
import EventSmallCardLoader from '../components/common/skeleton-loaders/event-loaders/EventSmallCardLoader';
import StatusSkeleton from '../components/common/skeleton-loaders/status-loader/StatusSkeleton';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ShareUpdateInput from '../components/home/ShareUpdateInput';
import { breakpoints, palettes } from '../config';
import { TStyle } from '../types/TStyle';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useHomeScreen } from '../hooks/useHomeScreen';
import { getQueryFieldForUser } from './EventsScreen';

const HomeScreen = () => {
	const {
		shouldHideScreen,
		isLessThanDesktopBase,
		loggedInUserDetails,
		upcomingEvents,
		upcomingEventsLoading,
		publishedEvents,
		publishedEventsLoading,
		upcomingConsumerPlannedEvents,
		upcomingPlannedEventsForConsumersLoading,
		consumerPendingEvents,
		consumerPendingEventsLoading,
		userId,
		eventsAffiliatedWithFollowingLoading,
		eventsAffiliatedWithFollowing,
		eventsAffiliatedWithFollowingGlobal,
		addHostLoadingState,
		idOfEventToAddHost,
		isSubscribedArray,
		statusPost,
		loading_status,
		showLoadMore,
		skipDoc,
		suggestedBrandsData,
		suggestedBrandsLoading,
		isFollowingArray,
		suggestedHostsData,
		suggestedHostsLoading,
		suggestedSellersData,
		suggestedSellersLoading,
		isFollowingBrandArray,
		suggestedConsumersData,
		suggestedConsumersLoading,
		followMeUnfollowMeButtonPressedId,
		loadingFollowMe,
		loadingUnfollowme,
		requestSendBooleanArray,
		isFollowingMeArray,
		getAffiliatedEventHeadings,
		setSkipDoc,
		onClickFollowMe,
		onPressSeeAllForSidebarBrands,
		onPressSeeAllForSidebarUsers,
		onEndReached,
		onPressAddHostButton,
		setStatusUpdate,
		executeFollowingStatusQuery,
		onPressViewAllForSidebarEvents,
	} = useHomeScreen({
		getQueryFieldForUser,
	});

	const { width } = useWindowDimensions();
	const { t } = useTranslation();

	const renderItem = (status: any) => {
		return <CommentBlock {...status.item} />;
	};

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			{/* {loadingState ? (
				<Loader />
			) : ( */}
			<View style={styleContainer(isLessThanDesktopBase)}>
				<View style={styleLeftSidebar(isLessThanDesktopBase)}>
					{!isLessThanDesktopBase && <ProfileStatsCard />}
					{loggedInUserDetails?.typeOfAccount !== 'CONSUMER' && (
						<>
							<SidebarEventList
								heading={t('Upcoming Events')}
								itemsToRender={3}
								events={upcomingEvents}
								loading={upcomingEventsLoading}
								canClick={true}
								onPressViewAll={onPressViewAllForSidebarEvents}
							/>
							<SidebarEventList
								heading={t('Published Events')}
								itemsToRender={4}
								events={publishedEvents}
								loading={publishedEventsLoading}
								canClick={true}
								onPressViewAll={onPressViewAllForSidebarEvents}
							/>
						</>
					)}
					{loggedInUserDetails?.typeOfAccount === 'CONSUMER' && (
						<>
							<SidebarEventList
								heading={t('Upcoming Planned Events')}
								itemsToRender={3}
								events={upcomingConsumerPlannedEvents}
								loading={
									upcomingPlannedEventsForConsumersLoading
								}
								canClick={true}
								onPressViewAll={onPressViewAllForSidebarEvents}
							/>
							<SidebarEventList
								heading={t('Events Pending')}
								isGreyedOut
								itemsToRender={4}
								events={consumerPendingEvents}
								loading={consumerPendingEventsLoading}
								canClick={true}
								onPressViewAll={onPressViewAllForSidebarEvents}
							/>
						</>
					)}
				</View>
				<View style={styleMainContent(isLessThanDesktopBase)}>
					<ShareAnUpdate />
					<ShareUpdateInput
						onSubmit={() => {
							executeFollowingStatusQuery({ skipDoc: 0 });
							setStatusUpdate(true);
						}}
						hasUpload
					/>
					{!userId ? (
						<Loader
							size="small"
							containerStyles={{
								margin: getResponsiveStyle(20),
							}}
						/>
					) : eventsAffiliatedWithFollowingLoading &&
					  !eventsAffiliatedWithFollowing.length ? (
						<DraggableScrollView
							horizontal
							showsHorizontalScrollIndicator={false}
							style={styleDraggableScroll(isLessThanDesktopBase)}
							data={[1, 2, 3]}
							//@ts-ignore
							renderItem={({
								item,
								index,
							}: {
								item: TGetAllEventCommon[];
								index: number;
							}) => (
								<EventSmallCardLoader
									size="small"
									containerStyles={styleEventCardContainer(
										isLessThanDesktopBase,
										item,
										1
									)}
								/>
							)}
						/>
					) : (
						!!eventsAffiliatedWithFollowing && (
							<ScrollEventSection
								hasViewAllLabel
								heading={
									getAffiliatedEventHeadings(
										loggedInUserDetails?.typeOfAccount
									) ?? ''
								}
								containerStyle={styleStyleSectionContainer()}
								events={
									eventsAffiliatedWithFollowingGlobal ??
									eventsAffiliatedWithFollowing
								}
								onPressViewAll={onPressViewAllForSidebarEvents}
								addButtonProps={{
									onPressAddHostButton,
									loading: addHostLoadingState,
									idOfEvent: idOfEventToAddHost ?? '',
								}}
								isSubscribedArray={isSubscribedArray}
								onEndReached={onEndReached}
								hasScrollbar={true}
							/>
						)
					)}

					<FlatList
						data={statusPost}
						keyExtractor={(item: any) => item?._id}
						renderItem={renderItem}
					/>
					{
						loading_status &&
							[...Array(5).keys()].map(_ => <StatusSkeleton />)
						// <Loader
						// 	size="small"
						// 	containerStyles={{
						// 		margin: getResponsiveStyle(20),
						// 	}}
						// />
					}
					{showLoadMore && (
						<Button
							title={t('Load More')}
							onPress={() => setSkipDoc(skipDoc + 1)}
						/>
					)}
				</View>
				{!isLessThanDesktopBase && (
					<View style={styleRightSidebar(width)}>
						{loggedInUserDetails?.typeOfAccount === 'CONSUMER' && (
							<ProfileList
								heading={t('Brands selected for you')}
								isBrandProfile
								hasAddIcon
								items={suggestedBrandsData}
								loading={suggestedBrandsLoading}
								section="Brand"
								onPressSeeAll={onPressSeeAllForSidebarBrands}
								isFollowingArray={isFollowingBrandArray}
								onClickUnFollowActionButton={
									id => null
									// removeUserStatusFromState(id)
								}
							/>
						)}
						{loggedInUserDetails?.typeOfAccount !== 'HOST' && (
							<ProfileList
								heading={t('Hosts selected for you')}
								hasAddIcon
								items={suggestedHostsData}
								loading={suggestedHostsLoading}
								section="Host"
								onPressSeeAll={onPressSeeAllForSidebarUsers}
								isFollowingArray={isFollowingArray}
								onClickUnFollowActionButton={
									id => null // removeUserStatusFromState(id)
								}
							/>
						)}
						{loggedInUserDetails?.typeOfAccount !== 'SELLER' && (
							<ProfileList
								heading={t('Sellers selected for you')}
								hasAddIcon
								items={suggestedSellersData}
								loading={suggestedSellersLoading}
								section="Host"
								onPressSeeAll={onPressSeeAllForSidebarUsers}
								isFollowingArray={isFollowingArray}
								onClickUnFollowActionButton={
									id => null // removeUserStatusFromState(id)
								}
							/>
						)}
						{loggedInUserDetails?.typeOfAccount === 'SELLER' && (
							<ProfileList
								heading={t('Brands selected for you')}
								isBrandProfile
								hasAddIcon
								items={suggestedBrandsData}
								loading={suggestedBrandsLoading}
								section="Brand"
								onPressSeeAll={onPressSeeAllForSidebarBrands}
								isFollowingArray={isFollowingBrandArray}
								onClickUnFollowActionButton={
									id => null // removeUserStatusFromState(id)
								}
							/>
						)}
						{loggedInUserDetails?.typeOfAccount === 'HOST' &&
							!!suggestedConsumersData && (
								<ProfileList
									heading={t('Guests selected for you')}
									hasAddIcon
									items={suggestedConsumersData}
									loading={suggestedConsumersLoading}
									section="Brand"
									onPressSeeAll={
										onPressSeeAllForSidebarBrands
									}
									isFollowingArray={isFollowingArray}
									followMeUnfollowMeButtonPressedId={
										followMeUnfollowMeButtonPressedId
									}
									onClickFollowMeActionButton={
										onClickFollowMe
									}
									loadingFollowMe={loadingFollowMe}
									loadingUnFollowMe={loadingUnfollowme}
									// setSelected={setSelectednow}
									isFollowingMeArray={isFollowingMeArray}
									requestSendBooleanArray={
										requestSendBooleanArray
									}
									onClickUnFollowActionButton={
										id => null // removeUserStatusFromState(id)
									}
								/>
							)}
					</View>
				)}
			</View>
			{/* )} */}
		</DesktopTemplate>
	);
};

const styles = StyleSheet.create({
	leftSidebar: {
		height: '100%',
		borderRightColor: palettes.grey[5],
	},
	mainContent: {
		flex: 1,
	},
	rightSidebar: {
		width: '27%',
		height: '100%',
		minHeight: '100vh',
		borderLeftColor: palettes.grey[5],
		borderLeftWidth: 1,
		backgroundColor: 'white',
		maxWidth: 400,
	},
	eventRow: {
		flexDirection: 'row',
		maxWidth: 'max-content',
	},
});

const styleContainer = (isLessThanDesktopBase: boolean): TStyle => {
	return {
		flexDirection: isLessThanDesktopBase ? 'column' : 'row',
	};
};

const styleLeftSidebar = (isLessThanDesktopBase: boolean): TStyle => {
	return [
		styles.leftSidebar,
		{
			paddingRight: isLessThanDesktopBase ? 0 : getResponsiveStyle(32),
			width: isLessThanDesktopBase ? '100%' : '18%',
			height: 'auto',
			borderRightWidth: isLessThanDesktopBase ? 0 : 1,
		},
	];
};

const styleMainContent = (isLessThanDesktopBase: boolean): TStyle => {
	return [
		styles.mainContent,
		{
			paddingHorizontal: isLessThanDesktopBase
				? 0
				: getResponsiveStyle(32),
			paddingTop: getResponsiveStyle(20),
			paddingBottom: getResponsiveStyle(100),
		},
	];
};

const styleRightSidebar = (width: number): TStyle => {
	return [
		styles.rightSidebar,
		{
			paddingLeft: getResponsiveStyle(32),
			paddingRight: width >= breakpoints.desktopBase ? 0 : 16,
		},
	];
};

const styleStyleSectionContainer = (): TStyle => {
	return {
		marginBottom: getResponsiveStyle(33),
		marginTop: getResponsiveStyle(12),
	};
};

const styleDraggableScroll = (isLessThanDesktopBase: boolean): TStyle => {
	return [
		styles.eventRow,
		{
			width: `calc(100% + ${
				isLessThanDesktopBase
					? getResponsiveStyle(16)
					: getResponsiveStyle(32)
			}px)`,
		},
	];
};

const styleEventCardContainer = (
	isLessThanDesktopBase: boolean,
	ToRender: TGetAllEventsData[],
	index: number
): TStyle => {
	return {
		width: getResponsiveStyle(320, 'dimensions'),
		marginRight:
			isLessThanDesktopBase || ToRender.length - 1 !== index
				? getResponsiveStyle(16)
				: getResponsiveStyle(32),
		marginBottom: getResponsiveStyle(8),
		maxWidth: '100%',
	} as TStyle;
};

export default HomeScreen;
