import { gql } from '@apollo/client';

const CREATE_NEW_EVENT = gql`
	mutation createNewEvent($args: CreateEventInputs!) {
		createEvent(args: $args) {
			statusCode
			success
			message
			data {
				... on Event {
					_id
					name
					description
					typeOfEvent
					startingEventDateTime
				}
			}
		}
	}
`;

const INVITE_ALL_FOLLOWERS = gql`
	mutation inviteAllFollowers(
		$eventId: ID!
		$typeOfAccount: TypeOfAccount!
		$invitedUsersWithoutDuplicate: [String]!
		$isInviteAll: Boolean
	) {
		inviteAllFollowers(
			eventId: $eventId
			typeOfAccount: $typeOfAccount
			invitedUsersWithoutDuplicate: $invitedUsersWithoutDuplicate
			isInviteAll: $isInviteAll
		) {
			statusCode
			success
			message
		}
	}
`;

const UPDATE_EVENT = gql`
	mutation editEvent($_id: ID!, $args: EditEventInputs!) {
		editEvent(_id: $_id, args: $args) {
			statusCode
			success
			message
			data {
				... on Event {
					_id
					name
					description
					typeOfEvent
					startingEventDateTime
				}
			}
		}
	}
`;

const DELETE_EVENT = gql`
	mutation deleteEvent($_id: ID!) {
		deleteEvent(_id: $_id) {
			statusCode
			success
			message
		}
	}
`;

const START_LIVE_EVENT = gql`
	mutation startLiveEvent($_id: ID!) {
		startLiveEvent(_id: $_id) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

const EDIT_EVENT_STATUS = gql`
	mutation editEventStatus($_id: ID!, $status: StatusOfEvent!) {
		editEventStatus(_id: $_id, status: $status) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

const EDIT_EVENT_STREAM_TIMELINE = gql`
	mutation editEventStreamTimeline(
		$_id: ID!
		$startedAt: String
		$endedAt: String
		$deviceType: String
		$streamRecordingInstance: String
		$os: String
	) {
		editEventStreamTimeline(
			_id: $_id
			startedAt: $startedAt
			endedAt: $endedAt
			deviceMetadata: {
				deviceType: $deviceType
				os: $os
				streamRecordingInstance: $streamRecordingInstance
			}
		) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export {
	CREATE_NEW_EVENT,
	UPDATE_EVENT,
	DELETE_EVENT,
	START_LIVE_EVENT,
	EDIT_EVENT_STATUS,
	EDIT_EVENT_STREAM_TIMELINE,
	INVITE_ALL_FOLLOWERS,
};
