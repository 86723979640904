import { useMutation } from '@apollo/client';
import { CREATE_LIKE } from '../../graphql/likes/mutation';

const useCreateLike = () => {
	const [createLike, states] = useMutation(CREATE_LIKE);

	const executeCreateLikeMutation = ({
		likeType,
		parentId,
	}: {
		likeType: string;
		parentId: string;
	}) => {
		createLike({
			variables: {
				likeType,
				parentId,
			},
		});
	};
	return { executeCreateLikeMutation, ...states };
};

export default useCreateLike;
