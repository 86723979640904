import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_ANONYMOUS_USER_MUTATION } from '../../graphql/auth/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { useAppSelector } from '../../redux/store';

type Props = {
	onCompleted?: (data: TCreateAnonymousUserResponse) => void | undefined;
	_onError?: (error: ApolloError) => void | undefined;
};

const useCreateAnonymousUser = (props: Props) => {
	const authState = useAppSelector(state => state.createUser);
	const { setAlert } = useSetAlert();

	const onError = (error: ApolloError) => {
		if (error) {
			setAlert(error.message, 'danger');
		}
	};

	const [_createAnonymousUser, { data, error, loading }] =
		useMutation<TCreateAnonymousUserResponse>(
			CREATE_ANONYMOUS_USER_MUTATION,
			{
				onCompleted: props?.onCompleted,
				onError: error => {
					props?._onError === undefined
						? onError(error)
						: props?._onError(error);
				},
			}
		);
	const createAnonymousUser = ({
		anonymousUserData,
	}: {
		anonymousUserData: TAnonymousUserInput;
	}) => {
		_createAnonymousUser({
			variables: {
				args: anonymousUserData,
			},
		});
	};
	return { createAnonymousUser, data, error, loading };
};

export default useCreateAnonymousUser;
