import React from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import UserImagePlaceholder from '../user-image-placeholder/UserImagePlaceholder';

type Props = {
	onPressContainer: () => void;
	profileImage: string;
	name: string;
	address: string;
	isLastElement: boolean;
};

export const SearchCard = ({
	address,
	name,
	onPressContainer,
	profileImage,
	isLastElement,
}: Props) => {
	return (
		<Pressable
			onPress={onPressContainer}
			style={[
				styles.container,
				{
					paddingVertical: isLastElement ? 0 : getResponsiveStyle(8),
					marginTop: isLastElement ? getResponsiveStyle(8) : 0,
				},
			]}
		>
			<View style={[styles.image, styleImage(), styles.imageContainer]}>
				{!!profileImage ? (
					<Image
						source={{ uri: profileImage }}
						style={styles.profileImage}
					/>
				) : (
					<UserImagePlaceholder />
				)}
			</View>
			<View style={styles.rightColumnContainer}>
				<Text style={[styles.companyName, styleCompanyName()]}>
					{name}
				</Text>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<Text
						style={[styles.companyLocation, styleCompanyLocation()]}
					>
						{address}
					</Text>
				</View>
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	rightColumnContainer: {
		marginTop: getResponsiveStyle(8),
	},
	profileImage: {
		width: getResponsiveStyle(48, 'dimensions'),
		height: getResponsiveStyle(48, 'dimensions'),
		borderRadius: 9999,
	},
	container: {
		flexDirection: 'row',
		paddingHorizontal: getResponsiveStyle(18),
	},
	imageContainer: {
		borderRadius: 9999,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: palettes.grey[10],
	},
	ratings: {
		padding: 0,
		alignSelf: 'flex-start',
	},
	companyLocation: {
		color: palettes.grey[0],
	},
	companyName: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
	image: {
		borderRadius: 9999,
	},
	verifiedText: {
		fontSize: 12,
		fontWeight: '700',
		color: palettes.purple[0],
	},
});

function styleCompanyLocation() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20),
		marginBottom: getResponsiveStyle(3),
	};
}

function styleCompanyName() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18),
	};
}

function styleImage() {
	return {
		width: getResponsiveStyle(56, 'dimensions'),
		height: getResponsiveStyle(56, 'dimensions'),
		marginRight: getResponsiveStyle(10),
	};
}
