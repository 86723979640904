import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	tabs?: { value: string; label: string }[];
	onPress?: (tab: string) => void;
	selectedTab?: string;
	containerStyle?: TStyle;
};

const Tabs = ({ tabs, onPress, selectedTab, containerStyle }: Props) => {
	const primary = usePrimaryColors();
	return (
		<View style={[styles.container, styleContainer(), containerStyle]}>
			{tabs?.map(
				tab =>
					!tab.value.includes('Hidden') && (
						<Pressable
							key={tab.value}
							onPress={
								onPress ? () => onPress(tab.value) : () => null
							}
							style={[
								styleTabContainer(
									selectedTab === tab.value,
									primary
								),
								styles.tabContainer,
							]}
						>
							<Text style={styleTab(selectedTab === tab.value)}>
								{tab.label}
							</Text>
						</Pressable>
					)
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	tabNotSelectedContainer: {
		borderBottomWidth: 0,
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'center',
		flexWrap: 'wrap',
		rowGap: getResponsiveStyle(20),
	},
	tabText: {
		textAlign: 'center',
		userSelect: 'none',
		paddingBottom: 12,
	},
	tabNotSelectedText: {
		color: palettes.grey[0],
		fontWeight: '400',
	},
	tabSelectedText: {
		color: palettes.dark[0],
		fontWeight: '700',
		paddingBottom: 12,
	},
	tabContainer: {
		width: 'max-content',
		marginRight: getResponsiveStyle(24),
	},
});

export default Tabs;

const styleTab = (isTabSelected: boolean): TStyleInputOrText => {
	return [
		styles.tabText,
		{
			fontSize: getResponsiveStyle(18, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		isTabSelected ? styles.tabSelectedText : styles.tabNotSelectedText,
	];
};

function styleTabContainer(isTabSelected: boolean, primary: string[]) {
	return isTabSelected
		? {
				borderBottomWidth: 1,
				borderBottomColor: primary[0],
		  }
		: styles.tabNotSelectedContainer;
}

function styleContainer() {
	return {
		marginBottom: getResponsiveStyle(48),
	};
}
