import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import StarRating from 'react-native-star-rating-widget';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

export type EntityObj = {
	type: string;
	_id: string;
	val?: number;
};
type Props = {
	onRatingChange: (entity: EntityObj) => void;
	entity: EntityObj;
};

const StarRatingComponent = ({ onRatingChange, entity }: Props) => {
	const [ratingValue, setRatingValue] = useState(0);
	const primary = usePrimaryColors();
	return (
		<StarRating
			starSize={38}
			color={primary[0]}
			rating={ratingValue}
			enableHalfStar={false}
			minRating={1}
			onChange={val => {
				setRatingValue(val);
				onRatingChange({ ...entity, val });
			}}
			style={styles.rating}
		/>
	);
};

export default StarRatingComponent;

const styles = StyleSheet.create({
	rating: {
		marginLeft: getResponsiveStyle(-7),
		marginBottom: getResponsiveStyle(10),
		marginTop: getResponsiveStyle(10),
	},
});
