import { useMutation } from '@apollo/client';
import { CREATE_MESSAGE } from '../../graphql/chat/mutation';

const useCreateMessage = () => {
	const [createMessage] = useMutation(CREATE_MESSAGE);

	const executeCreateMessageMutation = ({
		sender,
		recipients,
		message,
		messageType,
		conversationId,
	}: {
		sender: string;
		recipients: string;
		message: string;
		messageType: string;
		conversationId: string;
	}) => {
		createMessage({
			variables: {
				sender,
				recipients,
				message,
				messageType,
				conversationId,
			},
		});
	};
	return { executeCreateMessageMutation };
};

export default useCreateMessage;
