import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import * as Yup from 'yup';
import { Button, Input, SignupCompanyButtons, SignupWithDivider } from '../..';
import { useCreateUser } from '../../../api/auth';
import { useCheckUsernameEmailExists } from '../../../api/auth/useCheckUsernameEmailExists';
import { CHANGE_PASSWORD } from '../../../graphql/auth/mutations';
import { GET_USER } from '../../../graphql/auth/queries';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import {
	setPersonalInformation,
	setTypeOfAccount,
	setYourInformation,
} from '../../../redux/createUserSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setPrimaryColors } from '../../../redux/uiSlice';
import { formatEmail } from '../../../utils/formatEmail';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import { TButtonVariant } from '../../common/button/Button';
import PasswordHideShow from '../../common/password-hide-show/PasswordHideShow';

const validationSchema = Yup.object({
	username: Yup.string()
		.min(3, 'Min length: 3')
		.matches(/^\S+$/, 'Username cannot have spaces')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	password: Yup.string().required('Required'),
	phoneNumber: Yup.string(),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password')], 'Passwords does not match')
		.required('Required'),
});

const socialAuthValidationSchema = Yup.object({
	username: Yup.string()
		.min(3, 'Min length: 3')
		.matches(/^\S+$/, 'Username cannot have spaces')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	password: Yup.string(),
	confirmPassword: Yup.string(),
});

type Props = {
	isProfile?: boolean;
};

const PersonalInformationForm = ({ isProfile = false }: Props) => {
	const { SignupSidebarArray, args } = useAppSelector(state => ({
		SignupSidebarArray: state.ui.SignupSidebarArray,
		loggedInUserEmail: state.auth.loggedInUserDetails?.email,
		args: state.createUser.args,
	}));

	const { isSocialAuth, indexOfPrimaryColors } = useAppSelector(
		states => states.ui
	);

	const { goToNextStepScreen: goToNextAuthScreen } =
		useNextStepScreen(SignupSidebarArray);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const formikRef = useRef<any>();

	const [isConsumerSignUp, setIsConsumerSignUp] = useState(
		indexOfPrimaryColors === 0
	);
	const [isSellerSignUp, setIsSellerSignUp] = useState(
		indexOfPrimaryColors === 3
	);
	const [oldPassword, setOldPassword] = useState('');
	const [initialValues, setInitialValues] = useState({
		username: '',
		email: '',
		password: '',
		phoneNumber: '',
		confirmPassword: '',
	});

	const [isPasswordHidden, setIsPasswordHidden] = useState({
		oldPassword: true,
		password: true,
		confirmPassword: true,
	});

	const onCompletedCreateUser = (data: TCreateUserResponse) => {
		if (data?.createUser?.success) {
			dispatch(
				setPrimaryColors(
					data?.createUser?.data?.typeOfAccount || 'CONSUMER'
				)
			);
			// setAlert(
			// 	t('Please see the email we sent you to create your account'),
			// 	'normal'
			// );
			goToNextAuthScreen();
		} else {
			setAlert(t('Error occurred during account creation'), 'danger');
		}
	};

	const { createUser, loading: loadingCreateUser } = useCreateUser(
		onCompletedCreateUser
	);

	useEffect(() => {
		if (
			!!args?.email &&
			!!args.personalInformation.firstName &&
			!!args.personalInformation.lastName
		) {
			setInitialValues({
				...initialValues,
				email: args.email,
				username: args.username,
			});
		}
	}, []);

	const onCompleteVerifyUsernameEmailAvailability: TOnCompleteApiCall<
		TCheckUsernameEmailAvailabilityResponse
	> = data => {
		if (data?.checkUsernameEmailAvailability?.success) {
			if (isConsumerSignUp || isSellerSignUp) {
				createUser();
			} else {
				goToNextAuthScreen();
			}
		} else {
			setAlert(
				t(data?.checkUsernameEmailAvailability?.message as any) ||
					t('Username or email already exists'),
				'danger'
			);
			dispatch(
				setPersonalInformation({
					email: '',
					password: '',
					username: '',
				})
			);
		}
	};

	const {
		verifyUsernameEmailAvailability,
		loading: verifyUsernameEmailAvailabilityLoading,
	} = useCheckUsernameEmailExists({
		onCompleted: onCompleteVerifyUsernameEmailAvailability,
	});

	const [changePassword, { loading: editUserLoading }] = useMutation<
		TChangePasswordResponse,
		TChangePasswordInputs
	>(CHANGE_PASSWORD, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_USER }],
		onCompleted: data => {
			if (data.changePassword.success) {
				setAlert(t('Password updated!'), 'normal');
			} else {
				setAlert(t('Unable to update password'), 'danger');
			}
		},
		onError: () => {
			setAlert(t('Unable to update password'), 'danger');
		},
	});

	const handleEditUserSubmit = async (
		newPassword: string,
		oldPassword: string
	) => {
		await changePassword({
			variables: {
				oldPassword,
				newPassword,
			},
		});
		formikRef.current.resetForm();
		setOldPassword('');
	};

	const onPressEye = (
		type: 'confirm-password' | 'password' | 'old-password'
	) => {
		switch (type) {
			case 'password':
				setIsPasswordHidden({
					...isPasswordHidden,
					password: !isPasswordHidden.password,
				});
				break;

			case 'confirm-password':
				setIsPasswordHidden({
					...isPasswordHidden,
					confirmPassword: !isPasswordHidden.confirmPassword,
				});
				break;

			case 'old-password':
				setIsPasswordHidden({
					...isPasswordHidden,
					oldPassword: !isPasswordHidden.oldPassword,
				});
				break;
		}
	};

	return (
		<Formik
			innerRef={formikRef}
			enableReinitialize
			initialValues={initialValues}
			validationSchema={
				isSocialAuth ? socialAuthValidationSchema : validationSchema
			}
			onSubmit={values => {
				const {
					email: _email,
					password,
					username,
					phoneNumber,
				} = values;

				const email = formatEmail(_email);

				verifyUsernameEmailAvailability({
					email,
					username,
				});

				dispatch(
					setPersonalInformation({
						email,
						password,
						username,
					})
				);

				if (isConsumerSignUp || isSellerSignUp) {
					dispatch(
						setTypeOfAccount(
							isConsumerSignUp ? 'CONSUMER' : 'SELLER'
						)
					);
					dispatch(
						setYourInformation({
							address1: 'none',
							address2: '',
							city: 'Rochefourchat',
							countryCode: 'fr',
							countryLabel: 'France',
							description: '',
							eventType: 'PRIVATE',
							favouriteCategories: [
								{
									en: 'all',
									fr: 'all',
									value: 'all',
								},
							],
							firstName: username,
							gender: 'OTHER',
							interestTags: ['all'],
							lastName: username,
							numericSignature: '',
							phoneNumber,
							profileImageLink: '',
							website: '',
							zipCode: '26340',
						})
					);
				}
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				errors,
				touched,
				isValid,
				dirty,
				values,
			}) => (
				<View style={styleContainer(isProfile)}>
					{/* {isConsumerSignUp && (
						<>
							<SignupCompanyButtons isCompleteSignUpFromBackend />
							<SignupWithDivider />
						</>
					)} */}
					{!isProfile && (
						<>
							<Input
								containerStyles={styleInput()}
								onChangeText={handleChange('username')}
								value={values.username}
								onBlur={handleBlur('username')}
								label={t('Username') + '*'}
								error={
									touched?.username && errors?.username
										? errors.username
										: ''
								}
							/>
							{isSellerSignUp && (
								<Input
									value={values.phoneNumber}
									label={t('Phone Number')}
									containerStyles={styleInput()}
									onChangeText={handleChange('phoneNumber')}
									onBlur={handleBlur('phoneNumber')}
									error={
										touched.phoneNumber &&
										errors.phoneNumber
											? errors.phoneNumber
											: ''
									}
								/>
							)}
							<Input
								label={t('Email') + '*'}
								value={values.email}
								containerStyles={styleInput()}
								onChangeText={handleChange('email')}
								onBlur={handleBlur('email')}
								error={
									touched?.email && errors?.email
										? errors.email
										: ''
								}
							/>
						</>
					)}

					{isProfile && (
						<Input
							label={t('Old Password') + '*'}
							containerStyles={styleInput()}
							secureTextEntry={isPasswordHidden.oldPassword}
							value={oldPassword}
							onChangeText={setOldPassword}
							decoration={
								<PasswordHideShow
									isPasswordHidden={
										isPasswordHidden.oldPassword
									}
									onPressEye={() =>
										onPressEye('old-password')
									}
								/>
							}
						/>
					)}
					{!isSocialAuth && (
						<Input
							label={t('Password') + '*'}
							containerStyles={styleInput()}
							onChangeText={handleChange('password')}
							onBlur={handleBlur('password')}
							value={values.password}
							secureTextEntry={isPasswordHidden.password}
							error={
								touched.password && errors.password
									? errors.password
									: ''
							}
							decoration={
								<PasswordHideShow
									isPasswordHidden={isPasswordHidden.password}
									onPressEye={() => onPressEye('password')}
								/>
							}
						/>
					)}
					{!isSocialAuth && (
						<Input
							label={t('Confirm Password') + '*'}
							containerStyles={styleInput()}
							onChangeText={handleChange('confirmPassword')}
							onBlur={handleBlur('confirmPassword')}
							value={values.confirmPassword}
							secureTextEntry={isPasswordHidden.confirmPassword}
							error={
								touched.confirmPassword &&
								errors.confirmPassword
									? errors.confirmPassword
									: ''
							}
							decoration={
								<PasswordHideShow
									isPasswordHidden={
										isPasswordHidden.confirmPassword
									}
									onPressEye={() =>
										onPressEye('confirm-password')
									}
								/>
							}
						/>
					)}

					<Button
						title={isProfile ? t('Update') : t('Next')}
						size="lg"
						variant={
							isProfile
								? getButtonVariantForEdit(
										values.password,
										values.confirmPassword,
										oldPassword
								  )
								: (isSocialAuth && 'primary') ||
								  getButtonVariantForCreate(dirty, isValid)
						}
						onPress={() => {
							isProfile
								? handleEditUserSubmit(
										values.password,
										oldPassword
								  )
								: handleSubmit();
						}}
						loading={getSingleLoadingState(
							editUserLoading,
							loadingCreateUser,
							verifyUsernameEmailAvailabilityLoading
						)}
					/>
				</View>
			)}
		</Formik>
	);
};

const getButtonVariantForEdit = (
	password: string,
	confirmPassword: string,
	oldPassword: string
): TButtonVariant => {
	return !!password &&
		!!confirmPassword &&
		password === confirmPassword &&
		!!oldPassword
		? 'primary'
		: 'disabled';
};

const getButtonVariantForCreate = (
	dirty: boolean,
	isValid: boolean
): TButtonVariant => {
	return dirty && isValid ? 'primary' : 'disabled';
};

export default PersonalInformationForm;

const styleContainer = (isProfile: boolean) => {
	return { marginBottom: isProfile ? 0 : getResponsiveStyle(46) };
};

const styleInput = () => {
	return { marginBottom: getResponsiveStyle(24) };
};
