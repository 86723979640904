import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_MEDIA_MESSAGES } from '../../graphql/chat/queries';
import { useAppDispatch } from '../../redux/store';

const useGetMediaMessages = () => {
	const dispatch = useAppDispatch();

	const [getMediaMessages, { data, error, loading }] =
		useLazyQuery(GET_MEDIA_MESSAGES);

	const executeMediaMessageQuery = ({
		conversationId,
		messageType,
	}: {
		conversationId: string;
		messageType: string;
	}) => {
		if (conversationId)
			getMediaMessages({
				variables: {
					conversationId,
					messageType,
				},
			});
	};
	return { executeMediaMessageQuery, data, error, loading };
};

export default useGetMediaMessages;
