import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { About } from './About';
import { InfoSidebar } from './InfoSidebar';

type Props = {
	events: TGetAllEventsData[];
	description: TGetAllBrandsData['description'];
};

const Info = ({ events, description }: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View style={styles.container}>
			<About description={description} />
			{!isLessThanDesktopBase && <InfoSidebar events={events} />}
		</View>
	);
};

export default Info;

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
	},
});
