import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate, YourInformationForm } from '../components';
import { useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';
import { useDispatch } from 'react-redux';

const YourInformationScreen = () => {
	const { t } = useTranslation();
	const signupSidebarArray = useAppSelector(
		state => state.ui.SignupSidebarArray
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setCurrentScreen('/your-information'));
	}, []);
	return (
		<AuthTemplate
			heading={t('Your Information')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<YourInformationForm />
		</AuthTemplate>
	);
};

export default YourInformationScreen;
