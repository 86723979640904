export const placeholderImage =
	'https://ocpus-dev.s3.ap-southeast-1.amazonaws.com/dac834ba-12e8-45c3-b4de-ae832cabb440.png';
export const transformToPopulated = (
	variant: Variant,
	product: Product
): PopulatedVariant => {
	return {
		_id: variant._id,
		variator: variant?.variator,
		value: variant?.value,
		vat: variant?.vat,
		requiresShipping: true,
		image: variant?.image,
		option: variant?.option,
		GTIN: variant?.GTIN,
		associatedProduct: {
			_id: product._id,
			name: product.name,
			brand: product?.brand,
			shortDescription: product.shortDescription,
			description: product.description,
			category: product.category,
			publishedStatus: product.publishedStatus,
			images: product.images,
			brand: product.brand,
			averagePrice: product?.averagePrice,
			defaultVariant: product?.defaultVariant,
			variants: product?.variants,
		},
	};
};
