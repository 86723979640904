import React from 'react';
import { StyleSheet, View } from 'react-native';
import { formatAMPM } from '../../../../utils/monthsUtils';
import Profile from '../../../common/profile/Profile';

type Props = {
	user: TGetAllUsersData;
	location?: string;
	profileImage?: string;
	createdAt: string;
	isRatingType?: boolean;
	_id?: string;
};

export const CommentUserPictureRow = (profileInfo: Props) => {
	const date = new Date(+profileInfo.createdAt);

	return (
		<View style={styles.container}>
			<Profile
				name={`${profileInfo?.user?.username}`}
				country={
					profileInfo?.user?.personalInformation?.address?.countryCode
				}
				countryLabel={
					profileInfo?.user?.personalInformation?.address
						?.countryLabel
				}
				city={profileInfo?.user?.personalInformation?.address?.city}
				countryCode={
					profileInfo?.user?.personalInformation?.address?.countryCode
				}
				isRatingType={profileInfo.isRatingType}
				// location="Copenhagen, Denmark"
				profileImage={
					profileInfo?.user?.personalInformation?.profileImageLink
				}
				id={profileInfo?.user?._id}
				time={formatAMPM(date)}
				postId={profileInfo?._id}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: 16,
		marginBottom: 8,
	},
});
