import { BlurView } from 'expo-blur';
import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import PlayIcon from '../../../common/icons/PlayIcon';

const VideoPlayBtn = ({ onPress }: { onPress: () => void }) => {
	return (
		<View style={styles.playBtnRapper}>
			<Pressable onPress={onPress}>
				<BlurView intensity={30} tint={'light'} style={styles.playBtn}>
					<View style={styles.playBtnSm}>
						<PlayIcon
							onPress={onPress}
							color="#fff"
							width={40}
							height={40}
						/>
					</View>
				</BlurView>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	playBtnRapper: {
		position: 'absolute',
	},
	playBtn: {
		width: getResponsiveStyle(70),
		height: getResponsiveStyle(70),
		borderRadius: 50,
		position: 'relative',
		zIndex: 10,
		justifyContent: 'center',
		alignItems: 'center',
		// top: '27%',
		// left: '27%',
	},
	playBtnSm: {
		position: 'absolute',
	},
});

export default VideoPlayBtn;
