import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useAppSelector } from '../../../redux/store';
import NotAvailableMessage from '../not-available-message/NotAvailableMessage';
import { TableContent } from './TableContent';
import { TableHeader } from './TableHeader';

interface Props<T> {
	headers: TTableHeader[];
	content?: T[];
	type?: TTableTypes;

	messageButton?: boolean;
	profileButton?: boolean;
	followButton?: boolean;
	onPressFollowButton?: (id: number) => void;
	isFollowingArray?: TIsFollowingData[];
	loadingFollowUser?: boolean;
	followUnfollowButtonPressedId?: string;
	requestSendBooleanArray?: TIsFollowMeRequestData[];
	unfollowrequestSendBooleanArray?: TIsFollowMeRequestData[];
	onClickHostFollowMeActionButton?: (
		recipientId: string,
		hasRequestBeenSent: boolean,
		index: number
	) => void;
	followMeUnfollowMeButtonPressedId?: string;
	loadingFollowMe?: boolean;
	eventsAttendedCount?: EventAttendedCount[];
}

export const Table = <T extends unknown>({
	headers,
	content,
	type,
	messageButton = false,
	profileButton = true,
	followButton = false,
	onPressFollowButton = () => {},
	isFollowingArray = [],
	loadingFollowUser = false,
	followUnfollowButtonPressedId,
	requestSendBooleanArray,
	unfollowrequestSendBooleanArray,
	onClickHostFollowMeActionButton,
	followMeUnfollowMeButtonPressedId,
	loadingFollowMe,
	eventsAttendedCount,
}: Props<T>) => {
	const { t } = useTranslation();

	const isFollowingMeArray = useAppSelector(
		state => state.user.isFollowingMe
	);
	return (
		<View style={styles.container}>
			<ScrollView
				horizontal
				style={styles.scrollViewContainer}
				contentContainerStyle={styles.contentContainerStyle}
			>
				{content && content.length > 0 ? (
					<>
						<TableHeader headers={headers} type={type} />
						{content.map((user: any, index) => {
							let isFollowing = false;
							let loading = false;

							if (isFollowingArray !== undefined) {
								isFollowing =
									isFollowingArray.find(
										item => item.userId === user._id
									)?.isFollowing ?? false;

								loading =
									followUnfollowButtonPressedId ===
										user._id && loadingFollowUser;
							}

							const loading_ =
								followMeUnfollowMeButtonPressedId ===
									user._id && loadingFollowMe;

							const eventAttendedByUserCount =
								eventsAttendedCount?.find(
									user_ => user_.user === user._id
								)?.eventsAttendedCount;

							return (
								<TableContent
									key={index}
									user={user}
									headers={headers}
									type={type}
									messageButton={messageButton}
									profileButton={profileButton}
									followButton={followButton}
									onPressFollowButton={onPressFollowButton}
									index={index}
									isFollowing={isFollowing}
									loadingFollowUser={loading}
									hasRequestBeenSent={
										requestSendBooleanArray?.find(
											i => i.userId === user._id
										)?.isFollowRequestSent
									}
									hasUnfollowRequestBeenSent={
										unfollowrequestSendBooleanArray?.find(
											i => i.userId === user._id
										)?.isUnFollowRequestSent
									}
									onClickHostFollowMeActionButton={
										onClickHostFollowMeActionButton
									}
									isFollowingMe={
										isFollowingMeArray?.find(
											i => i.userId === user._id
										)?.isFollowingMe
									}
									loadingFollowMe={loading_}
									eventsAttendedCount={
										eventAttendedByUserCount
									}
								/>
							);
						})}
					</>
				) : (
					<View
						style={{
							marginLeft: 24,
						}}
					>
						<NotAvailableMessage
							message={t('No profiles found!')}
						/>
					</View>
				)}
			</ScrollView>
			{/* <Button title='Load More'/> */}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginLeft: -24,
	},
	scrollViewContainer: {
		flexDirection: 'column',
		width: 'calc(100% + 24px)',
	},
	contentContainerStyle: {
		flexDirection: 'column',
		minWidth: 1400,
		overflow: 'visible',
	},
});

export default Table;
