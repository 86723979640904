import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';

const EventProductLoader=()=>{
    return (
        <View style={styles.container}>
            <View>
                <Skeleton
                height={200}
                width ={getResponsiveStyle(208, 'dimensions')}
                style={{borderRadius: 6}}
                />
            </View>
            <View style={styles.text}>
                <Skeleton
                    height={8}
                    width ={200}
                    style={{borderRadius: 6}}
                    />
                <Skeleton
                    height={8}
                    width ={170}
                    style={{borderRadius: 6}}
                    />
                <Skeleton
                    height={13}
                    width ={80}
                    style={{ marginTop: getResponsiveStyle(10), borderRadius: 6}}
                    />
            </View>
        </View>
    )
}
const styles =StyleSheet.create({
    text:{
        flexDirection:'column',
        marginTop:getResponsiveStyle(5), 
        justifyContent:'center',
        alignItems:'center'
    },
    container:{
        marginRight: getResponsiveStyle(10),
        flexDirection:'column'
    }
})
export default EventProductLoader