import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import * as Yup from 'yup';
import { Button, Input, PasswordHideShow } from '../..';
import { useLogin } from '../../../api/auth';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const schema = Yup.object({
	email: Yup.string().required('Required'),
	password: Yup.string().required('Required'),
});

type TInitialValues = Yup.InferType<typeof schema>;

const initialValues: TInitialValues = {
	email: '',
	password: '',
};

const LoginForm = (props: { handlePressForgetPassword: () => void }) => {
	const { t } = useTranslation();
	const { executeLoginMutation, loading } = useLogin();

	const [isPasswordHidden, setIsPasswordHidden] = useState(true);

	const handlePressForgetPassword = () => {
		props.handlePressForgetPassword();
	};

	const signIn = (values: { email: string; password: string }) => {
		const { email, password } = values;
		if (!!email && !!password)
			executeLoginMutation({
				email,
				password,
			});
	};

	const onPressEye = () => {
		setIsPasswordHidden(prev => !prev);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={schema}
			onSubmit={values => {
				signIn(values);
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				dirty,
				isValid,
				touched,
				errors,
			}) => (
				<>
					<View>
						<Input
							style={[styles.input]}
							containerStyles={styleInput('email')}
							onChangeText={handleChange('email')}
							onBlur={handleBlur('email')}
							label={t('Username / Email')}
							onSubmitEditing={() => signIn(values)}
							error={
								touched.email && errors.email
									? errors.email
									: ''
							}
						/>
						<Input
							label={t('Password')}
							containerStyles={styleInput('password')}
							onChangeText={handleChange('password')}
							onBlur={handleBlur('password')}
							style={[styles.input]}
							onSubmitEditing={() => signIn(values)}
							secureTextEntry={isPasswordHidden}
							error={
								touched.password && errors.password
									? errors.password
									: ''
							}
							decoration={
								<PasswordHideShow
									isPasswordHidden={isPasswordHidden}
									onPressEye={onPressEye}
								/>
							}
						/>
					</View>

					<Button
						title={t('Login')}
						variant={dirty && isValid ? 'primary' : 'disabled'}
						outerContainerProps={{
							style: styleLoginButtonOuterContainer(),
						}}
						size="lg"
						onPress={handleSubmit}
						loading={loading}
					/>

					<TouchableOpacity
						onPress={handlePressForgetPassword}
						activeOpacity={0.75}
					>
						<Text
							style={[
								styles.textButtonForget,
								styleTextButtonForgot(),
							]}
						>
							{t('Did you forget your password?')}
						</Text>
					</TouchableOpacity>
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
	textButtonForget: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		textDecorationLine: 'underline',
		color: palettes.grey[0],
		textAlign: 'center',
	},
});

export default LoginForm;

const styleInput = (type: 'email' | 'password') => {
	return { marginBottom: getResponsiveStyle(type === 'email' ? 20 : 40) };
};
function styleTextButtonForgot() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	};
}

function styleLoginButtonOuterContainer() {
	return {
		marginBottom: getResponsiveStyle(20),
	};
}
