import React from 'react';
import { View } from 'react-native';
import { Button, Input } from '../../..';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';

const TimeOfWeekSelector = () => {
	const { t } = useTranslation();
	return (
		<View style={{ marginBottom: getResponsiveStyle(72) }}>
			<View
				style={{
					flexDirection: 'row',
					marginBottom: getResponsiveStyle(16),
				}}
			>
				<Input
					label={t('From')}
					containerStyles={{
						flex: 1,
						marginRight: getResponsiveStyle(16),
					}}
				/>
				<Input label={t('To')} containerStyles={{ flex: 1 }} />
			</View>
			<Button
				title={'+ ' + t('Create more Scheduals')}
				size="lg"
				variant="disabled"
			/>
		</View>
	);
};

export default TimeOfWeekSelector;
