import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-web-hover';
import ContactCard from './ContactCard';
import { People } from './People';

const PeopleSection = ({
	expandHeight,
	roomData = [],
}: {
	expandHeight?: boolean;
	roomData: RoomData[];
}) => {
	return (
		<View style={styles.flex1}>
			<ScrollView
				style={[styles.container, expandHeight && { height: 530 }]}
				showsHorizontalScrollIndicator={false}
			>
				{roomData &&
					roomData?.map(people => <ContactCard {...people} />)}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flex: 1,
		// marginBottom: 2,
	},
	container: {
		// flex: 1,
		width: '100%',
		overflow: 'scroll',
	},
});

export default PeopleSection;
