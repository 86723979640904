import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TInitialState = {
	typeOfAccount?: string;
	gender?: string;
	personalInformation?: {
		address?: {
			city?: string;
			countryCode?: string;
			zipCode?: string;
		};
		favouriteCategories?: string;
	};
	address?: {
		city?: string;
		zipCode?: string;
		countryCode?: string;
	};
	tags?: string;
	rating?: string;
	startingEventDateTime?: string;
	typeOfEvent?: TTypeOfEvent;
	eventLocation?: {
		address1?: string;
		address2?: string;
		city?: string;
		zipCode?: string;
		countryCode?: string;
		countryLabel?: string;
	};
};

const initialState: TInitialState = {};

const addFilterSlice = createSlice({
	name: 'addFilterSlice',
	initialState,
	reducers: {
		resetFilters: () => initialState,
		setAllFilters: (state, action: PayloadAction<TInitialState>) =>
			action.payload,
		setRating(state, action: PayloadAction<string>) {
			state.rating = action.payload;
		},
		setTags(state, action: PayloadAction<string>) {
			state.tags = action.payload;
		},
		setTypeOfAccount(state, action: PayloadAction<string>) {
			state.typeOfAccount = action.payload;
		},
		setGender(state, action: PayloadAction<string>) {
			state.gender = action.payload;
		},
	},
});

export const {
	setGender,
	setTypeOfAccount,
	setRating,
	setTags,
	resetFilters,
	setAllFilters,
} = addFilterSlice.actions;

export default addFilterSlice.reducer;
