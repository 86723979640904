import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const GoogleIcon = (props: SvgProps) => (
	<Svg viewBox="0 0 24 24" fill="none" {...props}>
		<G clipPath="url(#a)">
			<Path
				d="M24.448 12.276c0-.816-.067-1.636-.21-2.438H12.774v4.62h6.566a5.551 5.551 0 0 1-2.43 3.647v2.999h3.917c2.3-2.09 3.622-5.177 3.622-8.828Z"
				fill="#4285F4"
			/>
			<Path
				d="M12.773 24c3.279 0 6.043-1.062 8.058-2.896l-3.917-2.998c-1.09.731-2.497 1.146-4.136 1.146-3.171 0-5.86-2.112-6.825-4.952H1.911v3.091c2.064 4.052 6.266 6.61 10.862 6.61Z"
				fill="#34A853"
			/>
			<Path
				d="M5.949 14.3a7.1 7.1 0 0 1 0-4.594V6.615H1.91a11.873 11.873 0 0 0 0 10.776l4.038-3.09Z"
				fill="#FBBC04"
			/>
			<Path
				d="M12.773 4.75a6.653 6.653 0 0 1 4.663 1.799l3.47-3.426A11.766 11.766 0 0 0 12.774 0C8.177 0 3.975 2.558 1.911 6.615l4.038 3.09c.96-2.843 3.653-4.955 6.824-4.955Z"
				fill="#EA4335"
			/>
		</G>
		<Defs>
			<ClipPath id="a">
				<Path
					fill="#fff"
					transform="translate(.375)"
					d="M0 0h24.31v24H0z"
				/>
			</ClipPath>
		</Defs>
	</Svg>
);

export default GoogleIcon;
