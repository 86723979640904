import React, { useEffect } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withSpring,
} from 'react-native-reanimated';
import styled from 'rn-css';
import { palettes } from '../../../../config';
import { useAppSelector } from '../../../../redux/store';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import ReadReceiptIcon from '../../../common/icons/ReadReceiptIcon';
import UserImageMessage from './UserImageMessage';
import UserVideoMessage from './UserVideoMessage';
import { FacebookIcon } from '../../../common/icons';

const ChatMessageRapper = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

const ChatMessageText = styled.Text`
	position: relative;
	font-size: ${getResponsiveStyle(15, 'font')}px;
	background-color: #98a2b2;
	color: #ffffff;
	line-height: 1.375rem;
	width: max-content;
	max-width: 80%;
	margin: 5px;
	padding-top: 7px;
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 7px;
	border-top-left-radius: 25px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 25px;
	border-bottom-left-radius: 25px;
`;

const MessageTickContainer = styled.View`
	display: inline-block;
	margin-left: 2px;
	position: absolute;
	bottom: 1.5px;
	right: 6px;
	height: 16px;
	width: 16px;
`;

const UserMessage = ({
	message,
	roomMessage,
	user,
	from
}: {
	message: TODO;
	roomMessage?: boolean;
	user?: TCommonResponseData;
	from?: string
}) => {
	const offset = useSharedValue(0);

	const defaultSpringStyles = useAnimatedStyle(() => {
		return {
			transform: [{ translateY: withSpring(offset.value * 255) }],
		};
	});

	useEffect(() => {
		offset.value = offset.value + -0.05;
	}, []);

	const userDetail = useAppSelector(state => state.auth.loggedInUserDetails);


	return (
		<Animated.View style={defaultSpringStyles}>
			{(message.messageType === 'TEXT' ||
				message.messageType === 'LINK') && (
					<ChatMessageRapper>
						<ChatMessageText>
							{message.message}
							{!roomMessage && (
								<MessageTickContainer>
									<ReadReceiptIcon
										readReceipt={message.recipientsReadReceipt}
									/>
								</MessageTickContainer>
							)}
						</ChatMessageText>
						{user?.personalInformation?.profileImageLink ? (
							<Image
								style={styles.image}
								source={{
									uri: user?.personalInformation
										?.profileImageLink,
								}}
							/>
						) : userDetail?._id ? (
							<Image
								style={styles.image}
								source={{
									uri: userDetail?.personalInformation
										?.profileImageLink,
								}}
							/>
						) : (
							<Image
								style={styles.image}
								source={require('../../../../assets/defaultProfileImage.svg')}
							/>
						)}
						{from === "Facebook" && <FacebookIcon
							height={getResponsiveStyle(
								12,
								'dimensions'
							)}
							width={getResponsiveStyle(
								12,
								'dimensions'
							)}
						/>}
					</ChatMessageRapper>
					// <View style={styles.flex1}>
					// 	<View style={styles.textContainer}>
					// 		<Text style={styles.text}>{message.message}</Text>

					// 		{!message.roomMessage && (
					// 			<View style={styles.readReceipt}>
					// 				<ReadReceiptIcon
					// 					readReceipt={message.recipientsReadReceipt}
					// 				/>
					// 			</View>
					// 		)}
					// 	</View>
					// 	<Image
					// 		style={styles.image}
					// 		source={require(`../../../../assets/live-event/ChatImage.png`)}
					// 	/>
					// </View>
				)}
			{message.messageType === 'IMAGE' && (
				<UserImageMessage message={message} />
			)}

			{message.messageType === 'VIDEO' && (
				<UserVideoMessage message={message} />
			)}
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: 15,
	},
	textContainer: {
		width: 'max-content',
		height: 'max-content',
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 10,
		paddingLeft: 10,
		paddingRight: 10,
		backgroundColor: '#98A2B2',
		borderTopLeftRadius: 50,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 50,
		borderBottomLeftRadius: 50,
	},
	text: {
		width: 'max-content',
		height: 'max-content',
		// maxWidth: 450,
		fontSize: 15,
		fontWeight: 'normal',
		color: palettes.light[0],
		marginBottom: 5,
	},
	readReceipt: {
		position: 'absolute',
		right: 20,
		bottom: 0,
	},
	image: {
		width: 30,
		height: 30,
		borderRadius: 25,
	},
	container: {
		// borderWidth: 2,
		// borderColor: palettes.grey[4],
		height: 250,
		width: '100%',
		overflow: 'scroll',
	},
});

export default UserMessage;
