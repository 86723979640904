import { View, Text, StyleSheet, Image } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Label from '../../common/label/Label';
import GoogleMaps from '../../common/google-maps/google-maps';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

const LocationSection = () => {
	const { t } = useTranslation();
	const { isMobileView } = useIsSpecificViewportWidth();
	const { eventLocation, typeOfEvent } = useAppSelector(
		state => state.getEvent.event
	);
	const constructedAddress = `${eventLocation?.address1}, ${eventLocation?.city} ${eventLocation?.countryLabel}`;
	return (
		// <View style={Styles.container}>
		// 	<Text style={Styles.header}>{t('Location')}</Text>
		// 	<Image
		// 		source={{
		// 			uri: 'http://placekitten.com/500',
		// 		}}
		// 		style={Styles.imageContainer}
		// 	/>
		// </View>
		<>
			<Text style={[Styles.header, mobileHeader(isMobileView)]}>
				{t('Location')}
			</Text>
			<View style={styleAddressContainer()}>
				{/* <Label label={t('Location')} /> */}
				{typeOfEvent === 'PHYSICAL' && (
					<Text
						style={[
							Styles.header,
							{
								fontSize: getResponsiveStyle(20, 'font'),
							},
							mobileSubHeader(isMobileView),
						]}
					>
						{constructedAddress}
					</Text>
				)}
				{/* <GoogleMaps
					{...{
						googleMapProps: {
							mapContainerStyle: {
								height: getResponsiveStyle(390, 'dimensions'),
								width: '100%',
								borderRadius: 6,
							},
						},
					}}
				/> */}
			</View>
		</>
	);
};

const Styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	header: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
		marginBottom: getResponsiveStyle(10, 'spacing'),
	},
	imageContainer: {
		marginTop: getResponsiveStyle(24),
		width: '100%',
		height: getResponsiveStyle(390),
		resizeMode: 'cover',
	},
});

function styleAddressContainer() {
	return {
		marginBottom: getResponsiveStyle(24),
	};
}
function mobileHeader(isMobileView: boolean) {
	return isMobileView && { fontSize: getResponsiveStyle(16, 'font') };
}
function mobileSubHeader(isMobileView: boolean) {
	return isMobileView && { fontSize: getResponsiveStyle(14, 'font') };
}

export default LocationSection;
