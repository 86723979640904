import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	tabs: { label: string; value: string }[];
	onPress: (tab: string) => void;
	selectedTab: string;
	center: boolean;
};

const ChatTab = ({ tabs, onPress, selectedTab, center }: Props) => {
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const primaryColors = usePrimaryColors();
	return (
		<View
			style={[styles.container, center && { justifyContent: 'center' }]}
		>
			{tabs.map(tab => (
				<Pressable key={tab.value} onPress={() => onPress(tab.value)}>
					<Text
						style={styleTab(
							selectedTab,
							tab.value,
							width,
							primaryColors
						)}
					>
						{tab.label}
					</Text>
				</Pressable>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 2,
	},
	tab: {
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],
		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 1,
	},
});

export default ChatTab;

const styleTab = (
	selectedTab: string,
	tab: string,
	width: number,
	primary: string[]
) => {
	const tabSelected = {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 1,
	};

	const tabNotSelected = {
		color: palettes.grey[0],
		fontWeight: '400',
	};
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		selectedTab === tab
			? { ...tabSelected, borderBottomColor: primary[0] }
			: tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};
