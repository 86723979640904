import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const StarFilledIcon = (props: SvgProps) => {
	return (
		<Svg width={15} height={14} fill="none" viewBox="0 0 15 14" {...props}>
			<Path
				fill="#FEBF3B"
				d="m7.36 0 2.275 4.608 5.086.743-3.68 3.585L11.91 14l-4.55-2.392L2.813 14l.868-5.064L0 5.35l5.086-.743L7.361 0z"
			/>
		</Svg>
	);
};

export default StarFilledIcon;
