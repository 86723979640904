import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View, Text, StyleSheet, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';
import { palettes } from '../../../config';
import { SUBSCRIBE_TO_EVENT } from '../../../graphql/eventSubscription/mutation';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { showLocksToUnInvitedUser } from '../../../redux/getEventSlice';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Lock2Icon } from '../../common/icons';

type TDimensions = {
	height: number;
	width: number;
};

type Props = {
	item: TGetAllEventCommon;
	lockIconDimensions: TDimensions;
	lockBackgroundDimension: TDimensions;
	hasText?: boolean;
	lockColor?: 'red' | 'white';
	isOnLiveEventPlayer?: boolean;
	showLocks?: boolean;
};

export const LiveEventLock = ({
	item,
	lockBackgroundDimension,
	lockIconDimensions,
	hasText = false,
	lockColor = 'white',
	isOnLiveEventPlayer = false,
	showLocks = false,
}: Props) => {
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const dispatch = useDispatch();
	const [
		subscribeToEvent,
		{ loading: subscribeLoading, data: subscribeData },
	] = useMutation(SUBSCRIBE_TO_EVENT, {
		variables: {
			affiliatedEvent: item?._id,
		},
	});

	useEffect(() => {
		if (subscribeData?.subscribeToEvent?.success) {
			setAlert('Subscribed to Event Successfully', 'normal');
			dispatch(showLocksToUnInvitedUser(false));
		} else if (subscribeData?.subscribeToEvent?.success === false) {
			setAlert('Unable to subscribe to event', 'normal');
		}
	}, [subscribeData]);
	return showLocks ? (
		<View
			style={[
				styles.container,
				isOnLiveEventPlayer &&
					Platform.OS === 'web' &&
					({
						backdropFilter: `blur(40px)`,
						backgroundColor: `${palettes.light[0]}10`,
					} as any),
				!isOnLiveEventPlayer &&
					Platform.OS === 'web' && {
						backgroundColor: 'transparent',
						backdropFilter: `blur(1px)`,
					},
			]}
		>
			<View
				style={[
					{
						width: getResponsiveStyle(
							lockBackgroundDimension.width,
							'dimensions'
						),
						height: getResponsiveStyle(
							lockBackgroundDimension.height,
							'dimensions'
						),
					},
					styles.iconContainer,
					isOnLiveEventPlayer &&
						Platform.OS === 'web' &&
						({
							backdropFilter: `blur(40px)`,
							backgroundColor: `${palettes.light[0]}10`,
						} as any),
					!isOnLiveEventPlayer &&
						Platform.OS === 'web' && {
							backgroundColor: palettes.light[0],
						},
				]}
			>
				<Lock2Icon
					width={getResponsiveStyle(
						lockIconDimensions.width,
						'dimensions'
					)}
					height={getResponsiveStyle(
						lockIconDimensions.height,
						'dimensions'
					)}
					color={
						lockColor === 'white'
							? palettes.light[0]
							: palettes.red[0]
					}
				/>
			</View>
			{hasText && (
				<>
					{/* <Text
						style={[
							styles.text,
							{
								marginBottom: getResponsiveStyle(8),
							},
						]}
					>
						{t('This is a private event!')}
					</Text> */}
					<Pressable onPress={() => subscribeToEvent()}>
						<Text style={styles.text}>
							{t(
								'This is a private event!, Would you like to send an invite request?'
							)}
						</Text>{' '}
					</Pressable>
				</>
			)}
		</View>
	) : null;
};

const styles = StyleSheet.create({
	text: {
		fontWeight: '600',
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		color: 'white',
	},
	iconContainer: {
		borderRadius: 9999,
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: getResponsiveStyle(22),
	},
	container: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		height: '100%',
		width: '100%',
		zIndex: 999999,
		alignItems: 'center',
		justifyContent: 'center',
	},
});
