import { useLazyQuery } from '@apollo/client';
import { GET_AFFILIATED_BRAND } from '../../graphql/brands/queries';

const useGetAffiliatedBrandEvents = (params: any) => {
	// const dispatch = useDispatch();
	const onCompleted = (data: any) => {};
	const [getAffiliatedBrandsEvent, { data, error, loading, networkStatus }] =
		useLazyQuery<TGetAllEventsResponse>(GET_AFFILIATED_BRAND, {
			notifyOnNetworkStatusChange: true,
			onCompleted: params?.onCompleted,
		});

	const executeAffiliatedBrandEvents = ({ brandId }: { brandId: string }) => {
		getAffiliatedBrandsEvent({
			variables: {
				brandId,
			},
		});
	};
	return {
		executeAffiliatedBrandEvents,
		data,
		error,
		loading,
		networkStatus,
	};
};

export default useGetAffiliatedBrandEvents;
