import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
// import Skeleton from './Skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import BrandProductItem from './brandProductItem';

type Prop = {
	isMobileView: boolean;
};
const BrandScreenLoader = ({ isMobileView }: Prop) => {
	return (
		<View style={[styles.brandRow, styleBrand(isMobileView)]}>
			{isMobileView ? (
				<View>
					<View style={[styles.brands, styleBrands(isMobileView)]}>
						<View style={styles.row}>
							<Skeleton
								width={getResponsiveStyle(50)}
								height={getResponsiveStyle(50)}
								style={{ borderRadius: 50 }}
							/>
							<Text
								style={[styles.text, styleText(isMobileView)]}
							>
								<Skeleton
									width={getResponsiveStyle(100)}
									height={getResponsiveStyle(10)}
									style={{ borderRadius: 6, marginTop: 10 }}
								/>
								<Skeleton
									width={getResponsiveStyle(130)}
									height={getResponsiveStyle(5)}
									style={{ borderRadius: 6 }}
								/>
								<Skeleton
									width={getResponsiveStyle(60)}
									height={getResponsiveStyle(5)}
									style={{ borderRadius: 6 }}
								/>
							</Text>
							<Skeleton
								width={getResponsiveStyle(100)}
								height={getResponsiveStyle(30)}
								style={{ borderRadius: 6 }}
							/>
						</View>

						<View style={styles.stats}>
							<Skeleton
								width={getResponsiveStyle(20)}
								height={getResponsiveStyle(20)}
								style={{ borderRadius: 6, marginTop: 6 }}
							/>
							<Skeleton
								width={getResponsiveStyle(20)}
								height={getResponsiveStyle(20)}
								style={{ borderRadius: 6, marginTop: 6 }}
							/>
							<Skeleton
								width={getResponsiveStyle(20)}
								height={getResponsiveStyle(20)}
								style={{ borderRadius: 6, marginTop: 6 }}
							/>
						</View>
					</View>
					<View style={styles.averagePrice}>
						<Skeleton
							width={getResponsiveStyle(100)}
							height={getResponsiveStyle(8)}
							style={{ borderRadius: 6 }}
						/>
					</View>
				</View>
			) : (
				<View style={[styles.brands, styleBrands(isMobileView)]}>
					<Skeleton
						width={getResponsiveStyle(65)}
						height={getResponsiveStyle(65)}
						style={{ borderRadius: 50, marginBottom: 20 }}
					/>
					<Text style={[styles.text, styleText(isMobileView)]}>
						<Skeleton
							width={getResponsiveStyle(100)}
							height={getResponsiveStyle(10)}
							style={{ borderRadius: 6, marginTop: 10 }}
						/>
						<Skeleton
							width={getResponsiveStyle(130)}
							height={getResponsiveStyle(5)}
							style={{ borderRadius: 6 }}
						/>
					</Text>
					<Skeleton
						width={getResponsiveStyle(60)}
						height={getResponsiveStyle(8)}
						style={{ borderRadius: 6 }}
					/>
					<View style={styles.stats}>
						<Skeleton
							width={getResponsiveStyle(20)}
							height={getResponsiveStyle(20)}
							style={{ borderRadius: 6, marginTop: 6 }}
						/>
						<Skeleton
							width={getResponsiveStyle(20)}
							height={getResponsiveStyle(20)}
							style={{ borderRadius: 6, marginTop: 6 }}
						/>
						<Skeleton
							width={getResponsiveStyle(20)}
							height={getResponsiveStyle(20)}
							style={{ borderRadius: 6, marginTop: 6 }}
						/>
					</View>
					<View style={styles.averagePrice}>
						<Skeleton
							width={getResponsiveStyle(100)}
							height={getResponsiveStyle(8)}
							style={{ borderRadius: 6 }}
						/>
					</View>
					<View style={styles.buttons}>
						<Skeleton
							width={getResponsiveStyle(100)}
							height={getResponsiveStyle(30)}
							style={{ borderRadius: 6 }}
						/>
						<Skeleton
							width={getResponsiveStyle(100)}
							height={getResponsiveStyle(30)}
							style={{ borderRadius: 6, marginTop: 6 }}
						/>
					</View>
				</View>
			)}

			<View style={styles.products}>
				<BrandProductItem />
				<BrandProductItem />
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	brands: {
		// width: 300,
		// height: 390,
		display: 'flex',
		flexDirection: 'column',
		marginRight: getResponsiveStyle(20),
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.09)',
		shadowOpacity: 1,
		paddingVertical: getResponsiveStyle(30),
	},
	brandRow: {
		display: 'flex',
	},
	row: {
		paddingHorizontal: getResponsiveStyle(10),
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-around',
	},
	stats: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginTop: 5,
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-around',
	},
	image: {
		borderRadius: 6,
	},
	text: {
		marginVertical: 3,
		width: '70%',
		display: 'flex',
		flexDirection: 'column',
		// justifyContent: 'center',
	},
	averagePrice: {
		marginVertical: 10,
	},

	productContainer: {
		marginRight: getResponsiveStyle(20),
	},
	products: {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(20),
	},
});

function styleBrand(isMobileView: boolean) {
	return {
		flexDirection: isMobileView ? 'column' : 'row',
		margin: isMobileView ? -5 : 0,
		marginBottom: getResponsiveStyle(40),
	};
}
function styleBrands(isMobileView: boolean) {
	return {
		width: isMobileView ? '100vw' : 300,
		height: isMobileView ? 130 : 390,
		alignItems: isMobileView ? 'space-around' : 'center',
		padding: isMobileView ? getResponsiveStyle(5) : getResponsiveStyle(10),
		marginVertical: getResponsiveStyle(10),
	};
}
function styleText(isMobileView: boolean) {
	return {
		justifyContent: isMobileView ? 'start' : 'center',
		alignItems: isMobileView ? 'start' : 'center',
		marginLeft: isMobileView ? getResponsiveStyle(5) : 0,
	};
}
export default BrandScreenLoader;
