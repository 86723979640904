import { addHoursToTime } from './addHoursToTime';

export const hasEventExpired = (
	hoursToAdd: number,
	date: Date,
	timeStamp: number
) => {
	const timeStampWithHoursAdded = addHoursToTime(hoursToAdd, date);
	return timeStampWithHoursAdded < +timeStamp;
};
