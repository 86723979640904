import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { useVerifyEmail } from '../api/auth/useVerifyEmail';
import LottieMark from '../assets/LottieFIles/LottieMark.json';
import { AuthTemplate, Button } from '../components';
import { useSetAlert } from '../hooks/useSetAlerts';
import { TUseNavigation, TUseRoute } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useDispatch } from 'react-redux';
import { setCurrentScreen } from '../redux/uiSlice';

const VerifyEmailScreen = () => {
	const navigation = useNavigation<TUseNavigation>();
	const { setAlert } = useSetAlert();
	const routes = useRoute<TUseRoute<'VerifyEmail'>>();

	const routeParam = routes.params;

	const { verifyEmail } = useVerifyEmail({
		onCompleted(data) {},
	});

	useEffect(() => {
		verifyEmail({
			uId: routeParam?.uid,
			vId: routeParam?.vid,
		});
	}, []);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setCurrentScreen('/verify-email'));
	}, []);
	return (
		<AuthTemplate
			heading={t('All set up!')}
			subText={t('Email verified successfully.')}
			scrollItems={[]}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
			hasGoBack={false}
		>
			<Lottie
				loop={false}
				animationData={LottieMark}
				play
				style={{ width: '100&', height: '45%' }}
			/>
			<Button
				title={t('Done')}
				outerContainerProps={{
					style: { marginTop: getResponsiveStyle(70) },
				}}
				variant="primary"
				size="lg"
				onPress={() => {
					navigation.navigate('Login');
				}}
			/>
		</AuthTemplate>
	);
};

export default VerifyEmailScreen;
