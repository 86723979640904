import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { dropdownHeight } from '../../../utils/dropdownHeight';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ToggleLine from './ToggleLine';
type Props = {
	filterArr?: TDropdownObject[];
	filterObj: TDropdownObject;
	setValue: (name: string, value: string) => void;
	value?: any;
};
const ToggleMobile = ({
	filterObj,
	filterArr = [],
	setValue,
	value,
}: Props) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const primary = usePrimaryColors();
	return (
		<>
			<View style={style.container}>
				<ToggleLine
					heading={filterObj.label}
					open={open}
					textStyle={{
						fontSize: getResponsiveStyle(18, 'font'),
						color: primary[0],
					}}
					viewStyle={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginRight: 0,
						paddingBottom: getResponsiveStyle(6),
					}}
					setOpen={setOpen}
				/>
				<Text style={style.valueContainer}>
					{filterArr.find(filter => filter.value === value)?.label ??
						value}
				</Text>
			</View>
			{open && (
				<View>
					<DropDownPicker
						setValue={() => {}}
						onSelectItem={(item: TDropdownObject) => {
							setValue(filterObj.value, item.value);
						}}
						value={null}
						items={filterArr}
						searchable={
							filterArr.length
								? filterObj.value.includes('countryCode')
								: true
						}
						dropDownDirection="BOTTOM"
						searchPlaceholder={`${t('Search...')}`}
						ListEmptyComponent={() => <></>}
						zIndex={2000}
						zIndexInverse={2000}
						listMode="FLATLIST"
						showTickIcon={false}
						disableBorderRadius={false}
						disableLocalSearch={true}
						searchTextInputProps={{
							...(!filterArr.length && {
								defaultValue: value?.toString(),
							}),
							style: {
								borderColor: primary[0],
								borderWidth: 1,
								margin: 0,
								marginBottom: 5,
								borderRadius: getResponsiveStyle(6),
								height: getResponsiveStyle(30),
								padding: getResponsiveStyle(6),
							},
						}}
						open={open}
						setOpen={setOpen}
						scrollViewProps={{
							showsVerticalScrollIndicator: false,
							showsHorizontalScrollIndicator: false,
						}}
						style={[
							{
								flexDirection: 'row',
								alignItems: 'center',
							},
						]}
						textStyle={[
							{
								fontSize: getResponsiveStyle(14, 'font'),
								lineHeight: getResponsiveStyle(14, 'font'),
								color: palettes.grey[0],
								marginRight: 4,
							},
						]}
						containerStyle={[
							{
								width: '100%',
								height: dropdownHeight(filterArr.length),
								padding: getResponsiveStyle(6),
								paddingTop: 0,
								borderRadius: 6,
							},
						]}
						placeholderStyle={{ display: 'none' }}
						dropDownContainerStyle={[
							{
								padding: getResponsiveStyle(6),
								paddingTop: 0,
								borderWidth: 0,
								width: '100%',
								marginLeft: getResponsiveStyle(-8),
								marginTop: getResponsiveStyle(1),
								zIndex: 5999,
							},
						]}
						onChangeSearchText={(text: string) => {
							!filterArr.length &&
								setValue(filterObj.value, text);
						}}
						listItemContainerStyle={[
							{
								paddingBottom: getResponsiveStyle(6),
								// height: getResponsiveStyle(25, 'dimensions')
								borderLeftColor: 'transparent',
							},
						]}
						listItemLabelStyle={{
							display: 'flex',
							alignItems: 'center',

							lineHeight: 30,
						}}
						selectedItemLabelStyle={{
							color: palettes.dark[0],
						}}
						ArrowDownIconComponent={() => <></>}
						ArrowUpIconComponent={() => <></>}
					/>
				</View>
			)}
		</>
	);
};
const style = StyleSheet.create({
	labelContainer: {
		fontSize: getResponsiveStyle(20, 'font'),
		fontWeight: 'bold',
	},
	valueContainer: {
		fontSize: getResponsiveStyle(10, 'font'),
		color: palettes.grey[0],
	},
	container: {
		padding: getResponsiveStyle(10),
		// borderColor: 'black',
		height: 'auto',
		// borderBottomWidth: 1,
		// backgroundColor: palettes.grey[11],
		marginBottom: getResponsiveStyle(6),
	},
});
export default ToggleMobile;
