import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_EVENT = gql`
	mutation subscribeToEvent($affiliatedEvent: ID!) {
		subscribeToEvent(affiliatedEvent: $affiliatedEvent) {
			... on Response {
				success
				statusCode
				message
			}
		}
	}
`;

export const UNSUBSCRIBE_FROM_EVENT = gql`
	mutation unSubscribeFromEvent($affiliatedEvent: ID!) {
		unSubscribeFromEvent(affiliatedEvent: $affiliatedEvent) {
			... on Response {
				success
				statusCode
				message
			}
		}
	}
`;

export const ACCEPT_OR_REJECT_SUBSCRIPTION = gql`
	mutation acceptOrRejectEventSubscription(
		$subscriptionId: String!
		$option: AcceptOrRejectOption!
	) {
		acceptOrRejectEventSubscription(
			subscriptionId: $subscriptionId
			option: $option
		) {
			... on Response {
				success
				statusCode
				message
			}
		}
	}
`;
