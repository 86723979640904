import { useLazyQuery } from '@apollo/client';
import { GET_RECOMMENDED_HOST } from '../../graphql/events/queries';
import { setRecommendedHost } from '../../redux/getEventSlice';
import { useAppDispatch } from '../../redux/store';

const useGetRecommendedHost = () => {
	const dispatch = useAppDispatch();

	const [getRecommendedHost, { data, error, loading }] = useLazyQuery(
		GET_RECOMMENDED_HOST,
		{
			onCompleted: data => {
				const recommendedHost = data?.getRecommendedHosts?.data?.data;
				if (recommendedHost?.length) {
					dispatch(setRecommendedHost(recommendedHost));
				}
			},
		}
	);

	const executeRecommendedHostQuery = ({
		catchmentAreas,
		limit,
		current,
	}: {
		catchmentAreas: CatchmentArea[];
		limit: number;
		current: number;
	}) => {
		getRecommendedHost({
			variables: {
				catchmentAreas,
				limit,
				current,
			},
		});
	};
	return { executeRecommendedHostQuery, data, error, loading };
};

export default useGetRecommendedHost;
