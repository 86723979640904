import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { Button, Loader } from '../..';
import { palettes } from '../../../config';
import { ACCEPT_OR_REJECT_SUBSCRIPTION } from '../../../graphql/eventSubscription/mutation';
import {
	ACCEPT_HOSTING_INVITATION,
	DELETE_NOTIFICATION,
	REJECT_HOSTING_INVITATION,
} from '../../../graphql/notification/mutation';
import { GET_ALL_NOTIFICATIONS } from '../../../graphql/notification/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import {
	TNotificationMessage,
	TypeOfNotification,
} from '../../../types/notificationTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { handleNavigationToEvent } from '../../../utils/handleNavigationToEvent';
import ProfileIcon from '../ProfileIcon';

const StyledClearText = styled(Text)`
	font-size: ${getResponsiveStyle(11)}px;
	line-height: ${getResponsiveStyle(11)}px;
	color: ${palettes.red[0]};
	cursor: pointer;
	padding-bottom: 2;
	border-bottom: 1px solid ${palettes.red[0]};
`;

const getSecondsFromDate = 1000;
const getMinuteFromDate = 60000;
const getHourFromDate = 3600000;
const getDayFromDate = 86400000;
const aDay = 24;
const aMinute = 60;

export const InvitationRequestNotification = ({
	notification,
	hovered,
	removeFromNotification,
}: {
	notification?: NotificationType;
	hovered?: boolean;
	removeFromNotification: (id: string) => void;
}) => {
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const primary = usePrimaryColors();

	const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
		variables: {
			args: {
				id: notification?._id,
			},
		},
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
	});
	const [btnSelected, setBtnSelected] = useState('');
	const [acceptEventHostingInvite, { loading: accepting }] = useMutation(
		ACCEPT_HOSTING_INVITATION,
		{
			onCompleted: data => {
				if (
					data &&
					data.acceptEventHostingInvitation.success &&
					data.acceptEventHostingInvitation.statusCode === 200
				) {
					setAlert(
						t('Invitation to host event accepted successfully'),
						'normal'
					);
				} else if (
					data &&
					!data.acceptEventHostingInvitation.success &&
					data.acceptEventHostingInvitation.message ===
						t('An appointed host already exists for this event!')
				) {
					setAlert(t('An host already exists'), 'danger');
				}

				deleteNotification();
			},
		}
	);

	const [rejectEventHostingInvite, { loading: rejecting }] = useMutation(
		REJECT_HOSTING_INVITATION,
		{
			variables: {
				args: {
					_id: notification?.eventHostingId,
				},
			},
			onCompleted: () => {
				setAlert(t('Invitation to host event rejected'), 'danger');
				deleteNotification();
			},
		}
	);

	const [acceptOrRejectSubscription, { loading: acceptOrRejectSubLoading }] =
		useMutation(ACCEPT_OR_REJECT_SUBSCRIPTION, {
			onCompleted: data => {
				if (!data?.acceptOrRejectEventSubscription?.success) {
					setAlert(
						data?.acceptOrRejectEventSubscription?.message,
						'danger'
					);
				} else {
					setAlert(t('Response sent successfully'), 'normal');
					setBtnSelected('');
					removeFromNotification(notification?._id);
					deleteNotification();
				}
			},
		});

	const navigation = useNavigation<TUseNavigation>();
	const dispatch = useAppDispatch();
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	const event: any = {
		_id: notification?.event?._id,
		typeOfEvent: notification?.event?.typeOfEvent,
		isHostApplicationAccepted:
			notification?.event?.isHostApplicationAccepted,
		startingEventDateTime: notification?.event?.startingEventDateTime,
		eventStreamTimeline: notification?.event?.eventStreamTimeline,
	};
	const handleNavigation = () => {
		handleNavigationToEvent({
			dispatch,
			navigation,
			loggedInUserDetails,
			event,
		});
	};

	const ref = useRef(null);
	const isHovered = useHover(ref);

	const [deleteNotificationAllNotifications, { loading }] = useMutation(
		DELETE_NOTIFICATION,
		{
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
			onCompleted: data => {
				// if (data && data.deleteNotification_.success) {
				// 	setAlert(t('Notification successfully deleted'), 'normal');
				// } else {
				// 	setAlert(t('Could not delete notification'), 'danger');
				// }
			},
			onError: () => {
				setAlert(t('Could not delete notification'), 'danger');
			},
		}
	);
	// if (loading) {
	// 	return <Loader size={'small'} />;
	// }

	return (
		<View style={[styles.container, styleContainer()]} ref={ref}>
			<>
				<ProfileIcon
					style={styleProfileIcon()}
					imageSrc={
						notification?.sentBy?.personalInformation
							?.profileImageLink
					}
				/>
				<View style={{ flex: 1 }}>
					<View
						style={[
							styles.detailsContainer,
							styleDetailsContainer(),
						]}
					>
						<Text
							style={[
								styles.name,
								styleName(),
								isHovered && { width: 200 },
							]}
							numberOfLines={isHovered && 1}
						>
							{notification?.sentBy?.username}
						</Text>
						{isHovered && (
							<TouchableOpacity
								onPress={() => {
									removeFromNotification(
										notification?._id ?? ''
									);
									deleteNotificationAllNotifications({
										variables: {
											args: {
												id: notification?._id,
											},
										},
									});
								}}
							>
								<StyledClearText>{t('Clear')}</StyledClearText>
							</TouchableOpacity>
						)}
						{/* {isHovered && <StyledClearText>Clear</StyledClearText>} */}
					</View>
					<Text
						style={[styles.invitationText, styleInvitationText()]}
					>
						{notification?.notificationType ===
						TypeOfNotification.EVENT_SUBSCRIPTION_NOTIFICATION ? (
							<Text>
								{notification.message ===
								TNotificationMessage.EVENT_SUBSCRIPTION_NOTIFICATION_HOST
									? notification?.sentBy?.username +
									  ' ' +
									  t(
											'has just requested to host your event'
									  ) +
									  ' '
									: notification.message ===
									  TNotificationMessage.EVENT_SUBSCRIPTION_NOTIFICATION_UNHOST
									? notification?.sentBy?.username +
									  ' ' +
									  t('no longer wants to host your event') +
									  ' '
									: notification.message ===
									  TNotificationMessage.EVENT_SUBSCRIPTION_NOTIFICATION_JOIN
									? notification?.sentBy?.username +
									  ' ' +
									  t(
											'has just requested to join your event'
									  ) +
									  ' '
									: notification.message ===
											TNotificationMessage.EVENT_SUBSCRIPTION_NOTIFICATION_UNJOIN &&
									  notification?.sentBy?.username +
											' ' +
											t(
												'no longer wants to join your event'
											) +
											' '}
								<Text
									style={{
										color: primary[0],
									}}
								>
									{notification.eventId?.name}
								</Text>
							</Text>
						) : (
							<Text>
								{t('has invited you to host') + ' '}
								<Text
									style={{
										color: primary[0],
										textDecorationLine: 'underline',
									}}
									onPress={() => handleNavigation()}
								>
									{notification?.message}
								</Text>
							</Text>
						)}
					</Text>

					{Math.floor(
						(Date.now() - Number(notification?.createdAt)) /
							getSecondsFromDate
					) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getSecondsFromDate
							)}
							{''}s {t('ago')}
						</Text>
					) : Math.floor(
							(Date.now() - Number(notification?.createdAt)) /
								getMinuteFromDate
					  ) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getMinuteFromDate
							)}
							{''}m {t('ago')}
						</Text>
					) : Math.floor(
							(Date.now() - Number(notification?.createdAt)) /
								getHourFromDate
					  ) < aDay ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getHourFromDate
							)}
							{''}h {t('ago')}
						</Text>
					) : (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getDayFromDate
							)}{' '}
							{t('days ago')}
						</Text>
					)}

					<View style={styles.actionContainer}>
						<>
							<Button
								title={
									accepting ? (
										<Loader size={'small'} />
									) : (
										t('Accept')
									)
								}
								variant={rejecting ? 'disabled' : 'grey'}
								size="sm"
								outerContainerProps={{
									style: styles.acceptButton,
								}}
								loading={
									btnSelected === 'ACCEPT' &&
									acceptOrRejectSubLoading
								}
								onPress={() => {
									notification?.notificationType ===
									'EVENT_HOSTING_NOTIFICATION'
										? acceptEventHostingInvite({
												variables: {
													args: {
														_id: notification?.eventHostingId,
													},
												},
										  })
										: acceptOrRejectSubscription({
												variables: {
													subscriptionId:
														notification?.subscriptionId,
													option: 'ACCEPT',
												},
										  });
									setBtnSelected('ACCEPT');
								}}
							/>
							<Button
								title={
									rejecting ? (
										<Loader size={'small'} />
									) : (
										t('Reject')
									)
								}
								variant={accepting ? 'disabled' : 'outline'}
								size="sm"
								loading={
									btnSelected === 'REJECT' &&
									acceptOrRejectSubLoading
								}
								outlineColor={palettes.red[0]}
								outerContainerProps={{
									style: styles.rejectButton,
								}}
								onPress={() => {
									notification?.notificationType ===
									'EVENT_HOSTING_NOTIFICATION'
										? rejectEventHostingInvite()
										: acceptOrRejectSubscription({
												variables: {
													subscriptionId:
														notification?.subscriptionId,
													option: 'REJECT',
												},
										  });
									setBtnSelected('REJECT');
								}}
							/>
						</>
					</View>
				</View>
			</>
		</View>
	);
};

const styles = StyleSheet.create({
	rejectButton: {
		flex: 0.75,
	},
	acceptButton: {
		flex: 1,
	},
	eventDate: {
		color: palettes.grey[2],
		marginTop: 6,
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(10),
	},
	actionContainer: {
		flexDirection: 'row',
	},
	invitationText: {
		color: palettes.grey[0],
	},
	name: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
	detailsContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		flexDirection: 'row',
		width: '100%',
		borderLeftWidth: 3,
		borderLeftColor: 'transparent',
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
});

function styleInvitationText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleName() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
}

function styleDetailsContainer() {
	return {
		marginBottom: getResponsiveStyle(8),
	};
}

function styleProfileIcon() {
	return {
		width: getResponsiveStyle(56, 'dimensions'),
		height: getResponsiveStyle(56, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingBottom: getResponsiveStyle(15),
		paddingTop: getResponsiveStyle(15),
	};
}
