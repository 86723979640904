import * as React from 'react';
import Svg, {
	SvgProps,
	Path,
	Defs,
	LinearGradient,
	Stop,
} from 'react-native-svg';

const CalendarIconFilled = (props: SvgProps) => (
	<Svg fill="none" viewBox="0 0 24 24" {...props}>
		<Path
			d="M1.987 3.571c.61-.444 1.347-.674 2.35-.793v.836a1.446 1.446 0 0 0 2.892 0v-.96c.447-.003.928-.003 1.446-.003.517 0 .998 0 1.445.002v.961a1.446 1.446 0 0 0 2.892 0v-.836c1.004.12 1.74.35 2.35.793.41.297.77.657 1.067 1.066.544.749.766 1.686.857 3.074H.063C.154 6.323.376 5.386.92 4.637a4.82 4.82 0 0 1 1.067-1.066Z"
			fill="url(#a)"
		/>
		<Path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M.01 9.156C0 9.798 0 10.516 0 11.326c0 3.614 0 5.42.92 6.687.298.41.657.77 1.067 1.067C3.253 20 5.06 20 8.675 20c.808 0 1.526 0 2.168-.01v-.068c0-.821 0-1.321.069-1.753a5.542 5.542 0 0 1 4.607-4.607c.431-.068.931-.068 1.752-.068h.068c.01-.642.01-1.36.01-2.169 0-.808 0-1.527-.01-2.169H.01Zm5.773 2.41a.723.723 0 1 0 0 1.446h5.783a.723.723 0 1 0 0-1.446H5.783Z"
			fill="url(#b)"
		/>
		<Path
			d="M15.363 19.08c-.749.544-1.686.766-3.074.857 0-.877.004-1.244.05-1.542a4.096 4.096 0 0 1 3.406-3.405c.297-.047.664-.05 1.541-.05-.09 1.387-.313 2.325-.857 3.073-.297.41-.657.77-1.066 1.067Z"
			fill="url(#c)"
		/>
		<Path
			d="M6.506.723a.723.723 0 1 0-1.446 0v2.891a.723.723 0 0 0 1.446 0V.723Z"
			fill="url(#d)"
		/>
		<Path
			d="M12.29.723a.723.723 0 0 0-1.447 0v2.891a.723.723 0 1 0 1.446 0V.723Z"
			fill="url(#e)"
		/>
		<Defs>
			<LinearGradient
				id="a"
				x1={2.829}
				y1={5}
				x2={21.741}
				y2={19.676}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="b"
				x1={2.829}
				y1={5}
				x2={21.741}
				y2={19.676}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="c"
				x1={2.829}
				y1={5}
				x2={21.741}
				y2={19.676}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="d"
				x1={2.829}
				y1={5}
				x2={21.741}
				y2={19.676}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="e"
				x1={2.829}
				y1={5}
				x2={21.741}
				y2={19.676}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
		</Defs>
	</Svg>
);

export default CalendarIconFilled;
