import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text } from 'react-native';
import { palettes } from '../../../../../config';
import { getResponsiveStyle } from '../../../../../utils/getResponsiveStyle';

type Props = {
	item: { id: number; label: string; screen: string };
	selected?: boolean;
	onPress: (screen: string) => void;
};

export const TopNavigationItem = ({
	item: { id, label, screen },
	selected = false,
	onPress,
}: Props) => {
	const { t } = useTranslation();
	return (
		<Pressable onPress={() => onPress(screen)}>
			<Text
				style={[styleText(), styles.text, selected && styles.selected]}
			>
				{t(label)}
			</Text>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	text: {
		color: palettes.grey[1],
		textAlign: 'center',
		borderRadius: 6,
		fontWeight: '600',
	},
	selected: {
		backgroundColor: palettes.blue[1],
		color: palettes.dark[0],
	},
});
function styleText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		paddingLeft: getResponsiveStyle(8),
		paddingRight: getResponsiveStyle(11),
		paddingVertical: getResponsiveStyle(6),
		marginRight: getResponsiveStyle(6),
	};
}
