import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	paddingTop?: number;
	paddingBottom?: number;
	paddingY?: number;
	paddingX?: number;
	padding?: number;
};

const HorizontalDivider = ({
	padding,
	paddingBottom,
	paddingTop,
	paddingX,
	paddingY,
}: Props) => {
	return (
		<View
			style={[
				stylePadding(padding),
				stylePaddingBottom(paddingBottom),
				stylePaddingTop(paddingTop),
				stylePaddingY(paddingY),
				stylePaddingX(paddingX),
			]}
		>
			<View style={styles.common} />
		</View>
	);
};
const styles = StyleSheet.create({
	common: {
		borderBottomColor: palettes.grey[5],
		width: '100%',
		height: 1,
		borderBottomWidth: 1,
	},
});

export default HorizontalDivider;
function stylePaddingX(paddingX: number | undefined) {
	return (
		!!paddingX && {
			paddingHorizontal: getResponsiveStyle(paddingX),
		}
	);
}

function stylePaddingY(paddingY: number | undefined) {
	return (
		!!paddingY && {
			paddingVertical: getResponsiveStyle(paddingY),
		}
	);
}

function stylePaddingTop(paddingTop: number | undefined) {
	return (
		!!paddingTop && {
			paddingTop: getResponsiveStyle(paddingTop),
		}
	);
}

function stylePaddingBottom(paddingBottom: number | undefined) {
	return (
		!!paddingBottom && {
			paddingBottom: getResponsiveStyle(paddingBottom),
		}
	);
}

function stylePadding(padding: number | undefined) {
	return (
		!!padding && {
			padding: getResponsiveStyle(padding),
		}
	);
}
