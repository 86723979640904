import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	stat: number;
	label: string;
	containerStyles?: TStyle;
};

const CompanyCardStats = ({ label, stat, containerStyles }: Props) => {
	const { isDesktop } = useIsSpecificViewportWidth();
	return (
		<View style={[styles.container, styleContainer(), containerStyles]}>
			<Text
				style={[
					styles.text,
					isDesktop ? styles.statDesktop : styles.statMobile,
					isDesktop ? styleStatDesktop() : styleStatMobile(),
				]}
			>
				{stat ? stat : 0}
			</Text>
			<Text style={[styles.text, styles.label, styleLabel()]}>
				{label}
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
	},
	text: {
		fontWeight: '400',
		color: palettes.dark[0],
		textAlign: 'center',
	},
	label: {
		color: palettes.grey[0],

		// textTransform: 'uppercase',
	},
	statDesktop: {
		fontWeight: '600',
	},
	statMobile: {
		fontWeight: '800',
	},
});

function styleContainer() {
	return {
		paddingBottom: getResponsiveStyle(11),
	};
}

function styleLabel() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
	};
}

function styleStatDesktop(): TStyleInputOrText {
	return {
		fontSize: getResponsiveStyle(24, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: 7,
	};
}

function styleStatMobile(): TStyleInputOrText {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		marginBottom: 7,
	};
}

export default CompanyCardStats;
