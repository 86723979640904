import React from 'react';
import { View, StyleSheet, useWindowDimensions, Text } from 'react-native';
import { palettes } from '../../../config';
import PlusIcon from '../PlusIcon';

const PlaceholderEvent = () => {
	const dimensions = useWindowDimensions();
	return (
		<View style={[styles.container, { height: dimensions.height * 0.15 }]}>
			<PlusIcon height={14} width={14} />
			<Text style={styles.text}>Create New Event</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		borderStyle: 'dashed',
		borderWidth: 2,
		borderRadius: 6,
		borderColor: palettes.grey[5],
		backgroundColor: palettes.grey[6],
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		marginBottom: 15,
		minHeight: 136,
	},
	text: {
		marginTop: 19,
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[1],
		borderBottomColor: palettes.grey[1],
		borderBottomWidth: 1,
		paddingBottom: 2,
	},
});

export default PlaceholderEvent;
