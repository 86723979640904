import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import InviteAddUserIcon from '../icons/InviteAddUserIcon';

const InviteAll = ({
	InviteAllFollowersByType,
	text,
	disabled,
}: {
	InviteAllFollowersByType: () => void;
	text: string | undefined;
	disabled?: boolean;
}) => {
	return (
		<TouchableOpacity
			disabled={disabled}
			onPress={() => InviteAllFollowersByType()}
		>
			<View style={styles.container}>
				<View style={styles.iconContainer}>
					<InviteAddUserIcon />
				</View>
				<Text
					style={[
						styles.text,
						disabled && { color: palettes.grey[3] },
					]}
				>
					{text}
				</Text>
			</View>
		</TouchableOpacity>
	);
};

export default InviteAll;

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		// marginBottom: 12,
		paddingTop: getResponsiveStyle(12),
		paddingRight: getResponsiveStyle(28),
		paddingLeft: getResponsiveStyle(28),
		paddingBottom: getResponsiveStyle(12),
	},
	iconContainer: {
		backgroundColor: '#F2F4F6',
		height: getResponsiveStyle(56),
		width: getResponsiveStyle(56),
		marginRight: 12,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center',
	},
	text: {
		color: '#4285F4',
		fontSize: getResponsiveStyle(14),
		fontWeight: '400',
	},
	disabled: {
		borderColor: palettes.grey[3],
		backgroundColor: palettes.grey[9],
	},
});
