import { NetworkStatus, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { NotAvailableMessage } from '../..';
import { GET_ALL_STARTER_KITS } from '../../../graphql/brands/queries';
import { usePagination } from '../../../hooks/useCurrentForPagination';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import LoadMore from '../../common/load-more/LoadMore';
import Loader from '../../common/loader/Loader';
import { StarterKitProduct } from './StarterKitProduct';

type Props = {
	brandId: string;
};
const LIMIT = 6;
export const StarterKitProducts = ({ brandId }: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { t } = useTranslation();
	const [starterKits, setStarterKits] = useState<TGetAllStarterKitsData[]>(
		[]
	);

	const [currentPage, getNextPage] = usePagination();

	const [totalStarterKits, setTotalStarterKits] = useState(0);

	const [
		getStarterKits,
		{
			refetch: refetchStarterKits,
			networkStatus,
			loading: loadingStarterKits,
		},
	] = useLazyQuery<TGetAllStarterKitsResponse, TGetAllStarterKitsInputs>(
		GET_ALL_STARTER_KITS,
		{
			// notifyOnNetworkStatusChange: true,
			onCompleted: data => {
				if (
					data?.getAllStarterKits?.data &&
					data?.getAllStarterKits?.success
				) {
					setTotalStarterKits(data.getAllStarterKits.data.total);
					setStarterKits([
						...starterKits,
						...data.getAllStarterKits.data.data.filter(
							startervalue =>
								startervalue._id !=
								starterKits.find(
									starterkit =>
										starterkit._id === startervalue._id
								)?._id
						),
					]);
				} else {
					setStarterKits([]);
				}
			},
			onError: () => {
				setStarterKits([]);
			},
		}
	);

	useEffect(() => {
		getStarterKits({
			variables: {
				limit: LIMIT,
				current: currentPage,
				find: {
					brand: brandId,
				},
			},
		});
	}, []);

	return (
		<View style={{ paddingBottom: 50 }}>
			{loadingStarterKits ? (
				<Loader />
			) : starterKits && starterKits.length > 0 ? (
				<View
					style={{
						flexDirection: 'column',
					}}
				>
					<View
						style={[
							styles.starterKitsContainer,
							{
								justifyContent: isLessThanDesktopBase
									? 'center'
									: 'flex-start',
							},
						]}
					>
						{starterKits.map(starterKit => (
							<StarterKitProduct
								key={starterKit._id}
								brand={brandId}
								{...starterKit}
							/>
						))}
					</View>
					{currentPage * LIMIT < totalStarterKits && (
						<LoadMore
							onClick={() => {
								const nextPage = getNextPage();

								refetchStarterKits({
									current: nextPage,
								});
							}}
							loading={
								networkStatus === NetworkStatus.setVariables
							}
						/>
					)}
				</View>
			) : (
				<NotAvailableMessage
					message={t('This brand has no starter kits!')}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	starterKitsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		width: `calc(100% + ${getResponsiveStyle(16)}px)`,
	},
});
