import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_CART } from '../graphql/cart/queries';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setUsersCart } from '../redux/userSlice';

export const useGetCart = () => {
	const dispatch = useAppDispatch();
	const usersCart = useAppSelector(state => state.user.cart);
	const [cart, setCart] = useState<Cart>();
	const [getCart, { loading: gettingCartLoading }] = useLazyQuery(GET_CART, {
		onCompleted: data => {
			if (data !== null) {
				if (data.getCart !== null) {
					if (data.getCart.data !== null) {
						let cart: Cart = [];
						data?.getCart?.data?.data.map((item: TODO) => {
							cart.push(item.items);
						});
						setCart(cart);
						dispatch(setUsersCart(cart));
					} else {
						dispatch(setUsersCart([]));
					}
				}
			}
		},
	});

	return { getCart, cart, gettingCartLoading };
};
