import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TAddButtonProps } from '../../common/event-card/CardTop';
import Loader from '../../common/loader/Loader';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import { AllEventsDesktop, AllEventsDesktopProps } from './AllEventsDesktop';
import { AllEventsMobile } from './AllEventsMobile';

type Props = {
	loading?: boolean;
	totalEventsToRender?: number | undefined;
	addButtonProps?: TAddButtonProps;
} & AllEventsDesktopProps;

const AllEventsSection = ({
	eventsToRender: events,
	onPressViewMore,
	totalEventsToRender,
	loading = false,
	addButtonProps,
	refetchLoading = false,
}: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { t } = useTranslation();

	return (
		<>
			{isLessThanDesktopBase ? (
				events ? (
					events.length > 0 ? (
						<AllEventsMobile
							onPressViewMore={onPressViewMore}
							eventsToRender={events}
							addButtonProps={addButtonProps}
							refetchLoading={refetchLoading}
							totalEventsToRender={totalEventsToRender}
						/>
					) : (
						<NotAvailableMessage message={t('No event found')} />
					)
				) : (
					<></>
				)
			) : events ? (
				events.length > 0 ? (
					<AllEventsDesktop
						eventsToRender={events}
						onPressViewMore={onPressViewMore}
						refetchLoading={refetchLoading}
						totalEventsToRender={totalEventsToRender}
						addButtonProps={addButtonProps}
					/>
				) : (
					<NotAvailableMessage message={t('No event found')} />
				)
			) : (
				// <AllEventsDesktop EventsToRender={events} />
				<></>
			)}
			<View style={{ alignItems: 'center' }}>
				{loading && <Loader />}
			</View>
		</>
	);
};

export default AllEventsSection;
