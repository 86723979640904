import React from 'react';
import { StyleSheet, View } from 'react-native';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { PlaceholderUserIcon } from '../icons';
import { PlaceholderUserIconProps } from '../icons/PlaceholderUser';

type Props = {
	containerStyles?: TStyle;
	iconProps?: PlaceholderUserIconProps;
};

const UserImagePlaceholder = ({ containerStyles, iconProps }: Props) => {
	return (
		<View style={[styles.container, containerStyles]}>
			<PlaceholderUserIcon
				outlineColor={palettes.grey[2]}
				height={getResponsiveStyle(24, 'dimensions')}
				width={getResponsiveStyle(24, 'dimensions')}
				{...iconProps}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: getResponsiveStyle(48, 'dimensions'),
		height: getResponsiveStyle(48, 'dimensions'),
		backgroundColor: palettes.grey[6],
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 9999,
	},
});

export default UserImagePlaceholder;
