import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Profile, Ratings } from '../..';
import { palettes } from '../../../config';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Flag from '../../common/flag/Flag';
import { ActionButtons } from './ActionButtons';
import { EventAttendedStat } from './EventAttendedStat';
import { HostStatCard } from './HostStatCard';
import { ProfileImageWithGender } from './ProfileImageWithGender';
import { useTranslation } from 'react-i18next';
import useGetEntityRating from '../../../api/rating/useGetEntityRating';
const BRAND_STATS = [
	{
		label: 'Followers',
		stat: 447,
	},
	{
		label: 'Products',
		stat: '180+',
	},
	{
		label: 'Events',
		stat: 12,
	},
];

type Props = {
	containerStyles?: TStyle;
	showMessageButton?: boolean;
	isShowViewProfileBtn?: boolean;
	hasActionBtn?: boolean;
	hasRating?: boolean;
	hasEventAttendedOnly?: boolean;
	isUserCard?: boolean;
	isHost?: boolean;
	isSearch?: boolean;
	isInvited?: boolean;
	hasStats?: boolean;
	showGender?: boolean;
	item?: TGetAllUsersData;
	isBrand?: boolean;
	onClickHostFollowMeActionButton?: (
		id: string,
		hasRequestBeenSent?: boolean,
		index?: number
		// isFollowingMe: boolean
	) => void;
	// onClickHostFollowMeActionButton?: (id: string) => void;
	hasFollowHostRequestSent?: boolean;
	type?: string;
	hasUnfollowRequestBeenSent?: boolean;
	// followMeUnfollowMeButtonPressedId?: string;
	loadingFollowMe?: boolean;
	isFollowingMe?: boolean;
	index?: number;
	onPressFollowButton?: (id: number) => void;
	isFollowingArray?: TIsFollowingData[];
	loadingFollowUser?: boolean;
	followUnfollowButtonPressedId?: string;
	eventAttendedCountByUser?: number;
};

export const ProfileCard = ({
	containerStyles,
	isShowViewProfileBtn = true,
	showMessageButton = true,
	isHost = false,
	hasActionBtn,
	hasStats = true,
	isSearch = false,
	hasEventAttendedOnly,
	hasRating,
	isUserCard,
	isInvited = false,
	isBrand = false,
	showGender = true,
	item,
	onClickHostFollowMeActionButton,
	hasFollowHostRequestSent = false,
	type,
	hasUnfollowRequestBeenSent,
	// followMeUnfollowMeButtonPressedId,
	loadingFollowMe,
	isFollowingMe,
	index,
	onPressFollowButton,
	isFollowingArray,
	loadingFollowUser,
	followUnfollowButtonPressedId,
	eventAttendedCountByUser,
}: Props) => {
	const { t } = useTranslation();
	const HOST_STATS = [
		{
			label: t('Followers'),
			stat: item?.followersCount || 0,
		},
		{
			label: t('Events Attended'),
			stat: eventAttendedCountByUser ? eventAttendedCountByUser : 0,
		},
	];

	let isFollowing = false;
	let loading: boolean | undefined = false;
	if (isFollowingArray !== undefined) {
		isFollowing =
			isFollowingArray.find(item_ => item_.userId === item?._id)
				?.isFollowing ?? false;

		loading =
			followUnfollowButtonPressedId === item?._id && loadingFollowUser;
	}
	const { executeEntityRatingQuery, data: profileRating } =
		useGetEntityRating();

	useEffect(() => {
		executeEntityRatingQuery({
			entityId: item?._id as string,
		});
	}, []);

	const rating = profileRating?.getAverageRating?.data?.averageEntityRating;

	return (
		<View style={[styles.container, styleContainer(), containerStyles]}>
			{!isBrand ? (
				<>
					<ProfileImageWithGender
						item={item}
						gender={item?.gender}
						showGender={showGender}
					/>
					<Text style={[styles.name, styleName()]}>
						{item?.username}
					</Text>
					<Text style={[styles.location, styleLocation()]}>
						<Text style={styles.flexAddressContainer}>
							<Flag
								countryCode={
									item?.personalInformation?.address
										?.countryCode
								}
							/>{' '}
							{item?.personalInformation?.address?.city}
							{','}{' '}
							{item?.personalInformation?.address?.countryLabel}
						</Text>
						{/* <DotIcon style={styleDot()} />
						{item?.personalInformation?.address?.zipCode} */}
					</Text>
					{isUserCard && (
						<>
							<Text style={[styles.location, styleLocation()]}>
								<Text style={styles.flexAddressContainer}>
									{item?.personalInformation?.address?.city}{' '}
									<Flag
										countryCode={
											item?.personalInformation?.address
												?.countryCode
										}
									/>
								</Text>
								{/* <DotIcon style={styleDot()} />
						{item?.personalInformation?.address?.zipCode} */}
							</Text>
						</>
					)}
				</>
			) : (
				<>
					<View>
						<Profile
							name="Unilever"
							profileImage="http://placekitten.com/500"
							isBrandProfile={true}
						/>
					</View>
				</>
			)}
			{
				<View style={{ height: 35 }}>
					<Ratings ratings={rating} />
				</View>
			}
			{hasStats && (
				<>
					{hasEventAttendedOnly && (
						<EventAttendedStat
							stat={eventAttendedCountByUser || 0}
						/>
					)}
					{/* {isHost && <Ratings ratings={rating || 0} />} */}

					{!hasEventAttendedOnly && (
						<HostStatCard stats={HOST_STATS} />
					)}
					{isBrand && <HostStatCard stats={BRAND_STATS} />}
				</>
			)}

			<ActionButtons
				isHost={isHost}
				isSearch={isSearch}
				hasActionBtn={hasActionBtn}
				isInvited={isInvited}
				userId={item?._id}
				onClickHostFollowMeActionButton={
					onClickHostFollowMeActionButton
				}
				hasFollowHostRequestSent={hasFollowHostRequestSent}
				type={type}
				hasUnfollowRequestBeenSent={hasUnfollowRequestBeenSent}
				loadingFollowMe={loadingFollowMe}
				isFollowingMe={isFollowingMe}
				index={index}
				onPressFollowButton={onPressFollowButton}
				isFollowing={isFollowing}
				loadingFollowUser={loading}
				isShowViewProfileBtn={isShowViewProfileBtn}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	flexAddressContainer: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: getResponsiveStyle(5, 'font'),
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		width: 'max-content',
		marginBottom: getResponsiveStyle(10),
		marginTop: getResponsiveStyle(2),
		marginLeft: getResponsiveStyle(10),
		marginRight: getResponsiveStyle(1),
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.09)',
		shadowOpacity: 1,
	},
	name: {
		fontWeight: '600',

		textAlign: 'center',
		color: palettes.dark[0],
		marginBottom: 1,
	},
	profileHandle: {
		fontWeight: '400',
		color: palettes.grey[0],
		textAlign: 'center',
		marginBottom: 1,
	},
	dotIcon: {},
	location: {
		color: palettes.dark[4],
		paddingBottom: 11,
		textAlign: 'center',
	},
	eventsAttended: {
		textAlign: 'center',
		color: palettes.grey[0],
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
	eventsAttendedStat: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
});

const styleEventsAttended = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		paddingTop: getResponsiveStyle(10),
		marginBottom: getResponsiveStyle(18),
	};
};

const styleDot = () => {
	return {
		marginBottom: getResponsiveStyle(2),
		marginRight: getResponsiveStyle(3),
	};
};

const styleLocation = (): TStyleInputOrText => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		textTransform: 'capitalize',
	};
};

const styleProfileHandle = () => {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
};

const styleName = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginTop: getResponsiveStyle(13),
	};
};

const styleContainer = () => {
	return {
		// marginRight: getResponsiveStyle(17),
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(24),
		minWidth: getResponsiveStyle(300, 'dimensions'),
	};
};
