import { gql } from '@apollo/client';
import { USER_RESPONSE_FRAGMENT } from './fragments';

const CREATE_USER_MUTATION = gql`
	${USER_RESPONSE_FRAGMENT}

	mutation createUser($args: UserInput) {
		createUser(args: $args) {
			...UserResponseFragment
		}
	}
`;

const CREATE_ANONYMOUS_USER_MUTATION = gql`
	mutation createAnonymousUser($args: AnonymousUserInput) {
		createAnonymousUser(args: $args) {
			statusCode
			success
			message
			data {
				... on AnonymousUser {
					_id
					email
					stripeCustomerInfo {
						customerId
						name
						email
						created
						invoice_prefix
					}
				}
			}
		}
	}
`;

const VERIFY_EMAIL = gql`
	mutation verifyEmail($uId: String, $vId: String) {
		verifyEmail(uid: $uId, vid: $vId) {
			statusCode
			success
			message
		}
	}
`;

const SIGN_IN = gql`
	${USER_RESPONSE_FRAGMENT}
	mutation signIn($email: String, $password: String) {
		login(email: $email, password: $password) {
			...UserResponseFragment
		}
	}
`;

const CREATE_PASSWORD_LINK = gql`
	${USER_RESPONSE_FRAGMENT}
	mutation createPasswordLink($email: String) {
		createPasswordLink(email: $email) {
			...UserResponseFragment
		}
	}
`;

const CREATE_NEW_PASSWORD = gql`
	${USER_RESPONSE_FRAGMENT}
	mutation newPassword($password: String, $token: String) {
		newPassword(password: $password, token: $token) {
			...UserResponseFragment
		}
	}
`;

const CHANGE_PASSWORD = gql`
	mutation changePassword($oldPassword: String!, $newPassword: String!) {
		changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

const GOOGLE_AUTH = gql`
	mutation googleAuth(
		$token: String!
		$isCompleteSignUpFromBackend: Boolean
	) {
		googleAuth(
			token: $token
			isCompleteSignUpFromBackend: $isCompleteSignUpFromBackend
		) {
			statusCode
			success
			message
			data {
				... on SocialAuth {
					newAccount
					user {
						_id
						typeOfAccount
						username
						email
						isVerified
						website
						followers
						gender
						attendedEvents
						companyLegalName
						companyRegistrationNumber
						vat
						creditCardInformation {
							IBAN
							bankName
							city
						}
						personalInformation {
							phoneNumber
							interestTags
							favouriteCategories
							description
							profileImageLink
							firstName
							lastName
							address {
								city
								countryCode
								countryLabel
								address1
								address2
								zipCode
							}
						}
						connectAccountInfo {
							connectAccountId
						}
					}
				}
			}
		}
	}
`;

const FACEBOOK_AUTH = gql`
	mutation facebookAuth(
		$token: String!
		$isCompleteSignUpFromBackend: Boolean
	) {
		facebookAuth(
			token: $token
			isCompleteSignUpFromBackend: $isCompleteSignUpFromBackend
		) {
			statusCode
			success
			message
			data {
				... on SocialAuth {
					newAccount
					user {
						_id
						typeOfAccount
						username
						email
						isVerified
						website
						followers
						gender
						attendedEvents
						companyLegalName
						companyRegistrationNumber
						vat
						creditCardInformation {
							IBAN
							bankName
							city
						}
						personalInformation {
							phoneNumber
							interestTags
							favouriteCategories
							description
							profileImageLink
							firstName
							lastName
							address {
								city
								countryCode
								countryLabel
								address1
								address2
								zipCode
							}
						}
						connectAccountInfo {
							connectAccountId
						}
					}
				}
			}
		}
	}
`;

export {
	CREATE_USER_MUTATION,
	CREATE_ANONYMOUS_USER_MUTATION,
	SIGN_IN,
	CREATE_PASSWORD_LINK,
	CREATE_NEW_PASSWORD,
	CHANGE_PASSWORD,
	GOOGLE_AUTH,
	FACEBOOK_AUTH,
	VERIFY_EMAIL,
};
