import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { DesktopNavigationBar, MobileNavigationBarTop } from '../components';
import ChatBox from '../components/chat/chat-box/ChatBox';
import Contacts from '../components/chat/contacts/Contacts';
import ProfileDetail from '../components/chat/profile-detail/ProfileDetail';
import MobileChatHeading from '../components/common/templates/MobileChatHeading';
import { palettes } from '../config';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { setCurrentChat } from '../redux/chatSlice';
import { useAppSelector } from '../redux/store';
import { TStyle } from '../types/TStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const ChatScreen = () => {
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [user, setUser] = useState<string | undefined>('');

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/chat'));
	}, []);

	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const chat = useAppSelector(state => state.chat.chat);

	const SECTION_TABS = [
		{ label: t('People'), value: 'People' },
		{ label: t('Chat'), value: 'Chat' },
		{ label: t('Info'), value: 'Info' },
	];

	const [selectedTab, setSelectedTab] = React.useState<string>(
		SECTION_TABS[0].value
	);

	useEffect(() => {
		setUser(loggedInUserDetails?._id);
	}, [loggedInUserDetails?._id]);

	const onPress = (tab: string) => {
		if (tab !== 'Chat') {
			dispatch(setCurrentChat({} as ChatContactProps));
		}
		setSelectedTab(tab);
	};

	return shouldHideScreen ? null : (
		<>
			<View style={styles.container}>
				{isLessThanDesktopBase && (
					<View style={styles.mobile}>
						<MobileNavigationBarTop
							containerStyles={styleNavMobile(true)}
							hasBackButton={true}
							hasIcons={chat.contactPerson?._id ? false : true}
							heading={
								chat.contactPerson?._id ? (
									<MobileChatHeading
										chat={chat}
										tab={selectedTab}
										onPress={setSelectedTab}
									/>
								) : undefined
							}
						/>

						{/* <View style={{ marginTop: -15, marginBottom: 15 }}>
							<ChatTab
								tabs={SECTION_TABS}
								selectedTab={selectedTab}
								onPress={onPress}
								center={false}
							/>
						</View> */}
					</View>
				)}
				{!isLessThanDesktopBase && (
					<View
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							paddingTop: 32,
							zIndex: 5000,
						}}
					>
						{!isLessThanDesktopBase && (
							<DesktopNavigationBar
								containerStyles={styleNavDesktop(true)}
								hasSearch={true}
							/>
						)}
					</View>
				)}
				<View
					style={{
						flexDirection: 'row',
						paddingLeft: !isLessThanDesktopBase ? 16 : 0,
						height: `calc(100% - ${
							isLessThanDesktopBase ? '140px' : '155px'
						})`,
						zIndex: -9999,
					}}
				>
					{!isLessThanDesktopBase && (
						<>
							<Contacts userId={user} />
							<ChatBox
								loggedInUserDetails={loggedInUserDetails}
							/>
							<ProfileDetail />
						</>
					)}
					{isLessThanDesktopBase &&
						selectedTab === SECTION_TABS[0].value &&
						!chat.contactPerson?._id && <Contacts userId={user} />}
					{isLessThanDesktopBase &&
						chat.contactPerson?._id &&
						selectedTab !== SECTION_TABS[2].value && (
							<ChatBox
								loggedInUserDetails={loggedInUserDetails}
							/>
						)}
					{isLessThanDesktopBase &&
						selectedTab === SECTION_TABS[2].value &&
						chat.contactPerson?._id && <ProfileDetail />}
				</View>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		width: '100vw',
		height: '100vh',
	},
	mobile: {
		paddingTop: 20,
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 80,
	},
	image: {
		width: '100%',
		height: '480px',
		resizeMode: 'cover',
		borderRadius: 6,
	},
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		// marginTop: 40,
	},
	content: {
		flex: 1,
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},

	sidebar: {
		// width: 312,
		flexBasis: 312,
		flexGrow: 0,
		flexShrink: 0,
		// flex: "0 0 312px"
	},
});

export default ChatScreen;

const styleNavMobile = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 16,
	};
};

const styleNavDesktop = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 40,
	};
};
