import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Image,
	ListRenderItem,
	Platform,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import { Button, DraggableScrollView, Ratings } from '../..';
import { palettes } from '../../../config';
import { CREATE_CART } from '../../../graphql/cart/mutation';
import { GET_CART } from '../../../graphql/cart/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { twoDp } from '../../../utils/twoDP';
import { setUsersCart } from '../../../redux/userSlice';
import ModalComponent from '../../common/modal/ModalComponent';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { shortenName } from '../../../utils/manipulateString';

export const StarterKitProduct = ({
	_id,
	name,
	description,
	price,
	variants,
	rating,
	compareAtPrice,
	inventory,
	brand,
}: TGetAllStarterKitsData) => {
	const primary = usePrimaryColors();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [openState, setOpenState] = useState(false);
	const variantImages = useMemo(() => {
		return variants.map(variant => variant.image).flat();
	}, [variants]);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	// const { data: cartData, loading: cartLoading } = useQuery(GET_CART);
	const usersCart = useAppSelector(state => state.user.cart);
	const productBrand = brand?._id ?? brand;
	const brandInCartArray = usersCart.map(
		item =>
			item?.productId?.associatedProduct?.brand?._id ??
			item?.starterkitId?.brand?._id
	);
	const brandInCart = new Set(brandInCartArray);
	const [createCart, { data: createData, loading: createLoading }] =
		useMutation(CREATE_CART);
	const [getUpdatedCart] = useLazyQuery(GET_CART, {
		onCompleted: data => {
			let cart: Cart = [];
			data?.getCart?.data?.data.map((item: TODO) => {
				cart.push(item.items);
			});
			dispatch(setUsersCart(cart));
		},
	});
	const { setAlert } = useSetAlert();

	const isItemOutOfStock = +inventory?.quantity <= 0;

	const buyNowText = isItemOutOfStock ? t('Out of stock') : t('Buy Now');

	const buttonType = isItemOutOfStock ? 'disabled' : 'outline';

	const mutateCart = () => {
		if (isItemOutOfStock) {
			setAlert(t('Out of stock'), 'danger');
			return;
		}
		if (brandInCart.size === 0 || brandInCart.has(productBrand)) {
			createCart({
				variables: {
					args: {
						items: {
							quantity: 1,
							starterkitId: _id,
							type: 'StarterKit',
							price,
						},
					},
				},
				// awaitRefetchQueries: true,
				// refetchQueries: [{ query: GET_CART }],
				onCompleted: data => {
					if (data.createCart.success) {
						getUpdatedCart();
						// dispatch(setUsersCart(usersCart.concat(data.createCart.data.items)))
						setAlert(t('Item Added to Cart'), 'normal');
					} else {
						setAlert(t('Unable to add item to cart'), 'danger');
					}
				},
				onError: () => {
					setAlert(t('Unable to add item to cart'), 'danger');
				},
			});
		} else {
			setAlert(
				t('You cannot add items of different brands to cart'),
				'danger'
			);
		}
	};
	let inCart = false;
	usersCart.map(starterkit => {
		if (
			starterkit.type === 'StarterKit' &&
			starterkit.starterkitId._id === _id
		) {
			inCart = true;
		}
	});
	const renderItem: ListRenderItem<TProductImage> = ({ item }) => {
		return (
			<Image
				source={{ uri: item.src }}
				style={[styleImage(), styles.image]}
			/>
		);
	};
	return (
		<View style={[styles.container, styleContainer()]}>
			<Pressable onPress={() => setOpenState(true)}>
				<View style={[styles.cardHeader, styleCardHeader()]}>
					<Text style={[styles.kitName, styleKitName()]}>{name}</Text>
					{rating && (
						<Ratings
							ratings={rating || 0}
							containerStyles={styles.ratings}
						/>
					)}
				</View>
				<Text
					numberOfLines={2}
					style={[styles.kitDescription, styleKitDescription()]}
				>
					{description}
				</Text>
				<View style={[styles.imagesContainer, styleImagesContainer()]}>
					<DraggableScrollView
						data={variantImages}
						showsHorizontalScrollIndicator={false}
						renderItem={renderItem as any}
					/>
				</View>
				<View style={styles.footerContainer}>
					<Text style={[styles.kitPriceText, styleKitPriceText()]}>
						{t('Starter Kit')} /{' '}
						<Text style={{ color: primary?.[0] }}>
							{getSymbolFromCurrency('eur')}
							{twoDp(price)}
						</Text>
					</Text>
					{inCart ? (
						<Button
							title={`${t('In Cart')}`}
							disabled={true}
							variant="outline"
							size="sm"
						/>
					) : !inCart && createLoading ? (
						// <Text>Adding to cart...</Text>
						<Button
							title={`${t('Adding to cart...')}`}
							variant="outline"
							size="sm"
						/>
					) : (
						!inCart && (
							<Button
								title={`${buyNowText}`}
								variant={buttonType}
								size="sm"
								onPress={() => mutateCart()}
							/>
						)
					)}
				</View>
			</Pressable>
			<ModalComponent
				onClose={() => setOpenState(false)}
				header={t(`Starter Kit View`)}
				openState={openState}
				type={isLessThanDesktopBase ? 'fullpage' : 'sidebar'}
				isProductModal
			>
				<View style={{ height: '80vh' }}>
					<ScrollView style={{ height: '40%', paddingBottom: 40 }}>
						<View style={styles.modalImage}>
							<DraggableScrollView
								data={variantImages}
								showsHorizontalScrollIndicator={false}
								renderItem={renderItem as any}
							/>
						</View>
						<View style={styles.price}>
							<Text style={styles.startingPrice}>
								{getSymbolFromCurrency('eur')}
								{twoDp(compareAtPrice)}
							</Text>

							<Text style={styles.discountPrice}>
								{getSymbolFromCurrency('eur')}
								{twoDp(price)}
							</Text>
						</View>
						<View>
							<Text style={styles.shortDesc}>{name || ''}</Text>

							<View style={{ flexDirection: 'column' }}>
								{variants?.map((variant: any) => (
									<View style={{ flexDirection: 'column' }}>
										<Text style={styles.title}>
											{isLessThanDesktopBase
												? shortenName(
														variant
															?.associatedProduct
															?.name,
														30
												  )
												: variant?.associatedProduct
														?.name}
										</Text>
										<View
											style={{
												flexDirection: 'row',
												justifyContent: 'space-between',
											}}
										>
											<Image
												style={{
													width: 110,
													height: 120,
													marginRight: 10,
													borderRadius: 6,
												}}
												source={{
													uri: variant?.image?.src,
												}}
											/>
											<Text style={styles.discountPrice}>
												{getSymbolFromCurrency('eur')}
												{twoDp(
													variant?.option?.values[0]
														?.price
												)}
											</Text>
										</View>
									</View>
								))}
							</View>
							<Text style={styles.title}>DESCRIPTION</Text>
							<ScrollView
								style={{ overflow: 'scroll' }}
								showsHorizontalScrollIndicator={false}
							>
								<Text style={styles.desc}>{description}</Text>
							</ScrollView>
						</View>
					</ScrollView>
					<View
						style={[
							{
								// flex: 1,
								position: 'absolute',
								width: '100%',
								backgroundColor: '#fff',
							},
							Platform.select({
								native: {
									bottom: 15,
									left: 0,
								},
								default: {
									bottom: 15,
									left: 0,
								},
							}),
						]}
					>
						{inCart ? (
							<Button
								title={`${t('In Cart')}`}
								disabled={true}
								variant="outline"
							/>
						) : !inCart && createLoading ? (
							<Button
								title={`${t('Adding to cart...')}`}
								variant="outline"
								size="sm"
							/>
						) : (
							!inCart && (
								<Button
									title={`${buyNowText}`}
									variant={buttonType}
									onPress={() => mutateCart()}
								/>
							)
						)}
					</View>
				</View>
			</ModalComponent>
		</View>
	);
};

const styles = StyleSheet.create({
	kitPriceText: {
		color: palettes.grey[0],
		fontWeight: '600',
	},
	footerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	image: {
		borderRadius: 6,
		overflow: 'hidden',
	},
	imagesContainer: {
		overflow: 'hidden',
		borderRadius: 6,
	},
	kitDescription: {
		color: palettes.dark[2],
	},
	ratings: {
		paddingBottom: 0,
	},
	kitName: {
		color: palettes.dark[0],
		fontWeight: '700',
	},
	cardHeader: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	container: {
		borderRadius: 6,
		borderWidth: 1,
		borderColor: palettes.grey[5],
		backgroundColor: 'white',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
		flex: 1,
	},
	title: {
		fontSize: 18,
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
		marginBottom: 5,
		marginTop: 20,
	},
	shortDesc: {
		fontWeight: '600',
		fontSize: 22,
		fontStyle: 'normal',
		lineHeight: 20,
		color: palettes.dark[0],
	},
	desc: {
		fontSize: 14,
		fontWeight: 'normal',
		color: palettes.dark[4],
		lineHeight: 24,
		marginBottom: 15,
	},
	price: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 15,
		marginBottom: 20,
	},
	startingPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#7C889B',
		marginRight: 10,
		textDecorationLine: 'line-through',
	},
	modalImage: {
		width: '100%',
		borderRadius: 4,
		marginBottom: getResponsiveStyle(10),
	},
	priceContainer: {
		flexDirection: 'row',
	},
	discountPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#F96152',
	},
});
function styleKitPriceText() {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
}

function styleImage() {
	return {
		width: getResponsiveStyle(300, 'dimensions'),
		height: getResponsiveStyle(260, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleImagesContainer() {
	return {
		marginBottom: getResponsiveStyle(16),
	};
}

function styleKitDescription() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
		marginBottom: getResponsiveStyle(14),
		height: getResponsiveStyle(44, 'dimensions'),
	};
}

function styleKitName() {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
}

function styleCardHeader() {
	return {
		marginBottom: getResponsiveStyle(15),
	};
}

function styleContainer(): TStyle {
	return {
		minWidth: getResponsiveStyle(400, 'dimensions'),
		maxWidth: getResponsiveStyle(400, 'dimensions'),
		padding: getResponsiveStyle(25),
		marginRight: getResponsiveStyle(16),
		marginBottom: getResponsiveStyle(16),
	};
}
