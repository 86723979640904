import { useMutation } from '@apollo/client';
import { DELETE_STATUS } from '../../graphql/status/mutation';

const useDeleteStatus = () => {
	const [deleteStatus, states] = useMutation(DELETE_STATUS);

	const executeDeleteStatusMutation = ({ _id }: { _id: string }) => {
		deleteStatus({
			variables: {
				_id,
			},
		});
	};
	return { executeDeleteStatusMutation, ...states };
};

export default useDeleteStatus;
