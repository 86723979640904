import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatListProps, Pressable, StyleSheet, Text, View } from 'react-native';
import { DraggableScrollView, EventCard, SectionHeading } from '../..';
import { breakpoints } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { TAddButtonProps } from '../../common/event-card/CardTop';
import Loader from '../../common/loader/Loader';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import EventSmallCardLoader from '../../common/skeleton-loaders/event-loaders/EventSmallCardLoader';

type Props = {
	heading: string;
	hasViewAllLabel?: boolean;
	hasBorderBottom?: boolean;
	containerStyle?: TStyle;
	events?: TGetAllEventsData[];
	loading?: boolean;
	type?: 'time' | 'published';
	onPressViewAll?: () => void;
	isSubscribedArray?: isSubscribedResponse[];
	addButtonProps?: TAddButtonProps;
	onEndReached?: FlatListProps<unknown>['onEndReached'];
	hasScrollbar?: boolean;
};

type TRenderItemParams = {
	isLessThanDesktopBase: boolean;
	events: TGetAllEventsData[];
	index: number;
	item: any;
	type?: 'time' | 'published';
	canSubscribe?: boolean;
	isSubscribed?: boolean;
	addButtonProps?: TAddButtonProps;
	heading?: string;
};

const renderItem = ({
	events,
	index,
	isLessThanDesktopBase,
	item,
	addButtonProps,
	type,
	canSubscribe,
	isSubscribed,
	heading,
}: TRenderItemParams) => (
	<EventCard
		size="small"
		containerStyles={styleEventCardContainer(
			isLessThanDesktopBase,
			events,
			index
		)}
		item={item}
		type={type}
		// canSubscribe={canSubscribe}
		heading={heading}
		isSubscribed={isSubscribed}
		addButtonProps={addButtonProps}
	/>
);

const ScrollEventSection = ({
	hasViewAllLabel = false,
	hasBorderBottom = false,
	loading = false,
	heading,
	containerStyle,
	events,
	type,
	onPressViewAll,
	isSubscribedArray,
	addButtonProps,
	onEndReached,
	hasScrollbar,
}: Props) => {
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { t } = useTranslation();

	return (
		<View style={styleContainer(containerStyle, width)}>
			<>
				<SectionHeading
					heading={heading}
					hasViewAllLabel={hasViewAllLabel}
					hasBorderBottom={hasBorderBottom}
					onPressViewAll={onPressViewAll}
					stylesObject={{
						headingStyles: {
							fontWeight: '700',
						},
					}}
				/>
				{loading ? (
					<DraggableScrollView
						horizontal
						showsHorizontalScrollIndicator={false}
						style={styleDraggableScroll(isLessThanDesktopBase)}
						data={[1, 2, 3]}
						renderItem={({
							item,
							index,
						}: {
							item: TGetAllEventCommon;
							index: number;
						}) => (
							<EventSmallCardLoader
								size="small"
								containerStyles={styleEventCardContainer(
									isLessThanDesktopBase,
									item,
									1
								)}
							/>
						)}
					/>
				) : // <Loader
				// 	containerStyles={{
				// 		margin: getResponsiveStyle(20),
				// 	}}
				// />
				events && events.length > 0 ? (
					<Pressable>
						<DraggableScrollView
							horizontal
							showsHorizontalScrollIndicator={false}
							style={styleDraggableScroll(isLessThanDesktopBase)}
							data={events}
							onEndReached={onEndReached}
							renderItem={({
								item,
								index,
							}: {
								item: TGetAllEventCommon;
								index: number;
							}) =>
								renderItem({
									isLessThanDesktopBase,
									events,
									index,
									item,
									type,
									// canSubscribe: canSub,
									isSubscribed: isSubscribedArray?.find(
										data => data.eventId === item._id
									)?.isSubscribed,
									addButtonProps,
									heading,
								})
							}
							hasScrollbar={hasScrollbar}
						/>
					</Pressable>
				) : (
					<NotAvailableMessage message={t('No event found')} />
				)}
			</>
		</View>
	);
};

const getResponsiveMarginBottom = (width: number) => {
	const desktopMarginBottom = getResponsiveStyle(81);
	const mobileMarginBottom = getResponsiveStyle(33);
	return width >= breakpoints.desktopBase
		? desktopMarginBottom
		: mobileMarginBottom;
};
const styleDraggableScroll = (isLessThanDesktopBase: boolean): TStyle => {
	return [
		styles.eventRow,
		{
			width: `calc(100% + ${
				isLessThanDesktopBase
					? getResponsiveStyle(16)
					: getResponsiveStyle(32)
			}px)`,
		},
	];
};

const styleEventCardContainer = (
	isLessThanDesktopBase: boolean,
	ToRender: TGetAllEventsData[],
	index: number
): TStyle => {
	return {
		width: getResponsiveStyle(320, 'dimensions'),
		marginRight:
			isLessThanDesktopBase || ToRender.length - 1 !== index
				? getResponsiveStyle(16)
				: getResponsiveStyle(32),
		marginBottom: getResponsiveStyle(8),
		maxWidth: '100%',
	} as TStyle;
};

const styleContainer = (containerStyle: TStyle, width: number): TStyle => {
	return [
		{
			marginBottom: getResponsiveMarginBottom(width),
		},
		containerStyle,
	];
};

const styles = StyleSheet.create({
	eventRow: {
		flexDirection: 'row',
		maxWidth: 'max-content',
	},
});

export default ScrollEventSection;
