// import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { NotAvailableMessage, SectionHeading } from '../..';
import { useGetAllBrands } from '../../../api/brands/useGetAllBrands';
import { useGetBrandStats } from '../../../api/brands/useGetBrandStats';
import { useFollow } from '../../../api/follow/useFollow';
import { TStyle } from '../../../types/TStyle';
import { isLastArrElement } from '../../../utils/isLastArrElement';
import Profile from '../../common/profile/Profile';
import ProfileListCardSkeleton from '../../common/skeleton-loaders/profile-card/ProfileListCardSkeleton';

const NumberOfProfiles = [0, 1, 2, 3, 4, 6, 7, 8, 9, 10];

type Props = {
	heading: string;
	profileToRender?: number;
	isBrandProfile?: boolean;
	hasAddIcon?: boolean;
	time?: string;
	items?: any[];
	loading?: boolean;
	section?: string;
	onPressSeeAll?: () => void;
	isFollowingArray: any[];
	onClickFollowMeActionButton?: (
		recipientId: string,
		hasRequestBeenSent: boolean,
		index: number
	) => void;
	onClickUnFollowActionButton: (id: string) => void;
	followMeUnfollowMeButtonPressedId?: string;
	loadingFollowMe?: boolean;
	setSelected?: (userId: string) => void;
	requestSendBooleanArray?: TIsFollowMeRequestData[];
	isFollowingMeArray?: TODO[];
	loadingUnFollowMe?: boolean;
};

const ProfileList = ({
	time,
	onPressSeeAll,
	profileToRender = 5,
	items,
	heading,
	section,
	isBrandProfile = false,
	hasAddIcon = false,
	loading = false,
	isFollowingArray,
	onClickFollowMeActionButton,
	onClickUnFollowActionButton,
	followMeUnfollowMeButtonPressedId,
	loadingFollowMe,
	setSelected,
	requestSendBooleanArray,
	isFollowingMeArray,
	loadingUnFollowMe,
}: Props) => {
	const onCompletedGetAllBrands = (data: TGetAllBrandsResponse) => {};

	const { data: allBrandsData, loading: allBrandsLoading } = useGetAllBrands({
		onCompleted: onCompletedGetAllBrands,
	});
	const allBrands = allBrandsData?.getAllBrands?.data?.data;

	const { getBrandStats, data: brandStats } = useGetBrandStats({
		onCompleted: (data: any) => {},
	});

	const [isBrandFollowed, setIsBrandFollowed] = useState(false);

	const brandStatsArray = brandStats?.getBrandStats?.data?.data;

	/****************** API ********************** */

	/****************** START - API - useFollow ********************** */
	const onCompletedFollowUser: TOnCompleteApiCall<TFollowResponse> = data => {
		if (data.Follow.success && data.Follow.statusCode === 200) {
			setIsBrandFollowed(true);
		}
	};
	const { followUser } = useFollow({
		onCompleted: onCompletedFollowUser,
	});

	const rrr = isFollowingArray;
	const { t } = useTranslation();

	return (
		<View style={styles.container}>
			<SectionHeading
				heading={heading}
				hasSeeAllLabel
				onPressSeeAll={onPressSeeAll}
			/>
			{/* <AddHostPlaceholder /> */}

			{loading ? (
				// <Loader />
				<>
					<ProfileListCardSkeleton />
					<ProfileListCardSkeleton />
					<ProfileListCardSkeleton />
					<ProfileListCardSkeleton />
				</>
			) : items && items.length > 0 ? (
				items?.slice(0, profileToRender).map((item, index) => (
					<Profile
						brandDetails={
							isBrandProfile && {
								address: item.address,
								name: item.name,
								profileImage: item.profileImage,
								additionalMedia: item.additionalMedia,
								rating: item.rating,
								description: item.description,
								products: item.products,
								brandStatsArray: brandStatsArray,
								followUser: followUser,
							}
						}
						key={index}
						index={index}
						id={item._id}
						name={isBrandProfile ? item.name : item.username}
						city={
							isBrandProfile
								? item.address?.city
								: item.personalInformation?.address?.city
						}
						profileImage={
							isBrandProfile
								? item.profileImage
								: item.personalInformation?.profileImageLink
						}
						isBrandProfile={isBrandProfile}
						hasAddIcon={hasAddIcon}
						countryCode={
							isBrandProfile
								? item.address?.countryCode
								: item.personalInformation?.address?.countryCode
						}
						time={time}
						containerStyles={styleProfileContainer(
							index,
							profileToRender
						)}
						country={
							isBrandProfile
								? item.address?.countryCode
								: item.personalInformation?.address?.countryCode
						}
						countryLabel={
							isBrandProfile
								? item.address?.countryLabel
								: item.personalInformation?.address
										?.countryLabel
						}
						isFollowing={
							rrr?.find(i => i.userId === item._id)?.isFollowing
						}
						isGuest={
							heading === t('Guests selected for you')
								? true
								: false
						}
						followMeUnfollowMeButtonPressedId={
							followMeUnfollowMeButtonPressedId
						}
						onClickFollowMeActionButton={
							onClickFollowMeActionButton
						}
						onClickUnFollowActionButton={
							onClickUnFollowActionButton
						}
						loadingFollowMe={loadingFollowMe}
						loadingUnFollowMe={loadingUnFollowMe}
						// setSelected={setSelectednow}
						requestSendBooleanArray={requestSendBooleanArray}
						isFollowingMeArray={isFollowingMeArray}
					/>
				))
			) : section === 'Host' ? (
				<NotAvailableMessage message={t('No Host found')} />
			) : section === 'Brand' ? (
				<NotAvailableMessage message={t('No Brand found')} />
			) : (
				<NotAvailableMessage message={t('No Profile found')} />
			)}
		</View>
	);
};

const styleProfileContainer = (
	index: number,
	profileToRender: number
): TStyle => {
	return {
		marginBottom: isLastArrElement(
			NumberOfProfiles.slice(0, profileToRender),
			index
		)
			? 0
			: 16,
	};
};

const styles = StyleSheet.create({
	container: {
		marginBottom: 30,
	},
});

export default ProfileList;
