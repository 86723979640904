import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { UserSelectButton } from './UserSelectButton';

const UserSelect = (props: {
	onSubmit: (userType: TTypeOfAccount) => void;
}) => {
	const { t } = useTranslation();
	return (
		<View style={{ marginBottom: getResponsiveStyle(46) }}>
			<UserSelectButton
				title={t('Guest')}
				text={t('Looking to indulge in events and buy products?')}
				onSubmit={props.onSubmit}
				value="CONSUMER"
			/>

			<UserSelectButton
				title={t('Seller')}
				text={t('Looking to market products?')}
				onSubmit={props.onSubmit}
				value="SELLER"
			/>

			<UserSelectButton
				title={t('Host')}
				text={t('Looking to host online or physical events?')}
				onSubmit={props.onSubmit}
				value="HOST"
			/>
		</View>
	);
};

export default UserSelect;
