import { useAppSelector } from '../../redux/store';

// type TReturnType = [
// 	current: number,
// 	increaseCurrentByOne: () => number,
// 	setCurrent: React.Dispatch<React.SetStateAction<number>>
// ];

export const useGetCartItems = () => {
    const { visitorCartStore } = useAppSelector(state => state.user);
    const { isUserLoggedIn } = useAppSelector(state => state.auth);
    const cart = useAppSelector(state => state.user.cart);
    const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

    let brandsInCart: any = {};

    let brandInCartName: string[] = [];

    let brandInCartIds: string[] = [];

    let items: any[] = []

    usersCart.map(product => {
        if (product.type === 'Product') {
            if (
                Object.keys(brandsInCart).includes(
                    product.productId?.associatedProduct?.brand?._id
                )
            ) {
                brandsInCart[
                    product.productId?.associatedProduct?.brand
                        ?._id as keyof typeof brandsInCart
                ] += 1;
                // brandCounts += 1
            } else {
                brandsInCart[
                    product.productId?.associatedProduct?.brand
                        ?._id as keyof typeof brandsInCart
                ] = 1;
                brandInCartName.push(
                    product.productId?.associatedProduct?.brand?.name
                );
                brandInCartIds.push(
                    product.productId?.associatedProduct?.brand?._id
                );
            }
            items.push({
                productName: product?.productId?.associatedProduct?.name,
                brandName: product?.productId?.associatedProduct?.brand?.name,
                itemType: 'PRODUCT',
                variant: product?.productId?._id,
                starterKit: null,
                quantity: product?.quantity,
                affiliatedHost: product?.eventId?.affiliatedHosts[0]?._id,
                selfHosted: !product?.eventId?.isHostRequired,
                affiliatedSellerConnectId:
                    product?.eventId?.affiliatedSeller?.connectAccountInfo
                        ?.connectAccountId,
                price: product.price,
                brand: product.productId.associatedProduct?.brand?._id,
                brandConnectId:
                    product?.productId?.associatedProduct?.brand
                        ?.connectAccountInfo?.connectAccountId,
                affiliatedSellerId: product.eventId?.affiliatedSeller?._id,
                vat: product?.productId?.vat,
                eventName: product?.eventId?.name,
                optionValue: product.optionValue,
                variantType: product?.variantType,
                associatedVariantType: product?.associatedVariantType
            });
        } else {
            if (
                Object.keys(brandsInCart).includes(
                    product.starterkitId.brand._id
                )
            ) {
                brandsInCart[
                    product.starterkitId.brand._id as keyof typeof brandsInCart
                ] += 1;
                // brandCounts += 1
            } else {
                brandsInCart[
                    product.starterkitId.brand._id as keyof typeof brandsInCart
                ] = 1;
                brandInCartName.push(product.starterkitId.brand.name);
            }
        }
    });

    return {
        items,
        brandInCartIds,
        brandsInCart
    };
};
