import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { SectionHeading } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { StarterKitProducts } from './StarterKitProducts';

type Props = {
	brandId: string;
};

const StarterKit = ({ brandId }: Props) => {
	const { t } = useTranslation();
	return (
		<View style={styleContainer()}>
			<SectionHeading heading={t('Starter Kit')} size="large" />
			<StarterKitProducts brandId={brandId} />
		</View>
	);
};

export default StarterKit;
function styleContainer() {
	return { paddingTop: getResponsiveStyle(32) };
}
