import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	product: object;
	chat: ChatContactProps;
	liveEventAudience: RoomData[];
	unReadMessages: UnreadMessages[];
	contacts: ChatContactProps[];
};

const initialState: TInitialState = {
	product: {},
	chat: {} as ChatContactProps,
	liveEventAudience: [] as RoomData[],
	unReadMessages: [] as UnreadMessages[],
	contacts: [] as ChatContactProps[],
};

const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		setCurrentChatProduct(state, action: PayloadAction<object>) {
			state.product = action.payload;
		},
		setCurrentChat(state, action: PayloadAction<ChatContactProps>) {
			state.chat = action.payload;
		},
		setLiveEventAudience(state, action: PayloadAction<RoomData[]>) {
			state.liveEventAudience = action.payload;
		},
		setUnReadMessages(state, action: PayloadAction<UnreadMessages[]>) {
			state.unReadMessages = action.payload;
		},
		setContactsGlobal(state, action: PayloadAction<ChatContactProps[]>) {
			state.contacts = action.payload;
		},
	},
});

export const {
	setCurrentChatProduct,
	setCurrentChat,
	setLiveEventAudience,
	setUnReadMessages,
	setContactsGlobal,
} = chatSlice.actions;

export default chatSlice.reducer;
