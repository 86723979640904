import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Button } from '../..';
import useDeleteEvent from '../../../api/events/useDeleteEvent';
import { palettes } from '../../../config';
import { setTypeOfEvent } from '../../../redux/createEventSlice';
import { setIsEditEvent } from '../../../redux/getEventSlice';
import { useAppSelector } from '../../../redux/store';
import {
	setCreateEventSidebarValues,
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import DateComponent from '../dateComponent/DateComponent';
import DeleteModal from '../../common/delete-modal/DeleteModal';
import AsyncStorage from '@react-native-community/async-storage';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useMutation } from '@apollo/client';
import {
	SUBSCRIBE_TO_EVENT,
	UNSUBSCRIBE_FROM_EVENT,
} from '../../../graphql/eventSubscription/mutation';
import { IS_SUBSCRIBED_TO_EVENTS } from '../../../graphql/eventSubscription/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import DeleteIcon from '../../common/icons/DeleteIcon';
import PencilWritingIcon from '../../common/icons/PencilWritingIcon';
import { styles } from '../../common/page-details-hero/profileDetailsHero.styles';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import dayjs from 'dayjs';
import { shortenName } from '../../../utils/manipulateString';
import ShareArrowIcon from '../../common/icons/ShareArrowIcon';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

type Props = {
	title: string;
	subtitle: string;
	startDate: Date | string;
	endDate: Date | string;
	eventEnded: () => boolean;
	eventId?: string;
	isSubscribed?: boolean;
};

function DescriptionHeader({
	title,
	subtitle,
	startDate,
	eventEnded,
	eventId,
	isSubscribed,
}: Props) {
	const { t } = useTranslation();
	const [deleteModal, setDeleteModal] = useState(false);
	const [checked, setChecked] = useState<boolean>(false);
	const [reminderChecked, setReminderChecked] = useState('');
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));
	const { isMobileView } = useIsSpecificViewportWidth();
	const handleIsCheckEventDelete = async () => {
		await AsyncStorage.setItem('isCheckEventDelete', 'true');
	};

	const { setAlert } = useSetAlert();

	const event = useAppSelector(state => state.getEvent.event);

	const { executeDeleteEventMutation, loading, data } = useDeleteEvent();

	const dispatch = useDispatch();

	const primary = usePrimaryColors();

	const navigation = useNavigation<TUseNavigation>();

	const handleEditEvent = () => {
		dispatch(setTypeOfEvent(event.typeOfEvent));
		if (event.typeOfEvent) {
			dispatch(setCreateEventSidebarValues(event.typeOfEvent));
			dispatch(setIsEditEvent(true));
			navigation.navigate('EventDetail');
		}
	};

	const handleDeleteEvent = () => {
		executeDeleteEventMutation({
			_id: event._id,
		});
		if (checked) handleIsCheckEventDelete();
	};

	useEffect(() => {
		if (data?.deleteEvent?.success) {
			navigation.navigate('Home');
			setAlert(t('Deleted Successfully'), 'normal');
		}
	}, [data]);

	useEffect(() => {
		const getCheckedState = async () => {
			const value = await AsyncStorage.getItem('isCheckEventDelete');
			setReminderChecked(value as string);
		};
		getCheckedState();
	}, []);

	const canSubScribe = () => {
		return (
			(!!loggedInUserDetails &&
				loggedInUserDetails.typeOfAccount === 'HOST' &&
				!event?.isHostApplicationAccepted &&
				event.typeOfEvent === 'PHYSICAL') ||
			(loggedInUserDetails?.typeOfAccount === 'CONSUMER' &&
				event.typeOfEvent === 'VIRTUAL') ||
			(loggedInUserDetails?.typeOfAccount === 'CONSUMER' &&
				event.typeOfEvent === 'PHYSICAL' &&
				event?.isHostApplicationAccepted)
		);
	};

	const [subscribeToEvent, { loading: subscribeLoading }] = useMutation(
		SUBSCRIBE_TO_EVENT,
		{
			variables: {
				affiliatedEvent: eventId,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: IS_SUBSCRIBED_TO_EVENTS,
					variables: {
						eventIds: [eventId],
					},
				},
			],
		}
	);

	const [unSubscribeFromEvent, { loading: unsubscribeLoading }] = useMutation(
		UNSUBSCRIBE_FROM_EVENT,
		{
			variables: {
				affiliatedEvent: eventId,
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: IS_SUBSCRIBED_TO_EVENTS,
					variables: {
						eventIds: [eventId],
					},
				},
			],
		}
	);

	const subScribeText =
		loggedInUserDetails?.typeOfAccount === 'HOST' ? 'Host' : 'Join';

	const handleSubToEvent = () => {
		if (!isUserLoggedIn) {
			dispatch(setTypeOfAccount('CONSUMER'));
			dispatch(setPrimaryColors('CONSUMER'));
			dispatch(setSignupSidebarValues('CONSUMER'));
			navigation.navigate('PersonalInfo');
			return;
		}
		subscribeToEvent();
	};

	return (
		<>
			{deleteModal && (
				<DeleteModal
					isOpen={deleteModal}
					onCancelDelete={() => setDeleteModal(false)}
					onConfirmDelete={() => handleDeleteEvent()}
					loading={loading}
					onConfirmCheck={() => setChecked(!checked)}
					checked={checked}
					header={`${t('Delete Event')}`}
					info={t(
						'Are you sure you want to delete this event? This action cannot be undone.'
					)}
				/>
			)}
			<View style={[Styles.container, styleContainer()]}>
				<View style={Styles.headerContainer}>
					<DateComponent date={startDate} />
					<View style={Styles.titleContainer}>
						<View
							style={[
								Styles.titleSubContainer,
								isMobileView && { flexDirection: 'column' },
							]}
						>
							<Text
								style={[
									Styles.title,
									mobileTitle(isMobileView),
								]}
								// numberOfLines={2}
							>
								{isMobileView ? shortenName(title, 54) : title}
							</Text>
							{/* <View style={{ alignSelf: 'flex-start' }}>
								{(canSubScribe() ||
									!loggedInUserDetails?.typeOfAccount) &&
									(subscribeLoading ? (
										<Button
											title={`${t('Subscribing')}...`}
											variant="primary"
										/>
									) : unsubscribeLoading ? (
										<Button
											title={`${t('Unsubscribing')}...`}
											variant="primary"
										/>
									) : !isSubscribed ? (
										<Button
											title={`+${t('Subscribe')}`}
											variant="primary"
											onPress={handleSubToEvent}
										/>
									) : (
										<Button
											title={`${t('UnSubscribe')}`}
											variant="primary"
											onPress={unSubscribeFromEvent}
										/>
									))}
							</View> */}
						</View>
						<View
							style={[
								Styles.subtitleRapper,
								subtitleRapper(isMobileView),
							]}
						>
							<View style={mobileEventTypeControl(isMobileView)}>
								{/* <View style={Styles.subtitle}>
									<Text style={Styles.subtitleEvent}>
										{subtitle}
									</Text>
								</View> */}
								{isMobileView && (
									<View
										style={[
											Styles.buttonContainer,
											mobileButtonContainer(isMobileView),
											{ marginLeft: '100%' },
										]}
									>
										{!!loggedInUserDetails &&
											loggedInUserDetails._id ===
												event?.affiliatedSeller
													?._id && (
												<>
													{!eventEnded() && (
														<Text>
															<PencilWritingIcon
																onPress={
																	handleEditEvent
																}
																fill={
																	palettes
																		.dark[4]
																}
															/>
														</Text>
													)}
													<Text
														onPress={() => {
															if (
																reminderChecked ===
																'true'
															) {
																handleDeleteEvent();
															} else {
																setDeleteModal(
																	true
																);
															}
														}}
													>
														<DeleteIcon />
													</Text>
												</>
											)}

										{/* {canSubScribe() &&
											(subscribeLoading ? (
												<Button
													title={t('Subscribing')}
													variant="grey"
												/>
											) : unsubscribeLoading ? (
												<Button
													title={t('Unsubscribing')}
													variant="grey"
												/>
											) : !isSubscribed ? (
												<Button
													title={t(
														`Request to ${subScribeText}`
													)}
													variant="grey"
													onPress={() =>
														subscribeToEvent()
													}
												/>
											) : (
												<Button
													title={t('UnSubscribe')}
													variant="grey"
													onPress={() =>
														unSubscribeFromEvent()
													}
												/>
											))} */}
										<View
											style={[
												Styles.shareContainer,
												{
													backgroundColor:
														primary?.[0],
												},
											]}
										>
											<ShareArrowIcon
												data={event}
												width="20"
												height="20"
												fill="#fff"
											/>
										</View>
									</View>
								)}
							</View>
							<Text
								style={[
									Styles.eventDate,
									mobileEventDate(isMobileView),
								]}
							>
								{dayjs(+startDate).format('HH:mm')}
							</Text>
						</View>
					</View>
				</View>
				{!isMobileView && (
					<View style={[Styles.buttonContainer]}>
						{!!loggedInUserDetails &&
							loggedInUserDetails._id ===
								event?.affiliatedSeller?._id && (
								<>
									{!eventEnded() && (
										<Button
											title={t('Edit')}
											variant="grey"
											onPress={handleEditEvent}
										/>
									)}

									<Button
										title={t('Delete')}
										outlineColor={palettes.red[0]}
										onPress={() => {
											if (reminderChecked === 'true') {
												handleDeleteEvent();
											} else {
												setDeleteModal(true);
											}
										}}
									/>
								</>
							)}

						{/* {canSubScribe() &&
							(subscribeLoading ? (
								<Button
									title={t('Subscribing')}
									variant="grey"
								/>
							) : unsubscribeLoading ? (
								<Button
									title={t('Unsubscribing')}
									variant="grey"
								/>
							) : !isSubscribed ? (
								<Button
									title={t(`Request to ${subScribeText}`)}
									variant="grey"
									onPress={() => subscribeToEvent()}
								/>
							) : (
								<Button
									title={t('UnSubscribe')}
									variant="grey"
									onPress={() => unSubscribeFromEvent()}
								/>
							))} */}
						<View
							style={[
								Styles.shareContainer,
								{ backgroundColor: primary?.[0] },
							]}
						>
							<ShareArrowIcon
								data={event}
								width="20"
								height="20"
								fill="#fff"
							/>
						</View>
					</View>
				)}
			</View>
		</>
	);
}

const Styles = StyleSheet.create({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: '10px',
	},
	shareContainer: {
		padding: 5,
		borderRadius: 6,
	},
	titleSubContainer: {
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	subscribe: {
		marginLeft: '1rem',
		fontWeight: 'bold',
		color: palettes.primary[0],
		fontSize: getResponsiveStyle(14, 'font'),
		border: `3px solid ${palettes.primary[0]}`,
		padding: '3px',
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		// marginLeft: '100%',
		gap: '10px',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dateContainer: {
		width: getResponsiveStyle(72),
		height: getResponsiveStyle(64),
		border: '1px solid #E0E4E9',
		borderRadius: 6,
		marginRight: getResponsiveStyle(16),
	},
	deleteModalContent: {
		marginBottom: getResponsiveStyle(20),
		paddingBottom: getResponsiveStyle(20),
		color: palettes.grey[0],
		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 1,
	},
	deleteModalActionBtn: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	dateContainerTop: {
		height: getResponsiveStyle(48),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dateContainerTopText: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	dateContainerBottom: {
		height: getResponsiveStyle(16),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: palettes.red[0],
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
	},
	dateContainerBottomText: {
		fontSize: getResponsiveStyle(12, 'font'),
		fontWeight: '600',
		color: '#FFF',
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: getResponsiveStyle(2),
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
		marginRight: '1rem',
	},
	subtitleRapper: {
		flexDirection: 'row',
		// justifyContent: 'center',
		alignItems: 'center',
	},
	subtitle: {
		backgroundColor: '#EFF1F4',
		borderRadius: 50,
		width: getResponsiveStyle(72, 'dimensions'),
		height: getResponsiveStyle(24, 'dimensions'),
		justifyContent: 'center',
		alignItems: 'center',
	},
	subtitleEvent: {
		fontSize: getResponsiveStyle(9, 'font'),
		fontWeight: '700',
		color: palettes.dark[4],
	},
	eventDate: {
		color: '#616E82',
		fontWeight: '400',
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(16, 'spacing'),
		marginLeft: getResponsiveStyle(5, 'spacing'),
	},
});
export default DescriptionHeader;

function styleContainer() {
	return {
		marginTop: getResponsiveStyle(32),
		// marginBottom: getResponsiveStyle(41),
	};
}
function mobileEventDate(isMobileView: boolean) {
	return (
		isMobileView && {
			fontSize: getResponsiveStyle(12, 'font'),
		}
	);
}
function mobileButtonContainer(isMobileView: boolean): TStyle {
	return (
		isMobileView && {
			width: '100%',
			// justifyContent: 'flex-end',
		}
	);
}
function mobileTitle(isMobileView: boolean) {
	return (
		isMobileView && {
			fontSize: getResponsiveStyle(20, 'font'),
			maxWidth: 280,
		}
	);
}
function subtitleRapper(isMobileView: boolean): TStyle {
	return (
		isMobileView && {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: undefined,
			gap: getResponsiveStyle(3),
		}
	);
}
function mobileEventTypeControl(isMobileView: boolean): TStyle {
	return (
		isMobileView && {
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
		}
	);
}
