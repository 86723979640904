import Svg, { Path, SvgProps } from 'react-native-svg';

import React from 'react';

const EllipsisIcon = (props: SvgProps) => {
	return (
		<Svg viewBox="0 0 24 24" width={24} height={props.height || 20}>
			<Path fill="none" d="M0 0h24v24H0z" />
			<Path d="M12 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
		</Svg>
	);
};

export default EllipsisIcon;
