import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { Action } from '../../..';
import useCreateLike from '../../../../api/likes/useCreateLike';
import useGetAllLikes from '../../../../api/likes/useGetLikes';
import { palettes } from '../../../../config';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../../hooks/usePrimaryColors';
import { useAppSelector } from '../../../../redux/store';
import { TStyleInputOrText } from '../../../../types/TStyle';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { CommentIcon, HeartWithOverflowLineIcon } from '../../../common/icons';
import { FooterIcon } from './FooterIcon';
const WriteAComment = styled(Action)<{ primary: string[] }>`
	color: ${({ primary }) => primary[0]};
	font-size: ${getResponsiveStyle(12, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	font-weight: 400;
	&:hover {
		border-bottom: 1px solid ${({ primary }) => primary[0]};
	}
`;

type Props = {
	inThread?: boolean;
	threadId?: string;
	statusId: string;
	commentOpen?: boolean;
	showComment: () => void;
	showCommentBox: () => void;
	showReplyInput: () => void;
	onSubmit: (args: StatusSubmit) => void;
};

export const CommentBlockFooter = ({
	inThread,
	statusId,
	threadId,
	showComment,
	// showReplyInput,
	// onSubmit,
	// commentOpen,
	showCommentBox,
}: Props) => {
	const { t } = useTranslation();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const primary = usePrimaryColors();
	const [showCommentInput, setShowCommentInput] = useState(false);
	const [showComments, setShowComments] = useState(false);
	const [liked, setLiked] = useState(false);
	const [likeCount, setLikedCount] = useState(0);

	const { executeCreateLikeMutation } = useCreateLike();
	const { executeAllLikesQuery, data } = useGetAllLikes();

	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	useEffect(() => {
		executeAllLikesQuery({
			parentId: threadId || statusId,
		});
	}, []);

	useEffect(() => {
		if (data?.getAllLikes?.success) {
			setLikedCount(+data?.getAllLikes?.data?.total);

			const likedStatus = data?.getAllLikes?.data?.data.find(
				(status: any) => status.likedBy === loggedInUserDetails?._id
			);
			if (likedStatus?.likedBy) {
				setLiked(true);
			}
		}
	}, [data]);

	const handlePostLike = () => {
		executeCreateLikeMutation({
			likeType: inThread ? 'COMMENT_LIKE' : 'STATUS_LIKE',
			parentId: threadId || statusId,
		});
		if (liked) {
			setLikedCount(likeCount - 1);
		} else {
			setLikedCount(likeCount + 1);
		}
		setLiked(!liked);
	};

	return (
		<>
			<View style={[styles.container, styleContainer(inThread)]}>
				<View
					style={[
						styles.iconsContainer,
						styleIconsContainer(isLessThanDesktopBase),
					]}
				>
					<FooterIcon
						onPress={handlePostLike}
						icon={
							<HeartWithOverflowLineIcon
								onPress={handlePostLike}
								outlineColor={liked}
								style={styleLikeIcon()}
							/>
						}
						label={`${likeCount || 0} ${
							liked
								? t('Unlike')
								: `${likeCount > 1 ? t('Likes') : t('Like')}`
						} `}
					/>
					{!inThread && (
						<>
							{/* <FooterIcon
								onPress={() => null}
								icon={<EyeIcon style={styleEyeIcon()} />}
								label={t('View')}
							/> */}
							<FooterIcon
								onPress={() => {
									showComment();
									setShowComments(!showComments);
								}}
								icon={
									<CommentIcon
										onPress={() => {
											showComment();
											setShowComments(!showComments);
										}}
										style={styleCommentIcon()}
									/>
								}
								label={`${t('Comments')}`}
							/>
						</>
					)}
					{/* {inThread && (
						<FooterIcon
							onPress={() => showReplyInput()}
							icon={<ReplyIcon style={styleCommentIcon()} />}
							label={t('Reply')}
						/>
					)} */}
				</View>
				{!inThread && (
					<Pressable
						onPress={() => {
							setShowCommentInput(!showCommentInput);
							showCommentBox();
						}}
					>
						<WriteAComment
							primary={primary}
							style={styleAction(primary)}
						>
							{`${t(
								showCommentInput ? 'Cancel' : 'Write a comment'
							)}`}
						</WriteAComment>
					</Pressable>
				)}
				{/* {inThread && !isLessThanDesktopBase && (
					<ViewAllLink>{t('View all reply')}</ViewAllLink>
				)} */}
			</View>
			{/* {showCommentInput && (
				<ShareUpdateInput
					commentParentId={statusId}
					isCommentInput={true}
					inThread={inThread}
					onSubmit={onSubmit}
				/>
			)} */}
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottomColor: palettes.grey[5],
		borderTopColor: palettes.grey[5],
	},
	iconsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
});

const styleIconsContainer = (isLessThanDesktopBase: boolean) => {
	return {
		// width: isLessThanDesktopBase ? '100%' : 'auto',
		width: 'auto',
		// justifyContent: isLessThanDesktopBase ? 'space-between' : 'flex-start',
	} as TStyleInputOrText;
};

// const styleEyeIcon = () => {
// 	return {
// 		width: getResponsiveStyle(14, 'dimensions'),
// 		height: getResponsiveStyle(12, 'dimensions'),
// 		marginRight: getResponsiveStyle(2),
// 	};
// };

const styleCommentIcon = () => {
	return {
		width: getResponsiveStyle(13, 'dimensions'),
		height: getResponsiveStyle(13, 'dimensions'),
		marginRight: getResponsiveStyle(6),
	};
};

const styleAction = (primary: string[]) => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		color: primary[0],
		// paddingBottom: getResponsiveStyle(1),
	};
};
function styleContainer(inThread: boolean | undefined) {
	return {
		borderBottomWidth: inThread ? 1 : 0,
		borderTopWidth: inThread ? 0 : 1,
		paddingTop: inThread ? 0 : getResponsiveStyle(18),
		paddingVertical: getResponsiveStyle(18),
	};
}

function styleLikeIcon() {
	return {
		width: getResponsiveStyle(13, 'dimensions'),
		height: getResponsiveStyle(12, 'dimensions'),
		marginRight: getResponsiveStyle(4),
	};
}
