import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	scrollItems: TStepTemplateScrollItems[];
	selectedIndex: number;
	changeSelectedIndex: (value: number) => void;
};

const SidebarNavigationTab = ({
	scrollItems,
	selectedIndex = 0,
	changeSelectedIndex,
}: Props) => {
	return (
		<View style={styleContainer()}>
			{scrollItems.map(item => {
				const isSelected = item.id === selectedIndex;
				return (
					<Hoverable>
						{({ hovered }) => (
							<Pressable
								key={item.id}
								style={[
									styles.itemContainer,
									styleItemContainer(),
									isSelected && styles.itemContainerSelected,
								]}
								onPress={() => changeSelectedIndex(item.id)}
							>
								<Text
									style={[
										styles.itemText,
										styleItemText(),
										isSelected && styles.itemTextSelected,
										hovered && styles.itemTextHovered,
									]}
								>
									{item.label}
								</Text>
							</Pressable>
						)}
					</Hoverable>
				);
			})}
		</View>
	);
};

const styles = StyleSheet.create({
	itemContainer: {
		borderRadius: 6,
	},
	itemContainerSelected: {
		backgroundColor: palettes.blue[1],
	},
	itemText: {
		fontWeight: '600',
		color: palettes.grey[1],
	},
	itemTextSelected: {
		color: palettes.dark[0],
	},
	itemTextHovered: {
		color: palettes.dark[0],
	},
});

export default SidebarNavigationTab;

const styleContainer = () => {
	return { marginTop: getResponsiveStyle(104) };
};

const styleItemText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleItemContainer = () => {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(17),
	};
};
