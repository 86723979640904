import { useLazyQuery } from '@apollo/client';
import { GET_RATINGS_AND_REVIEWS } from '../../graphql/rating/queries';

type Params =
	| {
			onCompleted?: TOnCompleteApiCall<TGetRatingsAndReviews>;
	  }
	| undefined;

const useGetReviewsAndRating = (params?: Params) => {
	const [getReviewsAndRating, { data, error, loading }] = useLazyQuery(
		GET_RATINGS_AND_REVIEWS,
		{
			onCompleted: params?.onCompleted,
		}
	);

	const executeGetReviewsAndRating = ({ args }: { args: any }) => {
		getReviewsAndRating({
			variables: {
				args: args,
			},
		});
	};
	return { executeGetReviewsAndRating, data, error, loading };
};

export default useGetReviewsAndRating;
