import { gql } from '@apollo/client';

const CREATE_COMMENT = gql`
	mutation createComment(
		$commentType: TypeOfComment!
		$parentId: ID!
		$content: String!
	) {
		createComment(
			args: {
				commentType: $commentType
				parentId: $parentId
				content: $content
			}
		) {
			statusCode
			data {
				... on Comment {
					content
					commentBy {
						username
					}
				}
			}
		}
	}
`;

export { CREATE_COMMENT };
