import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
	fragment UserFragment on User {
		_id
		typeOfAccount
		username
		email
		isVerified
		website
		followers
		gender
		attendedEvents
		affiliatedBrand
		companyLegalName
		defaultLanguage
		companyRegistrationNumber
		vat
		creditCardInformation {
			IBAN
			bankName
			city
		}
		personalInformation {
			phoneNumber
			interestTags
			favouriteCategories
			description
			profileImageLink
			firstName
			lastName
			address {
				city
				countryCode
				countryLabel
				address1
				address2
				zipCode
			}
		}
		connectAccountInfo {
			connectAccountId
		}
	}
`;

const USER_RESPONSE_FRAGMENT = gql`
	${USER_FRAGMENT}
	fragment UserResponseFragment on Response {
		statusCode
		success
		message
		data {
			...UserFragment
		}
	}
`;

const GET_ALL_USER_FRAGMENT = gql`
	fragment GetAllUsersFragment on AllResponse {
		statusCode
		success
		message
		data {
			... on All {
				total
				data {
					... on User {
						_id
						rating
						email
						typeOfAccount
						username
						website
						companyLegalName
						gender
						personalInformation {
							address {
								city
								countryCode
								countryLabel
								zipCode
							}
							profileImageLink
							firstName
							lastName
						}
					}
				}
			}
		}
	}
`;

export { USER_RESPONSE_FRAGMENT, GET_ALL_USER_FRAGMENT };
