import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { DropDownPickerSmall } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from 'react-click-away-listener';
type Props = {
	categories: TDropdownObject[];
	label: string;
	placeholder?: string;
	selectedCategory?: string;
	onClick: (text: string) => void;
};

export const CategoriesDropdown = ({
	categories: propsCategories,
	label,
	onClick,
	selectedCategory,
	placeholder = 'All Categories',
}: Props) => {
	const { t } = useTranslation();
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [categories, setCategories] = useState<TDropdownObject[]>([]);
	const [dropdownCategoryValue, setDropdownCategoryValue] =
		useState<TDropdownValue>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	useEffect(() => {
		if (dropdownCategoryValue) {
			onClick(dropdownCategoryValue as string);
		}
	}, [dropdownCategoryValue]);

	useEffect(() => {
		setCategoriesResponsively(width, label, setCategories, propsCategories);
	}, [propsCategories, label]);

	return (
		<ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
			<View>
				<DropDownPickerSmall
					placeholder={t(placeholder)}
					items={categories}
					value={
						isLessThanDesktopBase
							? selectedCategory
							: t(placeholder)
					}
					setValue={setDropdownCategoryValue}
					open={isDropdownOpen}
					setOpen={setIsDropdownOpen}
					dropDownContainerStyle={[
						styles.dropDownContainerStyle,
						styleDropdownContainer(isLessThanDesktopBase),
					]}
					listItemContainerStyle={styleListItemContainer()}
					textStyle={styleText(isDropdownOpen, isLessThanDesktopBase)}
					listItemLabelStyle={styleListItemLabel()}
					listMessageTextStyle={styles.listMessageText}
				/>
			</View>
		</ClickAwayListener>
	);
};

const styles = StyleSheet.create({
	listMessageText: {
		lineHeight: 100,
	},
	dropDownContainerStyle: {
		maxHeight: '50vh',
		zIndex: 999,
	},
});

const setCategoriesResponsively = (
	width: number,
	label: string,
	setCategories: React.Dispatch<React.SetStateAction<TDropdownObject[]>>,
	categories: TDropdownObject[]
) => {
	if (width < 1280) {
		const categoriesWithCustomOptionAdded = getUpdatedCategories(
			categories,
			label
		);
		setCategories(categoriesWithCustomOptionAdded);
	} else return setCategories(categories);
};
function getUpdatedCategories(categories: TDropdownObject[], label: string) {
	return categories.map((item, index) => {
		if (index === 1) {
			return {
				label: `${label} selected for you`,
				value: 'selected-for-you',
			};
		} else {
			return item;
		}
	});
}

function styleListItemLabel() {
	return {
		lineHeight: getResponsiveStyle(50, 'font'),
	};
}

function styleText(isDropdownOpen: boolean, isLessThanDesktopBase: boolean) {
	return {
		color: isDropdownOpen ? palettes.grey[0] : palettes.dark[0],
		lineHeight: getResponsiveStyle(14, 'font'),
		fontSize: isLessThanDesktopBase
			? getResponsiveStyle(18, 'font')
			: getResponsiveStyle(16, 'font'),
		fontWeight: isLessThanDesktopBase ? 'bold' : 'normal',
	};
}

function styleListItemContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(8, 'font'),
		marginVertical: getResponsiveStyle(6, 'font'),
	};
}

function styleDropdownContainer(isLessThanDesktopBase: boolean) {
	return {
		left: isLessThanDesktopBase ? 0 : '',
		right: isLessThanDesktopBase ? '' : 0,
		width: getResponsiveStyle(329, 'dimensions'),
		top: getResponsiveStyle(24, 'dimensions'),
		paddingTop: getResponsiveStyle(20),
		paddingBottom: getResponsiveStyle(6),
	};
}
