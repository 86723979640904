import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { Button } from '../..';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	showMessageButton?: boolean;
	isShowViewProfileBtn?: boolean;
	isHost?: boolean;
	hasActionBtn?: boolean;
	isSearch?: boolean;
	isInvited?: boolean;
	userId?: string;
	// onClickHostFollowMeActionButton?: (id: string) => void;
	onClickHostFollowMeActionButton?: (
		id: string,
		hasRequestBeenSent?: boolean,
		index?: number
		// isFollowingMe: boolean
	) => void;
	hasFollowHostRequestSent?: boolean;
	type?: string;
	hasUnfollowRequestBeenSent?: boolean;
	loadingFollowMe?: boolean;
	isFollowingMe?: boolean;
	index?: number;
	onPressFollowButton?: (id: number) => void;
	isFollowing?: boolean;
	loadingFollowUser?: boolean;
};

export const ActionButtons = ({
	isShowViewProfileBtn,
	isHost = false,
	isInvited = false,
	isSearch = false,
	hasActionBtn,
	showMessageButton = false,
	hasFollowHostRequestSent = false,
	userId,
	onClickHostFollowMeActionButton,
	type,
	hasUnfollowRequestBeenSent,
	loadingFollowMe,
	isFollowingMe,
	index,
	onPressFollowButton,
	isFollowing,
	loadingFollowUser,
}: Props) => {
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();

	return (
		<View style={[styles.buttonContainer, styleButtonContainer(isInvited)]}>
			{!isSearch && (
				<>
					{(hasActionBtn && type === 'host') || type === 'seller' ? (
						<Button
							title={isFollowing ? t('Unfollow') : t('Follow')}
							onPress={() => {
								onPressFollowButton &&
									onPressFollowButton(index);
							}}
							loading={loadingFollowUser}
							outerContainerProps={{
								style: styles.button,
							}}
						/>
					) : hasActionBtn && type === 'consumer' ? (
						hasFollowHostRequestSent ||
						hasUnfollowRequestBeenSent ? (
							<Button
								title={t('Request Sent')}
								variant="disabled"
							/>
						) : isFollowingMe ? (
							<Button
								onPress={() => {
									onClickHostFollowMeActionButton &&
										onClickHostFollowMeActionButton(
											userId ?? '',
											true,
											index
										);
								}}
								title={t('Unfollow Me')}
								loading={loadingFollowMe}
							/>
						) : (
							!isFollowingMe && (
								<Button
									onPress={() => {
										onClickHostFollowMeActionButton &&
											onClickHostFollowMeActionButton(
												userId ?? '',
												false,
												index
											);
									}}
									title={t('Follow Me')}
									loading={loadingFollowMe}
								/>
							)
						)
					) : null}
				</>
			)}
			{isInvited && showMessageButton && (
				<Button
					title={t('Message')}
					outerContainerProps={{
						style: styles.button,
					}}
				/>
			)}
			{isShowViewProfileBtn && (
				<Button
					title={t('View Profile')}
					variant="grey"
					outerContainerProps={{
						style: styles.button,
					}}
					onPress={() => {
						navigation.navigate('UserProfile', { userId });
					}}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	button: {
		flex: 1,
	},
	buttonContainer: {
		flexDirection: 'row',
	},
});
function styleButtonContainer(isInvited: boolean) {
	return {
		marginTop: isInvited ? getResponsiveStyle(0) : 0,
	};
}
