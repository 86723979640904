import { useLazyQuery } from '@apollo/client';
import { GET_BRAND_RATING } from '../../graphql/rating/queries';

const useGetBrandRating = () => {
	const [getBrandRating, { data, error, loading }] =
		useLazyQuery(GET_BRAND_RATING);

	const executeBrandRatingQuery = ({ brandId }: { brandId: string }) => {
		getBrandRating({
			variables: {
				brandId,
			},
		});
	};
	return { executeBrandRatingQuery, data, error, loading };
};

export default useGetBrandRating;
