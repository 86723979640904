import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { NotificationBellIcon } from '../icons';
import ProfileIcon from '../ProfileIcon';

export const LiveEventInvitation = () => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	return (
		<View style={[styles.container, styleContainer()]}>
			<View style={{ position: 'relative' }}>
				<ProfileIcon
					style={styleProfileIcon()}
					imageSrc="https://picsum.photos/200"
				/>
				<View
					style={[
						styles.notificationIconContainer,
						styleNotificationIconContainer(primary),
					]}
				>
					<NotificationBellIcon
						style={[styles.notificationIcon]}
						isPressable={false}
						outlineColor={'#FFF'}
					/>
				</View>
			</View>
			<View style={[detailsContainer()]}>
				<Text style={[styleEventName(), styles.eventName]}>
					{t('Live Event')}
				</Text>
				<Text
					style={[styleEventDescription(), styles.eventDescription]}
				>
					{t('Looking to indulge in events and buy products?')}
				</Text>
				<Text style={[styleEventDate(), styles.eventDate]}>
					2h {t('ago')}
				</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	eventDescription: {
		color: palettes.dark[4],
	},
	eventDate: {
		color: palettes.grey[2],
		marginTop: 6,
	},
	eventName: {
		fontWeight: '600',
		color: palettes.dark[0],
	},
	notificationIcon: {
		width: 16,
		height: 16,
	},
	notificationIconContainer: {
		paddingHorizontal: 6,
		paddingVertical: 4,
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: 'white',
		borderWidth: 2,
		borderRadius: 9999,
		position: 'absolute',
	},
	container: {
		flexDirection: 'row',
		borderTopWidth: 1,
		borderTopColor: palettes.grey[5],
	},
});

function styleEventDescription() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleEventName() {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(5),
	};
}

function detailsContainer() {
	return {
		width: `calc(100% - ${getResponsiveStyle(56 + 16 + 24)}px)`,
	};
}
function styleEventDate() {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}
function styleNotificationIconContainer(primary: string[]) {
	return {
		right: getResponsiveStyle(10, 'font'),
		bottom: getResponsiveStyle(-6, 'font'),
		backgroundColor: primary[0],
	};
}

function styleProfileIcon() {
	return {
		width: getResponsiveStyle(56, 'dimensions'),
		height: getResponsiveStyle(56, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(15),
	};
}
