import React from 'react';
import {
	StyleProp,
	StyleSheet,
	Text,
	useWindowDimensions,
	View,
	ViewStyle,
} from 'react-native';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { breakpoints } from '../../../../config';
import {
	styleDesktop,
	styleMobile,
	styleSmall,
	styleTablet,
} from '../../event-card/EventCard';
import { TStyle } from '../../../../types/TStyle';

type Props = {
	size?: 'large' | 'small';
	containerStyles?: TStyle;
};

const EventSmallCardLoader = ({ size = 'small', containerStyles }: Props) => {
	const width = useWindowDimensions().width;
	return (
		<View style={styleContainer(width, size, containerStyles)}>
			<Skeleton style={styles.cardTop} height={getResponsiveStyle(160)} />
			<View style={styles.cardBottom}>
				<View style={{ flexDirection: 'row' }}>
					<Skeleton
						style={{
							borderRadius: 27,
							marginRight: getResponsiveStyle(5),
						}}
						height={50}
						width={50}
					/>

					<View
						style={{
							justifyContent: 'space-evenly',
							marginBottom: getResponsiveStyle(40),
						}}
					>
						<Skeleton
							width={getResponsiveStyle(80)}
							height={getResponsiveStyle(10)}
							style={{ borderRadius: 6 }}
						/>
						<Text style={{ fontSize: 14 }}>
							<Skeleton
								width={getResponsiveStyle(150)}
								height={getResponsiveStyle(8)}
								style={{ borderRadius: 6 }}
							/>
						</Text>
					</View>
				</View>
				<View style={styles.cardMeta}>
					<Skeleton
						height={getResponsiveStyle(20)}
						width={getResponsiveStyle(20)}
						style={{ borderRadius: 27 }}
					/>
					<Skeleton
						height={getResponsiveStyle(5)}
						width={getResponsiveStyle(100)}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	cardTop: {
		// position: 'relative',
		// height: '50%',
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		overflow: 'hidden',
	},
	cardBottom: {
		display: 'flex',
		flexDirection: 'column',
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		paddingTop: getResponsiveStyle(20),
		paddingBottom: getResponsiveStyle(20),
		paddingLeft: getResponsiveStyle(20),
		// height: '50%',
	},
	container: {
		width: '100%',
		borderRadius: 6,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
	},
	cardMeta: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: getResponsiveStyle(45),
		paddingRight: getResponsiveStyle(10),
	},
});

const styleContainer = (
	width: number,
	size: string,
	containerStyles?: StyleProp<ViewStyle>
): StyleProp<ViewStyle> => {
	return [
		styles.container,
		getResponsiveEventCardStyles(width),
		size === 'small' && styleSmall(),
		containerStyles,
	];
};

const getResponsiveEventCardStyles = (width: number) => {
	return width >= breakpoints.desktopBase
		? styleDesktop()
		: width >= breakpoints.tabletSm
		? styleTablet()
		: styleMobile();
};

export default EventSmallCardLoader;
