import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ChevronDown, Logo } from '../..';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

const SignupHeaderSmall = () => {
	const navigation = useNavigation();
	const primary = usePrimaryColors();
	return (
		<View style={styles.container}>
			<ChevronDown
				onPress={() => navigation.goBack()}
				style={styles.chevron}
			/>
			<View style={styles.dummyContainer}></View>
			<Logo
				tint={primary?.[0]}
				style={[styles.logo]}
				width={38}
				height={38}
				viewBox="0 0 38 38"
			/>
			<View style={styles.dummyContainer}></View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
	},
	logo: {
		minHeight: 36,
		maxWidth: 32,
		maxHeight: 36,
	},
	chevron: {
		transform: [
			{
				rotate: '90deg',
			},
		],
		maxWidth: 10,
		maxHeight: 15,
		marginLeft: -10,
		// minWidth: "max-content",
	},
	dummyContainer: { flex: 1 },
});

export default SignupHeaderSmall;
