import dayjs from 'dayjs';
import {
	currentTimeGreaterThanBuying,
	currentTimeGreaterThanBuyingPeriod,
} from './currentTimeGreaterThanBuyingPeriod';

export function currentTimeGreaterThanEventStartingLessThanBuyingPeriod(
	eventStreamTimeline: string
) {
	const startingTime = dayjs(Number(eventStreamTimeline));
	const currentTimeGreaterThanStartingTime = dayjs().isAfter(startingTime);
	const greaterThanBuyingPeriod =
		currentTimeGreaterThanBuyingPeriod(eventStreamTimeline);
	return currentTimeGreaterThanStartingTime && !greaterThanBuyingPeriod;
}
export function currentTimeGreaterThanEventStartingLessThanBuying(
	eventStreamTimeline: string
) {
	const startingTime = dayjs(Number(eventStreamTimeline));
	const currentTimeGreaterThanStartingTime = dayjs().isAfter(startingTime);
	const greaterThanBuyingPeriod =
		currentTimeGreaterThanBuying(eventStreamTimeline);
	return currentTimeGreaterThanStartingTime && !greaterThanBuyingPeriod;
}
