import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { useSetAlert } from './useSetAlerts';
import { setGlobalCartOpen, setVisitorCart } from '../redux/userSlice';
import { useState } from 'react';
import { GET_CART } from '../graphql/cart/queries';
import { useMutation } from '@apollo/client';
import { CREATE_CART } from '../graphql/cart/mutation';

export const useAddToCart = ({
	event,
	selectedVariant,
	setModalState,
	isItemOutOfStock,
}: any) => {
	// const [optionValue] = useState<string>(
	//     selectedVariant?.option?.values?.[0]?.name
	// );
	const productBrand = event?.affiliatedBrands?.[0]?._id;
	const { visitorCartStore } = useAppSelector(state => state.user);
	const [isLoadingVisitorAddToCart, setIsLoadingVisitorAddToCart] =
		useState(false);
	const cart = useAppSelector(state => state.user.cart);
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const dispatch = useAppDispatch();
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(
		state => state.auth
	);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	const brandInCartArray = usersCart.map(
		item =>
			item?.productId?.associatedProduct?.brand?._id ??
			item?.starterkitId?.brand?._id
	);
	const brandInCart = new Set(brandInCartArray);

	const [createCart, { data: createData, loading: createLoading }] =
		useMutation(CREATE_CART);

	const mutateCart = () => {
		const optionValue = selectedVariant?.option?.values?.[0]?.name;
		if (!isUserLoggedIn) {
			if (brandInCart.size === 0 || brandInCart.has(productBrand)) {
				setIsLoadingVisitorAddToCart(true);
				const cartItem = {
					eventId: event,
					quantity: 1,
					productId: {
						...selectedVariant,
						associatedProduct: {
							...selectedVariant?.associatedProduct,
							brand: event?.affiliatedBrands?.[0],
						},
					},
					type: 'Product',
					optionValue,
					price: Number(
						selectedVariant?.option?.values?.find(
							(value: any) => value.name === optionValue
						)?.price
					),
				};

				setTimeout(() => {
					if (visitorCartStore?.length) {
						const addToCart = [...visitorCartStore, cartItem];
						dispatch(setVisitorCart(addToCart as Cart));
					} else {
						dispatch(setVisitorCart([cartItem] as any));
					}
					setAlert(t('Item Added to Cart'), 'normal');
					setModalState && setModalState(false);
					setIsLoadingVisitorAddToCart(false);
					dispatch(setGlobalCartOpen(true));
				}, 500);
			} else {
				setModalState && setModalState(false);
				setAlert(
					t('You cannot add items of different brands to cart'),
					'danger'
				);
			}

			// dispatch(setTypeOfAccount('CONSUMER'));
			// dispatch(setPrimaryColors('CONSUMER'));
			// dispatch(setSignupSidebarValues('CONSUMER'));
			// navigation.navigate('PersonalInfo');
			return;
		}
		if (isItemOutOfStock) {
			setAlert(t('Out of stock'), 'danger');
			return;
		}
		// console.log({
		// 	hadBrand: brandInCart.has(productBrand),
		// 	productBrand,
		// 	brandInCart,
		// 	eventDetails,
		// });

		if (brandInCart.size === 0 || brandInCart.has(productBrand)) {
			createCart({
				variables: {
					args: {
						items: {
							eventId: event?._id,
							quantity: 1,
							productId: selectedVariant?._id,
							type: 'Product',
							optionValue,
							price: Number(
								selectedVariant?.option?.values?.find(
									(value: any) => value.name === optionValue
								)?.price
							),
						},
					},
				},
				awaitRefetchQueries: true,
				refetchQueries: [{ query: GET_CART }],
				onCompleted: data => {
					setModalState && setModalState(false);
					dispatch(setGlobalCartOpen(true));
					if (data.createCart.success) {
						setAlert(t('Item Added to Cart'), 'normal');
					} else {
						setAlert(t('Unable to add item to cart'), 'danger');
					}
				},
				onError: () => {
					setAlert(t('Unable to add item to cart'), 'danger');
				},
			});
		} else {
			setModalState && setModalState(false);
			setAlert(
				t('You cannot add items of different brands to cart'),
				'danger'
			);
		}
	};
	return {
		mutateCart,
		createLoading,
		isLoadingVisitorAddToCart,
	};
};
