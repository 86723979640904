import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TCard = {
	cardId: string;
	name: string;
	address: string;
	number: string;
	brand: string;
	default: boolean;
	expMonth?: string;
	expYear?: string;
	cvc?: string;
};

type TCardEdit = {
	cardid: string;
	name: string;
	month: string;
	year: string;
	cardNum: string;
	line1: string;
	city: string;
	state: string;
	country: string;
};
type TGetCardsSliceInitialState = {
	UserCards: [] | TCard[];
	defaultCard: TCard;
	changingDefault: boolean;
	loadingCards: boolean;
	editCardModalState: boolean;
	cardToBeEditted: TCardEdit;
	getCardsState: boolean;
	createCardModalState: boolean;
	cardComponentHeight: number;
	isSaveAddressCard?: boolean;
	isSaveCard?: boolean;
};
// getCardState is the state to register whether the userCards state has been set

const initialState: TGetCardsSliceInitialState = {
	UserCards: [],
	defaultCard: {
		cardId: '',
		name: '',
		address: '',
		number: '',
		brand: '',
		default: false,
	},
	cardToBeEditted: {
		cardid: '',
		name: '',
		month: '',
		year: '',
		cardNum: '',
		line1: '',
		city: '',
		state: '',
		country: '',
	},
	changingDefault: false,
	loadingCards: false,
	editCardModalState: false,
	getCardsState: false,
	createCardModalState: false,
	isSaveAddressCard: false,
	isSaveCard: false,
	cardComponentHeight: 0,
};

const userCardSlice = createSlice({
	name: 'getCards',
	initialState,
	reducers: {
		setUserCards(state, action: PayloadAction<TCard[]>) {
			state.UserCards = action.payload;
		},
		setDefaultCard(state, action: PayloadAction<TCard>) {
			state.defaultCard = action.payload;
		},
		setdefaultloading(state, action: PayloadAction<boolean>) {
			state.changingDefault = action.payload;
		},
		setloadingCards(state, action: PayloadAction<boolean>) {
			state.loadingCards = action.payload;
		},
		setEditCardModalState(state) {
			state.editCardModalState = !state.editCardModalState;
		},
		setCreateCardModalState(state) {
			state.createCardModalState = !state.createCardModalState;
		},

		setCardToBeEditted(state, action: PayloadAction<TCardEdit>) {
			state.cardToBeEditted = action.payload;
		},
		setCardState(state, action: PayloadAction<boolean>) {
			state.getCardsState = action.payload;
		},
		setIsSaveAddressCard(state, action: PayloadAction<boolean>) {
			state.isSaveAddressCard = action.payload;
		},
		setIsSaveCard(state, action: PayloadAction<boolean>) {
			state.isSaveCard = action.payload;
		},
		setCardComponentHeight(state, action: PayloadAction<number>) {
			state.cardComponentHeight = action.payload;
		},
	},
});

export default userCardSlice.reducer;
export const {
	setUserCards,
	setDefaultCard,
	setdefaultloading,
	setloadingCards,
	setEditCardModalState,
	setCardToBeEditted,
	setCardState,
	setCreateCardModalState,
	setCardComponentHeight,
	setIsSaveAddressCard,
	setIsSaveCard,
} = userCardSlice.actions;
