import React, { useEffect, useState } from 'react';
import ModalComponent from '../modal/ModalComponent';
// import StarRating from 'react-native-star-rating-widget';
import { StyleSheet, View, Dimensions, ScrollView } from 'react-native';
// import Input from '../Input';
// import { t } from 'i18next';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Label from '../label/Label';
// import { palettes } from '../../../config';
import Button from '../button/Button';
import StarRatingComponent, { EntityObj } from './StarRatingComponent';
import useRateEntity from '../../../api/rating/useRateEntity';
import { useTranslation } from 'react-i18next';
import Input from '../Input';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

type Props = {
	onClose: () => void;
	openState: boolean;
	entities: EntityObj[];
	ratingType: string;
	deleteNotification?: TODO;
};

const RatingModal = ({
	openState,
	onClose,
	entities,
	ratingType,
	deleteNotification,
}: Props) => {
	const { t } = useTranslation();
	const [entityToRate, setEntityToRate] = useState<EntityObj[]>([]);
	const [entityReview, setEntityReview] = useState<any[]>([]);

	const { executeRateEntityMutation, data, loading } = useRateEntity();

	const { isDesktop } = useIsSpecificViewportWidth();

	useEffect(() => {
		if (data?.rateEntity?.success) {
			onClose();
			// deleteNotification();
		}
	}, [data]);

	const handleChange = (review: string, entityType: EntityObj) => {
		// setEntityReview(prev => [...prev, { review, entityType }]);
		setEntityReview(prev => {
			const stateReviewIndex = prev.findIndex(
				(review: any) => review?.entityType?._id === entityType?._id
			);
			const stateEntityClone = [...prev];

			if (stateReviewIndex === -1) {
				return [...prev, { review, entityType }];
			} else {
				const newStateRating = stateEntityClone.splice(
					stateReviewIndex,
					1
				);

				return [...newStateRating, { review, entityType }];
			}
		});
	};

	const handleEntityRating = (ratingEntity: EntityObj) => {
		setEntityToRate(prev => {
			const stateRatingIndex = prev.findIndex(
				rating => rating._id === ratingEntity._id
			);
			const stateEntityClone = [...prev];

			if (stateRatingIndex === -1) {
				return [...prev, ratingEntity];
			} else {
				const newStateRating = stateEntityClone.splice(
					stateRatingIndex,
					1
				);

				return [...newStateRating, ratingEntity];
			}
		});
	};

	const getReview = (_id: string) => {
		const reviewIndex = entityReview.findLastIndex(
			(entity: any) => entity.entityType._id === _id
		);
		return entityReview?.[reviewIndex]?.review;
	};

	const handleRatingSubmit = () => {
		entityToRate.map(
			async entity =>
				await executeRateEntityMutation({
					parentId: entity._id,
					rating: entity.val as number,
					review: getReview(entity._id),
					ratingType,
				})
		);
	};
	return (
		<ModalComponent
			header={`${t('Your Rating & Review')}`}
			type={'sidebar'}
			openState={openState}
			onClose={onClose}
			isNotScrollable
		>
			<View style={styles.container}>
				<View style={styles.ratingContainer}>
					<ScrollView
						showsHorizontalScrollIndicator={false}
						showsVerticalScrollIndicator={true}
					>
						{entities?.map(entity => (
							<>
								<View style={{ alignItems: 'center' }}>
									<Label
										label={`${t('Select a Rating for')} ${
											entity.type
										}`}
									/>
									<StarRatingComponent
										entity={entity}
										onRatingChange={value =>
											handleEntityRating(value)
										}
									/>
								</View>
								<View
									style={{
										width: '100%',
									}}
								>
									<Input
										label={t('Write a Review')}
										onChangeText={text =>
											handleChange(text, entity)
										}
										multiline
										style={[
											styleDescription(isDesktop),
											styles.description,
										]}
									/>
								</View>
							</>
						))}
					</ScrollView>
				</View>
				<View style={styles.button}>
					<Button
						onPress={handleRatingSubmit}
						title={t('Submit')}
						size={'lg'}
						outerContainerProps={{ style: { width: '100%' } }}
						variant={'primary'}
						loading={loading}
					/>
				</View>
			</View>
		</ModalComponent>
	);
};

export default RatingModal;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		height: Dimensions.get('window').height,
	},
	ratingContainer: {
		height: '90%',
		paddingBottom: '90px',
		paddingHorizontal: '2px',
		// overflow: 'scroll',
	},
	button: {
		position: 'absolute',
		bottom: '80px',
		width: '100%',
		flexDirection: 'row',
		// backgroundColor: 'blue',
		alignItems: 'center',
		backgroundColor: 'white',
		zIndex: 9999,
	},
	description: {},
	rating: {},
});

function styleDescription(isDesktop: boolean) {
	return {
		height: getResponsiveStyle(isDesktop ? 160 : 80, 'dimensions'),
	};
}
