import { useLazyQuery } from '@apollo/client';
import { GET_ALL_USERS } from '../../graphql/auth/queries';

type TParams = {
	onCompleted?: (data: TGetAllUsersResponse) => void | undefined;
};

export const useGetAllUsersLazy = (params?: TParams) => {
	const [_getAllUsers, state] = useLazyQuery<TGetAllUsersResponse>(
		GET_ALL_USERS,
		{
			onCompleted: params?.onCompleted,
		}
	);

	const getAllUsersLazy = (find: TGetAllUsersFind) => {
		_getAllUsers({
			variables: {
				find,
			},
		});
	};

	return {
		getAllUsersLazy,
		...state,
	};
};
