import { useEffect } from 'react';
import useGetEntityRating from '../api/rating/useGetEntityRating';

export const useGetRating = (id: string) => {
	const { executeEntityRatingQuery, data: productRating } =
		useGetEntityRating();

	useEffect(() => {
		executeEntityRatingQuery({
			entityId: id as string,
		});
	}, []);

	const rating = productRating?.getAverageRating?.data?.averageEntityRating;
	return rating;
};
