import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'react-native';
import { AuthTemplate, Button, CreditCardInformationForm } from '../components';
import { useNextStepScreen } from '../hooks/useNextStepScreen';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const CreditCardInformationScreen = () => {
	const { width } = useWindowDimensions();
	const signupSidebarArray = useAppSelector(
		state => state.ui.SignupSidebarArray
	);
	const { goToNextStepScreen } = useNextStepScreen(signupSidebarArray);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/card-info'));
	}, []);
	return (
		<AuthTemplate
			heading={t('Add Credit Card Information')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<CreditCardInformationForm />
		</AuthTemplate>
	);
};

export default CreditCardInformationScreen;
