import { useAppSelector } from '../redux/store';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useMemo } from 'react';
import { TUseNavigation } from '../types/exportedTypes';

export const useNextStepScreen = (arr: TStepTemplateScrollItems[]) => {
	const route = useRoute();
	const navigation = useNavigation<any>();

	const updateEventState = useAppSelector(state => state.createEvent);

	const goToNextStepScreen = useMemo(() => {
		const indexOfCurrentScreen = arr.findIndex(
			item => item.screen === route.name
		);

		const nextScreenName = arr[indexOfCurrentScreen + 1].screen;
		return () => navigation.navigate(nextScreenName);
	}, [route.name]);

	return { goToNextStepScreen };
};
