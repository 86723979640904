import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
// import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../../redux/store';
import useGetMediaMessages from '../../../../api/chat/useGetMediaMessages';
import styled from 'rn-css';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import Loader from '../../../common/loader/Loader';

const Media = () => {
	// const windowWidth = Dimensions.get('window').width;
	// const windowHeight = Dimensions.get('window').height;

	const MediaRapper = styled.View`
		display: inline-block;
		width: 100%;
		padding-left: 10px;
		margin-top: 10px;
	`;

	const imageStyle = {
		display: 'inline-block',
		width: '44%',
		height: getResponsiveStyle(85, 'dimensions'),
		borderRadius: '6px',
		marginBottom: '10px',
		marginRight: '10px',
		cursor: 'pointer',
	};

	const chat = useAppSelector(state => state.chat.chat);

	const { executeMediaMessageQuery, loading, data } = useGetMediaMessages();
	// const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [imageIndex, setImageIndex] = useState<number>();

	useEffect(() => {
		if (chat?.conversationId)
			executeMediaMessageQuery({
				conversationId: chat?.conversationId,
				messageType: 'IMAGE',
			});
	}, [chat]);

	return (
		<ScrollView showsHorizontalScrollIndicator={false}>
			{loading && (
				<Loader
					size="small"
					containerStyles={{
						margin: getResponsiveStyle(20),
					}}
				/>
			)}
			{/* <View style={styles.container}>
				{data?.getMediaMessages?.data.map(
					(media: any, index: number) => (
						<Pressable onPress={() => setImageIndex(index)}>
							<Image
								style={[
									styles.image,
									isLessThanDesktopBase &&
										styles.mobileSizeImage,
									index === imageIndex && [
										styles.detailImage,
										isLessThanDesktopBase &&
											styles.mobileSizeDetailImage,
									],
								]}
								source={{
									uri: media?.message,
								}}
							/>
						</Pressable>
					)
				)}

				<Image
					style={styles.detailImage}
					source={require('../../../../assets/eventUploadImg/unsplash_GHCVUtBECuY.png')}
				/>
			</View> */}

			<MediaRapper>
				{data?.getMediaMessages?.data.map(
					(image: any, index: number) => (
						<img
							style={{
								...imageStyle,
								width: `${
									index === imageIndex ? '95%' : '44%'
								}`,
								height: `${
									index === imageIndex
										? getResponsiveStyle(106, 'dimensions')
										: getResponsiveStyle(85, 'dimensions')
								}`,
							}}
							onClick={() => setImageIndex(index)}
							src={image?.message}
						/>
					)
				)}
			</MediaRapper>
		</ScrollView>
	);
};

// const styles = StyleSheet.create({
// 	container: {
// 		paddingLeft: 20,
// 		paddingRight: 20,
// 		flexDirection: 'row',
// 		justifyContent: 'space-evenly',
// 		marginTop: 10,
// 		flexWrap: 'wrap',
// 	},
// 	image: {
// 		width: 105,
// 		height: 96,
// 		borderRadius: 6,
// 		marginBottom: 10,
// 		cursor: 'pointer',
// 	},
// 	detailImage: {
// 		width: 224,
// 		height: 204,
// 	},
// 	mobileSizeImage: {
// 		width: 159,
// 		height: 126,
// 	},
// 	mobileSizeDetailImage: {
// 		width: 324,
// 		height: 304,
// 	},
// });

export default Media;
