import { useRoute } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import {
	GuestMobileNavigationBarBottomArray,
	MobileNavigationBarBottomArray,
} from './MobileNavigationBarBottomArray';
import { MobileNavigationBottomIcon } from './MobileNavigationBottomIcon';

const MobileNavigationBarBottom = () => {
	const route = useRoute();
	const { t } = useTranslation();
	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		state => state.auth
	);
	const displayIndex = isUserLoggedIn ? 4 : 1;

	const ComputedNavigation =
		loggedInUserDetails?.typeOfAccount === 'CONSUMER'
			? GuestMobileNavigationBarBottomArray
			: MobileNavigationBarBottomArray.slice(0, displayIndex);
	return (
		<View style={[styles.container, styleContainer()]}>
			<View style={[styleIconsContainer(), styles.iconsContainer]}>
				{ComputedNavigation.map(
					({ icon, id, label, marginRight, screen }) => (
						<MobileNavigationBottomIcon
							key={id}
							icon={icon}
							label={route.name === screen ? `${t(label)}` : ''}
							marginRight={marginRight}
							to={screen}
						/>
					)
				)}
			</View>
		</View>
	);
};

export default MobileNavigationBarBottom;
function styleContainer() {
	return {
		height: 'auto',
		top: 0,
	};
}

function styleIconsContainer() {
	return {
		// marginBottom: getResponsiveStyle(20),
		paddingVertical: getResponsiveStyle(10),
		minHeight: getResponsiveStyle(30, 'dimensions'),
	};
}

const styles = StyleSheet.create({
	iconsContainer: {
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	container: {
		backgroundColor: 'white',
		// position: 'absolute',
		width: '100vw',
	},
});
