import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from 'react-native';
import { palettes } from '../../../../config';
import { removeaffiliatedBrands } from '../../../../redux/createEventSlice';
import { useAppDispatch } from '../../../../redux/store';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { HorizontalDivider } from '../../../common/horizontal-divider';
import { SelectedBrand } from './selected-brand';

type Props = {
	selectedBrands: TGetAllBrandsData[];
	setSelectedBrands: React.Dispatch<
		React.SetStateAction<TGetAllBrandsData[]>
	>;
	onPressBrandContainer: (brand: TGetAllBrandsData) => void;
};

const SelectedBrands = ({
	selectedBrands,
	setSelectedBrands,
	onPressBrandContainer,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const onRemoveSelectedBrand = (brandId: string) => {
		setSelectedBrands(() => {
			return selectedBrands.filter(brand => brand._id !== brandId);
		});
		dispatch(removeaffiliatedBrands(brandId));
	};

	return (
		<View>
			<Text
				style={{
					fontSize: getResponsiveStyle(14, 'font'),
					lineHeight: getResponsiveStyle(22, 'font'),
					marginBottom: getResponsiveStyle(20),
					color: palettes.dark[4],
				}}
			>
				{t('Brands Selected')}
				{/* ( {selectedBrands.length} ) */}
			</Text>
			{selectedBrands.map(brand => (
				<>
					<SelectedBrand
						key={brand._id}
						selectedBrand={brand}
						onPressActionButton={onRemoveSelectedBrand}
						onPressContainer={onPressBrandContainer}
					/>
					<HorizontalDivider
						paddingTop={getResponsiveStyle(8)}
						paddingBottom={getResponsiveStyle(24)}
					/>
				</>
			))}
		</View>
	);
};

export default SelectedBrands;
