import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';

import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useMutation } from '@apollo/client';
import { DELETE_BANK } from '../../../graphql/stripe/mutations';
import { LIST_BANKS } from '../../../graphql/stripe/queries';
import ModalComponent from '../../common/modal/ModalComponent';
import { Button } from '../..';
import { useTranslation } from 'react-i18next';
import { setBankComponentHeight } from '../../../redux/bankSlice';

type Props = {
	hide: string;
	bankId: string;
	name: string;
	number: string;
	city: string;
	defaultBank?: boolean;
};

const BankComponent = ({
	bankId,
	name,
	city,
	number,
	defaultBank = false,
}: Props) => {
	const { t } = useTranslation();
	const [deleteBankModalState, setDeleteBankModalState] =
		React.useState(false);
	const changeDeleteBankModalState = () => {
		setDeleteBankModalState(!deleteBankModalState);
	};
	const banks = useAppSelector(state => state.userBanks.UserBanks);
	const connectAccountId = useAppSelector(
		state => state.userBanks.connectAccountId
	);

	const connectID = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.connectAccountInfo?.connectAccountId
	);

	const dispatch = useAppDispatch();
	const [deleteBank, { loading: deletingBank, data: deleteBankData }] =
		useMutation(DELETE_BANK);

	const getBankToBeDeleted = async () => {
		let bankToBeDeleted: any;
		banks.map(bank => {
			if (bank.bankId === bankId) {
				bankToBeDeleted = bank;
			}
		});
		await deleteBank({
			variables: {
				args: {
					id: connectAccountId,
					bankId: bankToBeDeleted.bankId,
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: LIST_BANKS,
					variables: {
						args: {
							id: connectID,
							// id: connectAccountId,
						},
					},
				},
			],
		});
	};
	// const unDefault = () => {
	// 	updateBank({
	// 		variables: {
	// 			args: {
	// 				id: stripeId_,
	// 				bankId: dBank.bankId,
	// 				default: false,
	// 			},
	// 		},
	// 	});
	// };

	// const onClickEdit = async () => {
	// 	await dispatch(setdefaultloadingBank(true));
	// 	await updateBank({
	// 		variables: {
	// 			args: {
	// 				id: stripeId_,
	// 				bankId: bankId,
	// 				default: true,
	// 			},
	// 		},
	// 		awaitRefetchQueries: true,
	// 		refetchQueries: [
	// 			{
	// 				query: LIST_BANKS,
	// 				variables: {
	// 					args: {
	// 						id: stripeId_,
	// 					},
	// 				},
	// 			},
	// 		],
	// 	});

	// 	await dispatch(setdefaultloadingBank(false));
	// };
	return (
		<View
			onLayout={event => {
				const { height } = event.nativeEvent.layout;
				dispatch(setBankComponentHeight(height));
			}}
			style={[
				styles.container,
				{
					width: getResponsiveStyle(400),
					// maxWidth: !isLessThanDesktopBase
					// 	? '31.5%'
					// 	: isTabletView
					// 	? '45%'
					// 	: '100%',
				},
			]}
		>
			<View style={styles.headerContainer}>
				<View style={styles.headerTextContainer}>
					<Text style={styles.textTitile}>{t('Bank Name')}</Text>
					<Text style={styles.textSubtitle}>{name}</Text>
				</View>
				{defaultBank && (
					<View style={styles.defaultIndicator}>
						<Text style={styles.defaultIndicatorText}>
							{t('Default')}
						</Text>
					</View>
				)}
			</View>
			<View style={styles.contentContainer}>
				<View style={styles.textContainer}>
					<Text style={styles.textTitile}>{t('IBAN')}</Text>
					<Text style={styles.textSubtitle}>{number}</Text>
				</View>
				<View style={styles.textContainer}>
					<Text style={styles.textTitile}>{t('COUNTRY')}</Text>
					<Text style={styles.textSubtitle}>{city}</Text>
				</View>

				{/* {hide === 'no' ? (
					<Text
						onPress={async () => {
							await unDefault();
							await onClickEdit();
						}}
						style={styles.hoverText}
					>
						{t('Set as Default Bank')}
					</Text>
				) : null} */}
				<Text
					onPress={() => changeDeleteBankModalState()}
					style={styles.hoverText}
				>
					{t('Delete Bank Account')}
				</Text>

				<Text style={styles.hoverText}>{t('Edit Details')}</Text>
			</View>

			<ModalComponent
				header={`${t('Delete Bank Account')}`}
				openState={deleteBankModalState}
				onClose={changeDeleteBankModalState}
			>
				{deletingBank ? (
					<Text>{t('Deleting bank...')}</Text>
				) : deleteBankData ? (
					<Text>{t('Bank Deleted')}</Text>
				) : (
					<View style={styles.deleteContainer}>
						<Text>{t('Are you sure you want to delete? ')}</Text>
						<Button
							title={t('Yes')}
							onPress={() => getBankToBeDeleted()}
						/>
					</View>
				)}
			</ModalComponent>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingTop: getResponsiveStyle(28),
		padding: getResponsiveStyle(24),
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
		// maxWidth: getResponsiveStyle(440),
		width: '100%',
		marginRight: getResponsiveStyle(15),
		marginTop: getResponsiveStyle(24),
		// height: getResponsiveStyle(200),
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'flex-start',
	},
	headerTextContainer: {
		flexDirection: 'column',
		gap: 4,
	},
	deleteContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	// deleteContainerText: {
	// 	marginLeft: getResponsiveStyle(20)
	// },
	textTitile: {
		color: palettes.grey[1],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	},
	textSubtitle: {
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(21, 'font'),
	},
	defaultIndicator: {
		paddingHorizontal: getResponsiveStyle(17),
		paddingTop: getResponsiveStyle(10),
		paddingBottom: getResponsiveStyle(8),
		backgroundColor: palettes.purple[0],
		borderRadius: 6,
	},
	defaultIndicatorText: {
		color: palettes.light[0],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	},
	contentContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		marginTop: getResponsiveStyle(26),
	},
	textContainer: {
		flexDirection: 'column',
		gap: 6,
	},
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[1],
		cursor: 'pointer',
	},
});

export default BankComponent;
