import {
	setBrandPillCategories,
	setDropDownCategories,
	setInteresTags,
	setPillCategories,
} from '../../redux/tagsSlice';
type Tag = TDropdownObject & { key: string };
const matches = [/INTEREST_TAG/, /BRAND_CATEGORY/, /PRODUCT_CATEGORY/];
export const onCompletedTag = (data: any, dispatch: any) => {
	if (data && data?.getTags && data?.getTags?.success) {
		const tags: Tag[] = data.getTags.data.data.map((item: TAppConfig) => {
			return {
				label: item.value.value1,
				key: item.key,
				en: item.value.value1,
				fr: item.value.value2,
				value: item.value.value3,
			};
		});

		const pillCategories = tags.filter(item =>
			item?.key?.match(matches[2])
		);
		const brandCategories = tags.filter(item =>
			item?.key?.match(matches[1])
		);
		const dropdownCategories: TDropdownObject[] = Object.values(
			[
				...brandCategories.slice(3, brandCategories.length),
				...pillCategories.slice(3, pillCategories.length),
			].reduce((acc, obj) => ({ ...acc, [obj.value]: obj }), {})
		);

		dispatch(
			setInteresTags(tags.filter(item => item?.key?.match(matches[0])))
		);
		dispatch(setDropDownCategories(dropdownCategories));
		dispatch(setPillCategories(pillCategories?.slice(0, 3)));
		dispatch(setBrandPillCategories(brandCategories?.slice(0, 3)));
	}
};
