import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import UserCard from './UserCard';
type Props = {
	isForRecommend?: boolean;
	id: string;
	userName: string;
	userCountry: string;
	userImage: string;
	onRemove: (id: string) => void;
	onInvite?: (id: string) => void;
	countryCode?: string;
	city?: string;
};

const EventInviteCard = ({
	isForRecommend,
	id,
	userCountry,
	userImage,
	userName,
	onRemove,
	onInvite,
	countryCode,
	city,
}: Props) => {
	const { t } = useTranslation();

	return (
		<View>
			<View style={styles.flex1}>
				<UserCard
					id={id}
					userName={userName}
					userCountry={userCountry}
					userImage={userImage}
					countryCode={countryCode}
					city={city}
				/>
				{isForRecommend ? (
					<Text
						onPress={() => onInvite(id)}
						style={[
							styles.userName,
							styles.action,
							{ color: '#1877F2' },
						]}
					>
						{/* {t('Waiting')} */}
						{t('Invite')}
					</Text>
				) : (
					<Text
						onPress={() => onRemove(id)}
						style={[styles.userName, styles.action]}
					>
						{/* {t('Waiting')} */}
						{t('Remove')}
					</Text>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	agreementText: {
		color: palettes.dark[4],
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	},
	userName: {
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 14,
		color: '#101625',
	},
	flex1: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '15px',
	},
	action: {
		color: '#F96152',
	},
	user: { justifyContent: 'space-evenly' },
	userImg: { height: '56px', width: '56px', marginRight: '15px' },
	userDetail: {
		flexDirection: 'row',
	},
});

const AgreementLink = styled.Text`
	color: ${palettes.grey[1]};
	margin-left: 4px;
	margin-right: 4px;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
	&:focus {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

export default EventInviteCard;
