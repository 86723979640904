import React from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	text: string;
	selected: boolean;
	onPressButton: (text: string) => void;
};

const SelectButton = ({ text, selected, onPressButton }: Props) => {
	const primary = usePrimaryColors();
	return (
		<Pressable
			style={[styles.container, selected && { borderColor: primary[0] }]}
			onPress={() => onPressButton(text)}
		>
			<Text
				style={[
					styles.text,
					selected && { ...styles.selected, color: primary[0] },
				]}
			>
				{text}
			</Text>
		</Pressable>
	);
};

export default SelectButton;

const styles = StyleSheet.create({
	text: {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
		color: palettes.grey[0],
	},
	selected: {
		fontWeight: '600',
	},
	container: {
		borderRadius: 6,
		height: getResponsiveStyle(48, 'dimensions'),
		marginRight: getResponsiveStyle(16),
		marginBottom: getResponsiveStyle(16),
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: 1,
		flex: 1,
		minWidth: '19.8%',
		borderColor: palettes.grey[4],
	},
});
