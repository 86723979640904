import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Pressable } from 'react-native';
import { ProductCard } from '../..';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ModalComponent from '../../common/modal/ModalComponent';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import ProductView from '../../common/templates/ProductView';
import { useNavigation, useRoute } from '@react-navigation/native';
import { TUseNavigation } from '../../../types/exportedTypes';

type Props = {
	products?: TGetAllProductsData[];
	eventDetails: any;
	brand?: string;
};

const ProductCards = ({ products = [], eventDetails, brand }: Props) => {
	const { isLessThanDesktopBase, isMobileView } =
		useIsSpecificViewportWidth();
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();
	const route = useRoute();
	const { variantId, productId } = route?.params;
	const [openState, setOpenState] = useState(false);
	const [currentVariant, setCurrentVariant] = useState<Variant>();
	const [currentProduct, setCurrentProduct] = useState<TGetAllProductsData>();

	useEffect(() => {
		const getProduct = products?.find(product => product._id === productId);
		const getVariantInProduct = getProduct?.variants?.find(
			variant => variant._id === variantId
		);
		if (!currentProduct && !currentVariant) {
			setCurrentProduct(getProduct);
			setCurrentVariant(getVariantInProduct);
			setOpenState(true);
		}
	}, []);

	return (
		<View
			style={[
				styles.container,
				styleContainer(),
				styleSmallViewportContainer(isLessThanDesktopBase),
			]}
		>
			{!!products && products?.length ? (
				products?.map((product, index) =>
					product?.variants.map((variant, index2) => {
						return (
							<>
								<Pressable
									onPress={() => {
										// navigation.navigate(
										// 	'EventDescription',
										// 	{
										// 		eventId:
										// 			eventDetails?.eventDetails
										// 				?.eventDetails?._id,
										// 		variantId: variant?._id,
										// 		productId: product?._id,
										// 	}
										// );
										setCurrentProduct(product);
										setCurrentVariant(variant);
										setOpenState(true);
									}}
								>
									<ProductCard
										key={variant?._id}
										isLastItem={
											product.variants?.length *
											products?.length ===
											(index + 1) * (index2 + 1)
										}
										containerStyles={[
											styleProductContainer(),
											styleMobileProductContainer(
												isMobileView
											),
										]}
										image={variant?.image}
										options={variant?.option}
										optionValue={
											variant?.option?.values[0]?.name
										}
										price={
											+variant?.option?.values[0]?.price
										}
										compareAtPrice={
											+variant?.option?.values[0]
												?.compareAtPrice
										}
										name={
											product.name + ' ' + (variant?.value ? variant?.value : "")
										}
										rating={product.rating}
										id={variant?._id}
										eventDetails={eventDetails}
										variant={{
											_id: variant._id,
											variator: variant?.variator,
											value: variant?.value,
											vat: variant?.vat,
											requiresShipping: true,
											image: variant?.image,
											option: variant?.option,
											associatedProduct: {
												_id: product._id,
												name: product.name,
												shortDescription:
													product.shortDescription,
												description:
													product.description,
												category: product.category,
												publishedStatus:
													product?.publishedStatus,
												images: product.images,
												brand: product.brand,
											},
										}}
										brand={brand}
									/>
								</Pressable>
							</>
						);
					})
				)
			) : (
				<NotAvailableMessage
					message={t('This brand has no products!')}
				/>
			)}
			{currentVariant && currentProduct && (
				<ModalComponent
					onClose={() => {
						setOpenState(false);
					}}
					isProductModal
					header={`${t('Product View')}`}
					openState={openState}
					type={isLessThanDesktopBase ? 'fullpage' : 'sidebar'}
				>
					<ProductView
						productData={currentProduct}
						variant={{
							_id: currentVariant._id,
							variator: currentVariant?.variator,
							value: currentVariant?.value,
							vat: currentVariant?.vat,
							option: currentVariant?.option,
							requiresShipping: true,
							image: currentVariant?.image,
							associatedProduct: {
								_id: currentProduct?._id,
								name: currentProduct?.name,
								brand: currentProduct?.brand,
								shortDescription:
									currentProduct?.shortDescription,
								description: currentProduct?.description,
								category: currentProduct.category,
								publishedStatus:
									currentProduct?.publishedStatus,
								images: currentProduct?.images,
								brand: currentProduct?.brand,
							},
						}}
						eventDetails={eventDetails}
						setModalState={setOpenState}
					/>
				</ModalComponent>
			)}
		</View>
	);
};

export default ProductCards;
function styleContainer() {
	return {
		width: `calc(100% + ${getResponsiveStyle(16)}px)`,
	};
}

function styleSmallViewportContainer(isLessThanDesktopBase: boolean): TStyle {
	return (
		isLessThanDesktopBase && {
			justifyContent: 'space-between',
		}
	);
}

function styleProductContainer() {
	return {
		marginBottom: getResponsiveStyle(22),
	};
}

function styleMobileProductContainer(isMobileView: boolean) {
	return (
		isMobileView && {
			minWidth: getResponsiveStyle(165, 'dimensions'),
			maxWidth: getResponsiveStyle(165, 'dimensions'),
		}
	);
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
});
