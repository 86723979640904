import React, { useRef } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { palettes } from '../../../config';
import { i18n } from '../../../i18n';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Loader from '../loader/Loader';

function EventDeleteBtn({
	content,
	onPress,
	loading,
}: {
	content: string;
	onPress: () => void;
	loading?: boolean;
}) {
	const ref = useRef(null);
	const isHovered = useHover(ref);
	return (
		<Pressable
			ref={ref}
			onPress={onPress}
			style={[
				Styles.container,
				isHovered && { backgroundColor: '#de3322fc' },
			]}
		>
			{loading ? (
				<Loader
					size="small"
					color={palettes.light[0]}
					containerStyles={{
						margin: getResponsiveStyle(20),
					}}
				/>
			) : (
				<Text style={Styles.text}>{content}</Text>
			)}
		</Pressable>
	);
}

const Styles = StyleSheet.create({
	container: {
		width: 112,
		height: 40,
		backgroundColor: '#F96152',
		borderRadius: 6,
		justifyContent: 'center',
		alignItems: 'center',
	},
	text: {
		fontWeight: '600',
		fontSize: getResponsiveStyle(16),
		lineHeight: getResponsiveStyle(21),
		color: palettes.light[0],
	},
});
export default EventDeleteBtn;

function styleContainer() {
	return {
		// marginTop: getResponsiveStyle(54),
		// marginBottom: getResponsiveStyle(41),
	};
}
