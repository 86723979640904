import { SERVER_URL, SERVER_SOCKET_URL, SOCKET_SERVER, STRIPE_KEY, STRIPE_LIVE_KEY, CLIENT_URL } from '@env';

export default {
	SERVER_URL,
	SERVER_SOCKET_URL,
	SOCKET_SERVER,
	STRIPE_KEY,
	STRIPE_LIVE_KEY,
	CLIENT_URL
};
