import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate } from '../components';
import EventInviteForm from '../components/create-event/event-invite/EventInviteForm';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const EventInviteScreen = () => {
	const { t } = useTranslation();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/event-invite'));
	}, []);
	return (
		<AuthTemplate
			heading={t('Invite People')}
			subText={t(
				'Hope you have a list ready of all the people you want to invite.'
			)}
			scrollItems={createEventSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<EventInviteForm />
		</AuthTemplate>
	);
};

export default EventInviteScreen;
