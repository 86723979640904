// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDM49XnBi_UgfagtUGCN0i8jB08bdjzWiw',
	authDomain: 'ocpus-chat-7c97b.firebaseapp.com',
	databaseURL: 'http://ocpus-chat-7c97b.firebaseio.com',
	projectId: 'ocpus-chat-7c97b',
	storageBucket: 'ocpus-chat-7c97b.appspot.com',
	messagingSenderId: '600371664742',
	appId: '1:600371664742:web:3b15a626ce32a02deedaa7',
};

// apiKey: 'AIzaSyBT6BH0JkrRyg6Jn4-mpoKp_4oRMvIwUbA',
// 	authDomain: 'ocpus-chat.firebaseapp.com',
// 	databaseURL: 'http://ocpus-chat.firebaseio.com',
// 	projectId: 'ocpus-chat',
// 	storageBucket: 'ocpus-chat.appspot.com',
// 	messagingSenderId: '220772894208',
// 	appId: '1:220772894208:web:3c4a361a28c6d7a1fafae5',

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
