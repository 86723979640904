const GOOGLE_CLIENT_ID =
	'1038740817625-bjhtebr60bekuvhh4vad2610t8d56b82.apps.googleusercontent.com';

const PEOPLE_GOOGLE_API = `https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses,photos`;

const FACEBOOK_CLIENT_ID = '7218786151567118';
const FACEBOOK_CLIENT_SECRET = "10d6c5f9cb9f4a9aa862a37be2bfd461";

const REDIRECT_URI_EVENT = 'https://ocpdev.punchapps.cool/event-upload';
const FACEBOOK_CONFIG_ID = '338970325657540';
// '1080892159191666';
const REDIRECT_URI = 'https://ocpus.io/personal-info';
const FILELINKS = {
	TOC: 'https://ocpus-punch-dev.s3.eu-west-3.amazonaws.com/CGV+Ocpus.pdf',
	PrivacyPolicy:
		'https://ocpus-punch-dev.s3.eu-west-3.amazonaws.com/CGU+Ocpus+App%2BSite+Web.pdf',
	SellerAgreement:
		'https://ocpus-punch-dev.s3.eu-west-3.amazonaws.com/Contrat+Conseill%C3%A8re+X+Ocpus.pdf',
};

export {
	GOOGLE_CLIENT_ID,
	FACEBOOK_CLIENT_ID,
	REDIRECT_URI_EVENT,
	FACEBOOK_CONFIG_ID,
	FACEBOOK_CLIENT_SECRET,
	REDIRECT_URI,
	PEOPLE_GOOGLE_API,
	FILELINKS,
};
