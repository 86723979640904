import {
	Pressable,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import React from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';

import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useMutation } from '@apollo/client';
import { DELETE_CARD, UPDATE_CARD } from '../../../graphql/stripe/mutations';
import { LIST_CARDS } from '../../../graphql/stripe/queries';
import {
	setEditCardModalState,
	setdefaultloading,
	setCardToBeEditted,
	setCardComponentHeight,
	setDefaultCard,
} from '../../../redux/getCardsSlice';
import ModalComponent from '../../common/modal/ModalComponent';
import { Button } from '../..';
import { useTranslation } from 'react-i18next';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

type Props = {
	hide: string;
	cardId: string;
	name: string;
	address: string;
	paymentMethod: string;
	defaultBank?: boolean;
	onCheckout?: string;
	handleSelect?: () => void;
	selectedCard: string;
};

const CardComponent = ({
	hide,
	cardId,
	name,
	address,
	paymentMethod,
	defaultBank = false,
	onCheckout,
	handleSelect,
	selectedCard,
}: Props) => {
	const { t } = useTranslation();
	// const { isLessThanDesktopBase, isTabletView } =
	// 	useIsSpecificViewportWidth();

	const primary = usePrimaryColors();
	const [deleteCardModalState, setDeleteCardModalState] =
		React.useState(false);
	const changeDeleteCardModalState = () => {
		setDeleteCardModalState(!deleteCardModalState);
	};
	const dispatch = useAppDispatch();
	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);
	const [updateCard] = useMutation(UPDATE_CARD);
	const dCard = useAppSelector(state => state.userCards.defaultCard);
	const cards = useAppSelector(state => state.userCards.UserCards);
	const [deleteCard, { loading: deletingCard, data: deleteCardData }] =
		useMutation(DELETE_CARD);
	const billingAddressLine = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address.address1
	);
	const billingAddressCity = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address.city
	);
	const billingAddressZipCode = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address.zipCode
	);

	const getCardToBeEditted = () => {
		let ans: any;
		cards.map(card => {
			if (card.cardId === cardId) {
				ans = card;
			}
		});
		const cardToBeEditted = {
			cardid: ans.cardId,
			name: ans.name,
			month: ans.exp_month,
			year: ans.exp_year,
			cardNum: ans.number,
			line1: ans.address.split(',')[0],
			city: ans.address.split(',')[1],
			state: ans.address.split(',')[2],
			country: ans.address.split(',')[3],
		};

		dispatch(setCardToBeEditted(cardToBeEditted));
	};
	const unDefault = () => {
		updateCard({
			variables: {
				args: {
					id: dCard.cardId,
					default: false,
				},
			},
		});
	};
	const onClickEdit = async () => {
		await dispatch(setdefaultloading(true));
		await updateCard({
			variables: {
				args: {
					id: cardId,
					default: true,
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: LIST_CARDS,
					variables: {
						args: {
							id: stripeId_,
						},
					},
				},
			],
		});

		await dispatch(setdefaultloading(false));
	};

	const getCardToBeDeleted = async () => {
		let cardToBeDeleted: any;
		cards.map(card => {
			if (card.cardId === cardId) {
				cardToBeDeleted = card;
			}
		});
		if (cardToBeDeleted.default) {
			dispatch(
				setDefaultCard({
					cardId: '',
					name: '',
					address: '',
					number: '',
					brand: '',
					default: false,
				})
			);
		}
		await deleteCard({
			variables: {
				args: {
					id: cardToBeDeleted.cardId,
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: LIST_CARDS,
					variables: {
						args: {
							id: stripeId_,
						},
					},
				},
			],
		});
	};

	return (
		<Pressable
			onLayout={event => {
				const { height } = event.nativeEvent.layout;
				dispatch(setCardComponentHeight(height));
			}}
			onPress={handleSelect}
			style={[
				styles.container,
				{
					width: getResponsiveStyle(300),
					// !isLessThanDesktopBase && !onCheckout
					// 	? 1000
					// 	: isTabletView && !onCheckout
					// 	? '45%'
					// 	: onCheckout && !isLessThanDesktopBase
					// 	? '42.5%'
					// 	: onCheckout && isTabletView
					// 	? '45%'
					// 	: '100%',
				},
				selectedCard === cardId && { borderColor: primary[0] },
				// !onCheckout ? {height: getResponsiveStyle(300)} : null
			]}
		>
			<View style={styles.headerContainer}>
				<View style={styles.headerTextContainer}>
					<Text style={styles.textTitile}>
						{t('Card Holder Name')}
					</Text>
					<Text style={styles.textSubtitle}>{name}</Text>
				</View>
				{/* {defaultBank && (
					<View style={styles.defaultIndicator}>
						<Text style={styles.defaultIndicatorText}>
							{t('Default')}
						</Text>
					</View>
				)} */}
			</View>
			<View
				style={[
					styles.contentContainer,
					{ flexDirection: 'column', alignItems: 'flex-start' },
				]}
			>
				<View style={styles.textContainer}>
					<Text style={styles.textTitile}>
						{t('Billing Address')}
					</Text>
					<Text style={styles.textSubtitle}>
						{`${billingAddressLine}, ${billingAddressCity}, ${billingAddressZipCode}`}
					</Text>
				</View>
			</View>
			<View style={styles.contentContainer}>
				<View style={styles.textContainer}>
					<Text style={styles.textTitile}>
						{t('Payment Methood')}
					</Text>
					<Text style={styles.textSubtitle}>{paymentMethod}</Text>
				</View>

				<View style={styles.actionContainer}>
					{/* {hide === 'no' ? (
						<Text
							onPress={() => {
								unDefault();
								onClickEdit();
							}}
							style={styles.hoverText}
						>
							{t('Set as Default')}
						</Text>
					) : null} */}
					<Text
						style={styles.hoverText}
						onPress={() => {
							dispatch(setEditCardModalState()),
								getCardToBeEditted();
						}}
					>
						{t('Edit Details')}
					</Text>

					<Text
						onPress={() => changeDeleteCardModalState()}
						style={styles.hoverText}
					>
						{t('Delete Card')}
					</Text>
				</View>
			</View>
			{deleteCardModalState && (
				<ModalComponent
					header={t('Delete Card')}
					openState={deleteCardModalState}
					onClose={changeDeleteCardModalState}
				>
					{deletingCard ? (
						<Text>{t('Deleting Card...')}</Text>
					) : deleteCardData ? (
						<Text>{t('Card Deleted')}</Text>
					) : (
						<View>
							<Text>
								{t('Are you sure you want to delete?')}{' '}
							</Text>
							<Button
								title={t('Yes')}
								onPress={() => getCardToBeDeleted()}
							/>
						</View>
					)}
				</ModalComponent>
			)}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		// paddingTop: getResponsiveStyle(28),
		padding: getResponsiveStyle(15),
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpaaddress: 1,
		maxWidth: getResponsiveStyle(440),
		width: '100%',
		marginTop: getResponsiveStyle(24),
		marginRight: getResponsiveStyle(15),
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'flex-start',
	},
	actionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: getResponsiveStyle(26),
	},
	headerTextContainer: {
		flexDirection: 'column',
		gap: 4,
	},
	textTitile: {
		color: palettes.grey[1],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	},
	textSubtitle: {
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(21, 'font'),
	},
	defaultIndicator: {
		paddingHorizontal: getResponsiveStyle(17),
		paddingTop: getResponsiveStyle(10),
		paddingBottom: getResponsiveStyle(8),
		backgroundColor: palettes.purple[0],
		borderRadius: 6,
	},
	defaultIndicatorText: {
		color: palettes.light[0],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	},
	contentContainer: {
		// flexDirection: 'row',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
		// justifyContent: 'space-between',
		// alignItems: 'center',

		marginTop: getResponsiveStyle(16),
	},
	textContainer: {
		flexDirection: 'column',
		gap: 6,
	},
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[1],
		cursor: 'pointer',
	},
});

export default CardComponent;
