import {
	CalendarIconFilled,
	CalendarIconOutline,
	HomeIconFilled,
	HomeIconOutline,
	PeopleIconFilled,
	PeopleIconOutline,
	ShoppingBackIconFilled,
	ShoppingBagIconOutline,
} from '../icons';

export const MobileNavigationBarBottomArray = [
	{
		id: 1,
		label: 'Events',
		icon: {
			selected: CalendarIconFilled,
			notSelected: CalendarIconOutline,
		},
		screen: 'Events',
		marginRight: -4,
	},
	{
		id: 0,
		label: 'Home',
		icon: {
			selected: HomeIconFilled,
			notSelected: HomeIconOutline,
		},
		screen: 'Home',
		marginRight: 10,
	},
	{
		id: 2,
		label: 'Network',
		icon: {
			selected: PeopleIconFilled,
			notSelected: PeopleIconOutline,
		},
		screen: 'Network',
		marginRight: 8,
	},
	{
		id: 3,
		label: 'Brands',
		icon: {
			selected: ShoppingBackIconFilled,
			notSelected: ShoppingBagIconOutline,
		},
		screen: 'Brands',
		marginRight: 4,
	},
];

export const GuestMobileNavigationBarBottomArray = [
	{
		id: 1,
		label: 'Live Shopping',
		icon: {
			selected: CalendarIconFilled,
			notSelected: CalendarIconOutline,
		},
		screen: 'Events',
		marginRight: -4,
	},
	{
		id: 0,
		label: 'Home',
		icon: {
			selected: HomeIconFilled,
			notSelected: HomeIconOutline,
		},
		screen: 'Home',
		marginRight: 10,
	},
];
