import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Button } from '../..';
// import { useGetSingleBrandLazy } from '../../../api/brands/useGetSingleBrand';
import useGetBrandRating from '../../../api/rating/useGetBrandRating';
// import useGetEntityRating from '../../../api/rating/useGetEntityRating';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TUseNavigation } from '../../../types/exportedTypes';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CompanyProfileNameRating } from '../../common/page-details-hero/PageProfileNameRating';

export type DropdownCompanyProfileProps = {
	containerStyles?: TStyle;
	name: string;
	_id?: string;
	countryCode?: string;
	location: string;
	rating: number;
	onPressActionButton: () => void;
	onPressContainer: () => void;
	actionLabel: string;
	outlineColor?: string;
	image?: string;
};

export const DropdownCompanyProfile = ({
	containerStyles,
	name,
	countryCode,
	location,
	rating,
	onPressActionButton,
	onPressContainer,
	actionLabel,
	outlineColor,
	_id,
	image,
}: DropdownCompanyProfileProps) => {
	const navigation = useNavigation<TUseNavigation>();
	const primaryColors = usePrimaryColors();
	// const [brandImage, setBrandImage] = useState<string | undefined>('');

	const { executeBrandRatingQuery, data: brandRatingData } =
		useGetBrandRating();

	useEffect(() => {
		executeBrandRatingQuery({
			brandId: _id as string,
		});
	}, []);
	const brandRating = brandRatingData?.getBrandRating?.data?.rating;
	return (
		<View style={[styles.container, styleContainer(), containerStyles]}>
			<CompanyProfileNameRating
				companyName={name}
				countryCode={countryCode}
				location={location}
				profileImage={image}
				rating={brandRating || rating}
				size={'small'}
				onPressContainer={onPressContainer}
			/>
			<Button
				title={actionLabel}
				variant="outline"
				size="sm"
				outlineColor={outlineColor ?? primaryColors[0]}
				onPress={onPressActionButton}
				outerContainerProps={{
					style: {
						minWidth: getResponsiveStyle(80, 'dimensions'),
					},
				}}
			/>
		</View>
	);
};
function styleContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(16),
		marginBottom: getResponsiveStyle(16),
	};
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
});
