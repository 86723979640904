import {
	disableLiveStreamFromIVS,
	showLocksToUnInvitedUser,
} from '../../redux/getEventSlice';
import { isInvitedUser } from '../isAssociatedWithEvent';
import { THandleNavigationParams } from './handleNavigationToEvent.type';
import { navigateToEventDetails } from './navigateToEventDetails';
import { navigateToLiveEvent } from './navigateToLiveEvent';

export const handleNavigationForConsumer = ({
	event,
	stateOfEvent,
	loggedInUserDetails,
	navigation,
	dispatch,
}: THandleNavigationParams) => {
	const isInvitedToEvent = isInvitedUser(event, loggedInUserDetails);
	const eventId = event._id;

	if (isInvitedToEvent) {
		switch (stateOfEvent) {
			case 'Completed':
			case 'Expired':
				navigateToEventDetails(navigation, eventId);
				break;
			case 'NotStarted':
				navigateToEventDetails(navigation, eventId);
				break;
			case 'Ongoing':
			case 'Upcoming':
				navigateToLiveEvent(navigation, eventId);
				break;
			default:
				navigateToEventDetails(navigation, eventId);
		}
	} else {
		switch (stateOfEvent) {
			case 'Completed':
			case 'Expired':
				navigateToEventDetails(navigation, eventId);
				break;
			case 'NotStarted':
				navigateToEventDetails(navigation, eventId);
				break;
			case 'Ongoing':
			case 'Upcoming':
				navigateToLiveEvent(navigation, eventId);
				dispatch(showLocksToUnInvitedUser(true));
				break;
			default:
				navigateToEventDetails(navigation, eventId);
		}
	}
};
