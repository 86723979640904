import { useEffect, useRef } from 'react';

// useEffect that does not run on mounting
// but runs every time the value changes after that
export const useDidMountEffect = (func: any, deps: any[]) => {
	const didMount = useRef(false);

	useEffect(() => {
		if (didMount.current) func();
		else didMount.current = true;
	}, deps);
};
