import { gql } from '@apollo/client';

const CREATE_MESSAGE = gql`
	mutation createMessage(
		$sender: ID!
		$recipients: ID!
		$message: String!
		$messageType: String!
		$conversationId: String
	) {
		createMessage(
			MessageInput: {
				sender: $sender
				recipients: [$recipients]
				message: $message
				messageType: $messageType
				conversationId: $conversationId
			}
		) {
			statusCode
			message
		}
	}
`;

const DELETE_CONVERSATION = gql`
	mutation deleteOneConversation($conversationId: ID!) {
		deleteOneConversation(_id: $conversationId) {
			statusCode
			success
		}
	}
`;

const CREATE_LIVE_EVENT_MESSAGE = gql`
	mutation createLiveEventMessage(
		$sender: ID!
		$message: String
		$eventId: String
	) {
		createLiveEventMessage(
			LiveEventMessageInput: {
				sender: $sender
				message: $message
				eventId: $eventId
			}
		) {
			statusCode
			message
		}
	}
`;

const UPDATE_MESSAGE_RECEIPT = gql`
	mutation updateReadReceipt($recipients: ID!, $conversationId: String) {
		updateReadReceipt(
			MessageInput: {
				recipients: [$recipients]
				conversationId: $conversationId
			}
		) {
			statusCode
			success
			data {
				acknowledged
			}
		}
	}
`;

export {
	CREATE_MESSAGE,
	UPDATE_MESSAGE_RECEIPT,
	CREATE_LIVE_EVENT_MESSAGE,
	DELETE_CONVERSATION,
};
