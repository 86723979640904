import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	hostsInvited: TGetAllUsersData[];
	eventToAddHostsTo: TGetAllEventCommon;
	hostsInvitedIds: string[];
};

const initialState: TInitialState = {
	hostsInvited: [],
	hostsInvitedIds: [],
	eventToAddHostsTo: {
		_id: '',
		name: '',
		description: '',
		affiliatedSeller: {
			_id: '',
			username: '',
			personalInformation: {
				profileImageLink: '',
			},
		},
		affiliatedHosts: [],
		invitedPeople: [],
		typeOfEvent: 'VIRTUAL',
		isApproved: false,
		createdAt: '',
		startingEventDateTime: '',
		eventMedia: [],
		isSellerApplicationOpen: true,
		isHostApplicationAccepted: true,
		affiliatedBrands: [],
		eventStreamTimeline: null,
		eventLocation: {
			address1: '',
			city: '',
			countryCode: '',
			countryLabel: '',
		},
		isStreamGenerated: false,
	},
};

const addHostToEventSlice = createSlice({
	name: 'addHostToEvent',
	initialState,
	reducers: {
		setHostsInvited(state, action: PayloadAction<TGetAllUsersData[]>) {
			state.hostsInvited = action.payload;
		},
		appendHostsInvited(state, action: PayloadAction<TGetAllUsersData[]>) {
			state.hostsInvited = [...state.hostsInvited, ...action.payload];
		},

		removeFromHostsInvited(state, action: PayloadAction<TGetAllUsersData>) {
			state.hostsInvited = state.hostsInvited.filter(
				hostInvited => hostInvited._id !== action.payload._id
			);
		},
		setEventToAddHostsTo(state, action: PayloadAction<TGetAllEventCommon>) {
			state.eventToAddHostsTo = action.payload;
		},
		setHostsInvitedIds(state, action: PayloadAction<string[]>) {
			state.hostsInvitedIds = action.payload;
		},
	},
});

export const {
	setHostsInvited,
	setEventToAddHostsTo,
	appendHostsInvited,
	setHostsInvitedIds,
	removeFromHostsInvited,
} = addHostToEventSlice.actions;

export default addHostToEventSlice.reducer;
