import { useLazyQuery } from '@apollo/client';
import { GET_MEDIA } from '../../graphql/common/queries';

const useGetMedia = () => {
	const [getMedia, { data, error, loading }] = useLazyQuery(GET_MEDIA);

	const executeMediaQuery = ({ _id }: { _id: string }) => {
		getMedia({
			variables: {
				_id,
			},
		});
	};
	return { executeMediaQuery, data, error, loading };
};

export default useGetMedia;
