import { gql } from '@apollo/client';
export const GET_USER_TRANSACTIONS = gql`
	query getSellerTransactions($find: JSON, $current: Int, $limit: Int) {
		getSellerTransactions(
			current: $current
			find: $find
			limit: $limit
			populate: [
				"associatedUser"
				{
					path: "items"
					model: "Item"
					populate: {
						path: "variant"
						model: "Variant"
						populate: {
							path: "associatedProduct"
							model: "Product"
							populate: [
								{ path: "brand", model: "Brand" }
								{ path: "images", model: "Media" }
							]
						}
					}
				}
				{
					path: "items"
					model: "Item"
					populate: { path: "starterKit", model: "StarterKit" }
				}
				"brands"
			]
		) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						... on Order {
							_id
							brands {
								_id
								name
							}
							purchasedTotalAmount
							orderStatus
							orderId
							purchasedOn
							eventType
							deliveryFee
							associatedUser {
								_id
								username
								personalInformation {
									profileImageLink
									firstName
									lastName
									address {
										city
										countryCode
										countryLabel
										region
									}
								}
							}
							items {
								affiliatedSellerId
								optionValue
								variant {
									_id
									vat
									option {
										name
										values {
											name
											price
											compareAtPrice
										}
										inventory {
											quantity
										}
									}
									image {
										alt
										src
									}
									associatedProduct {
										_id
										name
										description
										shortDescription
										category
										publishedStatus
										brand {
											name
											_id
											connectAccountInfo {
												connectAccountId
												lastUrl
											}
										}
										images {
											name
											alt
											src
											type
										}
									}
								}
								starterKit {
									_id
									name

									description
									price
									compareAtPrice
								}
								itemType
								quantity
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_ORDERS = gql`
	query getAllUserOrders(
		$_id: ID
		$find: JSON
		$current: Int
		$limit: Int
		$sort: JSON
	) {
		getAllUserOrders(
			_id: $_id
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: [
				"associatedUser"
				{
					path: "items"
					model: "Item"
					populate: {
						path: "variant"
						model: "Variant"
						populate: {
							path: "associatedProduct"
							model: "Product"
							populate: [
								{ path: "brand", model: "Brand" }
								{ path: "images", model: "Media" }
							]
						}
					}
				}
				{
					path: "items"
					model: "Item"
					populate: { path: "starterKit", model: "StarterKit" }
				}
				"brands"
			]
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on Order {
								_id
								paidWith
								purchasedTotalAmount
								orderStatus
								deliveryFee
								associatedUser {
									_id
									username
								}
								brands {
									_id
									name
								}
								orderId
								purchasedOn

								items {
									optionValue
									variant {
										_id
										variator
										value
										vat
										option {
											name
											values {
												name
												price
												compareAtPrice
											}
											inventory {
												quantity
											}
										}
										image {
											alt
											src
										}

										associatedProduct {
											_id
											name
											description
											category
											shortDescription
											publishedStatus
											brand {
												name
												_id
												connectAccountInfo {
													connectAccountId
													lastUrl
												}
											}
											images {
												name
												alt
												src
												type
											}
										}
									}
									starterKit {
										_id
										name

										description
										price
										compareAtPrice
									}
									itemType
									quantity
									affiliatedSellerId
									affiliatedHost
									price
									vat
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_SELLERS_ORDERS = gql`
	query getOrdersSeller($args: GetStripeTranserInput) {
		getOrdersSeller(args: $args) {
			statusCode
			success
			data {
				total
				data {
					... on StripeTransfer {
						transferId
						amount
						transactionId
						transferedAt
						currency
						connectAccount
					}
				}
			}
		}
	}
`;

export const GET_SELLERS_ORDERS_TODAY = gql`
	query getOrdersSellerToday($args: GetStripeTranserInput) {
		getOrdersSellerToday(args: $args) {
			statusCode
			success
			data {
				total
				data {
					... on StripeTransfer {
						transferId
						amount
						transactionId
						transferedAt
						currency
						connectAccount
					}
				}
			}
		}
	}
`;

export const GET_SELLERS_ORDERS_YESTERDAY = gql`
	query getOrdersSellerYesterday($args: GetStripeTranserInput) {
		getOrdersSellerYesterday(args: $args) {
			statusCode
			success
			data {
				total
				data {
					... on StripeTransfer {
						transferId
						amount
						transactionId
						transferedAt
						currency
						connectAccount
					}
				}
			}
		}
	}
`;
export const GET_SINGLE_ORDER = gql`
	query getOrderById($_id: ID!) {
		getOrderById(
			_id: $_id
			populate: [
				"associatedUser"
				{
					path: "items"
					model: "Item"
					populate: {
						path: "variant"
						model: "Variant"
						populate: {
							path: "associatedProduct"
							model: "Product"
							populate: [
								{ path: "brand", model: "Brand" }
								{ path: "images", model: "Media" }
							]
						}
					}
				}
				{
					path: "items"
					model: "Item"
					populate: { path: "starterKit", model: "StarterKit" }
				}
				"brands"
			]
		) {
			statusCode
			success
			message
			data {
				... on Order {
					_id
					paidWith
					purchasedTotalAmount
					orderStatus
					deliveryFee
					associatedUser {
						_id
						username
					}
					brands {
						_id
						name
					}
					orderId
					purchasedOn

					items {
						optionValue
						variant {
							_id
							variator
							value
							vat
							option {
								name
								values {
									name
									price
									compareAtPrice
								}
								inventory {
									quantity
								}
							}
							image {
								alt
								src
							}

							associatedProduct {
								_id
								name
								description
								category
								shortDescription
								publishedStatus
								brand {
									name
									_id
									connectAccountInfo {
										connectAccountId
										lastUrl
									}
								}
								images {
									name
									alt
									src
									type
								}
							}
						}
						starterKit {
							_id
							name

							description
							price
							compareAtPrice
						}
						itemType
						quantity
						affiliatedSellerId
						affiliatedHost
						price
						vat
					}
				}
			}
		}
	}
`;
