import * as React from 'react';
import Svg, {
	SvgProps,
	Path,
	Defs,
	LinearGradient,
	Stop,
} from 'react-native-svg';

const NotePageIconFilled = (props: SvgProps) => (
	<Svg fill="none" viewBox="0 0 24 24" {...props}>
		<Path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18 9c0-3.75 0-5.625-.955-6.939A5 5 0 0 0 15.94.955C14.625 0 12.749 0 9 0 5.25 0 3.375 0 2.061.955A5 5 0 0 0 .955 2.06C0 3.375 0 5.251 0 9v2c0 .839 0 1.584.01 2.25h.07c.853 0 1.372 0 1.82.07a5.75 5.75 0 0 1 4.78 4.78c.07.448.07.967.07 1.82v.07C7.416 20 8.161 20 9 20c3.75 0 5.625 0 6.939-.955a5 5 0 0 0 1.106-1.106C18 16.625 18 14.749 18 11V9Zm-8-3.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Zm-5 4a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H5Zm5 4a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z"
			fill="url(#a)"
		/>
		<Path
			d="M1.665 14.802c-.309-.048-.69-.052-1.6-.052.095 1.44.326 2.412.89 3.189a5 5 0 0 0 1.106 1.106c.777.564 1.75.795 3.189.89 0-.91-.003-1.291-.052-1.6a4.25 4.25 0 0 0-3.533-3.533Z"
			fill="url(#b)"
		/>
		<Defs>
			<LinearGradient
				id="a"
				x1={2.935}
				y1={5}
				x2={22.008}
				y2={20.356}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
			<LinearGradient
				id="b"
				x1={2.935}
				y1={5}
				x2={22.008}
				y2={20.356}
				gradientUnits="userSpaceOnUse"
			>
				<Stop stopColor="#6645F4" />
				<Stop offset={1} stopColor="#12BCF3" />
			</LinearGradient>
		</Defs>
	</Svg>
);

export default NotePageIconFilled;
