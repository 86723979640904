import {
	setDisableDailyRoomNStream,
	showLocksToUnInvitedUser,
} from '../../redux/getEventSlice';
import { isOwnerOfEvent } from '../isAssociatedWithEvent';
import { THandleNavigationParams } from './handleNavigationToEvent.type';
import { navigateToEventDetails } from './navigateToEventDetails';
import { navigateToLiveEvent } from './navigateToLiveEvent';

export const handleNavigationForSellerAndHost = ({
	event,
	stateOfEvent,
	loggedInUserDetails,
	navigation,
	dispatch,
}: THandleNavigationParams) => {
	const isAffiliatedUser = isOwnerOfEvent(event, loggedInUserDetails);
	const eventId = event?._id;

	if (isAffiliatedUser) {
		switch (stateOfEvent) {
			case 'Completed':
			case 'Expired':
				navigateToEventDetails(navigation, eventId);
				break;
			case 'Ongoing':
			case 'Upcoming':
				navigateToLiveEvent(navigation, eventId);
				break;
			case 'NotStarted':
				navigateToEventDetails(navigation, eventId);
				break;
			default:
				navigateToEventDetails(navigation, eventId);
		}
	} else {
		switch (stateOfEvent) {
			case 'Upcoming':
			case 'Completed':
			case 'Expired':
				navigateToEventDetails(navigation, eventId);
				break;
			case 'NotStarted':
				navigateToEventDetails(navigation, eventId);
				break;
			case 'Ongoing':
				navigateToLiveEvent(navigation, eventId);
				dispatch(showLocksToUnInvitedUser(true));
				break;
			default:
				navigateToEventDetails(navigation, eventId);
		}
	}
};
