import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { breakpoints } from '../config';

export const useIsSpecificViewportWidth = () => {
	const { width, height } = useWindowDimensions();
	const widthBooleans = useMemo(
		() => ({
			width,
			height,
			isTabletView:
				width >= breakpoints.tabletSm &&
				width <= breakpoints.desktopBase,
			isMobileView: width < breakpoints.tabletSm,
			isDesktop: width >= breakpoints.desktopBase,
			isLessThanDesktopBase: width < breakpoints.desktopBase,
			isLessThanDesktopMedium: width < breakpoints.desktopMd,
			isLessThanDesktopSmall: width < breakpoints.desktopSm,
		}),
		[width, height]
	);
	return widthBooleans;
};
