import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const PhoneHandleIcon = (props: SvgProps) => (
	<Svg width={24} height={14} fill="none" viewBox="0 0 16 12" {...props}>
		<Path
			d="M11.824 4.273a7.917 7.917 0 0 0-4.192 0l.188 1.125a.75.75 0 0 1-.53.843A8.566 8.566 0 0 0 4.137 7.95a.75.75 0 0 1-1.018-.04L.753 5.541a.75.75 0 0 1-.051-1.004c.252-.31.52-.608.806-.893 4.54-4.54 11.9-4.54 16.44 0 .285.285.554.583.806.893a.75.75 0 0 1-.051 1.004l-2.367 2.366a.75.75 0 0 1-1.017.04 8.566 8.566 0 0 0-3.154-1.707.75.75 0 0 1-.529-.843l.188-1.125Zm1.686-.99-.288 1.728c.903.331 1.754.79 2.527 1.363l1.398-1.398a10.399 10.399 0 0 0-.26-.27C12.933.751 6.523.751 2.568 4.706a8.891 8.891 0 0 0-.26.27l1.399 1.398a10.057 10.057 0 0 1 2.527-1.363l-.288-1.727a9.332 9.332 0 0 1 1.24-.445l.048-.013a9.417 9.417 0 0 1 4.988 0l.048.013c.423.12.837.268 1.24.445Z"
			fill="#fff"
		/>
	</Svg>
);

export default PhoneHandleIcon;
