import * as React from 'react';
import Svg, { SvgProps, Circle, Path } from 'react-native-svg';

const FemaleGenderIcon = (props: SvgProps) => (
	<Svg width={24} height={24} fill="none" viewBox="0 0 22 22" {...props}>
		<Circle
			cx={11}
			cy={11}
			r={10}
			fill="#E0DAFD"
			stroke="#fff"
			strokeWidth={2}
		/>
		<Path
			fill="#6645F4"
			d="M10.597 11.844a3.017 3.017 0 0 1-1.921-1.053 2.893 2.893 0 0 1-.67-2.052 2.91 2.91 0 0 1 .944-1.946A3.037 3.037 0 0 1 10.997 6a3.04 3.04 0 0 1 2.051.79 2.91 2.91 0 0 1 .945 1.949 2.897 2.897 0 0 1-.67 2.054 3.017 3.017 0 0 1-1.926 1.05v.81h2v.782h-2V15h-.8v-1.565h-2v-.783h2v-.808zm.4-.757c.584 0 1.143-.227 1.556-.63.412-.404.644-.951.644-1.522 0-.571-.232-1.118-.644-1.522a2.225 2.225 0 0 0-1.556-.63c-.583 0-1.143.226-1.555.63a2.129 2.129 0 0 0-.645 1.522c0 .57.232 1.118.645 1.522.412.403.972.63 1.555.63z"
		/>
	</Svg>
);

export default FemaleGenderIcon;
