import React, { useState } from 'react';
import { Platform, Pressable, StyleSheet, TextInput, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { BlurView } from 'expo-blur';
import { useTranslation } from 'react-i18next';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { palettes } from '../../../config';

type Props = {
	onMessageSend?: (e?: TODO) => void;
	handleChange?: (e: string) => void;
	handleTypingFocusIn?: (e?: string) => void;
	handleTypingFocusOut?: (e?: string) => void;
	setIsFocused: (e?: boolean) => void;
	showUpload?: boolean;
	message?: string;
};

const EventChatInput = ({
	onMessageSend,
	handleChange,
	setIsFocused,
	message,
}: Props) => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	return (
		<View>
			<BlurView intensity={40} tint={'default'} style={styles.container}>
				<TextInput
					placeholder={t('Enter your chat here') + '...'}
					style={[
						Platform.OS === 'web' &&
							({ outlineColor: primary[0] } as any),
						styles.input,
					]}
					value={message}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onChangeText={handleChange}
					onSubmitEditing={onMessageSend}
				/>
				<Pressable style={styles.svg} onPress={onMessageSend}>
					<Svg width="40" height="40" viewBox="0 0 20 20" fill="none">
						<Path
							d="M0.494 0.938737L12.802 7.70807C12.8543 7.73685 12.8978 7.77912 12.9282 7.83048C12.9586 7.88184 12.9746 7.94041 12.9746 8.00007C12.9746 8.05973 12.9586 8.1183 12.9282 8.16966C12.8978 8.22102 12.8543 8.2633 12.802 8.29207L0.494 15.0614C0.443249 15.0893 0.386099 15.1035 0.328182 15.1026C0.270265 15.1017 0.213582 15.0858 0.163718 15.0563C0.113854 15.0268 0.072531 14.9849 0.0438223 14.9346C0.0151136 14.8842 9.91698e-06 14.8273 0 14.7694V1.23074C9.91698e-06 1.17281 0.0151136 1.11589 0.0438223 1.06558C0.072531 1.01528 0.113854 0.973316 0.163718 0.943842C0.213582 0.914368 0.270265 0.898396 0.328182 0.897501C0.386099 0.896606 0.443249 0.910818 0.494 0.938737ZM1.33333 8.66674V13.0781L10.5667 8.00007L1.33333 2.92207V7.3334H4.66667V8.66674H1.33333Z"
							fill="#7C889B"
						/>
					</Svg>
				</Pressable>
			</BlurView>
		</View>
	);
};
const styles = StyleSheet.create({
	input: {
		// borderWidth: 1,
		// borderColor: palettes.grey[4],
		background: 'rgba(255, 255, 255, 0.1)',
		fontSize: 16,
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		height: 47,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		paddingLeft: '25px',
		paddingRight: '55px',
		color: palettes.light[0],
	},
	svg: {
		position: 'absolute',
		// top: '34%',
		right: 1,
		// backgroundColor: "red",
		height: '100%',
		width: 45,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	container: {
		position: 'relative',
		zIndex: 10,
		borderWidth: 3,
		borderColor: 'transparent',
		borderRadius: 6,
	},
});

export default EventChatInput;
