import { gql } from '@apollo/client';

export const RATE_ENTITY = gql`
	mutation rateEntity($args: RatingInputs) {
		rateEntity(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Rating {
						_id
						parentId
					}
				}
			}
		}
	}
`;
