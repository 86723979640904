import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setAlerts } from '../../../redux/uiSlice';
import { Alert } from './alert';

const Alerts = () => {
	const dispatch = useAppDispatch();
	const errors = useAppSelector(s => s.ui.alerts);

	const pathName = window.location.pathname?.split('/')?.[1];
	const { isLessThanDesktopBase, isMobileView } =
		useIsSpecificViewportWidth();
	const alertAppearBelow = [
		'personal-info',
		'your-information',
		'seller-agreement',
		'credit-card',
		'signup-complete',
	].includes(pathName);

	useEffect(() => {
		let timeout: NodeJS.Timeout | null = null;
		if (errors.length > 0) {
			timeout = setTimeout(() => {
				dispatch(setAlerts([]));
			}, 5000);
		}
		return () => {
			timeout !== null && clearTimeout(timeout);
		};
	}, [errors]);

	const handlePressClose = (id: number) => {
		dispatch(setAlerts(errors.filter((e, i) => i !== id)));
	};

	const positioning =
		alertAppearBelow && isLessThanDesktopBase
			? {
					bottom: 5,
					left: 0,
			  }
			: {
					top: 5,
					left: 0,
			  };

	return (
		<View
			style={[
				styles.container,
				Platform.select({
					native: positioning,
					default: positioning,
				}),
			]}
		>
			{errors.map((error, i) => (
				<Alert
					description={error.description}
					title={error.title}
					variant={error.type}
					key={error.title + i}
					onPressClose={handlePressClose}
					id={i}
				/>
			))}
		</View>
	);
};

export default Alerts;

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		// left: 0,
		// right: 0,
		zIndex: 9999,
		// backgroundColor: 'white',
		width: '100%',
		height: 'max-content',
	},
});
