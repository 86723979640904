import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UpdateQty = {
	id: string;
	qty: number;
};

type TInitialState = {
	stats: UserDashboardStats;
	cart: Cart;
	visitorCartStore: Cart;
	loadingCart: boolean;
	followedIds: string[];
	networkFollowedIds: string[];
	isFollowingResult: TIsFollowingData[];
	followMeIds: string[];
	isFollowingMe: TODO[];
	defaultAddressBook: TAddressBook;
	paymentVouchers: TODO[];
	wasCardUsed: boolean;
	globalCartOpen: boolean;
	buyingFromEvent: any;
	signUpSubscribeEvent: TGetAllEventCommon;
};

const initialState: TInitialState = {
	stats: {
		eventsCount: 0,
		publishedEventsCount: 0,
		followersCount: 0,
		attendedEventsCount: 0,
		eventsInvitedCount: 0,
	},
	cart: [],
	visitorCartStore: [],
	loadingCart: false,
	followedIds: [],
	networkFollowedIds: [],
	isFollowingResult: [],
	followMeIds: [],
	isFollowingMe: [],
	defaultAddressBook: {} as TAddressBook,
	paymentVouchers: [],
	wasCardUsed: false,
	globalCartOpen: false,
	buyingFromEvent: null,
	signUpSubscribeEvent: null,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserStats(state, action: PayloadAction<UserDashboardStats>) {
			state.stats = action.payload;
		},
		setUsersCart(state, action: PayloadAction<Cart>) {
			state.cart = action.payload;
		},
		setVisitorCart(state, action: PayloadAction<Cart>) {
			state.visitorCartStore = action.payload;
		},
		setGlobalCartOpen(state, action: PayloadAction<boolean>) {
			state.globalCartOpen = action.payload;
		},
		setLoadingCart(state, action: PayloadAction<boolean>) {
			state.loadingCart = action.payload;
		},
		setIsFollowingResult(state, action: PayloadAction<TIsFollowingData[]>) {
			state.isFollowingResult = action.payload;
		},
		setFollowedIds(state, action: PayloadAction<string[]>) {
			state.followedIds = action.payload;
		},
		setNetworkFollowedIds(state, action: PayloadAction<string[]>) {
			state.networkFollowedIds = action.payload;
		},
		setFollowMeIds(state, action: PayloadAction<string[]>) {
			state.followMeIds = action.payload;
		},
		setIsFollowingMeResult(state, action: PayloadAction<TODO[]>) {
			state.isFollowingMe = action.payload;
		},
		setDefaultAddress(state, action: PayloadAction<TAddressBook>) {
			state.defaultAddressBook = action.payload;
		},
		setPaymentVouchers(state, action: PayloadAction<TODO[]>) {
			state.paymentVouchers = action.payload;
		},
		setWasCardUsed(state, action: PayloadAction<boolean>) {
			state.wasCardUsed = action.payload;
		},
		setBuyingFromEvent(state, action: PayloadAction<any>) {
			state.buyingFromEvent = action.payload;
		},
		setSignUpSubscribeEvent(
			state,
			action: PayloadAction<TGetAllEventCommon>
		) {
			state.signUpSubscribeEvent = action.payload;
		},
	},
});

export const {
	setUserStats,
	setUsersCart,
	setVisitorCart,
	setLoadingCart,
	setIsFollowingResult,
	setFollowedIds,
	setFollowMeIds,
	setIsFollowingMeResult,
	setDefaultAddress,
	setPaymentVouchers,
	setWasCardUsed,
	setGlobalCartOpen,
	setBuyingFromEvent,
	setNetworkFollowedIds,
	setSignUpSubscribeEvent,
} = userSlice.actions;

export default userSlice.reducer;
