import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { GOOGLE_AUTH } from '../../graphql/auth/mutations';
import { TUseNavigation } from '../../types/exportedTypes';

const useGoogleAuth = () => {
	const navigation = useNavigation<TUseNavigation>();
	const [googleAuth, states] = useMutation(GOOGLE_AUTH);

	const executeGoogleAuthMutation = ({
		token,
		isCompleteSignUpFromBackend,
	}: {
		token: string;
		isCompleteSignUpFromBackend?: boolean;
	}) => {
		googleAuth({
			variables: {
				token,
				isCompleteSignUpFromBackend,
			},
		});
	};
	return { executeGoogleAuthMutation, ...states };
};

export default useGoogleAuth;
