import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { palettes } from '../../../config';
import { useDidMountEffect } from '../../../hooks/useDidMountEffect';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { millisecondsInHoursSecondsAndMinutes } from '../../../utils/monthsUtils';
import { EyeOpenIcon, UserIcon2 } from '../../common/icons';
import MicFilledIcon from '../../common/icons/Mic';
import { LiveEventIconWithContent } from './LiveEventIconWithContent';

type Props = {
	totalUsersInRoom: number;
	totalInvitedPeople: number;
	liveStreamStartedAtTime: string | null | undefined;
	isHostRequired: boolean;
	isLiveStreaming: boolean;
	typeOfAccount?: TTypeOfAccount;
};

export const getTotalInvitedPeople = (
	totalInvitedPeople: number,
	isHostRequired: boolean
) => {
	if (isHostRequired) {
		return totalInvitedPeople + 2;
	} else {
		return totalInvitedPeople + 1;
	}
};

export const calculateTimeFromSpecificTime = (time: string) => {
	const someTimeInFuture = dayjs().unix() * 1000;
	const startingTime = Number(time);

	const difference = someTimeInFuture - startingTime;

	return millisecondsInHoursSecondsAndMinutes(difference);
};

export const LiveEventStats = ({
	totalInvitedPeople,
	totalUsersInRoom,
	isHostRequired,
	liveStreamStartedAtTime,
	isLiveStreaming,
	typeOfAccount,
}: Props) => {
	const [seconds, setSeconds] = useState(0);
	const [timeSinceLiveStreamStarted, setTimeSinceLiveStreamStarted] =
		useState('');
	const [intervalInstance, setIntervalInstance] = useState<NodeJS.Timer>();

	useEffect(() => {
		let interval: NodeJS.Timer;
		if (liveStreamStartedAtTime) {
			interval = setInterval(() => {
				setSeconds(seconds + 1);
				const _time = calculateTimeFromSpecificTime(
					liveStreamStartedAtTime
				);
				setTimeSinceLiveStreamStarted(_time);
			}, 1000);
			setIntervalInstance(intervalInstance);
		}

		return () => {
			if (liveStreamStartedAtTime) {
				clearInterval(interval);
			}
			if (intervalInstance) {
				clearInterval(intervalInstance);
			}
		};
	}, [seconds]);

	useDidMountEffect(() => {
		if (!isLiveStreaming && intervalInstance) {
			clearInterval(intervalInstance);
		}
	}, [isLiveStreaming]);

	const showEventStartedAtTime =
		liveStreamStartedAtTime &&
		(isLiveStreaming || typeOfAccount === 'CONSUMER');

	return (
		<View style={styleContainer()}>
			<LiveEventIconWithContent
				icon={<EyeOpenIcon width={16} height={16} fill="white" />}
				stat={totalUsersInRoom}
			/>
			<LiveEventIconWithContent
				icon={<UserIcon2 width={16} height={16} fill="white" />}
				stat={getTotalInvitedPeople(totalInvitedPeople, isHostRequired)}
			/>
			{showEventStartedAtTime ? (
				<LiveEventIconWithContent
					isLastElement
					icon={<MicFilledIcon fillColor="white" />}
					time={timeSinceLiveStreamStarted}
				/>
			) : (
				<View style={{ minWidth: 90 }}>00:00:00</View>
			)}
		</View>
	);
};

const styleContainer = () => {
	return [
		styles.container,
		Platform.OS === 'web' &&
			({
				backdropFilter: `blur(40px)`,
			} as any),
	];
};

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		backgroundColor: `${palettes.light[0]}10`,
		right: getResponsiveStyle(24),
		top: getResponsiveStyle(24),
		paddingHorizontal: getResponsiveStyle(18),
		paddingVertical: getResponsiveStyle(12),
		borderRadius: 6,
		flexDirection: 'row',
		alignItems: 'center',
		minWidth: 250,
		justifyContent: 'space-between',
	},
});
