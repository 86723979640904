import { useMutation } from '@apollo/client';
import { DELETE_EVENT } from '../../graphql/events/mutations';
import { useAppDispatch } from '../../redux/store';

const useDeleteEvent = () => {
	// const dispatch = useAppDispatch();

	const [deleteEvent, { data, error, loading }] = useMutation(DELETE_EVENT);

	const executeDeleteEventMutation = ({ _id }: { _id: string }) => {
		deleteEvent({
			variables: {
				_id,
			},
		});
	};
	return { executeDeleteEventMutation, data, error, loading };
};

export default useDeleteEvent;
