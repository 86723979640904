import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	View,
} from 'react-native';
import {
	DumbComponentForFlex,
	PersonalInformationForm,
	SellerAgreementForm,
	YourInformationForm,
} from '../..';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import CreditCardInformationForm from '../../auth/credit-card-information/CreditCardInformationForm';
import TopNavigationScroll from '../../common/templates/steps-template/top-navigation-scroll/TopNavigationScroll';
import ProfileEditInformationTemplate from './AccountPersonalInformation';
import SidebarNavigationTab from './SidebarNavigationTab';

const ProfileAccount = () => {
	const { t } = useTranslation();
	const { isLessThanDesktopMedium } = useIsSpecificViewportWidth();

	const { EditUserSidebarItems, typeOfAccount } = useAppSelector(state => ({
		typeOfAccount: state.auth.loggedInUserDetails?.typeOfAccount,
		EditUserSidebarItems: state.ui.EditUserSidebarItems,
	}));

	const [selectedTabId, setSelectedTabId] = useState(0);

	const changeSelectedIndex = (value: number) => {
		setSelectedTabId(value);
	};
	return (
		<ScrollView
			contentContainerStyle={styles.containerContent}
			style={[styles.container, styleContainer()]}
			showsVerticalScrollIndicator={false}
		>
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'position' : 'padding'}
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: isLessThanDesktopMedium
						? 'center'
						: 'flex-start',
				}}
			>
				{!isLessThanDesktopMedium && (
					<SidebarNavigationTab
						changeSelectedIndex={changeSelectedIndex}
						selectedIndex={selectedTabId}
						scrollItems={EditUserSidebarItems}
					/>
				)}

				{!isLessThanDesktopMedium && (
					<DumbComponentForFlex flex={0.69} />
				)}
				<View
					style={{
						maxWidth: getResponsiveStyle(464, 'dimensions'),
						width: '100%',
					}}
				>
					{isLessThanDesktopMedium && (
						<TopNavigationScroll
							scrollItems={EditUserSidebarItems}
							changeSelectedIndex={changeSelectedIndex}
							selectedIndex={selectedTabId}
							type="Profile"
						/>
					)}

					<View
						style={[
							{
								width: '100%',
								paddingRight: getResponsiveStyle(15),
								paddingVertical: getResponsiveStyle(15),
							},
						]}
					>
						{selectedTabId === 0 && (
							<ProfileEditInformationTemplate
								heading={t('Personal Information')}
							>
								<PersonalInformationForm isProfile />
							</ProfileEditInformationTemplate>
						)}
						{selectedTabId === 1 && typeOfAccount === 'SELLER' && (
							<ProfileEditInformationTemplate
								heading={t('Seller Agreement')}
							>
								<SellerAgreementForm isProfile />
							</ProfileEditInformationTemplate>
						)}
						{selectedTabId === 2 && (
							<ProfileEditInformationTemplate
								heading={t('Your Information')}
							>
								<YourInformationForm isProfile />
							</ProfileEditInformationTemplate>
						)}
						{selectedTabId === 3 && (
							<ProfileEditInformationTemplate
								heading={t('Credit Card Information')}
							>
								<CreditCardInformationForm isProfile />
							</ProfileEditInformationTemplate>
						)}
					</View>
				</View>
				{!isLessThanDesktopMedium && <DumbComponentForFlex flex={1} />}
			</KeyboardAvoidingView>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	containerContent: {
		// alignItems: 'center',
		minHeight: '100%',
	},
	container: {
		width: '100vw',
		minHeight: '100%',
	},
});

export default ProfileAccount;

const styleContainer = () => {
	return {
		paddingHorizontal: getResponsiveStyle(15),
		// paddingVertical: getResponsiveStyle(70),
	} as TStyle;
};
