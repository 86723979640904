import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate } from '../components';
import EventProductForm from '../components/create-event/event-product/EventProductForm';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const EventProductScreen = (props: { navigation: any }) => {
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/event-product'));
	}, []);

	return (
		<AuthTemplate
			heading={t('Add Brand')}
			subText={t(
				'Lets choose to display all the products you want to highlight in the event'
			)}
			scrollItems={createEventSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<EventProductForm />
		</AuthTemplate>
	);
};

export default EventProductScreen;
