import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

interface Props {
	text: string | string[];
	setModalState?: () => void;
	onboard?: () => void;
}
const AddCardBankPlaceholder = ({ text, setModalState, onboard }: Props) => {
	const { t } = useTranslation();
	const cardHeight = useAppSelector(
		state => state.userCards.cardComponentHeight
	);
	const bankheight = useAppSelector(
		state => state.userBanks.bankComponentHeight
	);
	const getHeight = () => {
		return { height: getResponsiveStyle(150) };
	};
	const dispatch = useAppDispatch();
	return (
		<View style={[getHeight(), styles.container]}>
			<TouchableOpacity
				onPress={() => {
					if (onboard && setModalState) {
						onboard();
						dispatch(setModalState());
					}
				}}
			>
				<Text
					style={{
						textAlign: 'center',
						color: palettes.grey[1],
						fontWeight: '400',
						fontSize: 20,
					}}
				>
					+
				</Text>
				<Text
					style={{
						textAlign: 'center',
						color: palettes.grey[1],
						fontWeight: '400',
						fontSize: 20,
						textDecorationLine: 'underline',
					}}
				>
					{t('Add your') + ' ' + t(text)}
				</Text>
			</TouchableOpacity>
		</View>
	);
};

export default AddCardBankPlaceholder;

const styles = StyleSheet.create({
	container: {
		paddingTop: getResponsiveStyle(28),
		padding: getResponsiveStyle(24),
		borderStyle: 'dashed',
		borderRadius: 6,
		borderColor: palettes.grey[4],
		borderWidth: 1,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpaaddress: 1,
		maxWidth: getResponsiveStyle(440),
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		width: getResponsiveStyle(350),
		marginTop: getResponsiveStyle(20),
	},
});
