import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { Button, Loader } from '../..';
import { palettes } from '../../../config';
import { DELETE_NOTIFICATION } from '../../../graphql/notification/mutation';
import { GET_ALL_NOTIFICATIONS } from '../../../graphql/notification/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProfileIcon from '../ProfileIcon';
import {
	TNotificationMessage,
	TypeOfNotification,
} from '../../../types/notificationTypes';

const getSecondsFromDate = 1000;
const getMinuteFromDate = 60000;
const getHourFromDate = 3600000;
const getDayFromDate = 86400000;
const aDay = 24;
const aMinute = 60;

const StyledClearText = styled(Text)`
	font-size: ${getResponsiveStyle(11)}px;
	line-height: ${getResponsiveStyle(11)}px;
	color: ${palettes.red[0]};
	cursor: pointer;
	padding-bottom: 2;
	border-bottom: 1px solid ${palettes.red[0]};
`;

export const ProfileViewNotification = ({
	notification,
	hovered,
	removeFromNotification,
}: {
	notification?: NotificationType;
	hovered?: boolean;
	removeFromNotification: (id: string) => void;
}) => {
	const navigation = useNavigation<TUseNavigation>();
	const ref = useRef(null);

	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const isHovered = useHover(ref);
	const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
		onCompleted: data => {
			// if (data && data.deleteNotification.success) {
			// 	setAlert(t('Notification successfully deleted'), 'normal');
			// } else {
			// 	setAlert(t('Could not delete notification'), 'danger');
			// }
		},
		onError: () => {
			setAlert(t('Could not delete notification'), 'danger');
		},
	});

	// if (loading) {
	// 	return <Loader size={'small'} />;
	// }

	return (
		<View style={[styles.container, styleContainer()]} ref={ref}>
			<>
				<ProfileIcon
					style={styleProfileIcon()}
					imageSrc={
						notification?.sentBy?.personalInformation
							?.profileImageLink
					}
				/>
				<View style={{ flex: 1 }}>
					<View
						style={[
							styles.detailsContainer,
							styleDetailsContainer(),
						]}
					>
						<Text
							style={[
								styles.name,
								styleName(),
								isHovered && { width: 200 },
							]}
							numberOfLines={isHovered && 1}
						>
							{notification?.sentBy?.username}
						</Text>
						{isHovered && (
							<TouchableOpacity
								onPress={() => {
									removeFromNotification(
										notification?._id ?? ''
									);
									deleteNotification({
										variables: {
											args: {
												id: notification?._id,
											},
										},
									});
								}}
							>
								<StyledClearText>Clear</StyledClearText>
							</TouchableOpacity>
						)}

						{/* {isHovered && <StyledClearText>Clear</StyledClearText>} */}
					</View>
					<Text
						style={[styles.invitationText, styleInvitationText()]}
					>
						{notification?.message ===
						TNotificationMessage.FOLLOW_ME_NOTIFICATION
							? notification?.sentBy?.username +
							  ' ' +
							  t('has requested you to follow their account')
							: notification?.message ===
							  TNotificationMessage.FOLLOW_NOTIFICATION
							? notification?.sentBy?.username +
							  ' ' +
							  t('has just followed you')
							: notification?.message ===
							  TNotificationMessage.UNFOLLOW_NOTIFICATION
							? notification?.sentBy?.username +
							  ' ' +
							  t('has just unfollowed you')
							: notification?.message ===
									TNotificationMessage.REMOVE_NOTIFICATION &&
							  notification?.sentBy?.username +
									' ' +
									t('has removed you from following them')}

						{/* Hosting notification response */}
						{notification?.notificationType ===
							TypeOfNotification.EVENT_HOSTING_NOTIFICATION_RESPONSE &&
						notification?.message ===
							TNotificationMessage.EVENT_HOSTING_NOTIFICATION_ACCEPTED
							? notification?.sentBy?.username +
							  ' ' +
							  t('has accepted to host your event') +
							  ' ' +
							  notification?.event?.name
							: notification?.notificationType ===
									TypeOfNotification.EVENT_HOSTING_NOTIFICATION_RESPONSE &&
							  notification?.message ===
									TNotificationMessage.EVENT_HOSTING_NOTIFICATION_REJECTED
							? notification?.sentBy?.username +
							  ' ' +
							  t(
									'has rejected your invitation to host your event'
							  ) +
							  ' ' +
							  notification?.event?.name
							: notification?.notificationType ===
									TypeOfNotification.EVENT_HOSTING_NOTIFICATION_RESPONSE &&
							  notification?.message ===
									TNotificationMessage.EVENT_HOSTING_NOTIFICATION_ALREADY_EXIST &&
							  t('has already been appointed to host event') +
									' ' +
									notification?.event?.name}
					</Text>
					{Math.floor(
						(Date.now() - Number(notification?.createdAt)) /
							getSecondsFromDate
					) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getSecondsFromDate
							)}
							{''}s {t('ago')}
						</Text>
					) : Math.floor(
							(Date.now() - Number(notification?.createdAt)) /
								getMinuteFromDate
					  ) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getMinuteFromDate
							)}
							{''}m {t('ago')}
						</Text>
					) : Math.floor(
							(Date.now() - Number(notification?.createdAt)) /
								getHourFromDate
					  ) < aDay ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getHourFromDate
							)}
							{''}h {t('ago')}
						</Text>
					) : (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
									getDayFromDate
							)}{' '}
							{t('days ago')}
						</Text>
					)}

					{/* <Text style={[styles.eventDate]}>2h ago</Text> */}
					<View style={styles.actionContainer}>
						<Button
							title={`${t('View Profile')}`}
							variant="outline"
							size="sm"
							outerContainerProps={{
								style: styles.acceptButton,
							}}
							onPress={() =>
								navigation.navigate('UserProfile', {
									userId: notification?.sentBy._id,
								})
							}
						/>
					</View>
				</View>
			</>
		</View>
	);
};

const styles = StyleSheet.create({
	rejectButton: {
		flex: 0.75,
	},
	acceptButton: {
		flex: 1,
	},
	eventDate: {
		color: palettes.grey[2],
		marginTop: 6,
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(10),
	},
	actionContainer: {
		flexDirection: 'row',
	},
	invitationText: {
		color: palettes.grey[0],
	},
	name: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
	detailsContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		flexDirection: 'row',
		width: '100%',
		borderLeftWidth: 3,
		borderLeftColor: 'transparent',
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
});

function styleInvitationText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleName() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
}

function styleDetailsContainer() {
	return {
		marginBottom: getResponsiveStyle(8),
	};
}

function styleProfileIcon() {
	return {
		width: getResponsiveStyle(56, 'dimensions'),
		height: getResponsiveStyle(56, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingBottom: getResponsiveStyle(15),
		paddingTop: getResponsiveStyle(15),
	};
}
