import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Button, CompanyCardStats, CompanyCardVertical, Ratings } from '../..';
import useGetBrandRating from '../../../api/rating/useGetBrandRating';
import { palettes } from '../../../config';
import {
	GET_BRAND_STATS,
	GET_FOLLOWING_BRANDS,
} from '../../../graphql/brands/queries';
import {
	FOLLOW_BRAND,
	UNFOLLOW_BRAND,
} from '../../../graphql/follow/mutations';
import { IS_FOLLOWING } from '../../../graphql/network/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TIsFollowingHash } from '../../../screens/BrandsScreen';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import { twoDp } from '../../../utils/twoDP';

type Props = {
	setIsFollowingObject: React.Dispatch<
		React.SetStateAction<TIsFollowingHash | undefined>
	>;
	isFollowingObject?: TIsFollowingHash;
	brandIds: string[];
	tag: string;
	brandStatsArray: BrandStats | undefined;
	averagePrice: number | string;
} & TGetAllBrandsData;

const CompanyCard = ({
	setIsFollowingObject,
	isFollowingObject,
	brandIds,
	averagePrice,
	tag,
	...brandDetails
}: Props) => {
	const navigation = useNavigation<TUseNavigation>();
	const { isDesktop, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { t } = useTranslation();

	const { executeBrandRatingQuery, data: brandRatingData } =
		useGetBrandRating();

	useEffect(() => {
		executeBrandRatingQuery({
			brandId: brandDetails._id,
		});
	}, []);

	const brandRating = brandRatingData?.getBrandRating?.data?.rating;

	const refetchQueries = [
		GET_BRAND_STATS,

		{
			query: IS_FOLLOWING,
			variables: {
				args: {
					followedIds: brandIds,
					type: 'BRAND',
				},
			} as TIsFollowingInputs,
		},

		tag === 'followed-brand' && GET_FOLLOWING_BRANDS,
	];

	/****************** START - API - follow ********************** */

	const [followAccount, { loading: loadingFollow }] = useMutation<
		TFollowBrandResponse,
		TFollowInputs
	>(FOLLOW_BRAND, {
		awaitRefetchQueries: true,
		refetchQueries,
		onCompleted: data => {
			if (
				data &&
				data.FollowBrand &&
				data.FollowBrand.data &&
				data.FollowBrand.success
			) {
				setIsFollowingObject({
					...isFollowingObject,
					[brandDetails._id]: true,
				});
			}
		},
	});

	/****************** End - API - follow ************************ */

	/******************** START - API - unfollow ***********************/
	const [unFollowAccount, { loading: loadingUnfollow }] = useMutation<
		TUnfollowBrandResponse,
		TUnfollowInputs
	>(UNFOLLOW_BRAND, {
		awaitRefetchQueries: true,
		refetchQueries,
		onCompleted: data => {
			if (
				data &&
				data.UnFollowBrand &&
				data.UnFollowBrand.data &&
				data.UnFollowBrand.success
			) {
				setIsFollowingObject({
					...isFollowingObject,
					[brandDetails._id]: false,
				});
			}
		},
	});

	/******************** END  -  API - unfollow ***********************/

	const followUnfollowMutationFunction = (id: string) => {
		const variables = {
			args: {
				followedId: id,
			},
		};
		if (!!isFollowingObject) {
			isFollowingObject?.[brandDetails._id]
				? unFollowAccount({
						variables,
				  })
				: followAccount({
						variables,
				  });
		}
	};

	const loading = useMemo(
		() => getSingleLoadingState(loadingUnfollow, loadingFollow),
		[loadingUnfollow, loadingFollow]
	);

	return (
		<View
			style={[
				styles.containerDesktop,
				isDesktop ? styleContainer() : styles.containerMobile,
			]}
		>
			<View
				style={[
					isLessThanDesktopBase &&
						styles.companyProfileButtonContainer,
					isLessThanDesktopBase &&
						styleCompanyProfileButtonContainer(),
				]}
			>
				<CompanyCardVertical
					rating={brandDetails.rating}
					imageUrl={brandDetails.profileImage}
					name={brandDetails.name}
					countryCode={brandDetails.address?.countryCode}
					location={`${brandDetails.address?.city},${brandDetails.address?.countryLabel} `}
				/>

				{isLessThanDesktopBase && (
					<Button
						title={t('View Profile')}
						variant={'outline'}
						size="sm"
						onPress={() =>
							navigation.navigate('BrandDetails', {
								_id: brandDetails._id,
								brandDetails,
							})
						}
					/>
				)}
			</View>

			{isDesktop && (
				<Ratings
					ratings={brandRating || 0}
					containerStyles={styleRatingContainer()}
				/>
			)}

			<View
				style={[
					styles.statsContainer,
					styleUnderline(isLessThanDesktopBase),
				]}
			>
				<CompanyCardStats
					label={t('Guests')}
					stat={
						brandDetails?.brandStatsArray?.followers
							?.guestCount as number
					}
				/>
				<CompanyCardStats
					label={t('Sellers')}
					stat={
						brandDetails?.brandStatsArray?.followers
							?.sellersCount as number
					}
				/>
				<CompanyCardStats
					label={t('Hosts')}
					stat={
						brandDetails?.brandStatsArray?.followers
							?.hostCount as number
					}
				/>
			</View>
			{/* )} */}

			<Text
				style={[
					styles.starterKit,
					styleStarterKit(),
					isLessThanDesktopBase && styles.starterKitMobile,
				]}
			>
				{t('Average Price')}
				<Text style={[styles.starterKitPrice, styleStarterKitPrice()]}>
					{getSymbolFromCurrency('eur')}
					{twoDp(averagePrice)}
				</Text>
			</Text>

			{isDesktop && (
				<View style={styles.actionContainer}>
					<Button
						title={
							isFollowingObject?.[brandDetails._id]
								? t('Unfollow')
								: t('Follow')
						}
						variant="outline"
						size="sm"
						loading={loading}
						outerContainerProps={{
							style: styles.actionButtonContainer,
						}}
						onPress={() =>
							followUnfollowMutationFunction(brandDetails._id)
						}
					/>

					<Button
						title={t('View Profile')}
						variant={'grey'}
						size="sm"
						outerContainerProps={{
							style: styles.actionButtonContainer,
						}}
						onPress={() =>
							navigation.navigate('BrandDetails', {
								_id: brandDetails._id,
								brandDetails,
							})
						}
					/>
				</View>
			)}
		</View>
	);
};

export default CompanyCard;

const styles = StyleSheet.create({
	companyProfileButtonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'center',
	},

	starterKitMobile: {
		alignSelf: 'flex-start',
	},
	containerDesktop: {
		borderRadius: 6,
		alignItems: 'center',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.09)',
		shadowOpacity: 1,

		justifyContent: 'space-between',
	},
	containerMobile: {
		shadowColor: 'transparent',
		width: '100%',
	},

	statsContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		// borderBottomColor: palettes.grey[5],
		// borderBottomWidth: 1,
	},
	starterKit: {
		color: palettes.grey[0],
	},
	starterKitPrice: {
		fontWeight: '600',
		color: palettes.dark[0],
	},
	actionContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
	},
	actionButtonContainer: {
		flex: 1,
	},
});

function styleCompanyProfileButtonContainer() {
	return {
		marginBottom: getResponsiveStyle(20),
	};
}
function styleUnderline(isLessThanDesktopBase: boolean) {
	return {
		borderBottomWidth: 1,
		borderBottomColor: palettes.grey[5],
	};
}

function styleStarterKitPrice() {
	return {
		marginLeft: getResponsiveStyle(4, 'font'),
	};
}

function styleStarterKit() {
	return {
		paddingTop: getResponsiveStyle(15),
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(14),
		marginBottom: getResponsiveStyle(20),
	};
}

function styleRatingContainer() {
	return {
		marginBottom: getResponsiveStyle(15),
	};
}

function styleContainer() {
	return {
		width: getResponsiveStyle(308, 'dimensions'),
		minHeight: getResponsiveStyle(344, 'dimensions'),
		padding: getResponsiveStyle(24),
		marginRight: getResponsiveStyle(32),
	};
}
