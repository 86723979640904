import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ActivityIndicator,
	Platform,
	Pressable,
	StyleSheet,
	Text,
	TextInput,
	View,
} from 'react-native';
import { useCreateMedia } from '../../../../api/common/useCreateMedia';
import { palettes } from '../../../../config';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
type Props = {
	onMessageSend: (e?: TODO) => void;
	handleChange: (e: string) => void;
	handleTypingFocusIn: (e: string) => void;
	handleTypingFocusOut: (e: string) => void;
	showUpload?: boolean;
};
import { usePrimaryColors } from '../../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../../hooks/useSetAlerts';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { AttachPictureIcon } from '../../../common/icons';
import SendIcon from '../../../common/icons/SendIcon';
import Loader from '../../../common/loader/Loader';

const ChatInput = ({
	onMessageSend,
	handleChange,
	handleTypingFocusIn,
	handleTypingFocusOut,
	showUpload,
}: Props) => {
	const [message, setMessage] = useState<string>('');
	const [fileType, setFileType] = useState<string>('');
	const [file, setFile] = useState<File | null>();
	const { isLessThanDesktopBase, isMobileView } =
		useIsSpecificViewportWidth();
	const {
		uploadMedia,
		loading: loading_uploadMedia,
		data,
	} = useCreateMedia({});

	// const { isTabletView } = useIsSpecificViewportWidth();
	const { setAlert } = useSetAlert();

	const primary = usePrimaryColors();

	// const { getRootProps, getInputProps, isDragActive } = useDropzone({});

	const handleFileChange = (e: any) => {
		const file = e.target.files[0];
		const fileSize = file.size / 1024 / 1024;
		if (fileSize > 5) {
			setAlert('File size too large', 'danger');
			return;
		}
		setFileType(file.type.split('/')[0]);
		setFile(file);

		// uploadMedia(e.target.files[0], 'USER', 'media-files');
	};
	const handleTextChange = (text: string) => {
		setMessage(text);
		handleChange(text);
	};

	const handleSendMessage = () => {
		onMessageSend({ message, messageType: 'TEXT' });
		setMessage('');
		if (fileType) {
			uploadMedia(file as File, 'USER', 'media-files');
		}
	};
	const [isFocused, setIsFocused] = useState(false);

	const handleFocusIn = () => {
		setIsFocused(true);
		handleTypingFocusIn && handleTypingFocusIn();
	};

	const handleFocusOut = () => {
		setIsFocused(false);
		handleTypingFocusOut;
	};
	const { t } = useTranslation();

	useEffect(() => {
		if (data) {
			const message = data.createMedia.data?.src;
			const type = fileType.toUpperCase();
			onMessageSend({ message, messageType: type });
			setFileType('');
			setFile(null);
		}
	}, [data]);

	return (
		<View>
			<View
				style={[
					styles.container,
					isFocused && {
						borderWidth: 3,
						borderRadius: 8,
						borderColor: primary[1],
					},
				]}
			>
				<TextInput
					value={message}
					placeholder={t('Enter your chat here')}
					style={[
						Platform.OS === 'web' &&
							({ outlineColor: primary[0] } as any),
						styles.input,
					]}
					onChangeText={handleTextChange}
					onSubmitEditing={handleSendMessage}
					onFocus={() => handleFocusIn()}
					onBlur={() => handleFocusOut}
					numberOfLines={message.length > 15 ? 2 : 1}
					multiline={isMobileView}
				/>
				{/* <View>
					<label htmlFor="test-input">
						<div> test</div>
					</label>
				</View> */}
				{showUpload && (
					<Pressable style={styles.shareIcon}>
						<View style={styles.attachPictureInnerContainer}>
							<label htmlFor="chat-input">
								<AttachPictureIcon
									style={styleAttachPictureIcon()}
								/>
							</label>
							{!isLessThanDesktopBase && (
								<Text
									style={[
										styles.attachPictureText,
										styleAttachPictureText(),
									]}
								>
									<label htmlFor="chat-input">
										{t(
											`${
												fileType
													? 'File Selected/Send'
													: 'Upload/Video'
											}`
										)}
									</label>
								</Text>
							)}
						</View>
						<input
							id="chat-input"
							type={'file'}
							style={{
								display: 'none',
								borderColor: primary[0],
								position: 'absolute',
								width: '100%',
							}}
							onChange={handleFileChange}
						/>
						{/* <div {...getRootProps()}>
						<input
							id="test-input"
							type={'file'}
							style={{ display: 'none' }}
							onChange={handleFileChange}
						/>
						<View style={styles.attachPictureInnerContainer}>
							<AttachPictureIcon
								style={styleAttachPictureIcon()}
							/>
							<Text
								style={[
									styles.attachPictureText,
									styleAttachPictureText(),
								]}
							>
								<label htmlFor="test-input">
									{t('Upload/Video')}
								</label>
							</Text>
						</View>
					</div> */}
					</Pressable>
				)}

				<Pressable style={styles.sendIcon} onPress={handleSendMessage}>
					{loading_uploadMedia ? (
						<Loader size="small" />
					) : (
						<SendIcon />
					)}
				</Pressable>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	input: {
		fontSize: 16,
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		height: 'fit-content',
		minHeight: 47,
		paddingVertical: '10px',
		paddingLeft: '30px',
		paddingRight: '40px',
		flex: 1,
	},
	sendIcon: {
		// position: 'absolute',
		// top: '34%',
		// right: 25,
		marginHorizontal: getResponsiveStyle(10),
	},
	shareIcon: {
		// position: 'absolute',
		// top: '34%',
		// right: 55,
		// flex:1
		marginLeft: getResponsiveStyle(10),
	},
	focused: {
		borderWidth: 3,
		borderRadius: 8,
	},

	container: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		zIndex: 10,
		borderWidth: 1,
		borderColor: palettes.grey[4],
		borderRadius: 6,
		alignItems: 'center',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		minHeight: 47,
	},
	attachPictureInnerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		// position: 'absolute',
		// right: -8,
	},
	attachPictureText: {
		color: palettes.grey[0],
	},
	// label: {
	//   fontStyle: "normal",
	//   fontWeight: "normal",
	//   color: palettes.grey[0],
	// },
});

export default ChatInput;

const styleAttachPictureText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleAttachPictureIcon = () => {
	return {
		width: getResponsiveStyle(21, 'dimensions'),
		height: getResponsiveStyle(19, 'dimensions'),
		marginRight: getResponsiveStyle(7),
	};
};
