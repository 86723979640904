import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthTemplate } from '../components';
import EventDurationForm from '../components/create-event/event-duration/EventDurationForm';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { TUseNavigation } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const EventDurationScreen = (props: { navigation: any }) => {
	const { t } = useTranslation();
	const navgation = useNavigation<TUseNavigation>();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const handleSelectUser = (userType: string) => {
		// TODO: handle select user
	};
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/event-duration'));
	}, []);

	return (
		<AuthTemplate
			heading={t('Select Date & Time')}
			subText={t('Whats your preference on scheduling the event?')}
			scrollItems={createEventSidebarArray}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<EventDurationForm />
		</AuthTemplate>
	);
};

export default EventDurationScreen;
