import { TInitialSortState } from '../redux/sortSlice';

export const sortValue = (a: any, b: any, sort: TInitialSortState) => {
	if (!sort?.local) {
		return 0;
	}
	let value1 = a[sort.type.label];
	let value2 = b[sort.type.label];
	if (sort?.value && sort?.type) {
		if (typeof value1 === 'string') {
			value1 = value1.toLocaleLowerCase();
			value2 = value2.toLocaleLowerCase();
		}
		if (value1 > value2) {
			return sort?.type.value;
		}
		if (value1 < value2) {
			return -1 * sort?.type.value;
		}
	}
	return 0;
};
export const sortCheck = (sort: TInitialSortState) => {
	if (!sort?.local) {
		return sort?.type;
	} else return {};
};
