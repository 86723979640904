import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { StyleSheet } from 'react-native';
import { useLogin } from '../api/auth';
import LottieMark from '../assets/LottieFIles/LottieMark.json';
import { AuthTemplate, Button } from '../components';
import { setIsUserLoggedIn, setLoggedInUserDetails } from '../redux/authSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { TUseNavigation } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';
import { handleNavigationToEvent } from '../utils/handleNavigationToEvent';

const SignupCompleteScreen = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigation = useNavigation<TUseNavigation>();
	const { signUpSubscribeEvent } = useAppSelector(state => state.user);
	const { executeLoginMutation, loading, data } = useLogin({
		_hideAlert: true,
		onCompleted: () => {
			if (signUpSubscribeEvent) {
				handleNavigationToEvent({
					event: signUpSubscribeEvent,
					navigation,
					loggedInUserDetails: data?.login?.data,
					dispatch,
				});
				dispatch(
					setLoggedInUserDetails(data?.login?.data as unknown as any)
				);
			}
			dispatch(setIsUserLoggedIn(true));
		},
		onError: () => {
			navigation.navigate('Login');
		},
	});

	useEffect(() => {
		dispatch(setCurrentScreen('/signup-complete'));
	}, []);

	const signupSidebarArray = useAppSelector(
		state => state.ui.SignupSidebarArray
	);
	const { email, password } = useAppSelector(state => ({
		email: state.createUser.args.email,
		password: state.createUser.args.password,
	}));

	const signIn = (values: { email: string; password: string }) => {
		const { email, password } = values;
		if (!!email && !!password)
			executeLoginMutation({
				email,
				password,
			});
	};

	const onPressButton = () => {
		signIn({
			email,
			password,
		});
	};

	return (
		<AuthTemplate
			heading={t('Complete')}
			subText={t('Create your account to fully experience the app')}
			scrollItems={signupSidebarArray}
			contentStyles={[styles.container, stylesContainer()]}
			hasGoBack={false}
		>
			<Lottie
				loop={false}
				animationData={LottieMark}
				play
				style={{ width: '100%', height: '70%' }}
			/>
			<Button
				onPress={onPressButton}
				title={t('Done')}
				size="lg"
				variant="primary"
				loading={loading}
			/>
		</AuthTemplate>
	);
};

const styles = StyleSheet.create({
	container: {
		width: '100%',
		display: 'flex',
		minHeight: 'calc(100vh - 250px)',
	},
});

export default SignupCompleteScreen;
function stylesContainer() {
	return { marginTop: getResponsiveStyle(50) };
}
