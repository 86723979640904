import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Input, Label } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Picker } from '@react-native-picker/picker';
import { palettes } from '../../../config';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useMutation } from '@apollo/client';
import {
	CREATE_BANK,
	CREATE_BANK_TOKEN,
} from '../../../graphql/stripe/mutations';
import { LIST_BANKS } from '../../../graphql/stripe/queries';
import { getCountryValueFromLabel } from '../../../utils/getCountryValueFromLabel';
import Currencies from '../../../utils/currencies';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { setCreateBankModalState } from '../../../redux/bankSlice';
import { useTranslation } from 'react-i18next';
const schema = Yup.object({
	bankName: Yup.string().required('Required'),
	accountNumber: Yup.string().required('Required'),
	city: Yup.string().required('Required'),
	country: Yup.string().required('Required'),
	currency: Yup.string().required('Required'),
});

type TInitialValues = Yup.InferType<typeof schema>;

const initialValues: TInitialValues = {
	bankName: '',
	city: '',
	country: '',
	accountNumber: '',
	currency: '',
};

const AddBankForm = (props: {
	onSubmit: (
		bankName: string,
		city: string,
		country: string,
		accountNumber: string,
		currency: string
	) => void;
}) => {
	const { t } = useTranslation();
	const handleSubmit = (values: {
		bankName: string;
		country: string;
		city: string;
		accountNumber: string;
		currency: string;
	}) => {
		props.onSubmit(
			values.bankName,
			values.city,
			values.country,
			values.accountNumber,
			values.currency
		);
	};
	const { setAlert } = useSetAlert();

	// adding bank queries

	const [bankName, setbankName] = useState('');
	const [accountNum, setAccountNum] = useState('');
	const [country, setCountry] = useState('');
	const [currency, setCurrency] = useState('');
	const [city, setCity] = useState('');
	const [tokenId, setTokenId] = useState('');
	const connectAccoundId_ = useAppSelector(
		state => state.userBanks.connectAccountId
	);
	const primaryColors = usePrimaryColors();

	const userCountry = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address
				.countryLabel
	);
	const [createbanktoken, { loading: loadingBanktoken }] = useMutation(
		CREATE_BANK_TOKEN,
		{
			variables: {
				args: {
					country:
						getCountryValueFromLabel(userCountry)?.toUpperCase(),
					currency:
						Currencies[userCountry as keyof typeof Currencies],
					account_number: accountNum,
				},
			},
			onCompleted: data => {
				if (data.createBankToken.data && data.createBankToken.success) {
					setTokenId(data.createBankToken.data.id);
				} else {
					setAlert(t('Unable to Create bank'), 'normal');
				}
			},
			onError: () => {
				setAlert(t('Unable to Create bank'), 'normal');
			},
		}
	);

	const connectID = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.connectAccountInfo?.connectAccountId
	);

	const dispatch = useAppDispatch();
	const [createBank, { loading: creatingBank }] = useMutation(CREATE_BANK, {
		variables: {
			args: {
				id: connectAccoundId_,
				source: { external_account: tokenId },
			},
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: LIST_BANKS,
				variables: {
					args: {
						id: connectID,
					},
				},
			},
		],
		onCompleted: data => {
			if (
				data.createBank.message === 'Bank Created' &&
				data.createBank.success
			) {
				dispatch(setCreateBankModalState());
				setAlert(t('Bank created successfully'), 'normal');
			}
		},
		onError: () => {
			dispatch(setCreateBankModalState());
			setAlert(t('Unable to Create Bank'), 'danger');
		},
	});
	const settingValues = (val: any) => {
		setAccountNum(val.accountNumber);
		setCountry(
			getCountryValueFromLabel(val.country)?.toUpperCase() ??
				'not selected'
		);
		setCurrency(val.currency);
	};
	if (creatingBank || loadingBanktoken) {
		return <div>Creating Bank...</div>;
	} else {
		return (
			<Formik
				initialValues={initialValues}
				// validationSchema={schema}
				onSubmit={async values => {
					await settingValues(values);
					await createbanktoken();
					await createBank();
				}}
			>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					values,
					errors,
					touched,
					setFieldValue,
				}) => (
					<>
						<View>
							{/* <Label
								error={touched.bankName ? errors.bankName : ''}
								label="Bank Name"
							/>
							<Picker
								selectedValue={values.bankName}
								onValueChange={itemValue =>
									setFieldValue('bankName', itemValue)
								}
								style={[
									styles.dropdownContainer,
									Platform.OS === 'web' &&
									!touched.bankName &&
									!errors.bankName
										? styleFocusOutline(primaryColors)
										: styleErrorOutline(),

									// styleInput(decoration),
									!!errors.bankName &&
										touched.bankName &&
										styles.inputError,
								]}
								dropdownIconColor={palettes.grey[1]}
								onBlur={handleBlur('bankName')}
								itemStyle={styles.dropdownItem}
							>
								<Picker.Item
									label="Select Bank"
									value=""
									enabled={false}
								/>
								<Picker.Item
									label="Account 1"
									value="Account 1"
								/>
								<Picker.Item
									label="Account 2"
									value="Account 2"
								/>
								<Picker.Item
									label="Account 3"
									value="Account 3"
								/>
							</Picker> */}

							<Input
								value={
									Currencies[
										userCountry as keyof typeof Currencies
									]
								}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								onChangeText={handleChange('currency')}
								onBlur={handleBlur('currency')}
								label="Currency"
								error={touched.currency ? errors.currency : ''}
								editable={false}
								// placeholder="Enter account number"
							/>

							<Input
								value={userCountry}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								onChangeText={handleChange('country')}
								onBlur={handleBlur('country')}
								label="Country"
								error={touched.country ? errors.country : ''}
								editable={false}
								// placeholder="Enter account number"
							/>

							<Input
								value={values.accountNumber}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								onChangeText={handleChange('accountNumber')}
								onBlur={handleBlur('accountNumber')}
								label="IBAN"
								error={
									touched.accountNumber
										? errors.accountNumber
										: ''
								}
								// placeholder="Enter account number"
							/>
						</View>

						<Button
							title="Save"
							variant="primary"
							outerContainerProps={{
								style: styleLoginButtonOuterContainer(),
							}}
							size="lg"
							onPress={handleSubmit}
						/>
					</>
				)}
			</Formik>
		);
	}
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	countryContainer: {
		flexBasis: '100%',
		height: '50px',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		marginBottom: getResponsiveStyle(35),
		paddingLeft: getResponsiveStyle(19),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
});

export default AddBankForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(46),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary[0],
		outerWidth: 1,
	} as any;
}
