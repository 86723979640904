import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	FilterCategories,
	Loader,
	LoadMore,
	ProfileCards,
	ProfileTableControl,
	Tabs,
} from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { sortCheck, sortValue } from '../utils/handleSort';
import { useNetworkScreen } from '../hooks/useNetworkScreen';
const NetworkScreen = () => {
	const {
		shouldHideScreen,
		USER,
		selectedTab,
		selectedCategory,
		DROPDOWN_CATEGORIES,
		PILL_INIT_CATEGORIES,
		PILL_CATEGORIES,
		GUEST_HEADERS,
		HOST_HEADERS,
		isFilteredData,
		hostFilteredData,
		allValues,
		loadingState,
		tabDataLoadingState,
		requestSendBooleanArray,
		unfollowrequestSendBooleanArray,
		followMeUnfollowMeButtonPressedId,
		isFollowingBooleanArray,
		loadingFollowMe,
		loadingUnfollowme,
		generalNetworkSort,
		networkHostSort,
		networkFilters,
		sort,
		total,
		OnPressViewMore,
		getnetworkStatus,
		getTabData,
		setSelectednow,
		onClickFollowMe,
		addStats,
		setFilterIds,
		setHostFilterData,
		setSelectedCategory,
		setIsFilteredData,
		onPress,
		getTabs,
	} = useNetworkScreen({
		sortCheck,
	});

	const { t } = useTranslation();

	return shouldHideScreen ? null : (
		<DesktopTemplate>
			<Tabs
				onPress={onPress}
				selectedTab={selectedTab}
				tabs={getTabs(USER)}
			/>
			<FilterCategories
				onClick={category => {
					if (selectedCategory === category) {
						return;
					} else {
						setFilterIds([]);
						setHostFilterData([]);
						setSelectedCategory(category);

						if (category === 'all') {
							setIsFilteredData(false);
							return;
						}
						setIsFilteredData(true);
					}
				}}
				dropdownCategories={DROPDOWN_CATEGORIES}
				pillCategories={[...PILL_INIT_CATEGORIES, ...PILL_CATEGORIES]}
				selectedCategory={selectedCategory}
				secondLabel={`${t(selectedTab as any)}`}
			/>
			{/* {isFilteredData && !hostFilteredData.length && !loadingState && (
				<NotAvailableMessage message={t('No Result Found!')} />
			)} */}

			<ProfileCards
				data={
					isFilteredData
						? addStats(hostFilteredData)
						: addStats(allValues)
				}
				hasActionBtn
				hasRating={selectedTab !== 'Guests'}
				hasEventAttendedOnly={selectedTab === 'Guests'}
				loading={isFilteredData ? loadingState : tabDataLoadingState}
				requestSendBooleanArray={requestSendBooleanArray}
				onClickHostFollowMeActionButton={onClickFollowMe}
				type={
					selectedTab === 'Guests'
						? 'consumer'
						: selectedTab === 'Hosts'
						? 'host'
						: 'seller'
				}
				unfollowrequestSendBooleanArray={
					unfollowrequestSendBooleanArray
				}
				followMeUnfollowMeButtonPressedId={
					followMeUnfollowMeButtonPressedId
				}
				isFollowingBooleanArray={isFollowingBooleanArray}
				loadingFollowMe={loadingFollowMe || loadingUnfollowme}
				setSelected={setSelectednow}
			/>
			{tabDataLoadingState && !getTabData().length ? (
				<Loader />
			) : (
				<>
					<ProfileTableControl
						heading={selectedTab}
						data={addStats(getTabData()).sort((a, b) =>
							sortValue(a, b, sort)
						)}
						page="network"
						setSelected={setSelectednow}
						hasFilter
						filterItem={networkFilters}
						sortItem={
							(selectedTab === 'Guests'
								? generalNetworkSort
								: [
										...generalNetworkSort,
										...networkHostSort,
								  ]) as any
						}
						type={selectedTab === 'Guests' ? 'consumer' : 'host'}
						selectedTab={selectedTab}
						messageButton={false}
						followButton={true}
						headers={
							selectedTab === 'Guests'
								? GUEST_HEADERS
								: HOST_HEADERS
						}
						requestSendBooleanArray={requestSendBooleanArray}
						unfollowrequestSendBooleanArray={
							unfollowrequestSendBooleanArray
						}
						onClickHostFollowMeActionButton={onClickFollowMe}
						followMeUnfollowMeButtonPressedId={
							followMeUnfollowMeButtonPressedId
						}
						loadingFollowMe={loadingFollowMe || loadingUnfollowme}
						isFollowingBooleanArray={isFollowingBooleanArray}
					/>
					{getTabData().length < total && (
						<LoadMore
							onClick={OnPressViewMore}
							loading={getnetworkStatus() === 2}
						/>
					)}
				</>
			)}
		</DesktopTemplate>
	);
};

export default NetworkScreen;
