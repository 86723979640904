import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
dayjs.extend(RelativeTime);

type TStatProps = {
	stat?: number;
	time?: never;
};

type TTimeProps = {
	stat?: never;
	time?: string;
};

type TGeneralProps = {
	icon: JSX.Element;
	isLastElement?: boolean;
};

type RootProps = TGeneralProps & (TStatProps | TTimeProps);

export const LiveEventIconWithContent = ({
	icon,
	stat,
	time,
	isLastElement = false,
}: RootProps) => {
	return (
		<View style={styleContainer(isLastElement)}>
			<View style={styles.icon}>{icon}</View>
			<Text style={styles.content}>{!!stat && stat}</Text>
			<Text style={styles.content}>{!!time && time}</Text>
		</View>
	);
};

const styleContainer = (isLastElement: boolean) => {
	return [
		styles.container,
		{
			marginRight: isLastElement ? 0 : 20,
		},
	];
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	content: {
		color: 'white',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 2,
	},
	icon: {
		marginRight: 6,
		justifyContent: 'center',
	},
});
