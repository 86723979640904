import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CategoriesDropdown } from '../../common/filter-categories/CategoriesDropdown';
import ChartComponent from './ChartComponent';
import ChartDetailItem from './ChartDetailItem';

type Props = {
	prevData?: number[];
	currData?: number[];
	loadingCart: boolean;
	todaysOrders: TSellersOrder_[];
	yesterdaysOrders: TSellersOrder_[];
	chartItemValues?: {
		title: string;
		percentageDifference: number;
		percentageIncrease: boolean;
		value: number | string;
		showPercentage?: boolean;
	}[];
	todayNoOfOrders: number;
	yesterdayNoOfOrders: number;
};

const SalesSection = ({
	prevData,
	currData,
	loadingCart,
	todaysOrders,
	yesterdaysOrders,
	todayNoOfOrders,
	yesterdayNoOfOrders,
}: Props) => {
	const { t } = useTranslation();
	const ordersToday = todayNoOfOrders;
	const [todaysRevenue, setTodaysRevenue] = useState(0);
	const [yesterdaysRevenue, setYesterdaysRevenue] = useState(0);
	useEffect(() => {
		let revenueToday = 0;
		todaysOrders.map(order => (revenueToday += order.amount));
		setTodaysRevenue(revenueToday);

		let revenueYesterday = 0;
		yesterdaysOrders.map(order => (revenueYesterday += order.amount));
		setYesterdaysRevenue(revenueYesterday);
	}, [todaysOrders, yesterdaysOrders]);

	const ordersYesterday = yesterdayNoOfOrders;
	const revenuepercent: number | string =
		yesterdaysRevenue > 0
			? Math.ceil(
					((todaysRevenue - yesterdaysRevenue) * 100) /
						yesterdaysRevenue
			  )
			: 'nil';

	const ordersPercent: number | string =
		ordersYesterday > 0
			? Math.ceil(
					((ordersToday - ordersYesterday) * 100) / ordersYesterday
			  )
			: 'nil';

	const averagePercent_: number = Math.ceil(
		((todaysRevenue / ordersToday - yesterdaysRevenue / ordersYesterday) *
			100) /
			(yesterdaysRevenue / ordersYesterday)
	);
	const averagePercent: number | string =
		averagePercent_ >= 0 || averagePercent_ < 0 ? averagePercent_ : 'nil';

	const SORT_BY_FILTER = [
		{
			label: t('Revenue'),
			value: 'revenue',
		},
		{
			label: t('Orders'),
			value: 'orders',
		},
		{
			label: t('Value'),
			value: 'value',
		},
		{
			label: t('Refund'),
			value: 'refund',
		},
	];
	const DATE_FILTER = [
		{
			label: t('Last week'),
			value: 'last_year',
		},
		{
			label: t('Last month'),
			value: 'last_month',
		},
		{
			label: t('Last 3 months'),
			value: 'last_3_months',
		},
		{
			label: t('Last year'),
			value: 'last_year',
		},
	];
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<View style={Styles.container}>
			<View style={Styles.headerContainer}>
				<Text style={Styles.headerText}>{t('Sales')}</Text>
				{!isLessThanDesktopBase && (
					<View style={Styles.filterLegendContainer}>
						<View style={Styles.filterLegendItemContainer}>
							<View style={Styles.filterLegendItemIndicator1} />
							<Text style={Styles.filterLegendItemText}>
								{t('Last month')}
							</Text>
						</View>
						<View style={Styles.filterLegendItemContainer}>
							<View style={Styles.filterLegendItemIndicator2} />
							<Text style={Styles.filterLegendItemText}>
								{t('This month')}
							</Text>
						</View>
					</View>
				)}

				{/* <View style={Styles.filterContainer}>
					<CategoriesDropdown
						categories={SORT_BY_FILTER}
						label=""
						placeholder={t('Sort By')}
						onClick={() => {}}
					/>
					<CategoriesDropdown
						categories={DATE_FILTER}
						label=""
						placeholder={t('Date')}
						onClick={() => {}}
					/>
				</View> */}
			</View>
			{isLessThanDesktopBase && (
				<View
					style={[
						Styles.filterLegendContainer,
						{
							justifyContent: 'center',
						},
					]}
				>
					<View style={Styles.filterLegendItemContainer}>
						<View style={Styles.filterLegendItemIndicator1} />
						<Text style={Styles.filterLegendItemText}>
							{t('Last month')}
						</Text>
					</View>
					<View style={Styles.filterLegendItemContainer}>
						<View style={Styles.filterLegendItemIndicator2} />
						<Text style={Styles.filterLegendItemText}>
							{t('This month')}
						</Text>
					</View>
				</View>
			)}
			<ScrollView
				contentContainerStyle={Styles.chartDetailsSection}
				horizontal={true}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			>
				<ChartDetailItem
					heading={t("Today's revenue")}
					value={todaysRevenue}
					percentage={revenuepercent}
					percentageIncrease={
						todaysRevenue >= yesterdaysRevenue ? true : false
					}
				/>
				<ChartDetailItem
					heading={t("Today's orders")}
					value={ordersToday}
					percentage={ordersPercent}
					percentageIncrease={
						ordersToday >= ordersYesterday ? true : false
					}
				/>
				<ChartDetailItem
					heading={t('Average value')}
					value={
						ordersToday && (todaysRevenue / ordersToday).toFixed(2)
					}
					percentage={averagePercent}
					percentageIncrease={
						todaysRevenue / ordersToday >
						yesterdaysRevenue / ordersYesterday
							? true
							: false
					}
				/>
				<ChartDetailItem
					heading={t("Today's refund")}
					value={0}
					percentage={10}
				/>
			</ScrollView>
			<ChartComponent
				data1={prevData}
				data2={currData}
				loading={loadingCart}
			/>
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		width: '100%',
		marginTop: 48,
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(24, 'font'),
	},
	filterLegendContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignContent: 'center',
		gap: getResponsiveStyle(36),
	},
	filterLegendItemContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(11),
	},
	filterLegendItemText: {
		fontSize: 14,
		fontWeight: '400',
		color: palettes.dark[0],
	},
	filterLegendItemIndicator1: {
		width: getResponsiveStyle(10, 'dimensions'),
		height: getResponsiveStyle(10, 'dimensions'),
		borderRadius: getResponsiveStyle(10 / 2, 'dimensions'),
		borderWidth: 2,
		borderColor: palettes.red[0],
	},
	filterLegendItemIndicator2: {
		width: getResponsiveStyle(10, 'dimensions'),
		height: getResponsiveStyle(10, 'dimensions'),
		borderRadius: getResponsiveStyle(10 / 2, 'dimensions'),

		borderWidth: 2,
		borderColor: palettes.purple[0],
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	chartDetailsSection: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		// justifyContent: 'space-between',
		marginTop: getResponsiveStyle(57),
		marginBottom: getResponsiveStyle(48),
		gap: getResponsiveStyle(16),
	},
});

export default SalesSection;
