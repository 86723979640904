// import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
// import { useTranslation } from 'react-i18next';
import { palettes } from '../../../config';
import UserMessage from '../../live-event/product-section/event-chat/UserMessage';
import ChatInput from '../../live-event/product-section/event-chat/ChatInput';
import ContactMessage from '../../live-event/product-section/event-chat/ContactMessage';
import {
	useCreateMessage,
	useGetConversation,
	useGetConversations,
} from '../../../api/chat';
import { useAppSelector } from '../../../redux/store';
import {
	getLinkFromString,
	getUniquesContacts,
	updateMessagesWithReadReceipt,
	updateOneChat,
	updateUsersTyping,
} from '../../../utils/chatUtil';
import useUpdateReadReceipt from '../../../api/chat/useUpdateReadReceipt';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { setCurrentChat } from '../../../redux/chatSlice';
import { useDispatch } from 'react-redux';
import Loader from '../../common/loader/Loader';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import config from '../../../../config';
// import { socket } from '../../../../App';
import { addDoc, collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../../services/firebase';

const ChatBox = ({ loggedInUserDetails }: { loggedInUserDetails: TODO }) => {
	const { executeConversationQuery, loading, data } = useGetConversation();
	const { executeUpdateReadReceiptMutation } = useUpdateReadReceipt();
	const { executeCreateMessageMutation } = useCreateMessage();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const {
		executeConversationsMutation,
		// loading: loadingContacts,
		data: contactsData,
	} = useGetConversations();
	const { chat, contacts } = useAppSelector(state => state.chat);
	// const { t } = useTranslation();
	const [messages, setMessages] = useState<TODO[]>([]);
	const [usersTyping, setUsersTyping] = useState<string[]>([]);
	const [message, setMessage] = useState<string>('');

	const uniqueID =
		chat?.contactPerson?._id > loggedInUserDetails?._id
			? `${chat?.contactPerson?._id}${loggedInUserDetails?._id}`
			: `${loggedInUserDetails?._id}${chat?.contactPerson?._id}`;

	const scrollViewRef: any = useRef();

	const dispatch = useDispatch();

	useEffect(() => {
		// socket.on('sendMessage', message => {
		// 	setMessages(prev => updateOneChat(prev, message.message));
		// });

		const privateChatDocRef = collection(
			db,
			'chat',
			uniqueID,
			'privateChat'
		);
		const privateChatQuery = query(privateChatDocRef);

		onSnapshot(privateChatQuery, querySnapshot => {
			let messages: TODO[] = [];
			const changes = querySnapshot.docChanges();
			querySnapshot.forEach(doc => {
				// console.log({ fireBaseMessage: doc.data() });
				messages.push(doc?.data());
			});
			if (changes.length === 1) {
				setMessages(prev =>
					updateOneChat(
						prev,
						messages[changes?.[0]?.newIndex]?.message
					)
				);
			}
			// setMessages(prev =>
			// 	updateOneChat(prev, messages?.[messages?.length - 1]?.message)
			// );
		});

		// socket.on('confirmReadReceipt', () => {
		// 	setMessages(prevMessage =>
		// 		updateMessagesWithReadReceipt(prevMessage)
		// 	);
		// });

		// socket.on('onTyping', ({ typing, sender }) => {
		// 	setUsersTyping(prevUsersTyping =>
		// 		updateUsersTyping(prevUsersTyping, typing, sender)
		// 	);
		// });
		if (loggedInUserDetails?._id)
			executeConversationsMutation({
				userId: loggedInUserDetails?._id,
			});
	}, []);

	useEffect(() => {
		/**
		 * this block ensure that only the recipient of message
		 * can trigger read receipt, because with out this if
		 * statement both the sender and receiver component will
		 * run into a loop of continuous back and forth emission,
		 * because this useEffect runs when messages
		 * array change which will happen when the sender send a message
		 * and the recipients receives a message, we only want the recipient
		 * alone to trigger this function
		 */
		// NB: loggedInUserDetails?._id simply indicate the userId of this current user

		if (
			(chat.conversationId &&
				messages[messages.length - 1]?.recipients[0] ===
					loggedInUserDetails?._id) ||
			messages[messages.length - 1]?.recipients ===
				loggedInUserDetails?._id
		) {
			/**
			 * this also works when up open up a chat for the first time
			 * the useEffect run as you change into conversation with someone else
			 * selectedConversationId changes in our state, therefor triggers this
			 * as well
			 */
			executeUpdateReadReceiptMutation({
				conversationId: chat.conversationId,
				recipients: loggedInUserDetails?._id,
			});
			// socket.emit('confirmReadReceipt', {
			// 	recipients: chat.contactPerson?._id,
			// 	sender: loggedInUserDetails?._id,
			// 	confirmReadReceipt: true,
			// });
		}
	}, [messages, chat.conversationId]);

	// useEffect(() => {
	// 	if (loggedInUserDetails?._id) {
	// 		socket.emit('addUser', loggedInUserDetails?._id);
	// 	}
	// }, [loggedInUserDetails?._id]);

	useEffect(() => {
		const contactPersons = contacts?.map(contact => contact?.contactPerson);
		const contactPersonIndex = contactPersons?.findIndex(
			contactPerson => contactPerson?._id === chat?.contactPerson?._id
		);
		if (
			!chat.conversationId &&
			chat.contactPerson?._id &&
			contactPersonIndex === -1
		) {
			executeCreateMessageMutation({
				sender: loggedInUserDetails?._id,
				recipients: chat.contactPerson?._id,
				message: '',
				messageType: 'TEXT',
				conversationId: '',
			});
			return;
		}
		executeConversationQuery({
			conversationId: chat.conversationId,
		});
	}, [chat]);

	useEffect(() => {
		const lastConversation =
			contactsData?.getAllConversations?.data?.[0]?.id;
		if (lastConversation) {
			const uniqueContact = getUniquesContacts(
				contactsData?.getAllConversations?.data.slice(0, 1),
				loggedInUserDetails?._id
			);
			if (uniqueContact.length && !chat.conversationId) {
				dispatch(setCurrentChat(uniqueContact[0]));
			}
			if (!isLessThanDesktopBase) {
				executeConversationQuery({
					conversationId: lastConversation,
				});
			}
		}
	}, [contactsData]);

	useEffect(() => {
		setMessages([]);
		if (data?.getOneConversationByUserId?.data?.messages) {
			setMessages(data?.getOneConversationByUserId?.data?.messages);
		}
	}, [data]);

	const handleChange = (text: string) => {
		setMessage(text);
		// if (!message.length && text.length) {
		// 	socket.emit('onTyping', {
		// 		recipients: chat.contactPerson?._id,
		// 		sender: loggedInUserDetails?._id,
		// 		typing: true,
		// 	});
		// } else if (message.length && !text.length) {
		// 	socket.emit('onTyping', {
		// 		recipients: chat.contactPerson?._id,
		// 		sender: loggedInUserDetails?._id,
		// 		typing: false,
		// 	});
		// }
	};

	const handleTypingFocusIn = () => {
		if (message.length) {
			// socket.emit('onTyping', {
			// 	recipients: chat.contactPerson?._id,
			// 	sender: loggedInUserDetails?._id,
			// 	typing: true,
			// });
		}
	};

	const handleTypingFocusOut = () => {
		if (message.length) {
			// socket.emit('onTyping', {
			// 	recipients: chat.contactPerson?._id,
			// 	sender: loggedInUserDetails?._id,
			// 	typing: false,
			// });
		}
	};

	const handleMessageSend = async ({
		message,
		messageType,
	}: {
		message: string;
		messageType: string;
	}) => {
		const matchedUrl = getLinkFromString(message);
		if (messageType === 'TEXT' && matchedUrl) {
			messageType = 'LINK';
		}

		if (!message.length) return;
		// socket.emit('sendMessage', {
		// 	message: {
		// 		message: message,
		// 		recipients: chat?.contactPerson?._id,
		// 		sender: loggedInUserDetails._id,
		// 		messageType: messageType,
		// 		recipientsReadReceipt: false,
		// 		createdAt: new Date().getTime(),
		// 	},
		// 	contacts: {
		// 		contactPerson: loggedInUserDetails,
		// 		conversationId: chat.conversationId,
		// 	},
		// 	recipients: chat?.contactPerson?._id,
		// });
		await addDoc(collection(db, 'chat', uniqueID, 'privateChat'), {
			message: {
				message: message,
				recipients: chat?.contactPerson?._id,
				sender: loggedInUserDetails._id,
				messageType: messageType,
				recipientsReadReceipt: false,
				createdAt: new Date().getTime(),
			},
			contacts: {
				contactPerson: loggedInUserDetails,
				conversationId: chat.conversationId,
			},
			recipients: chat?.contactPerson?._id,
		});

		// setMessages(prev => [
		// 	...prev,
		// 	{
		// 		message: message,
		// 		recipients: chat?.contactPerson?._id,
		// 		messageType: messageType,
		// 		sender: loggedInUserDetails._id,
		// 	},
		// ]);

		socket.emit('onTyping', {
			recipients: chat?.contactPerson?._id,
			sender: loggedInUserDetails._id,
			typing: false,
		});
		if (chat.conversationId)
			executeCreateMessageMutation({
				sender: loggedInUserDetails._id,
				recipients: chat?.contactPerson?._id,
				message,
				messageType: messageType,
				conversationId: chat.conversationId,
			});
	};

	return (
		<View style={styles.container}>
			{/* {!messages.length && !loading && (
				<View style={styles.defaultTextContainer}>
					<Text style={styles.defaultText}>
						Click on contact to start chat
					</Text>
				</View>
			)} */}
			{loading && (
				<Loader
					size="small"
					containerStyles={{
						margin: getResponsiveStyle(20),
					}}
				/>
			)}
			<ScrollView
				style={styles.chatBox}
				showsHorizontalScrollIndicator={false}
				ref={scrollViewRef}
				onContentSizeChange={() =>
					scrollViewRef.current.scrollToEnd({ animated: false })
				}
			>
				{messages.map((message: TODO, index: number) => (
					<React.Fragment key={index}>
						{message.sender === loggedInUserDetails._id ? (
							<UserMessage message={message} />
						) : (
							<ContactMessage message={message} />
						)}
					</React.Fragment>
				))}
				{!!usersTyping.length &&
					usersTyping.includes(chat?.contactPerson?._id) && (
						<ContactMessage
							usersTyping={usersTyping}
							message={{ message: 'is typing...' }}
						/>
					)}
			</ScrollView>
			{(chat.conversationId || contactsData) && (
				<View
					style={[
						{
							// flex: 1,
							position: 'absolute',
							width: '100%',
							backgroundColor: '#fff',
							paddingBottom: 0,
							paddingHorizontal: 10,
						},
						Platform.select({
							native: {
								bottom: 0,
								left: 0,
							},
							default: {
								bottom: 0,
								left: 0,
							},
						}),
					]}
				>
					<ChatInput
						handleChange={handleChange}
						handleTypingFocusIn={handleTypingFocusIn}
						handleTypingFocusOut={handleTypingFocusOut}
						onMessageSend={handleMessageSend}
						showUpload
					/>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// width: '60%',
		flex: 6,
		borderRightWidth: 1,
		paddingLeft: 20,
		paddingRight: 20,
		borderColor: palettes.grey[4],
	},
	defaultTextContainer: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	defaultText: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
	},
	chatBox: {
		paddingBottom: 80,
	},
});

export default ChatBox;
