import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, Text, View } from 'react-native';
import Flag from '../../common/flag/Flag';
type Props = {
	id: string;
	userName: string;
	userCountry: string;
	userImage: string;
	countryCode?: string;
	city?: string;
};

const UserCard = ({ ...event }: Props) => {
	const { t } = useTranslation();

	return (
		<View style={styles.userDetail}>
			<Image
				style={styles.userImg}
				source={
					event.userImage
						? {
								uri: event.userImage,
						  }
						: require('../../../assets/defaultProfileImage.svg')
				}
			/>
			<View style={styles.user}>
				<Text style={styles.userName}>{event.userName}</Text>
				<View
					style={{
						marginTop: 5,
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Flag countryCode={event.countryCode} />
					<Text>
						{event?.city},{event?.userCountry}
					</Text>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	userName: {
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 14,
		color: '#101625',
	},
	user: { justifyContent: 'space-evenly' },
	userImg: {
		height: '56px',
		width: '56px',
		marginRight: '15px',
		borderRadius: 9999,
	},
	userDetail: {
		flexDirection: 'row',
	},
});

export default UserCard;
