import { Fragment } from 'react';
import { TCallItems } from './utils/LiveEventSection.types';
import { LiveEventTile } from './LiveEventTile';

type Props = {
	callItems: TCallItems;
	onVideoHeight: (val: string) => void;
	eventDetails?: TSingleLiveEventData;
};

export const LiveEventTiles = ({
	callItems,
	onVideoHeight,
	eventDetails,
}: Props) => {
	const tiles: JSX.Element[] = [];

	Object.entries(callItems).forEach(([id, callItem]: any) => {
		if (id === 'local') {
			const tile = (
				<LiveEventTile
					key={id}
					videoTrackState={callItem.videoTrackState}
					audioTrackState={callItem.audioTrackState}
					onVideoHeight={val => onVideoHeight(val)}
					eventDetails={eventDetails}
				/>
			);
			tiles.push(tile);
		}
	});
	return <Fragment>{tiles}</Fragment>;
};
