import { gql } from '@apollo/client';

export const GET_ALL_VOUCHERs = gql`
	query getAllVouchers($limit: Int, $current: Int) {
		getAllVouchers(limit: $limit, current: $current) {
			... on AllResponse {
				statusCode
				success
				data {
					... on All {
						total
						data {
							... on Voucher {
								_id
								brandName
								description
								user
								amountLeft
								amountSpent
								createdAt
								brandId {
									_id
									name
									address {
										city
										countryLabel
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
