import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import Script from 'react-load-script';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';

type Props = {
	onLoadIVSPlayer: () => void;
};

const IVS_PLAYER_URL =
	'https://player.live-video.net/1.8.0/amazon-ivs-player.min.js';

export const LoadIVSScript = ({ onLoadIVSPlayer }: Props) => {
	const navigation = useNavigation<TUseNavigation>();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const { typeOfAccount, disableLivestreamFromIVS } = useAppSelector(
		state => ({
			typeOfAccount: state.auth.loggedInUserDetails?.typeOfAccount,
			disableLivestreamFromIVS: state.getEvent.disableLivestreamFromIVS,
		})
	);

	return (typeOfAccount === 'CONSUMER' || !typeOfAccount) &&
		!disableLivestreamFromIVS ? (
		<Script
			url={IVS_PLAYER_URL}
			onCreate={() => {}}
			onError={() => {
				setAlert(t('Unable to fetch live-stream'), 'danger');
				navigation.navigate('Events');
			}}
			onLoad={onLoadIVSPlayer}
		/>
	) : null;
};
