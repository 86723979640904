import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Label } from '../../..';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { SelectedSchedual } from './SelectedSchedual';
import { useTranslation } from 'react-i18next';
const SelectedScheduals = () => {
	const { t } = useTranslation();
	return (
		<View>
			<Label label={t('Selected Scheduals')} />
			<View style={styles.selectedSchedualListContainer}>
				<SelectedSchedual />
				<SelectedSchedual />
				<SelectedSchedual />
				<SelectedSchedual />
				<SelectedSchedual />
			</View>
		</View>
	);
};

export default SelectedScheduals;

const styles = StyleSheet.create({
	selectedSchedualListContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginRight: getResponsiveStyle(-16),
	},
});
