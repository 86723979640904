import { useCallback, useState } from 'react';

type TReturnType = [
	current: number,
	increaseCurrentByOne: () => number,
	setCurrent: React.Dispatch<React.SetStateAction<number>>
];

export const usePagination = (): TReturnType => {
	const [current, setCurrent] = useState(0);

	const increaseCurrentByOne = useCallback(() => {
		setCurrent(prev => prev + 1);
		return current + 1;
	}, [setCurrent, current]);

	return [current, increaseCurrentByOne, setCurrent];
};
