type TManipulateString = {
	titleCase: (str?: string) => string;
	capitalize: (str?: string) => string;
	extractFloatsFromString: (str?: string) => number;
};

export const manipulateString: TManipulateString = {
	titleCase(str = '') {
		var splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			splitStr[i] =
				splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		return splitStr.join(' ');
	},

	capitalize(str = '') {
		if (!str) return '';
		const _str = str.toLowerCase();
		return _str.charAt(0).toUpperCase() + _str.slice(1);
	},
	extractFloatsFromString(str = '') {
		if (str === '') return 0;
		const EXTRACT_FLOATS_FROM_STRING_AS_STRING = /\d+(\.\d+)?/;
		const floatsAsStrings =
			str && str.match(EXTRACT_FLOATS_FROM_STRING_AS_STRING);
		return floatsAsStrings ? +floatsAsStrings[0] : 0;
	},
};

export const shortenName = (username: string, limit?: number) => {
	return username?.length < (limit || 16)
		? username
		: username?.substring(0, limit || 16) + '...';
};
