import { gql } from '@apollo/client';

const GET_CONVERSATION = gql`
	query getConversation($conversationId: String!) {
		getOneConversationByUserId(conversationId: $conversationId) {
			statusCode
			data {
				messages {
					message
					messageType
					sender
					recipients
					recipientsReadReceipt
				}
			}
		}
	}
`;

const GET_CONVERSATION_BY_PARTICIPANT = gql`
	query getConversation($participants: [String]!) {
		getConversationByParticipants(participants: $participants) {
			statusCode
			data {
				id
			}
		}
	}
`;

const GET_CONVERSATIONS = gql`
	query getConversations($userId: String!) {
		getAllConversations(userId: $userId) {
			statusCode
			message
			data {
				id
				participants {
					_id
					email
					username
					typeOfAccount
					personalInformation {
						profileImageLink
						firstName
						lastName
						address {
							countryLabel
							countryCode
							city
						}
					}
				}
			}
		}
	}
`;

const GET_LIVEEVENTMESSSAGE = gql`
	query getLiveEventMessages($eventId: ID!) {
		getLiveEventMessages(eventId: $eventId) {
			statusCode
			message
			data {
				message
				roomMessage
				sender {
					_id
					email
					username
					typeOfAccount
					personalInformation {
						profileImageLink
						firstName
						lastName
						address {
							countryLabel
							countryCode
							city
						}
					}
				}
			}
		}
	}
`;

const GET_SEARCH_USERS = gql`
	query getConversations($searchTerm: String!, $typeOfAccount: String) {
		getUsers(searchTerm: $searchTerm, typeOfAccount: $typeOfAccount) {
			statusCode
			message
			data {
				_id
				username
				email
				typeOfAccount
				personalInformation {
					profileImageLink
					firstName
					lastName
					address {
						countryLabel
					}
				}
			}
		}
	}
`;

const GET_PROFILE_STATS = gql`
	query getUserById($_id: ID!) {
		getUserById(_id: $_id) {
			statusCode
			data {
				... on User {
					_id
					email
					typeOfAccount
					username
					website
					companyLegalName
					personalInformation {
						address {
							city
							countryLabel
							zipCode
							countryCode
						}
						profileImageLink
						firstName
						lastName
					}
				}
			}
		}
	}
`;

const GET_MEDIA_MESSAGES = gql`
	query getMediaMessages($conversationId: String!, $messageType: String) {
		getMediaMessages(
			MessageInput: {
				conversationId: $conversationId
				messageType: $messageType
			}
		) {
			statusCode
			data {
				message
				messageType
				createdAt
			}
		}
	}
`;

const GET_UNREAD_MESSAGES = gql`
	query getUnreadMessages($recipientId: String!) {
		getUnreadMessages(recipientId: $recipientId) {
			statusCode
			success
			data {
				id
				conversationId
				message
			}
		}
	}
`;

export {
	GET_CONVERSATION,
	GET_CONVERSATIONS,
	GET_LIVEEVENTMESSSAGE,
	GET_SEARCH_USERS,
	GET_PROFILE_STATS,
	GET_MEDIA_MESSAGES,
	GET_UNREAD_MESSAGES,
	GET_CONVERSATION_BY_PARTICIPANT,
};
