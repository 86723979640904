import { gql } from '@apollo/client';

export const CREATE_STRIPE_CUSTOMER = gql`
	mutation createCustomer($args: CreatCustomerInputs!) {
		createCustomer(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Customer {
						name
						email
						invoice_prefix
					}
				}
			}
		}
	}
`;

export const CREATE_CONNECT_ACCOUNT = gql`
	mutation createConnectAccount($args: CreatCustomerInputs!) {
		createConnectAccount(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on CreateConnect {
						id
						type
					}
				}
			}
		}
	}
`;

export const ONBOARD_ACCOUNT = gql`
	mutation accountOnBoarding($args: AccountOnBoardingInputs!) {
		accountOnBoarding(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on AccountLink {
						url
					}
				}
			}
		}
	}
`;

export const CREATE_CARD = gql`
	mutation createCard($args: CreateCardInputs!) {
		createCard(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Card {
						id
						card {
							brand
							last4
						}
						billing_details {
							address {
								city
								country
								state
								line1
							}
							name
						}
					}
				}
			}
		}
	}
`;

export const ATTACH_CARD_TO_USER = gql`
	mutation attachPaymentToCustomer($args: AttachPaymentToCustomer!) {
		attachPaymentToCustomer(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Card {
						id
						card {
							brand
							last4
						}
						billing_details {
							address {
								city
								country
								state
								line1
							}
							name
						}
					}
				}
			}
		}
	}
`;

export const DELETE_CARD = gql`
	mutation deleteCard($args: DeleteCardInputs!) {
		deleteCard(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const UPDATE_CARD = gql`
	mutation updateCard($args: UpdateCardInputs!) {
		updateCard(args: $args) {
			... on Response {
				statusCode
				success
				message
				# data{
				# 	... on Card{
				# 		billing_details{
				# 			address{
				# 				country
				# 				city
				# 				line1
				# 				state
				# 			}
				# 		}
				# 	}
				# }
			}
		}
	}
`;

export const CREATE_BANK = gql`
	mutation createBank($args: CreateBankInputs!) {
		createBank(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const CREATE_BANK_TOKEN = gql`
	mutation createBankToken($args: CreateBankTokenInputs!) {
		createBankToken(args: $args) {
			... on Response {
				statusCode
				success
				message

				data {
					... on BankToken {
						id
						used
					}
				}
			}
		}
	}
`;

export const UPDATE_BANK = gql`
	mutation updateBank($args: UpdateBankInputs!) {
		updateBank(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Bank {
						last4
					}
				}
			}
		}
	}
`;

export const DELETE_BANK = gql`
	mutation deleteBank($args: DeleteBankInputs!) {
		deleteBank(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const CHARGE_CUSTOMER_WITHOUT_CARD = gql`
	mutation chargeCustomerWithoutSavingCard(
		$args: ChargeCustomerInputsWithoutSavedCard
	) {
		chargeCustomerWithoutSavingCard(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Charge {
						id
						charges {
							data {
								id
							}
						}
						amount
						customer
						currency
						payment_method
						client_secret
					}
				}
			}
		}
	}
`;

export const CHARGE_CUSTOMER = gql`
	mutation chargeCustomer($args: ChargeCustomerInputs!) {
		chargeCustomer(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Charge {
						id
						charges {
							data {
								id
							}
						}
						amount
						customer
						currency
						payment_method
						client_secret
					}
				}
			}
		}
	}
`;

export const CREATE_TRANSFER = gql`
	mutation createTransfer($args: CreateTransferInputs!) {
		createTransfer(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const CREATE_TRANSFER_FROM_PENDING_TRFS = gql`
	mutation createTransferFromPendingTransfer($sellerId: String) {
		createTransferFromPendingTransfer(sellerId: $sellerId) {
			... on AllResponse {
				statusCode
				success
				message
			}
		}
	}
`;

export const CREATE_PAYOUT = gql`
	mutation createPayout($args: CreatePayoutInputs!) {
		createPayout(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const CREATE_PENDING_TRANSFER = gql`
	mutation createPendingTransfer($args: CreatePendingTransfer!) {
		createPendingTransfer(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const DELETE_PENDING_TRANSFER = gql`
	mutation deletePendingTransfer($args: GetPendingTransfer!) {
		deletePendingTransfer(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;
export const CONFIRM_CHARGE = gql`
	mutation confirmCharge($args: ConfirmPaymentInput) {
		confirmCharge(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Charge {
						id
						charges {
							data {
								id
							}
						}
						amount
						customer
						currency
						payment_method
						client_secret
					}
				}
			}
		}
	}
`;
