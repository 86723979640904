import { useMutation } from '@apollo/client';
import { CREATE_NEW_EVENT } from '../../graphql/events/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { useAppSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';

type TParams = {
	onCompleted: (data: TODO) => void;
};

type TQueryArgs = { wereHostsSkipped?: boolean; isHostRequired?: boolean };
type TQueryFn = (args: TQueryArgs) => void;

export const useCreateNewEvent = ({ onCompleted }: TParams) => {
	const createEventState = useAppSelector(state => state.createEvent);
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();

	const onError: ApolloOnErrorFn = error => {
		if (error) {
			setAlert(t('An error occurred!'), 'danger');
		}
	};

	const createNewEvent: TQueryFn = ({
		wereHostsSkipped = false,
		isHostRequired = true,
	}) => {
		let args = {
			...createEventState,
			isHostRequired,
			affiliatedHosts: wereHostsSkipped
				? []
				: [...new Set(createEventState.affiliatedHosts)],
			affiliatedBrands: [...new Set(createEventState.affiliatedBrands)],
			invitedPeople: [...new Set(createEventState?.invitedPeople)],
		};

		if (createEventState.typeOfEvent === 'VIRTUAL') {
			delete args.eventLocation;
		}

		_createNewEvent({
			variables: {
				args,
			},
		});
	};

	const [_createNewEvent, states] = useMutation<
		TCreateNewEventResponse,
		TCreateEventInputsWithArgs
	>(CREATE_NEW_EVENT, {
		onCompleted,
		onError,
	});

	return { createNewEvent, ...states };
};
