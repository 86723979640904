import { useCallback, useEffect, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSetAlert } from './useSetAlerts';
import { useTranslation } from 'react-i18next';
import { useHandleUnmount } from './useHandleUnmount';
import { TUseNavigation } from '../types/exportedTypes';
import { LiveEventMessage } from '../components/live-event/product-section/event-chat/EventChatBox';
import useGetLiveEventMessage from '../api/chat/useGetLiveEventMessage';
import { setEventProduct } from '../redux/eventProductDetail';
import { useAppSelector } from '../redux/store';
import { useGetSingleBrandLazy } from '../api/brands/useGetSingleBrand';
import { useDispatch } from 'react-redux';
import { setCurrentScreen } from '../redux/uiSlice';
import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';
import {
	Timestamp,
	addDoc,
	collection,
	getDocs,
	onSnapshot,
	orderBy,
	query,
} from 'firebase/firestore';
import { db } from '../services/firebase';
import { showLocksToUnInvitedUser } from '../redux/getEventSlice';
import { useLazyQuery, useMutation } from '@apollo/client';
import { START_LIVE_EVENT } from '../graphql/events/mutations';
import { GET_SINGLE_LIVE_EVENT_DETAILS } from '../graphql/events/queries';
import { useIsSpecificViewportWidth } from './useIsSpecificViewportWidth';
import { isAccountHostOrSeller } from '../components/live-event/event-section/utils/LiveEventSection.utils';
import { setLiveEventAudience } from '../redux/chatSlice';

const isAffiliatedSeller = (userId: string, sellerId: string) => {
	return userId === sellerId;
};

const getHostIds = (hosts: TLiveEventAffiliatedHosts[]) => {
	return hosts?.map(host => host?._id);
};

const isAffiliatedHost = (userId: string, hostIds: string[]) => {
	return hostIds?.includes(userId);
};

const isAffiliatedHostOrSeller = (
	loggedInUserDetails: TCommonResponseData | undefined,
	event: TSingleLiveEventData
) => {
	if (
		!loggedInUserDetails ||
		!isAccountHostOrSeller(loggedInUserDetails.typeOfAccount)
	) {
		return false;
	}

	const userId = loggedInUserDetails._id;

	const typeOfAccount = loggedInUserDetails.typeOfAccount;

	return typeOfAccount === 'HOST'
		? isAffiliatedHost(userId, getHostIds(event.affiliatedHosts))
		: isAffiliatedSeller(userId, event?.affiliatedSeller?._id);
};

const getProductsFromBrands = (brands: TLiveEventAffiliatedBrand[]) => {
	return brands.map(brand => brand.brandProducts).flat();
};

export const useLiveEventScreen = ({ eventId }: any) => {
	const navigation = useNavigation<TUseNavigation>();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();
	const [showUsernameModal, setShowUsernameModal] = useState(false);
	const [showEmailCollectionModal, setShowEmailCollectionModal] =
		useState(false);
	const [messages, setMessages] = useState<LiveEventMessage[]>([]);
	const [username, setUsername] = useState('');
	const [visitorEmail, setVisitorEmail] = useState('');
	const [liveStreamAccessToken, setLiveStreamAccessToken] = useState('');
	const [highlightedEventProducts, setHighlightedEventProducts] = useState<
		any[]
	>([]);
	const [highlightedEventProductsTime, setHighlightedEventProductsTime] =
		useState<number[]>([]);
	const [lastStoreEventProductTime, setLastStoreEventProductTime] =
		useState(0);
	const [tempMessagesStore, setTempMessagesStore] = useState<
		LiveEventMessage[]
	>([]);

	const { executeGetLiveEventMessage, data: liveEventMessage } =
		useGetLiveEventMessage();

	useEffect(() => {
		dispatch(setEventProduct({}));
		executeGetLiveEventMessage({
			eventId: eventId,
		});
	}, []);

	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const showLocks = useAppSelector(state => state.getEvent.showLocks);
	const eventProduct = useAppSelector(state => state.eventProduct);
	const { indexOfPrimaryColors } = useAppSelector(state => state.ui);

	const isLoggedInTypeOfAccount =
		isUserLoggedIn && indexOfPrimaryColors === 0
			? 'CONSUMER'
			: indexOfPrimaryColors === 3
			? 'SELLER'
			: indexOfPrimaryColors === 2
			? 'HOST'
			: null;

	// console.log({
	// 	typeOfRequestAccount: loggedInUserDetails?.typeOfAccount,
	// 	isLoggedInTypeOfAccount,
	// });

	const [liveEventDetails, setLiveEventDetails] =
		useState<TSingleLiveEventData>();

	const [products, setProducts] = useState<TGetAllProductsData[]>([]);

	const onCompletedGetSingleBrandDetails = (
		data: TGetSingleBrandResponse
	) => {
		if (data.getBrand.success && data.getBrand.data) {
			setProducts(data?.getBrand?.data?.products?.data);
		}
	};

	const { getSingleBrand, loading } = useGetSingleBrandLazy({
		onCompleted: onCompletedGetSingleBrandDetails,
	});
	useEffect(() => {
		const brandId = liveEventDetails?.affiliatedBrands?.[0]?._id;
		if (brandId) getSingleBrand(brandId);
	}, [liveEventDetails]);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/live-event'));
		dispatch(setEventProduct({}));
		const getAnonymousUsername = async () => {
			const value = await AsyncStorage.getItem('anonymousUsername');
			const email = await AsyncStorage.getItem('anonymousEmail');
			setUsername(value as string);
			setVisitorEmail(email as string);
		};
		getAnonymousUsername();
	}, []);

	useEffect(() => {
		if (
			liveEventDetails?.affiliatedSeller?._id ===
				loggedInUserDetails?._id &&
			liveStreamAccessToken
		) {
			setInterval(() => {
				axios
					.get(
						`https://graph.facebook.com/${liveEventDetails?.socialStreamKey?.facebookStreamKey?.id}/comments?access_token=${liveStreamAccessToken}`
					)
					.then(async data => {
						// @ts-ignore
						const msgsRef = collection(
							db,
							'messages',
							eventId,
							'LiveChat'
						);
						const q = query(msgsRef, orderBy('createdAt', 'asc'));
						const querySnapshot = await getDocs(q);
						let messages: LiveEventMessage[] = [];

						querySnapshot.forEach(doc => {
							const data = doc.data();
							if (data) {
								messages.push(data as LiveEventMessage);
							}
						});

						const getNewMessages = data?.data?.data
							?.filter(
								(chat: TODO) =>
									messages.findIndex(
										(m: TODO) => m?.id === chat?.id
									) === -1
							)
							?.map((newMessage: any) => {
								return {
									...newMessage,
									user: newMessage?.from,
								};
							});

						getNewMessages.forEach((message: any) => {
							addDoc(
								// @ts-ignore
								collection(
									db,
									'messages',
									liveEventDetails?._id,
									'LiveChat'
								),
								{
									message: {
										message: message.message || '',
										messageType: 'TEXT',
									},
									user: {
										id: message?.user?.id || '',
										username:
											message?.user?.name ||
											'Facebook User',
									},
									from: 'Facebook',
									id: message.id || '',
									roomMessage: true,
									createdAt: Timestamp.fromDate(new Date()),
								}
							);
						});
					})
					.catch(error => {
						console.log({ commentError: error });
					});
			}, 5000);
		}
		// const eventSource = new EventSource(`https://streaming-graph.facebook.com/2446835912192690/live_comments?access_token=${access_token}`);

		// eventSource.addEventListener('message', (event) => {
		// 	const newComment = JSON.parse(event.data);
		// 	console.log({ newComment });

		// 	// setComments((prevComments) => [...prevComments, newComment]);
		// });

		// return () => {
		// 	eventSource.close();
		// };
	}, [liveStreamAccessToken]);

	useEffect(() => {
		const userMessages = messages?.filter(
			data => data?.user?.username === username
		);
		if (userMessages.length === 5 && !visitorEmail) {
			setShowEmailCollectionModal(true);
		}
	}, [messages]);

	useEffect(() => {
		const msgsRef = collection(db, 'messages', eventId, 'LiveChat');
		const q = query(msgsRef, orderBy('createdAt', 'asc'));

		onSnapshot(q, querySnapshot => {
			let messages: LiveEventMessage[] = [];
			querySnapshot.forEach(doc => {
				messages.push(doc.data() as LiveEventMessage);
			});
			if (!liveEventDetails) return;
			const isEventEnded =
				liveEventDetails?.deviceMetadata?.streamRecordingInstance &&
				liveEventDetails?.eventStreamTimeline?.endedAt;
			if (!!isEventEnded) {
				setTempMessagesStore(messages);
			} else {
				setMessages(messages);
			}
		});
	}, [liveEventDetails]);

	//TODO: update the data on the backend

	useEffect(() => {
		if (liveEventMessage?.getLiveEventMessages?.data) {
			// const restructureObjArr =
			// 	liveEventMessage?.getLiveEventMessages?.data?.map(data => {
			// 		return {
			// 			...data,
			// 			message: { message: data.message, messageType: 'TEXT' },
			// 			user: data.sender,
			// 		};
			// 	});
			// if (!liveEventDetails) return;
			// const isEventEnded =
			// 	liveEventDetails?.deviceMetadata?.streamRecordingInstance &&
			// 	liveEventDetails?.eventStreamTimeline?.endedAt;
			// if (!!isEventEnded) {
			// 	setTempMessagesStore(restructureObjArr);
			// } else {
			// 	setMessages(restructureObjArr);
			// }
		}
	}, [liveEventMessage]);

	const onTimeUpdate = (event: any) => {
		const videoElement = event.target;
		const currentTimeInSeconds = Math.floor(videoElement.currentTime);
		const liveStreamStart = liveEventDetails?.eventStreamTimeline
			?.startedAt as any;
		const startStreamTimeWithCurrent =
			Math.floor(+liveStreamStart / 1000) + currentTimeInSeconds;
		// const findMessageInTime = tempMessagesStore?.find(
		// 	(data: LiveEventMessage) => {
		// 		return data?.createdAt?.seconds === startStreamTimeWithCurrent;
		// 	}
		// );

		const findMessageAtAnyTime = tempMessagesStore?.filter(
			(data: LiveEventMessage) =>
				data?.createdAt?.seconds <= startStreamTimeWithCurrent
		);

		const findCurrentHighlightProduct = highlightedEventProducts?.find(
			data => {
				return data?.createdAt?.seconds === startStreamTimeWithCurrent;
			}
		);

		// const findMessageInTimeIndex = tempMessagesStore?.findIndex(
		// 	(data: LiveEventMessage) => {
		// 		return data?.createdAt?.seconds === startStreamTimeWithCurrent;
		// 	}
		// );

		if (findMessageAtAnyTime.length > messages.length) {
			// setMessages((prevMessages: LiveEventMessage[]) => {
			// 	const isMessageAlreadyAdded = prevMessages?.find(
			// 		(data: LiveEventMessage) =>
			// 			data?.createdAt?.seconds +
			// 				data?.createdAt?.nanoseconds ===
			// 			findMessageInTime?.createdAt?.seconds +
			// 				findMessageInTime?.createdAt?.nanoseconds
			// 	);
			// 	if (isMessageAlreadyAdded) return prevMessages;
			// 	return [...prevMessages, findMessageInTime];
			// });
			setMessages(findMessageAtAnyTime);
		}
		if (findCurrentHighlightProduct) {
			const storedTime =
				findCurrentHighlightProduct?.createdAt?.seconds +
				findCurrentHighlightProduct?.createdAt?.nanoseconds;
			setLastStoreEventProductTime(storedTime);
			setTimeout(() => {
				if (lastStoreEventProductTime !== storedTime) {
					dispatch(setEventProduct({}));
				}
			}, 100);
			setTimeout(() => {
				// console.log({ products, highlightedEventProducts: findCurrentHighlightProduct?.eventProduct });

				const getOnlyProductVariants = products
					?.map(data => data?.variants)
					?.flat()
					?.find(
						variant =>
							variant.GTIN ===
							findCurrentHighlightProduct?.eventProduct?.GTIN
					);

				console.log({
					updatedProductToUser: {
						associatedProduct:
							findCurrentHighlightProduct?.eventProduct,
					},
					...getOnlyProductVariants,
					products,
				});

				if (lastStoreEventProductTime !== storedTime) {
					dispatch(
						setEventProduct({
							...getOnlyProductVariants,
							associatedProduct:
								findCurrentHighlightProduct?.eventProduct
									?.associatedProduct,
						})
					);
				}
			}, 500);
		}
	};

	useFocusEffect(
		useCallback(() => {
			if (loggedInUserDetails?.typeOfAccount === 'CONSUMER') {
				const loggedInUserId = loggedInUserDetails?._id;
				const invitedUsers = liveEventDetails?.invitedPeople?.map(
					user => user._id
				);
				const isUserInvited =
					invitedUsers?.length &&
					invitedUsers.includes(loggedInUserId);
				// setting show locks to false, as means to remove subscribe feature temporarily,
				// dispatch(showLocksToUnInvitedUser(!isUserInvited));
				dispatch(showLocksToUnInvitedUser(false));
			}
		}, [liveEventDetails])
	);

	const handleErrorFetchingEventDetails = () => {
		setAlert(t('Unable to fetch event details!'), 'danger');
		navigation.navigate('Events');
	};

	const [
		startLiveEvent,
		{ loading: loadingStartLiveEvent, called: startLiveStreamCalled },
	] = useMutation<TStartLiveEventResponse, TStartLiveEventInputs>(
		START_LIVE_EVENT,
		{
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_SINGLE_LIVE_EVENT_DETAILS,
					variables: {
						_id: eventId ?? '',
						typeOfAccount:
							loggedInUserDetails?.typeOfAccount as TTypeOfAccount,
					},
				},
			],
			onCompleted: data => {
				if (!data.startLiveEvent) {
					setAlert(t('An error occurred!'), 'danger');
					navigation.navigate('Events');
				} else {
					getSingleEventDetails({
						variables: {
							_id: eventId ?? '',
							typeOfAccount:
								loggedInUserDetails?.typeOfAccount as TTypeOfAccount,
						},
					});
				}
			},
			onError: () => {
				setAlert(t('An error occurred!'), 'danger');
				navigation.navigate('Events');
			},
		}
	);

	useEffect(() => {
		getSingleEventDetails({
			variables: {
				_id: eventId ?? '',
				typeOfAccount:
					loggedInUserDetails?.typeOfAccount as TTypeOfAccount,
			},
		});
	}, [eventId]);

	const [
		getSingleEventDetails,
		{ loading: loadingGetSingleLiveEventDetails },
	] = useLazyQuery<TSingleLiveEventResponse, TSingleLiveEventInputs>(
		GET_SINGLE_LIVE_EVENT_DETAILS,
		{
			nextFetchPolicy: 'cache-first',
			onCompleted: async data => {
				if (data.getEvent.data && data.getEvent.success) {
					const event = data.getEvent.data;

					// unnecessary because we cannot route to a specific
					// page directly from url in React Native Web.
					// This would have been required in plain react

					// const isCurrentTimeGreaterThanBuyingPeriod =
					// 	currentTimeGreaterThanBuyingPeriod(
					// 		event.startingEventDateTime
					// 	);

					// if (isCurrentTimeGreaterThanBuyingPeriod) {
					// 	navigation.navigate('EventDescription', {
					// 		eventId: event._id,
					// 	});
					// }

					if (isAffiliatedHostOrSeller(loggedInUserDetails, event)) {
						if (
							event.isStreamGenerated &&
							event?.liveStream?.stream?.channel?.arn
						) {
							const products = getProductsFromBrands(
								event.affiliatedBrands
							);

							setProducts(products);
						} else {
							if (!startLiveStreamCalled) {
								startLiveEvent({
									variables: {
										_id: eventId ?? '',
									},
								});
								setLiveEventDetails(event);
							}
						}
					} else if (
						loggedInUserDetails?.typeOfAccount === 'CONSUMER' ||
						!loggedInUserDetails?.typeOfAccount
					) {
						const products = getProductsFromBrands(
							event.affiliatedBrands
						);

						setProducts(products);
						setLiveEventDetails(event);
					} else {
						// const stream = await getMediaStream();
						// stopVideoAndAudio(stream);
						setAlert(t('Not allowed!'), 'danger');
						navigation.navigate('Events');
					}
					setLiveEventDetails(event);
				} else {
					handleErrorFetchingEventDetails();
				}
			},
			onError: () => {
				handleErrorFetchingEventDetails();
			},
		}
	);

	// useEffect(() => {
	//     // window.addEventListener('beforeunload', alertUser)
	//     window.addEventListener('unload', handleTabClosing)
	//     return () => {
	//         // window.removeEventListener('beforeunload', alertUser)
	//         window.removeEventListener('unload', handleTabClosing)
	//     }
	// })

	// const handleTabClosing = () => {
	//     removePlayerFromGame()
	// }

	useEffect(() => {
		if (liveEventDetails) {
			// isAssociatedWithEvent(liveEventDetails, loggedInUserDetails) &&
			// 	socket.on('liveEventRoomData', ({ roomData }) => {
			// 		dispatch(setLiveEventAudience(roomData));
			// 	});
			// if (isAssociatedWithEvent(liveEventDetails, loggedInUserDetails)) {
			//@ts-ignore
			const liveAudienceRef = collection(
				db,
				'eventAudience',
				eventId,
				'LiveEventAudience'
			);
			const q = query(liveAudienceRef);

			onSnapshot(q, querySnapshot => {
				//@ts-ignore
				let liveEventAudience = [];
				querySnapshot.forEach(doc => {
					liveEventAudience.push(doc.data());
				});
				//@ts-ignore
				dispatch(setLiveEventAudience(liveEventAudience));
			});
			// }
		}
	}, [liveEventDetails]);

	useEffect(() => {
		if (liveEventDetails) {
			const isEventEnded =
				liveEventDetails?.deviceMetadata?.streamRecordingInstance &&
				liveEventDetails?.eventStreamTimeline?.endedAt;
			const liveStreamStart = liveEventDetails?.eventStreamTimeline
				?.startedAt as any;
			const startStreamTimeWithCurrent = Math.floor(
				+liveStreamStart / 1000
			);
			//@ts-ignore
			const eventHighLightProductRef = collection(
				db,
				'eventSelectedProduct',
				eventId,
				'product'
			);
			const q = query(eventHighLightProductRef);

			onSnapshot(q, querySnapshot => {
				let highlightedProducts: Variant[] = [];
				const changes = querySnapshot.docChanges();
				querySnapshot.forEach(doc => {
					highlightedProducts.push(doc.data() as Variant);
				});
				if (
					!isEventEnded &&
					loggedInUserDetails?.typeOfAccount !== 'SELLER'
				) {
					if (changes?.length === 1) {
						setTimeout(() => {
							dispatch(setEventProduct({}));
						}, 100);
						setTimeout(() => {
							dispatch(
								setEventProduct(
									//@ts-ignore
									highlightedProducts[changes?.[0]?.newIndex]
										?.eventProduct
								)
							);
						}, 1000);
					}
				}
				setHighlightedEventProducts(highlightedProducts);
				setHighlightedEventProductsTime(
					highlightedProducts?.map(
						data =>
							//@ts-ignore
							data?.createdAt?.seconds -
							startStreamTimeWithCurrent
					)
				);
			});
		}
	}, [liveEventDetails]);

	const handleLiveAudience = async () => {
		const vId = await AsyncStorage.getItem('vId');
		const visitorUser = {
			userId: vId,
			room: eventId,
			userDetails: {
				username: username || 'visitor',
			},
		};
		const user = {
			userId: loggedInUserDetails?._id,
			room: eventId,
			userDetails: loggedInUserDetails,
		};

		try {
			await addDoc(
				//@ts-ignore
				collection(db, 'eventAudience', eventId, 'LiveEventAudience'),
				isUserLoggedIn ? user : visitorUser
			);
		} catch (error) {
			console.log({ error }, 'why it failed');
		}
	};

	const liveEventAudience = useAppSelector(
		state => state.chat.liveEventAudience
	);

	useEffect(() => {
		AsyncStorage.getItem('vId').then(id => {
			// console.log({ liveEventAudience, id });
			const isUserAlreadyInEvent = liveEventAudience.find(
				data => data.userId === (loggedInUserDetails?._id || id)
			);
			if (
				// eventId &&
				liveEventDetails &&
				!isUserAlreadyInEvent
			) {
				// if (isAssociatedWithEvent(liveEventDetails, loggedInUserDetails) || !loggedInUserDetails?.typeOfAccount) {
				handleLiveAudience();
				// }
			}
		});
	}, [loggedInUserDetails?._id, liveEventDetails]);

	// useEffect(() => {
	// 	if (loggedInUserDetails?._id && eventId && liveEventDetails) {
	// 		// if (isAssociatedWithEvent(liveEventDetails, loggedInUserDetails)) {
	// 		// 	handleLiveAudience();
	// 		// }

	// 		isAssociatedWithEvent(liveEventDetails, loggedInUserDetails) &&
	// 			socket.emit(
	// 				'joinEvent',
	// 				{
	// 					userId: loggedInUserDetails?._id,
	// 					room: eventId,
	// 					userDetails: loggedInUserDetails,
	// 				},
	// 				({
	// 					roomData,
	// 					isAlreadyInRoom,
	// 				}: {
	// 					roomData: RoomData[];
	// 					isAlreadyInRoom: boolean;
	// 				}) => {
	// 					if (isAlreadyInRoom) {
	// 						// setAlert(
	// 						// 	t('You are already in this room!'),
	// 						// 	'danger'
	// 						// );
	// 						// navigation.navigate('Events');
	// 					}
	// 					dispatch(setLiveEventAudience(roomData));
	// 				}
	// 			);
	// 	}
	// }, [loggedInUserDetails?._id, liveEventDetails]);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return {
		shouldHideScreen,
		loadingGetSingleLiveEventDetails,
		loadingStartLiveEvent,
		liveEventDetails,
		products,
		showLocks,
		messages,
		showUsernameModal,
		username,
		showEmailCollectionModal,
		isUserLoggedIn,
		isLessThanDesktopBase,
		highlightedEventProductsTime,
		setLiveStreamAccessToken,
		onTimeUpdate,
		setVisitorEmail,
		setUsername,
		setShowUsernameModal,
		setShowEmailCollectionModal,
	};
};
