import React from 'react';
import { Pressable } from 'react-native-web-hover';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';

type Props = {
	title: string;
	text: string;
	onSubmit: (userType: TTypeOfEvent) => void;
	value: TTypeOfEvent;
	selected: boolean;
};

export const EventSelectButton = ({
	text,
	title,
	onSubmit,
	value,
	selected,
}: Props) => {
	const onSelectUserType = (eventType: TTypeOfEvent) => {
		onSubmit(eventType);
	};
	const primary = usePrimaryColors();
	return (
		<Pressable
			style={({ focused }) => [
				styles.button,
				styleButtonOuterContainer(),
				styles.buttonOuterContainer,
				(focused || selected) && { borderColor: primary[1] },
			]}
		>
			{({ hovered, focused, pressed }) => (
				<View
					style={[
						styles.buttonInnerContainer,
						styleButtonInnerContainer(),
						(focused || selected) && { borderColor: primary[0] },
						pressed && { borderColor: primary[0] },
					]}
				>
					<View style={styleButtonInner()}>
						<Text
							style={[
								styles.title,
								styleTitle(),
								hovered && styles.titleHovered,
								(focused || selected) && styles.titleFocused,
								pressed && styles.titlePressed,
							]}
						>
							{pressed && onSelectUserType(value)}
							{title}
						</Text>

						<Text style={styleText()}>{text}</Text>
					</View>
				</View>
			)}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	buttonOuterContainer: {
		borderWidth: 3,
		borderColor: 'transparent',
		borderRadius: 8,
	},
	button: {
		backgroundColor: '#fff',
	},
	buttonInnerContainer: {
		borderWidth: 1,
		borderColor: palettes.grey[3],
		borderRadius: 6,
	},
	buttonLabel: { fontWeight: 'bold' },
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		color: palettes.grey[0],
	},
	title: {
		marginBottom: 6,
		fontStyle: 'normal',
		fontWeight: 'normal',
		color: palettes.dark[4],
	},
	titleFocused: {
		color: palettes.dark[0],
	},
	titleHovered: {
		color: palettes.dark[0],
	},
	titlePressed: {
		color: palettes.dark[0],
	},
});

const styleButtonInnerContainer = () => {
	return { paddingVertical: getResponsiveStyle(18) };
};

function styleTitle() {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
	};
}

function styleButtonInner() {
	return {
		marginLeft: getResponsiveStyle(24),
		marginRight: getResponsiveStyle(24),
	};
}

function styleButtonOuterContainer() {
	return {
		marginBottom: getResponsiveStyle(12),
	};
}
