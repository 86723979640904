type TFunction = (
	typeOfAccount: TGetAllEventCommon | TSingleLiveEventData,
	loggedInUserDetails?: TCommonResponseData
) => boolean;

export const isOwnerOfEvent: TFunction = (event, loggedInUserDetails) => {
	if (loggedInUserDetails?.typeOfAccount === 'SELLER') {
		return loggedInUserDetails?._id === event.affiliatedSeller?._id;
	} else {
		return loggedInUserDetails?._id === event.affiliatedHosts?.[0]?._id;
	}
};

export const isInvitedUser: TFunction = (event, loggedInUserDetails) => {
	const invitedPeopleArray = event?.invitedPeople;
	let isInvited = false;

	for (const invitedPeople of invitedPeopleArray) {
		if (invitedPeople?._id === loggedInUserDetails?._id) {
			isInvited = true;
		}
	}

	return isInvited;
};

export const isAffiliatedHost = (
	event: TGetAllEventCommon,
	loggedInUserDetails: TGetAllUsersData
) => {
	return (
		(loggedInUserDetails?._id === event?.affiliatedHosts?.[0]?._id &&
			event?.isHostApplicationAccepted) ||
		(!event?.affiliatedHosts?.length &&
			loggedInUserDetails?._id === event?.affiliatedSeller?._id &&
			event.typeOfEvent === 'VIRTUAL')
	);
};

export const isAffiliatedSeller = (
	event: TGetAllEventCommon,
	loggedInUserDetails: TGetAllUsersData
) => {
	return loggedInUserDetails?._id === event?.affiliatedSeller?._id;
};

export const isAssociatedWithEvent: TFunction = (
	event,
	loggedInUserDetails
) => {
	if (loggedInUserDetails?.typeOfAccount === 'CONSUMER') {
		return isInvitedUser(event, loggedInUserDetails);
	} else {
		return isOwnerOfEvent(event, loggedInUserDetails);
	}
};
