import React from 'react';
import { View } from 'react-native';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import Skeleton from '../Skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const TopProfileSkeleton = () => {
	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: getResponsiveStyle(15),
			}}
		>
			<View style={{ flexDirection: 'row' }}>
				<Skeleton
					width={getResponsiveStyle(45)}
					height={getResponsiveStyle(45)}
					style={styles.image}
				/>
				<View style={{ justifyContent: 'space-evenly' }}>
					<Skeleton
						width={getResponsiveStyle(71)}
						height={getResponsiveStyle(10)}
						style={styles.radius}
					/>
					<Skeleton
						width={getResponsiveStyle(85)}
						height={getResponsiveStyle(10)}
						style={styles.radius}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = {
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: getResponsiveStyle(15),
	},
	image: { borderRadius: 9999, marginRight: getResponsiveStyle(10) },
	info: {
		alignItems: 'center',
	},
	radius: {
		borderRadius: 6,
	},
};

export default TopProfileSkeleton;
