import {
	View,
	Text,
	StyleSheet,
	Pressable,
	ListRenderItem,
} from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppSelector } from '../../../redux/store';
import DraggableScrollView from '../../common/DraggableScrollView';
import { TStyle } from '../../../types/TStyle';

type Props = {
	tabs: { label: string; value: string }[];
	onPress: (tab: string) => void;
	selectedTab: string;
	isAllowedLocation: boolean;
};

const DescriptionTabs = ({
	tabs,
	onPress,
	selectedTab,
	isAllowedLocation,
}: Props) => {
	const { width } = useIsSpecificViewportWidth();
	const primaryColors = usePrimaryColors();
	const { typeOfEvent } = useAppSelector(state => state.getEvent.event);
	const { loggedInUserDetails } = useAppSelector(state => state.auth);
	const tabLimit =
		loggedInUserDetails?.typeOfAccount !== 'SELLER'
			? 2
			: typeOfEvent === 'PHYSICAL' && isAllowedLocation
			? 4
			: 3;

	const renderItem: ListRenderItem<{ label: string; value: string }> = ({
		item,
		index,
	}) => {
		return (
			<Pressable key={item?.value} onPress={() => onPress(item?.value)}>
				<Text
					style={styleTab(
						selectedTab,
						item?.value,
						width,
						primaryColors
					)}
				>
					{item?.label}
				</Text>
			</Pressable>
		);
		// }
	};
	return (
		<View style={styles.container}>
			<DraggableScrollView
				data={tabs.slice(0, tabLimit)}
				horizontal
				showsHorizontalScrollIndicator={false}
				renderItem={renderItem as any}
				style={styleDraggableScroll()}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container_: {
		flexDirection: 'row',
		zIndex: 1,
		position: 'relative',
		// justifyContent: 'center',
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
		maxWidth: '100%',
		zIndex: 2,
		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 2,
	},
	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],

		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 2,
	},
});

export default DescriptionTabs;

const styleTab = (
	selectedTab: string,
	tab: string,
	width: number,
	primary: string[]
) => {
	const tabSelected = {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 2,
		zIndex: 3,
	};
	const tabNotSelected = {
		color: palettes.grey[0],
		fontWeight: '400',
	};

	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		selectedTab === tab
			? { ...tabSelected, borderBottomColor: primary[0] }
			: tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};

const styleDraggableScroll = (): TStyle => {
	return [
		styles.container_,
		{
			width: '100%',
		},
	];
};
