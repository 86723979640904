import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useGetSingleBrandLazy } from '../../../api/brands/useGetSingleBrand';
import { palettes } from '../../../config';

import { LiveEventProductViewProps } from '../event-section-mobile/ProductView';
import { LiveEventLock } from '../event-section/LiveEventLock';
import ChatTab from './ChatTab';
import CustomLabel from './CustomLabel';
import EventChatBox, { LiveEventMessage } from './event-chat/EventChatBox';
import PeopleSection from './event-chat/PeopleSection';
import EventProduct from './event-product/EventProduct';
import { useTranslation } from 'react-i18next';

// import { socket } from '../../chat/chat-box/ChatBox';
import { useAppSelector } from '../../../redux/store';

type Props = {
	showLocks: boolean;
	messages: LiveEventMessage[];
	eventDetails: TSingleLiveEventData;
	onMessageSend?: (e?: TODO) => void;
	handleChange?: (e?: string) => void;
	handleTypingFocusIn: () => void;
} & LiveEventProductViewProps;
const ProductSection = ({
	brands,
	showLocks,
	eventDetails,
	messages,
	handleTypingFocusIn,
	handleChange,
}: Props) => {
	const { t } = useTranslation();
	const SECTION_TABS = [
		{ label: t('Chat'), value: 'Chat' },
		{ label: t('Participants'), value: 'Participants' },
	];

	const SECTION: TEventChat = 'chat';

	const getTabs = (section: TEventChat) => {
		return SECTION_TABS;
	};
	const [selectedTab, setSelectedTab] = React.useState<string>(
		getTabs(SECTION)[0].value
	);

	// const [roomData, setRoomData] = useState([]);

	const [selectedView, setSelectedView] = React.useState<string>();

	const [brandProducts, setBrandProducts] = useState<TGetAllProductsData[]>(
		[]
	);

	// const [messages, setMessages] = useState<LiveEventMessage[]>([]);

	// useEffect(() => {
	// 	socket.on('liveEventMessage', data => {
	// 		setMessages(prev => [...prev, data]);
	// 	});
	// }, []);

	const liveEventAudience = useAppSelector(
		state => state.chat.liveEventAudience
	);

	// useEffect(() => {
	// 	socket.on('liveEventRoomData', (roomData) => {

	// 		// setRoomData(roomData);
	// 	});
	// });

	const onCompletedGetSingleBrandDetails = (
		data: TGetSingleBrandResponse
	) => {
		if (data.getBrand.success && data.getBrand.data) {
			setBrandProducts(
				data?.getBrand?.data?.products?.data?.filter(
					product => product.publishedStatus === 'ACTIVE'
				)
			);
		}
	};

	const { getSingleBrand, loading } = useGetSingleBrandLazy({
		onCompleted: onCompletedGetSingleBrandDetails,
	});

	useEffect(() => {
		if (brands?.[0]?._id) getSingleBrand(brands?.[0]?._id);
	}, []);

	const onPress = (tab: string) => {
		setSelectedTab(tab);

		// TODO: Set wondows scroll here
	};

	const onSelect = (view: string) => {
		setSelectedView(view);
	};
	return (
		<View style={styles.container}>
			<LiveEventLock
				lockIconDimensions={{
					height: 36,
					width: 33,
				}}
				lockBackgroundDimension={{
					height: 84,
					width: 84,
				}}
				lockColor={'red'}
				showLocks={showLocks}
			/>
			{selectedView === 'Product' && (
				<>
					<CustomLabel
						labelText={t('Product')}
						selectText=""
						labelLink="Close"
						onSelect={onSelect}
					/>
					<EventProduct
						expandHeight={true}
						products={brandProducts}
						eventDetails={{} as TSingleLiveEventData}
					/>
				</>
			)}

			{selectedView === 'Participants' && (
				<>
					<CustomLabel
						labelText={'Participants'}
						selectText=""
						labelLink="Close"
						onSelect={onSelect}
					/>
					<ChatTab
						onPress={onPress}
						selectedTab={selectedTab}
						tabs={getTabs(SECTION)}
						center={false}
					/>
					{selectedTab === 'Participants' ? (
						<PeopleSection
							roomData={liveEventAudience}
							expandHeight={true}
						/>
					) : (
						<EventChatBox
							eventDetails={eventDetails}
							messages={messages}
							expandHeight={true}
							handleChange={handleChange}
							handleTypingFocusIn={handleTypingFocusIn}
						/>
					)}
				</>
			)}

			{!selectedView && (
				<>
					<View style={styles.box1}>
						<CustomLabel
							labelText={'Product'}
							labelLink="View All"
							selectText="Product"
							onSelect={onSelect}
						/>
						<EventProduct
							eventDetails={eventDetails}
							products={brandProducts}
							loadingSkeleton={loading}
						/>
					</View>
					<View style={styles.box2}>
						<CustomLabel
							labelText={'Participants'}
							selectText={'Participants'}
							labelLink="View All"
							onSelect={onSelect}
						/>
						<ChatTab
							onPress={onPress}
							selectedTab={selectedTab}
							tabs={getTabs(SECTION)}
							center={false}
						/>
						{selectedTab === 'Participants' ? (
							<PeopleSection roomData={liveEventAudience} />
						) : (
							<EventChatBox
								eventDetails={eventDetails}
								messages={messages}
								handleChange={handleChange}
								handleTypingFocusIn={handleTypingFocusIn}
							/>
						)}
					</View>
				</>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		width: '25%',
		paddingLeft: 20,
		paddingRight: 20,
		borderLeftWidth: 1,
		borderColor: palettes.grey[4],
		// borderBottomWidth: 2,
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: 'white',
	},
	labeText: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 18,
		lineHeight: 14,
		color: palettes.dark[0],
	},
	labelLink: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 14,
		textDecorationLine: 'underline',
		color: '#98A2B2',
	},
	label: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	box1: {
		// borderColor: palettes.grey[4],
		// borderLeftWidth: 2,
		// borderBottomWidth: 2,
		// borderRightWidth: 2,
		// borderTopWidth: 2,
		flex: 1,
	},
	box2: {
		// borderColor: palettes.grey[4],
		// borderLeftWidth: 2,
		// borderBottomWidth: 2,
		// borderRightWidth: 2,
		// borderTopWidth: 2,
		flex: 1,
	},
});

export default ProductSection;
