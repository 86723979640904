import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, Linking } from 'react-native';
import styled from 'rn-css';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
const Link = styled.Text<{ primary: string }>`
	cursor: pointer;
	color: ${({ primary }) => primary};
	&:hover {
		border-bottom: 1px solid ${({ primary }) => primary};
	}
`;
const handlePress = (url: string) => {
	Linking.canOpenURL(url).then(supported => {
		return Linking.openURL(url);
	});
};

const LoginFooter = () => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	return (
		<View style={[styles.container, styleContainer()]}>
			<Text style={[styles.textCommon, styleTextCommon()]}>
				{t('Need help?')}{' '}
				{/* <Link
					style={styles.link}
					onPress={() => handlePress('mailto:support@ocpus.com')}
					primary={primary?.[0]}
				>
					{t('Click here')}
				</Link>{' '} */}
				{t('Please')} {t('send an email to')}: {'\n'}{' '}
				<Link
					style={styles.link}
					onPress={() => handlePress('mailto:support@ocpus.io')}
					primary={primary?.[0]}
				>
					support@ocpus.io
				</Link>
			</Text>
		</View>
	);
};

export default LoginFooter;

function styleContainer() {
	return { marginBottom: getResponsiveStyle(31) };
}

function styleTextCommon(): TStyleInputOrText {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
	};
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	link: {
		paddingBottom: 2,
		fontWeight: '600',
	},
	textCommon: {
		textAlign: 'center',
		letterSpacing: 0.18,
	},
});
