import React, { ComponentProps, CSSProperties } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Label from '../label/Label';
import { TDateTimeTypeProps, WebDateTimePicker } from './WebDateTimePicker';

type Props = {
	containerStyles?: TStyle;
	dateTimeProps?: TDateTimeTypeProps;
	label?: string;
};

// return different datetime picker for different platforms
const DateTimePicker = ({ containerStyles, dateTimeProps, label }: Props) => {
	return (
		<View style={[styles.container, containerStyles]}>
			{label && <Label label={label} />}
			{Platform.OS === 'web' && dateTimeProps && (
				<WebDateTimePicker {...dateTimeProps} />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: '100%',
		marginRight: getResponsiveStyle(16),
	},
});

export default DateTimePicker;
