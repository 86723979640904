import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { ActionLink } from '../..';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { handleNavigationToEvent } from '../../../utils/handleNavigationToEvent';

export const ViewDetailsButton = ({ event }: { event: TGetAllEventsData }) => {
	const navigation = useNavigation<TUseNavigation>();
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(
		state => state.auth
	);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const navigateToGuestSignUp = () => {
		dispatch(setTypeOfAccount('CONSUMER'));
		dispatch(setPrimaryColors('CONSUMER'));
		dispatch(setSignupSidebarValues('CONSUMER'));
		navigation.navigate('PersonalInfo');
	};

	return (
		<ActionLink
			onPress={() =>
				isUserLoggedIn
					? handleNavigationToEvent({
							event,
							navigation,
							loggedInUserDetails,
							dispatch,
					  })
					: navigateToGuestSignUp()
			}
			style={{
				fontSize: getResponsiveStyle(12, 'font'),
				lineHeight: getResponsiveStyle(14, 'font'),
			}}
		>
			{t('View Detail')}
		</ActionLink>
	);
};
