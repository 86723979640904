import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ShoppingBagIconOutline = (props: SvgProps) => (
	<Svg viewBox="0 0 24 24" fill="none" {...props}>
		<Path
			d="M1.46 12.528l.497-.055-.497.055zm19.025 0l-.497-.055.497.055zm.282-2.536l.497.055-.497-.055zm-19.59 0l.498-.055-.497.055zm15.51.08a.5.5 0 100-1v1zm-11.429-1a.5.5 0 000 1v-1zm15.012.865l-.282 2.536.994.11.282-2.536-.994-.11zM1.957 12.473l-.282-2.536-.994.11.282 2.536.994-.11zm9.015 8.027c-2.056 0-3.55 0-4.703-.148-1.138-.145-1.875-.425-2.448-.938l-.667.745c.783.7 1.745 1.027 2.989 1.185 1.23.157 2.8.156 4.83.156v-1zM.962 12.583c.223 2.002.394 3.55.685 4.748.294 1.212.724 2.128 1.507 2.828l.667-.745c-.574-.514-.934-1.212-1.202-2.319-.272-1.12-.437-2.594-.662-4.622l-.994.11zm19.026-.11c-.225 2.028-.39 3.501-.662 4.622-.269 1.107-.628 1.805-1.202 2.319l.667.745c.782-.7 1.213-1.616 1.507-2.828.29-1.197.462-2.746.684-4.748l-.994-.11zM10.972 21.5c2.03 0 3.6 0 4.83-.156 1.243-.158 2.206-.484 2.989-1.185l-.667-.745c-.573.513-1.31.793-2.448.938-1.153.147-2.647.148-4.704.148v1zm6.009-15.238c1.008 0 1.711.001 2.236.074.51.072.766.201.939.394l.745-.667c-.396-.442-.921-.63-1.546-.717-.61-.085-1.396-.084-2.374-.084v1zm4.283 3.785c.108-.972.196-1.753.178-2.37-.017-.63-.146-1.172-.541-1.614l-.745.667c.172.192.272.46.287.976.015.53-.062 1.229-.173 2.23l.994.111zm-16.3-4.785c-.978 0-1.765-.001-2.375.084-.624.087-1.15.275-1.545.717l.745.667c.173-.193.428-.322.939-.394.524-.073 1.228-.074 2.236-.074v-1zm-3.29 4.675c-.11-1.002-.187-1.701-.172-2.231.014-.516.115-.784.287-.976l-.745-.667C.65 6.505.52 7.047.502 7.678c-.017.616.07 1.397.179 2.37l.994-.111zm15.013-.866H5.258v1h11.429v-1zM10.972 1.5a4.262 4.262 0 014.262 4.262h1A5.262 5.262 0 0010.972.5v1zm4.762 4.762h1.247v-1h-1.247v1zm-10.77 0H6.21v-1H4.964v1zm1.246 0h9.524v-1H6.21v1zM10.972.5A5.262 5.262 0 005.71 5.762h1A4.262 4.262 0 0110.972 1.5v-1z"
			fill="#98A2B2"
		/>
	</Svg>
);

export default ShoppingBagIconOutline;
