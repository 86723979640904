import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_USER_MUTATION } from '../../graphql/auth/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { useAppSelector } from '../../redux/store';

const useCreateUser = (
	onCompleted: (data: TCreateUserResponse) => void | undefined,
	_onError?: (error: ApolloError) => void | undefined
) => {
	const authState = useAppSelector(state => state.createUser);
	const { setAlert } = useSetAlert();

	const onError = (error: ApolloError) => {
		if (error) {
			setAlert(error.message, 'danger');
		}
	};

	const [_createUser, { data, error, loading }] =
		useMutation<TCreateUserResponse>(CREATE_USER_MUTATION, {
			onCompleted,
			onError: error => {
				_onError === undefined ? onError(error) : _onError(error);
			},
		});
	const createUser = () => {
		_createUser({
			variables: {
				args: authState.args,
			},
		});
	};
	return { createUser, data, error, loading };
};

export default useCreateUser;
