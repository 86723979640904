import React from 'react';
import {
	Linking,
	StyleSheet,
	Text,
	TextProps,
	TouchableOpacity,
	TouchableOpacityProps,
	View,
	ViewProps,
} from 'react-native';
import { Pressable } from 'react-native-web-hover';
import styled from 'rn-css';
import { palettes } from '../../../../config';
import { usePrimaryColors } from '../../../../hooks/usePrimaryColors';
import { TStyle, TStyleInputOrText } from '../../../../types/TStyle';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import {
	dayMonthYear,
	millisecondsInHoursSecondsAndMinutes,
} from '../../../../utils/monthsUtils';
type Props = {
	textProps?: TextProps;
	innerContainerProps?: TouchableOpacityProps;
	outerContainerProps?: ViewProps;
	title: string | undefined;
	variant?: TVariant;
	size?: TSize;
	date?: number;
	onPress?: () => void;
	disabled?: boolean;
	outlineColor?: string;
	loading?: boolean;
	radius?: number;
	width?: number;
};
type TSize = 'sm' | 'md' | 'lg';
type TVariant = 'outline' | 'grey' | 'primary' | 'disabled' | 'grey-outline';

type TVariantStyles = {
	[K in TVariant]: {
		['container']: TStyle;
		['text']: TStyleInputOrText;
		['hover']: TStyle;
	};
};

const StyledPressable = styled(Pressable)<{ disabled: boolean }>`
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Link = ({
	textProps,
	innerContainerProps,
	outerContainerProps,
	variant = 'outline',
	size = 'sm',
	title,
	date = 0,
	outlineColor,
	onPress = () => {},
	loading = false,
	width,
}: Props) => {
	// Moved variantStyles here to make the ouline colour dynamic
	const primary = usePrimaryColors();
	const linkDateTime = new Date(date);
	const linkDate = dayMonthYear(linkDateTime);
	const linkTime = millisecondsInHoursSecondsAndMinutes(
		linkDateTime.getTime()
	);
	const variantStyles: TVariantStyles = {
		outline: {
			container: {
				borderColor: outlineColor ?? primary[0],
			},
			text: {
				color: outlineColor ?? primary[0],
			},
			hover: {
				borderColor: `${outlineColor ?? primary[0]}20`,
			},
		},
		grey: {
			container: {
				borderColor: palettes.grey[3],
			},
			text: {
				color: palettes.grey[8],
			},
			hover: {
				borderColor: '#E0e4e9',
			},
		},
		primary: {
			container: {
				borderColor: primary[0],
				backgroundColor: primary[0],
			},
			text: {
				color: 'white',
			},
			hover: {
				// backgroundColor: "#5131DA",
				// borderColor: "transparent",
				// borderWidth: 0,
			},
		},
		disabled: {
			container: {
				borderColor: palettes.grey[3],
				backgroundColor: palettes.grey[9],
			},
			text: {
				color: palettes.grey[1],
			},
			hover: {},
		},
		'grey-outline': {
			container: {
				borderColor: palettes.grey[4],
				backgroundColor: 'white',
			},
			text: {
				color: palettes.dark[2],
			},
			hover: {
				borderColor: `${palettes.grey[3]}20`,
			},
		},
	};

	const outerContainerStyles = outerContainerProps?.style;
	const innerContainerStyles = innerContainerProps?.style;
	delete innerContainerProps?.style;
	delete outerContainerProps?.style;
	delete textProps?.style;
	const buttonWidth = width || 10;
	return (
		<StyledPressable
			style={outerContainerStyles}
			disabled={variant === 'disabled'}
		>
			{({ hovered }) => {
				return (
					<View
						style={[
							commonStyles.outerContainer,
							hovered && commonStyles.hover,
							hovered && variantStyles[variant].hover,
							loading && variantStyles['disabled'].hover,
						]}
						{...outerContainerProps}
					>
						<TouchableOpacity
							onPressIn={() => {
								Linking.openURL(title as string);
							}}
							style={[
								commonStyles.innerContainer,
								variantStyles[variant].container,
								styleContainer(size, buttonWidth),
								innerContainerStyles,
								loading && variantStyles['disabled'].container,
							]}
							{...innerContainerProps}
							onPress={
								variant === 'disabled' ? () => {} : onPress
							}
							disabled={variant === 'disabled'}
						>
							<View style={commonStyles.linkRapper}>
								<Text style={commonStyles.link}>
									{title?.substring(0, 30)}
								</Text>
								<View style={commonStyles.linkDate}>
									<Text>{linkDate}</Text>
									<Text>{linkTime}</Text>
								</View>
							</View>
						</TouchableOpacity>
					</View>
				);
			}}
		</StyledPressable>
	);
};

const commonStyles = StyleSheet.create({
	loaderContainer: {
		position: 'relative',
		marginLeft: getResponsiveStyle(16),
	},
	outerContainer: {
		borderWidth: 3,
		borderColor: 'transparent',
		width: 'max-content',
	},
	innerContainer: {
		borderRadius: 6,
		borderWidth: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
	},
	linkRapper: {},
	link: {
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 18,
		color: palettes.dark[0],
		marginBottom: getResponsiveStyle(10),
	},
	linkDate: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	text: {
		fontWeight: '600',
		textAlign: 'center',
	},
	hover: {
		borderWidth: 3,
		borderRadius: 8,
	},
});

export default Link;

const styleContainer = (size: TSize, width: number) => {
	return {
		// paddingHorizontal: getResponsiveStyle(width),
		// paddingVertical: getResponsiveStyle(9),
		width: 'max-content',
		paddingLeft: getResponsiveStyle(25),
		paddingRight: getResponsiveStyle(25),
		height: getResponsiveStyle(70, 'dimensions'),
	};
};
