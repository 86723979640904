import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AuthTemplate, Button } from '../components';
import EventSelect from '../components/create-event/event-select/EventSelect';
import { setTypeOfEvent } from '../redux/createEventSlice';
import { useAppSelector } from '../redux/store';
import {
	setCreateEventSidebarValues,
	setCurrentScreen,
} from '../redux/uiSlice';
import { TUseNavigation } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const EventCreateScreen = () => {
	const navgation = useNavigation<TUseNavigation>();
	const dispatch = useDispatch();
	const typeOfEvent = useAppSelector(state => state.createEvent.typeOfEvent);
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(setCurrentScreen('/create-event'));
	}, []);

	const handleSelectUser = (userType: TTypeOfEvent) => {
		dispatch(setTypeOfEvent(userType));
	};

	const onPressNextButton = () => {
		if (typeOfEvent) {
			dispatch(setCreateEventSidebarValues(typeOfEvent));
			navgation.navigate('EventDetail');
		}
	};

	return (
		<AuthTemplate
			heading={t('Create new event')}
			subText={t('Lets get you started!')}
			scrollItems={[]}
			contentStyles={{ marginTop: getResponsiveStyle(100) }}
		>
			<EventSelect onSubmit={handleSelectUser} />
			<Button
				title={t('Next')}
				variant={typeOfEvent === undefined ? 'disabled' : 'primary'}
				size="lg"
				onPress={onPressNextButton}
				outerContainerProps={{
					style: {
						marginTop: getResponsiveStyle(46),
					},
				}}
			/>
		</AuthTemplate>
	);
};

export default EventCreateScreen;
