import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, Text, StyleSheet, Dimensions } from 'react-native';
import { Button, Spacing } from '../..';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import {
	resetFilters,
	setAllFilters,
	TInitialState,
} from '../../../redux/filterSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import countryNamesWithCodes from '../../../utils/countryNamesWithCodes';
import {
	GENDER_CATEGORIES,
	RATING_CATEGORIES,
	TYPE_OF_ACCOUNT,
	TYPE_OF_EVENT,
} from '../../../utils/filterCategories';
import { filterObject } from '../../../utils/filterObject';
import { findFilterVal } from '../../../utils/findFilterVal';
import ModalComponent from '../modal/ModalComponent';
import ToggleDropdown from './ToggleDropdown';
import ToggleMobile from './toggleFilterMobile';
import ToggleLine from './ToggleLine';
type Props = {
	heading: string;
	filters?: TDropdownObject[];
	sort?: TDropdownObject[];
};
const ToggleButton = ({ heading, filters }: Props) => {
	const [open, setOpen] = useState(false);
	const [filterValue, setFilters] = useState<TInitialState>({});
	const { isDesktop } = useIsSpecificViewportWidth();
	const { t } = useTranslation();
	const language = useAppSelector(state => state.ui.language);
	const { DROPDOWN_CATEGORIES, PILL_CATEGORIES } = useAppSelector(
		state => state.tags
	);
	const FAVOURITE_CATEGORIES: TDropdownObject[] = [
		...PILL_CATEGORIES,
		...DROPDOWN_CATEGORIES,
	].map(category => {
		return {
			label: category[language],
			value: category.value,
		};
	});
	const dispatch = useAppDispatch();
	const primary = usePrimaryColors();
	const filterAppValue = useAppSelector(state => state.filter);
	useEffect(() => {
		setFilters(filterAppValue);
	}, [filterAppValue]);

	const filterValues = (value: string) => {
		if (value.includes('gender')) return GENDER_CATEGORIES;
		if (value.includes('interestTags')) return FAVOURITE_CATEGORIES;
		if (value.includes('tags')) return FAVOURITE_CATEGORIES;
		if (value.includes('rating')) return RATING_CATEGORIES;
		if (value.includes('countryCode')) return countryNamesWithCodes;
		if (value.includes('typeOfAccount')) return TYPE_OF_ACCOUNT;
		if (value.includes('typeOfEvent')) return TYPE_OF_EVENT;
		return [];
	};

	const setFilter = (name: string, value: any): any => {
		let obj: TInitialState = {};
		// console.log('THIS IS A VALUE,', value);
		if (!value) return;
		// console.log('THIS IS A VALUE,', value);
		if (name?.includes('user')) {
			if (name?.includes('add')) {
				const valName = name.split('add')[1];
				let val = {
					personalInformation: {
						...filterValue?.personalInformation,
						address: {
							...filterValue?.personalInformation?.address,
							...filterObject(valName, value),
						},
					},
				};
				obj = { ...obj, ...val };
				return setFilters({ ...filterValue, ...obj });
			} else {
				let val = {
					personalInformation: {
						...(filterValue?.personalInformation ?? {}),
						favouriteCategories: value,
					},
				};
				obj = { ...obj, ...val };
				return setFilters({ ...filterValue, ...obj });
			}
		} else if (name?.includes('brand')) {
			if (name?.includes('add')) {
				const valName = name?.split('add')[1];
				let val = {
					address: {
						...filterValue?.address,
						...filterObject(valName, value),
					},
				};
				obj = { ...obj, ...val };
				return setFilters({ ...filterValue, ...obj });
			}
		} else if (name?.includes('event')) {
			if (name?.includes('add')) {
				const valName = name?.split('add')[1];
				let val = {
					eventLocation: {
						...filterValue?.eventLocation,
						...filterObject(valName, value),
					},
				};
				obj = { ...obj, ...val };
				return setFilters({ ...filterValue, ...obj });
			}
		} else {
			obj = { ...obj, ...filterObject(name, value) };
			return setFilters({ ...filterValue, ...obj });
		}
	};
	const dispatchFunction = () => {
		dispatch(setAllFilters(filterValue));
	};
	const resetAllFilters = () => {
		setFilters({});

		dispatch(resetFilters());
	};
	return (
		<>
			{open &&
				filters &&
				(isDesktop ? (
					<Spacing
						containerStyles={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Spacing>
							<Text
								style={{ color: primary[0] }}
								onPress={dispatchFunction}
							>
								{t('Apply Filter')}
							</Text>
							<Text
								style={{ color: primary[0] }}
								onPress={resetAllFilters}
							>
								{t('Reset')}
							</Text>
						</Spacing>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							{filters?.map((filter, index) => {
								return (
									<ToggleDropdown
										key={index}
										filterObj={filter}
										setFilters={setFilter}
										items={filterValues(filter.value)}
									/>
								);
							})}
						</View>
					</Spacing>
				) : (
					<ModalComponent
						type="sidebar"
						openState={open}
						onClose={() => setOpen(false)}
						header={heading}
						isNotScrollable
					>
						<View style={style.filterContainer}>
							<View style={style.filterScrollView}>
								<ScrollView
									showsHorizontalScrollIndicator={false}
									showsVerticalScrollIndicator={true}
								>
									{filters.map((filter, index) => {
										return (
											<ToggleMobile
												key={index}
												filterObj={filter}
												value={findFilterVal(
													filterValue,
													filter.value
												)}
												setValue={setFilter}
												filterArr={filterValues(
													filter.value
												)}
											/>
										);
									})}
								</ScrollView>
							</View>

							<View style={style.buttonContainer}>
								<Button
									size="lg"
									title={t('Apply Filter')}
									onPress={dispatchFunction}
								/>
								<Text
									style={{ color: primary[0] }}
									onPress={resetAllFilters}
								>
									{t('RESET')}
								</Text>
							</View>
						</View>
					</ModalComponent>
				))}
			<View style={style.container}>
				<ToggleLine
					heading={heading}
					textStyle={{
						...(Object.keys(filterAppValue).length && {
							color: primary[0],
						}),
					}}
					setOpen={setOpen}
					open={open}
				/>
			</View>
		</>
	);
};
const style = StyleSheet.create({
	buttonContainer: {
		position: 'absolute',
		bottom: '80px',
		width: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: 'white',
		zIndex: 9999,
		justifyContent: 'space-between',
	},
	container: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	textContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	filterScrollView: {
		height: '90%',
		paddingBottom: '90px',
		paddingHorizontal: '2px',
		overflow: 'scroll',
	},
	filterContainer: {
		flex: 1,
		justifyContent: 'center',
		height: Dimensions.get('window').height,
	},
});
export default ToggleButton;
