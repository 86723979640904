import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { twoDp } from '../../../../../utils/twoDP';
import { countryAndCurrency } from '../../../../../utils/countryNamesWithCodes';

const ProductPriceInfo = ({
	variant,
	optionValue,
	highestPrice,
	availableVariantCombinationPrice,
	currencyCountry,
}: {
	variant: PopulatedVariant | Variant;
	highestPrice?: number;
	optionValue: string;
	availableVariantCombinationPrice: {
		price: string;
		comparePrice: string;
	};
	currencyCountry?: string;
}) => {
	const { t } = useTranslation();

	const compareAtPrice: number = (variant?.option?.values.find(
		value => value.name === optionValue
	)?.compareAtPrice ||
		availableVariantCombinationPrice?.comparePrice) as number;

	const discountPrice: number = variant?.option?.values.find(
		value => value.name === optionValue
	)?.price as number;

	const findCurrencyAndRate = countryAndCurrency?.find(
		data => data?.country === currencyCountry
	);

	return (
		<View style={styles.container}>
			<View style={styles.price}>
				{compareAtPrice > 0 && (
					<Text style={styles.startingPrice}>
						{getSymbolFromCurrency(
							findCurrencyAndRate?.currency || 'eur'
						)}
						{twoDp(
							(compareAtPrice ?? highestPrice) *
								(findCurrencyAndRate?.rate || 1)
						)}
					</Text>
				)}

				<Text style={styles.discountPrice}>
					{getSymbolFromCurrency(
						findCurrencyAndRate?.currency || 'eur'
					)}
					{twoDp(
						((availableVariantCombinationPrice?.price ||
							discountPrice) as number) *
							(findCurrencyAndRate?.rate || 1)
					)}
				</Text>
			</View>
			<Pressable onPress={() => null}>
				<Text style={styles.labelLink}></Text>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	price: {
		flexDirection: 'row',
	},
	startingPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#7C889B',
		marginRight: 10,
		textDecorationLine: 'line-through',
	},
	discountPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#F96152',
	},
	labelLink: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 14,
		textDecorationLine: 'underline',
		color: '#6645F4',
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 15,
		marginBottom: 20,
	},
});

export default ProductPriceInfo;
