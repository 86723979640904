import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { NotAvailableMessage, SectionHeading } from '../..';
import {
	GET_ALL_USERS,
	GET_USER_TABLE_STATS,
} from '../../../graphql/auth/queries';
import { FOLLOW, UNFOLLOW } from '../../../graphql/follow/mutations';
import { IS_FOLLOWING } from '../../../graphql/network/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import LoadMore from '../../common/load-more/LoadMore';
import { Table } from '../../common/table/Table';
import { ProfileCard } from '../profile-cards/ProfileCard';
import { useAppSelector } from '../../../redux/store';

type Props = {
	heading: string;
	hasFilter?: boolean;
	filterItem?: TDropdownObject[];
	sortItem?: TDropdownObject[];
	page?: string;
	data?: TGetAllUsersData[] | undefined;
	headers?: any[];
	type?: TTableTypes;
	messageButton?: boolean;
	profileButton?: boolean;
	followButton?: boolean;
	setSelected?: (userId: string) => void;

	requestSendBooleanArray?: TIsFollowMeRequestData[];
	unfollowrequestSendBooleanArray?: TIsFollowMeRequestData[];
	onClickHostFollowMeActionButton?: (
		recipientId: string,
		hasRequestBeenSent: boolean,
		index: number
	) => void;
	followMeUnfollowMeButtonPressedId?: string;
	loadingFollowMe?: boolean;
	isFollowingBooleanArray?: TIsFollowingData[];
	eventsAttendedCount?: EventAttendedCount[];
	selectedTab?: string;
};

const ProfileTableControl = ({
	heading,
	data,
	page,
	headers,
	type,
	hasFilter = false,
	setSelected,
	filterItem,
	sortItem,
	messageButton = false,
	profileButton = true,
	followButton = false,
	requestSendBooleanArray,
	unfollowrequestSendBooleanArray,
	onClickHostFollowMeActionButton,
	followMeUnfollowMeButtonPressedId,
	loadingFollowMe,
	isFollowingBooleanArray,
	eventsAttendedCount,
	selectedTab,
}: Props) => {
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const { isMobileView, isLessThanDesktopBase } =
		useIsSpecificViewportWidth();

	const HEADERS = [
		{
			headerLabel: t('User Name'),
			width: '20.73%',
		},
		{
			headerLabel: t('Gender'),
			width: '12.11%',
		},
		{
			headerLabel: t('Followers'),
			width: '9.57%',
		},
		{
			headerLabel: t('City'),
			width: '15.32%',
		},
		{
			headerLabel: t('Country'),
			width: '15.85%',
		},
		{
			headerLabel: t('Events attended'),
			width: '9.85%',
		},
	];

	const userIds: string[] | undefined = useMemo(
		() => data && data?.map(item => item._id),
		[data]
	);

	const refetchIsFollowing = [
		IS_FOLLOWING,
		GET_USER_TABLE_STATS,
		// {
		// 	query: IS_FOLLOWING,
		// 	variables: {
		// 		args: {
		// 			followedIds: userIds,
		// 		},
		// 	} as TIsFollowingInputs,
		// },
		// {
		// 	query:GET_USER_TABLE_STATS,
		// 	variables:{
		// 		id:userIds
		// 	}
		// }
	];

	const [followAccount, { loading: loadingFollowAccount }] = useMutation<
		TFollowResponse,
		TFollowInputs
	>(FOLLOW, {
		awaitRefetchQueries: true,
		refetchQueries: refetchIsFollowing,
		onCompleted: data => {
			if (data.Follow.success && data.Follow.statusCode === 200) {
				setFollowUnfollowButtonPressedId('');
			}
		},
		onError: () => {
			setFollowUnfollowButtonPressedId('');
		},
	});

	const [followUnfollowButtonPressedId, setFollowUnfollowButtonPressedId] =
		useState('');
	const onPressFollowUnfollowButton = async (index: number) => {
		if (!data) {
			setAlert(t('Unable to follow user'), 'danger');
		}

		const user = data?.find((_, i) => i === index);

		if (user === undefined) {
			setAlert(t('Unable to follow user'), 'danger');
		}
		user && setSelected && (await setSelected(user._id));

		setFollowUnfollowButtonPressedId(user?._id ?? '');

		const isFollowingUser = isFollowingBooleanArray?.find(
			item => item.userId === user?._id
		)?.isFollowing;

		const variables = {
			args: {
				followedId: user?._id ?? '',
			},
		};

		isFollowingUser
			? unFollowAccount({
					variables,
			  })
			: followAccount({
					variables,
			  });
	};

	const [unFollowAccount, { loading: loadingUnfollow }] = useMutation<
		TUnfollowResponse,
		TUnfollowInputs
	>(UNFOLLOW, {
		awaitRefetchQueries: true,
		refetchQueries: refetchIsFollowing,
		onCompleted: data => {
			if (data.UnFollow.success && data.UnFollow.statusCode === 200) {
				setFollowUnfollowButtonPressedId('');
			}
		},
		onError: () => {
			setFollowUnfollowButtonPressedId('');
		},
	});

	// const [isFollowingBooleanArray, setIsFollowingBooleanArray] = useState<
	// 	TIsFollowingData[]
	// >([]);

	// const runQuery = async (userIds: string[]) => {
	// 	isFollowingUser({
	// 		variables: {
	// 			args: {
	// 				followedIds: userIds,
	// 				type: 'USER',
	// 			},
	// 		},
	// 	});
	// };

	// useEffect(() => {
	// 	if (!!userIds) {
	// 		runQuery(userIds);
	// 		// getUsersStats({
	// 		// 	variables:{id:userIds}
	// 		// })
	// 	}
	// }, [userIds]);

	// const [isFollowingUser] = useLazyQuery<
	// 	TIsFollowingResponse,
	// 	TIsFollowingInputs
	// >(IS_FOLLOWING, {
	// 	fetchPolicy: 'network-only',
	// 	onCompleted: data => {
	// 		if (data.IsFollowing.success && data.IsFollowing.data) {
	// 			setIsFollowingBooleanArray(data.IsFollowing.data.data);
	// 		}
	// 	},
	// });

	const loadingState = getSingleLoadingState(
		loadingFollowAccount,
		loadingUnfollow
		// userStatLoading
	);

	const hasRequestBeenSentFunc = (item: TGetAllUsersData) => {
		return requestSendBooleanArray?.find(i => i.userId === item._id)
			?.isFollowRequestSent;
	};

	const hasUnfollowRequestBeenSentFunc = (item: TGetAllUsersData) => {
		return unfollowrequestSendBooleanArray?.find(i => i.userId === item._id)
			?.isUnFollowRequestSent;
	};

	const loadingFunc = (item: TGetAllUsersData) => {
		return (
			followMeUnfollowMeButtonPressedId === item._id && loadingFollowMe
		);
	};

	const isFollowingMeArray = useAppSelector(
		state => state.user.isFollowingMe
	);

	const isFollowingMeFunc = (item: TGetAllUsersData) => {
		return isFollowingMeArray?.find(i => i.userId === item._id)
			?.isFollowingMe;
	};

	const eventAttendedCountByUser = (item: TGetAllUsersData) => {
		return eventsAttendedCount?.find(user => user.user === item._id)
			?.eventsAttendedCount;
	};
	return (
		<View style={styleContainer()}>
			<SectionHeading
				heading={`${heading}`}
				size={isMobileView ? 'medium' : 'large'}
				hasFilters={hasFilter}
				sortItem={sortItem}
				filterItem={filterItem}
				containerStyles={styleSectionHeadingContainer()}
			/>
			{isLessThanDesktopBase && page === 'network' ? (
				<View
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'row',
						flexWrap: 'wrap',
					}}
				>
					{data?.map((item: TGetAllUsersData, index) => (
						<View style={{ marginBottom: getResponsiveStyle(10) }}>
							<ProfileCard
								containerStyles={{
									minWidth: getResponsiveStyle(
										350,
										'dimensions'
									),
								}}
								item={item}
								isHost={false}
								hasActionBtn
								hasEventAttendedOnly={selectedTab === 'Guests'}
								hasRating={selectedTab !== 'Guests'}
								// isUserCard
								// isInvited={isInvited}
								showGender
								hasFollowHostRequestSent={hasRequestBeenSentFunc(
									item
								)}
								onClickHostFollowMeActionButton={
									onClickHostFollowMeActionButton
								}
								type={type}
								hasUnfollowRequestBeenSent={hasUnfollowRequestBeenSentFunc(
									item
								)}
								// followMeUnfollowMeButtonPressedId={followMeUnfollowMeButtonPressedId}**
								loadingFollowMe={loadingFunc(item)}
								isFollowingMe={isFollowingMeFunc(item)}
								index={index}
								// isSearch={isSearch}
								hasStats
								onPressFollowButton={
									onPressFollowUnfollowButton
								}
								isFollowingArray={isFollowingBooleanArray}
								loadingFollowUser={loadingState}
								followUnfollowButtonPressedId={
									followUnfollowButtonPressedId
								}
								eventAttendedCountByUser={eventAttendedCountByUser(
									item
								)}
								// isShowViewProfileBtn={isShowViewProfileBtn}
							/>
						</View>
					))}
				</View>
			) : (
				<>
					{data && data.length ? (
						<Table
							content={data}
							headers={headers ? headers : HEADERS}
							messageButton={messageButton}
							profileButton={profileButton}
							followButton={followButton}
							type={type}
							onPressFollowButton={onPressFollowUnfollowButton}
							isFollowingArray={isFollowingBooleanArray}
							loadingFollowUser={loadingState}
							followUnfollowButtonPressedId={
								followUnfollowButtonPressedId
							}
							requestSendBooleanArray={requestSendBooleanArray}
							unfollowrequestSendBooleanArray={
								unfollowrequestSendBooleanArray
							}
							onClickHostFollowMeActionButton={
								onClickHostFollowMeActionButton
							}
							followMeUnfollowMeButtonPressedId={
								followMeUnfollowMeButtonPressedId
							}
							loadingFollowMe={loadingFollowMe}
							eventsAttendedCount={eventsAttendedCount}
						/>
					) : (
						<NotAvailableMessage message={t('No Result Found!')} />
					)}
				</>
			)}
		</View>
	);
};

export default ProfileTableControl;

const styleContainer = () => {
	return {
		marginBottom: getResponsiveStyle(32),
	} as TStyle;
};

const styleSectionHeadingContainer = (): TStyleInputOrText => {
	return {
		marginBottom: getResponsiveStyle(27),
	};
};
