import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
	query getProduct($_id: ID!) {
		getProduct(_id: $_id, populate: ["images"]) {
			statusCode
			data {
				... on Product {
					_id
					name
					shortDescription
					description
					publishedStatus
					createdAt
					category
					defaultVariant
					brand {
						_id
					}

					variants {
						_id
						variator
						value
						GTIN
						weight
						weightUnit

						image {
							src
							_id
						}
						option {
							name
							values {
								compareAtPrice
								price
								name
							}
							inventory {
								quantity
								isTracked
								isAvailable
							}
						}
						vat
					}
				}
			}
		}
	}
`;
