import { useCallback, useEffect, useMemo, useState } from "react";
import { useIsSpecificViewportWidth } from "../useIsSpecificViewportWidth";
import { useTranslation } from "react-i18next";
import { setCurrentScreen, setIsVisitingAddHostScreenFromEventsScreen } from "../../redux/uiSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useHandleUnmount } from "../useHandleUnmount";
import { resetSort } from "../../redux/sortSlice";
import { resetFilters } from "../../redux/filterSlice";
import { useFocusEffect } from "@react-navigation/native";
import { usePagination } from "../useCurrentForPagination";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_EVENTS, GET_ALL_PUBLISHED_EVENTS_FOR_EVENT_SCREEN, GET_ALL_UPCOMING_EVENTS, GET_EVENTS_SELECTED_FOR_YOU, GET_HOST_PUBLISHED_EVENTS_AROUND_YOU, GET_HOST_PUBLISHED_EVENTS_SELECTED_FOR_YOU, GET_PAST_EVENTS, GET_RAW_ALL_EVENTS, GET_RAW_PAST_EVENTS, GET_UPCOMING_EVENTS } from "../../graphql/events/queries";
import { setEventIds } from "../../redux/getEventSlice";
import { IS_SUBSCRIBED_TO_EVENTS } from "../../graphql/eventSubscription/queries";
import { setHostsInvited } from "../../redux/addHostToEventSlice";

const TEN_THOUSAND_METERS = 10000;
const LIMIT = 24;

let totalEventsToRender: number | undefined = 0;


export const useEventScreen = ({
    getQueryFieldForUser,
    sortCheck
}: any) => {
    const dispatch = useAppDispatch();
    const { isDesktop, isLessThanDesktopBase } = useIsSpecificViewportWidth();
    const { t } = useTranslation();


    const [upcomingEvents, setUpcomingEvents] = useState<TGetAllEventsData[]>(
        []
    );

    const [allUpcomingEvents, setAllUpcomingEvents] = useState<
        TGetAllEventsData[]
    >([]);

    const [rawPastEvents, setRawPastEvents] = useState<TGetAllEventsData[]>([]);
    const [rawAllEvents, setRawAllEvents] = useState<TGetAllEventsData[]>([]);

    const [pastEvents, setPastEvents] = useState<TGetAllEventsData[]>([]);
    const [publishedEvents, setPublishedEvents] = useState<TGetAllEventsData[]>(
        []
    );
    const [allEventsData, setAllEventsData] = useState<TGetAllEventsData[]>([]);
    const [allEventsInvite, setAllEventsInvite] = useState<TGetAllEventsData[]>(
        []
    );

    const [publishedEventsSelectedForYou, setPublishedEventsSelectedForYou] =
        useState<TGetAllEventsData[]>([]);

    const [upcomingEventsSelectedForYou, setUpcomingEventsSelectedForYou] =
        useState<TGetAllEventsData[]>([]);

    const [eventsAroundYou, setEventsAroundYou] = useState<TGetAllEventsData[]>(
        []
    );

    const [isSubscribedArray, setIsSubscribedArray] = useState<
        isSubscribedResponse[]
    >([]);

    useEffect(() => {
        dispatch(setCurrentScreen('/event'));
    }, []);
    const generalEventSort = [
        {
            label: `${t('Event Name')}: A-Z`,
            local: false,

            value: { name: 1 },
        },
        {
            label: `${t('Event Name')}: Z-A`,
            local: false,
            value: { name: -1 },
        },

        {
            label: `${t('Start Date')}: ${t('Descending')} `,
            local: false,
            value: { startingEventDateTime: -1 },
        },
        {
            label: `${t('Start Date')}: ${t('Ascending')}`,
            local: false,
            value: { startingEventDateTime: 1 },
        },
        {
            label: `${t('Most Recent Events')}`,
            local: false,
            value: { createdAt: -1 },
        },
        {
            label: `${t('Older Events')} `,
            local: false,
            value: { createdAt: 1 },
        },
    ];
    const eventFilters = [
        { label: t('City'), value: 'eventaddcity' },
        { label: t('Zip Code'), value: 'eventaddzipCode' },
        { label: t('Country'), value: 'eventaddcountryCode' },
        { label: t('Start Date'), value: 'startingEventDateTime' },
        { label: t('Type of Event'), value: 'typeOfEvent' },
    ];
    const { shouldHideScreen } = useHandleUnmount();
    const [filter, sort] = useAppSelector(state => {
        return [state.filter, state.sort];
    });
    useEffect(() => {
        dispatch(resetSort());
        dispatch(resetFilters());
    }, []);
    useFocusEffect(
        useCallback(() => {
            // Do something when the screen is focused

            return () => {
                // Do something when the screen is unfocused
                // Useful for cleanup functions
                dispatch(resetSort());
                dispatch(resetFilters());
            };
        }, [])
    );
    const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
        loggedInUserDetails: state.auth.loggedInUserDetails,
        isUserLoggedIn: state.auth.isUserLoggedIn,
    }));

    const upcomingEventsVariable = useMemo(
        () => ({
            // startingEventDateTime: {
            // 	'$gte': Date.now().toString(),
            // },
            ...getQueryFieldForUser(
                loggedInUserDetails?.typeOfAccount,
                loggedInUserDetails?._id
            ),
        }),
        [loggedInUserDetails?.typeOfAccount, loggedInUserDetails?._id]
    );

    const pastEventsVariable = useMemo(
        () => ({
            startingEventDateTime: { '$lt': Date.now().toString() },
            ...getQueryFieldForUser(
                loggedInUserDetails?.typeOfAccount,
                loggedInUserDetails?._id
            ),
        }),
        [loggedInUserDetails?.typeOfAccount, loggedInUserDetails?._id]
    );

    const onCompleted = (
        type:
            | 'all'
            | 'published'
            | 'past'
            | 'upcoming'
            | 'allUpcoming'
            | 'rawPast'
            | 'rawAll',
        data: TGetAllEventsResponse
    ) => {
        const sellerPublishedEvent = data?.getPublishedEvents?.data?.data;
        const allUpcomingEventsData = data?.getAllUpcomingEvents?.data?.data;
        const rawPastEventsData = data?.getRawAllEvents?.data?.data;
        const rawAllEventsData = data?.getRawAllEvents?.data?.data;

        if (
            data &&
            data.getAllEvents &&
            data.getAllEvents.data &&
            data.getAllEvents.success
        ) {
            const dataToSet = data.getAllEvents.data?.data;

            switch (type) {
                case 'all':
                    setAllEventsInvite(data?.getAllEvents?.data?.data);
                    setAllEventsData([
                        ...allEventsData,
                        ...data.getAllEvents.data?.data,
                    ]);
                    break;
                case 'past':
                    setPastEvents([...pastEvents, ...dataToSet]);
                    break;
                // case 'published':
                // 	setPublishedEvents([...publishedEvents, ...dataToSet]);
                // 	break;
                case 'upcoming':
                    setUpcomingEvents([...upcomingEvents, ...dataToSet]);
                    break;
            }
        } else if (sellerPublishedEvent?.length) {
            setPublishedEvents(sellerPublishedEvent);
        } else if (type === 'allUpcoming') {
            setAllUpcomingEvents(allUpcomingEventsData);
        } else if (type === 'rawPast') {
            setRawPastEvents([...rawPastEvents, ...rawPastEventsData]);
        } else if (type === 'rawAll') {
            setRawAllEvents([...rawAllEvents, ...rawAllEventsData]);
        }
    };

    const [currentPageForAllEvents, increasePageForAllEvents] = usePagination();

    const [currentPageUpcomingEvents, increasePageForUpcomingEvents] =
        usePagination();

    const [currentPageForPublishedEvents, increasePageForPublishedEvents] =
        usePagination();

    const [currentPageForPastEvents, increasePageForPastEvents] =
        usePagination();

    const [
        currentPageForPublishedEventsSelectedForYou,
        increasePageForPublishedEventsSelectedForYou,
    ] = usePagination();

    const [
        currentPageForUpcomingEventsSelectedForYou,
        increasePageForUpcomingEventsSelectedForYou,
    ] = usePagination();

    const [
        currentPageForPublishedEventsAroundYou,
        increasePageForPublishedEventsAroundYou,
    ] = usePagination();

    const [
        currentPageForUpcomingEventsAroundYou,
        increasePageForUpcomingEventsAroundYou,
    ] = usePagination();

    const eventFindParam = useCallback((userType?: TTypeOfAccount) => {
        switch (userType?.toUpperCase()) {
            case 'HOST':
                return {
                    affiliatedHosts: loggedInUserDetails?._id,
                    isHostApplicationAccepted: true,
                };
            case 'SELLER':
                return {
                    affiliatedSeller: loggedInUserDetails?._id,
                };
            case 'CONSUMER':
                return {
                    invitedPeople: loggedInUserDetails?._id,
                };
            default:
                return {};
        }
    }, []);

    const [
        getAllEvents,
        {
            loading: allEventsLoading,
            refetch: refetchGetAllEvents,
            networkStatus: allEventsNetworkStatus,
        },
    ] = useLazyQuery<TGetAllEventsResponse, TPaginatedOptions>(GET_ALL_EVENTS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            onCompleted('all', data);
            totalEventsToRender = data?.getAllEvents?.data?.total;
        },
    });

    const [
        getRawAllEvents,
        {
            loading: rawAllEventsLoading,
            refetch: refetchGetRawAllEvents,
            networkStatus: rawAllEventsNetworkStatus,
        },
    ] = useLazyQuery<TGetAllEventsResponse, TPaginatedOptions>(
        GET_RAW_ALL_EVENTS,
        {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            onCompleted: data => {
                onCompleted('rawAll', data);
                totalEventsToRender = data?.getRawAllEvents?.data?.total;
            },
        }
    );

    const [
        getUpcomingEvents,
        { loading: upcomingEventsLoading, refetch: refetchUpcomingEvents },
    ] = useLazyQuery<TGetAllEventsResponse, Omit<TGetAllEventsInputs, 'sort'>>(
        GET_UPCOMING_EVENTS,
        {
            onCompleted: data => {
                onCompleted('upcoming', data);
            },
        }
    );

    const [
        getAllUpcomingEvents,
        {
            loading: allUpcomingEventsLoading,
            refetch: refetchAllUpcomingEvents,
        },
    ] = useLazyQuery<TGetAllEventsResponse, Omit<TGetAllEventsInputs, 'sort'>>(
        GET_ALL_UPCOMING_EVENTS,
        {
            onCompleted: data => {
                onCompleted('allUpcoming', data);
            },
        }
    );

    const [
        getAllPastEvents,
        { loading: pastEventsLoading, refetch: refetchAllPastEvents },
    ] = useLazyQuery<TGetAllEventsResponse, Omit<TGetAllEventsInputs, 'sort'>>(
        GET_PAST_EVENTS,
        {
            onCompleted: data => {
                onCompleted('past', data);
            },
        }
    );

    const [
        getRawAllPastEvents,
        { loading: rawPastEventsLoading, refetch: refetchRawAllPastEvents },
    ] = useLazyQuery<TGetAllEventsResponse, Omit<TGetAllEventsInputs, 'sort'>>(
        GET_RAW_PAST_EVENTS,
        {
            onCompleted: data => {
                onCompleted('rawPast', data);
            },
        }
    );

    const [
        getAllPublishedEvents,
        { loading: publishedEventsLoading, refetch: refetchPublishedEvents },
    ] = useLazyQuery<TGetAllEventsResponse, TGetAllEventsInputs>(
        GET_ALL_PUBLISHED_EVENTS_FOR_EVENT_SCREEN,
        {
            onCompleted: data => {
                onCompleted('published', data);
            },
        }
    );

    const [
        getPublishedEventsSelectedForYou,
        {
            loading: publishedEventsSelectedForYouLoading,
            refetch: refetchPublishedEventsSelectedForYou,
        },
    ] = useLazyQuery<
        TGetHostPublishedEventsSelectedForYouResponse,
        TGetEventsSelectedForYouInputs
    >(GET_HOST_PUBLISHED_EVENTS_SELECTED_FOR_YOU, {
        onCompleted: data => {
            if (
                data &&
                data.getEventsSelectedForUser &&
                data.getEventsSelectedForUser.data &&
                data.getEventsSelectedForUser.success &&
                data.getEventsSelectedForUser.data.data
            ) {
                setPublishedEventsSelectedForYou([
                    ...publishedEventsSelectedForYou,
                    ...data.getEventsSelectedForUser.data.data,
                ]);
            }
        },
    });

    const [
        getUpcomingEventsSelectedForYou,
        // {
        // 	loading: upcomingEventsSelectedForYouLoading,
        // 	refetch: refetchUpcomingEventsSelectedForYou,
        // },
    ] = useLazyQuery<
        TGetEventsSelectedForYouResponse,
        TGetEventsSelectedForYouInputs
    >(GET_EVENTS_SELECTED_FOR_YOU, {
        onCompleted: data => {
            if (
                data &&
                data.getEventsSelectedForUser &&
                data.getEventsSelectedForUser.data &&
                data.getEventsSelectedForUser.success &&
                data.getEventsSelectedForUser.data.data
            ) {
                setUpcomingEventsSelectedForYou([
                    ...upcomingEventsSelectedForYou,
                    ...data.getEventsSelectedForUser.data.data,
                ]);
            }
        },
    });

    const [
        getPublishedEventsAroundYou,
        {
            loading: eventsAroundYouLoading,
            refetch: refetchPublishedEventsAroundYou,
        },
    ] = useLazyQuery<
        TGetHostPublishedEventsAroundYouResponse,
        TGetEventsAroundYouInputs
    >(GET_HOST_PUBLISHED_EVENTS_AROUND_YOU, {
        onCompleted: data => {
            if (
                data &&
                data.getEventsAroundUser &&
                data.getEventsAroundUser.data &&
                data.getEventsAroundUser.success
            ) {
                setEventsAroundYou([
                    ...eventsAroundYou,
                    ...data.getEventsAroundUser.data.data,
                ]);
            }
        },
    });

    useEffect(() => {
        if (isUserLoggedIn) {
            getUpcomingEvents({
                variables: {
                    find: upcomingEventsVariable,
                    limit: LIMIT,
                    current: currentPageUpcomingEvents,
                    status: 'UPCOMING',
                },
            });
            getRawAllPastEvents({
                variables: {
                    limit: LIMIT,
                    current: currentPageForPastEvents,
                },
            });
            getAllPublishedEvents({
                variables: {
                    find: {
                        isApproved: true,
                        ...getQueryFieldForUser(
                            loggedInUserDetails?.typeOfAccount,
                            loggedInUserDetails?._id
                        ),
                    },
                    limit: LIMIT,
                    current: currentPageForPublishedEvents,
                    status: 'PUBLISHED',
                },
            });
            getAllPastEvents({
                variables: {
                    limit: LIMIT,
                    current: currentPageForPastEvents,
                    filterBy: 'PAST',
                },
            });
        }
        getAllUpcomingEvents({
            variables: {
                limit: LIMIT,
                current: currentPageUpcomingEvents,
            },
        });
        getRawAllEvents({
            variables: {
                limit: LIMIT,
                current: currentPageForPastEvents,
            },
        });
    }, []);
    useEffect(() => {
        setAllEventsData([]);
        getAllEvents({
            variables: {
                limit: LIMIT,
                current: currentPageForAllEvents,
                find: {
                    ...eventFindParam(loggedInUserDetails?.typeOfAccount),
                    ...filter,
                },
                sort: sort?.value
                    ? { ...sortCheck(sort) }
                    : { startingEventDateTime: -1 },
            },
        });
    }, [filter, sort]);

    const runEventsSelectedForYouQueries = (typeOfAccount: TTypeOfAccount) => {
        if (typeOfAccount === 'HOST') {
            getPublishedEventsSelectedForYou({
                variables: {
                    // find: JSON.stringify({
                    // 	statusOfEvent: 'PUBLISHED',
                    // }),
                    status: 'PUBLISHED',
                    distance: TEN_THOUSAND_METERS,
                    limit: LIMIT,
                    current: currentPageForPublishedEventsSelectedForYou,
                },
            });
        }

        if (typeOfAccount === 'CONSUMER') {
            getUpcomingEventsSelectedForYou({
                variables: {
                    // find: JSON.stringify({
                    // 	startingEventDateTime: {
                    // 		'$gt': Date.now().toString(),
                    // 	},
                    // }),
                    status: 'UPCOMING',
                    distance: TEN_THOUSAND_METERS,
                    limit: LIMIT,
                    current: currentPageForUpcomingEventsSelectedForYou,
                },
            });
        }
    };

    const runEventsAroundYouQueries = (typeOfAccount: TTypeOfAccount) => {
        if (typeOfAccount === 'HOST') {
            getPublishedEventsAroundYou({
                variables: {
                    // find: JSON.stringify({
                    // 	statusOfEvent: 'PUBLISHED',
                    // }),
                    status: 'PUBLISHED',
                    distance: TEN_THOUSAND_METERS,
                    current: currentPageForPublishedEventsAroundYou,
                    limit: LIMIT,
                },
            });
        }

        if (typeOfAccount === 'CONSUMER') {
            getPublishedEventsAroundYou({
                variables: {
                    // find: JSON.stringify({
                    // 	startingEventDateTime: {
                    // 		'$gt': Date.now().toString(),
                    // 	},
                    // }),
                    status: 'UPCOMING',
                    distance: TEN_THOUSAND_METERS,
                    limit: LIMIT,
                    current: currentPageForUpcomingEventsAroundYou,
                },
            });
        }
    };

    const [isSubscribedToEvent] = useLazyQuery(IS_SUBSCRIBED_TO_EVENTS, {
        onCompleted: data =>
            setIsSubscribedArray(data?.isSubscribedToEvent?.data?.data),
    });

    useEffect(() => {
        let eventIds: string[] = [];
        eventsAroundYou?.map(event => eventIds.push(event._id));
        upcomingEventsSelectedForYou?.map(event => eventIds.push(event._id));
        publishedEventsSelectedForYou?.map(event => eventIds.push(event._id));
        dispatch(setEventIds(eventIds));
        isSubscribedToEvent({
            variables: {
                eventIds,
            },
        });
    }, [
        eventsAroundYou,
        upcomingEventsSelectedForYou,
        publishedEventsSelectedForYou,
    ]);

    useEffect(() => {
        dispatch(setIsVisitingAddHostScreenFromEventsScreen(false));
        dispatch(setHostsInvited([]));
    }, []);

    useEffect(() => {
        if (
            loggedInUserDetails?.typeOfAccount &&
            (loggedInUserDetails.typeOfAccount === 'HOST' ||
                loggedInUserDetails.typeOfAccount === 'CONSUMER')
        ) {
            runEventsSelectedForYouQueries(loggedInUserDetails?.typeOfAccount);
            runEventsAroundYouQueries(loggedInUserDetails?.typeOfAccount);
        }
    }, []);

    /* -------------------------------------------------------------------------- */
    /*                                Event Details                               */
    /* -------------------------------------------------------------------------- */

    // const [getEventDetails, { loading: loadingGetEventDetails }] = useLazyQuery<
    // 	TGetSingleEventResponse,
    // 	{ _id: string }
    // >(GET_SINGLE_EVENT, {});

    // const [getAllInvitedHosts, { loading: loadingAllInvitedHosts }] =
    // 	useLazyQuery<TGetUsersByIds, TGetUsersByIdsInputs>(
    // 		GET_ALL_USERS_BY_IDS,
    // 		{
    // 			onCompleted: data => {
    // 				if (
    // 					data &&
    // 					data.getUsersByIds &&
    // 					data.getUsersByIds.data &&
    // 					data.getUsersByIds.success
    // 				) {
    // 					dispatch(setHostsInvited(data.getUsersByIds.data.data));
    // 				}
    // 			},
    // 		}
    // 	);

    // const [idOfEventToAddHost, setIdOfEventToAddHost] = useState<string>();
    const onPressAddHostButton = useCallback(async (eventId: string) => {
        // setIdOfEventToAddHost(eventId);
        // dispatch(setIsVisitingAddHostScreenFromEventsScreen(true));
        // dispatch(setIsEditEvent(false));
        // const data = await getEventDetails({
        // 	variables: {
        // 		_id: eventId,
        // 	},
        // });

        // if (data?.data?.getEvent?.data) {
        // 	dispatch(setEventToAddHostsTo(data.data.getEvent.data));
        // 	const hostIds = data.data.getEvent.data.affiliatedHosts?.map(
        // 		host => host._id
        // 	);
        // 	dispatch(setHostsInvitedIds(hostIds));

        // 	if (!!hostIds && hostIds.length > 0) {
        // 		await getAllInvitedHosts({
        // 			variables: {
        // 				_ids: hostIds,
        // 			},
        // 		});
        // 	}
        // 	navigation.navigate('EventHost');
        // 	dispatch(setCurrentEvent(data.data.getEvent.data));
        // }
    }, []);

    // const addHostLoadingState = useMemo(() => {
    // 	return getSingleLoadingState(
    // 		loadingAllInvitedHosts,
    // 		loadingGetEventDetails
    // 	);
    // }, [loadingAllInvitedHosts, loadingGetEventDetails]);

    const onEndReachedForUpcomingEvents = () => {
        const nextPage = increasePageForUpcomingEvents();

        refetchUpcomingEvents({
            find: upcomingEventsVariable,
            limit: LIMIT,
            current: nextPage,
            status: 'UPCOMING',
        });
    };

    const onEndReachedForPublishedEvents = () => {
        const nextPage = increasePageForPublishedEvents();

        refetchPublishedEvents({
            find: {
                isApproved: true,
                ...getQueryFieldForUser(
                    loggedInUserDetails?.typeOfAccount,
                    loggedInUserDetails?._id
                ),
            },
            limit: LIMIT,
            current: nextPage,
            status: 'PUBLISHED',
        });
    };

    const onEndReachedForPublishedEventsSelectedForYou = () => {
        const nextPage = increasePageForPublishedEventsSelectedForYou();

        refetchPublishedEventsSelectedForYou({
            // find: JSON.stringify({
            // 	statusOfEvent: 'PUBLISHED',
            // }),
            limit: LIMIT,
            current: nextPage,
        });
    };

    const onEndReachedForPublishedEventsAroundYou = () => {
        const nextPage = increasePageForPublishedEventsAroundYou();

        refetchPublishedEventsAroundYou({
            // find: JSON.stringify({
            // 	statusOfEvent: 'PUBLISHED',
            // }),
            limit: LIMIT,
            current: nextPage,
            distance: TEN_THOUSAND_METERS,
        });
    };

    // const onEndReachedForUpcomingEventsSelectedForYou = () => {
    // 	const increasedPage = increasePageForUpcomingEventsSelectedForYou();
    // 	refetchUpcomingEventsSelectedForYou({
    // 		find: JSON.stringify({
    // 			startingEventDateTime: {
    // 				'$gt': Date.now().toString(),
    // 			},
    // 		}),
    // 		limit: LIMIT,
    // 		current: increasedPage,
    // 	});
    // };

    // const onEndReachedForUpcomingEventsAroundYou = () => {
    // 	const nextPage = increasePageForUpcomingEventsAroundYou();
    // 	refetchPublishedEventsAroundYou({
    // 		find: JSON.stringify({
    // 			startingEventDateTime: {
    // 				'$gt': Date.now().toString(),
    // 			},
    // 		}),
    // 		distance: TEN_THOUSAND_METERS,
    // 		limit: LIMIT,
    // 		current: nextPage,
    // 	});
    // };

    const onEndReachedPastEvents = () => {
        const nextPage = increasePageForPastEvents();
        refetchAllPastEvents({
            find: pastEventsVariable,
            limit: LIMIT,
            current: nextPage,
            status: 'DONE',
        });
    };

    const onEndReachedForAllEvents = () => {
        const nextPage = increasePageForAllEvents();
        // if (isUserLoggedIn) {
        // 	refetchGetAllEvents({
        // 		limit: LIMIT,
        // 		current: nextPage,
        // 		find: {
        // 			...eventFindParam(loggedInUserDetails?.typeOfAccount),
        // 			...filter,
        // 		},
        // 	});
        // } else {
        refetchGetRawAllEvents({
            limit: LIMIT,
            current: nextPage,
            find: {
                ...filter,
            },
        });
        // }
    };

    const isSellerOrHost =
        isUserLoggedIn && loggedInUserDetails?.typeOfAccount !== 'CONSUMER';

    useEffect(() => {
        const video: any = document.getElementById('banner-video');
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream) {
            video.play();
        }
    }, []);

    return {
        isDesktop,
        isLessThanDesktopBase,
        generalEventSort,
        eventFilters,
        isUserLoggedIn,
        loggedInUserDetails,
        upcomingEvents,
        allUpcomingEvents,
        rawPastEvents,
        rawAllEvents,
        pastEvents,
        publishedEvents,
        allEventsData,
        publishedEventsSelectedForYou,
        upcomingEventsSelectedForYou,
        eventsAroundYou,
        isSubscribedArray,
        isSellerOrHost,
        totalEventsToRender,
        allEventsNetworkStatus,
        allEventsLoading,
        eventsAroundYouLoading,
        rawPastEventsLoading,
        pastEventsLoading,
        rawAllEventsLoading,
        upcomingEventsLoading,
        publishedEventsLoading,
        allUpcomingEventsLoading,
        publishedEventsSelectedForYouLoading,
        onPressAddHostButton,
        onEndReachedForUpcomingEvents,
        onEndReachedForPublishedEvents,
        onEndReachedForPublishedEventsSelectedForYou,
        onEndReachedForPublishedEventsAroundYou,
        onEndReachedPastEvents,
        onEndReachedForAllEvents
    };
};
