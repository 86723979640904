import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';
import { FOLLOW_ME, UNFOLLOW_ME } from '../../../graphql/follow/mutations';
import { IS_FOLLOWING_ME_REQUEST_SENT } from '../../../graphql/follow/queries';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { i18n } from '../../../i18n';
import { useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import { TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Flag from '../../common/flag/Flag';
import { DotIcon } from '../../common/icons';
import { ProfileCard } from '../../network/profile-cards/ProfileCard';
import { useTranslation } from 'react-i18next';
import { placeholderImage } from '../../../utils/productCategories';
import { GET_USERS_EVENTS_ATTENDED_COUNT } from '../../../graphql/eventAttendance/queries';
import { GET_USER_STATS } from '../../../graphql/user/queries';

const DetailSidebar = () => {
	const { setAlert } = useSetAlert();
	const navigation = useNavigation<TUseNavigation>();
	const { t } = useTranslation();

	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const isHost = loggedInUserDetails?.typeOfAccount === 'HOST';

	const { affiliatedSeller, affiliatedHosts, isHostApplicationAccepted } =
		useAppSelector(state => state.getEvent.event);
	const [followRequestSent, setFollowRequestSent] = useState(false);

	const [getIdsForRequestsSent, setGetIdsForRequestsSent] =
		useState<string[]>();

	const [requestSendBooleanArray, setRequestSendBooleanArray] =
		useState<TIsFollowMeRequestData[]>();
	const [sellerStats, setSellerStats] = useState<UserDashboardStats>();

	/******************** START - API - followMe ***********************/
	const refetchRequestSentStatusQuery = [
		{
			query: IS_FOLLOWING_ME_REQUEST_SENT,
			variables: {
				args: {
					ids: getIdsForRequestsSent ?? [],
				},
			} as TIsFollowMeRequestSentInputs,
		},
	];

	const [followMe] = useMutation<TFollowMeResponse, TFollowMeInputs>(
		FOLLOW_ME,
		{
			awaitRefetchQueries: true,
			refetchQueries: refetchRequestSentStatusQuery,
			onCompleted: data => {
				if (data.FollowMe.success && data.FollowMe.statusCode === 200) {
					setAlert(t('Follow request sent!'), 'normal');
					setFollowRequestSent(true);
				} else {
					setAlert(t('Unable to send follow request!'), 'danger');
					setFollowRequestSent(false);
				}
			},
			onError: () => {
				setAlert(t('Unable to send follow request!'), 'danger');
				setFollowRequestSent(false);
			},
		}
	);

	/******************** END  -  API - followMe ***********************/

	/******************** START - API - unFollowMe ***********************/
	const [unFollowMe] = useMutation<TUnfollowMeResponse, TUnfollowMeInputs>(
		UNFOLLOW_ME,
		{
			awaitRefetchQueries: true,
			refetchQueries: refetchRequestSentStatusQuery,
			onCompleted: data => {
				if (
					data.UnFollowMe.success &&
					data.UnFollowMe.statusCode === 200
				) {
					setAlert(t('Unfollow request sent!'), 'normal');
				} else {
					setAlert(t('Unable to send unfollow request!'), 'danger');
				}
			},
			onError: () => {
				setAlert(t('Unable to send unfollow request!'), 'danger');
			},
		}
	);

	/******************** END  -  API - unFollowMe ***********************/

	/******************** START - API - isFollowMeRequestSent ***********************/

	useEffect(() => {
		if (!!affiliatedHosts) {
			const _getIdsForRequestsSent = affiliatedHosts.map(
				item => item._id
			);
			setGetIdsForRequestsSent(_getIdsForRequestsSent);
			getIfRequestHaveBeenSent({
				variables: {
					args: {
						ids: _getIdsForRequestsSent,
					},
				},
			});
		}
		if (!!affiliatedSeller) {
			getUserStats({
				variables: {
					_id: affiliatedSeller._id,
				},
			});
		}
	}, []);

	const [
		getIfRequestHaveBeenSent,
		{ loading: loadingIsFollowingMeRequestSent },
	] = useLazyQuery<
		TIsFollowMeRequestSentResponse,
		TIsFollowMeRequestSentInputs
	>(IS_FOLLOWING_ME_REQUEST_SENT, {
		onCompleted: data => {
			if (
				data.IsFollowMeRequestSent.data &&
				data.IsFollowMeRequestSent.success
			) {
				setRequestSendBooleanArray(
					data.IsFollowMeRequestSent.data.data
				);
			}
		},
		onError: () => {},
	});

	/******************** END  -  API - isFollowMeRequestSent ***********************/

	const onClickFollowMe = (
		recipientId: string,
		hasRequestBeenSent: boolean
	) => {
		const variables = {
			args: {
				recipientId,
			},
		};

		hasRequestBeenSent
			? unFollowMe({
					variables,
			  })
			: followMe({
					variables,
			  });
	};

	const [eventAttendedCount, setEventAttendedCount] = useState([]);

	const [getUserAttendanceCount] = useLazyQuery(
		GET_USERS_EVENTS_ATTENDED_COUNT,
		{
			onCompleted: data =>
				setEventAttendedCount(data?.getUserAttendanceCount?.data?.data),
		}
	);

	useEffect(() => {
		getUserAttendanceCount({
			variables: {
				users: [affiliatedHosts?.[0]?._id],
			},
		});
	}, [affiliatedHosts]);
	const [getUserStats] = useLazyQuery(GET_USER_STATS, {
		onCompleted: data => {
			if (
				data &&
				data?.getUserDashboardStats?.success &&
				data?.getUserDashboardStats?.data
			) {
				setSellerStats(data?.getUserDashboardStats?.data);
			}
		},
	});

	const onPressViewProfile = (id: string) => {};

	return (
		<View style={Styles.container}>
			<View style={Styles.headerContainer}>
				<Image
					source={{
						uri:
							affiliatedSeller?.personalInformation
								?.profileImageLink || placeholderImage,
					}}
					style={Styles.avatar}
				/>

				<View style={Styles.titleContainer}>
					<Text style={[Styles.name, styleName()]}>
						@{affiliatedSeller?.username}
					</Text>
					<>
						<Text style={[Styles.location, styleLocation()]}>
							<Text style={Styles.flexAddressContainer}>
								<Flag
									countryCode={
										affiliatedSeller?.personalInformation
											?.address.countryCode
									}
								/>
								{
									affiliatedSeller?.personalInformation
										?.address?.city
								}
								,{' '}
								{
									affiliatedSeller?.personalInformation
										?.address?.countryLabel
								}
							</Text>
							<DotIcon style={styleDot()} />
							{
								affiliatedSeller?.personalInformation?.address
									?.zipCode
							}
						</Text>
					</>
				</View>
				<View style={Styles.statsContainer}>
					<View style={Styles.statItemContainer}>
						<Text style={Styles.title}>
							{sellerStats?.followersCount || 0}
						</Text>
						<Text style={Styles.subtitle}>{t('Followers')}</Text>
					</View>
					<View style={Styles.statsDivider} />
					<View style={Styles.statItemContainer}>
						<Text style={Styles.title}>
							{sellerStats?.eventsCount || 0}
						</Text>
						<Text style={Styles.subtitle}>{t('Events')}</Text>
					</View>
				</View>
				<View style={Styles.contentContainer}>
					<Text style={Styles.content}>
						{affiliatedSeller?.personalInformation?.description}
					</Text>
				</View>
				{affiliatedSeller?.personalInformation?.description?.length >
					100 && (
					<Text style={[Styles.hoverText, { marginTop: 10 }]}>
						{t('Learn More')}
					</Text>
				)}
				{isUserLoggedIn && (
					<View style={Styles.buttonContainer}>
						{loggedInUserDetails?.typeOfAccount !== 'SELLER' && (
							<>
								<Button width={40} title={t('Message')} />
								<Button
									width={40}
									title={t('View Profile')}
									variant="grey"
									onPress={() =>
										navigation.navigate('UserProfile', {
											userId: affiliatedSeller._id,
										})
									}
								/>
							</>
						)}
						{loggedInUserDetails?.typeOfAccount === 'SELLER' && (
							<Button
								width={80}
								title={t('View Profile')}
								variant="grey"
								onPress={() =>
									navigation.navigate('UserProfile', {
										userId: affiliatedSeller._id,
									})
								}
							/>
						)}
					</View>
				)}
			</View>

			{isHostApplicationAccepted && (
				<>
					<View style={Styles.sectionDivider} />
					<Text style={Styles.hostTitle}>{t('Event Host')}</Text>
				</>
			)}
			<View style={Styles.hostContainer}>
				{isHostApplicationAccepted &&
					affiliatedHosts.map(host => {
						const isFollowRequestSent =
							requestSendBooleanArray?.find(
								item => item.userId === host._id
							)?.isFollowRequestSent;
						return (
							<ProfileCard
								isHost={true}
								showMessageButton={isHost}
								hasActionBtn={!isHost}
								isInvited={true}
								showGender={true}
								item={host}
								hasEventAttendedOnly
								onClickHostFollowMeActionButton={id =>
									onClickFollowMe(
										id,
										isFollowRequestSent ?? false
									)
								}
								hasFollowHostRequestSent={isFollowRequestSent}
								eventAttendedCountByUser={
									eventAttendedCount?.[0]?.eventsAttendedCount
								}
							/>
						);
					})}
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: '#FFFFFF',
	},
	name: {
		fontWeight: '600',
		textAlign: 'center',
		color: palettes.dark[0],
		marginBottom: 1,
	},
	flexAddressContainer: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	dotIcon: {},
	location: {
		color: palettes.dark[4],
		paddingBottom: 11,
		textAlign: 'center',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		width: getResponsiveStyle(80),
		height: getResponsiveStyle(80),
		resizeMode: 'cover',
		borderRadius: getResponsiveStyle(80 / 2),
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 1,
		marginTop: getResponsiveStyle(13),
	},
	title: {
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		color: palettes.dark[0],
	},
	subtitle: {
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: 'normal',
		color: palettes.grey[0],
	},
	statsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		// flex: 1,
		width: getResponsiveStyle(123),
	},
	statItemContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	statsTitle: {
		fontSize: getResponsiveStyle(16, 'font'),
		fontWeight: '600',
		color: palettes.dark[0],
	},
	statsSubtitle: {
		fontSize: getResponsiveStyle(10, 'font'),
		fontWeight: 'normal',
		color: palettes.grey[0],
	},
	statsDivider: {
		height: getResponsiveStyle(34),
		backgroundColor: palettes.grey[5],
		width: 1,
	},
	contentContainer: {
		marginTop: getResponsiveStyle(16),
	},
	content: {
		fontSize: getResponsiveStyle(12, 'font'),
		color: palettes.grey[0],
		fontWeight: 'normal',
		textAlign: 'center',
	},
	hoverText: {
		// marginTop: 20,
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: getResponsiveStyle(29),
		alignItems: 'center',
		gap: getResponsiveStyle(8),
		marginBottom: getResponsiveStyle(32),
	},
	sectionDivider: {
		width: '100%',
		height: 1,
		backgroundColor: palettes.grey[5],
	},
	upcomingContainer: {
		marginTop: getResponsiveStyle(28),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	upcomingText: {
		fontSize: getResponsiveStyle(12, 'font'),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},

	dateContainer: {
		marginTop: getResponsiveStyle(14),
		display: 'flex',
		flexDirection: 'column',
		gap: getResponsiveStyle(16),
		marginBottom: getResponsiveStyle(40),
	},
	dateItemContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		// gap: '16px',
		// flexWrap: 'wrap',
	},
	dateItemTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 1,
	},
	dateItemTextTitle: {
		fontSize: getResponsiveStyle(14, 'font'),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	dateItemTextSubtitle: {
		fontSize: getResponsiveStyle(12, 'font'),
		color: palettes.dark[4],
		fontWeight: 'normal',
	},
	hostContainer: {
		marginTop: getResponsiveStyle(16),
		alignItems: 'center',
	},
	hostTitle: {
		fontSize: getResponsiveStyle(18, 'font'),
		marginTop: getResponsiveStyle(16),
		fontWeight: '600',
		color: palettes.dark[0],
	},
	hostDetailsContainer: {
		display: 'flex',
		flexDirection: 'row',
		// alignItems: 'center',
		gap: getResponsiveStyle(9),
		marginTop: getResponsiveStyle(22),
	},
	hostDetailsAvatarContainer: {
		width: getResponsiveStyle(44),
		height: getResponsiveStyle(44),
		borderRadius: getResponsiveStyle(44 / 2),
		padding: 2,
		borderWidth: 1,
		borderColor: palettes.yellow[0],
	},
	hostDetailsAvatar: {
		width: '100%',
		height: '100%',
		borderRadius: getResponsiveStyle(44 / 2),
	},
	hostDetailTitle: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	hostDetailSubtitle: {
		fontSize: getResponsiveStyle(12, 'font'),
		fontWeight: 'normal',
		color: palettes.grey[0],
	},
	hostDetailRatingContainer: {
		marginTop: getResponsiveStyle(8),
	},
	hostDetailsButtonContainer: {
		marginTop: getResponsiveStyle(24),
		width: getResponsiveStyle(150),
	},
});

const styleName = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginTop: getResponsiveStyle(13),
	};
};

const styleLocation = (): TStyleInputOrText => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		textTransform: 'capitalize',
	};
};

const styleDot = () => {
	return {
		marginBottom: getResponsiveStyle(2),
		marginRight: getResponsiveStyle(3),
	};
};

export default DetailSidebar;
