import { useLazyQuery, useQuery } from '@apollo/client';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Label } from '../../';
import useInviteAllFollowers from '../../../api/events/useInviteAllFollowers';
import { useCreateNewEvent } from '../../../api/events/useCreateNewEvent';
import { palettes } from '../../../config';
import { GET_ALL_USERS } from '../../../graphql/auth/queries';
import { GET_USER_FOLLOWERS } from '../../../graphql/user/queries';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useUpdateEvent } from '../../../hooks/useUpdateEvent';
import {
	removeFromInvitedPeople,
	resetAffiliatedBrands,
	resetAffiliatedHosts,
	resetCreateEventSlice,
	resetEventMedia,
	resetInvitedPeople,
	setaffiliatedBrands,
	setAffiliatedHosts,
	setCatchmentAreas,
	setEventDetails,
	setEventLocation,
	setEventMedia,
	setEventTimeAndDuration,
	setInvitedPeople,
} from '../../../redux/createEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	setIsInviteAllGuest,
	setIsInviteAllGuestUsers,
} from '../../../redux/uiSlice';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { placeholderImage } from '../../../utils/productCategories';
import Dropdown from '../../common/dropdown/Dropdown';
import EventInviteCard from './InviteCard';
import { useUpdateNewEvent } from '../../../api/events/useUpdateNewEvent';
import { tConvert } from '../../../utils/monthsUtils';
import useCreateStatus from '../../../api/status/useCreateStatus';
import { useGetAllUsersLazy } from '../../../api/auth/useGetAllUsersLazy';

const EventInviteForm = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();

	const [disabledInviteAllByTypeButton, setDisableInviteByTypeAllButton] =
		useState(false);
	const [disabledInviteAllButton, setDisableInviteAllButton] =
		useState(false);

	const { isInviteAllGuest, isInviteAllGuestUsers } = useAppSelector(
		state => ({
			isInviteAllGuest: state.ui.isInviteAllGuest,
			isInviteAllGuestUsers: state.ui.isInviteAllGuestUsers,
		})
	);

	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const { isEditEvent, isEditEventFromLink, isAffiliatedHostEditingEvent } =
		useAppSelector(state => state.getEvent);
	const event = useAppSelector(state => state.getEvent.event);

	// const updateEventState = useAppSelector(state => state.createEvent);

	const typeOfEvent = useAppSelector(state => state.createEvent.typeOfEvent);

	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);

	const [dropdownInvitableUserOptions, setDropdownInvitableUserOptions] =
		useState<TDropdownObject[]>([]);

	const [
		dummyDropdownInvitableUserOptions,
		setDummyDropdownInvitableUserOptions,
	] = useState<TDropdownObject[]>([]);

	const [dropdownSelectedUserToInvite, setDropdownSelectedUserToInvite] =
		useState<TDropdownObject>();
	const [usersInvited, setUsersInvited] = useState<TGetAllUsersData[]>([]);

	useEffect(() => {
		dispatch(setIsInviteAllGuestUsers(false));
		dispatch(setIsInviteAllGuest(true));
	}, []);

	useFocusEffect(
		useCallback(() => {
			setUsersInvited([]);
		}, [])
	);

	const { data: allInvitableUsers, loading: loadingGetAllUsers } =
		useQuery<TGetAllUsersResponse>(GET_ALL_USERS, {
			onCompleted: data => {
				if (
					data.getAllUsers &&
					data?.getAllUsers.success &&
					data.getAllUsers.data
				) {
					const _invitableUsers = data.getAllUsers.data.data.map(
						invitableUser => ({
							label: invitableUser.username,
							value: invitableUser._id,
							country:
								invitableUser?.personalInformation?.address
									?.countryLabel,
							countryCode:
								invitableUser?.personalInformation?.address
									?.countryCode,
							city: invitableUser?.personalInformation?.address
								?.city,
							profileImage:
								invitableUser?.personalInformation
									?.profileImageLink,
						})
					);
					setDropdownInvitableUserOptions(_invitableUsers);
					setDummyDropdownInvitableUserOptions(_invitableUsers);
				}
			},
			variables: {
				find: {
					typeOfAccount: 'CONSUMER',
					profileStatus: 'ACTIVE',
				},
			},
		});

	const onInviteUser = () => {
		if (!allInvitableUsers) return;

		const userToInvite = allInvitableUsers.getAllUsers.data?.data.find(
			user => user._id === dropdownSelectedUserToInvite?.value
		);

		if (!userToInvite) return;
		//handle redux state
		dispatch(setInvitedPeople(userToInvite._id));

		//remove selected user from dropdown options
		setDropdownInvitableUserOptions(() => {
			return dropdownInvitableUserOptions.filter(
				user => user.value !== userToInvite._id
			);
		});

		//add selected user object to display data
		setUsersInvited([userToInvite, ...usersInvited]);

		//reset selected dropdown value
		setDropdownSelectedUserToInvite({
			label: '',
			value: '',
		});
	};

	//TODO: this fix is temporary

	useEffect(() => {
		if (isEditEvent && isAffiliatedHostEditingEvent) {
			const date = new Date(+event.startingEventDateTime);
			const startDateGMT = date.toString().split(' ');

			if (event.typeOfEvent === 'PHYSICAL') {
				const {
					address1,
					address2,
					city,
					zipCode,
					countryCode,
					countryLabel,
				} = event.eventLocation;
				const eventLocation = {
					address1: address1,
					address2: address2,
					city: city,
					zipCode: zipCode,
					countryCode: countryCode,
					countryLabel: countryLabel,
				};
				dispatch(
					setEventLocation(eventLocation as TCommonAddressInput)
				);
				dispatch(setCatchmentAreas(event.catchmentAreas));
			}

			dispatch(
				setEventDetails({
					description: event.description,
					name: event.name,
				})
			);
			const { contructedTime, startDate } = tConvert(date, startDateGMT);
			dispatch(setEventTimeAndDuration(event.startingEventDateTime));
			dispatch(resetInvitedPeople([]));
			event?.invitedPeople?.map(userToInvite => {
				+dispatch(setInvitedPeople(userToInvite?._id));
			});
			dispatch(resetAffiliatedBrands([]));
			event.affiliatedBrands.map(affiliatedBrand => {
				dispatch(setaffiliatedBrands(affiliatedBrand._id));
			});
			dispatch(resetAffiliatedHosts([]));
			event.affiliatedHosts.map(selectedHostToInvite => {
				dispatch(setAffiliatedHosts(selectedHostToInvite?._id ?? ''));
			});
			dispatch(resetEventMedia([]));
			event.eventMedia.map(media => dispatch(setEventMedia(media)));
		}
	}, []);

	const onRemoveUser = (id: string) => {
		if (!allInvitableUsers) return;
		const userToRemove = allInvitableUsers.getAllUsers.data?.data.find(
			user => user._id === id
		);

		setDropdownInvitableUserOptions([
			...dropdownInvitableUserOptions,
			{
				label: userToRemove?.username ?? '',
				value: userToRemove?._id ?? '',
				city: userToRemove?.personalInformation?.address?.city,
				country:
					userToRemove?.personalInformation?.address?.countryLabel,
				countryCode:
					userToRemove?.personalInformation?.address?.countryCode,
				profileImage:
					userToRemove?.personalInformation?.profileImageLink,
			},
		]);

		dispatch(removeFromInvitedPeople(id));
		setUsersInvited(prev =>
			prev.filter(person => person._id !== userToRemove?._id)
		);
	};

	const { runUpdateEvent } = useUpdateEvent();
	const navigation = useNavigation<TUseNavigation>();

	useEffect(() => {
		if (isEditEvent) {
			setUsersInvited(event?.invitedPeople as TGetAllUsersData[]);
			setDropdownInvitableUserOptions(() => {
				return dropdownInvitableUserOptions.filter(
					user =>
						!event?.invitedPeople
							?.map(user => user._id)
							?.includes(user.value)
				);
			});
		} else if (isEditEventFromLink) {
			runUpdateEvent();
			setDropdownInvitableUserOptions(() => {
				return dropdownInvitableUserOptions.filter(
					user =>
						!event?.invitedPeople
							?.map(user => user._id)
							?.includes(user.value)
				);
			});
		}
	}, [dummyDropdownInvitableUserOptions]);

	const inviteAllGuestFollowers = ({ inviteAll }: { inviteAll: boolean }) => {
		if (inviteAll) {
			setDisableInviteAllButton(true);
			dispatch(setIsInviteAllGuestUsers(true));
			getAllUsersLazy({
				typeOfAccount: 'CONSUMER',
				profileStatus: 'ACTIVE',
			});
		} else {
			setDisableInviteByTypeAllButton(true);
			dispatch(setIsInviteAllGuest(true));
			executeUserByFollowers({
				variables: { typeOfAccount: 'CONSUMER' },
			});
		}

		setAlert(t('All Guest Followers Invited'), 'normal');
	};

	const { executeInviteAllFollowersMutation } = useInviteAllFollowers();
	const { executeCreateStatusMutation } = useCreateStatus();

	const invitedUsersWithoutDuplicate = usersInvited?.filter(
		(value, index, self) =>
			index === self.findIndex(user => user._id === value._id)
	);

	const invitedUserIdsWithoutDuplicate = invitedUsersWithoutDuplicate.map(
		data => data._id
	);

	const onCompletedCreateNewEvent: TOnCompletedCreateNewEvent = data => {
		if (data.createEvent.success && data.createEvent.data) {
			dispatch(resetCreateEventSlice());
			const eventData = data?.createEvent.data;
			if (isInviteAllGuest) {
				executeInviteAllFollowersMutation({
					eventId: data?.createEvent?.data?._id,
					invitedUsersWithoutDuplicate:
						invitedUserIdsWithoutDuplicate,
					typeOfAccount: 'CONSUMER',
				});
			}
			if (isInviteAllGuestUsers) {
				executeInviteAllFollowersMutation({
					eventId: data?.createEvent?.data?._id,
					invitedUsersWithoutDuplicate:
						invitedUserIdsWithoutDuplicate,
					typeOfAccount: 'CONSUMER',
					isInviteAll: true,
				});
			}
			executeCreateStatusMutation({
				status: eventData.name,
				fileType: 'EVENT',
				isEventPost: true,
				event: eventData._id,
				file: '',
			});
			setAlert(t('Event created successfully!'), 'normal');
			goToNextStepScreen();
		} else {
			setAlert(t('Event created failed!'), 'danger');
		}
	};
	const { createNewEvent, loading } = useCreateNewEvent({
		onCompleted: onCompletedCreateNewEvent,
	});

	// GET FOLLOWERS

	const [executeUserByFollowers] = useLazyQuery(GET_USER_FOLLOWERS, {
		fetchPolicy: 'cache-and-network',
		onCompleted: (data: any) => {
			if (
				data.getFollowers &&
				data.getFollowers.data &&
				data.getFollowers.success
			) {
				setUsersInvited(data?.getFollowers?.data?.data);
			}
		},
	});

	const { getAllUsersLazy } = useGetAllUsersLazy({
		onCompleted: data => {
			setUsersInvited(
				data?.getAllUsers?.data?.data as TGetAllUsersData[]
			);
		},
	});

	const onCompletedUpdateEvent = (data: any) => {
		if (data.editEvent.success && data.editEvent.data) {
			if (isInviteAllGuest) {
				executeInviteAllFollowersMutation({
					eventId: data?.editEvent?.data?._id,
					invitedUsersWithoutDuplicate:
						invitedUserIdsWithoutDuplicate,
					typeOfAccount: 'CONSUMER',
				});
			}
			if (isInviteAllGuestUsers) {
				executeInviteAllFollowersMutation({
					eventId: data?.editEvent?.data?._id,
					invitedUsersWithoutDuplicate:
						invitedUserIdsWithoutDuplicate,
					typeOfAccount: 'CONSUMER',
					isInviteAll: true,
				});
			}
			setAlert(t('Event updated successfully!'), 'normal');
			isAffiliatedHostEditingEvent
				? navigation.navigate('Home')
				: goToNextStepScreen();
		} else {
			setAlert(t('Event update failed!'), 'danger');
		}
	};

	const { updateNewEvent, loading: updateLoading } = useUpdateNewEvent({
		onCompleted: onCompletedUpdateEvent,
	});
	return (
		<View>
			<View
				style={[
					styles.inputButtonContainer,
					styleInputButtonContainer(),
				]}
			>
				<View style={{ flex: 1 }}>
					<Dropdown
						labelProps={{ label: t('Invite') }}
						loading={loadingGetAllUsers}
						options={dropdownInvitableUserOptions}
						values={dropdownSelectedUserToInvite}
						setValue={setDropdownSelectedUserToInvite}
						isSearchable
						fromEventInvite={true}
						showInviteAll
						InviteAllFollowersByType={inviteAllGuestFollowers}
						InviteAllByType={inviteAllGuestFollowers}
						inviteText={t('Invite All Guest Following You')}
						inviteAllText={t('Invite All Guest')}
						typeOfSearch={'CONSUMER'}
						isSearchFromBackend
						disabledInviteAllButton={disabledInviteAllButton}
						disabledInviteAllByTypeButton={
							disabledInviteAllByTypeButton
						}
						inputProps={{ placeholder: 'Search name / location' }}
					/>
				</View>

				<Button
					title={t('Invite')}
					size="lg"
					variant={
						dropdownSelectedUserToInvite &&
						dropdownSelectedUserToInvite.value
							? 'outline'
							: 'disabled'
					}
					onPress={onInviteUser}
				/>
			</View>
			{usersInvited && usersInvited?.length > 0 && (
				<ScrollView
					style={{
						marginTop: getResponsiveStyle(32),
						height: getResponsiveStyle(200),
						paddingLeft: getResponsiveStyle(10),
						paddingRight: getResponsiveStyle(10),
					}}
				>
					<Label {...{ label: t('Invite Send') }} />
					{invitedUsersWithoutDuplicate.map(invitedUser => (
						<EventInviteCard
							key={invitedUser?._id}
							userCountry={
								invitedUser?.personalInformation?.address
									?.countryLabel
							}
							userImage={
								invitedUser?.personalInformation
									?.profileImageLink ?? placeholderImage
							}
							userName={invitedUser?.username}
							id={invitedUser?._id}
							onRemove={onRemoveUser}
							city={
								invitedUser?.personalInformation?.address?.city
							}
							countryCode={
								invitedUser?.personalInformation?.address
									?.countryCode
							}
						/>
					))}
				</ScrollView>
			)}
			<View>
				<Button
					title={t('Next')}
					outerContainerProps={{
						style: { marginTop: getResponsiveStyle(70) },
					}}
					loading={loading || updateLoading}
					size="lg"
					variant={
						(usersInvited && usersInvited.length > 0) ||
						isAffiliatedHostEditingEvent
							? 'primary'
							: 'disabled'
					}
					onPress={() => {
						if (isAffiliatedHostEditingEvent) {
							updateNewEvent();
							return;
						}
						if (typeOfEvent === 'VIRTUAL') {
							isEditEvent
								? updateNewEvent()
								: createNewEvent({
										wereHostsSkipped: true,
										isHostRequired: false,
								  });
						} else {
							goToNextStepScreen();
						}
					}}
				/>
				<Button
					title={t('Skip')}
					outerContainerProps={{
						style: { marginTop: getResponsiveStyle(4) },
					}}
					loading={loading}
					size="lg"
					variant={'outline'}
					onPress={() => {
						if (isAffiliatedHostEditingEvent) {
							dispatch(setIsInviteAllGuest(false));
							navigation.navigate('EventComplete');
							return;
						}
						if (typeOfEvent === 'VIRTUAL') {
							dispatch(setIsInviteAllGuest(false));

							isEditEvent
								? updateNewEvent()
								: createNewEvent({
										wereHostsSkipped: true,
										isHostRequired: false,
								  });
						} else {
							dispatch(setIsInviteAllGuest(false));
							goToNextStepScreen();
						}
					}}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	inputButtonContainer: {
		flexDirection: 'row',
		alignItems: 'flex-end',
		zIndex: 5000,
		flex: 1,
	},
	agreementText: {
		color: palettes.dark[4],
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	},
});

export default EventInviteForm;
function styleInputButtonContainer() {
	return {
		marginBottom: getResponsiveStyle(24),
	};
}
