import { Video } from 'expo-av';
import React, { useLayoutEffect } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../../redux/store';

const UserVideoMessage = ({
	message,
}: {
	message: { message: string };
	usersTyping?: string[];
}) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const userDetail = useAppSelector(state => state.auth.loggedInUserDetails);

	const video = React.useRef(null);

	useLayoutEffect(() => {
		document.querySelector('video') &&
			document
				.querySelector('video')
				?.setAttribute(
					'style',
					`height: ${
						isLessThanDesktopBase ? '100vh' : '75vh'
					}; object-fit: cover;`
				);
	}, [message]);
	return (
		<>
			{/* <ModalComponent
				type="modal"
				header="Image"
				openState={true}
				onClose={() => null}
			>
				<Image
					style={[
						styles.largeImage,
						isLessThanDesktopBase && styles.mobileSizeImage,
					]}
					source={{
						uri: message.message,
					}}
				/>
			</ModalComponent> */}
			<View style={styles.flex1}>
				<Video
					ref={video}
					style={[
						styles.video,
						{
							display: 'flex',
							justifyContent: 'center',
						},
					]}
					source={{
						uri: message.message,
					}}
					useNativeControls
					resizeMode="stretch"
					isLooping
					// shouldPlay={true}
				/>
				<Image
					style={styles.profileImage}
					source={{
						uri: userDetail?.personalInformation?.profileImageLink,
					}}
				/>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flexDirection: 'row',
		marginTop: 15,
		height: 150,
		justifyContent: 'flex-end',
	},
	profileImage: {
		width: 30,
		height: 30,
		borderRadius: 25,
	},
	video: {
		width: '30%',
		height: '100%',
		borderRadius: 6,
		marginBottom: 10,
		marginRight: 20,
		cursor: 'pointer',
	},
	largeImage: {
		height: 400,
		width: '100%',
	},
	detailImage: {
		width: 224,
		height: 204,
	},
	mobileSizeImage: {
		width: 159,
		height: 126,
	},
	mobileSizeDetailImage: {
		width: 324,
		height: 304,
	},
});

export default UserVideoMessage;
