export const getUniquesContacts = (
	combinedContacts: [],
	userId: string | undefined
) => {
	const contactData = combinedContacts?.map(
		(conversationContact: Contact) => {
			return {
				conversationId: conversationContact.id,
				contactPerson: conversationContact.participants.filter(
					(c: any) => c._id !== userId
				)[0] as any,
			};
		}
	);

	return (contactData as ChatContactProps[]) || [];
};

export const updateOneChat = (prevMessage: any, messagesInfo: Message) => {
	const currentChatExist = prevMessage.find(
		(message: Message) => message?.sender === messagesInfo?.sender
	);
	if (currentChatExist) {
		return [...prevMessage, messagesInfo];
	} else {
		return [...prevMessage];
	}
};

export const updateMessagesWithReadReceipt = (prevMessage: any) => {
	const messages = prevMessage || [];
	const updatedMessages = messages.map((msg: {}) => {
		return { ...msg, recipientsReadReceipt: true };
	});
	return updatedMessages;
};

export const updateContactMessageWithReadReceipt = (
	prevContact: Contact[],
	readReceiptInfo: Message
) => {
	const contact = prevContact.find(
		contact => contact.contactPerson._id === readReceiptInfo.sender
	);
	if (contact) contact.recipientsReadReceipt = true;
	return [...prevContact];
};

export const updateWithContact = (
	prevContact: Contact[],
	incomingMessageContact: Contact
) => {
	prevContact = prevContact.filter(
		contact =>
			contact.conversationId !== incomingMessageContact.conversationId
	);
	return [incomingMessageContact, ...prevContact];
};

export const updateUsersTyping = (
	prevUsersTyping: string[],
	typing: boolean,
	sender: string
) => {
	if (typing) {
		prevUsersTyping.push(sender);
	} else {
		const userIndex = prevUsersTyping.indexOf(sender);
		userIndex >= 0 && prevUsersTyping.splice(userIndex, 1);
	}
	return [...prevUsersTyping];
};

export const filterFoundUsers = (
	contactedUsers: [],
	foundUsers: ChatContactProps[],
	userId: string | undefined
) => {
	const contactPerson = getUniquesContacts(contactedUsers, userId);
	const foundUserContactPerson = foundUsers.map(
		contact => contact.contactPerson
	);
	for (let i = 0; i < foundUserContactPerson.length; i++) {
		const existingUserContact = contactPerson.find(
			(contact: any) =>
				contact?.contactPerson?._id === foundUserContactPerson[i]?._id
		);
		if (existingUserContact) {
			foundUsers[i] = existingUserContact as ChatContactProps;
		}
	}
	const currentUserIndex = foundUsers?.findIndex(
		user => user?.contactPerson?._id === userId
	);
	if (currentUserIndex >= 0) foundUsers.splice(currentUserIndex, 1);
	return foundUsers;
};

export const handleSortSelectClick = (
	contactedUsers: [],
	selectedUser: string,
	userId: string | undefined
) => {
	const contactPerson = getUniquesContacts(contactedUsers, userId);
	const foundUserIndex = contactPerson.findIndex(
		(contact: any) => contact.contactPerson._id === selectedUser
	);

	const foundUser = contactPerson[foundUserIndex];
	if (foundUser) {
		contactPerson.splice(foundUserIndex, 1);
		return [foundUser, ...contactPerson];
	} else {
		return null;
	}
};

export const getUnreadMessages = (
	messages: UnreadMessages[],
	conversationId: string,
	isFilterOutRead: boolean
) => {
	const filterOutRead = (message: UnreadMessages) => {
		return isFilterOutRead
			? message.conversationId !== conversationId
			: message.conversationId === conversationId;
	};
	return messages.filter(filterOutRead);
};

export const getLinkFromString = (str: string) => {
	const matchedUrl = str.match(
		/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/
	);
	return matchedUrl;
};

export const makeEven = (number: number) => {
	// Check if the number is odd
	if (Math.floor(number) % 2 !== 0) {
		// If odd, add 1 to make it even
		number = Math.floor(number) + 1;
	} else {
		// If even, round down to the nearest whole number
		number = Math.floor(number);
	}

	return number;
};
