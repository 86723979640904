import { gql } from '@apollo/client';
import {
	GET_ALL_LIVE_EVENTS_FRAGMENT,
	GET_ALL_PHYSICAL_EVENTS_FRAGMENT,
} from '../events/queries';

const GET_FOLLOWING_STATUS = gql`
	query getUserStatus($skipDoc: Int) {
		getUserStatus(skipDoc: $skipDoc) {
			statusCode
			data {
				total
				data {
					... on Status {
						_id
						status
						file
						fileType
						createdAt
						user {
							_id
							username
							personalInformation {
								profileImageLink
								firstName
								lastName
								address {
									city
									countryCode
									countryLabel
								}
							}
						}
						event {
							_id
							name
							description
							isStreamGenerated
							affiliatedSeller {
								... on User {
									_id
									username
									email
									gender
									personalInformation {
										description
										profileImageLink
										address {
											city
											countryCode
											countryLabel
										}
									}
								}
							}
							affiliatedHosts {
								... on User {
									_id
									username
									email
									gender
									personalInformation {
										description
										profileImageLink
										address {
											city
											countryCode
											countryLabel
										}
									}
								}
							}
							affiliatedSeller {
								... on User {
									_id
									username
									email
									gender
									personalInformation {
										description
										profileImageLink
										address {
											city
											countryCode
											countryLabel
										}
									}
								}
							}
							invitedPeople {
								... on User {
									_id
									username
									email
									gender
									personalInformation {
										description
										profileImageLink
										address {
											city
											countryCode
											countryLabel
										}
									}
								}
							}
							affiliatedBrands {
								_id
								profileImage
								address {
									city
									countryCode
								}
							}
							isHostRequired
							typeOfEvent
							isApproved
							createdAt
							startingEventDateTime
							eventMedia
							isSellerApplicationOpen
							isHostApplicationAccepted
							isApproved
							deviceMetadata {
								deviceType
								os
							}
							eventStreamTimeline {
								startedAt
								endedAt
							}
						}
					}
				}
			}
		}
	}
`;

const GET_STATUSES_BY_USER = gql`
	query getStatusesByUser($current: Int, $userId: String) {
		getStatusesByUser(
			current: $current
			userId: $userId
			limit: 10
			populate: [{ path: "user", model: "User" }]
		) {
			statusCode
			success
			message
			data {
				total
				data {
					... on Status {
						_id
						status
						file
						fileType
						createdAt
						user {
							_id
							username
							personalInformation {
								profileImageLink
								firstName
								lastName
								address {
									city
									countryCode
									countryLabel
								}
							}
						}
					}
				}
			}
		}
	}
`;

export { GET_FOLLOWING_STATUS, GET_STATUSES_BY_USER };
