import React from 'react';
import {
	Pressable,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';

import { palettes } from '../../config';
import { usePrimaryColors } from '../../hooks/usePrimaryColors';

const Support = (props: { onSubmit: () => void }) => {
	const primary = usePrimaryColors();
	const onPressHelp = () => {
		props.onSubmit();
	};

	return (
		<>
			<Text style={styles.container}>
				<TouchableOpacity disabled>
					<Text style={styles.text}>Need help? </Text>
				</TouchableOpacity>
				<TouchableOpacity onPress={onPressHelp} activeOpacity={0.75}>
					<Text style={[styles.clickHere, { color: primary?.[0] }]}>
						Click here
					</Text>
				</TouchableOpacity>{' '}
				<TouchableOpacity disabled>
					<Text style={styles.text}>or email us at: </Text>
				</TouchableOpacity>
				<TouchableOpacity disabled>
					<Text style={[styles.email, { color: primary?.[0] }]}>
						support@ocpus.com
					</Text>
				</TouchableOpacity>
			</Text>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		textAlign: 'center',
	},
	clickHere: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 24,
		textDecorationLine: 'underline',
	},
	email: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 24,
	},
	text: {
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: 14,
		lineHeight: 24,
		color: palettes.dark[0],
	},
});

export default Support;
