import React, { useEffect } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../../config';
import { useFonts } from '../../../../hooks/useCutomFonts';

type Props = {
	icon: JSX.Element;
	label: string;
	onPress: () => void;
};

export const FooterIcon = ({ icon, label, onPress }: Props) => {
	useEffect(() => {
		useFonts().then(() => null);
	}, []);
	return (
		<Pressable onPress={onPress} style={styles.container}>
			{icon}
			<Text style={styles.text}>{label}</Text>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: 11,
	},
	text: {
		fontSize: 14,
		lineHeight: 14,
		color: palettes.dark[0],
		// fontFamily: 'maisonNeue',
	},
});
