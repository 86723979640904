import React from 'react';
import EventProduct from '../product-section/event-product/EventProduct';

type Props = {
	products: TGetAllProductsData[];
	eventDetails: TSingleLiveEventData;
};

export const LiveEventProducts = ({ eventDetails, products }: Props) => (
	<EventProduct
		expandHeight={true}
		isMobileView={true}
		products={products}
		eventDetails={eventDetails}
	/>
);
