import React, { useCallback, useEffect, useRef, useState } from 'react';
import AsyncStorage from '@react-native-community/async-storage';
import { useTranslation } from 'react-i18next';
import {
	Image,
	Platform,
	Pressable,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { useDispatch } from 'react-redux';
// import useCreateAppSettings from '../../../../api/appSettings/useCreateAppSettings';
import useDeleteConversation from '../../../../api/chat/useDeleteConversation';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../../hooks/useSetAlerts';
import { setCurrentChat } from '../../../../redux/chatSlice';
import { useAppSelector } from '../../../../redux/store';
import { getUnreadMessages } from '../../../../utils/chatUtil';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import DeleteModal from '../../../common/delete-modal/DeleteModal';
// import { useGetConversation } from '../../../../api/chat';
import Flag from '../../../common/flag/Flag';
import DeleteIcon from '../../../common/icons/DeleteIcon';

const ContactCard = ({ ...contact }: ChatContactProps) => {
	const dispatch = useDispatch();
	const { setAlert } = useSetAlert();
	const { t } = useTranslation();

	const [unreadMessages, setUnreadMessages] = useState<UnreadMessages[]>([]);
	const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
	const [checked, setChecked] = useState<boolean>(false);
	const [reminderChecked, setReminderChecked] = useState('');

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const chat = useAppSelector(state => state.chat.chat);

	const handleIsCheckConversationDelete = async () => {
		await AsyncStorage.setItem('isCheckConversationDelete', 'true');
	};

	const handleContactSelect = () => {
		dispatch(setCurrentChat(contact));
		setUnreadMessages([]);
		contact.onUserSelect(chat.conversationId);
	};

	const ref = useRef(null);

	const {
		executeDeleteConversationMutation,
		loading,
		data: deleteConversation,
	} = useDeleteConversation();

	useEffect(() => {
		if (deleteConversation?.deleteOneConversation?.success) {
			contact.refetch();
			setIsDeleteModal(false);
			setAlert(t('Deleted Successfully'), 'normal');
		}
	}, [deleteConversation]);

	useEffect(() => {
		const getCheckedState = async () => {
			const value = await AsyncStorage.getItem(
				'isCheckConversationDelete'
			);
			setReminderChecked(value as string);
		};
		getCheckedState();
	}, []);

	useEffect(() => {
		const messages = getUnreadMessages(
			contact.allUnreadMessages,
			contact?.conversationId,
			false
		);
		setUnreadMessages(messages);
	}, [contact?.allUnreadMessages]);

	useEffect(() => {
		if (
			chat.conversationId === contact?.conversationId &&
			unreadMessages.length
		) {
			setUnreadMessages([]);
		}
	}, [unreadMessages]);

	const prefixNumber = useCallback(
		(number: number) => {
			return number === 0 ? null : number < 9 ? `0 ${number}` : number;
		},
		[contact?.allUnreadMessages]
	);
	const primaryColors = usePrimaryColors();

	const isHovered = useHover(ref);

	const isSelectedConversation =
		chat?.conversationId === contact?.conversationId;

	const handleConversationDelete = () => {
		executeDeleteConversationMutation({
			conversationId: contact?.conversationId,
		});
		if (checked) handleIsCheckConversationDelete();
	};

	const adminTag =
		contact?.contactPerson?.typeOfAccount === 'ADMIN'
			? 'ADMIN'
			: contact?.contactPerson?.typeOfAccount === 'SUPER_ADMIN'
			? 'SUPER_ADMIN'
			: null;

	return (
		<>
			{isDeleteModal && (
				<View style={{ position: 'absolute' }}>
					<DeleteModal
						header={`${t('Delete Chat')}`}
						info={`${t(
							'Are you sure you want to delete this chat? This action cannot be undone.'
						)}`}
						isOpen={isDeleteModal}
						types={t('Chat')}
						onCancelDelete={() => setIsDeleteModal(false)}
						onConfirmCheck={() => setChecked(!checked)}
						checked={checked}
						loading={loading}
						onConfirmDelete={handleConversationDelete}
					/>
				</View>
			)}
			<Pressable onPress={handleContactSelect}>
				<View
					ref={ref}
					style={[
						styles.userDetail,
						styles.view,
						isHovered && styles.hover,
						isSelectedConversation && styles.hover,
					]}
				>
					<View style={{ flexDirection: 'row' }}>
						{contact?.contactPerson?.personalInformation
							?.profileImageLink ? (
							<Image
								style={styles.userImg}
								source={{
									uri: contact?.contactPerson
										?.personalInformation?.profileImageLink,
								}}
							/>
						) : (
							<Image
								style={styles.userImg}
								source={require('../../../../assets/defaultProfileImage.svg')}
							/>
						)}

						<View style={styles.user}>
							<Text style={styleName()}>
								{contact?.contactPerson?.username?.toLocaleLowerCase()}
								{adminTag && `(${adminTag})`}
							</Text>
							<Text style={{ fontSize: 14 }}>
								<Flag
									countryCode={
										contact?.contactPerson
											?.personalInformation?.address
											?.countryCode
									}
								/>
								<Text
									style={{
										fontSize: getResponsiveStyle(
											14,
											'font'
										),
									}}
								>
									{
										contact?.contactPerson
											?.personalInformation?.address?.city
									}
								</Text>
							</Text>
						</View>
					</View>

					{!isLessThanDesktopBase && (
						<Text
							style={[
								styles.unreadMessages,
								{ color: primaryColors?.[0] },
							]}
						>
							{prefixNumber(unreadMessages.length)}
						</Text>
					)}
					{isHovered && !contact.isModalState && (
						<Pressable
							onPress={() => {
								if (reminderChecked === 'true') {
									handleConversationDelete();
								} else {
									setIsDeleteModal(true);
								}
							}}
						>
							<DeleteIcon height={18} />
						</Pressable>
					)}
				</View>
			</Pressable>
		</>
	);
};

const styles = StyleSheet.create({
	name: {
		fontWeight: '600',
		color: '#101625',
	},
	user: { justifyContent: 'space-evenly' },
	userImg: { height: 50, width: 50, marginRight: 10, borderRadius: 27 },
	userDetail: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		padding: 10,
		cursor: 'pointer',
		justifyContent: 'space-between',
	},
	unreadMessages: {
		alignSelf: 'flex-end',
		fontWeight: '600',
		fontSize: getResponsiveStyle(14),
	},
	view: {
		...Platform.select({
			web: {
				cursor: 'pointer',
				outlineStyle: 'none',
				borderBottomWidth: 1,
				borderBottomColor: 'transparent',
				transitionDuration: '200ms',
			},
			default: {},
		}),
	},
	hover: {
		// opacity: 0.6,
		backgroundColor: '#F7F8F9',
		borderRadius: 6,
	},
});

export default ContactCard;

const styleName = () => {
	return [
		styles.name,
		{
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(10, 'font'),
		},
	];
};
