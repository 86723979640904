import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	stat: number;
	label: string;
	hasBorderRight?: boolean;
	hasCurrencySign?: boolean;
};

export const Stats = ({
	stat,
	label,
	hasBorderRight = false,
	hasCurrencySign = false,
}: Props) => {
	return (
		<View
			style={[
				styles.container,
				hasBorderRight && styles.containerBorderRight,
			]}
		>
			<Text style={styleStat()}>
				{hasCurrencySign ? '$' : ''}
				{stat ? stat : 0}
			</Text>
			<Text style={styleLabel()}>{label}</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		width: '50%',
	},
	containerBorderRight: {
		borderRightWidth: 1,
		borderRightColor: palettes.grey[5],
	},
	stat: {
		fontWeight: '600',
		color: palettes.dark[0],
		marginBottom: 6,
	},
	label: {
		textTransform: 'uppercase',

		color: palettes.grey[0],
	},
});

const styleStat = () => {
	return [
		styles.stat,
		{
			fontSize: getResponsiveStyle(24, 'font'),
			lineHeight: getResponsiveStyle(21, 'font'),
		},
	];
};

const styleLabel = () => {
	return [
		styles.label,
		{
			fontSize: getResponsiveStyle(10, 'font'),
			lineHeight: getResponsiveStyle(12, 'font'),
		},
	];
};
