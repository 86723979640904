import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SectionHeading } from '..';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { PencilWritingIcon } from '../common/icons';
import { useTranslation } from 'react-i18next';
const ShareAnUpdate = () => {
	const { t } = useTranslation();
	return (
		<View style={[styles.container, styleContainer()]}>
			<PencilWritingIcon style={styleIcon()} />
			<SectionHeading
				heading={t('Share an update')}
				containerStyles={styles.text}
				stylesObject={{
					headingStyles: {
						fontWeight: '600',
					},
				}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		maxWidth: 'max-content',
	},
	text: {
		marginBottom: 0,
	},
});

export default ShareAnUpdate;

const styleIcon = () => {
	return {
		width: getResponsiveStyle(18, 'dimensions'),
		height: getResponsiveStyle(18, 'dimensions'),
		marginRight: getResponsiveStyle(10),
	};
};

const styleContainer = () => {
	return {
		paddingBottom: 12,
		marginBottom: getResponsiveStyle(16),
	};
};
