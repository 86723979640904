import { NetworkStatus } from '@apollo/client';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
	AllEventsSection,
	Loader,
	PageHeading,
	ScrollEventSection,
	SectionHeading,
} from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { sortCheck } from '../utils/handleSort';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { View } from 'react-native';
import { useEventScreen } from '../hooks/handleEvent/useEventScreen';

type TUserType = 'seller' | 'host' | 'guest' | 'brand';

const USER_TYPE: TUserType = 'brand';

export const getQueryFieldForUser = (
	typeOfAccount?: TTypeOfAccount,
	userId?: string
) => {
	switch (typeOfAccount) {
		case 'CONSUMER':
			return { invitedPeople: userId };
		case 'HOST':
			return { affiliatedHosts: userId };
		case 'SELLER':
			return {
				affiliatedSeller: userId,
			};
		default:
			return {};
	}
};

const Events = () => {
	const { t } = useTranslation();

	const { shouldHideScreen } = useHandleUnmount();

	const {
		isDesktop,
		isLessThanDesktopBase,
		generalEventSort,
		eventFilters,
		isUserLoggedIn,
		loggedInUserDetails,
		allEventsData,
		allUpcomingEvents,
		eventsAroundYou,
		isSellerOrHost,
		isSubscribedArray,
		pastEvents,
		publishedEvents,
		publishedEventsSelectedForYou,
		rawAllEvents,
		rawPastEvents,
		upcomingEvents,
		allEventsNetworkStatus,
		allEventsLoading,
		rawPastEventsLoading,
		eventsAroundYouLoading,
		allUpcomingEventsLoading,
		pastEventsLoading,
		publishedEventsLoading,
		rawAllEventsLoading,
		upcomingEventsLoading,
		publishedEventsSelectedForYouLoading,
		totalEventsToRender,
		onEndReachedForAllEvents,
		onEndReachedForPublishedEvents,
		onEndReachedForPublishedEventsAroundYou,
		onEndReachedForPublishedEventsSelectedForYou,
		onEndReachedForUpcomingEvents,
		onEndReachedPastEvents,
		onPressAddHostButton
	} = useEventScreen({
		getQueryFieldForUser,
		sortCheck
	})


	return shouldHideScreen ? null : (
		<DesktopTemplate hasNoPaddingLeftAndRight={isLessThanDesktopBase}>
			{isLessThanDesktopBase ? (
				<View
					style={{
						width: '100%',
						marginTop: getResponsiveStyle(-20),
						marginBottom: '1rem',
						shadowOffset: {
							width: 0,
							height: 15,
						},
						shadowRadius: 25,
						shadowColor: '#0000001f',
						shadowOpacity: 1,
					}}
				>
					<video
						src={require('../assets/videoadmobile.mp4')}
						style={{ width: '100%' }}
						autoPlay
						loop
						muted
						id="banner-video"
					/>
				</View>
			) : (
				<View
					style={{
						shadowOffset: {
							width: 0,
							height: 15,
						},
						shadowRadius: 25,
						shadowColor: '#0000001f',
						shadowOpacity: 1,
						marginTop: getResponsiveStyle(-20),
						marginLeft: getResponsiveStyle(-40),
						marginRight: getResponsiveStyle(-40),
						marginBottom: '1rem',
					}}
				>
					<video
						style={{
							// width: '100%',
							height: (isDesktop ? '70vh' : null) as any,
						}}
						autoPlay
						loop
						muted
						src="https://ocpus-punch-dev.s3.eu-west-3.amazonaws.com/videos/a1904bf1-0e6e-45c3-8db7-4687be9a096a.mp4"
					/>
				</View>
			)}

			<View
				style={{
					paddingRight: isLessThanDesktopBase && 16,
					paddingLeft: isLessThanDesktopBase && 16,
				} as any}
			>
				{isDesktop && isSellerOrHost && (
					<PageHeading
						heading={t('Events Overview')}
						alignment="left"
					/>
				)}
				<>
					<ScrollEventSection
						heading={t('Choose your Live Shopping')}
						// hasViewAllLabel={
						// 	allUpcomingEvents && allUpcomingEvents.length > 3
						// }
						events={allUpcomingEvents}
						loading={allUpcomingEventsLoading}
						// onEndReached={onEndReachedForUpcomingEvents}
						type="time"
					/>
					{isUserLoggedIn &&
						loggedInUserDetails?.typeOfAccount !== 'CONSUMER' && (
							<ScrollEventSection
								heading={t('Your Upcoming Events')}
								hasViewAllLabel={
									upcomingEvents && upcomingEvents.length > 3
								}
								events={upcomingEvents}
								loading={upcomingEventsLoading}
								onEndReached={onEndReachedForUpcomingEvents}
								type="time"
							/>
						)}
					{loggedInUserDetails?.typeOfAccount === 'SELLER' &&
						isUserLoggedIn && (
							<ScrollEventSection
								heading={t('Your Published Events')}
								hasViewAllLabel={
									publishedEvents &&
									publishedEvents.length > 3
								}
								events={publishedEvents}
								loading={publishedEventsLoading}
								type="published"
								onEndReached={onEndReachedForPublishedEvents}
								addButtonProps={{
									onPressAddHostButton,
									// loading: addHostLoadingState,
									// idOfEvent: idOfEventToAddHost ?? '',
								} as any}
							/>
						)}

					{loggedInUserDetails?.typeOfAccount === 'HOST' &&
						isUserLoggedIn && (
							<ScrollEventSection
								heading={t('Published Events Selected For You')}
								hasViewAllLabel={
									publishedEventsSelectedForYou &&
									publishedEventsSelectedForYou.length > 3
								}
								events={publishedEventsSelectedForYou}
								loading={publishedEventsSelectedForYouLoading}
								type="published"
								isSubscribedArray={isSubscribedArray}
								onEndReached={
									onEndReachedForPublishedEventsSelectedForYou
								}
							/>
						)}

					{loggedInUserDetails?.typeOfAccount === 'HOST' &&
						isUserLoggedIn && (
							<ScrollEventSection
								heading={t('Published Events Around You')}
								hasViewAllLabel={
									eventsAroundYou &&
									eventsAroundYou.length > 3
								}
								events={eventsAroundYou}
								loading={eventsAroundYouLoading}
								type="published"
								isSubscribedArray={isSubscribedArray}
								onEndReached={
									onEndReachedForPublishedEventsAroundYou
								}
							/>
						)}

					{/* {loggedInUserDetails?.typeOfAccount === 'CONSUMER' &&
					isUserLoggedIn && (
						<ScrollEventSection
							heading={t('Upcoming Events Selected For You')}
							hasViewAllLabel={
								upcomingEventsSelectedForYou &&
								upcomingEventsSelectedForYou.length > 3
							}
							events={upcomingEventsSelectedForYou}
							loading={upcomingEventsSelectedForYouLoading}
							type="published"
							isSubscribedArray={isSubscribedArray}
							onEndReached={
								onEndReachedForUpcomingEventsSelectedForYou
							}
						/>
					)} */}

					{/* {loggedInUserDetails?.typeOfAccount === 'CONSUMER' &&
					isUserLoggedIn && (
						<ScrollEventSection
							heading={t('Upcoming Events Around You')}
							hasViewAllLabel={
								eventsAroundYou && eventsAroundYou.length > 3
							}
							events={eventsAroundYou}
							loading={eventsAroundYouLoading}
							type="published"
							isSubscribedArray={isSubscribedArray}
							onEndReached={
								onEndReachedForUpcomingEventsAroundYou
							}
						/>
					)} */}

					{getUserBasedEventCategories(USER_TYPE, t)}

					{loggedInUserDetails?.typeOfAccount &&
						loggedInUserDetails?.typeOfAccount !== 'CONSUMER' && (
							<ScrollEventSection
								heading={t('Past Events')}
								hasViewAllLabel={
									pastEvents && pastEvents.length > 3
								}
								events={
									isUserLoggedIn ? pastEvents : rawPastEvents
								}
								loading={
									isUserLoggedIn
										? pastEventsLoading
										: rawPastEventsLoading
								}
								onEndReached={onEndReachedPastEvents}
								type="time"
							/>
						)}
					{allEventsLoading &&
						allEventsData &&
						!allEventsData.length ? (
						<Loader />
					) : (
						<>
							<SectionHeading
								heading={t('All our Live Shopping')}
								hasFilters
								filterItem={eventFilters}
								sortItem={generalEventSort as any}
							/>

							<AllEventsSection
								eventsToRender={rawAllEvents}
								loading={
									rawAllEvents.length
										? false
										: rawAllEventsLoading
								}
								refetchLoading={
									allEventsNetworkStatus ===
									NetworkStatus.setVariables ||
									rawAllEventsLoading
								}
								onPressViewMore={onEndReachedForAllEvents}
								totalEventsToRender={totalEventsToRender}
								addButtonProps={{
									onPressAddHostButton,
									// loading: addHostLoadingState,
									// idOfEvent: idOfEventToAddHost ?? '',
								} as any}
							/>
						</>
					)}
				</>
			</View>
		</DesktopTemplate>
	);
};

const getUserBasedEventCategories = (
	userType: TUserType,
	t: TFunction<'en', undefined>
) => {
	switch (userType) {
		case 'seller':
			return (
				<>
					<ScrollEventSection
						heading={t('Your Published Events')}
						hasViewAllLabel
					/>
				</>
			);
		case 'host':
			return (
				<>
					<ScrollEventSection
						heading={t('Published Events Selected For You')}
						hasViewAllLabel
					/>
					<ScrollEventSection
						heading={t('Published Events Around You')}
						hasViewAllLabel
					/>
				</>
			);
		case 'guest':
			return (
				<>
					<ScrollEventSection
						heading={t('Upcoming Events Selected For You')}
						hasViewAllLabel
					/>
					<ScrollEventSection
						heading={t('Upcoming Events Around You')}
						hasViewAllLabel
					/>
				</>
			);
		case 'brand':
			return <></>;
	}
};

export default Events;
