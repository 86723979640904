export enum TypeOfNotification {
	EVENT_INVITATION_NOTIFICATION = 'EVENT_INVITATION_NOTIFICATION',
	EVENT_HOSTING_NOTIFICATION = 'EVENT_HOSTING_NOTIFICATION',
	EVENT_SUBSCRIPTION_NOTIFICATION = 'EVENT_SUBSCRIPTION_NOTIFICATION',
	FOLLOW_NOTIFICATION = 'FOLLOW_NOTIFICATION',
	EVENT_HOSTING_NOTIFICATION_RESPONSE = 'EVENT_HOSTING_NOTIFICATION_RESPONSE',
	ORDER_PURCHASE_NOTIFICATION = 'ORDER_PURCHASE_NOTIFICATION',
	STATUS_NOTIFICATION = 'STATUS_NOTIFICATION',
	EVENT_ABOUT_TO_START_NOTIFICATION = 'EVENT_ABOUT_TO_START_NOTIFICATION',
	EVENT_ABOUT_TO_END_NOTIFICATION = 'EVENT_ABOUT_TO_END_NOTIFICATION',
	ADMIN_NOTIFICATION = 'ADMIN_NOTIFICATION',
	APPROVED_OR_REJECTED_USER_NOTIFICATION = 'APPROVED_OR_REJECTED_USER_NOTIFICATION',
	EVENT_APPROVED_OR_REJECTED_NOTIFICATION = 'EVENT_APPROVED_OR_REJECTED_NOTIFICATION',
	CONSUMER_EVENT_SUBSCRIPTION_NOTIFICATION = 'CONSUMER_EVENT_SUBSCRIPTION_NOTIFICATION',
	EVENT_SUBSCRIPTION_NOTIFICATION_ACCEPTED = 'EVENT_SUBSCRIPTION_NOTIFICATION_ACCEPTED',
	EVENT_UNSUBSCRIPTION_NOTIFICATION = 'EVENT_UNSUBSCRIPTION_NOTIFICATION',
	EVENT_LOCATION_UPDATE_NOTIFICATION = 'EVENT_LOCATION_UPDATE_NOTIFICATION',
	WITHDRAW_EVENT_HOSTING_NOTIFICATION = 'WITHDRAW_EVENT_HOSTING_NOTIFICATION',
	EVENT_INVITATION_NOTIFICATION_RESPONSE = 'EVENT_INVITATION_NOTIFICATION_RESPONSE',
	HOST_NOTIFICATION_TO_INVITE_GUEST = 'HOST_NOTIFICATION_TO_INVITE_GUEST',
	EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION = 'EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION',
	EVENT_MODIFIED_NOTIFICATION = 'EVENT_MODIFIED_NOTIFICATION',
	NEW_PHYSICAL_EVENT_PUBLISHED = 'NEW_PHYSICAL_EVENT_PUBLISHED',
	NEW_EVENT_WITHOUT_HOST = 'NEW_EVENT_WITHOUT_HOST',
}

export enum TNotificationMessage {
	EVENT_SUBSCRIPTION_NOTIFICATION_HOST = 'host',
	EVENT_SUBSCRIPTION_NOTIFICATION_UNHOST = 'unhost',
	EVENT_SUBSCRIPTION_NOTIFICATION_UNJOIN = 'unjoin',
	EVENT_SUBSCRIPTION_NOTIFICATION_JOIN = 'join',
	FOLLOW_ME_NOTIFICATION = 'followme request',
	FOLLOW_NOTIFICATION = 'followed you',
	UNFOLLOW_NOTIFICATION = 'unfollowed you',
	REMOVE_NOTIFICATION = 'removed you',
	ACCEPTED_INVITATION = 'ACCEPTED',
	REJECTED_INVITATION = 'REJECTED',

	EVENT_HOSTING_NOTIFICATION_ACCEPTED = 'accepted',
	EVENT_HOSTING_NOTIFICATION_REJECTED = 'rejected',
	EVENT_HOSTING_NOTIFICATION_ALREADY_EXIST = 'Host already exists',
	EVENT_LOCATION_UPDATE_NOTIFICATION = 'event_location_update',
	WITHDRAW_EVENT_HOSTING_NOTIFICATION = 'withdraw-invitation',
	WITHDRAW_EVENT_HOSTING_NOTIFICATION_GUEST = 'withdraw-invitation-guest',
	EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION_ACCEPTED = 'ACCEPTED',
	EVENT_SUBSCRIPTION_RESPONSE_NOTIFICATION_REJECTED = 'REJECTED',
}
