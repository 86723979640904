import { gql } from '@apollo/client';
import { USER_RESPONSE_FRAGMENT } from '../auth/fragments';
import { ADDRESS_BOOK_FRAGMENT } from './fragments';

export const GET_USER_DETAILS = gql`
	query getUser {
		getUser {
			data {
				... on User {
					username
					email
					website
					typeOfAccount
					profileStatus
					personalInformation {
						profileImageLink
						firstName
						lastName
						address {
							city
							countryCode
							countryLabel
							region
						}
						phoneNumber
						interestTags
						description
					}
				}
			}
		}
	}
`;

export const GET_USER_BY_ID = gql`
	${USER_RESPONSE_FRAGMENT}
	query getUserById($_id: ID!) {
		getUserById(_id: $_id) {
			...UserResponseFragment
		}
	}
`;

export const GET_ALL_ADDRESS_BOOKS = gql`
	${ADDRESS_BOOK_FRAGMENT}
	query getAllAddressBooks {
		getAllAddressBooks {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...AddressBookFragment
						}
					}
				}
			}
		}
	}
`;
