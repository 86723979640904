import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const EventPlaceholderIcon = (props: SvgProps) => (
	<Svg fill="none" viewBox="0 0 49 44" {...props}>
		<Path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25.545 0a23.214 23.214 0 0 0-13.953 4.652l-2.07-2.76a23.313 23.313 0 1 0 27.958 37.31l2.068 2.763A23.313 23.313 0 0 0 25.545 0Zm-13.29 15.564a12.296 12.296 0 0 1 3.86-4.847l2.076 2.772a12.186 12.186 0 0 1 7.354-2.476 12.46 12.46 0 0 1 5.435 1.238 12.296 12.296 0 0 1 1.957 20.874l-2.059-2.745a12.164 12.164 0 0 1-7.354 2.476 12.456 12.456 0 0 1-5.439-1.256 12.298 12.298 0 0 1-5.83-16.036Zm8.762 4.227h7.032a1.408 1.408 0 0 1 1.409 1.409v1.458a1.408 1.408 0 0 1-1.409 1.408h-7.032a1.408 1.408 0 0 1-1.408-1.408V21.2a1.408 1.408 0 0 1 1.408-1.409Z"
			fill="#C1CDDF"
		/>
	</Svg>
);

export default EventPlaceholderIcon;
