export const shouldAddPlaceholderToEvents = (
	eventsArrayLength: number,
	columns: number
) => {
	const remainder = eventsArrayLength % columns;
	return {
		shouldAddPlaceholder: remainder === 0 ? false : true,
		/**
		 * Eg. 24 % 5 = 4;
		 * empty space for placeholder is
		 * 5(columns) - 4 (remainder) = 1.
		 * By adding 1 more placeholder,
		 * the grid will be complete
		 */
		placeholdersToAdd: columns - remainder,
	};
};

export type TCalculateAllEventCardColumns = {
	columns: number;
};

export const calculateColumnsForEvents = (width: number): number => {
	// keeping 440 as optimum width (given in figma)
	const eventCardWidth = 440;
	const pagePaddings = 40;
	const horizontalPagePaddings = pagePaddings * 2;

	const availableSpaceForEventsWithoutConsideringGap =
		width - horizontalPagePaddings;

	// checking how many columns are needed for 440px width cards
	const possibleColumns = Math.floor(
		availableSpaceForEventsWithoutConsideringGap / eventCardWidth
	);

	return possibleColumns;
};
