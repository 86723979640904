import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TInitialYourInformationValues } from '../components/auth/your-information/YourInformationForm';

const initialState: TMutationCreateUserArgs = {
	args: {
		email: '',
		username: '',
		password: '',
		gender: 'OTHER',
		eventType: 'PRIVATE',
		businessAgreementTandC: false,
		companyRegistrationNumber: '',
		companyLegalName: '',
		signedDocuments: [],
		numericSignature: '',
		personalInformation: {
			favouriteCategories: [],
			interestTags: [],
			address: {
				address1: '',
				city: '',
				countryCode: '',
				countryLabel: '',
				zipCode: '',
				address2: '',
			},
			description: '',
			firstName: '',
			lastName: '',
			phoneNumber: '',
			profileImageLink: '',
		},
		CITI_APE_NAF: '',
		typeOfAccount: '',
		vat: 0,

		website: '',
	},
};

const createUserSlice = createSlice({
	name: 'createUser',
	// name: 'auth',
	initialState,
	reducers: {
		setTypeOfAccount(state, action: PayloadAction<TTypeOfAccount>) {
			state.args.typeOfAccount = action.payload;
		},
		setPersonalInformation(
			_state,
			action: PayloadAction<{
				username: string;
				email: string;
				password: string;
			}>
		) {
			const { email, password, username } = action.payload;
			const state = _state.args;
			state.email = email;
			state.password = password;
			state.username = username;
		},
		setSellerAgreement(
			_state,
			action: PayloadAction<Omit<TSellerAgreementInputs, 'CGUCGVLink'>>
		) {
			const {
				companyRegistrationNumber,
				businessLegalName,
				vat,
				CITI_APE_NAF,
			} = action.payload;
			const state = _state.args;
			state.companyRegistrationNumber = companyRegistrationNumber;
			state.vat = vat ? +vat : 0;
			state.companyLegalName = businessLegalName;
			state.businessAgreementTandC = true;
			state.CITI_APE_NAF = CITI_APE_NAF;
		},
		setCGUCGVLink(state, action: PayloadAction<string>) {
			state.args.signedDocuments = [
				...state.args.signedDocuments,
				action.payload,
			];
		},
		setYourInformation(
			_state,
			action: PayloadAction<TInitialYourInformationValues>
		) {
			const {
				address1,
				address2,
				city,
				countryCode,
				countryLabel,
				description,
				eventType,
				favouriteCategories,
				firstName,
				gender,
				interestTags,
				lastName,
				numericSignature,
				phoneNumber,
				profileImageLink,
				website,
				zipCode,
			} = action.payload;
			const state = _state.args;

			const _favouriteCategories = favouriteCategories.map(
				categories => categories.value
			);
			state.personalInformation = {
				firstName,
				lastName,
				description,
				favouriteCategories: _favouriteCategories,
				interestTags,
				phoneNumber,
				profileImageLink,
			};
			state.personalInformation.address = {
				address1,
				city,
				zipCode,
				address2,
				countryCode,
				countryLabel,
			};
			state.website = website;
			state.gender = gender;
			state.numericSignature = numericSignature;
			state.eventType = eventType;
		},
		setBankAccountDetails(
			state,
			action: PayloadAction<TCreditCardInformation>
		) {
			state.args.creditCardInformation = action.payload;
		},
		// setSettingCatchmentAreaLocation(
		// 	state,
		// 	action: PayloadAction<Omit<TSettingCatchmentArea, 'radius'>>
		// ) {
		// 	state.args.settingCatchmentArea = {
		// 		lat: action.payload.lat,
		// 		lng: action.payload.lng,
		// 		radius: state.args.settingCatchmentArea?.radius ?? 0,
		// 	};
		// },
		// setSettingCatchmentAreaRadius(
		// 	state,
		// 	action: PayloadAction<Pick<TSettingCatchmentArea, 'radius'>>
		// ) {
		// 	state.args.settingCatchmentArea = {
		// 		lat: state.args.settingCatchmentArea?.lat ?? 0,
		// 		lng: state.args.settingCatchmentArea?.lng ?? 0,
		// 		radius: action.payload.radius,
		// 	};
		// },
		setProfileImage(state, action: PayloadAction<string>) {
			state.args.personalInformation.profileImageLink = action.payload;
		},
		setGoogleLoginData(
			state,
			action: PayloadAction<Omit<TGoogleLoginData, 'googleId' | 'name'>>
		) {
			const { email, username, familyName, givenName, imageUrl } =
				action.payload;
			state.args.username = username;
			state.args.email = email;
			state.args.personalInformation.firstName = givenName;
			state.args.personalInformation.lastName = familyName;
			state.args.personalInformation.profileImageLink = imageUrl;
		},
	},
});

export default createUserSlice.reducer;
export const {
	setPersonalInformation,
	setTypeOfAccount,
	setYourInformation,
	setSellerAgreement,
	setCGUCGVLink,
	setBankAccountDetails,
	// setSettingCatchmentAreaLocation,
	// setSettingCatchmentAreaRadius,
	setProfileImage,
	setGoogleLoginData,
} = createUserSlice.actions;
