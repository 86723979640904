import React, { CSSProperties, useCallback } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { Label } from '../..';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import PlusIcon from '../../home/PlusIcon';

const AddImageLink = styled.Text`
	color: ${palettes.grey[1]};
	width: max-content;
	border-bottom: 1px solid transparent;
	padding-bottom: 2px;
	margin-top: ${getResponsiveStyle(19)}px;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

type Props = {
	onDrop?: DropzoneProps['onDrop'];
	containerStyles?: TStyle;
};

export const UploadImage = ({ onDrop: _onDrop, containerStyles }: Props) => {
	const { t } = useTranslation();

	const onDrop: DropzoneProps['onDrop'] = useCallback(
		(acceptedFiles, fileRejected, event) => {
			_onDrop && _onDrop(acceptedFiles, fileRejected, event);
		},
		[]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});
	return (
		<View style={containerStyles}>
			<Label label={t('Add Image & Video')} />
			<div
				style={{
					...ContainerStyles,
					...styleImageDropzoneContainer(),
				}}
				{...getRootProps()}
			>
				<input
					type={'file'}
					style={{
						display: 'none',
					}}
					id="file-uploader-input"
					{...getInputProps()}
				/>
				<PlusIcon height={14} width={14} />
				<AddImageLink>{t('Add Image')}</AddImageLink>
			</div>
		</View>
	);
};

export default UploadImage;

const ContainerStyles: CSSProperties = {
	border: `1px solid ${palettes.grey[4]}`,
	borderRadius: 6,
	borderStyle: 'dashed',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: 3,
	cursor: 'pointer',
	position: 'relative',
};

const styles = StyleSheet.create({
	outerContainer: {},
	imageDropzoneContainer: {
		borderWidth: 1,
		borderRadius: 6,
		borderStyle: 'dashed',
		borderColor: palettes.grey[4],
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 3,
	},
});

const styleImageDropzoneContainer = (): CSSProperties => {
	return {
		width: getResponsiveStyle(128, 'dimensions'),
		height: getResponsiveStyle(136, 'dimensions'),
	};
};
