import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
// import Skeleton from '../Skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ProfileImageWithGender } from '../../../network/profile-cards/ProfileImageWithGender';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import Profile from '../../profile/Profile';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { TStyleInputOrText } from '../../../../types/TStyle';
import { StatsSkeleton } from '../StatsSkeleton';

type Props = {
	showMessageButton?: boolean;
	hasActionBtn?: boolean;
	hasRating?: boolean;
	hasEventAttendedOnly?: boolean;
	isUserCard?: boolean;
	isHost?: boolean;
	isInvited?: boolean;
	showGender?: boolean;
	item?: TGetAllUsersData;
	isBrand?: boolean;
	hasFollowHostRequestSent?: boolean;
	type?: string;
	hasUnfollowRequestBeenSent?: boolean;
	loadingFollowMe?: boolean;
	isFollowingMe?: boolean;
	index?: number;
};

export const ProfileCardSkeleton = ({
	showMessageButton = true,
	isHost = false,
	hasActionBtn,
	hasEventAttendedOnly,
	isUserCard,
	isInvited = false,
	isBrand = false,
	showGender = true,
}: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View style={[styles.container, styleContainer()]}>
			{!isBrand ? (
				<View style={{ alignItems: 'center', width: '100%' }}>
					<Skeleton
						width={getResponsiveStyle(80)}
						height={getResponsiveStyle(80)}
						style={{
							...styleProfilePicture(),
							// isLessThanDesktopBase && {
							// 	marginRight: 10,
							// },
							...{
								borderRadius: 9999,
								marginRight: isLessThanDesktopBase ? 10 : 0,
							},
						}}
					/>
					<Skeleton
						width={getResponsiveStyle(120)}
						height={getResponsiveStyle(10)}
						style={{ borderRadius: 6 }}
					/>
					<Text style={[styles.location, styleLocation()]}>
						<Skeleton
							style={{
								...styles.flexAddressContainer,
								borderRadius: 6,
							}}
							width={getResponsiveStyle(150)}
							height={getResponsiveStyle(10)}
						/>
					</Text>
					{isUserCard && (
						<>
							<Text style={[styles.location, styleLocation()]}>
								<Skeleton
									style={{
										...styles.flexAddressContainer,
										borderRadius: 6,
									}}
									width={getResponsiveStyle(150)}
									height={getResponsiveStyle(18)}
								/>
							</Text>
						</>
					)}
				</View>
			) : (
				<>
					<View>
						<Profile
							name="Unilever"
							profileImage="http://placekitten.com/500"
							isBrandProfile={true}
						/>
					</View>
				</>
			)}

			<View style={styles.statsContainer}>
				<StatsSkeleton />
				<StatsSkeleton />
			</View>

			<View
				style={[
					styles.statsContainer,
					{
						justifyContent: 'center',
					},
				]}
			>
				<Skeleton
					width={getResponsiveStyle(110)}
					height={getResponsiveStyle(35)}
					style={{ borderRadius: 6, marginRight: 7 }}
				/>
				<Skeleton
					width={getResponsiveStyle(110)}
					height={getResponsiveStyle(35)}
					style={{ borderRadius: 6 }}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	flexAddressContainer: {
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: getResponsiveStyle(5, 'font'),
	},
	statsContainer: {
		flexDirection: 'row',
		width: '100%',
		marginTop: getResponsiveStyle(25),
	},
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		width: 'max-content',
		marginBottom: getResponsiveStyle(10),
		marginTop: getResponsiveStyle(2),
		marginLeft: getResponsiveStyle(10),
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.09)',
		shadowOpacity: 1,
	},
	name: {
		fontWeight: '600',

		textAlign: 'center',
		marginBottom: 1,
	},
	dotIcon: {},
	location: {
		paddingBottom: 11,
		textAlign: 'center',
	},
});

const styleProfilePicture = () => {
	return {
		marginBottom: getResponsiveStyle(13),
	};
};
const styleLocation = (): TStyleInputOrText => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		textTransform: 'capitalize',
	};
};

const styleContainer = () => {
	return {
		// marginRight: getResponsiveStyle(17),
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(24),
		minWidth: getResponsiveStyle(300, 'dimensions'),
	};
};
