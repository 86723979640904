import { useLazyQuery } from '@apollo/client';
import { GET_STRIPE_ID } from '../graphql/stripe/queries';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setStripeID } from '../redux/stripeIdSlice';

export const useGetStripeId = () => {
	const dispatch = useAppDispatch();
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	const [getLoggedInUserStripeId] = useLazyQuery(GET_STRIPE_ID, {
		variables: {
			args: { email: loggedInUserDetails?.email },
		},
		onCompleted: data => {
			data &&
				loggedInUserDetails?.email &&
				dispatch(setStripeID(data?.getStripeId?.data?.id));
		},
	});

	return { getLoggedInUserStripeId };
};
