import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';
import { EventSelectButton } from './EventSelectButton';

const EventSelect = (props: {
	onSubmit: (eventType: TTypeOfEvent) => void;
}) => {
	const { t } = useTranslation();
	const typeOfEvent = useAppSelector(state => state.createEvent.typeOfEvent);
	return (
		<>
			<EventSelectButton
				title={t('Live Shopping')}
				text={t('Want to live stream the event?')}
				onSubmit={props.onSubmit}
				value="VIRTUAL"
				selected={typeOfEvent === 'VIRTUAL'}
			/>

			<EventSelectButton
				title={t('Physical Event')}
				text={t('Want to invite individuals to a venue?')}
				onSubmit={props.onSubmit}
				value="PHYSICAL"
				selected={typeOfEvent === 'PHYSICAL'}
			/>
		</>
	);
};

export default EventSelect;
