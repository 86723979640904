import { useLazyQuery } from '@apollo/client';
import { GET_ALL_EVENTS } from '../../graphql/events/queries';

export const useGetAllEvents = () => {
	const [getAllEvents, { data, error, loading, refetch }] =
		useLazyQuery<TGetAllEventsResponse>(GET_ALL_EVENTS);
	const executeEventsQuery = (find: TGetAllEventFind, limit?: number) => {
		getAllEvents({
			variables: {
				find,
				limit,
				sort: { startingEventDateTime: -1 },
			},
		});
	};
	return { executeEventsQuery, data, error, loading, refetch };
};
