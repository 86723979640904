import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { CREATE_LIVE_EVENT_MESSAGE } from '../../graphql/chat/mutation';
import { TUseNavigation } from '../../types/exportedTypes';

const useCreateLiveEventMessage = () => {
	const navigation = useNavigation<TUseNavigation>();
	const [createLiveEventMessage] = useMutation(CREATE_LIVE_EVENT_MESSAGE);

	const executeCreateLiveEventMessageMutation = ({
		sender,
		message,
		eventId,
	}: {
		sender?: string;
		message?: string;
		eventId?: string;
	}) => {
		createLiveEventMessage({
			variables: {
				sender,
				message,
				eventId,
			},
		});
	};
	return { executeCreateLiveEventMessageMutation };
};

export default useCreateLiveEventMessage;
