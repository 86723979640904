import React from 'react';
import { StyleSheet, View } from 'react-native';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const VariantSkeleton = () => {
	return (
		<View style={[styles.productDetail]}>
			<View style={{ flexDirection: 'row' }}>
				<Skeleton style={{ marginRight: 10 }} height={56} width={56} />
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	productDetail: {
		flexDirection: 'row',
	},
});

export default VariantSkeleton;
