import { useMutation } from '@apollo/client';
import { CREATE_TRANSFER } from '../../graphql/stripe/mutations';

type TQueryArgs = {
	currency: string;
	amount: number;
	chargeId: string;
	destination: string;
	type?: 'BRAND' | 'SELLER';
};
type QueryFn = (args: TQueryArgs) => void;
export const useCreateTransfer = () => {
	const createTransfer: QueryFn = ({
		currency = 'eur',
		amount = 0,
		chargeId,
		destination,
		type,
	}) => {
		let args = {
			currency,
			amount,
			chargeId,
			destination,
			type,
		};

		createTransfer_({
			variables: {
				args,
			},
		});
	};
	const [createTransfer_] = useMutation(CREATE_TRANSFER);

	return createTransfer;
};
