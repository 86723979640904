import { useLazyQuery } from '@apollo/client';
import { GET_ENTITY_RATING } from '../../graphql/rating/queries';

const useGetEntityRating = () => {
	const [getEntityRating, { data, error, loading }] =
		useLazyQuery(GET_ENTITY_RATING);

	const executeEntityRatingQuery = ({ entityId }: { entityId: string }) => {
		getEntityRating({
			variables: {
				entityId,
			},
		});
	};
	return { executeEntityRatingQuery, data, error, loading };
};

export default useGetEntityRating;
