import { ApolloError, useLazyQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
	Loader,
	LoadMore,
	NotAvailableMessage,
	ProfileTableControl,
} from '../..';
import { palettes } from '../../../config';
import {
	GET_BRAND_STATS,
	GET_FOLLOWING_BRANDS,
} from '../../../graphql/brands/queries';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import { usePagination } from '../../../hooks/useCurrentForPagination';
import { twoDp } from '../../../utils/twoDP';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { resetFilters } from '../../../redux/filterSlice';
import { resetSort } from '../../../redux/sortSlice';
import { sortCheck, sortValue } from '../../../utils/handleSort';

const AllBrands = () => {
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const dispatch = useAppDispatch();
	const [filter, sort, dropdown, brandCategories, language] = useAppSelector(
		state => [
			state.filter,
			state.sort,
			state.tags.DROPDOWN_CATEGORIES,
			state.tags.BRAND_PILL_CATEGORIES,
			state.ui.language,
		]
	);

	const [allBrands, setAllBrands] = useState<any[]>([]);
	const [total, setTotal] = useState<number>(0);
	const LIMIT = 10;
	const HEADERS = [
		{
			headerLabel: t('Name'),
			width: '15%',
		},
		{
			headerLabel: t('Rating'),
			width: '10%',
		},
		{
			headerLabel: t('Category'),
			width: '20%',
		},
		{
			headerLabel: t('Followers'),
			width: '24%',
		},
		{
			headerLabel: t('Average Price'),
			width: '10%',
		},
		{
			headerLabel: t('Country'),
			width: '12.57%',
		},
	];

	const brandTableSort = [
		{
			label: `${t('Name')}: A-Z`,
			local: false,
			value: { getFollowingBrands: { name: 1 } },
		},
		{
			label: `${t('Name')}: Z-A`,
			local: false,
			value: { getFollowingBrands: { name: -1 } },
		},

		{
			label: `${t('Country')}: A-Z`,
			local: false,
			value: {
				getFollowingBrands: { address: { countryLabel: 1 } },
			},
		},
		{
			label: `${t('Country')}: Z-A`,
			local: false,
			value: {
				getFollowingBrands: { address: { countryLabel: -1 } },
			},
		},

		{
			label: `${t('Rating')} ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'rating', value: -1 },
		},
		{
			label: `${t('Rating')} ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'name', value: 1 },
		},
		{
			label: `${t('Guest')} ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'guest', value: -1 },
		},
		{
			label: `${t('Guest')} ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'guest', value: 1 },
		},
		{
			label: `${t('Host')} ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'host', value: -1 },
		},
		{
			label: `${t('Host')} ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'host', value: 1 },
		},
		{
			label: `${t('Seller')} ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'seller', value: -1 },
		},
		{
			label: `${t('Seller')} ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'seller', value: 1 },
		},
		{
			label: `${t('Average Price')} ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'averagePrice', value: -1 },
		},
		{
			label: `${t('Average Price')} ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'averagePrice', value: 1 },
		},
	];
	const brandFilters = [
		{ label: t('City'), value: 'brandaddcity' },
		{ label: t('Zip Code'), value: 'brandaddzipCode' },
		{ label: t('Country'), value: 'brandaddcountryCode' },
		{ label: t('Interest tags'), value: 'tags' },
		{ label: t('Rating'), value: 'rating' },
	];
	const getCategories = (arr?: string[]) => {
		const tags = arr
			?.map(
				(tag: string) =>
					[...dropdown, ...brandCategories]?.find(
						value => value?.value === tag
					)?.[language]
			)
			.filter(n => n)
			.join(', ');

		return tags?.length ? tags : 'N/A';
	};
	useEffect(() => {
		dispatch(resetFilters());
		dispatch(resetSort());
	}, []);

	const [getBrandStats, { loading: brandStatsLoading }] = useLazyQuery(
		GET_BRAND_STATS,
		{
			fetchPolicy: 'no-cache',
			onCompleted: (data: any) => {
				if (
					data.getBrandStats.data.data &&
					data.getBrandStats.success &&
					allBrandsData !== undefined
				) {
					let stats = data.getBrandStats.data.data;

					let val = allBrandsData?.getFollowingBrands?.data?.data.map(
						(brand: any) => {
							return {
								_id: brand._id,
								name: brand.name,
								profileImage: brand.profileImage,
								address: {
									countryLabel: brand.address.countryLabel,
									countryCode: brand.address.countryCode,
									city: brand.address.city,
								},
								phoneNumber: brand.phoneNumber,
								brandCategory: getCategories(brand?.tags),
								email: brand.email,
								rating: brand.rating,
								averagePrice:
									brand?.products?.data[0]?.averagePrice ?? 0,
								guest: stats.find(
									(stat: { brandId: any }) =>
										stat.brandId === brand._id
								)?.followers?.guestCount,
								seller: stats.find(
									(stat: { brandId: any }) =>
										stat.brandId === brand._id
								)?.followers?.sellersCount,
								host: stats.find(
									(stat: { brandId: any }) =>
										stat.brandId === brand._id
								)?.followers?.hostCount,
								followers: stats.find(
									(stat: { brandId: any }) =>
										stat.brandId === brand._id
								)?.followers,
							};
						}
					);
					val = val?.filter(item => {
						const x = allBrands.find(
							element => element._id === item._id
						);
						if (!x) {
							return item;
						}
					});
					setAllBrands([...allBrands, ...val]);
				}
			},
		}
	);
	const [allFollowingCurrent, increaseAllFollowingCurrent, setCurrent] =
		usePagination();
	const OnPressViewMore = () => {
		const increaseCurrent = increaseAllFollowingCurrent();
		refetchFollowingBrands({
			limit: LIMIT,
			current: increaseCurrent,
			find: {
				...filter,
			},
		});
	};

	const [
		getFollowingBrands,
		{
			loading: allBrandsLoading,
			data: allBrandsData,
			refetch: refetchFollowingBrands,
			networkStatus: allFollowingNetworkStatus,
		},
	] = useLazyQuery<TGetAllFollowedBrandsResponse>(GET_FOLLOWING_BRANDS, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,

		onCompleted: data => {
			if (
				data &&
				data.getFollowingBrands &&
				data.getFollowingBrands.data &&
				data.getFollowingBrands.success &&
				data.getFollowingBrands.data.data
			) {
				if (data.getFollowingBrands.data.data.length > 0) {
					setTotal(data.getFollowingBrands.data.total);
					getBrandStats({
						variables: {
							_ids: data.getFollowingBrands.data.data.map(
								(x: { _id: any }) => x._id
							),
						},
					});
				}
			} else {
				setAlert('Unable to fetch brands!', 'danger');
			}
		},
		onError: (error: ApolloError) => {
			if (!!error) {
				setAlert('Unable to fetch brands!', 'danger');
			}
		},
	});
	useEffect(() => {
		setTotal(0);
		setAllBrands([]);
		setCurrent(0);
		getFollowingBrands({
			variables: {
				limit: LIMIT,
				current: allFollowingCurrent,
				find: {
					...filter,
				},
				sort: sortCheck(sort),
			},
		});
	}, [filter, sort]);

	const loadingState = useMemo(
		() => getSingleLoadingState(allBrandsLoading, brandStatsLoading),
		[allBrandsLoading, brandStatsLoading]
	);

	return (
		<View style={styles.container}>
			{loadingState && allBrands.length <= 0 ? (
				<Loader />
			) : (
				<>
					<ProfileTableControl
						heading={t('Brands Following')}
						data={allBrands.sort((a, b) => sortValue(a, b, sort))}
						hasFilter
						filterItem={brandFilters}
						sortItem={brandTableSort}
						headers={HEADERS}
						type="brand"
					/>

					{total > allBrands.length && (
						<LoadMore
							loading={
								allFollowingNetworkStatus === 2 ||
								brandStatsLoading
							}
							onClick={OnPressViewMore}
						/>
					)}
				</>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(24),
	},
	tabContainer: {
		flexDirection: 'row',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header: {
		fontSize: getResponsiveStyle(24),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	tableContainer: {
		marginTop: getResponsiveStyle(19),
	},
});

export default AllBrands;
